import { EntityTypes } from "../entities/definitions";
import type { Issue, IssueCodes, ValidationResult } from "./validationManager";
import ValidationManager from "./validationManager";
import AnalyticsEntity from "../entities/analyticsEntity";
import DataElementEntity from "../entities/dataElementEntity";
import DataTableEntity from "../entities/dataTableEntity";
import GroupEntity from "../entities/groupEntity";
import LogicEntity from "../entities/logicEntity";
import NarrationEntity from "../entities/narrationEntity";
import ParameterEntity from "../entities/parameterEntity";
import PlaceholderEntity from "../entities/placeholderEntity";
import PrioritizedListCandidateEntity from "../entities/prioritizedListCandidateEntity";
import PrioritizedListEntity from "../entities/prioritizedListEntity";
import ProgramEntity from "../entities/programEntity";
import RecordingEntity from "../entities/recordingEntity";
import SceneAnimationEntity from "../entities/sceneAnimationEntity";
import SceneEntity from "../entities/sceneEntity";
import ScenePartEntity from "../entities/scenePartEntity";
import SceneValidationEntity from "../entities/sceneValidationEntity";
import StoryEntity from "../entities/storyEntity";
import StudioDataElementEntity from "../entities/studioDataElementEntity";
import { DataElementOrigins } from "../../../common/types/dataElement";
import {
    LOADING_PROJECT_WIREFRAMES_FROM_UIASSET,
    LOADING_PROJECT_WIREFRAMES_PRESETS_SUCCESS
} from "../projects/projectWireframes/projectsWireframesActions";
import ValidationTable from "./validationTable";
import traverse from "traverse";
import AssetUtils from "../common/assetUtils";
import { GET_PROJECT_VERSIONS_COMPLETE, LOADING_PROJECT_LIFECYCLE_STAGE_SUCCESS } from "../projects/projectsActions";
import { UPDATE_ASSET } from "../projects/projectAssets/projectAssetsActions";
import { LOADING_PROJECT_SNAPSHOT_SUCCESS } from "../projects/projectLifecycle/projectLifecycleActions";

type GetValidationManagerFunction = () => ValidationManager;

const getValidationManager: GetValidationManagerFunction = (function() {
    let validationManager: ValidationManager;

    return (): ValidationManager => {
        if (!validationManager) {
            validationManager = new ValidationManager([
                new AnalyticsEntity(),
                new DataTableEntity(),
                new GroupEntity(),
                new LogicEntity(),
                new NarrationEntity(),
                new ParameterEntity(),
                new PlaceholderEntity(),
                new PrioritizedListCandidateEntity(),
                new PrioritizedListEntity(),
                new ProgramEntity(),
                new RecordingEntity(),
                new SceneAnimationEntity(),
                new SceneEntity(),
                new ScenePartEntity(),
                new SceneValidationEntity(),
                new StoryEntity(),
                new StudioDataElementEntity(),
                new DataElementEntity()
            ]);
        }

        return validationManager;
    };
})();

const nonRelevantAction = (actionType: string) => {
    return (
        actionType === LOADING_PROJECT_LIFECYCLE_STAGE_SUCCESS ||
        actionType === GET_PROJECT_VERSIONS_COMPLETE ||
        actionType === LOADING_PROJECT_WIREFRAMES_PRESETS_SUCCESS ||
        actionType === UPDATE_ASSET ||
        actionType === LOADING_PROJECT_SNAPSHOT_SUCCESS
    );
};

export default function validationReducer(state: any, action: any): any {
    //TODO: run only if draft! in case we're on a specific stage and version we don't need to calculate all validations cause we have them saved on the snapshot
    //TODO: find a way not to rebuild unchanged validation results

    if (!action || nonRelevantAction(action.type) || state.isLoading || state.loadingCount > 0 || (action.payload && action.payload.stage === "diff")) {
        return state;
    }
    if (action.type === LOADING_PROJECT_WIREFRAMES_FROM_UIASSET) {
        return { ...state, validationData: new ValidationTable() };
    }
    if (action.payload && action.payload.projectName) {
        let table = getValidationManager().init(state.validationData, {
            state: state,
            projectName: action.payload.projectName,
            stage: action.payload.stage,
            version: action.payload.version
        });

        return { ...state, validationData: table };
    }

    return state;
}

export function getDataElementValidationResult(state: any, dataElementId: string, dataElementOrigin: string): ValidationResult {
    if (dataElementOrigin === DataElementOrigins.Feed || dataElementOrigin === DataElementOrigins.Creative) {
        return getValidationManager().readValidationResultById(state.validationData, EntityTypes.DATA_ELEMENT, dataElementId);
    }
    else if (dataElementOrigin === DataElementOrigins.Derived) {
        return getValidationManager().readValidationResultById(state.validationData, EntityTypes.DERIVED_DATA_ELEMENT, dataElementId);
    }
}

export function getNarrationValidationResult(state: any, narrationId: string): ValidationResult {
    const narrationEntityId: string = AssetUtils.getCrossVersionId(narrationId);
    return getValidationManager().readValidationResultById(state.validationData, EntityTypes.NARRATION, narrationEntityId);
}

export function getSceneValidationResult(state: any, sceneId: string): ValidationResult {
    return getValidationManager().readValidationResultById(state.validationData, EntityTypes.SCENE, sceneId);
}

export function getPriorityListCandidateValidationResult(state: any, prioritizedListCandidateKey: string): ValidationResult {
    return getValidationManager().readValidationResultById(state.validationData, EntityTypes.PRIORITIZED_LIST_CANDIDATE, prioritizedListCandidateKey);
}

export function getPriorityListValidationResult(state: any, prioritizedListId: string): ValidationResult {
    return getValidationManager().readValidationResultById(state.validationData, EntityTypes.PRIORITIZED_LIST, prioritizedListId);
}

export function getStoryValidationResult(state: any, storyId: string): ValidationResult {
    return getValidationManager().readValidationResultById(state.validationData, EntityTypes.STORY, storyId);
}

export function getPlaceholderValidationResult(state: any, placeholderName: string, sceneId: string): ValidationResult {
    return getValidationManager().readValidationResultById(state.validationData, EntityTypes.PLACEHOLDER, PlaceholderEntity.generateId(placeholderName, sceneId));
}

export function getGroupValidationResult(state: any, groupId: string): ValidationResult {
    return getValidationManager().readValidationResultById(state.validationData, EntityTypes.GROUP, groupId);
}

export function getParameterValidationResult(state: any, parameterName: string, sceneId: string): ValidationResult {
    return getValidationManager().readValidationResultById(state.validationData, EntityTypes.PARAMETER, ParameterEntity.generateId(parameterName, sceneId));
}

export function getDataTableValidationResult(state: any, dataTableName: string): ValidationResult {
    return getValidationManager().readValidationResultById(state.validationData, EntityTypes.DATA_TABLE, dataTableName);
}

export function getProgramValidationResult(state: any, projectName: string): ValidationResult {
    return getValidationManager().readValidationResultById(state.validationData, EntityTypes.PROGRAM, projectName);
}

export function getLogicValidationResult(state: any, logicId: string): ValidationResult {
    return getValidationManager().readValidationResultById(state.validationData, EntityTypes.LOGIC, logicId);
}

/**
 * filter issues by code including children
 * @param result the validation result with the issues array
 * @param code the issue code to filter by
 * returns array of issues with the requested issue code
 */
export function filterIssues(result: ValidationResult, code: IssueCodes): Issue[] {
    return traverse(result).reduce((acc, node) => {
        if (node && node.code === code) {
            acc.push(node);
        }
        return acc;
    }, []);
}
