import { findDataElementById, findDataElementByName, getDerivedDataElementLogicId, getDataElementId } from "./DataElementsManager";
import { Graph } from "../../../common/Graph";
import { extractDataElementIdsFromLogic } from "../vlx/editorLogicUtils";
import type { LogicJSON } from "../../../common/types/logic";
import type { DataElement } from "../../../common/types/dataElement";
import { convertArrayOfObjectsToObject } from "../../../common/arrayUtils";

const getDataElementIdsFromLogic = function(derivedLogic: LogicJSON, shouldEnterGraph: (deId: string) => boolean, mappingTables: any[]): string[] {
    if (!derivedLogic) {
        return [];
    }
    return extractDataElementIdsFromLogic(derivedLogic, mappingTables).filter((deId) => shouldEnterGraph(deId));
};

class StudioDataManager {
    _graph: any;
    _derivedLogic: { [id: string]: LogicJSON };
    _mappingTables: any[];
    _dataElements: DataElement[];
    _dataElementsByName: { [name: string]: DataElement };

    constructor(context) {
        this._graph = new Graph();
        this._derivedLogic = (context && context.derivedLogic) || {};
        this._mappingTables = (context && context.mappingTables) || [];
        this._dataElements = (context && context.dataElements) || [];

        this._dataElementsByName = convertArrayOfObjectsToObject(this._dataElements, "name");
    }

    getLogic(idOrName: string): LogicJSON {
        let dataElement = findDataElementById(idOrName, this._dataElements);
        dataElement = dataElement || findDataElementByName(idOrName, this._dataElements); // Backward compatibility

        if (!dataElement) {
            return null;
        }

        let logicId = getDerivedDataElementLogicId(dataElement);

        return this._derivedLogic[logicId];
    }

    buildDerivedGraphFromLogic(logic: LogicJSON, includeNonDerived: boolean) {
        let _shouldEnterGraph = (dataElementIdFromLogic: string): boolean => {
            if (includeNonDerived) {
                return true;
            }
            let logic = this.getLogic(dataElementIdFromLogic);
            return !!logic;
        };

        this._graph = new Graph();

        let dataElementIDs = getDataElementIdsFromLogic(logic, _shouldEnterGraph, this._mappingTables);

        dataElementIDs.forEach((deId) => this._graph.addVertex(deId));

        this._enhanceGraphFromVertices(dataElementIDs, _shouldEnterGraph);
    }

    buildDerivedGraph(dataElements: DataElement[]) {
        let _shouldEnterGraph = (dataElementIdFromLogic) => {
            let dataElement = findDataElementById(dataElementIdFromLogic, dataElements);
            dataElement = dataElement || findDataElementByName(dataElementIdFromLogic, dataElements); // Backward compatibility
            return !!dataElement;
        };

        this._graph = new Graph();

        let vertices = [];
        dataElements.forEach((dataElement) => {
            let dataElementId = getDataElementId(dataElement);
            this._graph.addVertex(dataElementId);
            vertices.push(dataElementId);
        });

        this._enhanceGraphFromVertices(vertices, _shouldEnterGraph);
    }

    _enhanceGraphFromVertices(vertices, shouldEnterGraph) {
        let newVertices = [];

        vertices.forEach((vertex) => {
            let logic = this.getLogic(vertex);
            let dataElementIdsOrNames: string[] = getDataElementIdsFromLogic(logic, shouldEnterGraph, this._mappingTables);

            dataElementIdsOrNames.forEach((dataElementIdOrName) => {
                let dataElementId: string = this._dataElementsByName[dataElementIdOrName] ? this._dataElementsByName[dataElementIdOrName].id : dataElementIdOrName;
                this._graph.addEdge(vertex, dataElementId);
                if (!this._graph.hasVertex(dataElementId)) {
                    this._graph.addVertex(dataElementId);
                    newVertices.push(dataElementId);
                }
            });
        });

        if (newVertices.length === 0) {
            return;
        }

        this._enhanceGraphFromVertices(newVertices, shouldEnterGraph);
    }

    detectCycles(): string[] {
        return this._graph.detectCycles();
    }

    getDependentDerivedDataElementIds(dataElementId: string | string[]): string[] {
        let deIDs = Array.isArray(dataElementId) ? dataElementId : [dataElementId];

        let reversedGraph = this._graph.getReversedGraph();

        return this._getDependentDerivedDataElementIds(deIDs, reversedGraph);
    }

    _getDependentDerivedDataElementIds(dataElementIds: string[], graph: any): string[] {
        let set = new Set<string>();

        dataElementIds.forEach((id) => {
            let linkedVertices = graph.getLinkedVertices(id);
            linkedVertices.forEach((v) => set.add(v));
        });

        return Array.from(set);
    }

    sortDerivedByDependencies(): string[] {
        return this._graph.sortByDependencies();
    }
}

export default StudioDataManager;
