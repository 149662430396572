import { createAction } from "redux-actions";
import { reportError, setForceLoad, setLoading } from "../../common/commonActions";
import { loadingGqlProgramSuccess } from "../projectsActions";
import { addNarrationSuccess, deleteNarrationSuccess, loadNarrationsSuccess } from "./projectNarrations/projectNarrationsActions";
import { LogicContainers, placeholderType, wireFramesAssetType } from "../../../../common/commonConst";
import StateReaderUtils from "../../common/StateReaderUtils";
import NarrationsModelUtils from "./projectNarrations/NarrationsModelUtils";
import type { DataElement, DataElementContentTypes } from "../../../../common/types/dataElement";
import { DataElementOrigins } from "../../../../common/types/dataElement";
import { CREATIVES_PRESET_ID, IGNORE_SERVER_ERRORS, IGNORE_UPDATED, MOCK_SCENE_ENTITY_LOGIC_ID, POSTGRES_CREATIVES_PRESET_ID } from "../../common/Consts";
import { deepCloneObj, memoizeThunkAction } from "../../common/generalUtils";
import { clearObj, getUniqueNameWithRunningSuffix, isObject, isUuid } from "../../../../common/generalUtils";
import type { LogicJSON, WireframeLogic, WireframeLogicContainer, WireframeProgramLogic, WireframeStoriesLogic } from "../../../../common/types/logic";
import { LogicType } from "../../../../common/types/logic";
import { v4 as uuid } from "uuid";
import type {
    CreatePresetMutationResult,
    DeletePresetMutationResult,
    GetProgramPresetsQueryResult,
    GetProgramPresetsWithoutValuesQueryResult,
    GetProgramPresetValuesQueryResult,
    GqlClientCopyNarrationInput,
    GqlClientCopyNarrationMutation,
    GqlClientCopySceneMutation,
    GqlClientCopyScenePartMutation,
    GqlClientCreateCustomAnalyticFieldInput,
    GqlClientCreateCustomAnalyticFieldMutation,
    GqlClientCreateMasterAnimationGuidelineMutation,
    GqlClientCreatePresetDataElementValueInput,
    GqlClientCreatePresetMutationVariables,
    GqlClientCreatePresetSystemElementValueInput,
    GqlClientCreateScenePartMutation,
    GqlClientCreateSceneWithScenePartMutation,
    GqlClientDeleteCustomAnalyticFieldInput,
    GqlClientDeleteCustomAnalyticFieldMutation,
    GqlClientDeleteMasterAnimationGuidelineMutation,
    GqlClientDeletePlaceholderGroupLogicMutation,
    GqlClientDeletePresetMutationVariables,
    GqlClientDeletePrioritizedListLogicMutation,
    GqlClientDeleteSceneEntityLogicMutation,
    GqlClientGetBuilderProgramQuery,
    GqlClientNarration,
    GqlClientProgram,
    GqlClientScene,
    GqlClientSceneForBuilderFragment,
    GqlClientScenePart,
    GqlClientScenePartFragment,
    GqlClientScenePartNarrationsFragment,
    GqlClientUpdateBuildersSceneMutation,
    GqlClientUpdateCustomAnalyticFieldInput,
    GqlClientUpdateCustomAnalyticFieldMutation,
    GqlClientUpdateMasterAnimationGuidelineMutation,
    GqlClientUpdateMasterDataInput,
    GqlClientUpdateNarrationOrderMutation,
    GqlClientUpdatePresetMutation,
    GqlClientUpdatePresetMutationVariables,
    GqlClientUpdateProgramInput,
    GqlClientUpdateProgramMutation,
    GqlClientUpdateScenePartMutation,
    GqlClientUpdateScenePartWithAllPlaceholdersMutation
} from "../../../graphql/graphqlGeneratedTypes/graphqlClient";
import {
    CopyNarrationDocument,
    CopySceneDocument,
    CopyScenePartDocument,
    CreateCustomAnalyticFieldDocument,
    CreateMasterAnimationGuidelineDocument,
    CreatePresetDocument,
    CreateScenePartDocument,
    CreateSceneWithScenePartDocument,
    DeleteCustomAnalyticFieldDocument,
    DeleteMasterAnimationGuidelineDocument,
    DeletePlaceholderGroupLogicDocument,
    DeletePresetDocument,
    DeletePrioritizedListLogicDocument,
    DeleteSceneDocument,
    DeleteSceneEntityLogicDocument,
    DeleteScenePartDocument,
    GetBuilderProgramDocument,
    GetProgramPresetsDocument,
    GetProgramPresetsWithoutValuesDocument,
    GetProgramPresetValuesDocument,
    GqlClientCreatePresetResult,
    GqlClientDeletePresetResult,
    GqlClientDynamoMigrationStatus,
    UpdateBuildersSceneDocument,
    UpdateCustomAnalyticFieldDocument,
    UpdateMasterAnimationGuidelineDocument,
    UpdateMasterDataDocument,
    UpdateNarrationOrderDocument,
    UpdatePlaceholderGroupLogicDocument,
    UpdatePresetDocument,
    UpdatePrioritizedListLogicDocument,
    UpdateProgramDocument,
    UpdateSceneEntityLogicDocument,
    UpdateScenePartDocument,
    UpdateScenePartWithAllPlaceholdersDocument
} from "../../../graphql/graphqlGeneratedTypes/graphqlClient";
import {
    buildAnalyticsLogicFromGql,
    buildDataElementsFromGql,
    buildDerivedLogicFromGql,
    buildMasterLogicFromGql,
    buildProgramLogicFromGql,
    buildSceneLogicFromGql,
    buildScenesLogicFromGql,
    buildStoriesLogicFromGql,
    buildWireframesFromGql,
    buildWireframesNarrationFromGql,
    buildWireframesNarrationsFromGql,
    buildWireframesNarrationsPartFromGql,
    buildWireframesSceneFromGql,
    getIdFromGqlId
} from "../../common/convertGqlEntityToWireframesUtils";
import type { ThunkServices } from "../../common/types";
import { BulkId } from "../../common/types";
import { convertAnimationGuidelineType } from "../../builder/graphql/convertTypes";
import { convertGqlPresetsResult, convertGqlPresetsWithoutValuesResult, convertGqlPresetValuesResultAndAddToPresetMap } from "../../common/convertGqlPresetUtils";
import { loadingProjectAssetsSuccess } from "../projectAssets/projectAssetsActions";
import { AssetTypes } from "../../../../common/types/asset";
import type { Snapshot } from "../../../../common/types/snapshot";
import { SnapshotSource } from "../../../../common/types/snapshot";
import { reportErrorToSplunk } from "../../common/logReportUtils";
import type { ElementData, Preset, PresetData, PresetMap, PresetObjectType, PresetValue } from "../../../../common/types/preset";
import type { Program, ProgramSummary } from "../../../../common/types/program";
import type { Group, Parameter, ParameterTypes, PrioritizedList, Scene, ScenePart } from "../../../../common/types/scene";
import type { Narration, VariationData } from "../../../../common/types/narration";
import type { CustomAnalyticField } from "../../../../common/types/analytics";
import type { FetchResult } from "@apollo/client";
import type { MasterData } from "../../../../common/types/master";

export type GetBuilderProgramQueryProgram = FetchResult<GqlClientGetBuilderProgramQuery>["data"]["program"];
export type GetBuilderProgramQueryProgramVersion = GetBuilderProgramQueryProgram["programVersion"];
export type GetBuilderProgramQueryScene = GetBuilderProgramQueryProgramVersion["scenes"][0];
export type GetBuilderProgramQueryScenePart = GetBuilderProgramQueryScene["sceneParts"][0];
export type GetBuilderProgramQueryNarration = GetBuilderProgramQueryScenePart["narrations"][0];
export type GetBuilderProgramQueryPlaceholder = GetBuilderProgramQueryScenePart["placeholders"][0];
export type GetBuilderProgramQueryPlaceholderGroup = GetBuilderProgramQueryScenePart["placeholderGroups"][0];
export type GetBuilderProgramQueryAnimationGuideline = GetBuilderProgramQueryScenePart["animationGuidelines"][0];
export type GetBuilderProgramQueryPrioritizedList = GetBuilderProgramQueryScenePart["prioritizedLists"][0];
export type GetBuilderProgramQueryStory = GetBuilderProgramQueryProgramVersion["stories"][0];
export type GetBuilderProgramQueryMasterAnimationGuideline = GetBuilderProgramQueryProgramVersion["masterAnimationGuidelines"][0];
export type GetBuilderProgramQueryCustomAnalyticField = GetBuilderProgramQueryProgram["customAnalyticFields"][0];


export const LOADING_PROJECT_WIREFRAMES_FROM_UIASSET = "LOADING_PROJECT_WIREFRAMES_FROM_UIASSET";
export const loadingProjectWireframesFromUIAsset = createAction(LOADING_PROJECT_WIREFRAMES_FROM_UIASSET, (accountId, projectName, data, stage, version) => {
    return { accountId, projectName, data, stage, version };
}, undefined);

export const ADDING_PROJECT_WIREFRAMES_SCENE_SUCCESS = "ADDING_PROJECT_WIREFRAMES_SCENE_SUCCESS";
export const addingProjectWireframesSceneSuccess = createAction(ADDING_PROJECT_WIREFRAMES_SCENE_SUCCESS, function(accountId, projectName, sceneId, sceneData) {
    return { accountId, projectName, sceneId, sceneData };
});

export const ADDING_PROJECT_WIREFRAMES_SCENE_PART_SUCCESS = "ADDING_PROJECT_WIREFRAMES_SCENE_PART_SUCCESS";
export const AddingProjectWireframesScenePartsSuccess = createAction(ADDING_PROJECT_WIREFRAMES_SCENE_PART_SUCCESS, function(accountId, projectName, sceneId, scenePart) {
    return { accountId, projectName, sceneId, scenePart };
});

export const CHANGING_PROJECT_WIREFRAMES_SCENE_PART_SUCCESS = "CHANGING_PROJECT_WIREFRAMES_SCENE_PART_SUCCESS";
export const ChangingProjectWireframesScenePartsSuccess = createAction(CHANGING_PROJECT_WIREFRAMES_SCENE_PART_SUCCESS, function(accountId, projectName, sceneId, scenePart, scenePartIndex) {
    return { accountId, projectName, sceneId, scenePart, scenePartIndex };
}, undefined);

export const CHANGING_BULK_SCENE_PARTS_SUCCESS = "CHANGING_BULK_SCENE_PARTS_SUCCESS";
export const ChangingBulkScenePartsSuccess = createAction(CHANGING_BULK_SCENE_PARTS_SUCCESS, function(accountId, projectName, bulkSceneParts) {
    return { accountId, projectName, bulkSceneParts };
});

export const CHANGING_PROJECT_WIREFRAMES_SCENE_SUCCESS = "CHANGING_PROJECT_WIREFRAMES_SCENE_SUCCESS";
export const changingProjectWireframesSceneSuccess = createAction(CHANGING_PROJECT_WIREFRAMES_SCENE_SUCCESS, function(accountId, projectName, sceneId, sceneData) {
    return { accountId, projectName, sceneId, sceneData };
});

export const CHANGING_PROJECT_WIREFRAMES_STORY_SUCCESS = "CHANGING_PROJECT_WIREFRAMES_STORY_SUCCESS";

export const DELETING_PROJECT_WIREFRAMES_SCENE_PART_SUCCESS = "DELETING_PROJECT_WIREFRAMES_SCENE_PART_SUCCESS";
export const deletingProjectWireframesScenePartsSuccess = createAction(DELETING_PROJECT_WIREFRAMES_SCENE_PART_SUCCESS, function(accountId, projectName, sceneId, scenePartIndex) {
    return { accountId, projectName, sceneId, scenePartIndex };
});

export const DELETING_PROJECT_WIREFRAMES_SCENE_SUCCESS = "DELETING_PROJECT_WIREFRAMES_SCENE_SUCCESS";
export const deletingProjectWireframesSceneSuccess = createAction(DELETING_PROJECT_WIREFRAMES_SCENE_SUCCESS, function(accountId, projectName, sceneId) {
    return { accountId, projectName, sceneId };
});

export const UPDATING_PROJECT_WIREFRAMES_SCENE_INPUT_LOGIC_SUCCESS = "UPDATING_PROJECT_WIREFRAMES_SCENE_INPUT_LOGIC_SUCCESS";
export const updatingProjectWireFramesSceneInputLogicSuccess = createAction(UPDATING_PROJECT_WIREFRAMES_SCENE_INPUT_LOGIC_SUCCESS, function(
    accountId,
    projectName,
    sceneId,
    inputName,
    logicData,
    stage,
    version
) {
    return { accountId, projectName, sceneId, inputName, logicData, stage, version };
}, undefined);

// bulkLogicItems is { [logicContainer/logicOwner] : {status: number} }
export const VALUE_SETS_CHANGED = "VALUE_SETS_CHANGED";
export const valueSetsChanged = createAction(VALUE_SETS_CHANGED, function(accountId, projectName, dataElementIds) {
    return { accountId, projectName, dataElementIds };
});

export const LOADING_PROJECT_WIREFRAMES_SCENE_INPUT_LOGIC = "LOADING_PROJECT_WIREFRAMES_SCENE_INPUT_LOGIC";
export const loadingProjectWireFramesSceneInputLogic = createAction(LOADING_PROJECT_WIREFRAMES_SCENE_INPUT_LOGIC, function(accountId, projectName, sceneId, inputName, stage, version) {
    return { accountId, projectName, sceneId, inputName, stage, version };
}, undefined);

export const ADDING_PROJECT_WIREFRAMES_SCENE_PART_STORYBOARD_FRAME_SUCCESS = "ADDING_PROJECT_WIREFRAMES_SCENE_PART_STORYBOARD_FRAME_SUCCESS";
export const addingProjectWireframesScenePartStoryboardFrameSuccess = createAction(ADDING_PROJECT_WIREFRAMES_SCENE_PART_STORYBOARD_FRAME_SUCCESS, function(
    accountId,
    projectName,
    sceneId,
    scenePartId,
    scenePartData,
    scenePartIndex
) {
    return { accountId, projectName, sceneId, scenePartId, scenePartData, scenePartIndex };
}, undefined);

export const LOADING_PROGRAM_WIREFRAMES_SUCCESS = "LOADING_PROGRAM_WIREFRAMES_SUCCESS";
export interface LoadingProgramWireframeSuccess {
    accountId: string,
    projectName: string,
    initialWireframes: any,
    dataElements: any,
    logicData: Omit<WireframeLogic, "program" | "story">,
    programLogic: WireframeProgramLogic,
    storiesLogic: WireframeStoriesLogic,
    narrations: LoadedNarration[],
    stage: string,
    version: string,
    calculateDeriveCycles
}
export const loadingProgramWireframeSuccess = createAction(LOADING_PROGRAM_WIREFRAMES_SUCCESS, function(
    accountId: string,
    projectName: string,
    initialWireframes: any,
    dataElements: DataElement[],
    logicData: Omit<WireframeLogic, "program" | "story">,
    programLogic: WireframeProgramLogic,
    storiesLogic: WireframeStoriesLogic,
    narrations: LoadedNarration[],
    stage: string,
    version: string,
    calculateDeriveCycles: boolean
): LoadingProgramWireframeSuccess {
    return { accountId, projectName, initialWireframes, dataElements, logicData, programLogic, storiesLogic, narrations, stage, version, calculateDeriveCycles };
}, undefined);

export const DELETING_PROJECT_WIREFRAMES_INPUT_LOGICS_SUCCESS = "DELETING_PROJECT_WIREFRAMES_INPUT_LOGICS_SUCCESS";
export const deletingProjectWireFramesInputLogicSuccess = createAction(DELETING_PROJECT_WIREFRAMES_INPUT_LOGICS_SUCCESS, function(accountId, projectName, sceneId, logicName) {
    return { accountId, projectName, sceneId, logicName };
});

export const DELETING_PROJECT_MASTER_ANIMATION_GUIDELINE_SUCCESS = "DELETING_PROJECT_MASTER_ANIMATION_GUIDELINE_SUCCESS";
export const deleteMasterAnimationGuidelineSuccess = createAction(DELETING_PROJECT_MASTER_ANIMATION_GUIDELINE_SUCCESS, function(projectName: string, animationName: string) {
    return { projectName, animationName };
});

export const CREATE_PROJECT_MASTER_ANIMATION_GUIDELINE_SUCCESS = "CREATE_PROJECT_MASTER_ANIMATION_GUIDELINE_SUCCESS";
export const createMasterAnimationGuidelineSuccess = createAction(CREATE_PROJECT_MASTER_ANIMATION_GUIDELINE_SUCCESS, function(projectName: string, animationGuideline: Parameter) {
    return { projectName, animationGuideline };
});

export const RENAME_PROJECT_MASTER_ANIMATION_GUIDELINE_SUCCESS = "RENAME_PROJECT_MASTER_ANIMATION_GUIDELINE_SUCCESS";
export const renameMasterAnimationGuidelineSuccess = createAction(RENAME_PROJECT_MASTER_ANIMATION_GUIDELINE_SUCCESS,
    function(projectName: string, updatedAnimationGuidelineIndex: number, updatedAnimationGuideline:Partial<Parameter>) {
        return { projectName, updatedAnimationGuidelineIndex, updatedAnimationGuideline
        };
    });

export const ADDING_PROJECT_WIREFRAMES_PRESET_SUCCESS = "ADDING_PROJECT_WIREFRAMES_PRESET_SUCCESS";
export const AddingProjectWireframesPresetSuccess = createAction(ADDING_PROJECT_WIREFRAMES_PRESET_SUCCESS, function(accountId, projectName, preset) {
    return { accountId, projectName, preset };
});

export const CHANGING_PROJECT_WIREFRAMES_PRESET_SUCCESS = "CHANGING_PROJECT_WIREFRAMES_PRESET_SUCCESS";
export const changingProjectWireframesPresetSuccess = createAction(CHANGING_PROJECT_WIREFRAMES_PRESET_SUCCESS, function(accountId, projectName, presetId, presetData) {
    return { accountId, projectName, presetId, presetData };
});

export const DELETING_PROJECT_WIREFRAMES_PRESET_SUCCESS = "DELETING_PROJECT_WIREFRAMES_PRESET_SUCCESS";
export const deletingProjectWireFramesPresetSuccess = createAction(DELETING_PROJECT_WIREFRAMES_PRESET_SUCCESS, function(accountId, projectName, presetId) {
    return { accountId, projectName, presetId };
});

export const LOADING_PROJECT_WIREFRAMES_PRESETS_SUCCESS = "LOADING_PROJECT_WIREFRAMES_PRESETS_SUCCESS";
export const loadingProjectWireframesPresetsSuccess = createAction(LOADING_PROJECT_WIREFRAMES_PRESETS_SUCCESS,
    function(accountId: string, projectName: string, presets: PresetMap) {
        return { accountId, projectName, presets };
    });

export const SET_CUSTOM_ANALYTICS_ORDER_SUCCESS = "SET_CUSTOM_ANALYTICS_ORDER_SUCCESS";
export const setCustomAnalyticsOrderSuccess = createAction(SET_CUSTOM_ANALYTICS_ORDER_SUCCESS, function(accountId, projectName, analyticsOrder, updated: string) {
    return { accountId, projectName, analyticsOrder, updated };
});

export const SET_CUSTOM_ANALYTIC_FIELD_SUCCESS = "SET_CUSTOM_ANALYTIC_FIELD_SUCCESS";
export const setCustomAnalyticFieldSuccess = createAction(SET_CUSTOM_ANALYTIC_FIELD_SUCCESS, function(accountId, projectName, analyticFieldUpdates: Partial<CustomAnalyticField>) {
    return { accountId, projectName, analyticFieldUpdates };
});

export const ADD_CUSTOM_ANALYTIC_FIELD_SUCCESS = "ADD_CUSTOM_ANALYTIC_FIELD_SUCCESS";
export const addCustomAnalyticFieldSuccess = createAction(ADD_CUSTOM_ANALYTIC_FIELD_SUCCESS, function(accountId, projectName, analyticField: CustomAnalyticField) {
    return { accountId, projectName, analyticField };
});

export const DELETE_CUSTOM_ANALYTIC_FIELD_SUCCESS = "DELETE_CUSTOM_ANALYTIC_FIELD_SUCCESS";
export const deleteCustomAnalyticFieldSuccess = createAction(DELETE_CUSTOM_ANALYTIC_FIELD_SUCCESS, function(accountId, projectName, analyticFieldId: string) {
    return { accountId, projectName, analyticFieldId };
});

export const CHANGE_ACTIVE_PRESET = "CHANGE_ACTIVE_PRESET";
export const changeActivePreset = createAction(CHANGE_ACTIVE_PRESET, function(presetId) {
    return { presetId };
});

export const UPDATE_PROJECT_MASTER_SUCCESS = "UPDATE_PROJECT_MASTER_SUCCESS";
export const updateProjectMasterDataSuccess = createAction(UPDATE_PROJECT_MASTER_SUCCESS, (projectName, masterData) => ({
    projectName,
    masterData
}));

export const ADDING_SURVEY_SUCCESS = "ADDING_SURVEY_SUCCESS";
export const addingSurveySuccess = createAction(ADDING_SURVEY_SUCCESS, function(accountId, projectName, surveyId, surveyData) {
    return { accountId, projectName, surveyId, surveyData };
});

export const UPDATING_SURVEY_SUCCESS = "UPDATING_SURVEY_SUCCESS";
export const updatingSurveySuccess = createAction(UPDATING_SURVEY_SUCCESS, function(accountId, projectName, surveyId, surveyData) {
    return { accountId, projectName, surveyId, surveyData };
});

export const DELETING_SURVEY_SUCCESS = "DELETING_SURVEY_SUCCESS";
export const deletingSurveySuccess = createAction(DELETING_SURVEY_SUCCESS, function(accountId, projectName, surveyId) {
    return { accountId, projectName, surveyId };
});

export const LOADING_SURVEYS_SUCCESS = "LOADING_SURVEYS_SUCCESS";
export const loadingSurveysSuccess = createAction(LOADING_SURVEYS_SUCCESS, function(accountId, projectName, surveys) {
    return { accountId, projectName, surveys };
});

export const UPDATE_NARRATION_POSTGRES_ID_SUCCESS = "UPDATE_NARRATION_POSTGRES_ID_SUCCESS";
export const updateNarrationPostgresIdSuccess = createAction(UPDATE_NARRATION_POSTGRES_ID_SUCCESS, (accountId, projectName, narrationId, postgresId, isPostgresOnly: boolean) => {
    return { accountId, projectName, narrationId, postgresId, isPostgresOnly };
}, undefined);

export const UPDATE_WIREFRAME_GRAPHQL_PARENT_PROGRAM_VERSION_ID = "UPDATE_WIREFRAME_GRAPHQL_PARENT_PROGRAM_VERSION_ID";
export const updateWireframeGraphQLParentProgramVersionId = createAction(UPDATE_WIREFRAME_GRAPHQL_PARENT_PROGRAM_VERSION_ID, function(projectName, graphQLParentProgramVersionId) {
    return { projectName, graphQLParentProgramVersionId };
});

export interface LoadedNarration {
    id: string,
    dbNarration: {
        structure: Narration,
        postgresNarrationId?: string,
        variationData: { [variationKey: string]: VariationData }
        graphQLId: string,
        graphQLUpdated: string
    }
}

/**
 * validates a scene part.
 * We are interested only in prioritized lists with no slots slots that are groups with no placeholders.
 * @param scenePart
 */
const invalidScenePart = function(scenePart: ScenePart) : Boolean {
    return (scenePart.prioritizedLists || []).some((pl: PrioritizedList) => {
        //The scene part is invalid in case we have prioritized list with no slots slots that are groups with no placeholders.
        return !pl.slots || pl.slots.length === 0 ||
        pl.slots.some(slot => {
            return (slot as Group).placeholders && (slot as Group).placeholders.length === 0;
        });
    });
};

let addNarrationWithBody = function(dispatch, getState, services, accountId, projectName, sceneId, scenePartIndex, structure, variationDataMap, postgresNarrationId?) {
    let narrationId;
    let dbStructure = {
        dimensionData: NarrationsModelUtils.dbExtractDimensionDataFromStructure(structure),
        mergeData: NarrationsModelUtils.dbExtractMergeDataFromStructure(structure),
        representativeKey: NarrationsModelUtils.dbExtractRepresentativeKeyFromStructure(structure),
        creativeOverride: NarrationsModelUtils.dbExtractCreativeOverrideFromStructure(structure)
    };

    // This promise returns narration id along with given sceneId and scene part index - for comfort purposes.
    return services.projectNarrationsServices
        .addNarration(accountId, projectName, sceneId, dbStructure, postgresNarrationId)
        .then((narrId) => {
            let dbVariationDataUpdates = NarrationsModelUtils.dbExtractVariationDataUpdates(variationDataMap.toJSON());
            narrationId = narrId;
            return services.projectNarrationsServices.storeVariationData(accountId, projectName, sceneId, narrationId, dbVariationDataUpdates);
        })
        .then(() => {
            dispatch(addNarrationSuccess(accountId, projectName, sceneId, narrationId, structure, variationDataMap, postgresNarrationId));
            return { narrationId, sceneId, scenePartIndex };
        });
};

const getNarrationPostgresId = (projectName: string, state, narrationId: string): string => {
    const wireframes = StateReaderUtils.getWireFrame(state, projectName);
    return StateReaderUtils.getNarrationPostgresId(wireframes, narrationId);
};

export const initMasterObj = () => ({
    id: LogicContainers.Master,
    name: "Master",
    sceneParts: []
});

export const initMasterDataObj = () => ({
    placeholders: [],
    parameters: []
});

const loadProjectWireframesTaskName = "Load Project Wireframes";

export const loadProjectWireframesVersionPromise = (services: ThunkServices, dispatch, accountId, projectName, wireframesVersion, stage, version) => {
    return services.projectAssetsServices.getAssetContent(accountId, projectName, wireFramesAssetType, "draft", wireframesVersion).then((wireframeUIAsset) => {
        if (!wireframeUIAsset.scenes[LogicContainers.Master]) {
            wireframeUIAsset.scenes[LogicContainers.Master] = initMasterObj();
            wireframeUIAsset.scenes[LogicContainers.Master].sceneParts[0] = initMasterDataObj();
        }
        if (!wireframeUIAsset.logic[LogicContainers.Master]) {
            wireframeUIAsset.logic[LogicContainers.Master] = {};
        }
        dispatch(loadingProjectWireframesFromUIAsset(accountId, projectName, wireframeUIAsset, stage, version));
    });
};

const loadProjectWireframesVersionTheOldWay = memoizeThunkAction((accountId, projectName, stage, version?, withNarrationRecordings?: boolean, showLoadingSpinner?: boolean) => {
    return (dispatch, getState, services: ThunkServices) => {
        if (showLoadingSpinner) {
            dispatch(setLoading(true, accountId, projectName));
        }

        const wireframesVersion = StateReaderUtils.getWireframesUIVersionFromSnapshot({ state: getState(), projectName, version, stage });

        return loadProjectWireframesVersionPromise(services, dispatch, accountId, projectName, wireframesVersion, stage, version)
            .then(() => {
                const wireframes = StateReaderUtils.getWireFrame(getState(), projectName, stage, version);

                if (wireframes.narrationsVersion > 0 && withNarrationRecordings) {
                    const isPostgresOnly = wireframes.programDynamoMigrationStatus?.mostEntities === GqlClientDynamoMigrationStatus.DONE;
                    if (!isPostgresOnly) {
                        return services.projectAssetsServices.getAllAssets(accountId, projectName, AssetTypes.recording, undefined, undefined, true);
                    }
                }

                return;
            })
            .then(recordingAssets => {
                if (recordingAssets) {
                    //@ts-ignore
                    dispatch(loadingProjectAssetsSuccess(projectName, recordingAssets, !!version));
                }
            })
            .catch((err) => {
                dispatch(reportError(err));
            })
            .finally(() => {
                if (showLoadingSpinner) {
                    dispatch(setLoading(false, accountId, projectName, stage, version));
                }
            });
    };
});

const loadProjectWireframesTheNewWay = memoizeThunkAction((accountId: string, projectName: string, versionId: string, stage?: string, version?: string, showLoadingSpinner?: boolean) => {
    return async (dispatch, getState, services: ThunkServices) => {
        if (showLoadingSpinner) {
            dispatch(setLoading(true, accountId, projectName));
        }

        const { programId } = StateReaderUtils.getBuilderProgramAndDraftVersionIds(getState(), projectName);
        return services.graphQlClient
            .query<GqlClientGetBuilderProgramQuery>({
                fetchPolicy: "no-cache",
                query: GetBuilderProgramDocument,
                variables: {
                    id: programId,
                    versionId: versionId
                }
            })
            .then((gqlResult: FetchResult<GqlClientGetBuilderProgramQuery>) => {
                const program: GetBuilderProgramQueryProgram = gqlResult && gqlResult.data && gqlResult.data.program;
                const wireframes = buildWireframesFromGql(program);
                const dataElements = buildDataElementsFromGql(program as GqlClientProgram);
                const derivedLogic = buildDerivedLogicFromGql(program as GqlClientProgram);
                const analyticsLogic = buildAnalyticsLogicFromGql(program as GqlClientProgram);
                const masterLogic = buildMasterLogicFromGql(program as GqlClientProgram);
                const scenesLogicItems = buildScenesLogicFromGql(program as GqlClientProgram);
                const storyLogic: WireframeStoriesLogic = buildStoriesLogicFromGql(program as GqlClientProgram);
                const programLogic = buildProgramLogicFromGql(program as GqlClientProgram);
                const narrations = buildWireframesNarrationsFromGql(program as GqlClientProgram, false, [], {});

                const allProgramLogicItems: Omit<WireframeLogic, "program" | "story"> = {
                    [LogicContainers.Derived]: derivedLogic,
                    [LogicContainers.Analytics]: analyticsLogic,
                    [LogicContainers.Master]: masterLogic,
                    ...scenesLogicItems
                };

                const calculateDeriveCycles: boolean = !!Object.keys(derivedLogic).length;
                dispatch(loadingProgramWireframeSuccess(accountId, projectName, wireframes, dataElements, allProgramLogicItems,
                    programLogic, storyLogic, narrations, stage, version, calculateDeriveCycles));
            })
            .then(() => {
                dispatch(setForceLoad(false));

            })
            .catch((err) => {
                dispatch(reportError(err));
            })
            .finally(() => {
                if (showLoadingSpinner) {
                    dispatch(setLoading(false, accountId, projectName, stage, version));
                }
            });
    };
});

// Before calling the thunk, please regard the following assumptions:
// 1. projectSummaries was fetched and is on redux
// 2.  project stageSnapshot (state.projects.byName[projectName][stageSnapshot]) and state.snapshots may be missing, but this thunk will do nothing if stage or version were requested.
//    state.snapshots is used to get the wireframesUI in case of old version, or graphQLId in case of new one.
// 3. showLoadingSpinner is ugly as hell, but we need it in the diffActions
export const loadProjectWireframes = (accountId: string, projectName: string, stage?: string, version?: string, triggeredWithForceLoad?: boolean, showLoadingSpinner = true) => {
    return async (dispatch, getState) => {

        // if the wireframes is already on the state and there's no need to force load - do nothing.
        let forceLoad = triggeredWithForceLoad || StateReaderUtils.getForceLoad(getState());
        let wireframes = StateReaderUtils.getWireFrame(getState(), projectName, stage, version);
        if (!forceLoad && wireframes && wireframes.snapshotNumber === version) {
            return Promise.resolve();
        }

        if (!stage && !version) {
            const { programVersionId } = StateReaderUtils.getBuilderProgramAndDraftVersionIds(getState(), projectName);
            return dispatch(loadProjectWireframesTheNewWay(accountId, projectName, programVersionId, undefined, undefined, showLoadingSpinner));
        }

        const project: Program = StateReaderUtils.getProject(getState(), projectName);

        const snapshotId: string = version ?
            StateReaderUtils.getSnapshotIdByNumber(projectName, version) :
            StateReaderUtils.getLatestSnapshotIdForStage(project, stage);

        const versionDetails: Snapshot | undefined = StateReaderUtils.getSnapshot(getState(), { snapshotId });
        if (versionDetails) {
            if (versionDetails.snapshotSource === SnapshotSource.POSTGRES) {
                return dispatch(loadProjectWireframesTheNewWay(accountId, projectName, versionDetails.graphQLId, stage, version, showLoadingSpinner));
            }
            else {
                const wireframesVersion = StateReaderUtils.getWireframesUIVersionFromSnapshot({ state: getState(), projectName, version, stage });
                if (!wireframesVersion) {
                    return Promise.resolve();
                }
                return dispatch(loadProjectWireframesVersionTheOldWay(accountId, projectName, stage, version, true, showLoadingSpinner));
            }
        }
    };
};

export const getDuplicatedSceneName = function(wireframes: any, sceneName: string): string {
    let newSceneName = sceneName + "Copy";
    let allSceneNames = Object.keys(wireframes.scenes).map(key => wireframes.scenes[key].name);

    return getUniqueNameWithRunningSuffix(newSceneName, allSceneNames);
};

export const duplicateScene = function(accountId, projectName, sceneData: Scene) {
    return (dispatch, getState, services: ThunkServices) => {
        let wireframes = StateReaderUtils.getWireFrame(getState(), projectName);
        let newScene: Scene;

        const narrationsVersion = StateReaderUtils.getNarrationsVersion(wireframes);

        services.graphQlClient
            .mutate({
                mutation: CopySceneDocument,
                variables: {
                    input: {
                        sourceId: sceneData.graphQLId,
                        targetName: getDuplicatedSceneName(wireframes, sceneData.name)
                    }
                }
            })
            .then((gqlOutput: FetchResult<GqlClientCopySceneMutation>) => {
                const gqlScene: GqlClientSceneForBuilderFragment = gqlOutput.data.copyScene.targetScene;
                // @ts-ignore
                newScene = buildWireframesSceneFromGql(narrationsVersion, gqlScene);
                if (narrationsVersion > 0) {
                    let loadedNarrations: LoadedNarration[] = [];
                    gqlScene.sceneParts.forEach((part: GqlClientScenePartFragment) => {
                        if (part.narrations.length > 0) {
                            part.narrations.forEach((narr: GqlClientNarration) => {
                                loadedNarrations.push(buildWireframesNarrationFromGql(narr));
                            });
                        }
                    });
                    if (loadedNarrations.length > 0) {
                        dispatch(loadNarrationsSuccess(accountId, projectName, loadedNarrations));
                    }
                }

                // must update scene BEFORE updating logic
                dispatch(addingProjectWireframesSceneSuccess(accountId, projectName, newScene.id, newScene));
                const newSceneLogicItems: WireframeLogicContainer = buildSceneLogicFromGql(gqlScene as GqlClientScene);
                // Update redux
                Object.keys(newSceneLogicItems).forEach((inputName) => {
                    dispatch(updatingProjectWireFramesSceneInputLogicSuccess(accountId, projectName, newScene.id, inputName, newSceneLogicItems[inputName]));
                });
            })
            .catch((err) => {});
    };
};

export const addProjectWireframesScene = function(accountId, projectName, sceneName, cb?) {
    return (dispatch, getState, services: ThunkServices) => {
        services.graphQlClient
            .mutate({
                mutation: CreateSceneWithScenePartDocument,
                variables: {
                    input: {
                        name: sceneName,
                        programVersionId: StateReaderUtils.getBuilderProgramAndDraftVersionIds(getState(), projectName).programVersionId
                    }
                }
            })
            .then((gqlOutput: FetchResult<GqlClientCreateSceneWithScenePartMutation>) => {
                const gqlScene = gqlOutput.data.createSceneWithScenePart.scene;
                const gqlScenePart = gqlScene.sceneParts[0];
                const sceneLocalId: string = getIdFromGqlId(gqlScene.id);
                const sceneData = {
                    name: gqlScene.name,
                    creationTime: gqlScene.created,
                    id: sceneLocalId,
                    sceneParts: [
                        {
                            scenePart: getIdFromGqlId(gqlScenePart.id),
                            placeholders: [],
                            parameters: [],
                            NarrationParts: [],
                            graphQLId: gqlScenePart.id,
                            graphQLUpdated: gqlScenePart.updated
                        }
                    ],
                    graphQLId: gqlScene.id,
                    graphQLUpdated: gqlScene.updated
                };

                dispatch(addingProjectWireframesSceneSuccess(accountId, projectName, sceneData.id, sceneData));
                if (cb) {
                    cb(sceneLocalId);
                }
            })
            .catch(() => {
            });
    };
};

export const editProjectWireframesScene = function(accountId, projectName, sceneId, scene: Scene) {
    return (dispatch, getState, services) => {
        let sceneData = Object.assign({}, scene, { sceneParts: scene.sceneParts.map((scenePartItem) => scenePartItem.scenePart) });

        const isPostgresOnly: boolean = StateReaderUtils.isProgramBulkDynamoMigrationDoneByLegacyId(getState(), projectName, BulkId.MostEntities);
        if (!isPostgresOnly) {
            services.wireframes
                .editProjectWireframesScene(accountId, projectName, sceneId, sceneData)
                .then(() => {
                    dispatch(changingProjectWireframesSceneSuccess(accountId, projectName, sceneId, scene));
                })
                .then(() => {
                    const { programVersionId } = StateReaderUtils.getBuilderProgramAndDraftVersionIds(getState(), projectName);

                    // Graph QL Update
                    services.graphQlClient
                        .mutate({
                            mutation: UpdateBuildersSceneDocument,
                            variables: {
                                input: {
                                    id: `${programVersionId}|${sceneId}`,
                                    updated: IGNORE_UPDATED,
                                    name: scene.name,
                                    scenePartOrder: scene.sceneParts.map((scenePart) => `${programVersionId}|${sceneId}|${scenePart.postgresScenePartId || scenePart.scenePart}`)
                                },
                                dryRun: false,
                                [IGNORE_SERVER_ERRORS]: true
                            }
                        })
                        .catch((err) => {
                        });
                })
                .catch((err) => dispatch(reportError(err)));
        }
        else {
            services.graphQlClient
                .mutate({
                    mutation: UpdateBuildersSceneDocument,
                    variables: {
                        input: {
                            id: scene.graphQLId,
                            updated: IGNORE_UPDATED,
                            name: scene.name,
                            scenePartOrder: scene.sceneParts.map((scenePart) => scenePart.graphQLId)
                        },
                        dryRun: false
                    }
                })
                .then((gqlOutput: FetchResult<GqlClientUpdateBuildersSceneMutation>) => {
                    if (gqlOutput.data.updateScene.__typename === "UpdateSceneOutputSuccess") {
                        const gqlScene = gqlOutput.data.updateScene.scene;
                        dispatch(changingProjectWireframesSceneSuccess(accountId, projectName, sceneId, { ...scene, graphQLId: gqlScene.id, graphQLUpdated: gqlScene.updated }));
                    }
                })
                .catch((err) => {});
        }
    };
};


export const setProjectWireframesScenePart = function(accountId, projectName, sceneId, scenePartId, scenePartData: ScenePart, scenePartIndex, isUpdateRelevantToNarrationPart: boolean, cb?) {
    return (dispatch, getState, services: ThunkServices) => {
        if (invalidScenePart(scenePartData)) {

            const currentScenePart: ScenePart = StateReaderUtils.getProjectWireframesScenePart(StateReaderUtils.getWireFrame(getState(), projectName), sceneId, scenePartIndex);

            reportErrorToSplunk("Trying to update invalid scene part", {
                metadata: {
                    scenePartId,
                    scenePartData,
                    scenePartBeforeUpdate: currentScenePart
                },
                crashed: false
            });
        }
        const wireframes = StateReaderUtils.getWireFrame(getState(), projectName);
        const narrationsVersion = StateReaderUtils.getNarrationsVersion(wireframes);

        const dispatchChangingProjectWireframesScenePartsSuccess = (scenePart: ScenePart) => {
            dispatch(ChangingProjectWireframesScenePartsSuccess(accountId, projectName, sceneId, scenePart, scenePartIndex));
            if (typeof cb === "function") {
                cb();
            }
        };

        if (!isUpdateRelevantToNarrationPart) {
            return services.graphQlClient
                .mutate<GqlClientUpdateScenePartWithAllPlaceholdersMutation>({
                    mutation: UpdateScenePartWithAllPlaceholdersDocument,
                    variables: {
                        input: {
                            id: scenePartData.graphQLId,
                            updated: IGNORE_UPDATED,
                            scenePartData: scenePartData
                        },
                        dryRun: false
                    }
                })
                .then((gqlOutput) => {
                    if (gqlOutput.data.updateScenePartWithAllPlaceholders.__typename === "UpdateScenePartWithAllPlaceholdersOutputSuccess") {
                        const gqlScenePart = gqlOutput.data.updateScenePartWithAllPlaceholders.scenePart;
                        dispatchChangingProjectWireframesScenePartsSuccess({
                            ...scenePartData,
                            graphQLUpdated: gqlScenePart.updated
                        });
                    }
                })
                .catch(() => {
                });
        }
        else if (narrationsVersion <= 0) {
            return services.graphQlClient.mutate<GqlClientUpdateScenePartMutation>({
                mutation: UpdateScenePartDocument,
                variables: {
                    input: {
                        id: scenePartData.graphQLId,
                        updated: IGNORE_UPDATED,
                        oldNarrationData: scenePartData.NarrationParts
                    },
                    dryRun: false
                }
            })
                .then((gqlOutput) => {
                    if (gqlOutput.data.updateScenePart.__typename === "UpdateScenePartOutputSuccess") {
                        const gqlScenePart = gqlOutput.data.updateScenePart.scenePart;
                        dispatchChangingProjectWireframesScenePartsSuccess({
                            ...scenePartData,
                            graphQLUpdated: gqlScenePart.updated
                        });
                    }
                })
                .catch(() => {
                });
        }
    };
};

export const moveNarrationPartUpOrDown = function(accountId, projectName, sceneId, scenePartIndex, narrationPartIndex, direction) {
    return (dispatch, getState, services) => {
        const isPostgresOnly: boolean = StateReaderUtils.isProgramBulkDynamoMigrationDoneByLegacyId(getState(), projectName, BulkId.MostEntities);
        let wireframes = StateReaderUtils.getWireFrame(getState(), projectName);
        let scene: Scene = StateReaderUtils.getProjectWireframesScene(wireframes, sceneId);
        let scenePart: ScenePart = scene.sceneParts[scenePartIndex];
        let narrationPartData = scenePart.NarrationParts[narrationPartIndex];
        let isUp = direction === "UP";
        let narrationIndexIsOnTheEdge = isUp ? narrationPartIndex === 0 : narrationPartIndex === scenePart.NarrationParts.length - 1;
        let scenePartIndexIsOnTheEdge = isUp ? scenePartIndex === 0 : scenePartIndex === scene.sceneParts.length - 1;
        let linkedNarrationIndex = isUp ? narrationPartIndex - 1 : narrationPartIndex + 1;
        let linkedScenePartIndex = isUp ? scenePartIndex - 1 : scenePartIndex + 1;
        let addNarrationToArr = (scenePart, narrationPart) => {
            return isUp ? [...scenePart.NarrationParts, narrationPart] : [narrationPart, ...scenePart.NarrationParts];
        };
        let removeNarrationFromArr = (scenePart) => {
            return isUp ? scenePart.NarrationParts.slice(1) : scenePart.NarrationParts.slice(0, scenePart.NarrationParts.length - 1);
        };

        if (!narrationIndexIsOnTheEdge) {
            let newNarArr = [...scenePart.NarrationParts];
            const linkedNarrationData = newNarArr[linkedNarrationIndex];
            newNarArr[linkedNarrationIndex] = narrationPartData;
            newNarArr[narrationPartIndex] = linkedNarrationData;

            let newScenePart = { ...scenePart, NarrationParts: newNarArr };
            if (!isPostgresOnly) {
                dispatch(
                    setProjectWireframesScenePart(accountId, projectName, sceneId, scenePart.scenePart, newScenePart, scenePartIndex, true, () => {
                        // Graph QL Update
                        const { programVersionId } = StateReaderUtils.getBuilderProgramAndDraftVersionIds(getState(), projectName);
                        const postgresNarrationId: string = StateReaderUtils.getNarrationPostgresId(wireframes, narrationPartData.id);
                        const postgresScenePartId: string = scenePart.postgresScenePartId || scenePart.scenePart;

                        services.graphQlClient
                            .mutate({
                                mutation: UpdateNarrationOrderDocument,
                                variables: {
                                    input: {
                                        id: `${programVersionId}|${sceneId}|${postgresScenePartId}|${postgresNarrationId}`,
                                        updated: IGNORE_UPDATED,
                                        sourceScenePartNarrationOrder: newNarArr.map((n) => getNarrationPostgresId(projectName, getState(), n.id))
                                    },
                                    dryRun: false,
                                    [IGNORE_SERVER_ERRORS]: true
                                }
                            })
                            .catch((err) => {
                            });
                    })
                );
            }
            else {
                services.graphQlClient
                    .mutate({
                        mutation: UpdateNarrationOrderDocument,
                        variables: {
                            input: {
                                id: narrationPartData.id,
                                updated: IGNORE_UPDATED,
                                sourceScenePartNarrationOrder: newNarArr.map((n) => getIdFromGqlId(n.id))
                            },
                            dryRun: false
                        }
                    })
                    .then((gqlOutput: FetchResult<GqlClientUpdateNarrationOrderMutation>) => {
                        if (gqlOutput.data.updateNarrationOrder.__typename === "UpdateNarrationOrderSuccess") {
                            let graphQLUpdated = gqlOutput.data.updateNarrationOrder.originalScenePart.updated;
                            newScenePart = { ...newScenePart, graphQLUpdated };
                            dispatch(ChangingProjectWireframesScenePartsSuccess(accountId, projectName, sceneId, newScenePart, scenePartIndex));
                        }
                    })
                    .catch((err) => {
                    });
            }
        }
        else if (!scenePartIndexIsOnTheEdge) {
            if (!isPostgresOnly) {
                let linkedScenePart = scene.sceneParts[linkedScenePartIndex];
                let newLinkedNarArr = addNarrationToArr(linkedScenePart, narrationPartData);
                let newLinkedScenePart = { ...linkedScenePart, NarrationParts: newLinkedNarArr };
                let newOriginalArr;

                let updatedLinkedScenePart: ScenePart;
                services.wireframes
                    .setProjectWireframesScenePart(accountId, projectName, sceneId, linkedScenePart.scenePart, newLinkedScenePart)
                    .then((updatedScenePart) => {
                        updatedLinkedScenePart = updatedScenePart;
                        newOriginalArr = removeNarrationFromArr(scenePart);
                        let newScenePart = { ...scenePart, NarrationParts: newOriginalArr };
                        return services.wireframes.setProjectWireframesScenePart(accountId, projectName, sceneId, scenePart.scenePart, newScenePart);
                    })
                    .then((updatedScenePart) => {
                        let bulkUpdates = {
                            [sceneId + "/" + scenePart.scenePart]: updatedScenePart,
                            [sceneId + "/" + linkedScenePart.scenePart]: updatedLinkedScenePart
                        };
                        dispatch(ChangingBulkScenePartsSuccess(accountId, projectName, bulkUpdates));
                    })
                    .then(() => {
                        // Graph QL Update
                        const { programVersionId } = StateReaderUtils.getBuilderProgramAndDraftVersionIds(getState(), projectName);
                        const postgresNarrationId = StateReaderUtils.getNarrationPostgresId(wireframes, narrationPartData.id);
                        const postgresScenePartId: string = scenePart.postgresScenePartId || scenePart.scenePart;

                        services.projectNarrationsServices.updateNarrationPostgresId(accountId, projectName, sceneId, narrationPartData.id, uuid()).then((narr: Narration) => {
                            dispatch(updateNarrationPostgresIdSuccess(accountId, projectName, narrationPartData.id, narr.postgresNarrationId, false));

                            services.graphQlClient
                                .mutate({
                                    mutation: UpdateNarrationOrderDocument,
                                    variables: {
                                        input: {
                                            id: `${programVersionId}|${sceneId}|${postgresScenePartId}|${postgresNarrationId}`,
                                            updated: IGNORE_UPDATED,
                                            sourceScenePartNarrationOrder: newOriginalArr.map((n) => getNarrationPostgresId(projectName, getState(), n.id)),
                                            targetScenePartNarrationOrder: newLinkedNarArr.map((n) => getNarrationPostgresId(projectName, getState(), n.id)),
                                            targetScenePartId: updatedLinkedScenePart.postgresScenePartId || updatedLinkedScenePart.scenePart,
                                            newNarrationId: narr.postgresNarrationId
                                        },
                                        dryRun: false,
                                        [IGNORE_SERVER_ERRORS]: true
                                    }
                                })
                                .catch((err) => {
                                });
                        });
                    })
                    .catch((err) => {
                        dispatch(reportError(err));
                    });
            }
            else {
                const newNarrationId = uuid();
                const { programVersionId } = StateReaderUtils.getBuilderProgramAndDraftVersionIds(getState(), projectName);
                let linkedScenePart = scene.sceneParts[linkedScenePartIndex];
                let newNarrationPartData = { ...narrationPartData, id: `${programVersionId}|${sceneId}|${linkedScenePart.scenePart}|${newNarrationId}` };
                let newLinkedNarArr = addNarrationToArr(linkedScenePart, newNarrationPartData);
                let newOriginalArr = removeNarrationFromArr(scenePart);

                services.graphQlClient
                    .mutate({
                        mutation: UpdateNarrationOrderDocument,
                        variables: {
                            input: {
                                id: narrationPartData.id,
                                updated: IGNORE_UPDATED,
                                sourceScenePartNarrationOrder: newOriginalArr.map((n) => getIdFromGqlId(n.id)),
                                targetScenePartNarrationOrder: newLinkedNarArr.map((n) => getIdFromGqlId(n.id)),
                                targetScenePartId: linkedScenePart.scenePart,
                                newNarrationId: newNarrationId
                            },
                            dryRun: false
                        }
                    })
                    .then((gqlOutput: FetchResult<GqlClientUpdateNarrationOrderMutation>) => {
                        if (gqlOutput.data.updateNarrationOrder.__typename === "UpdateNarrationOrderSuccess") {
                            let originalUpdated = gqlOutput.data.updateNarrationOrder.originalScenePart.updated;
                            let linkedUpdated = gqlOutput.data.updateNarrationOrder.targetScenePart.updated;
                            let newScenePart = { ...scenePart, NarrationParts: newOriginalArr, graphQLUpdated: originalUpdated };
                            let newLinkedScenePart = { ...linkedScenePart, NarrationParts: newLinkedNarArr, graphQLUpdated: linkedUpdated };
                            let bulkUpdates = {
                                [sceneId + "/" + newScenePart.scenePart]: newScenePart,
                                [sceneId + "/" + newLinkedScenePart.scenePart]: newLinkedScenePart
                            };
                            dispatch(updateNarrationPostgresIdSuccess(accountId, projectName, narrationPartData.id, newNarrationPartData.id, true));
                            dispatch(ChangingBulkScenePartsSuccess(accountId, projectName, bulkUpdates));
                        }
                    })
                    .catch(() => {});
            }
        }
    };
};

export const addProjectWireframesScenePart = function(accountId, projectName, sceneId, scenePartData: ScenePart, duplication?: boolean, cb?) {
    return (dispatch, getState, services) => {
        let dynamoScenePart;

        const dispatchAddScenePart = (scenePart: ScenePart) => {
            dispatch(AddingProjectWireframesScenePartsSuccess(accountId, projectName, sceneId, scenePart));

            if (typeof cb === "function") {
                cb(scenePart);
            }
        };

        const { programVersionId } = StateReaderUtils.getBuilderProgramAndDraftVersionIds(getState(), projectName);
        const isPostgresOnly: boolean = StateReaderUtils.isProgramBulkDynamoMigrationDoneByLegacyId(getState(), projectName, BulkId.MostEntities);
        if (!isPostgresOnly) {
            services.wireframes
                .addProjectWireframesScenePart(accountId, projectName, sceneId, scenePartData)
                .then((scenePart) => {
                    dynamoScenePart = scenePart;
                    dispatchAddScenePart(scenePart);
                })
                .then(() => {
                    // Graph QL create
                    if (!duplication) {
                        services.graphQlClient
                            .mutate({
                                mutation: CreateScenePartDocument,
                                variables: {
                                    input: {
                                        sceneId: `${programVersionId}|${sceneId}`,
                                        legacyId: dynamoScenePart.scenePart
                                    },
                                    [IGNORE_SERVER_ERRORS]: true
                                }
                            })
                            .catch((err) => {
                            });
                    }
                })
                .catch((err) => {
                    dispatch(reportError(err));
                });
        }
        else {
            if (duplication) {
                dispatchAddScenePart(scenePartData);
            }
            else {
                services.graphQlClient
                    .mutate({
                        mutation: CreateScenePartDocument,
                        variables: {
                            input: {
                                sceneId: `${programVersionId}|${sceneId}`
                            }
                        }
                    })
                    .then((gqlOutput: FetchResult<GqlClientCreateScenePartMutation>) => {
                        const gqlScenePart = gqlOutput.data.createScenePart.scenePart;
                        const scenePartLocalId: string = getIdFromGqlId(gqlScenePart.id);

                        dispatchAddScenePart({
                            graphQLId: gqlScenePart.id,
                            graphQLUpdated: gqlScenePart.updated,
                            NarrationParts: [],
                            placeholders: [],
                            parameters: [],
                            scenePart: scenePartLocalId
                        });
                    })
                    .catch((err) => {
                    });
            }
        }
    };
};

export const duplicateProjectWireframesScenePart = function(accountId, projectName, sceneId, scenePartIndex, scenePartId) {
    return (dispatch, getState, services) => {
        const wireframes = StateReaderUtils.getWireFrame(getState(), projectName);
        const narrationsVersion = StateReaderUtils.getNarrationsVersion(wireframes);
        const scenePartData: ScenePart = StateReaderUtils.getProjectWireframesScenePart(wireframes, sceneId, scenePartIndex);
        let newScenePartData = deepCloneObj(scenePartData);
        let newNarrationIds = [];

        delete newScenePartData.storyboardFrameThumbnailLocation;

        const isPostgresOnly: boolean = StateReaderUtils.isProgramBulkDynamoMigrationDoneByLegacyId(getState(), projectName, BulkId.MostEntities);
        if (!isPostgresOnly) {
            let promiseChain = Promise.resolve();
            if (narrationsVersion > 0) {
                promiseChain = scenePartData.NarrationParts.reduce((promiseChain, narrationPart) => {
                    let narrations = wireframes.narrations;
                    let structure = narrations.byId[narrationPart.id].structure;
                    let variationDataMap = narrations.byId[narrationPart.id].variationDataMap;

                    let postgresNarrationId: string = StateReaderUtils.getNarrationPostgresId(wireframes, narrationPart.id) || narrationPart.id;
                    return promiseChain.then((answer: { narrationId: string; sceneId: string; scenePartIndex: number }) => {
                        if (answer) {
                            newNarrationIds.push(answer.narrationId);
                        }
                        return addNarrationWithBody(dispatch, getState, services, accountId, projectName, sceneId, undefined, structure, variationDataMap, postgresNarrationId);
                    });
                }, Promise.resolve(null));
                promiseChain.then((lastAnswer: any) => {
                    if (lastAnswer) {
                        newNarrationIds.push(lastAnswer.narrationId);
                        newScenePartData.NarrationParts = newNarrationIds.map((narrationId) => ({ id: narrationId }));
                    }
                });
            }
            promiseChain
                .then(() => {
                    dispatch(
                        addProjectWireframesScenePart(accountId, projectName, sceneId, newScenePartData, true, (newScenePart: ScenePart) => {
                            // Graph QL Copy
                            const { programVersionId } = StateReaderUtils.getBuilderProgramAndDraftVersionIds(getState(), projectName);
                            const postgresScenePartId: string = scenePartData.postgresScenePartId || scenePartId;

                            services.graphQlClient
                                .mutate({
                                    mutation: CopyScenePartDocument,
                                    variables: {
                                        input: {
                                            sourceId: `${programVersionId}|${sceneId}|${postgresScenePartId}`
                                        },
                                        legacyId: newScenePart.scenePart,
                                        [IGNORE_SERVER_ERRORS]: true
                                    }
                                })
                                .catch((err) => {
                                });
                        })
                    );
                })
                .catch((err) => dispatch(reportError(err)));
        }
        else {
            services.graphQlClient
                .mutate({
                    mutation: CopyScenePartDocument,
                    variables: {
                        input: {
                            sourceId: scenePartData.graphQLId
                        }
                    }
                })
                .then((gqlOutput: FetchResult<GqlClientCopyScenePartMutation>) => {
                    const gqlScenePart: Pick<GqlClientScenePart, "id" | "updated"> & GqlClientScenePartNarrationsFragment = gqlOutput.data.copyScenePart.targetScenePart;
                    const scenePartData = { ...newScenePartData, scenePart: getIdFromGqlId(gqlScenePart.id), graphQLId: gqlScenePart.id, graphQLUpdated: gqlScenePart.updated };
                    if (narrationsVersion > 0 && gqlScenePart.narrations.length > 0) {
                        let loadedNarrations: LoadedNarration[] = [];
                        scenePartData.NarrationParts = buildWireframesNarrationsPartFromGql(gqlScenePart.narrations, gqlScenePart.narrationPartOrder);
                        gqlScenePart.narrations.forEach((narr: GqlClientNarration) => {
                            loadedNarrations.push(buildWireframesNarrationFromGql(narr));
                        });
                        if (loadedNarrations.length > 0) {
                            dispatch(loadNarrationsSuccess(accountId, projectName, loadedNarrations));
                        }
                    }

                    dispatch(addProjectWireframesScenePart(accountId, projectName, sceneId, scenePartData, true));
                })
                .catch((err) => {
                });
        }
    };
};

export const deleteProjectWireframesScenePart = function(accountId, projectName, sceneId, scenePartId) {
    return (dispatch, getState, services) => {
        const wireframes = StateReaderUtils.getWireFrame(getState(), projectName);
        const narrationsVersion = StateReaderUtils.getNarrationsVersion(wireframes);
        let scenePart = StateReaderUtils.getProjectWireframesScenePartById(wireframes, sceneId, scenePartId);
        let narrationIds = [];

        if (narrationsVersion > 0) {
            scenePart[placeholderType.narration].forEach((narration) => narrationIds.push(narration.id));
        }

        const isPostgresOnly: boolean = StateReaderUtils.isProgramBulkDynamoMigrationDoneByLegacyId(getState(), projectName, BulkId.MostEntities);
        if (!isPostgresOnly) {
            services.wireframes
                .deleteProjectWireframesScenePart(accountId, projectName, sceneId, scenePartId)
                .then(() => {
                    dispatch(deletingProjectWireframesScenePartsSuccess(accountId, projectName, sceneId, scenePartId));
                })
                .then(() => {
                    let deleteNarrationPromises = narrationIds.map((id) => services.projectNarrationsServices.deleteNarration(accountId, projectName, sceneId, id));
                    return Promise.all(deleteNarrationPromises);
                })
                .then(() => {
                    narrationIds.forEach((id) => {
                        dispatch(deleteNarrationSuccess(accountId, projectName, sceneId, id));
                    });
                })
                .then(() => {
                    const { programVersionId } = StateReaderUtils.getBuilderProgramAndDraftVersionIds(getState(), projectName);
                    const postgresScenePartId: string = scenePart.postgresScenePartId || scenePartId;

                    // Graph QL Delete
                    services.graphQlClient
                        .mutate({
                            mutation: DeleteScenePartDocument,
                            variables: {
                                input: {
                                    id: `${programVersionId}|${sceneId}|${postgresScenePartId}`
                                },
                                [IGNORE_SERVER_ERRORS]: true
                            }
                        })
                        .catch((err) => {
                        });
                })
                .catch((err) => dispatch(reportError(err)));
        }
        else {
            services.graphQlClient
                .mutate({
                    mutation: DeleteScenePartDocument,
                    variables: {
                        input: {
                            id: scenePart.graphQLId
                        }
                    }
                })
                .then(() => {
                    dispatch(deletingProjectWireframesScenePartsSuccess(accountId, projectName, sceneId, scenePartId));
                    narrationIds.forEach((id) => {
                        dispatch(deleteNarrationSuccess(accountId, projectName, sceneId, id));
                    });
                })
                .catch((err) => {
                });
        }
    };
};

export const deleteProjectWireframesScene = function(accountId, projectName, sceneId) {
    return (dispatch, getState, services) => {
        const wireframes = StateReaderUtils.getWireFrame(getState(), projectName);
        const narrationsVersion = StateReaderUtils.getNarrationsVersion(wireframes);
        let scene = StateReaderUtils.getProjectWireframesScene(wireframes, sceneId);
        let narrationIds = [];

        if (narrationsVersion > 0) {
            scene.sceneParts.forEach((part) => {
                part[placeholderType.narration].forEach((narration) => {
                    narrationIds.push(narration.id);
                });
            });
        }

        const isPostgresOnly: boolean = StateReaderUtils.isProgramBulkDynamoMigrationDoneByLegacyId(getState(), projectName, BulkId.MostEntities);
        if (!isPostgresOnly) {
            services.wireframes
                .deleteProjectWireframesScene(accountId, projectName, sceneId)
                .then(() => {
                    dispatch(deletingProjectWireframesSceneSuccess(accountId, projectName, sceneId));
                })
                .then(() => {
                    let deleteNarrationPromises = narrationIds.map((id) => services.projectNarrationsServices.deleteNarration(accountId, projectName, sceneId, id));
                    return Promise.all(deleteNarrationPromises);
                })
                .then(() => {
                    narrationIds.forEach((id) => {
                        dispatch(deleteNarrationSuccess(accountId, projectName, sceneId, id));
                    });
                })
                .then(() => {
                    // Graph QL Delete
                    const { programVersionId } = StateReaderUtils.getBuilderProgramAndDraftVersionIds(getState(), projectName);

                    services.graphQlClient
                        .mutate({
                            mutation: DeleteSceneDocument,
                            variables: {
                                input: {
                                    id: `${programVersionId}|${sceneId}`
                                },
                                [IGNORE_SERVER_ERRORS]: true
                            }
                        })
                        .catch((err) => {
                        });
                })
                .catch((err) => dispatch(reportError(err)));
        }
        else {
            services.graphQlClient
                .mutate({
                    mutation: DeleteSceneDocument,
                    variables: {
                        input: {
                            id: scene.graphQLId
                        }
                    }
                })
                .then(() => {
                    dispatch(deletingProjectWireframesSceneSuccess(accountId, projectName, sceneId));
                    narrationIds.forEach((id) => {
                        dispatch(deleteNarrationSuccess(accountId, projectName, sceneId, id));
                    });
                })
                .catch((err) => {
                });
        }
    };
};

//TODO: Refactor to 'setSeceneInputLogic' and  `updatingSceneInputLogicSuccess` maybe??
export const setProjectWireframesSceneInputLogic = function(accountId, projectName, logicContainer, logicOwner, logicData: LogicJSON) {
    return (dispatch, getState, services: ThunkServices) => {
        dispatch(loadingProjectWireFramesSceneInputLogic(accountId, projectName, logicContainer, logicOwner));
        const scene: Scene = StateReaderUtils.getProjectWireframesScene(StateReaderUtils.getWireFrame(getState(), projectName), logicContainer);
        const sceneGqlId: string = scene?.graphQLId;
        let mutation;
        let input: any = {
            id: sceneGqlId,
            updated: IGNORE_UPDATED
        };

        if (logicOwner.endsWith("_animation")) {
            mutation = UpdateBuildersSceneDocument;
            input = { ...input, animationLogic: logicData };
        }
        else if (logicOwner.endsWith("_validation")) {
            mutation = UpdateBuildersSceneDocument;
            input = { ...input, validationLogic: logicData };
        }
        else if (logicOwner.endsWith("_nextScene")) {
            //do nothing
        }
        else if (logicOwner === "descriptive-transcript") {
            mutation = UpdateBuildersSceneDocument;
            input = { ...input, descriptiveTranscriptLogic: logicData };
        }
        else if (logicContainer === LogicContainers.Derived) {
            //do nothing
        }
        else if (logicContainer === LogicContainers.Analytics) {
            //add Analytics mutation?
        }
        else if (logicContainer === LogicContainers.Master) {
            mutation = UpdateMasterAnimationGuidelineDocument;
            const masterAnimationGuideline: Parameter = StateReaderUtils.getProjectMasterAnimationGuidelineByName(getState(), projectName, logicOwner);input = {
                ...input,
                id: masterAnimationGuideline.graphQLId,
                logic: logicData
            };
        }
        else if (isUuid(logicOwner)) {
            // it is priority list or group logic
            input = { ...input, id: `${sceneGqlId}|${logicOwner}`, logic: logicData };
            if (logicData.outputType === LogicType.Prioritized) {
                // it is priority list logic
                mutation = UpdatePrioritizedListLogicDocument;
            }
            else {
                // it is group list logic
                mutation = UpdatePlaceholderGroupLogicDocument;
            }
        }
        else {
            // it is placeholder logic
            mutation = UpdateSceneEntityLogicDocument;
            input = {
                ...input,
                id: `${sceneGqlId}|${MOCK_SCENE_ENTITY_LOGIC_ID}`,
                name: logicOwner,
                logic: logicData
            };
        }
        if (mutation) {
            services.graphQlClient
                .mutate({
                    mutation,
                    variables: {
                        input,
                        dryRun: false
                    }
                })
                .then(() => {
                    //TODO update scene updated or master animation guideline updated.
                    dispatch(updatingProjectWireFramesSceneInputLogicSuccess(accountId, projectName, logicContainer, logicOwner, logicData));
                })
                .catch((err) => {
                });
        }
    };
};

export const deleteProjectWireframesSceneInputLogic = function(accountId, projectName, sceneId, inputName) {
    return (dispatch, getState, services: ThunkServices) => {
        const isPostgresOnly: boolean = StateReaderUtils.isProgramBulkDynamoMigrationDoneByLegacyId(getState(), projectName, BulkId.MostEntities);
        const logicData: LogicJSON = StateReaderUtils.getProjectWireframesSceneInputLogic(getState(), projectName, sceneId, inputName, null, null);
        if (!isPostgresOnly) {
            services.wireframes
                .deleteProjectWireFramesInputLogic(accountId, projectName, sceneId, inputName)
                .then(() => {
                    dispatch(deletingProjectWireFramesInputLogicSuccess(accountId, projectName, sceneId, inputName));
                    // Graph QL Update
                    const { programVersionId } = StateReaderUtils.getBuilderProgramAndDraftVersionIds(getState(), projectName);
                    let mutation;
                    let input: any;

                    if (
                        inputName.endsWith("_animation") ||
                            inputName.endsWith("_validation") ||
                            inputName.endsWith("_nextScene") ||
                            inputName === "descriptive-transcript" ||
                            sceneId === LogicContainers.Derived ||
                            sceneId === LogicContainers.Master ||
                            sceneId === LogicContainers.Analytics
                    ) {
                        //do nothing
                    }
                    else if (isUuid(inputName)) {
                        // it is priority list or group logic
                        input = { id: `${programVersionId}|${sceneId}|${inputName}` };
                        if (logicData.outputType === LogicType.Prioritized) {
                            // it is priority list logic
                            mutation = DeletePrioritizedListLogicDocument;
                        }
                        else {
                            // it is group list logic
                            mutation = DeletePlaceholderGroupLogicDocument;
                        }
                    }
                    else {
                        // it is placeholder logic
                        mutation = DeleteSceneEntityLogicDocument;
                        input = {
                            id: `${programVersionId}|${sceneId}|${MOCK_SCENE_ENTITY_LOGIC_ID}`,
                            name: inputName
                        };
                    }

                    if (mutation) {
                        services.graphQlClient
                            .mutate({
                                mutation,
                                variables: {
                                    input,
                                    [IGNORE_SERVER_ERRORS]: true
                                }
                            })
                            .catch((err) => {
                            });
                    }
                })
                .catch((err) => {
                    dispatch(reportError(err));
                });
        }
        else {
            let mutation;
            let input: any;
            const scene: Scene = StateReaderUtils.getProjectWireframesScene(StateReaderUtils.getWireFrame(getState(), projectName), sceneId);
            if (!(inputName.endsWith("_animation") ||
                inputName.endsWith("_validation") ||
                inputName.endsWith("_nextScene") ||
                inputName === "descriptive-transcript" ||
                sceneId === LogicContainers.Derived ||
                sceneId === LogicContainers.Master ||
                sceneId === LogicContainers.Analytics)) {
                if (isUuid(inputName)) {
                    // it is priority list or group logic
                    input = { id: `${scene.graphQLId}|${inputName}` };
                    if (logicData.outputType === LogicType.Prioritized) {
                        // it is priority list logic
                        mutation = DeletePrioritizedListLogicDocument;
                    }
                    else {
                        // it is group list logic
                        mutation = DeletePlaceholderGroupLogicDocument;
                    }
                }
                else {
                    // it is placeholder logic
                    mutation = DeleteSceneEntityLogicDocument;
                    input = { id: `${scene.graphQLId}`, name: inputName };
                }
            }
            if (mutation) {
                services.graphQlClient
                    .mutate<GqlClientDeleteSceneEntityLogicMutation | GqlClientDeletePlaceholderGroupLogicMutation | GqlClientDeletePrioritizedListLogicMutation>({
                        mutation,
                        variables: {
                            input
                        }
                    })
                    .then((gqlOutput) => {
                        //TODO update scene updated and make sure to update state for logic we didnt send a delete mutation thou this thunk.
                        dispatch(deletingProjectWireFramesInputLogicSuccess(accountId, projectName, sceneId, inputName));
                    })
                    .catch((err) => {
                    });
            }
        }
    };
};

//used in postgres flow only
export const deleteScenePartStoryboardFrame = function(accountId: string, projectName: string, sceneId: string, scenePartId: string, scenePartIndex: number) {
    return function(dispatch, state, services: ThunkServices) {
        const wireframes = StateReaderUtils.getWireFrame(state(), projectName);
        const scenePartData: ScenePart = StateReaderUtils.getProjectWireframesScenePart(wireframes, sceneId, scenePartIndex);
        const gqlScenePartId: string = scenePartData.graphQLId;
        return services.graphQlClient
            .mutate<GqlClientUpdateScenePartMutation>({
                mutation: UpdateScenePartDocument,
                variables: {
                    input: {
                        id: gqlScenePartId,
                        updated: IGNORE_UPDATED,
                        thumbnailLocation: null
                    },
                    dryRun: false
                }
            })
            .then((gqlOutput) => {
                if (gqlOutput.data.updateScenePart.__typename === "UpdateScenePartOutputSuccess") {
                    const gqlScenePart = gqlOutput.data.updateScenePart.scenePart;
                    dispatch(ChangingProjectWireframesScenePartsSuccess(accountId, projectName, sceneId, {
                        ...scenePartData,
                        graphQLUpdated: gqlScenePart.updated
                    }, scenePartIndex));
                }
            })
            .catch((err) => {
            });
    };
};

export const addProjectWireframesScenePartStoryboardFrame = function(accountId, projectName, sceneId, scenePartId, scenePartData, scenePartIndex, storyboardFrameFile) {
    return async function(dispatch, getState, services) {
        let thumbnailLocation;

        const wireframes = StateReaderUtils.getWireFrame(getState(), projectName);
        const data: ScenePart = StateReaderUtils.getProjectWireframesScenePart(wireframes, sceneId, scenePartIndex);
        const postgresScenePartId: string = data.postgresScenePartId || scenePartId;
        const { programVersionId } = StateReaderUtils.getBuilderProgramAndDraftVersionIds(getState(), projectName);
        const isPostgresOnly: boolean = StateReaderUtils.isProgramBulkDynamoMigrationDoneByLegacyId(getState(), projectName, BulkId.MostEntities);
        if (!isPostgresOnly) {
            services.wireframes
                .addProjectWireframesScenePartStoryboardFrame(accountId, projectName, sceneId, scenePartId, storyboardFrameFile, isPostgresOnly)
                .then((scenePart) => {
                    thumbnailLocation = scenePart.storyboardFrameThumbnailLocation;
                    dispatch(addingProjectWireframesScenePartStoryboardFrameSuccess(accountId, projectName, sceneId, scenePartId, scenePart, scenePartIndex));
                })
                .then(() => {
                    // Graph QL Update
                    services.graphQlClient
                        .mutate({
                            mutation: UpdateScenePartDocument,
                            variables: {
                                input: {
                                    id: `${programVersionId}|${sceneId}|${postgresScenePartId}`,
                                    updated: IGNORE_UPDATED,
                                    thumbnailLocation
                                },
                                dryRun: false,
                                [IGNORE_SERVER_ERRORS]: true
                            }
                        })
                        .catch(() => {
                        });
                })
                .catch((err) => dispatch(reportError(err)));
        }
        else {
            const thumbnailLocation = await services.wireframes.addProjectWireframesScenePartStoryboardFrame(accountId, projectName, sceneId, scenePartId, storyboardFrameFile, isPostgresOnly);

            services.graphQlClient
                .mutate({
                    mutation: UpdateScenePartDocument,
                    variables: {
                        input: {
                            id: scenePartData.graphQLId,
                            updated: IGNORE_UPDATED,
                            thumbnailLocation
                        },
                        dryRun: false
                    }
                })
                .then((gqlOutput: FetchResult<GqlClientUpdateScenePartMutation>) => {
                    if (gqlOutput.data.updateScenePart.__typename === "UpdateScenePartOutputSuccess") {
                        const gqlScenePart = gqlOutput.data.updateScenePart.scenePart;
                        dispatch(addingProjectWireframesScenePartStoryboardFrameSuccess(accountId, projectName, sceneId, scenePartId,
                            { ...scenePartData, storyboardFrameThumbnailLocation: thumbnailLocation, graphQLUpdated: gqlScenePart.updated }, scenePartIndex));
                    }
                })
                .catch((err) => {
                });
        }
    };
};

export const addProjectWireframesPreset = (accountId: string, projectName: string, presetData: Preset, cb, isCreative: boolean) => {
    return async (dispatch, getState, services) => {

        // Write to Postgres
        let pgsMutationSuccess;
        let postgresPreset: Preset;
        try {
            const { programVersionId } = StateReaderUtils.getBuilderProgramAndDraftVersionIds(getState(), projectName);
            const programDataElements: DataElement[] = StateReaderUtils.getProjectDataElements(getState(), projectName);
            const { systemElementValues, dataElementValues } = getPresetValuesFromData(presetData.data, programVersionId, programDataElements);

            let mutationVariables: GqlClientCreatePresetMutationVariables = {
                programVersionId,
                input: {
                    name: presetData.name,
                    isCreative: Boolean(isCreative), // 'isCreative' can be undefined
                    dataElementValues,
                    systemElementValues
                }
            };
            if (isCreative) {
                mutationVariables.legacyId = POSTGRES_CREATIVES_PRESET_ID;
            }

            const gqlResult: CreatePresetMutationResult = await services.graphQlClient.mutate({
                mutation: CreatePresetDocument,
                variables: mutationVariables
            }).catch(() => {});

            const createPresetOutput = gqlResult.data.createPreset;
            pgsMutationSuccess = createPresetOutput.result === GqlClientCreatePresetResult.SUCCESS;
            if (pgsMutationSuccess) {
                postgresPreset = {
                    ...presetData,
                    id: isCreative ? CREATIVES_PRESET_ID : getIdFromGqlId(createPresetOutput.preset.id),
                    graphQLId: createPresetOutput.preset.id,
                    graphQLUpdated: createPresetOutput.preset.updated
                };
            }
        }
        catch (err) {
            // ignore error
        }

        if (pgsMutationSuccess) {
            dispatch(AddingProjectWireframesPresetSuccess(accountId, projectName, postgresPreset));
            if (typeof cb === "function") {
                cb();
            }
        }
    };
};

export const editProjectWireframesPreset = (accountId: string, projectName: string, presetId: string, presetData: Partial<Preset>, cb) => {
    return async (dispatch, getState, services: ThunkServices) => {

        // Write to Postgres (not creatives. they are updated)
        try {
            const { programVersionId } = StateReaderUtils.getBuilderProgramAndDraftVersionIds(getState(), projectName);
            const programDataElements: DataElement[] = StateReaderUtils.getProjectDataElements(getState(), projectName);
            const { systemElementValues, dataElementValues } = getPresetValuesFromData(presetData.data, programVersionId, programDataElements);
            const presetGraphQLId: string = StateReaderUtils.getPresetById(getState(), projectName, presetId).graphQLId;

            const mutationVariables: GqlClientUpdatePresetMutationVariables = {
                input: {
                    id: presetGraphQLId,
                    updated: IGNORE_UPDATED,
                    name: presetData.name,
                    dataElementValues,
                    systemElementValues
                }
            };

            const gqlResult = await services.graphQlClient.mutate<GqlClientUpdatePresetMutation>({
                mutation: UpdatePresetDocument,
                variables: mutationVariables
            });

            if (gqlResult.data.updatePreset.__typename === "UpdatePresetOutputSuccess") {
                let postgresPreset: Partial<Preset> = {
                    ...presetData,
                    graphQLId: gqlResult.data.updatePreset.preset.id,
                    graphQLUpdated: gqlResult.data.updatePreset.preset.updated
                };

                dispatch(changingProjectWireframesPresetSuccess(accountId, projectName, presetId, clearObj(postgresPreset)));
                if (typeof cb === "function") {
                    cb();
                }
            }
        }
        catch (err) {
            // ignore error
        }
    };
};

export const deleteProjectWireframesPreset = (accountId: string, projectName: string, presetId: string) => {
    return async (dispatch, getState, services) => {
        // get programId
        let programId: string = StateReaderUtils.getProgramId(getState(), projectName);
        if (!programId) {
            const projectSummaries = await services.projectServices.loadProjectSummaries(accountId);
            programId = StateReaderUtils.getProgramId({ projectSummaries }, projectName);
        }

        const writeBuilderEntitiesPostgresOnly = StateReaderUtils.isProgramBulkDynamoMigrationDone(getState(), programId, BulkId.MostEntities);

        // Write to dynamo if needed
        if (!writeBuilderEntitiesPostgresOnly) {
            try {
                await services.wireframes.deleteProjectWireFramesPreset(accountId, projectName, presetId);
            }
            catch (err) {
                dispatch(reportError(err));
            }
        }

        // Write to Postgres
        let pgsMutationSuccess;
        try {
            const { programVersionId } = StateReaderUtils.getBuilderProgramAndDraftVersionIds(getState(), projectName);
            const presetGraphQLId: string = writeBuilderEntitiesPostgresOnly ?
                StateReaderUtils.getPresetById(getState(), projectName, presetId).graphQLId :
                `${programVersionId}|${presetId}`;
            const mutationVariables: GqlClientDeletePresetMutationVariables & { [IGNORE_SERVER_ERRORS]: boolean } = {
                input: {
                    id: presetGraphQLId
                },
                [IGNORE_SERVER_ERRORS]: !writeBuilderEntitiesPostgresOnly
            };

            const gqlResult: DeletePresetMutationResult = await services.graphQlClient.mutate({
                mutation: DeletePresetDocument,
                variables: mutationVariables
            }).catch(() => {});

            pgsMutationSuccess = gqlResult.data.deletePreset.result === GqlClientDeletePresetResult.SUCCESS;
        }
        catch (err) {
            // ignore error
        }

        if (pgsMutationSuccess || !writeBuilderEntitiesPostgresOnly) {
            dispatch(deletingProjectWireFramesPresetSuccess(accountId, projectName, presetId));
        }
    };
};
// Before calling the thunk, please regard the following assumptions:
// 1. projectSummaries was fetched and is on redux
// 2. project stageSnapshot (state.projects.byName[projectName][stageSnapshot]) and state.snapshots may be missing, but this thunk will do nothing if stage or version were requested.
//    state.snapshots is used to get the graphQLId in case of new one.
export const getProjectWireframesPresets = (accountId, projectName, version: string, stage: string) => {
    return async (dispatch, state, services: ThunkServices) => {
        try {
            const { programId, programVersionId: programDraftVersionId } = StateReaderUtils.getBuilderProgramAndDraftVersionIds(state(), projectName);
            let programVersionId: string;

            // get programVersionId - for version and stage view we will
            if (version || stage) {
                const project: Program = StateReaderUtils.getProject(state(), projectName);
                const snapshotId: string = version ?
                    StateReaderUtils.getSnapshotIdByNumber(projectName, version) :
                    StateReaderUtils.getLatestSnapshotIdForStage(project, stage);

                const versionDetails: Snapshot | undefined = StateReaderUtils.getSnapshot(state(), { snapshotId });
                if (versionDetails) {
                    programVersionId = versionDetails.snapshotSource === SnapshotSource.POSTGRES ? versionDetails.graphQLId : programDraftVersionId;
                }
            }
            else {
                programVersionId = programDraftVersionId;
            }
            const readBuilderEntitiesFromPostgres = StateReaderUtils.isProgramBulkDynamoMigrationDone(state(), programId, BulkId.MostEntities);

            let dynamoPresets: PresetMap;
            let gqlPresets: PresetMap;

            // Need dynamo presets
            if (!readBuilderEntitiesFromPostgres) {
                dynamoPresets = await services.wireframes.getProjectWireframesPreset(accountId, projectName);
            }

            // Need postgres presets
            if (readBuilderEntitiesFromPostgres && programVersionId) {
                const getGqlPresetsResponse: GetProgramPresetsQueryResult = await services.graphQlClient.query({
                    fetchPolicy: "no-cache",
                    query: GetProgramPresetsDocument,
                    variables: {
                        programId: programId,
                        programVersionId: programVersionId
                    }
                }).catch(() => null);
                gqlPresets = convertGqlPresetsResult(getGqlPresetsResponse);
            }
            dispatch(loadingProjectWireframesPresetsSuccess(accountId, projectName, readBuilderEntitiesFromPostgres ? gqlPresets : dynamoPresets));
        }
        catch (err) {
            dispatch(reportError(err));
        }
    };
};

// Before calling the thunk, please regard the following assumptions:
// 1. projectSummaries was fetched and is on redux
// 2. project stageSnapshot (state.projects.byName[projectName][stageSnapshot]) and state.snapshots may be missing, but this thunk will do nothing if stage or version were requested.
//    state.snapshots is used to get the graphQLId in case of new one.
export const getProjectWireframesPresetsWithoutValues = (accountId, projectName, version: string, stage: string) => {
    return async (dispatch, state, services: ThunkServices) => {
        try {
            const { programId, programVersionId: programDraftVersionId } = StateReaderUtils.getBuilderProgramAndDraftVersionIds(state(), projectName);
            let programVersionId: string;

            // get programVersionId - for version and stage view we will
            if (version || stage) {
                const project: Program = StateReaderUtils.getProject(state(), projectName);
                const snapshotId: string = version ?
                    StateReaderUtils.getSnapshotIdByNumber(projectName, version) :
                    StateReaderUtils.getLatestSnapshotIdForStage(project, stage);

                const versionDetails: Snapshot | undefined = StateReaderUtils.getSnapshot(state(), { snapshotId });
                if (versionDetails) {
                    programVersionId = versionDetails.snapshotSource === SnapshotSource.POSTGRES ? versionDetails.graphQLId : programDraftVersionId;
                }
            }
            else {
                programVersionId = programDraftVersionId;
            }

            const getGqlPresetsResponse: GetProgramPresetsWithoutValuesQueryResult = await services.graphQlClient.query({
                fetchPolicy: "no-cache",
                query: GetProgramPresetsWithoutValuesDocument,
                variables: {
                    programId: programId,
                    programVersionId: programVersionId
                }
            }).catch(() => null);
            const existingPresets: PresetMap = state().presets.byName[projectName];
            const gqlPresets: PresetMap = convertGqlPresetsWithoutValuesResult(getGqlPresetsResponse, existingPresets);
            dispatch(loadingProjectWireframesPresetsSuccess(accountId, projectName, gqlPresets));
        }
        catch (err) {
            dispatch(reportError(err));
        }
    };
};

export const getProjectWireframesPresetValues = (accountId, projectName, version: string, stage: string, presetId: string) => {
    return async (dispatch, state, services: ThunkServices) => {
        try {
            const { programId, programVersionId: programDraftVersionId } = StateReaderUtils.getBuilderProgramAndDraftVersionIds(state(), projectName);
            let programVersionId: string;

            // get programVersionId - for version and stage view we will
            if (version || stage) {
                const project: Program = StateReaderUtils.getProject(state(), projectName);
                const snapshotId: string = version ?
                    StateReaderUtils.getSnapshotIdByNumber(projectName, version) :
                    StateReaderUtils.getLatestSnapshotIdForStage(project, stage);

                const versionDetails: Snapshot | undefined = StateReaderUtils.getSnapshot(state(), { snapshotId });
                if (versionDetails) {
                    programVersionId = versionDetails.snapshotSource === SnapshotSource.POSTGRES ? versionDetails.graphQLId : programDraftVersionId;
                }
            }
            else {
                programVersionId = programDraftVersionId;
            }

            const getGqlPresetValuesResponse: GetProgramPresetValuesQueryResult = await services.graphQlClient.query({
                fetchPolicy: "no-cache",
                query: GetProgramPresetValuesDocument,
                variables: {
                    programId,
                    programVersionId,
                    presetId
                }
            }).catch(() => null);
            const existingPresets: PresetMap = state().presets.byName[projectName];
            const gqlPresets: PresetMap = convertGqlPresetValuesResultAndAddToPresetMap(getGqlPresetValuesResponse, existingPresets);
            dispatch(loadingProjectWireframesPresetsSuccess(accountId, projectName, gqlPresets));
        }
        catch (err) {
            dispatch(reportError(err));
        }
    };
};

export const updateCustomAnalyticsOrder = function(accountId: string, projectName: string, analyticsOrder: string[]) {
    return function(dispatch, getState, services: ThunkServices) {

        let programId: string = StateReaderUtils.getProgramId(getState(), projectName);

        let input: GqlClientUpdateProgramInput = {
            id: programId,
            updated: IGNORE_UPDATED,
            customAnalyticsOrder: analyticsOrder
        };

        services.graphQlClient
            .mutate<GqlClientUpdateProgramMutation>({
                mutation: UpdateProgramDocument,
                variables: {
                    input: input,
                    [IGNORE_SERVER_ERRORS]: true
                }
            })
            .then(res => {
                if (res.data.updateProgram.__typename === "UpdateProgramOutputSuccess") {
                    dispatch(setCustomAnalyticsOrderSuccess(accountId, projectName, analyticsOrder, res.data.updateProgram.program.updated));
                }
            }).catch(err => {
                dispatch(reportError(err));
            });
    };
};

export const addCustomField = (accountId: string, projectName: string, fieldName: string, fieldData: LogicJSON) => {
    return function(dispatch, getState, services: ThunkServices) {
        let programId: string = StateReaderUtils.getProgramId(getState(), projectName);

        let input: GqlClientCreateCustomAnalyticFieldInput = {
            name: fieldName,
            logic: fieldData,
            programId: programId
        };

        services.graphQlClient
            .mutate<GqlClientCreateCustomAnalyticFieldMutation>({
                mutation: CreateCustomAnalyticFieldDocument,
                variables: {
                    input: input,
                    [IGNORE_SERVER_ERRORS]: true
                }
            })
            .then((result) => {
                const analyticField: CustomAnalyticField = {
                    id: result.data.createCustomAnalyticField.customAnalyticField.id,
                    name: fieldName,
                    data: fieldData,
                    graphQLUpdated: result.data.createCustomAnalyticField.customAnalyticField.updated
                };
                dispatch(addCustomAnalyticFieldSuccess(accountId, projectName, analyticField));
            })
            .catch((err) => {
                dispatch(reportError(err));
            });
    };
};

export const updateCustomField = (accountId: string, projectName: string, fieldId: string, newName?: string, newData?: LogicJSON) => {
    return async function(dispatch, getState, services: ThunkServices) {

        // Graph QL update
        const program: Program = StateReaderUtils.getProject(getState(), projectName);
        const isProgramPermanentlyMigratedToPostgres = StateReaderUtils.isProgramBulkDynamoMigrationDone(getState(), program.graphQLId, BulkId.MostEntities);

        let gqlAnalyticFieldId: string;

        // get custom analytic field id
        if (isProgramPermanentlyMigratedToPostgres) {
            gqlAnalyticFieldId = fieldId;
        }
        else {
            const program: GqlClientProgram = getState().activeGqlProgram;
            gqlAnalyticFieldId = program.customAnalyticFields.find((field) => field.name === fieldId).id;
        }

        // create GQL input parameters
        let input: GqlClientUpdateCustomAnalyticFieldInput = {
            id: gqlAnalyticFieldId,
            updated: IGNORE_UPDATED,
            logic: newData,
            name: newName
        };

        // mutate
        try {
            const gqlResult: FetchResult<GqlClientUpdateCustomAnalyticFieldMutation> = await services.graphQlClient
                .mutate<GqlClientUpdateCustomAnalyticFieldMutation>({
                    mutation: UpdateCustomAnalyticFieldDocument,
                    variables: {
                        input: input,
                        [IGNORE_SERVER_ERRORS]: true
                    }
                });

            // handle successfull update
            if (gqlResult.data.updateCustomAnalyticField.__typename === "UpdateCustomAnalyticFieldOutputSuccess") {
                if (isProgramPermanentlyMigratedToPostgres) {

                    const analyticFieldUpdates: Partial<CustomAnalyticField> = {
                        id: fieldId,
                        name: newName,
                        data: newData,
                        graphQLUpdated: gqlResult.data.updateCustomAnalyticField.customAnalyticField.updated
                    };
                    Object.keys(analyticFieldUpdates).forEach(key => analyticFieldUpdates[key] === undefined && delete analyticFieldUpdates[key]);

                    dispatch(setCustomAnalyticFieldSuccess(accountId, projectName, analyticFieldUpdates));
                }
                else {
                    // Here we save the mapping from field name in dynamo to field id in PG. If the project is permanently migrated, we don't need this mapping anymore
                    dispatch(loadingGqlProgramSuccess(gqlResult.data.updateCustomAnalyticField.customAnalyticField.program));
                }
            }
        }
        catch (err) {
            if (isProgramPermanentlyMigratedToPostgres) {
                dispatch(reportError(err));
            }
        }
    };
};

export const deleteCustomField = (accountId: string, projectName: string, fieldId: string) => {
    return function(dispatch, getState, services: ThunkServices) {

        // Graph QL update
        const program: Program = StateReaderUtils.getProject(getState(), projectName);
        const isProgramPermanentlyMigratedToPostgres = StateReaderUtils.isProgramBulkDynamoMigrationDone(getState(), program.graphQLId, BulkId.MostEntities);

        let gqlAnalyticFieldId: string;

        // get custom analytic field id
        if (isProgramPermanentlyMigratedToPostgres) {
            gqlAnalyticFieldId = fieldId;
        }
        else {
            const program: GqlClientProgram = getState().activeGqlProgram;
            gqlAnalyticFieldId = program.customAnalyticFields.find((field) => field.name === fieldId).id;
        }

        let input: GqlClientDeleteCustomAnalyticFieldInput = {
            id: gqlAnalyticFieldId
        };

        services.graphQlClient
            .mutate<GqlClientDeleteCustomAnalyticFieldMutation>({
                mutation: DeleteCustomAnalyticFieldDocument,
                variables: {
                    input: input,
                    [IGNORE_SERVER_ERRORS]: true
                }
            })
            .then((result) => {
                if (isProgramPermanentlyMigratedToPostgres) {
                    dispatch(deleteCustomAnalyticFieldSuccess(accountId, projectName, fieldId));
                }
                else {
                    dispatch(loadingGqlProgramSuccess(result.data.deleteCustomAnalyticField.program));
                }
            })
            .catch((err) => {
                if (isProgramPermanentlyMigratedToPostgres) {
                    dispatch(reportError(err));
                }
            });
    };
};

export const duplicateNarration = (accountId, projectName, sceneId, scenePartIndex, narrationPartIndex) => {
    return (dispatch, getState, services) => {
        let wireframes = StateReaderUtils.getWireFrame(getState(), projectName);
        let scenePart = StateReaderUtils.getProjectWireframesScenePart(wireframes, sceneId, scenePartIndex);
        const narrationsVersion = StateReaderUtils.getNarrationsVersion(wireframes);

        if (narrationsVersion > 0) {
            let narrationId = scenePart.NarrationParts[narrationPartIndex].id;
            const isPostgresOnly: boolean = StateReaderUtils.isProgramBulkDynamoMigrationDoneByLegacyId(getState(), projectName, BulkId.MostEntities);
            let structure = wireframes.narrations.byId[narrationId].structure;
            let variationDataMap = wireframes.narrations.byId[narrationId].variationDataMap;
            if (!isPostgresOnly) {
                addNarrationWithBody(dispatch, getState, services, accountId, projectName, sceneId, scenePartIndex, structure, variationDataMap)
                    .then((answer: { narrationId: string; sceneId: string; scenePartIndex: number }) => {
                        let newScenePart = { ...scenePart };
                        newScenePart.NarrationParts.push({ id: answer.narrationId });
                        dispatch(setProjectWireframesScenePart(accountId, projectName, sceneId, newScenePart.scenePart, newScenePart, scenePartIndex, true));

                        return answer.narrationId;
                    })
                    .then((newNarrationId: string) => {
                        // Graph QL update
                        let programSummary: ProgramSummary = StateReaderUtils.getProgramSummaries(getState()).find((ps: ProgramSummary) => ps.projectIds.builders === projectName);

                        let wireframes = StateReaderUtils.getWireFrame(getState(), projectName);
                        let scenePart: ScenePart = StateReaderUtils.getProjectWireframesScenePart(wireframes, sceneId, scenePartIndex);

                        const postgresNarrationId: string = StateReaderUtils.getNarrationPostgresId(wireframes, narrationId) || narrationId;

                        let input: GqlClientCopyNarrationInput = {
                            sourceId: `${programSummary.projectIds.builderDraftVersionId}|${sceneId}|${scenePart.scenePart}|${postgresNarrationId}`
                        };

                        services.graphQlClient
                            .mutate({
                                mutation: CopyNarrationDocument,
                                variables: {
                                    input: input,
                                    legacyId: newNarrationId,
                                    [IGNORE_SERVER_ERRORS]: true
                                }
                            })
                            .catch(() => {});
                    });
            }
            else {
                services.graphQlClient
                    .mutate({
                        mutation: CopyNarrationDocument,
                        variables: {
                            input: { sourceId: narrationId }
                        }
                    })
                    .then((gqlOutput: FetchResult<GqlClientCopyNarrationMutation>) => {
                        let { updated, id } = gqlOutput.data.copyNarration.targetNarration;
                        dispatch(addNarrationSuccess(accountId, projectName, sceneId, id, structure, variationDataMap, undefined, updated));
                        let newNarrationParts = [...scenePart.NarrationParts, { id }];
                        let newScenePart = { ...scenePart, NarrationParts: newNarrationParts };
                        dispatch(ChangingProjectWireframesScenePartsSuccess(accountId, projectName, sceneId, newScenePart, scenePartIndex));
                    })
                    .catch(() => {});
            }
        }
        else {
            let newScenePart = { ...scenePart };
            let newNarration = newScenePart.NarrationParts[narrationPartIndex];
            newScenePart.NarrationParts = [...scenePart.NarrationParts, newNarration];
            dispatch(setProjectWireframesScenePart(accountId, projectName, sceneId, newScenePart.scenePart, newScenePart, scenePartIndex, true));
        }
    };
};

//TODO used for dynamo flow only, when fully migrated to postgres can be deleted.
export const updateProjectMasterData = (accountId, projectName, master) => {
    return (dispatch, state, services) => {
        const masterData = master.sceneParts[0];
        services.wireframes
            .updateProjectMasterData(accountId, projectName, masterData)
            .then((master) => {
                dispatch(updateProjectMasterDataSuccess(projectName, master.masterData));
                // Graph QL Update
                const { programVersionId } = StateReaderUtils.getBuilderProgramAndDraftVersionIds(state(), projectName);
                const input: GqlClientUpdateMasterDataInput = {
                    programVersionId,
                    masterData
                };
                services.graphQlClient
                    .mutate({
                        mutation: UpdateMasterDataDocument,
                        variables: {
                            input,
                            dryRun: false,
                            [IGNORE_SERVER_ERRORS]: true
                        }
                    })
                    .catch((err) => {
                    });
            })
            .catch((error) => {
                dispatch(reportError(error));
            });
    };
};

export const createMasterAnimationGuideline = (accountId: string, projectName: string, name: string, type: ParameterTypes, cb: () => void) => {
    return (dispatch, getState, services: ThunkServices) => {
        const { programVersionId } = StateReaderUtils.getBuilderProgramAndDraftVersionIds(getState(), projectName);
        services.graphQlClient
            .mutate<GqlClientCreateMasterAnimationGuidelineMutation>({
                mutation: CreateMasterAnimationGuidelineDocument,
                variables: {
                    input: {
                        programVersionId,
                        name,
                        type: convertAnimationGuidelineType(type)
                    },
                    dryRun: false
                }
            })
            .then(gqlOutput => {
                const gqlMasterAnimationGuideline = gqlOutput.data.createMasterAnimationGuideline.masterAnimationGuideline;
                const masterAnimationGuideline: Parameter = {
                    graphQLId: gqlMasterAnimationGuideline.id,
                    graphQLUpdated: gqlMasterAnimationGuideline.updated,
                    type,
                    name
                };
                dispatch(createMasterAnimationGuidelineSuccess(projectName, masterAnimationGuideline));
                cb();
            })
            .catch((err) => {});
    };
};

export const renameMasterAnimationGuideline = (accountId: string, projectName: string, updatedAnimationGuidelineIndex: number, newName: string, cb: () => void) => {
    return (dispatch, getState, services: ThunkServices) => {
        const masterData: MasterData = StateReaderUtils.getProjectMasterData(getState(), projectName);
        const animationGuideline: Parameter = masterData.parameters[updatedAnimationGuidelineIndex];
        services.graphQlClient
            .mutate<GqlClientUpdateMasterAnimationGuidelineMutation>({
                mutation: UpdateMasterAnimationGuidelineDocument,
                variables: {
                    input: {
                        id: animationGuideline.graphQLId,
                        name: newName,
                        updated: IGNORE_UPDATED
                    },
                    dryRun: false
                }
            })
            .then(gqlOutput => {
                if (gqlOutput.data.updateMasterAnimationGuideline.__typename === "UpdateMasterAnimationGuidelineOutputSuccess") {
                    const gqlMasterAnimationGuideline = gqlOutput.data.updateMasterAnimationGuideline.masterAnimationGuideline;
                    const masterAnimationGuideline: Partial<Parameter> = {
                        graphQLUpdated: gqlMasterAnimationGuideline.updated,
                        name: newName
                    };
                    dispatch(renameMasterAnimationGuidelineSuccess(projectName, updatedAnimationGuidelineIndex, masterAnimationGuideline));
                    cb();
                }
            })
            .catch((err) => {});
    };
};

export const deleteMasterAnimationGuideline = (accountId: string, projectName: string, deletedAnimationGuidelineIndex: number, cb: () => void) => {
    return (dispatch, state, services: ThunkServices) => {
        const masterData: MasterData = StateReaderUtils.getProjectMasterData(state(), projectName);
        const animationGuideline: Parameter = masterData.parameters[deletedAnimationGuidelineIndex];
        services.graphQlClient
            .mutate<GqlClientDeleteMasterAnimationGuidelineMutation>({
                mutation: DeleteMasterAnimationGuidelineDocument,
                variables: {
                    input: {
                        id: animationGuideline.graphQLId
                    },
                    dryRun: false
                }
            })
            .then(gqlOutput => {
                dispatch(deleteMasterAnimationGuidelineSuccess(projectName, animationGuideline.name));
                cb();
            })
            .catch((err) => {});
    };
};

//////////////////////////////////////////////////////////////////////////////////////
// Surveys
//////////////////////////////////////////////////////////////////////////////////////

//NOT IN USE TODO: remove all related code clint and server (action, service, rest, dynamo table...).
export const addSurvey = function(accountId, projectName, surveyData) {
    return function(dispatch, getState, services) {
        services.wireframes
            .addSurvey(accountId, projectName, surveyData)
            .then((surveyId) => {
                dispatch(addingSurveySuccess(accountId, projectName, surveyId, surveyData));
            })
            .catch(function(err) {
                dispatch(reportError(err));
            });
    };
};
//NOT IN USE TODO: remove all related code clint and server (action, service, rest, dynamo table...).
export const deleteSurvey = function(accountId, projectName, surveyId) {
    return function(dispatch, getState, services) {
        // let sceneData = Object.assign({}, data, {sceneParts:  data.sceneParts.map(scenePartItem => scenePartItem.scenePart)})
        services.wireframes
            .deleteSurvey(accountId, projectName, surveyId)
            .then(() => {
                dispatch(deletingSurveySuccess(accountId, projectName, surveyId));
            })
            .catch(function(err) {
                dispatch(reportError(err));
            });
    };
};
//NOT IN USE TODO: remove all related code clint and server (action, service, rest, dynamo table...).
export const getSurveys = function(accountId, projectName) {
    return function(dispatch, getState, services) {
        services.wireframes
            .getSurveys(accountId, projectName)
            .then((surveys) => {
                dispatch(loadingSurveysSuccess(accountId, projectName, surveys));
            })
            .catch(function(err) {
                dispatch(reportError(err));
            });
    };
};

const getPresetValuesFromData = (presetData: PresetData, programVersionId: string, dataElements: DataElement[]) => {
    const systemElementValues: GqlClientCreatePresetSystemElementValueInput[] = presetData ? [] : undefined;
    const dataElementValues: GqlClientCreatePresetDataElementValueInput[] = presetData ? [] : undefined;

    presetData &&
    Object.keys(presetData).forEach((presetValueId: string) => {
        const dataElement = dataElements.find((de) => de.id === presetValueId || de.name === presetValueId || de.displayName === presetValueId);
        if (!dataElement) return;

        const { type, value, valueId } = getDynamoPresetValueFields(presetData[presetValueId]);

        const nullableFields: Pick<GqlClientCreatePresetDataElementValueInput, "type" | "value" | "valueId"> = {
            type,
            value: value !== null && value !== undefined ? JSON.stringify(value) : null,
            valueId
        };

        if (dataElement.origin === DataElementOrigins.System) {
            systemElementValues.push({ systemElementId: presetValueId, ...nullableFields });
        }
        else {
            dataElementValues.push({ dataElementId: `${programVersionId}|${dataElement.id}`, ...nullableFields });
        }
    });

    return {
        systemElementValues,
        dataElementValues
    };
};

export const getDynamoPresetValueFields = (dynamoPresetValue: PresetValue) => {
    const isPrimitivePresetValue: boolean = dynamoPresetValue === null || dynamoPresetValue === undefined || (!dynamoPresetValue.hasOwnProperty("value") && !dynamoPresetValue.hasOwnProperty("type"));

    return {
        type: isPrimitivePresetValue ? null : getPresetValueType(dynamoPresetValue),
        value: isPrimitivePresetValue ? dynamoPresetValue : (dynamoPresetValue as ElementData).value,
        valueId: isPrimitivePresetValue ? null : (dynamoPresetValue as ElementData).valueId || null
    };
};

const getPresetValueType = (presetValue: any): DataElementContentTypes => {
    return isObject(presetValue.type) ? (presetValue.type as PresetObjectType).type : (presetValue.type as DataElementContentTypes);
};
