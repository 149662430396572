import StateReaderUtils from "../common/StateReaderUtils";
import { buildInputRules } from "./logixBuilder";
import type { VLXProgram } from "../../../common/types/vlxTypes";
import type { Asset } from "../../../common/types/asset";
import type { Program } from "../../../common/types/program";
import type { DataElement } from "../../../common/types/dataElement";
import { VLXInputLogicType } from "../../../common/types/vlxTypes";

function createProgramLogic(state: any, projectName: string, storyIdToStoryAssetName: { [storyId: string]: string }): VLXProgram {
    let program: Program = StateReaderUtils.getProject(state, projectName);
    let dataElements: DataElement[] = StateReaderUtils.getProjectDataElements(state, projectName);
    let assets: Asset[] = StateReaderUtils.getProjectAssets(program, state.assets);

    const programDataFields = { dataElements, assets, storyIdToStoryAssetName };

    let storySelectionLogic = StateReaderUtils.getProgramStorySelectionLogic(state, projectName);

    return {
        storyName: {
            rules: buildInputRules(storySelectionLogic, programDataFields, false, false, true),
            type: VLXInputLogicType.TEXT
        }
    };
}

export default createProgramLogic;
