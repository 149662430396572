import type {
    GqlClientAnimationFragment,
    GqlClientAnimationVersionFragment,
    GqlClientAssetFragment,
    GqlClientAssetVersionFragment,
    GqlClientCreateAssetWithVersionInput,
    GqlClientUpdateAssetInput
} from "../../../graphql/graphqlGeneratedTypes/graphqlClient";
import type {
    AnimationHistoryItem,
    AnimationItem,
    ConvertedGqlAsset,
    ConvertedGqlAssetHistoryItem,
    CuratedAsset,
    MediaTypes
} from "../../../../common/types/asset";
import {
    AssetTypes
} from "../../../../common/types/asset";
import { STATUS_REQUEST } from "../../../components/legacyCommon/Consts";
import type { WithGraphQLId } from "../../common/types";

export type AssetUpdatedData = Pick<CuratedAsset, "title" | "description" | "activeVersion" | "inUse" | "deleted" | "archived">;

export type LegacyData = {
    inUse: boolean;
    deleted: boolean;
    archived: boolean;
};

// this function is meant to replicate as much as possible the expected asset shape in Redux state
export const convertPostgresAssetToDynamoAsset = (postgresAsset: GqlClientAssetFragment & { assetVersions?: GqlClientAssetVersionFragment[] }): ConvertedGqlAsset => {
    const { assetActiveVersion, accountId } = postgresAsset;
    const [, projectName, , assetDynamoName] = postgresAsset.legacyAssetId.split("/");

    const convertedGqlAsset: ConvertedGqlAsset = {
        // asset active version related fields
        activeVersion: assetActiveVersion.version,
        filename: assetActiveVersion.filename,
        format: assetActiveVersion.format,
        height: assetActiveVersion.height,
        size: assetActiveVersion.size,
        status: assetActiveVersion.version === 0 ? STATUS_REQUEST : "approved",
        thumbnailUrl: assetActiveVersion.thumbnailUrl,
        width: assetActiveVersion.width,
        url: assetActiveVersion.url,
        version: assetActiveVersion.version, // relevant only for old UI. 'version' is always the active version number

        // asset related fields
        ...(postgresAsset.legacyData as LegacyData),
        assetId: `${AssetTypes.curated}/${assetDynamoName}`,
        createdTime: new Date(postgresAsset.created).getTime(),
        description: postgresAsset.description,
        id: postgresAsset.id,
        isPostgresAsset: true, // to let the client know where to get the preview/thumbnail/download URL from
        mediaType: (postgresAsset.type as any) as MediaTypes,
        name: assetDynamoName,
        projectId: `${accountId}/${projectName}`,
        title: postgresAsset.name,
        type: AssetTypes.curated,
        updatedTime: new Date(postgresAsset.anyUpdated).getTime()

        /**
         'location' field is replaced with 'url'
         'thumbnailLocation' field is replaced with 'thumbnailUrl'
         'downloadSelf' field is no longer needed
         'mediaSubType' field is irrelevant hence ignored
         'uploadedBy' field is irrelevant hence ignored
         **/
    };

    if (postgresAsset.assetVersions) {
        // sort converted versions in reverse order by 'version' (latest version first)
        const convertedGqlAssetVersions: ConvertedGqlAssetHistoryItem[] = postgresAsset.assetVersions
            .map(convertPostgresAssetVersionToDynamoAssetVersion)
            .sort((a, b) => (a.version > b.version ? -1 : 1));

        convertedGqlAsset.history = { Items: convertedGqlAssetVersions };
    }

    return convertedGqlAsset;
};

const convertPostgresAssetVersionToDynamoAssetVersion = (postgresAssetVersion: GqlClientAssetVersionFragment): ConvertedGqlAssetHistoryItem => ({
    ...postgresAssetVersion,
    uploadedBy: postgresAssetVersion.createdBy,
    updateTime: new Date(postgresAssetVersion.created).getTime(),
    isPostgresAsset: true
});

export const initLegacyData = (): Pick<GqlClientCreateAssetWithVersionInput, "legacyData"> => ({
    legacyData: {
        inUse: false,
        deleted: false,
        archived: false
    }
});

export const convertAssetUpdatedDataToGqlInput = (assetUpdatedData: AssetUpdatedData, gqlStateAsset: ConvertedGqlAsset): Partial<GqlClientUpdateAssetInput> => {
    const gqlInput: Pick<GqlClientUpdateAssetInput, "name" | "description" | "activeAssetVersionId" | "legacyData"> = {};

    if (assetUpdatedData.title !== undefined) {
        gqlInput.name = assetUpdatedData.title;
    }
    if (assetUpdatedData.description !== undefined) {
        gqlInput.description = assetUpdatedData.description;
    }
    if (assetUpdatedData.activeVersion !== undefined) {
        const designatedActiveVersion = gqlStateAsset.history.Items.find((av) => av.version === assetUpdatedData.activeVersion);
        gqlInput.activeAssetVersionId = designatedActiveVersion.id;
    }
    if (assetUpdatedData.inUse !== undefined || assetUpdatedData.archived !== undefined || assetUpdatedData.deleted !== undefined) {
        const currentLegacyData: LegacyData = {
            inUse: Boolean(gqlStateAsset.inUse),
            archived: Boolean(gqlStateAsset.archived),
            deleted: Boolean(gqlStateAsset.deleted)
        };

        gqlInput.legacyData = { ...currentLegacyData, ...assetUpdatedData };
    }

    return gqlInput;
};

export const convertPostgresAnimationToDynamoAnimation = (
    postgresAnimation: GqlClientAnimationFragment & { animationVersions?: GqlClientAnimationVersionFragment[] }
): WithGraphQLId<AnimationItem> => {
    const { animationActiveVersion } = postgresAnimation;
    const [accountId, projectName, animationType, animationDynamoName] = postgresAnimation.legacyAnimationId.split("/");

    const convertedGqlAnimation: WithGraphQLId<AnimationItem> = {
        // animation active version related fields
        activeVersion: animationActiveVersion.version,

        // animation related fields
        assetId: `${animationType}/${animationDynamoName}`,
        description: postgresAnimation.description,
        graphQLId: postgresAnimation.id,
        name: animationDynamoName,
        projectId: `${accountId}/${projectName}`,
        title: postgresAnimation.name,
        type: AssetTypes.animation,
        updatedTime: new Date(postgresAnimation.updated).getTime()
    };

    if (postgresAnimation.animationVersions) {
        // sort converted versions in reverse order by 'version' (latest version first)
        const convertedGqlAnimationVersions: WithGraphQLId<AnimationHistoryItem>[] = postgresAnimation.animationVersions
            .map(convertPostgresAnimationVersionToDynamoAnimationVersion)
            .sort((a, b) => (a.version > b.version ? -1 : 1));

        convertedGqlAnimation.history = { Items: convertedGqlAnimationVersions };
    }

    return convertedGqlAnimation;
};

const convertPostgresAnimationVersionToDynamoAnimationVersion = (postgresAnimationVersion: GqlClientAnimationVersionFragment): WithGraphQLId<AnimationHistoryItem> => ({
    graphQLId: postgresAnimationVersion.id,
    type: AssetTypes.animation,
    version: postgresAnimationVersion.version,
    updateTime: new Date(postgresAnimationVersion.created).getTime(),
    uploadedBy: postgresAnimationVersion.createdBy,
    comment: postgresAnimationVersion.comment,
    aeFileName: postgresAnimationVersion.aeFilename
});

export type AnimationUpdatedData = Pick<AnimationItem, "description" | "activeVersion">;
