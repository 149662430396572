import { convertArrayOfObjectsToObject } from "../../../common/arrayUtils";
import type {
    GetProgramWithAssetsQueryResult,
    GqlClientAnimationFragment,
    GqlClientAssetFragment,
    GqlClientDataTableFragment,
    GqlClientDerivedDataElement,
    GqlClientNarration,
    GqlClientNarrationRecordingFragment,
    GqlClientNarrationRecordingVersionFragment,
    GqlClientNarrationVariation,
    GqlClientNarratorFragment,
    GqlClientProgram,
    GqlClientProgramVersionSnapshotDetailsFragment,
    GqlClientScene,
    GqlClientScenePart,
    GqlClientStory
} from "../../graphql/graphqlGeneratedTypes/graphqlClient";
import type { Story } from "../../../common/types/story";
import type { Group, NarrationPart, Parameter, Placeholder, PrioritizedList, Scene, ScenePart } from "../../../common/types/scene";
import { defaultNarratorId, LogicContainers } from "../../../common/commonConst";
import type {
    GetBuilderProgramQueryAnimationGuideline,
    GetBuilderProgramQueryCustomAnalyticField,
    GetBuilderProgramQueryMasterAnimationGuideline,
    GetBuilderProgramQueryNarration,
    GetBuilderProgramQueryPlaceholder,
    GetBuilderProgramQueryPlaceholderGroup,
    GetBuilderProgramQueryPrioritizedList,
    GetBuilderProgramQueryProgram,
    GetBuilderProgramQueryProgramVersion,
    GetBuilderProgramQueryScene,
    GetBuilderProgramQueryScenePart,
    GetBuilderProgramQueryStory,
    LoadedNarration
} from "../projects/projectWireframes/projectsWireframesActions";
import { initMasterDataObj, initMasterObj } from "../projects/projectWireframes/projectsWireframesActions";
import {
    convertAnimationGuidelineTypeReverse,
    convertBackgroundAssetTypeReverse,
    convertDataElementStatusReverse,
    convertDataElementTypeReverse,
    convertNarrationRecordingErrorReason,
    convertPlaceholderTypeReverse,
    convertProgramTypeReverse,
    convertStoryRationAndQualityOptionsTypeReverse
} from "../builder/graphql/convertTypes";
import type { CreativeDataElement, DataElement, DerivedDataElement, FeedDataElement } from "../../../common/types/dataElement";
import { DataElementOrigins, TagNames } from "../../../common/types/dataElement";
import { getAllSystemDataElements } from "../../../common/SystemDataElementsUtils";
import type { VariationData } from "../../../common/types/narration";
import type { ConvertedGqlNarrator, ConvertedGqlRecordingAsset } from "../../../common/types/asset";
import { AssetTypes, MediaTypes } from "../../../common/types/asset";
import type { Program } from "../../../common/types/program";
import type { CustomAnalyticField } from "../../../common/types/analytics";
import type { DataTable } from "../../../common/types/dataTable";
import type { WithGraphQLId } from "./types";
import { getRecordingAssetNameByKey } from "./exportNarrationsUtils";
import type { Snapshot, SnapshotAsset } from "../../../common/types/snapshot";
import { SnapshotSource } from "../../../common/types/snapshot";
import type { WireframeLogicContainer, WireframeProgramLogic, WireframeScenesLogic, WireframeStoriesLogic, WireframeStoryLogic } from "../../../common/types/logic";
import { LogicContext } from "../../../common/types/logic";
import { isEditorAccountLibraryProgram } from "../../../common/editorUtils";

export function sortArrayByRepresentativeFieldArray<T>(arrToSort: Array<T>, representativeField: keyof T, arrToSortBy: Array<string>): Array<T> {
    let sortedArray: Array<T> = [];
    const arrayToSortAsObject: { [key: string]: T } = convertArrayOfObjectsToObject<T>(arrToSort, representativeField);
    (arrToSortBy || []).forEach(id => {
        if (arrayToSortAsObject[id]) {
            sortedArray.push(arrayToSortAsObject[id]);
        }
    });
    return sortedArray;
}

export const buildWireframesAnalyticsOrderFromGql = (gqlProgram: GetBuilderProgramQueryProgram): string[] => {
    return gqlProgram.customAnalyticsOrder;
};

export const buildWireframesAnalyticFieldsFromGql = (gqlProgram: GetBuilderProgramQueryProgram): Record<string, CustomAnalyticField> => {
    return gqlProgram.customAnalyticFields.reduce((acc, field: GetBuilderProgramQueryCustomAnalyticField) => {
        acc[field.id] = buildCustomAnalyticFieldFromGql(field);
        return acc;
    }, {});
};

export const buildWireframesStoryFromGqlStory = (gqlStory: GetBuilderProgramQueryStory, isEditor: boolean): Story => {
    return {
        id: getIdFromGqlId(gqlStory.id),
        graphQLId: gqlStory.id,
        graphQLUpdated: gqlStory.updated,
        name: gqlStory.name,
        description: gqlStory.description,
        activateCaching: gqlStory.activateCaching,
        initialDecisionPointId: isEditor ? undefined : getIdFromGqlId(gqlStory.initialDecisionPoint.id),
        backgroundAssetType: convertBackgroundAssetTypeReverse(gqlStory.backgroundAssetType),
        narrator: gqlStory.narratorName,
        displaySceneOrder: gqlStory.displaySceneOrder.map(getIdFromGqlId),
        numberOfProducts: gqlStory.numberOfProducts,
        durationOptions: gqlStory.durationOptions,
        ratioAndQualityOptions: gqlStory.ratioAndQualityOptions?.map(convertStoryRationAndQualityOptionsTypeReverse)
    };
};

export const buildWireframesScenePartsFromGql = (programVersionNarrationsVersion: number, gqlScene: GetBuilderProgramQueryScene): ScenePart[] => {
    const sortedGqlSceneParts: GetBuilderProgramQueryScenePart[] = sortArrayByRepresentativeFieldArray<GetBuilderProgramQueryScenePart>(gqlScene.sceneParts, "id", gqlScene.scenePartOrder);
    return sortedGqlSceneParts.map(gqlScenePart => buildWireframesScenePartFromGql(programVersionNarrationsVersion, gqlScenePart));
};

export const buildWireframesScenePartFromGql = (programVersionNarrationsVersion: number, gqlScenePart: GetBuilderProgramQueryScenePart): ScenePart => {
    return {
        scenePart: getIdFromGqlId(gqlScenePart.id), // todo: this way two scene parts can have the same uuid. Should we get the full id instead?
        graphQLId: gqlScenePart.id,
        graphQLUpdated: gqlScenePart.updated,
        storyboardFrameThumbnailLocation: gqlScenePart.thumbnailLocation,
        NarrationParts: programVersionNarrationsVersion > 0 ? buildWireframesNarrationsPartFromGql(gqlScenePart.narrations, gqlScenePart.narrationPartOrder)
            : buildWireframesLegacyNarrationsPartFromGql(gqlScenePart),
        placeholders: gqlScenePart.placeholders.map(buildWireframesPlaceholderFromGql),
        groups: gqlScenePart.placeholderGroups.map(buildWireframesGroupFromGql),
        parameters: gqlScenePart.animationGuidelines.map(buildWireframesParametersFromGql),
        prioritizedLists: gqlScenePart.prioritizedLists.map(buildWireframesPrioritizedListFromGql)
    };
};

export const buildCustomAnalyticFieldFromGql = (gqlCustomAnalyticField: GetBuilderProgramQueryCustomAnalyticField): CustomAnalyticField => {
    return {
        id: gqlCustomAnalyticField.id,
        name: gqlCustomAnalyticField.name,
        data: gqlCustomAnalyticField.logic,
        graphQLUpdated: gqlCustomAnalyticField.updated
    };
};

export const buildWireframesSceneFromGql = (programVersionNarrationsVersion: number, gqlScene: GetBuilderProgramQueryScene): Scene => {
    return {
        id: getIdFromGqlId(gqlScene.id),
        graphQLId: gqlScene.id,
        graphQLUpdated: gqlScene.updated,
        name: gqlScene.name,
        creationTime: new Date(gqlScene.created).getTime().toString(),
        sceneParts: buildWireframesScenePartsFromGql(programVersionNarrationsVersion, gqlScene)
    };
};

export const buildProgramFromGql = (gqlProgram: GetProgramWithAssetsQueryResult["data"]["program"]): Program => {
    return {
        accountId: gqlProgram.accountId,
        projectName: gqlProgram.legacyBuilderProgramId || gqlProgram.id,
        graphQLId: gqlProgram.id,
        graphQLUpdated: gqlProgram.updated,
        graphQLBuilderDraftId: gqlProgram.programVersion.id,
        programId: gqlProgram.saleForceId,
        displayName: gqlProgram.name,
        description: gqlProgram.description,
        isLegacy: gqlProgram.isLegacy,
        programType: convertProgramTypeReverse(gqlProgram.programVersion.programType),
        creativeVersion: gqlProgram.programVersion.creativeVersion,
        RFR: gqlProgram.programVersion.rfr,
        RFRUpdateTimestamp: new Date(gqlProgram.programVersion.rfrUpdateTime).getTime(),
        creativeDDE: gqlProgram.programVersion.creativeDfde,
        creativeNameLabel: gqlProgram.programVersion.creativeNameLabel,
        narrationsVersion: gqlProgram.programVersion.narrationsVersion,
        storiesVersion: 1,
        pii: Boolean(gqlProgram.pii),
        editorDataConnectorEnabled: Boolean(gqlProgram.programVersion.editorDataConnectorEnabled),
        editorDataConnectorType: gqlProgram.programVersion.editorDataConnectorType,
        chapteringEnabled: Boolean(gqlProgram.programVersion.chapteringEnabled),
        publishTarget: gqlProgram.publishTarget
    };
};

export const buildWireframesFromGql = (gqlProgram: GetBuilderProgramQueryProgram) => {
    const gqlProgramVersion: GetBuilderProgramQueryProgramVersion = gqlProgram.programVersion;

    let gqlScenes = gqlProgramVersion.scenes.reduce((acc, scene: GetBuilderProgramQueryScene) => {
        const sceneId = getIdFromGqlId(scene.id);
        acc[sceneId] = buildWireframesSceneFromGql(gqlProgramVersion.narrationsVersion, scene);
        return acc;
    }, {});

    gqlScenes[LogicContainers.Master] = initMasterObj();
    gqlScenes[LogicContainers.Master].sceneParts[0] = initMasterDataObj();
    if (gqlProgramVersion.masterAnimationGuidelines.length > 0) {
        gqlScenes[LogicContainers.Master].sceneParts[0] = {
            parameters: gqlProgramVersion.masterAnimationGuidelines.map(buildWireframesMasterDataFromGql),
            placeholders: []
        };
    }

    let gqlStories = gqlProgramVersion.stories.reduce((acc, story) => {
        const storyId = getIdFromGqlId(story.id);
        acc[storyId] = buildWireframesStoryFromGqlStory(story, gqlProgram.isEditor);
        return acc;
    }, {});

    return {
        graphQLProgramVersionId: gqlProgramVersion.id,
        scenes: gqlScenes,
        stories: gqlStories,
        storiesVersion: 1,
        customAnalyticsOrder: buildWireframesAnalyticsOrderFromGql(gqlProgram),
        customAnalyticFields: buildWireframesAnalyticFieldsFromGql(gqlProgram),
        narrationsVersion: gqlProgramVersion.narrationsVersion,
        creativeVersion: gqlProgramVersion.creativeVersion,
        creativeDDE: gqlProgramVersion.creativeDfde,
        creativeNameLabel: gqlProgramVersion.creativeNameLabel,
        programRFR: gqlProgramVersion.rfr,
        programRFRTimestamp: new Date(gqlProgramVersion.rfrUpdateTime).getTime(),
        transition: gqlProgramVersion.transition,
        graphQLParentProgramVersionId: gqlProgramVersion.parent1ProgramVersion?.id
    };
};

export const getIdFromGqlId = (gqlId: string): string => {
    return gqlId?.split("|").pop() ?? "";
};

export const buildSlotsFromGql = (prioritizedList: GetBuilderProgramQueryPrioritizedList): any[] => {
    if (prioritizedList.placeholders.length > 0) {
        return prioritizedList.placeholders.map(buildWireframesPlaceholderFromGql);
    }

    if (prioritizedList.groups.length > 0) {
        return prioritizedList.groups.map(buildWireframesGroupFromGql);
    }

    return [];
};

export const buildWireframesPlaceholderFromGql = (gqlPlaceholder: GetBuilderProgramQueryPlaceholder): Placeholder => {
    return {
        x: gqlPlaceholder.x === null ? undefined : gqlPlaceholder.x,
        y: gqlPlaceholder.y === null ? undefined : gqlPlaceholder.y,
        height: gqlPlaceholder.height === null ? undefined : gqlPlaceholder.height,
        width: gqlPlaceholder.width === null ? undefined : gqlPlaceholder.width,
        type: convertPlaceholderTypeReverse(gqlPlaceholder.type),
        name: gqlPlaceholder.logic.name,
        graphQLId: gqlPlaceholder.id,
        graphQLUpdated: gqlPlaceholder.updated
    };
};

export const buildWireframesParametersFromGql = (gqlAnimationGuideline: GetBuilderProgramQueryAnimationGuideline): Parameter => {
    return {
        type: convertAnimationGuidelineTypeReverse(gqlAnimationGuideline.type),
        name: gqlAnimationGuideline.logic.name,
        graphQLId: gqlAnimationGuideline.id,
        graphQLUpdated: gqlAnimationGuideline.updated
    };
};

export const buildWireframesMasterDataFromGql = (gqlMasterAnimationGuideline: GetBuilderProgramQueryMasterAnimationGuideline): Parameter => {
    return {
        type: convertAnimationGuidelineTypeReverse(gqlMasterAnimationGuideline.type),
        name: gqlMasterAnimationGuideline.name,
        graphQLId: gqlMasterAnimationGuideline.id,
        graphQLUpdated: gqlMasterAnimationGuideline.updated
    };
};

export const buildWireframesNarrationsPartFromGql = (gqlNarrations: GetBuilderProgramQueryNarration[], narrationPartOrder): NarrationPart[] => {
    const sortedGqlNarrations: GetBuilderProgramQueryNarration[] = sortArrayByRepresentativeFieldArray<GetBuilderProgramQueryNarration>(gqlNarrations, "id", narrationPartOrder);
    return sortedGqlNarrations.map(buildWireframesNarrationPartFromGql);
};

export const buildWireframesNarrationPartFromGql = (gqlNarration: GetBuilderProgramQueryNarration): NarrationPart => {
    return {
        id: gqlNarration.id,
        graphQLId: gqlNarration.id,
        graphQLUpdated: gqlNarration.updated,
        lastUsedRecordingFileName: gqlNarration.lastUsedRecordingFileNumber || 0,
        key: gqlNarration.dtaasPrefixKey
    };
};

export const buildWireframesLegacyNarrationsPartFromGql = (gqlScenePart: GetBuilderProgramQueryScenePart): NarrationPart[] => {
    return gqlScenePart.oldNarrationData as NarrationPart[];
};

export const buildWireframesGroupFromGql = (group: GetBuilderProgramQueryPlaceholderGroup): Group => {
    return {
        id: getIdFromGqlId(group.logic.id),
        graphQLId: group.id,
        graphQLUpdated: group.updated,
        name: group.logic.name,
        placeholders: group.placeholders.map(buildWireframesPlaceholderFromGql)
    };
};

export const buildWireframesPrioritizedListFromGql = (gqlPrioritizedList: GetBuilderProgramQueryPrioritizedList): PrioritizedList => {
    return {
        id: getIdFromGqlId(gqlPrioritizedList.logic.id),
        graphQLId: gqlPrioritizedList.id,
        graphQLUpdated: gqlPrioritizedList.updated,
        name: gqlPrioritizedList.logic.name,
        slots: buildSlotsFromGql(gqlPrioritizedList)
    };
};

export const buildDataElementsFromGql = (gqlProgram: GqlClientProgram): DataElement[] => {
    let gqlDataElements: DataElement[] = [];

    const gqlDerivedElements: DerivedDataElement[] = gqlProgram.programVersion.derivedDataElements.map((de: GqlClientDerivedDataElement) => {
        return {
            id: getIdFromGqlId(de.id),
            graphQLId: de.id,
            graphQLUpdated: de.updated,
            compatibilityVersion: 0.3,
            updatedTime: new Date(de.updated).valueOf(),
            displayName: de.name,
            origin: DataElementOrigins.Derived,
            description: de.description,
            type: convertDataElementTypeReverse(de.dataType),
            tags: de.pii ? [TagNames.PII] : []
        };
    });
    gqlDataElements.push(...gqlDerivedElements);

    const gqlCreativeElements: CreativeDataElement[] = gqlProgram.programVersion.creativeDataElements.map((de) => {
        return {
            id: getIdFromGqlId(de.id),
            graphQLId: de.id,
            graphQLUpdated: de.updated,
            compatibilityVersion: 0.3,
            updatedTime: new Date(de.updated).valueOf(),
            displayName: de.name,
            origin: DataElementOrigins.Creative,
            description: de.description,
            type: convertDataElementTypeReverse(de.dataType),
            tags: de.pii ? [TagNames.PII] : [],
            valueSet: de.valueSet,
            useValueSet: de.useValueSet,
            assignedStories: de.stories.map((s) => getIdFromGqlId(s.id)),
            assignedToAllStories: de.assignedToAllStories
        };
    });
    gqlDataElements.push(...gqlCreativeElements);

    const gqlFeedElements: FeedDataElement[] = gqlProgram.programVersion.feedDataElements.map((de) => {
        return {
            id: getIdFromGqlId(de.id),
            graphQLId: de.id,
            graphQLUpdated: de.updated,
            compatibilityVersion: 0.3,
            updatedTime: new Date(de.updated).valueOf(),
            displayName: de.name,
            origin: DataElementOrigins.Feed,
            description: de.description,
            type: convertDataElementTypeReverse(de.dataType),
            tags: de.pii ? [TagNames.PII] : [],
            valueSet: de.valueSet,
            useValueSet: de.useValueSet,
            status: convertDataElementStatusReverse(de.status)
        };
    });
    gqlDataElements.push(...gqlFeedElements);

    gqlDataElements.push(...getAllSystemDataElements(gqlProgram.programVersion?.programType, isEditorAccountLibraryProgram(gqlProgram.name)));

    return gqlDataElements;
};

export const buildMasterLogicFromGql = (gqlProgram: GqlClientProgram): WireframeLogicContainer => {
    let loadedLogicItems: WireframeLogicContainer = {};
    const gqlGuidelines = gqlProgram.programVersion.masterAnimationGuidelines;
    gqlGuidelines.forEach((g) => {
        if (g.logic) {
            loadedLogicItems[g.name] = g.logic;
        }
    });
    return loadedLogicItems;
};

export const buildWireframesNarrationFromGql = (narr: GqlClientNarration): LoadedNarration => {
    return {
        id: narr.id,
        dbNarration: {
            graphQLId: narr.id,
            graphQLUpdated: narr.updated,
            structure: {
                dimensionData: narr.dimensionData,
                mergeData: narr.mergeData,
                representativeKey: narr.representativeKey,
                creativeOverride: narr.supportsCreativeDataElementOverride
            },
            variationData: narr.narrationVariations.reduce((varAcc: { [variationKey: string]: VariationData }, variation: GqlClientNarrationVariation) => {
                varAcc[variation.variationKey] = {
                    graphQLId: variation.id,
                    graphQLUpdated: variation.updated,
                    text: variation.sentence,
                    muted: variation.muted,
                    comment: variation.note,
                    overrideId: variation.overridingCreativeDataElement && getIdFromGqlId(variation.overridingCreativeDataElement.id)
                };
                return varAcc;
            }, {})
        }
    };
};

export const buildWireframesNarrationsFromGql = (
    gqlProgram: GqlClientProgram,
    compareGqlToDynamo: boolean,
    dynamoLoadedNarrations: LoadedNarration[],
    loadNarrationIdsMap: Record<string, string>
): LoadedNarration[] => {
    const gqlToDynamoIdMap: Record<string, string> = compareGqlToDynamo ? dynamoLoadedNarrations.reduce((acc, narr: LoadedNarration) => {
        let graphQLId = `${loadNarrationIdsMap[narr.id]}|${narr.dbNarration?.postgresNarrationId || narr.id}`;
        acc[graphQLId] = narr.id;
        return acc;
    }, {}) : {};
    const gqlScenes = gqlProgram.programVersion.scenes;
    return gqlScenes.reduce((acc: LoadedNarration[], scene) => {
        scene.sceneParts.forEach((part) => {
            part.narrations.forEach((narr: GqlClientNarration) => {
                // if we're only comparing to dynamo - we need to filter out not loaded narration + adjust the id to dynamo id
                if (!compareGqlToDynamo || gqlToDynamoIdMap[narr.id]) {
                    let convertedNarration: LoadedNarration = buildWireframesNarrationFromGql(narr);
                    if (compareGqlToDynamo) {
                        convertedNarration.id = gqlToDynamoIdMap[narr.id];
                    }
                    acc.push(convertedNarration);
                }
            });
        });
        return acc;
    }, []);
};

export const buildDerivedLogicFromGql = (gqlProgram: GqlClientProgram): WireframeLogicContainer => {
    const gqlDerivedElements = gqlProgram.programVersion.derivedDataElements;
    return gqlDerivedElements.reduce((acc, de) => {
        if (de.logic) {
            acc[getIdFromGqlId(de.id)] = de.logic;
        }
        return acc;
    }, {});
};

export const buildAnalyticsLogicFromGql = (gqlProgram: GqlClientProgram): WireframeLogicContainer => {
    const gqlAnalytics = gqlProgram.customAnalyticFields;
    return gqlAnalytics.reduce((logicItems, a) => {
        logicItems[a.id] = a.logic;
        return logicItems;
    }, {});
};

export const buildStoriesLogicFromGql = (gqlProgram: GqlClientProgram): WireframeStoriesLogic | null => {
    const gqlStories = gqlProgram.isEditor ? [] : gqlProgram.programVersion.stories;
    const storiesLogic = gqlStories.reduce((acc, story) => {
        acc[getIdFromGqlId(story.id)] = buildStoryLogicFromGql(story);
        return acc;
    }, {});
    return gqlStories.length ? storiesLogic : null;
};

export const buildStoryLogicFromGql = (story: GqlClientStory): WireframeStoryLogic => {
    let storyLogics: WireframeStoryLogic = {
        [LogicContainers.BackgroundAsset] : {
            [MediaTypes.Image] : story?.backgroundImageLogic,
            [MediaTypes.Video] : story?.backgroundVideoLogic
        },
        [LogicContainers.Soundtrack] : {
            [MediaTypes.Audio] : story?.backgroundAudioLogic
        },
        [LogicContainers.VideoSettings] : {
            [LogicContext.StoryRatioAndQuality] : story?.ratioAndQualityLogic
        },
        [LogicContainers.DecisionPoint] : story.decisionPoints.reduce((dpAcc, dp) => {
            if (dp.logic) {
                dpAcc[getIdFromGqlId(dp.id)] = dp.logic;
            }
            return dpAcc;
        }, {})
    };
    return storyLogics;
};

export const buildScenesLogicFromGql = (gqlProgram: GqlClientProgram): WireframeScenesLogic => {
    const gqlScenes = gqlProgram.programVersion.scenes;
    return gqlScenes.reduce((acc, scene: GqlClientScene) => {
        const sceneId: string = getIdFromGqlId(scene.id);
        acc[sceneId] = buildSceneLogicFromGql(scene);
        return acc;
    }, {});
};

export const buildSceneLogicFromGql = (gqlScene: GqlClientScene): WireframeLogicContainer => {
    let sceneLogicItems: WireframeLogicContainer = {};
    if (gqlScene.animationLogic) {
        sceneLogicItems[`${getIdFromGqlId(gqlScene.id)}_animation`] = gqlScene.animationLogic;
    }
    if (gqlScene.validationLogic) {
        sceneLogicItems[`${getIdFromGqlId(gqlScene.id)}_validation`] = gqlScene.validationLogic;
    }
    if (gqlScene.descriptiveTranscriptLogic) {
        sceneLogicItems["descriptive-transcript"] = gqlScene.descriptiveTranscriptLogic;
    }
    gqlScene.sceneParts.forEach((part: GqlClientScenePart) => {
        part.animationGuidelines.forEach((ag) => {
            if (ag.logic.logic) {
                sceneLogicItems[ag.logic.name] = ag.logic.logic;
            }
        });
        part.placeholders.forEach((ph) => {
            if (ph.logic.logic) {
                sceneLogicItems[ph.logic.name] = ph.logic.logic;
            }
        });

        part.placeholderGroups.forEach((gp) => {
            if (gp.logic.logic) {
                sceneLogicItems[getIdFromGqlId(gp.logic.id)] = gp.logic.logic;
            }
        });

        part.prioritizedLists.forEach((pl) => {
            if (pl.logic.logic) {
                sceneLogicItems[getIdFromGqlId(pl.logic.id)] = pl.logic.logic;
            }

        });
    });

    return sceneLogicItems;
};

export const buildProgramLogicFromGql = (gqlProgram: GqlClientProgram): WireframeProgramLogic | null => {
    const gqlStorySelectionLogic = gqlProgram.programVersion.storySelectionLogic;
    return gqlStorySelectionLogic ? {
        [LogicContainers.StorySelection] :{
            [LogicContainers.StorySelection] : gqlStorySelectionLogic
        }
    } : null;
};

export const buildNarratorsFromGql = (accountId: string, programName: string, gqlNarrators: GqlClientNarratorFragment[]): ConvertedGqlNarrator[] => {
    return gqlNarrators.map(narr => buildNarratorFromGql(accountId, programName, narr));
};

export const buildNarratorFromGql = (accountId: string, programName: string, gqlNarrator: GqlClientNarratorFragment): ConvertedGqlNarrator => {
    return {
        graphQLId: gqlNarrator.id,
        projectId: `${accountId}/${programName}`,
        location: gqlNarrator.carUrl,
        status: gqlNarrator.status,
        createdTime: new Date(gqlNarrator.created).valueOf(),
        decisionTablesLoadTaskId: gqlNarrator.decisionTablesLoadTaskId,
        name: gqlNarrator.name,
        decisionTablesLocation: gqlNarrator.dtaasPrefix,
        assetId: `${AssetTypes.narrator}/${gqlNarrator.name}`,
        updatedTime: new Date(gqlNarrator.updated).valueOf(),
        title: gqlNarrator.name,
        type: AssetTypes.narrator
    };
};

export const buildDataTableFromGql = (postgresDataTable: GqlClientDataTableFragment): DataTable => {
    const assetId: string = getIdFromGqlId(postgresDataTable.id);
    return {
        name: postgresDataTable.legacyDataTableId || assetId,
        mediaType: MediaTypes.Application,
        type: AssetTypes.mappingTable,
        graphQLId: postgresDataTable.id,
        graphQLUpdated: postgresDataTable.updated,
        description: postgresDataTable.description,
        isPostgresAsset: true,
        location: postgresDataTable.location,
        mappingTableScheme: postgresDataTable.schema,
        title: postgresDataTable.name,
        url: postgresDataTable.downloadUrl,
        decisionTablesLocation: postgresDataTable.dtaasPrefix,
        assetId: `${AssetTypes.mappingTable}/${postgresDataTable.legacyDataTableId || assetId}`,
        createdTime: new Date(postgresDataTable.created).valueOf(),
        updatedTime: new Date(postgresDataTable.updated).valueOf(),
        version: 0,
        activeVersion: 0,
        inUse: true
    };
};

type gqlNarrationRecordingsType = GqlClientNarrationRecordingFragment & { narrationRecordingVersions?: GqlClientNarrationRecordingVersionFragment[] };

export const buildNarrationRecordingsFromGql = (accountId: string, programName: string, gqlNarrationRecordings: gqlNarrationRecordingsType[], isEditor: boolean): ConvertedGqlRecordingAsset[] => {
    return gqlNarrationRecordings.map(rec => buildNarrationRecordingFromGql(accountId, programName, rec, isEditor));
};

export const buildNarrationRecordingFromGql = (accountId: string, programName: string, gqlNarrationRecording: gqlNarrationRecordingsType, isEditor: boolean): ConvertedGqlRecordingAsset => {
    const name = isEditor ? gqlNarrationRecording.id : getRecordingAssetNameByKey(gqlNarrationRecording.narrationId, defaultNarratorId, gqlNarrationRecording.key);

    let convertedNarrationRecording: ConvertedGqlRecordingAsset = {
        graphQLId: gqlNarrationRecording.id,
        graphQLUpdated: gqlNarrationRecording.updated,
        projectId: `${accountId}/${programName}`,
        RFR: gqlNarrationRecording.rfr,
        type: AssetTypes.recording,
        mediaType: MediaTypes.Audio,
        filename: gqlNarrationRecording.filename,
        version: gqlNarrationRecording.lastVersionNumber,
        activeVersion: gqlNarrationRecording.narrationRecordingActiveVersion?.version || 0,
        activeVersionId: gqlNarrationRecording.narrationRecordingActiveVersion?.id,
        narrationMetadata: { ...gqlNarrationRecording.metadata, text: gqlNarrationRecording.text },
        name,
        createdTime: new Date(gqlNarrationRecording.created).valueOf(),
        updatedTime: new Date(gqlNarrationRecording.updated).valueOf(),
        assetId: `${AssetTypes.recording}/${name}`,
        inUse: true,
        url: gqlNarrationRecording.narrationRecordingActiveVersion?.url,
        recordingStatus: gqlNarrationRecording.narrationRecordingActiveVersion?.status,
        recordingErrorReason: convertNarrationRecordingErrorReason(gqlNarrationRecording.narrationRecordingActiveVersion?.errorReason),
        status: gqlNarrationRecording.lastVersionNumber === 0 ? "request" : "approved",
        narrationId: gqlNarrationRecording.narrationId,
        isPostgresAsset: true,
        location: gqlNarrationRecording.narrationRecordingActiveVersion?.location
    };

    if (gqlNarrationRecording.narrationRecordingVersions) {
        const convertedRecordingVersions: WithGraphQLId<any>[] = gqlNarrationRecording.narrationRecordingVersions
            .map(narrationRecordingVersion => buildNarrationRecordingVersionFromGql(narrationRecordingVersion, gqlNarrationRecording.filename))
            .sort((a, b) => (a.version > b.version ? -1 : 1));

        convertedNarrationRecording.history = { Items: convertedRecordingVersions };
    }

    return convertedNarrationRecording;
};

const buildNarrationRecordingVersionFromGql = (gqlNarrationRecordingVersion: GqlClientNarrationRecordingVersionFragment, filename: string): WithGraphQLId<any> => ({
    ...gqlNarrationRecordingVersion,
    uploadedBy: gqlNarrationRecordingVersion.createdBy,
    updateTime: new Date(gqlNarrationRecordingVersion.created).getTime(),
    recordingStatus: gqlNarrationRecordingVersion.status,
    recordingErrorReason: convertNarrationRecordingErrorReason(gqlNarrationRecordingVersion.errorReason),
    isPostgresAsset: true,
    filename
});

export const buildStateVersionsFromGql = (programVersionSnapshots: GqlClientProgramVersionSnapshotDetailsFragment[]): Snapshot[] => {
    return programVersionSnapshots.map((gqlProgramVersionSnapshot: GqlClientProgramVersionSnapshotDetailsFragment) => buildStateSnapshotFromGql(gqlProgramVersionSnapshot));
};

export const buildStateSnapshotFromGql = (gqlProgramVersionSnapshot: GqlClientProgramVersionSnapshotDetailsFragment, snapshotAssets?: SnapshotAsset[]): Snapshot => {
    let snapshot: Snapshot = {
        createdBy: gqlProgramVersionSnapshot.snapshottedBy,
        createdOn: new Date(gqlProgramVersionSnapshot.snapshotted).getTime(),
        snapshotComment: gqlProgramVersionSnapshot.snapshotComment,
        snapshotName: gqlProgramVersionSnapshot.snapshotName,
        snapshotNumber: gqlProgramVersionSnapshot.snapshotNumber,
        validationData: gqlProgramVersionSnapshot.snapshotValidationData,
        snapshotSource: SnapshotSource.POSTGRES,
        graphQLId: gqlProgramVersionSnapshot.id,
        graphQLParentProgramVersionId: gqlProgramVersionSnapshot.parent1ProgramVersion?.id
    };
    if (snapshotAssets) {
        snapshot.assets = snapshotAssets;
    }
    return snapshot;
};

export const buildStateSnapshotAssetsFromGql = (
    postgresAssets: GqlClientAssetFragment[],
    postgresAnimations: GqlClientAnimationFragment[],
    postgresDataTables: GqlClientDataTableFragment[],
    postgresNarrationRecordings: GqlClientNarrationRecordingFragment[],
    postgresNarrators: GqlClientNarratorFragment[]
): SnapshotAsset[] => {
    let snapshotAssets: SnapshotAsset[] = postgresAssets.map((postgresAsset: GqlClientAssetFragment): SnapshotAsset => {
        const { assetActiveVersion } = postgresAsset;
        const assetDynamoName = postgresAsset.legacyAssetId.split("/").pop();
        return {
            name: assetDynamoName,
            type: AssetTypes.curated,
            version: assetActiveVersion.version,
            url: assetActiveVersion.url,
            thumbnailUrl: assetActiveVersion.thumbnailUrl
        };
    });

    let snapshotAnimations: SnapshotAsset[] = postgresAnimations.map((postgresAnimation: GqlClientAnimationFragment): SnapshotAsset => {
        const { animationActiveVersion } = postgresAnimation;
        const animationDynamoName = postgresAnimation.legacyAnimationId.split("/").pop();
        return {
            name: animationDynamoName,
            type: AssetTypes.animation,
            version: animationActiveVersion.version
        };
    });

    let snapshotDataTables: SnapshotAsset[] = postgresDataTables.map((postgresDataTable: GqlClientDataTableFragment): SnapshotAsset => {
        return {
            name: postgresDataTable.legacyDataTableId || getIdFromGqlId(postgresDataTable.id),
            type: AssetTypes.mappingTable,
            version: 0,
            url: postgresDataTable.downloadUrl,
            decisionTablesLocation: postgresDataTable.dtaasPrefix
        };
    });

    let snapshotNarrationRecordings: SnapshotAsset[] = postgresNarrationRecordings.map((postgresNarrationRecording: GqlClientNarrationRecordingFragment): SnapshotAsset => {
        return {
            name: getRecordingAssetNameByKey(postgresNarrationRecording.narrationId, defaultNarratorId, postgresNarrationRecording.key),
            type: AssetTypes.recording,
            version: postgresNarrationRecording.narrationRecordingActiveVersion?.version || 0,
            location: postgresNarrationRecording.narrationRecordingActiveVersion?.location,
            url: postgresNarrationRecording.narrationRecordingActiveVersion?.url,
            RFR: postgresNarrationRecording.rfr,
            filename: postgresNarrationRecording.filename,
            recordingStatus: postgresNarrationRecording.narrationRecordingActiveVersion?.status
        };
    });

    let snapshotNarrators: SnapshotAsset[] = postgresNarrators.map((postgresNarrator: GqlClientNarratorFragment): SnapshotAsset => {
        return {
            name: postgresNarrator.legacyNarratorId.split("/").pop(),
            type: AssetTypes.narrator,
            version: 1,
            location: postgresNarrator.carUrl,
            decisionTablesLocation: postgresNarrator.dtaasPrefix
        };
    });
    return [...snapshotAssets, ...snapshotAnimations, ...snapshotNarrationRecordings, ...snapshotDataTables, ...snapshotNarrators];
};

