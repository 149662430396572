import {
    ADD_NARRATION_SUCCESS,
    CANCEL_REMOVE_NARRATION_DIMENSION,
    DELETE_NARRATION_SUCCESS,
    LOAD_NARRATIONS_SUCCESS,
    NARRATION_USED_VALUE_SET_PROVIDER_CHANGED,
    REMOVE_NARRATION_DIMENSION,
    SET_SHOW_MUTED_ROWS,
    SET_SHOW_NOTE_COLUMN,
    SET_SHOW_STATUS_COLUMN,
    UPDATE_NARRATION_STRUCTURE_AND_VARIATIONDATA_FAILURE,
    UPDATE_NARRATION_STRUCTURE_AND_VARIATIONDATA_PENDING,
    UPDATE_NARRATION_STRUCTURE_AND_VARIATIONDATA_SUCCESS
} from "./projectNarrationsActions";
import {
    LOADING_PROGRAM_WIREFRAMES_SUCCESS,
    LOADING_PROJECT_WIREFRAMES_FROM_UIASSET,
    UPDATE_NARRATION_POSTGRES_ID_SUCCESS
} from "../projectsWireframesActions";
import StateReaderUtils from "../../../common/StateReaderUtils";
import NarrationsModelUtils from "./NarrationsModelUtils";
import { generateValueSetProviders } from "./ValueSetAdapterUtils";
import { AssetTypes } from "../../../../../common/types/asset";
import { immutablyReplaceValue } from "../../../../../common/generalUtils";
import type { LoadingProgramWireframeSuccess } from "../projectsWireframesActions";
import type { Asset, ConvertedGqlRecordingAsset } from "../../../../../common/types/asset";
import type { Program } from "../../../../../common/types/program";

function projectNarrationsReducer(state, action) {
    switch (action.type) {
        case ADD_NARRATION_SUCCESS: {
            let { projectName, narrationId, sceneId, structure, variationDataMap, postgresNarrationId, graphQLUpdated } = action.payload;
            const wireframes = StateReaderUtils.getWireFrame(state, projectName);
            const dataElements = StateReaderUtils.getProjectDataElementsFromWireframes(wireframes);
            const prioritizedLists = StateReaderUtils.getScenePrioritizedList(wireframes, sceneId);
            let valueSetProviders = generateValueSetProviders(dataElements, prioritizedLists);

            let narrationState: any = {
                updating: false,
                postgresNarrationId: postgresNarrationId,
                structure,
                variationDataMap,
                graphQLUpdated
            };

            narrationState.dimensions = NarrationsModelUtils.buildDimensions(narrationState.structure, valueSetProviders);
            narrationState.variations = NarrationsModelUtils.buildVariations(narrationState.structure, narrationState.variationDataMap, narrationState.dimensions, valueSetProviders);

            return updatedStateWithNewNarration(state, projectName, null, null, narrationId, narrationState);
        }

        case DELETE_NARRATION_SUCCESS: {
            let { projectName, narrationId } = action.payload;

            return updatedStateWithDeletedNarration(state, projectName, narrationId);
        }

        case LOAD_NARRATIONS_SUCCESS:
        case LOADING_PROGRAM_WIREFRAMES_SUCCESS: {
            let { projectName, narrations, stage, version } = action.payload as LoadingProgramWireframeSuccess;
            const wireframes = StateReaderUtils.getWireFrame(state, projectName, stage, version);
            const dataElements = StateReaderUtils.getProjectDataElementsFromWireframes(wireframes);
            const dataElementValueSetProviders = generateValueSetProviders(dataElements, []);
            const allPrioritizedLists = StateReaderUtils.getProjectPrioritizedLists(wireframes);
            const allValueSetProviders = generateValueSetProviders(dataElements, allPrioritizedLists);

            narrations.forEach((loadedNarration) => {
                let narrationId = loadedNarration.id;
                let dbNarration = loadedNarration.dbNarration;

                let dbStructure = dbNarration.structure;

                let dbDimensionData = dbStructure.dimensionData;
                let dbMergeData = dbStructure.mergeData;
                let dbRepresentativeKey = dbStructure.representativeKey;
                let dbCreativeOverride = dbStructure.creativeOverride;
                let dbVariationData = dbNarration.variationData;

                let structure = NarrationsModelUtils.dbBuildStructureFromDBData(dbDimensionData, dbMergeData, dbRepresentativeKey, dbCreativeOverride);
                let variationDataMap = NarrationsModelUtils.dbBuildVariationDataMapFromVariationData(dbVariationData);

                let narrationState: any = {
                    graphQLUpdated: dbNarration.graphQLUpdated,
                    updating: false,
                    postgresNarrationId: dbNarration.postgresNarrationId,
                    structure: structure,
                    variationDataMap: variationDataMap
                };

                let sceneId = StateReaderUtils.getSceneIdOfNarration(wireframes, narrationId);
                let valueSetProviders;
                if (sceneId) {
                    let scenePrioritizedLists = StateReaderUtils.getScenePrioritizedList(wireframes, sceneId);
                    let sceneValueSetProviders = generateValueSetProviders([], scenePrioritizedLists);
                    valueSetProviders = [...dataElementValueSetProviders, ...sceneValueSetProviders];
                }
                else {
                    // This case happens when duplication a scene / scene part - the action LOAD_NARRATIONS_SUCCESS is called.
                    valueSetProviders = allValueSetProviders;
                }

                narrationState.dimensions = NarrationsModelUtils.buildDimensions(narrationState.structure, valueSetProviders);
                narrationState.variations = NarrationsModelUtils.buildVariations(narrationState.structure, narrationState.variationDataMap, narrationState.dimensions, valueSetProviders);

                state = updatedStateWithNewNarration(state, projectName, stage, version, narrationId, narrationState);
            });

            state = {
                ...state,
                lastRelevantNarrationUpdateTimeStamp: Date.now(),
                showMutedNarrationRows: true,
                showNarrationNoteColumn: true,
                showNarrationStatusColumn: false
            };
            return state;
        }

        case LOADING_PROJECT_WIREFRAMES_FROM_UIASSET: {
            let { projectName, data, stage, version } = action.payload;

            if (data.narrations) {
                const wireframes = StateReaderUtils.getWireFrame(state, projectName, stage, version);
                const dataElements = StateReaderUtils.getProjectDataElementsFromWireframes(wireframes);
                const prioritizedLists = StateReaderUtils.getProjectPrioritizedLists(wireframes);
                let valueSetProviders = generateValueSetProviders(dataElements, prioritizedLists);

                Object.keys(data.narrations.byId).forEach((narrationId) => {
                    let serializedNarrationState = data.narrations.byId[narrationId];

                    let narrationState: any = {
                        updating: false,
                        structure: NarrationsModelUtils.unserializeStructure(serializedNarrationState.structure),
                        variationDataMap: NarrationsModelUtils.unserializeVariationDataMap(serializedNarrationState.variationDataMap)
                    };

                    narrationState.dimensions = NarrationsModelUtils.buildDimensions(narrationState.structure, valueSetProviders);
                    narrationState.variations = NarrationsModelUtils.buildVariations(narrationState.structure, narrationState.variationDataMap, narrationState.dimensions, valueSetProviders);

                    state = updatedStateWithNewNarration(state, projectName, stage, version, narrationId, narrationState);
                });

                state = {
                    ...state,
                    lastRelevantNarrationUpdateTimeStamp: Date.now(),
                    showMutedNarrationRows: true,
                    showNarrationNoteColumn: true,
                    showNarrationStatusColumn: false
                };
            }

            return state;
        }

        case UPDATE_NARRATION_STRUCTURE_AND_VARIATIONDATA_PENDING: {
            let { projectName, narrationId, variationDataUpdates } = action.payload;
            let wireframes = StateReaderUtils.getWireFrame(state, projectName);

            let narrationState = { ...wireframes.narrations.byId[narrationId] };
            narrationState.updating = true;

            if (variationDataUpdates) {
                Object.keys(variationDataUpdates).forEach((variationKey) => {
                    let variationDataUpdate = variationDataUpdates[variationKey];
                    narrationState.variationDataMap = NarrationsModelUtils.updateVariationDataMap(narrationState.variationDataMap, variationKey, variationDataUpdate);

                    let variationData = narrationState.variationDataMap.get(variationKey);
                    if (variationData) {
                        narrationState.variations = NarrationsModelUtils.applyVariationDataToVariations(narrationState.variations, variationKey, variationData);
                    }
                });
            }

            return updatedStateWithNarration(state, projectName, narrationId, narrationState);
        }

        case UPDATE_NARRATION_STRUCTURE_AND_VARIATIONDATA_SUCCESS: {
            let { projectName, sceneId, narrationId, structureUpdate, mayCauseChangeInAssetLibrary, graphQLUpdated } = action.payload;
            const wireframes = StateReaderUtils.getWireFrame(state, projectName);
            const dataElements = StateReaderUtils.getProjectDataElementsFromWireframes(wireframes);
            const prioritizedLists = StateReaderUtils.getScenePrioritizedList(wireframes, sceneId);
            let valueSetProviders = generateValueSetProviders(dataElements, prioritizedLists);

            let narrationState = { ...wireframes.narrations.byId[narrationId], graphQLUpdated };
            narrationState.updating = false;

            if (structureUpdate) {
                narrationState.structure = NarrationsModelUtils.updateStructure(narrationState.structure, structureUpdate);
                narrationState.dimensions = NarrationsModelUtils.buildDimensions(narrationState.structure, valueSetProviders);
                narrationState.variations = NarrationsModelUtils.buildVariations(narrationState.structure, narrationState.variationDataMap, narrationState.dimensions, valueSetProviders);
            }

            return updatedStateWithNarration(state, projectName, narrationId, narrationState, mayCauseChangeInAssetLibrary);
        }

        case UPDATE_NARRATION_STRUCTURE_AND_VARIATIONDATA_FAILURE: {
            let { projectName, narrationId, variationReverts, variationDataMapReverts } = action.payload;
            let wireframes = StateReaderUtils.getWireFrame(state, projectName);

            let narrationState = { ...wireframes.narrations.byId[narrationId] };
            narrationState.updating = false;

            if (variationReverts) {
                Object.keys(variationReverts).forEach((variationKey) => {
                    narrationState.variations = NarrationsModelUtils.applyVariationDataToVariations(narrationState.variations, variationKey, variationReverts[variationKey]);
                });
            }

            if (variationDataMapReverts) {
                Object.keys(variationDataMapReverts).forEach((variationKey) => {
                    narrationState.variationDataMap = NarrationsModelUtils.applyVariationDataToVariationDataMap(narrationState.variationDataMap, variationKey, variationDataMapReverts[variationKey]);
                });
            }

            return updatedStateWithNarration(state, projectName, narrationId, narrationState);
        }

        case REMOVE_NARRATION_DIMENSION: {
            let { projectName, narrationId, sceneId, index } = action.payload;
            const wireframes = StateReaderUtils.getWireFrame(state, projectName);
            const dataElements = StateReaderUtils.getProjectDataElementsFromWireframes(wireframes);
            const prioritizedLists = StateReaderUtils.getScenePrioritizedList(wireframes, sceneId);
            let valueSetProviders = generateValueSetProviders(dataElements, prioritizedLists);

            let narrationState = { ...wireframes.narrations.byId[narrationId] };
            narrationState.dimensions = NarrationsModelUtils.removeDimension(narrationState.dimensions, index);
            narrationState.variations = NarrationsModelUtils.buildVariations(narrationState.structure, narrationState.variationDataMap, narrationState.dimensions, valueSetProviders);

            return updatedStateWithNarration(state, projectName, narrationId, narrationState, true);
        }

        case UPDATE_NARRATION_POSTGRES_ID_SUCCESS: {
            const { accountId, projectName, narrationId, postgresId, isPostgresOnly } = action.payload;
            const wireframes = StateReaderUtils.getWireFrame(state, projectName);
            let narrationState = { ...wireframes.narrations.byId[narrationId] };

            if (!isPostgresOnly) {
                narrationState.postgresNarrationId = postgresId;
                return updatedStateWithNarration(state, projectName, narrationId, narrationState);
            }
            else {
                const newAssetsByIds: Record<string, ConvertedGqlRecordingAsset> = {};
                const newAssetAllIds: string[] = [];

                state.assets.allIds.forEach((assetId: string) => {
                    const asset: Asset = state.assets.byId[assetId];
                    if (asset.type === AssetTypes.recording && (asset as ConvertedGqlRecordingAsset).narrationId === narrationId) {
                        let newName = asset.name.replace(narrationId, postgresId);
                        let newAssetId = StateReaderUtils.getAssetUniqueId(accountId, projectName, AssetTypes.recording, newName);
                        newAssetsByIds[newAssetId] = {
                            ...asset as ConvertedGqlRecordingAsset,
                            name: newName,
                            assetId: newAssetId,
                            narrationId: postgresId
                        };
                        newAssetAllIds.push(newAssetId);
                    }
                    else {
                        newAssetsByIds[assetId] = asset as ConvertedGqlRecordingAsset;
                        newAssetAllIds.push(assetId);
                    }
                });

                const program: Program = StateReaderUtils.getProject(state, projectName);
                const newProgramAssets: string[] = [];

                program.assets.forEach(assetId => {
                    if (assetId.includes(narrationId)) {
                        newProgramAssets.push(assetId.replace(narrationId, postgresId));
                    }
                    else {
                        newProgramAssets.push(assetId);
                    }
                });

                let newState = immutablyReplaceValue(state, ["assets", "byId"], newAssetsByIds);
                newState = immutablyReplaceValue(newState, ["assets", "allIds"], newAssetAllIds);
                newState = immutablyReplaceValue(newState, ["projects", "byName", projectName, "assets"], newProgramAssets);

                return updatedStateWithNarration(newState, projectName, postgresId, narrationState);
            }
        }

        case CANCEL_REMOVE_NARRATION_DIMENSION:
        case NARRATION_USED_VALUE_SET_PROVIDER_CHANGED: {
            let { projectName, sceneId, narrationId } = action.payload;
            const wireframes = StateReaderUtils.getWireFrame(state, projectName);
            const dataElements = StateReaderUtils.getProjectDataElementsFromWireframes(wireframes);
            const prioritizedLists = StateReaderUtils.getScenePrioritizedList(wireframes, sceneId);
            let valueSetProviders = generateValueSetProviders(dataElements, prioritizedLists);

            let narrationState = { ...wireframes.narrations.byId[narrationId] };
            narrationState.dimensions = NarrationsModelUtils.buildDimensions(narrationState.structure, valueSetProviders);
            narrationState.variations = NarrationsModelUtils.buildVariations(narrationState.structure, narrationState.variationDataMap, narrationState.dimensions, valueSetProviders);

            return updatedStateWithNarration(state, projectName, narrationId, narrationState);
        }

        case SET_SHOW_MUTED_ROWS:
            return { ...state, showMutedNarrationRows: action.payload.show };
        case SET_SHOW_NOTE_COLUMN:
            return { ...state, showNarrationNoteColumn: action.payload.show };
        case SET_SHOW_STATUS_COLUMN:
            return { ...state, showNarrationStatusColumn: action.payload.show };

        default:
            return state;
    }
}

function updatedStateWithNarration(state, projectName, narrationId, narrationState, mayCauseChangeInAssetLibrary?) {
    let wireframes = StateReaderUtils.getWireFrame(state, projectName);
    let wireframesNarrations = _getOrInitWireFramesNarration(wireframes);
    let newState = {
        ...state,
        wireframes: {
            ...state.wireframes,
            byName: {
                ...state.wireframes.byName,
                [projectName]: {
                    ...wireframes,
                    narrations: {
                        ...wireframesNarrations,
                        byId: {
                            ...wireframesNarrations.byId,
                            [narrationId]: { ...narrationState }
                        }
                    }
                }
            }
        }
    };

    if (mayCauseChangeInAssetLibrary) {
        newState.lastRelevantNarrationUpdateTimeStamp = Date.now();
    }

    return newState;
}

function updatedStateWithNewNarration(state, projectName, stage, version, narrationId, narrationState) {
    let wireframes = StateReaderUtils.getWireFrame(state, projectName, stage, version);
    let wireframesNarrations = _getOrInitWireFramesNarration(wireframes);

    let wireframeKey;
    if (stage) {
        wireframeKey = `${stage}wireframes`;
    }
    else if (version) {
        wireframeKey = "versionwireframes";
    }
    else {
        wireframeKey = "wireframes";
    }

    const narrationObj = {
        ...wireframesNarrations,
        allIds: [...wireframesNarrations.allIds, narrationId],
        byId: {
            ...wireframesNarrations.byId,
            [narrationId]: { ...narrationState }
        }
    };
    const byNameObj =
        stage === "diff"
            ? {
                ...state[wireframeKey].byName[projectName],
                [version]: {
                    ...wireframes,
                    narrations: narrationObj
                }
            }
            : {
                ...wireframes,
                narrations: narrationObj
            };

    return {
        ...state,
        [wireframeKey]: {
            ...state[wireframeKey],
            byName: {
                ...state[wireframeKey].byName,
                [projectName]: byNameObj
            }
        }
    };
}

function updatedStateWithDeletedNarration(state, projectName, narrationId) {
    let wireframes = StateReaderUtils.getWireFrame(state, projectName);
    let newState = {
        ...state,
        wireframes: {
            ...state.wireframes,
            byName: {
                ...state.wireframes.byName,
                [projectName]: {
                    ...wireframes,
                    narrations: {
                        ...wireframes.narrations,
                        allIds: wireframes.narrations.allIds.filter((id) => id !== narrationId)
                    }
                }
            }
        },
        lastRelevantNarrationUpdateTimeStamp: Date.now()
    };
    let newWireframes = StateReaderUtils.getWireFrame(newState, projectName);
    delete newWireframes.narrations.byId[narrationId];
    return newState;
}

function _getOrInitWireFramesNarration(wireframes) {
    return wireframes.narrations ? { ...wireframes.narrations } : { byId: {}, allIds: [] };
}

export default projectNarrationsReducer;
