import url from "url";
import { isNewNav } from "../../utils/newNavUtils";
import { matchPath } from "react-router";
import { legacyAccountIdProjectsProjectNamePath, legacyProgramBasePath, newNavVideoPagePath } from "@sundaysky/smartvideo-hub-urls";
class UrlUtils {
    static append(from, to) {
        if (from[from.length - 1] !== "/")
            from += "/";
        return url.resolve(from, to);
    }
    static getAccountAndProgramFromPath() {
        var _a, _b, _c, _d, _e, _f;
        const match = matchPath(window.location.pathname, {
            path: legacyProgramBasePath.pattern,
            exact: false,
            strict: false
        });
        if (match) {
            return {
                accountId: (_a = match === null || match === void 0 ? void 0 : match.params) === null || _a === void 0 ? void 0 : _a.accountId,
                program: (_b = match === null || match === void 0 ? void 0 : match.params) === null || _b === void 0 ? void 0 : _b.programId,
                isLegacy: false
            };
        }
        const newNavMatch = matchPath(window.location.pathname, {
            path: newNavVideoPagePath.pattern,
            exact: false,
            strict: false
        });
        if (newNavMatch) {
            return {
                accountId: (_c = newNavMatch === null || newNavMatch === void 0 ? void 0 : newNavMatch.params) === null || _c === void 0 ? void 0 : _c.accountId,
                program: (_d = newNavMatch === null || newNavMatch === void 0 ? void 0 : newNavMatch.params) === null || _d === void 0 ? void 0 : _d.videoId,
                isLegacy: false
            };
        }
        const legacyMatch = matchPath(window.location.pathname, {
            path: legacyAccountIdProjectsProjectNamePath.pattern,
            exact: false,
            strict: false
        });
        return {
            accountId: (_e = legacyMatch === null || legacyMatch === void 0 ? void 0 : legacyMatch.params) === null || _e === void 0 ? void 0 : _e.accountId,
            program: (_f = legacyMatch === null || legacyMatch === void 0 ? void 0 : legacyMatch.params) === null || _f === void 0 ? void 0 : _f.projectName,
            isLegacy: true
        };
    }
}
export default UrlUtils;
