import { CANVAS_DIMENSIONS as canvas } from "../../../components/legacyCommon/Consts";
export function getGroupX(placeholders) {
    const xCoords = placeholders.map((p) => p.x);
    const x = xCoords.sort((a, b) => a - b)[0];
    return x <= 6 ? 0 : x - 6;
}
export function getGroupWidth(groupX, placeholders) {
    const rightX = placeholders.map((p) => p.x + p.width);
    const sortedX = rightX.sort((a, b) => b - a);
    const grpWidth = sortedX[0] - groupX + 5;
    return grpWidth + groupX <= canvas.width ? grpWidth : canvas.width - groupX;
}
export function getGroupY(placeholders) {
    const yCoords = placeholders.map((p) => p.y);
    const y = yCoords.sort((a, b) => a - b)[0];
    return y <= 5 ? 0 : y - 5;
}
export function getGroupHeight(groupY, placeholders) {
    const heights = placeholders.map((p) => p.y + p.height);
    const sortedHeights = heights.sort((a, b) => b - a);
    const grpHeight = sortedHeights[0] - groupY + 5;
    return grpHeight + groupY <= canvas.height ? grpHeight : canvas.height - groupY;
}
export function calcGroupCoords(placeholders) {
    const x = getGroupX(placeholders);
    const y = getGroupY(placeholders);
    return {
        x,
        y,
        width: getGroupWidth(x, placeholders),
        height: getGroupHeight(y, placeholders)
    };
}
