import { legacyAccountAccountIdProgramProgramIdNavigationItemTypePath, StudioMainCategoriesPaths } from "@sundaysky/smartvideo-hub-urls";
import type { onSaveArgs } from "../../types";
import type { ProgramDialogProps } from "../../../../components/framework/dialogs/ProgramDialog";
import type {
    GqlClientProgram } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import {
    withCreateProgram,
    withCreateProgramAndDraft,
    withDeleteProgram,
    withGetProgram,
    withGetProgramMetadata, withUpdateFrameworkProgram,
    withUpdateProgramAndDraft
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import type { FrameworkContainerProps } from "../../../../components/framework/FrameworkContainer";
import type { withGqlMutationProps } from "../../../../components/framework/WithGqlMutations";
import type { CreateProjectContainerProps } from "../../../../components/newProject/CreateProjectContainer";
import type { MainContainerProps } from "../../../../components/main/MainContainer";

import { roles } from "../../../../../common/rolesPermissionsConst";

const onSave = (args: onSaveArgs) => {
    const { data, createCB, updateCB, dryRun } = args;
    const isNew = !data.id;
    if (isNew) {
        createCB({ variables: { input: data } });
    }
    else {
        updateCB({ variables: { input: data, dryRun } });
    }
};

const navigateToProgramAfterCreation = (
    loadProjectSummaries: (accountId: string, forceLoad?: boolean, cb?) => void,
    userRoles: string[],
    newlyCreatedProgram: Partial<Pick<GqlClientProgram, "accountId" | "id" | "legacyBuilderProgramId">>,
    history,
    studioMainCategoriesPath?: StudioMainCategoriesPaths
): void => {
    loadProjectSummaries(newlyCreatedProgram.accountId, true, () => {
        let path: string;
        const redirectToFramework: boolean = userRoles.includes(roles.admin) || userRoles.includes(roles.frameworkEditor) || userRoles.includes(roles.frameworkViewer);
        if (studioMainCategoriesPath) {
            path = legacyAccountAccountIdProgramProgramIdNavigationItemTypePath({
                accountId: newlyCreatedProgram.accountId,
                programId: newlyCreatedProgram.id,
                // @ts-ignore
                navigationItemType: studioMainCategoriesPath
            });
        }
        else if (redirectToFramework) {
            path = legacyAccountAccountIdProgramProgramIdNavigationItemTypePath({
                accountId: newlyCreatedProgram.accountId,
                programId: newlyCreatedProgram.id,
                navigationItemType: StudioMainCategoriesPaths.Framework
            });
        }
        else {
            path = legacyAccountAccountIdProgramProgramIdNavigationItemTypePath({
                accountId: newlyCreatedProgram.accountId,
                programId: newlyCreatedProgram.legacyBuilderProgramId,
                navigationItemType: StudioMainCategoriesPaths.Builder
            });
        }
        history.push(path);
    });
};

const Program = {
    queries: {
        data: withGetProgram<FrameworkContainerProps>({
            name: "queryProgram",
            options: ({ programId }) => ({ variables: { id: programId } }),
            skip: ({ programId }) => !programId
        }),
        metadata: withGetProgramMetadata<ProgramDialogProps>({
            name: "queryProgramMetadata",
            options: ({ programId }) => ({ variables: { id: programId } }),
            skip: ({ programId }) => !programId
        })
    },
    mutations: {
        // TODO remove with feature flag newProgramAndFrameworkDrafts
        createProgramAndDraft: withCreateProgramAndDraft<withGqlMutationProps<ProgramDialogProps>>({
            name: "createProgramAndDraft",
            options: ({ setMutationResult, setNewProgramId }) => ({
                onCompleted: ({ createProgramAndDraft }) => {
                    setMutationResult(createProgramAndDraft.result);
                    setNewProgramId(createProgramAndDraft.program.id);
                }
            })
        }),
        // TODO remove with feature flag newProgramAndFrameworkDrafts
        updateProgramAndDraft: withUpdateProgramAndDraft<withGqlMutationProps<ProgramDialogProps>>({
            name: "updateProgramAndDraft",
            options: ({ setMutationResult }) => ({
                onCompleted: ({ updateProgramAndDraft }) => {
                    setMutationResult(updateProgramAndDraft.result);
                }
            })
        }),

        create: withCreateProgram<CreateProjectContainerProps>({
            name: "createProgram",
            options: ({ loadProjectSummaries, history, userRoles }) => ({
                onCompleted: ({ createProgram }) => {
                    navigateToProgramAfterCreation(loadProjectSummaries, userRoles, createProgram.program, history);
                }
            })
        }),
        // This mutation adds a Builder to a program that has only Framework, then redirects to Builder
        update: withUpdateFrameworkProgram<MainContainerProps>({
            name: "updateFrameworkProgram",
            options: ({ loadProjectSummaries, match, history }) => ({
                onCompleted: ({ updateProgram }) => {
                    if (updateProgram.__typename === "UpdateProgramOutputSuccess") {
                        loadProjectSummaries(updateProgram.program.accountId, true, () => {
                            const path = legacyAccountAccountIdProgramProgramIdNavigationItemTypePath({
                                accountId: match.params.accountId,
                                programId: updateProgram.program.name,
                                navigationItemType: StudioMainCategoriesPaths.Builder
                            });
                            history.push(path);
                        });
                    }
                }
            })
        }),
        delete: withDeleteProgram({
            name: "deleteProgram"
        })
    },
    onSave,
    navigateToProgramAfterCreation
};

export default Program;
