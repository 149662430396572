import { IssueSeverity } from "./validationManager";
import { VALIDATION_STATUS } from "../vlx/consts";

export default class ValidationUtils {
    static convertIssueSeverityToValidationStatus(severity: IssueSeverity): string {
        switch (severity) {
            case IssueSeverity.ERROR:
                return VALIDATION_STATUS.Invalid;
            case IssueSeverity.WARNING:
                return VALIDATION_STATUS.Partial;
            default:
                return VALIDATION_STATUS.Valid;
        }
    }
}
