import BaseServices from "./BaseServices";
import { EDITING_MODE, STAGE_MODE } from "./Consts";
import type { Asset } from "../../../common/types/asset";


class PromptToVideoServices extends BaseServices {
    constructor(authService, builderModeRetriever) {
        super(authService, builderModeRetriever);
    }

    promptToVideo(accountId: string, promptText: string): Promise<any> {
        const formData: FormData = new FormData();
        formData.append("promptText", promptText);
        return this.post<Asset>(
            [EDITING_MODE, STAGE_MODE],
            `/rest/${accountId}/promptToVideo`,
            formData,
            { "Content-Type": "multipart/form-data" });
    }
}

export default PromptToVideoServices;
