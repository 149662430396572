import type { GetDependencyIdFunction } from "./baseEntity";
import { BaseEntity } from "./baseEntity";
import StateReaderUtils from "../common/StateReaderUtils";
import type { GetValidationResultFunction, Issue, ValidationResult } from "../validations/validationManager";
import { IssueCodes } from "../validations/validationManager";
import type { Collector, CollectorArgs, IEntity, EntityInstance } from "./definitions";
import { EntityTypes } from "./definitions";
import { removeMasterFromScenes } from "../common/generalUtils";

export default class SceneAnimationEntity extends BaseEntity implements IEntity {
    constructor() {
        super(EntityTypes.SCENE_ANIMATION);
    }

    static generateId = (sceneId: string): string => {
        return sceneId + "_animation";
    };

    collector: Collector = (args: CollectorArgs): EntityInstance[] => {
        const { context, previousContext } = args;
        let scenes: EntityInstance[] = [];
        let allScenes = StateReaderUtils.getProjectWireframeScenes(context.state, context.projectName, context.stage, context.version);
        if (allScenes) {
            allScenes = removeMasterFromScenes(allScenes);
            const wireframes = StateReaderUtils.getWireFrame(context.state, context.projectName, context.stage, context.version);
            Object.values(allScenes).forEach((scene: any) => {
                const sceneLogic = StateReaderUtils.getAllProgramLogicFromWireframes(wireframes, scene.id);
                const sceneAnimationLogicId = SceneAnimationEntity.generateId(scene.id);
                const sceneAnimationLogic = sceneLogic && sceneLogic[sceneAnimationLogicId];
                scenes.push({
                    id: sceneAnimationLogicId,
                    validate: (prevValidationResult: ValidationResult, getValidationResultFunction: GetValidationResultFunction): ValidationResult => {
                        return this.doValidate(sceneAnimationLogic || {}, getValidationResultFunction, scene);
                    }
                });
            });
        }
        return scenes;
    };

    doValidate = (sceneAnimationLogic: any, getValidationResult: GetValidationResultFunction, scene: any): ValidationResult => {
        const type: EntityTypes = this.getType();
        const id = SceneAnimationEntity.generateId(scene.id);
        let sceneAnimationValidationResult: ValidationResult = { type, id, issues: [] };

        // Check scene animation logic
        if (sceneAnimationLogic) {
            const getDependencyId: GetDependencyIdFunction = () => id;
            let issue: Issue = this.getDependenciesIssue([sceneAnimationLogic], EntityTypes.LOGIC, getDependencyId, getValidationResult, IssueCodes.SCENE_VALIDATION_LOGIC_ERROR);
            if (issue) {
                sceneAnimationValidationResult.issues.push(issue);
                sceneAnimationValidationResult.severity = issue.severity;
            }
        }
        return sceneAnimationValidationResult;
    };
}
