import BaseServices from "./BaseServices";
import { EDITING_MODE, STAGE_MODE } from "./Consts";

class RaasServices extends BaseServices {
    constructor(authService, builderModeRetriever) {
        super(authService, builderModeRetriever);
    }

    raasRequest(videospec) {
        return this.post([EDITING_MODE, STAGE_MODE], "/rest/raas", videospec);
    }
}

export default RaasServices;
