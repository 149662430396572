import BaseServices from "./BaseServices";
import { EDITING_MODE, STAGE_MODE } from "./Consts";
import type { TestSftpConnectionBody } from "../../../common/types/restApiTypes";

class TestSftpConnectionService extends BaseServices {
    constructor(authService, builderModeRetriever) {
        super(authService, builderModeRetriever);
    }

    testConnectionRequest(requestBody: TestSftpConnectionBody) {
        return this.post<{ statusCode: number, body: string }>([EDITING_MODE, STAGE_MODE], "/rest/testSftpConnection", requestBody);
    }
}

export default TestSftpConnectionService;