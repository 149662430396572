import { createAction } from "redux-actions";
import { valueSetsChanged } from "../projects/projectWireframes/projectsWireframesActions";
import { reportError } from "../common/commonActions";
import StateReaderUtils from "../common/StateReaderUtils";
import {
    buildDataElement,
    changeID,
    findDataElementById,
    getDataElementDisplayName,
    normalizeDataElement
} from "./DataElementsManager";
import { ASSET_TYPES } from "../vlx/consts";
import { getDataElementIdsFromMappingTable, normalizeLogic } from "../vlx/editorLogicUtils";
import HubMappingTable from "../../components/HubEditor/rightPanels/HubMappingTable";
import { updateDataTableMutation, uploadingProjectAssetsSuccess } from "../projects/projectAssets/projectAssetsActions";
import { LogicContainers } from "../../../common/commonConst";
import StudioDataManager from "./StudioDataManager";
import type { DataElement, UsedIn } from "../../../common/types/dataElement";
import { DataElementOrigins, DataElementStatuses, TagNames } from "../../../common/types/dataElement";
import type { ProgramSummary } from "../../../common/types/program";
import type {
    ConvertFeedDataElementToDerivedMutationResult,
    CreateCreativeDataElementMutationResult,
    CreateDerivedDataElementMutationResult,
    CreateFeedDataElementMutationResult,
    GqlClientConvertFeedDataElementToDerivedMutation,
    GqlClientConvertFeedDataElementToDerivedOutputSuccess,
    GqlClientDataTableFragment, GqlClientDeleteDataElementOutput, GqlClientDeleteDataElementOutputRefusal,
    GqlClientGenericDataElement,
    GqlClientUpdateCreativeDataElementInput,
    GqlClientUpdateCreativeDataElementMutation,
    GqlClientUpdateCreativeDataElementOutputSuccess,
    GqlClientUpdateDerivedDataElementInput,
    GqlClientUpdateDerivedDataElementMutation,
    GqlClientUpdateDerivedDataElementOutputSuccess,
    GqlClientUpdateFeedDataElementInput,
    GqlClientUpdateFeedDataElementMutation,
    GqlClientUpdateFeedDataElementOutputSuccess,
    UpdateCreativeDataElementMutationResult,
    UpdateDerivedDataElementMutationResult,
    UpdateFeedDataElementMutationResult
} from "../../graphql/graphqlGeneratedTypes/graphqlClient";
import {
    ConvertFeedDataElementToDerivedDocument,
    CreateCreativeDataElementDocument,
    CreateDerivedDataElementDocument,
    CreateFeedDataElementDocument,
    GqlClientConvertFeedDataElementToDerivedResult, GqlClientDeleteDataElementResult,
    GqlClientUpdateCreativeDataElementResult,
    GqlClientUpdateDerivedDataElementResult,
    GqlClientUpdateFeedDataElementResult,
    UpdateCreativeDataElementDocument,
    UpdateDerivedDataElementDocument,
    UpdateFeedDataElementDocument
} from "../../graphql/graphqlGeneratedTypes/graphqlClient";

import { IGNORE_SERVER_ERRORS, IGNORE_UPDATED } from "../common/Consts";
import type { LogicJSON, ThunkServices } from "../common/types";
import { BulkId } from "../common/types";
import { convertDataElementStatus, convertDataElementType } from "../builder/graphql/convertTypes";
import { deleteMultipleDataElements } from "../builder/graphql/deleteMultipleDataElements";
import { v4 as uuid } from "uuid";
import type { ValueSet } from "../../../common/types/logic";
import type { DataTable } from "../../../common/types/dataTable";
import MappingTablesUtils from "../MappingTables/MappingTablesUtils";
import { AssetTypes } from "../../../common/types/asset";
import { buildDataTableFromGql, getIdFromGqlId } from "../common/convertGqlEntityToWireframesUtils";

export const UPDATING_PROJECT_WIREFRAMES_DATA_ELEMENTS_SUCCESS = "UPDATING_PROJECT_WIREFRAMES_DATA_ELEMENTS_SUCCESS";
export const UPDATE_DATA_ELEMENT_SUCCESS = "UPDATE_DATA_ELEMENT_SUCCESS";
export const ADD_DATA_ELEMENT_SUCCESS = "ADD_DATA_ELEMENT_SUCCESS";
export const DELETE_DATA_ELEMENTS_SUCCESS = "DELETE_DATA_ELEMENTS_SUCCESS";
// export const CALCULATE_LOGIC_VALIDATIONS = Symbol("CALCULATE_LOGIC_VALIDATIONS");
export const UPDATE_DATA_ELEMENT_ALL_DONE_SUCCESS = "UPDATE_DATA_ELEMENT_ALL_DONE_SUCCESS";

export const updatingProjectWireFramesDataElementsSuccess = createAction(UPDATING_PROJECT_WIREFRAMES_DATA_ELEMENTS_SUCCESS, (accountId, projectName, dataElements: DataElement[], stage, version) => {
    return { accountId, projectName, dataElements, stage, version };
}, undefined);

export const updateDataElementSuccess = createAction(UPDATE_DATA_ELEMENT_SUCCESS, function(accountId, projectName, dataElement, logic) {
    return { accountId, projectName, dataElement, logic };
});
export const addDataElementSuccess = createAction(ADD_DATA_ELEMENT_SUCCESS, function(accountId, projectName, dataElement, logic) {
    return { accountId, projectName, dataElement, logic };
});
export const deleteDataElementsSuccess = createAction(DELETE_DATA_ELEMENTS_SUCCESS, function(accountId, projectName, dataElementIds) {
    return { accountId, projectName, dataElementIds };
});

function nameHadChanged(currentDataElement: DataElement, newDataElement: DataElement) {
    return getDataElementDisplayName(currentDataElement) !== getDataElementDisplayName(newDataElement);
}

function valueSetHadChanged(currentDataElement: DataElement, newDataElement: DataElement) {
    // TODO: We can't use the getValueSet on both because it newDataElement returns false answer.
    // TODO: So since it's just a optimization, let's keep it 'true' until we find a solution
    return true;
}

type GQLUpdateDataElementResult =
    | UpdateFeedDataElementMutationResult
    | UpdateCreativeDataElementMutationResult
    | UpdateDerivedDataElementMutationResult
    | ConvertFeedDataElementToDerivedMutationResult;
type GQLCreateDataElementResult = CreateFeedDataElementMutationResult | CreateCreativeDataElementMutationResult | CreateDerivedDataElementMutationResult;
type GQLPartialDataElement = Pick<GqlClientGenericDataElement, "id" | "updated">;

const gqlUpdateDataElementPromise = (state, accountId: string, projectName: string, dataElementToUpdate: DataElement, services, logic?: LogicJSON): Promise<GQLUpdateDataElementResult> => {
    const dataElementId = dataElementToUpdate.id;
    const dataElements = StateReaderUtils.getProjectDataElements(state, projectName);
    const oldDataElement = findDataElementById(dataElementId, dataElements);

    let gqlPromise: Promise<GQLUpdateDataElementResult>;

    let programSummary: ProgramSummary = StateReaderUtils.getProgramSummaries(state).find((ps: ProgramSummary) => ps.projectIds.builders === projectName);

    if (oldDataElement.origin === DataElementOrigins.Feed && dataElementToUpdate.origin === DataElementOrigins.Derived) {
        // 'Convert' mutation
        gqlPromise = services.graphQlClient.mutate({
            mutation: ConvertFeedDataElementToDerivedDocument,
            variables: {
                input: {
                    id: `${programSummary.projectIds.builderDraftVersionId}|${dataElementId}`,
                    updated: IGNORE_UPDATED,
                    logic: logic
                },
                dryRun: false,
                [IGNORE_SERVER_ERRORS]: true
            }
        });
    }
    else if (oldDataElement.origin === dataElementToUpdate.origin) {
        // 'Update' mutation
        let mutation;
        let input: GqlClientUpdateFeedDataElementInput | GqlClientUpdateDerivedDataElementInput | GqlClientUpdateCreativeDataElementInput;

        if (dataElementToUpdate.origin === DataElementOrigins.Feed) {
            mutation = UpdateFeedDataElementDocument;
            input = {
                id: `${programSummary.projectIds.builderDraftVersionId}|${dataElementId}`,
                updated: IGNORE_UPDATED,

                name: dataElementToUpdate.displayName,
                description: dataElementToUpdate.description,
                dataType: convertDataElementType(dataElementToUpdate.type),
                pii: dataElementToUpdate.tags && dataElementToUpdate.tags.includes(TagNames.PII),
                status: convertDataElementStatus(dataElementToUpdate.status),
                useValueSet: dataElementToUpdate.useValueSet,
                valueSet: dataElementToUpdate.valueSet && [...dataElementToUpdate.valueSet]
            };
        }
        else if (dataElementToUpdate.origin === DataElementOrigins.Derived) {
            mutation = UpdateDerivedDataElementDocument;
            input = {
                id: `${programSummary.projectIds.builderDraftVersionId}|${dataElementId}`,
                updated: IGNORE_UPDATED,
                name: dataElementToUpdate.displayName,
                description: dataElementToUpdate.description,
                dataType: convertDataElementType(dataElementToUpdate.type),
                pii: dataElementToUpdate.tags && dataElementToUpdate.tags.includes(TagNames.PII),
                logic: logic
            };
        }
        else if (dataElementToUpdate.origin === DataElementOrigins.Creative) {
            mutation = UpdateCreativeDataElementDocument;
            input = {
                id: `${programSummary.projectIds.builderDraftVersionId}|${dataElementId}`,
                updated: IGNORE_UPDATED,
                name: dataElementToUpdate.displayName,
                description: dataElementToUpdate.description,
                dataType: convertDataElementType(dataElementToUpdate.type),
                pii: dataElementToUpdate.tags && dataElementToUpdate.tags.includes(TagNames.PII),
                storyIds: dataElementToUpdate.assignedStories.map((storyId: string) => `${programSummary.projectIds.builderDraftVersionId}|${storyId}`),
                assignedToAllStories: dataElementToUpdate.assignedToAllStories,
                useValueSet: dataElementToUpdate.useValueSet,
                valueSet: dataElementToUpdate.valueSet && [...dataElementToUpdate.valueSet]
            };
        }

        gqlPromise = services.graphQlClient.mutate({
            mutation: mutation,
            variables: {
                input: input,
                dryRun: false,
                [IGNORE_SERVER_ERRORS]: true
            }
        });
    }

    return gqlPromise;
};

const getDataElementFromGQLResponse = (GQLResponse: GQLUpdateDataElementResult): GQLPartialDataElement | null => {
    if (GQLResponse.data.hasOwnProperty("updateFeedDataElement")) {
        const output = (GQLResponse.data as GqlClientUpdateFeedDataElementMutation).updateFeedDataElement;
        if (output.result === GqlClientUpdateFeedDataElementResult.SUCCESS) {
            return (output as GqlClientUpdateFeedDataElementOutputSuccess).feedDataElement;
        }
    }
    else if (GQLResponse.data.hasOwnProperty("updateDerivedDataElement")) {
        const output = (GQLResponse.data as GqlClientUpdateDerivedDataElementMutation).updateDerivedDataElement;
        if (output.result === GqlClientUpdateDerivedDataElementResult.SUCCESS) {
            return (output as GqlClientUpdateDerivedDataElementOutputSuccess).derivedDataElement;
        }
    }
    else if (GQLResponse.data.hasOwnProperty("updateCreativeDataElement")) {
        const output = (GQLResponse.data as GqlClientUpdateCreativeDataElementMutation).updateCreativeDataElement;
        if (output.result === GqlClientUpdateCreativeDataElementResult.SUCCESS) {
            return (output as GqlClientUpdateCreativeDataElementOutputSuccess).creativeDataElement;
        }
    }
    else if (GQLResponse.data.hasOwnProperty("convertFeedDataElementToDerived")) {
        const output = (GQLResponse.data as GqlClientConvertFeedDataElementToDerivedMutation).convertFeedDataElementToDerived;
        if (output.result === GqlClientConvertFeedDataElementToDerivedResult.SUCCESS) {
            return (output as GqlClientConvertFeedDataElementToDerivedOutputSuccess).derivedDataElement;
        }
    }
    return null;
};

export const updateDataElement = function(args) {
    const { accountId, projectName, dataElementId } = args;
    const dataElement: DataElement = args.data;
    const logic: LogicJSON | undefined = args.logic;
    let oldDataElement: DataElement;
    let dataElements: DataElement[];
    let derivedLogic;

    return (dispatch, getState, services) => {
        const areDataElementsFromGQL: boolean = StateReaderUtils.isProgramBulkDynamoMigrationDoneByLegacyId(getState(), projectName, BulkId.MostEntities);

        dataElements = StateReaderUtils.getProjectDataElements(getState(), projectName);
        derivedLogic = StateReaderUtils.getAllProgramLogic(getState(), projectName, LogicContainers.Derived);
        oldDataElement = findDataElementById(dataElementId, dataElements);

        let promise: Promise<null>;

        if (areDataElementsFromGQL) {
            promise = gqlUpdateDataElementPromise(getState(), accountId, projectName, dataElement, services, logic).then((GQLResponse: GQLUpdateDataElementResult) => {
                let returnedDataElement: GQLPartialDataElement = getDataElementFromGQLResponse(GQLResponse);

                if (!returnedDataElement) {
                    return Promise.reject("Cannot update the data element. Please refresh the page");
                }

                const dataElementToUpdate = { ...dataElement };
                dataElementToUpdate.updatedTime = new Date(returnedDataElement.updated).getTime();

                dispatch(updateDataElementSuccess(accountId, projectName, dataElementToUpdate, logic));
            });
        }
        else {
            let dataElementToUpdate: DataElement = normalizeDataElement(dataElement);
            let logicToUpdate: LogicJSON = normalizeLogic(logic, dataElements);

            promise = services.dataElements
                .updateDataElement(accountId, projectName, dataElementId, dataElementToUpdate)
                .then((dataElement) => {
                    dispatch(updateDataElementSuccess(accountId, projectName, dataElement, undefined));
                })
                .then(() => {
                    gqlUpdateDataElementPromise(getState(), accountId, projectName, dataElementToUpdate, services, logicToUpdate).catch(() => {});
                });
        }

        // The following 3 'thens' are workaround to update mapping tables that use this data element, in case the name was changed.
        // If and when we'll refactor mapping tables to work like narrations (with id's) we will no longer need those 'thens'
        promise
            .then(function() {
                let project = StateReaderUtils.getProject(getState(), projectName);
                let mappingTables: DataTable[] = StateReaderUtils.getProjectAssetsByType(project, getState().assets, ASSET_TYPES.mappingTable);
                let promises = [];
                if (nameHadChanged(oldDataElement, dataElement)) {
                    mappingTables.forEach((mappingTable) => {
                        let inputs = getDataElementIdsFromMappingTable(mappingTable);
                        let inputKey;
                        if (inputs.includes(dataElementId)) {
                            inputKey = dataElementId;
                        }
                        if (inputs.includes(getDataElementDisplayName(oldDataElement))) {
                            inputKey = getDataElementDisplayName(oldDataElement);
                        }
                        if (inputKey) {
                            let downloadPromise = MappingTablesUtils.fetchData(mappingTable, false, (url, cb) => services.downloadServices.downloadUrl(url).then(cb));
                            promises.push(Promise.all([mappingTable, inputKey, downloadPromise]));
                        }
                    });
                }
                return Promise.all(promises);
            })
            .then(function(promiseResults) {
                let uploadJsonPromises: Promise<DataTable>[] = [];
                promiseResults.map(([mappingTable, inputKey, mappingTableData]) => {
                    let newMappingTableData = HubMappingTable.renameInput(mappingTableData, inputKey, getDataElementDisplayName(dataElement));

                    if (StateReaderUtils.isProgramBulkDynamoMigrationDoneByLegacyId(getState(), projectName, BulkId.DataTables)) {
                        const promise = updateDataTableMutation(services, mappingTable.graphQLId, {}, newMappingTableData).then(result => {
                            if (result.data.updateDataTable.__typename === "UpdateDataTableOutputSuccess") {
                                const gqlDataTable: GqlClientDataTableFragment = result.data.updateDataTable.dataTable;
                                return buildDataTableFromGql(gqlDataTable);
                            }
                            return null;
                        });

                        uploadJsonPromises.push(promise);
                    }
                    else {
                        uploadJsonPromises.push(services.projectAssetsServices.uploadJsonAssetContent(accountId, projectName, mappingTable.name, newMappingTableData, AssetTypes.mappingTable, false));
                    }
                });
                return Promise.all(uploadJsonPromises);
            })
            .then(function(assets: DataTable[]) {
                assets.forEach((asset) => dispatch(uploadingProjectAssetsSuccess(asset, projectName, AssetTypes.mappingTable, undefined)));
            })
            ///////////////////////////////////////

            .then(function() {
                // Update the logic of all Derived data elements that use this data element in their logic
                if (valueSetHadChanged(oldDataElement, dataElement)) {
                    let studioDataManager = new StudioDataManager({ dataElements, derivedLogic });
                    studioDataManager.buildDerivedGraph(dataElements);

                    let dependentDerivedDataElementIds = studioDataManager.getDependentDerivedDataElementIds(dataElementId);
                    dependentDerivedDataElementIds.push(dataElementId);

                    dispatch(valueSetsChanged(accountId, projectName, dependentDerivedDataElementIds));
                }
            })

            .catch(function(err) {
                // setLoading(false, accountId, projectName);
                dispatch(reportError(err));
            });
    };
};

export const deleteDataElements = function(
    accountId: string,
    projectName: string,
    dataElementIds: string[],
    callBack: (s: string[]) => void,
    usedInEditorCallback?: (usedInData: UsedIn[]) => void
) {
    return (dispatch, getState, services) => {

        let programSummary: ProgramSummary = StateReaderUtils.getProgramSummaries(getState()).find((ps: ProgramSummary) => ps.projectIds.builders === projectName);
        const gqlReadMostEntities: boolean = StateReaderUtils.isProgramBulkDynamoMigrationDone(getState(), programSummary.id, BulkId.MostEntities);

        let dataElementGQLIds: string[] = dataElementIds.map((id) => programSummary.projectIds.builderDraftVersionId + "|" + id);

        let mutationParams = deleteMultipleDataElements(dataElementGQLIds);

        services.graphQlClient
            .mutate({
                mutation: mutationParams.mutation,
                variables: {
                    ...mutationParams.variables,
                    [IGNORE_SERVER_ERRORS]: true
                }
            })
            .then((response) => {
                if (gqlReadMostEntities) {
                    const dataElements = StateReaderUtils.getProjectDataElements(getState(), projectName);
                    const derivedLogic = StateReaderUtils.getAllProgramLogic(getState(), projectName, LogicContainers.Derived);

                    try {
                        if (typeof usedInEditorCallback === "function") {
                            const usedInData: UsedIn[] = Object.values(response.data)
                                .filter((mutationResult: GqlClientDeleteDataElementOutput) => mutationResult.result === GqlClientDeleteDataElementResult.USED_IN_REFUSAL)
                                .map((mutationResult: GqlClientDeleteDataElementOutputRefusal) => mutationResult.usedInData);
                            if (usedInData.length > 0) {
                                usedInEditorCallback(usedInData);
                                const usedDeIds = new Set(usedInData.map(usedInResult => getIdFromGqlId(usedInResult?.dataElementId)));
                                dataElementIds = dataElementIds.filter(id => !usedDeIds.has(id));
                            }
                        }
                        if (callBack) callBack(dataElementIds);
                    }
                    finally {
                        dispatch(deleteDataElementsSuccess(accountId, projectName, dataElementIds));
                    }

                    // Update the logic of all Derived data elements that use this derived data element in their logic
                    let studioDataManager = new StudioDataManager({ dataElements, derivedLogic });
                    studioDataManager.buildDerivedGraph(dataElements);

                    let dependentDerivedDataElementIds = studioDataManager.getDependentDerivedDataElementIds(dataElementIds);
                    const deSet = new Set(dependentDerivedDataElementIds);
                    dataElementIds.forEach((id) => deSet.add(id));

                    dispatch(valueSetsChanged(accountId, projectName, Array.from(deSet)));
                }
            })
            .catch((err) => {
                if (gqlReadMostEntities) {
                    dispatch(reportError(err));
                }
            });
    };
};

export const deleteDataElement = function(accountId: string, projectName: string, dataElementId: string, callBack: (s: string) => void) {
    let dataElements: DataElement[];
    let derivedLogic;

    return async (dispatch, getState, services) => {
        const gqlReadMostEntities: boolean = StateReaderUtils.isProgramBulkDynamoMigrationDoneByLegacyId(getState(), projectName, BulkId.MostEntities);
        try {
            await services.dataElements.deleteDataElement(accountId, projectName, dataElementId);

            if (!gqlReadMostEntities) {
                dataElements = StateReaderUtils.getProjectDataElements(getState(), projectName);
                derivedLogic = StateReaderUtils.getAllProgramLogic(getState(), projectName, LogicContainers.Derived);
                try {
                    if (callBack) callBack(dataElementId);
                }
                finally {
                    dispatch(deleteDataElementsSuccess(accountId, projectName, [dataElementId]));
                }

                // Update the logic of all Derived data elements that use this derived data element in their logic
                let studioDataManager = new StudioDataManager({ dataElements, derivedLogic });
                studioDataManager.buildDerivedGraph(dataElements);

                let dependentDerivedDataElementIds = studioDataManager.getDependentDerivedDataElementIds(dataElementId);
                dependentDerivedDataElementIds.push(dataElementId);

                dispatch(valueSetsChanged(accountId, projectName, dependentDerivedDataElementIds));
            }
        }
        catch (err) {
            if (!gqlReadMostEntities) {
                dispatch(reportError(err));
            }
        }
    };
};

export const addDataElement = function(accountId: string, projectName: string, dataElement: DataElement, logic: LogicJSON, callBack: (d: DataElement) => void) {
    return (dispatch, getState, services: ThunkServices) => {
        dataElement = changeID(dataElement, uuid());

        // Update Postgres via GraphQL request
        const { programVersionId } = StateReaderUtils.getBuilderProgramAndDraftVersionIds(getState(), projectName);

        let mutation;
        let input;

        if (dataElement.origin === DataElementOrigins.Feed) {
            mutation = CreateFeedDataElementDocument;
            input = {
                name: dataElement.displayName,
                description: dataElement.description,
                dataType: dataElement.type,
                useValueSet: dataElement.useValueSet,
                valueSet: dataElement.valueSet,
                pii: dataElement.tags.includes(TagNames.PII),
                status: convertDataElementStatus(dataElement.status) || DataElementStatuses.Pending
            };
        }
        else if (dataElement.origin === DataElementOrigins.Derived) {
            mutation = CreateDerivedDataElementDocument;
            input = {
                name: dataElement.displayName,
                description: dataElement.description,
                dataType: dataElement.type,
                pii: dataElement.tags.includes(TagNames.PII),
                logic: logic
            };
        }
        else if (dataElement.origin === DataElementOrigins.Creative) {
            mutation = CreateCreativeDataElementDocument;
            input = {
                name: dataElement.displayName,
                description: dataElement.description,
                dataType: dataElement.type,
                useValueSet: dataElement.useValueSet,
                valueSet: dataElement.valueSet,
                assignedToAllStories: dataElement.assignedToAllStories,
                storyIds: dataElement.assignedStories.map((storyId: string) => programVersionId + "|" + storyId)
            };
        }

        return services.graphQlClient
            .mutate({
                mutation: mutation,
                variables: {
                    programVersionId: programVersionId,
                    input: input,
                    legacyId: dataElement.id,
                    [IGNORE_SERVER_ERRORS]: true
                }
            })
            .then((result: GQLCreateDataElementResult) => {

                let returnedGQLDataElement: GQLPartialDataElement;

                if (result.data.hasOwnProperty("createFeedDataElement")) {
                    returnedGQLDataElement = (result as CreateFeedDataElementMutationResult).data.createFeedDataElement.feedDataElement;
                }
                else if (result.data.hasOwnProperty("createDerivedDataElement")) {
                    returnedGQLDataElement = (result as CreateDerivedDataElementMutationResult).data.createDerivedDataElement.derivedDataElement;
                }
                else if (result.data.hasOwnProperty("createCreativeDataElement")) {
                    returnedGQLDataElement = (result as CreateCreativeDataElementMutationResult).data.createCreativeDataElement.creativeDataElement;
                }

                let returnedDataElement: DataElement = buildDataElement({
                    id: dataElement.id,
                    type: dataElement.type,
                    displayName: dataElement.displayName,
                    description: dataElement.description,
                    origin: dataElement.origin,
                    status: dataElement.origin === DataElementOrigins.Feed ? dataElement.status : undefined,
                    valueSet: dataElement.origin === DataElementOrigins.Feed || dataElement.origin === DataElementOrigins.Creative ? (dataElement.valueSet as ValueSet) : undefined,
                    useValueSet: dataElement.origin === DataElementOrigins.Feed || dataElement.origin === DataElementOrigins.Creative ? dataElement.useValueSet : undefined,
                    updatedTime: new Date(returnedGQLDataElement.updated).getTime(),
                    tags: dataElement.tags,
                    assignedStories: dataElement.origin === DataElementOrigins.Creative ? dataElement.assignedStories : undefined,
                    assignedToAllStories: dataElement.origin === DataElementOrigins.Creative ? dataElement.assignedToAllStories : undefined
                });

                const logicToUpdate = dataElement.origin === DataElementOrigins.Derived ? logic : undefined;

                dispatch(addDataElementSuccess(accountId, projectName, returnedDataElement, logicToUpdate));

                if (callBack) {
                    callBack(dataElement);
                }
            })
            .catch((err) => {
                dispatch(reportError(err));
            });
    };
};

/***
 * Calculates validations for all logic stored in state
 * @param accountId
 * @param projectName
 * @returns {Function}
 */
export const recalculateLogicValidations = function(accountId, projectName) {
    return (dispatch) => {};
};
