import { createAction } from "redux-actions";
import { reportError, reportErrorSuccess, setLoadingForUpdateNarrationLibrary } from "../../../common/commonActions";
import { updateBulkAssetsSuccess } from "../../projectAssets/projectAssetsActions";
import NarrationsModelUtils from "./NarrationsModelUtils";
import StateReaderUtils from "../../../common/StateReaderUtils";
import type {
    LoadedNarration } from "../projectsWireframesActions";
import {
    ChangingBulkScenePartsSuccess,
    ChangingProjectWireframesScenePartsSuccess,
    setProjectWireframesScenePart
} from "../projectsWireframesActions";
import { defaultNarratorId, recordingAssetType } from "../../../../../common/commonConst";
import { DATA_ELEMENT_SPECIAL_VALUE_PREFIX, STATUS_REQUEST } from "../../../../components/legacyCommon/Consts";
import { updateProjectComplete } from "../../projectsActions";
import { generateValueSetProviders } from "./ValueSetAdapterUtils";
import { normalizeValueSetValueDisplayName } from "../../../Logics/ValueSetUtils";
import memoizeOne from "memoize-one";
import type { LogicJSON, ValueSetValue } from "../../../../../common/types/logic";
import { ASSET_TYPES, LOGIC_CONTEXT as LogicContainers } from "../../../vlx/consts";
import type {
    Narration,
    NarrationUpdates,
    VariationData,
    VariationDataUpdates
} from "../../../../../common/types/narration";
import type { DataElement } from "../../../../../common/types/dataElement";
import { DataElementContentTypes, DataElementOrigins } from "../../../../../common/types/dataElement";
import type { Dimension, DimensionList, Variation } from "./NarrationEntities";
import testSingleInputLogic from "../../../vlx/tester";
import type { ConvertedGqlRecordingAsset, RecordingAsset } from "../../../../../common/types/asset";
import { AssetTypes, MediaTypes } from "../../../../../common/types/asset";
import type { DataElementValues } from "../../../../../common/types/preset";
import type { Context } from "../../../Logics/Logic";
import { buildDataElement } from "../../../DataElements/DataElementsManager";
import type { TestResult, ThunkServices } from "../../../common/types";
import { BulkId } from "../../../common/types";
import type { Program, ProgramSummary } from "../../../../../common/types/program";
import { IGNORE_SERVER_ERRORS, IGNORE_UPDATED } from "../../../common/Consts";
import type {
    GqlClientCreateNarrationInput,
    GqlClientCreateNarrationMutation,
    GqlClientCreateRfrVersionInput,
    GqlClientCreateRfrVersionMutation,
    GqlClientDeleteNarrationInput, GqlClientNarrationRecording,
    GqlClientNarrationUpdates,
    GqlClientUpdateNarrationInput,
    GqlClientUpdateNarrationMutation,
    GqlClientUpdateNarrationVariationDelete,
    GqlClientUpdateNarrationVariationUpsert } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import {
    CreateNarrationDocument,
    CreateRfrVersionDocument,
    DeleteNarrationDocument,
    UpdateNarrationDocument
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import type { NarrationPart, ScenePart } from "../../../../../common/types/scene";
import type { DataTable } from "../../../../../common/types/dataTable";
import type { FetchResult } from "@apollo/client";
import AssetUtils from "../../../common/assetUtils";
import { convertArrayOfObjectsToObjectWithKeys } from "../../../../../common/arrayUtils";
import { getRecordingAssetNameByKey } from "../../../common/exportNarrationsUtils";

export enum AddOrRemove {
    ADD = "add",
    REMOVE = "remove"
}

//
// Action Creators
//

export const LOAD_NARRATIONS_SUCCESS = "LOAD_NARRATIONS_SUCCESS";
export const loadNarrationsSuccess = createAction(LOAD_NARRATIONS_SUCCESS, (accountId, projectName, narrations: LoadedNarration[]) => {
    return { accountId, projectName, narrations };
}, undefined);

export const UPDATE_NARRATION_STRUCTURE_AND_VARIATIONDATA_PENDING = "UPDATE_NARRATION_STRUCTURE_AND_VARIATIONDATA_PENDING";
export const updateNarrationStructureAndVariationDataPending = createAction(
    UPDATE_NARRATION_STRUCTURE_AND_VARIATIONDATA_PENDING,
    (accountId, projectName, sceneId, narrationId, structureUpdate, variationDataUpdates) => {
        return { accountId, projectName, sceneId, narrationId, structureUpdate, variationDataUpdates };
    },
    undefined
);

export const UPDATE_NARRATION_STRUCTURE_AND_VARIATIONDATA_SUCCESS = "UPDATE_NARRATION_STRUCTURE_AND_VARIATIONDATA_SUCCESS";
export const updateNarrationStructureAndVariationDataSuccess = createAction(
    UPDATE_NARRATION_STRUCTURE_AND_VARIATIONDATA_SUCCESS,
    (accountId, projectName, sceneId, narrationId, structureUpdate, variationDataUpdates, mayCauseChangeInAssetLibrary, graphQLUpdated) => {
        return { accountId, projectName, sceneId, narrationId, structureUpdate, variationDataUpdates, mayCauseChangeInAssetLibrary, graphQLUpdated };
    },
    undefined
);

export const UPDATE_NARRATION_STRUCTURE_AND_VARIATIONDATA_FAILURE = "UPDATE_NARRATION_STRUCTURE_AND_VARIATIONDATA_FAILURE";
export const updateNarrationStructureAndVariationDataFailure = createAction(
    UPDATE_NARRATION_STRUCTURE_AND_VARIATIONDATA_FAILURE,
    (accountId, projectName, sceneId, narrationId, structureUpdate, variationReverts, variationDataMapReverts) => {
        return { accountId, projectName, sceneId, narrationId, structureUpdate, variationReverts, variationDataMapReverts };
    },
    undefined
);

export const NARRATION_USED_VALUE_SET_PROVIDER_CHANGED = "NARRATION_USED_VALUE_SET_PROVIDER_CHANGED";
export const narrationUsedValueSetProviderChanged = createAction(NARRATION_USED_VALUE_SET_PROVIDER_CHANGED, (accountId, projectName, sceneId, narrationId) => {
    return { accountId, projectName, sceneId, narrationId };
});

export const REMOVE_NARRATION_DIMENSION = "REMOVE_NARRATION_DIMENSION";
export const removeNarrationDimension = createAction(REMOVE_NARRATION_DIMENSION, (accountId, projectName, sceneId, narrationId, index) => {
    return { accountId, projectName, sceneId, narrationId, index };
}, undefined);

export const CANCEL_REMOVE_NARRATION_DIMENSION = "CANCEL_REMOVE_NARRATION_DIMENSION";
export const cancelRemoveNarrationDimension = createAction(CANCEL_REMOVE_NARRATION_DIMENSION, (accountId, projectName, sceneId, narrationId) => {
    return { accountId, projectName, sceneId, narrationId };
});

export const ADD_NARRATION_SUCCESS = "ADD_NARRATION_SUCCESS";
export const addNarrationSuccess = createAction(ADD_NARRATION_SUCCESS, (accountId, projectName, sceneId, narrationId, structure, variationDataMap, postgresNarrationId, graphQLUpdated) => {
    return { accountId, projectName, sceneId, narrationId, structure, variationDataMap, postgresNarrationId, graphQLUpdated };
}, undefined);

export const DELETE_NARRATION_SUCCESS = "DELETE_NARRATION_SUCCESS";
export const deleteNarrationSuccess = createAction(DELETE_NARRATION_SUCCESS, (accountId, projectName, sceneId, narrationId) => {
    return { accountId, projectName, sceneId, narrationId };
});

export const SET_SHOW_MUTED_ROWS = "SET_SHOW_MUTED_ROWS";
export const setShowMutedRows = createAction(SET_SHOW_MUTED_ROWS, (show) => {
    return { show };
});

export const SET_SHOW_NOTE_COLUMN = "SET_SHOW_NOTE_COLUMN";
export const setShowNoteColumn = createAction(SET_SHOW_NOTE_COLUMN, (show) => {
    return { show };
});

export const SET_SHOW_STATUS_COLUMN = "SET_SHOW_STATUS_COLUMN";
export const setShowStatusColumn = createAction(SET_SHOW_STATUS_COLUMN, (show) => {
    return { show };
});
//
// Action Thunks
//

type PartialRecording = Pick<GqlClientNarrationRecording, "id" | "key" | "narrationId" | "updated" | "lastVersionNumber">;

export const updateNarrationStructureAndVariationData = (
    accountId: string,
    projectName: string,
    sceneId: string,
    narrationId: string,
    structureUpdate,
    variationDataUpdates: VariationDataUpdates,
    mayCauseChangeInAssetLibrary: boolean
) => {
    return (dispatch, getState, services: ThunkServices) => {
        let variationReverts = {};
        let variationDataMapReverts = {};

        if (variationDataUpdates) {
            let state = getState();
            let wireframes = StateReaderUtils.getWireFrame(state, projectName);

            let variations = wireframes.narrations.byId[narrationId].variations;
            let variationDataMap = wireframes.narrations.byId[narrationId].variationDataMap;

            Object.keys(variationDataUpdates).forEach((variationKey) => {
                if (variations.has(variationKey)) {
                    variationReverts[variationKey] = variations.get(variationKey).variationData;
                }
                variationDataMapReverts[variationKey] = variationDataMap.get(variationKey);
            });
        }

        dispatch(updateNarrationStructureAndVariationDataPending(accountId, projectName, sceneId, narrationId, structureUpdate, variationDataUpdates));

        let dbStructure: NarrationUpdates = {};
        let dbVariationDataUpdates: VariationDataUpdates = {};

        Promise.resolve()
            .then(() => {
                if (structureUpdate && Object.keys(structureUpdate).length > 0) {
                    if (structureUpdate.stack) {
                        dbStructure.dimensionData = NarrationsModelUtils.dbExtractDimensionDataFromStructure(structureUpdate);
                        dbStructure.mergeData = NarrationsModelUtils.dbExtractMergeDataFromStructure(structureUpdate);
                    }

                    if (structureUpdate.representativeKey) {
                        dbStructure.representativeKey = NarrationsModelUtils.dbExtractRepresentativeKeyFromStructure(structureUpdate);
                    }

                    if (structureUpdate.creativeOverride !== undefined) {
                        dbStructure.creativeOverride = NarrationsModelUtils.dbExtractCreativeOverrideFromStructure(structureUpdate);
                    }

                    if (variationDataUpdates && Object.keys(variationDataUpdates).length > 0) {
                        dbVariationDataUpdates = NarrationsModelUtils.dbExtractVariationDataUpdates(variationDataUpdates);
                    }
                }
                if (variationDataUpdates && Object.keys(variationDataUpdates).length > 0) {
                    dbVariationDataUpdates = NarrationsModelUtils.dbExtractVariationDataUpdates(variationDataUpdates);
                }
                const { programVersionId } = StateReaderUtils.getBuilderProgramAndDraftVersionIds(getState(), projectName);

                let variationsToUpsert: GqlClientUpdateNarrationVariationUpsert[] = [];
                let variationsToDelete: GqlClientUpdateNarrationVariationDelete[] = [];

                Object.keys(dbVariationDataUpdates).forEach((variationKey) => {
                    let variationUpdate: Partial<VariationData> = dbVariationDataUpdates[variationKey];

                    if (variationUpdate) {
                        variationsToUpsert.push({
                            variationKey: variationKey,
                            sentence: variationUpdate.text,
                            note: variationUpdate.comment,
                            muted: variationUpdate.muted,
                            overridingCreativeDataElementId: variationUpdate.overrideId ? `${programVersionId}|${variationUpdate.overrideId}` : variationUpdate.overrideId
                        });
                    }
                    else {
                        variationsToDelete.push({
                            variationKey: variationKey
                        });
                    }
                });

                let input: GqlClientUpdateNarrationInput = {
                    id: narrationId,
                    updated: IGNORE_UPDATED,
                    representativeKey: dbStructure.representativeKey,
                    supportsCreativeDataElementOverride: dbStructure.creativeOverride,
                    dimensionData: dbStructure.dimensionData,
                    mergeData: dbStructure.mergeData,
                    variationsToUpsert: variationsToUpsert,
                    variationsToDelete: variationsToDelete
                };

                return services.graphQlClient
                    .mutate({
                        mutation: UpdateNarrationDocument,
                        variables: {
                            input: input,
                            dryRun: false
                        }
                    });
            })
            .then((gqlOutput: FetchResult<GqlClientUpdateNarrationMutation>) => {
                if (gqlOutput.data.updateNarration.__typename === "UpdateNarrationOutputSuccess") {
                    let graphQLUpdated = gqlOutput.data.updateNarration.narration.updated;
                    dispatch(updateNarrationStructureAndVariationDataSuccess(
                        accountId,
                        projectName,
                        sceneId,
                        narrationId,
                        structureUpdate,
                        variationDataUpdates,
                        mayCauseChangeInAssetLibrary,
                        graphQLUpdated
                    ));
                    dispatch(setLoadingForUpdateNarrationLibrary(false, accountId, projectName));
                }
            })
            .catch(() => {
                dispatch(updateNarrationStructureAndVariationDataFailure(accountId, projectName, sceneId, narrationId, structureUpdate, variationReverts, variationDataMapReverts));
                dispatch(setLoadingForUpdateNarrationLibrary(false, accountId, projectName));
            });
    };
};

export const addNarrationDimension = (accountId, projectName, sceneId, narrationId, valueSetProvider) => {
    return (dispatch, getState) => {
        let state = getState();
        const wireframes = StateReaderUtils.getWireFrame(state, projectName);
        const dataElements = StateReaderUtils.getProjectDataElementsFromWireframes(wireframes);
        const prioritizedLists = StateReaderUtils.getScenePrioritizedList(wireframes, sceneId);
        let valueSetProviders = generateValueSetProviders(dataElements, prioritizedLists);

        let { structureUpdate, variationDataUpdates } = NarrationsModelUtils.addDimension(wireframes.narrations.byId[narrationId].structure, valueSetProviders, valueSetProvider);
        dispatch(updateNarrationStructureAndVariationData(accountId, projectName, sceneId, narrationId, structureUpdate, variationDataUpdates, true));
    };
};

export const mergeNarrationVariations = (accountId, projectName, sceneId, narrationId, variationKeySets) => {
    return (dispatch, getState) => {
        let state = getState();
        let wireframes = StateReaderUtils.getWireFrame(state, projectName);

        let { structureUpdate, variationDataUpdates } = NarrationsModelUtils.merge(
            wireframes.narrations.byId[narrationId].structure,
            wireframes.narrations.byId[narrationId].variations,
            variationKeySets
        );
        dispatch(updateNarrationStructureAndVariationData(accountId, projectName, sceneId, narrationId, structureUpdate, variationDataUpdates, true));
    };
};

export const unmergeNarrationPoints = (accountId, projectName, sceneId, narrationId, points) => {
    return (dispatch, getState) => {
        let state = getState();
        let wireframes = StateReaderUtils.getWireFrame(state, projectName);

        let { structureUpdate, variationDataUpdates } = NarrationsModelUtils.unmerge(wireframes.narrations.byId[narrationId].structure, wireframes.narrations.byId[narrationId].variations, points);
        dispatch(updateNarrationStructureAndVariationData(accountId, projectName, sceneId, narrationId, structureUpdate, variationDataUpdates, true));
    };
};

export const acceptNarrationOrphansAndRemoved = (accountId, projectName, sceneId, narrationId) => {
    return (dispatch, getState) => {
        let state = getState();
        const wireframes = StateReaderUtils.getWireFrame(state, projectName);
        const dataElements = StateReaderUtils.getProjectDataElementsFromWireframes(wireframes);
        const prioritizedLists = StateReaderUtils.getScenePrioritizedList(wireframes, sceneId);
        let valueSetProviders = generateValueSetProviders(dataElements, prioritizedLists);

        let { structureUpdate, variationDataUpdates } = NarrationsModelUtils.acceptOrphansAndRemoved(
            wireframes.narrations.byId[narrationId].structure,
            wireframes.narrations.byId[narrationId].variationDataMap,
            wireframes.narrations.byId[narrationId].dimensions,
            valueSetProviders
        );
        dispatch(updateNarrationStructureAndVariationData(accountId, projectName, sceneId, narrationId, structureUpdate, variationDataUpdates, true));
    };
};

export const setNarrationDefaultText = (accountId, projectName, sceneId, scenePartIndex, narrationPartIndex, text) => {
    return (dispatch, getState) => {
        let state = getState();
        let wireframes = StateReaderUtils.getWireFrame(state, projectName);
        let scenePart = StateReaderUtils.getProjectWireframesScenePart(wireframes, sceneId, scenePartIndex);
        let narrationPart = scenePart.NarrationParts && scenePart.NarrationParts[narrationPartIndex];
        const narrationsVersion = StateReaderUtils.getNarrationsVersion(wireframes);

        if (narrationsVersion > 0) {
            //let representativeKey = StateReaderUtils.getNarrationTableRepresentativeKey(wireframes, narrationPart.id);
            let defaultTextKey = StateReaderUtils.getNarrationDefaultTextKey(wireframes, narrationPart.id);
            if (defaultTextKey) {
                dispatch(setNarrationText(accountId, projectName, sceneId, narrationPart.id, defaultTextKey, text));
            }
        }
        else {
            scenePart.NarrationParts[narrationPartIndex] = { ...narrationPart, mockValue: text };
            dispatch(setProjectWireframesScenePart(accountId, projectName, sceneId, scenePart.scenePart, scenePart, scenePartIndex, true));
        }
    };
};

export const setNarrationRepresentative = (accountId, projectName, sceneId, narrationId, representativeKey) => {
    return (dispatch, getState) => {
        let state = getState();
        let wireframes = StateReaderUtils.getWireFrame(state, projectName);

        //if (wireframes.narrations.byId[narrationId].variations.has(representativeKey) && !wireframes.narrations.byId[narrationId].variations.get(representativeKey).variationData.muted) {
        if (wireframes.narrations.byId[narrationId].variations.has(representativeKey)) {
            let { structureUpdate, variationDataUpdates } = NarrationsModelUtils.setRepresentativeKey(representativeKey);
            dispatch(updateNarrationStructureAndVariationData(accountId, projectName, sceneId, narrationId, structureUpdate, variationDataUpdates, false));
        }
    };
};

export const setNarrationText = (accountId, projectName, sceneId, narrationId, variationKey, text) => {
    return (dispatch, getState) => {
        let state = getState();
        let wireframes = StateReaderUtils.getWireFrame(state, projectName);

        let variationDataMap = wireframes.narrations.byId[narrationId].variationDataMap;
        let variation = wireframes.narrations.byId[narrationId].variations.get(variationKey);
        if (variation) {
            let { structureUpdate, variationDataUpdates } = NarrationsModelUtils.setText(variationKey, variationDataMap, variation.variationData, text);
            dispatch(updateNarrationStructureAndVariationData(accountId, projectName, sceneId, narrationId, structureUpdate, variationDataUpdates, true));
        }
    };
};

export const setNarrationComment = (accountId, projectName, sceneId, narrationId, variationKey, comment) => {
    return (dispatch, getState) => {
        let state = getState();
        let wireframes = StateReaderUtils.getWireFrame(state, projectName);

        let variationDataMap = wireframes.narrations.byId[narrationId].variationDataMap;
        let variation = wireframes.narrations.byId[narrationId].variations.get(variationKey);
        if (variation) {
            let { structureUpdate, variationDataUpdates } = NarrationsModelUtils.setComment(variationKey, variationDataMap, variation.variationData, comment);
            dispatch(updateNarrationStructureAndVariationData(accountId, projectName, sceneId, narrationId, structureUpdate, variationDataUpdates, false));
        }
    };
};

export const muteNarration = (accountId, projectName, sceneId, narrationId, variationKey) => {
    return (dispatch, getState) => {
        let state = getState();
        let wireframes = StateReaderUtils.getWireFrame(state, projectName);

        //if (variationKey !== wireframes.narrations.byId[narrationId].structure.representativeKey) {
        let variationDataMap = wireframes.narrations.byId[narrationId].variationDataMap;
        let variation = wireframes.narrations.byId[narrationId].variations.get(variationKey);
        if (variation) {
            let { structureUpdate, variationDataUpdates } = NarrationsModelUtils.mute(variationKey, variationDataMap, variation.variationData);
            dispatch(updateNarrationStructureAndVariationData(accountId, projectName, sceneId, narrationId, structureUpdate, variationDataUpdates, true));
        }
        //}
    };
};

export const unmuteNarration = (accountId, projectName, sceneId, narrationId, variationKey) => {
    return (dispatch, getState) => {
        let state = getState();
        let wireframes = StateReaderUtils.getWireFrame(state, projectName);

        let variationDataMap = wireframes.narrations.byId[narrationId].variationDataMap;
        let variation = wireframes.narrations.byId[narrationId].variations.get(variationKey);
        if (variation) {
            let { structureUpdate, variationDataUpdates } = NarrationsModelUtils.unmute(variationKey, variationDataMap, variation.variationData);
            dispatch(updateNarrationStructureAndVariationData(accountId, projectName, sceneId, narrationId, structureUpdate, variationDataUpdates, true));
        }
    };
};

export const addNarration = (accountId, projectName, sceneId, scenePartIndex) => {
    return (dispatch, getState, services) => {
        let structure = NarrationsModelUtils.defaultStructure();
        let variationDataMap = NarrationsModelUtils.defaultVariationDataMap();

        let dbStructure: Narration = {
            dimensionData: NarrationsModelUtils.dbExtractDimensionDataFromStructure(structure),
            mergeData: NarrationsModelUtils.dbExtractMergeDataFromStructure(structure),
            representativeKey: NarrationsModelUtils.dbExtractRepresentativeKeyFromStructure(structure),
            creativeOverride: false
        };

        let wireframes = StateReaderUtils.getWireFrame(getState(), projectName);
        let scenePart = StateReaderUtils.getProjectWireframesScenePart(wireframes, sceneId, scenePartIndex);

        const isPostgresOnly: boolean = StateReaderUtils.isProgramBulkDynamoMigrationDoneByLegacyId(getState(), projectName, BulkId.MostEntities);
        if (!isPostgresOnly) {
            return services.projectNarrationsServices
                .addNarration(accountId, projectName, sceneId, dbStructure)
                .then((narrationId) => {
                    dispatch(addNarrationSuccess(accountId, projectName, sceneId, narrationId, structure, variationDataMap));

                    let newNarrationPart = {
                        id: narrationId
                    };

                    let newNarrationParts = [...scenePart.NarrationParts, newNarrationPart];
                    let newScenePart = { ...scenePart, NarrationParts: newNarrationParts };

                    dispatch(setProjectWireframesScenePart(accountId, projectName, sceneId, newScenePart.scenePart, newScenePart, scenePartIndex, true));

                    return narrationId;
                })
                .then((narrationId) => {
                    // Graph QL update

                    const { programVersionId } = StateReaderUtils.getBuilderProgramAndDraftVersionIds(getState(), projectName);

                    let postgresScenePartId: string = scenePart.postgresScenePartId || scenePart.scenePart;

                    let input: GqlClientCreateNarrationInput = {
                        scenePartId: `${programVersionId}|${sceneId}|${postgresScenePartId}`,
                        representativeKey: dbStructure.representativeKey,
                        supportsCreativeDataElementOverride: dbStructure.creativeOverride,
                        dimensionData: dbStructure.dimensionData,
                        mergeData: dbStructure.mergeData
                    };

                    services.graphQlClient
                        .mutate({
                            mutation: CreateNarrationDocument,
                            variables: {
                                input: input,
                                legacyId: narrationId,
                                [IGNORE_SERVER_ERRORS]: true
                            }
                        })
                        .catch((err) => {});

                })
                .catch((err) => dispatch(reportError(err)));
        }
        else {
            let input: GqlClientCreateNarrationInput = {
                scenePartId: scenePart.graphQLId,
                representativeKey: dbStructure.representativeKey,
                supportsCreativeDataElementOverride: dbStructure.creativeOverride,
                dimensionData: dbStructure.dimensionData,
                mergeData: dbStructure.mergeData
            };

            services.graphQlClient
                .mutate({
                    mutation: CreateNarrationDocument,
                    variables: {
                        input: input
                    }
                })
                .then((gqlOutput: FetchResult<GqlClientCreateNarrationMutation>) => {
                    let { updated, id } = gqlOutput.data.createNarration.narration;
                    dispatch(addNarrationSuccess(accountId, projectName, sceneId, id, structure, variationDataMap, undefined, updated));
                    let newNarrationParts = [...scenePart.NarrationParts, { id }];
                    let newScenePart = { ...scenePart, NarrationParts: newNarrationParts };
                    dispatch(ChangingProjectWireframesScenePartsSuccess(accountId, projectName, sceneId, newScenePart, scenePartIndex));
                })
                .catch(() => {});
        }
    };
};

export const deleteNarration = (accountId, projectName, sceneId, scenePartIndex, narrationId) => {
    return (dispatch, getState, services) => {
        const isPostgresOnly: boolean = StateReaderUtils.isProgramBulkDynamoMigrationDoneByLegacyId(getState(), projectName, BulkId.MostEntities);
        let wireframes = StateReaderUtils.getWireFrame(getState(), projectName);
        let scenePart = StateReaderUtils.getProjectWireframesScenePart(wireframes, sceneId, scenePartIndex);
        if (!isPostgresOnly) {
            services.projectNarrationsServices
                .deleteNarration(accountId, projectName, sceneId, narrationId)
                .then(() => {
                    let newNarrationParts = scenePart.NarrationParts.filter((narrationPart) => narrationPart.id !== narrationId);
                    let newScenePart = { ...scenePart, NarrationParts: newNarrationParts };

                    return services.wireframes.setProjectWireframesScenePart(accountId, projectName, sceneId, newScenePart.scenePart, newScenePart);
                })
                .then((scenePart) => {
                    dispatch(ChangingProjectWireframesScenePartsSuccess(accountId, projectName, sceneId, scenePart, scenePartIndex));
                    dispatch(deleteNarrationSuccess(accountId, projectName, sceneId, narrationId));
                })
                .catch((err) => dispatch(reportError(err)));

            // Graph QL update

            let programSummary: ProgramSummary = StateReaderUtils.getProgramSummaries(getState()).find((ps: ProgramSummary) => ps.projectIds.builders === projectName);

            let wireframes = StateReaderUtils.getWireFrame(getState(), projectName);
            let scenePart: ScenePart = StateReaderUtils.getProjectWireframesScenePart(wireframes, sceneId, scenePartIndex);

            const postgresNarrationId: string = StateReaderUtils.getNarrationPostgresId(wireframes, narrationId) || narrationId;
            const postgresScenePartId: string = scenePart.postgresScenePartId || scenePart.scenePart;

            let input: GqlClientDeleteNarrationInput = {
                id: `${programSummary.projectIds.builderDraftVersionId}|${sceneId}|${postgresScenePartId}|${postgresNarrationId}`
            };

            services.graphQlClient
                .mutate({
                    mutation: DeleteNarrationDocument,
                    variables: {
                        input: input,
                        [IGNORE_SERVER_ERRORS]: true
                    }
                })
                .catch(() => {});
        }
        else {
            let input: GqlClientDeleteNarrationInput = {
                id: narrationId
            };
            services.graphQlClient
                .mutate({
                    mutation: DeleteNarrationDocument,
                    variables: {
                        input
                    }
                })
                .then(() => {
                    let newNarrationParts = scenePart.NarrationParts.filter((narrationPart) => narrationPart.id !== narrationId);
                    let newScenePart = { ...scenePart, NarrationParts: newNarrationParts };
                    dispatch(ChangingProjectWireframesScenePartsSuccess(accountId, projectName, sceneId, newScenePart, scenePartIndex));
                    dispatch(deleteNarrationSuccess(accountId, projectName, sceneId, narrationId));

                })
                .catch(() => {});
        }
    };
};

export const updateNarrationLibrary = (
    accountId: string,
    projectName: string,
    bulkAssetUpdates: { [assetName: string]: Partial<RecordingAsset> },
    bulkScenePartUpdates: { [scenePartKey: string]: ScenePart },
    bulkNarrationUpdates: {[narrationId: string]: Partial<NarrationPart>},
    programUpdates: Partial<Program>,
    assetToVariationKeyMap: { [assetKey: string]: string[] }
) => {
    return (dispatch, getState, services: ThunkServices) => {
        dispatch(setLoadingForUpdateNarrationLibrary(true, accountId, projectName));

        if (assetToVariationKeyMap) {
            let mergeData = Object.keys(assetToVariationKeyMap).reduce((acc, key) => {
                if (assetToVariationKeyMap[key].length > 1) {
                    let splittedKey = key.split("/");
                    let sceneId = splittedKey[0];
                    let narrationId = splittedKey[1];
                    acc[sceneId + "/" + narrationId] = acc[sceneId + "/" + narrationId] || [];
                    acc[sceneId + "/" + narrationId].push(assetToVariationKeyMap[key]);
                }
                return acc;
            }, {});
            Object.keys(mergeData).forEach((key) => {
                let splittedKey = key.split("/");
                let sceneId = splittedKey[0];
                let narrationId = splittedKey[1];
                dispatch(mergeNarrationVariations(accountId, projectName, sceneId, narrationId, mergeData[key]));
            });
        }

        const areRecordingsMigratedToPostgres: boolean = StateReaderUtils.isProgramBulkDynamoMigrationDoneByLegacyId(getState(), projectName, BulkId.MostEntities);

        if (!areRecordingsMigratedToPostgres) {

            // We update the project twice: first the lastUsedRecordingFileName, then we update the assets, and only then the RFR of the project.
            // The reason is that if some asset requests fail, we do not want to continue updating project's RFR.
            // Also, if the project's lastUsedRecordingFileName request fails, we do not want to continue updating the assets

            return services.wireframes
                .bulkUpdateProjectWireframesSceneParts(accountId, projectName, bulkScenePartUpdates)
                .then((responseBody) => {
                    let allScenePartsSucceeded = Object.keys(responseBody).every((scenePartId) => responseBody[scenePartId].status === 200);
                    if (allScenePartsSucceeded) {
                        dispatch(ChangingBulkScenePartsSuccess(accountId, projectName, bulkScenePartUpdates));
                        return services.projectAssetsServices.updateRecordingAssets(accountId, projectName, bulkAssetUpdates);
                    }
                    else {
                        throw "Failed updating sceneParts";
                    }
                })
                .then((response) => {
                    const responseBody = response.response;
                    dispatch(updateBulkAssetsSuccess(projectName, responseBody, recordingAssetType));
                    let allAssetsSucceeded = Object.keys(responseBody).every((assetName) => responseBody[assetName].status === 200);
                    if (allAssetsSucceeded) {
                        return services.projectServices.updateProject(accountId, projectName, {
                            RFR: programUpdates.RFR,
                            RFRUpdateTimestamp: Date.now()
                        });
                    }
                })
                .then((updatedProgram) => {
                    dispatch(updateProjectComplete(updatedProgram));
                    dispatch(setLoadingForUpdateNarrationLibrary(false, accountId, projectName));
                })
                .then(() => {
                    // Graph QL update
                    const { programVersionId } = StateReaderUtils.getBuilderProgramAndDraftVersionIds(getState(), projectName);

                    let input: GqlClientCreateRfrVersionInput = {
                        programVersionId: programVersionId,
                        updated: IGNORE_UPDATED,
                        RFR: programUpdates.RFR,
                        narrationUpdates: [],
                        narrationRecordingsToUpsert: []
                    };

                    services.graphQlClient
                        .mutate({
                            mutation: CreateRfrVersionDocument,
                            variables: {
                                input: input,
                                [IGNORE_SERVER_ERRORS]: true
                            }
                        })
                        .catch(() => {
                        });
                })
                .catch((err) => {
                    dispatch(reportError(err));
                    dispatch(setLoadingForUpdateNarrationLibrary(false, accountId, projectName));
                });
        }
        else {
            const { programVersionId } = StateReaderUtils.getBuilderProgramAndDraftVersionIds(getState(), projectName);

            const narrationUpdates: GqlClientNarrationUpdates[] = Object.keys(bulkNarrationUpdates).map(id => {
                return {
                    narrationId: id,
                    updated: IGNORE_UPDATED,
                    lastUsedRecordingFileName: bulkNarrationUpdates[id].lastUsedRecordingFileName
                };
            });

            const narrationRecordingsToUpsert = Object.keys(bulkAssetUpdates).map(assetName => {
                const recordingAsset: ConvertedGqlRecordingAsset | undefined = StateReaderUtils.getAsset(getState(), accountId, projectName, AssetTypes.recording, assetName);
                const recordingAssetUpdates = bulkAssetUpdates[assetName];

                const { narrationId, key } = AssetUtils.deconstructRecordingAssetName(assetName);

                return {
                    narrationRecordingId: recordingAsset && recordingAsset.graphQLId,
                    updated: IGNORE_UPDATED,

                    narrationId: narrationId,

                    key: key,
                    filename: recordingAssetUpdates.filename,
                    text: recordingAssetUpdates.narrationMetadata?.text,
                    rfr: recordingAssetUpdates.RFR,

                    metadata: recordingAssetUpdates.narrationMetadata
                };
            });

            let input: GqlClientCreateRfrVersionInput = {
                programVersionId: programVersionId,
                updated: IGNORE_UPDATED,
                RFR: programUpdates.RFR,
                narrationUpdates: narrationUpdates,
                narrationRecordingsToUpsert: narrationRecordingsToUpsert
            };

            services.graphQlClient
                .mutate<GqlClientCreateRfrVersionMutation>({
                    mutation: CreateRfrVersionDocument,
                    variables: {
                        input: input
                    }
                })
                .then(result => {
                    if (result.data.createRFRVersion.__typename === "CreateRFRVersionOutputSuccess") {

                        dispatch(ChangingBulkScenePartsSuccess(accountId, projectName, bulkScenePartUpdates));

                        const programVersion = result.data.createRFRVersion.programVersion;
                        const updatedProgram: Partial<Program> = { ...programUpdates, projectName, RFRUpdateTimestamp: Date.now(), graphQLUpdated: programVersion.updated };
                        dispatch(updateProjectComplete(updatedProgram));

                        const bulkResponse: Record<string, { status: 200, response: Partial<RecordingAsset> }> = {};

                        const recordingsByAssetName = convertArrayOfObjectsToObjectWithKeys<PartialRecording>(
                            programVersion.narrationRecordings,
                            ["narrationId", "key"],
                            (narrationId: string, key: string) => getRecordingAssetNameByKey(narrationId, defaultNarratorId, key)
                        );

                        Object.keys(bulkAssetUpdates).forEach(assetName => {
                            const asset: RecordingAsset | undefined = StateReaderUtils.getAsset(getState(), accountId, projectName, AssetTypes.recording, assetName);
                            const gqlNarrationRecording = recordingsByAssetName[assetName];

                            const assetUpdates: Partial<RecordingAsset> = asset
                                ? { ...bulkAssetUpdates[assetName], graphQLUpdated: programVersion.updated }
                                : {
                                    ...bulkAssetUpdates[assetName],
                                    version: 0,
                                    activeVersion: 0,
                                    status: STATUS_REQUEST,
                                    type: AssetTypes.recording,
                                    assetId: StateReaderUtils.getAssetId(AssetTypes.recording, assetName),
                                    name: assetName,
                                    mediaType: MediaTypes.Audio,
                                    graphQLUpdated: gqlNarrationRecording.updated,
                                    graphQLId: gqlNarrationRecording.id,
                                    isPostgresAsset: true,
                                    createdTime: Date.now(),
                                    updatedTime: Date.now()
                                };

                            bulkResponse[assetName] = {
                                status: 200,
                                response: assetUpdates
                            };
                        });

                        dispatch(updateBulkAssetsSuccess(projectName, bulkResponse, recordingAssetType));
                    }
                })
                .catch(() => {})
                .finally(() => {
                    dispatch(setLoadingForUpdateNarrationLibrary(false, accountId, projectName));
                });
        }
    };
};

const DE_COLUMN_PREFIX = "DataElement_";
const TEXT_COLUMN_NAME = "Approved Narration";

const validateColumns = (csvColumns, dimensions) => {
    if (!csvColumns.includes(TEXT_COLUMN_NAME)) {
        return false;
    }

    let deColumns = csvColumns.filter((column) => column.startsWith(DE_COLUMN_PREFIX));

    return deColumns.every((deColumn) => {
        let deName = deColumn.slice(DE_COLUMN_PREFIX.length);
        return dimensions.some((dim) => dim.name === deName);
    });
};

const getValueId = memoizeOne((valueName, dimension) => {
    let normalize = normalizeValueSetValueDisplayName;

    let type = dimension.type;
    let value = dimension.valueSet.find((value) => normalize(value.dn, type) === normalize(valueName, type) || (!valueName && value.dn.startsWith(DATA_ELEMENT_SPECIAL_VALUE_PREFIX)));

    return value ? value.id : null;
});

const buildVariationDataUpdateFromCSVRow = (csvRow, dimensions, variations, textColumnIndex) => {
    let valueNames = csvRow.slice(0, dimensions.size);

    let valueIds = valueNames.map((valueName, index) => {
        let dimension = dimensions.get(index);
        return getValueId(valueName, dimension);
    });

    if (valueIds.includes(null)) {
        return null;
    }

    let key = NarrationsModelUtils.generateKey([valueIds]);

    if (!variations.has(key)) {
        return {};
    }

    return {
        [key]: {
            text: csvRow[textColumnIndex]
        }
    };
};

const buildVariationDataUpdatesFromCSVData = (csvData, dimensions, variations, textColumnIndex) => {
    let variationDataUpdates = {};

    for (let i = 0; i < csvData.length; ++i) {
        let row = csvData[i];
        let rowUpdates = buildVariationDataUpdateFromCSVRow(row, dimensions, variations, textColumnIndex);

        if (rowUpdates !== null) {
            Object.assign(variationDataUpdates, rowUpdates);
        }
        else {
            return { errorLine: i + 2, result: null }; // i + 2 explanation: +1 to normalize index to start from 1, +1 because the first line is the columns
        }
    }

    return {
        errorLine: -1,
        result: variationDataUpdates
    };
};

export const updateNarrationFromCSVData = (accountId, projectName, sceneId, narrationId, csvData) => {
    return (dispatch, getState) => {
        dispatch(setLoadingForUpdateNarrationLibrary(true, accountId, projectName));

        let wireframes = StateReaderUtils.getWireFrame(getState(), projectName);
        let csvColumns = csvData[0];
        let dimensions = StateReaderUtils.getNarrationTableDimensions(wireframes, narrationId);
        let variations = StateReaderUtils.getNarrationTableVariations(wireframes, narrationId);

        if (!validateColumns(csvColumns, dimensions)) {
            dispatch(setLoadingForUpdateNarrationLibrary(false, accountId, projectName));
            dispatch(reportErrorSuccess("One or more of the column names in the file do not match the data elements defined for this narration, please fix accordingly."));
            return null;
        }

        csvData.splice(0, 1);

        let textColumnIndex = csvColumns.findIndex((col) => col === TEXT_COLUMN_NAME);

        let result = buildVariationDataUpdatesFromCSVData(csvData, dimensions, variations, textColumnIndex);
        if (result.result) {
            dispatch(updateNarrationStructureAndVariationData(accountId, projectName, sceneId, narrationId, null, result.result, true));
        }
        else {
            dispatch(setLoadingForUpdateNarrationLibrary(false, accountId, projectName));
            dispatch(reportErrorSuccess("There is an invalid input in row " + result.errorLine + ". Please check that the input matches the narration's values."));
        }
    };
};

const removeDimensionsFromDerivedLogic = (dimensions: DimensionList, context: Context): void => {
    if (!context.derivedLogic) {
        return;
    }

    context.derivedLogic = Object.keys(context.derivedLogic).reduce((acc, deId) => {
        let hasDE = dimensions.find((dimension: Dimension) => {
            return dimension.id.includes(deId);
        });

        if (!hasDE) {
            acc[deId] = context.derivedLogic[deId];
        }

        return acc;
    }, {});
};

export const createDataElementsFromDimensions = (dimensions: DimensionList): DataElement[] => {
    return dimensions
        .map((dim: Dimension) => {
            return buildDataElement({
                id: dim.id,
                name: dim.name,
                type: DataElementContentTypes.String,
                origin: DataElementOrigins.Feed,
                displayName: dim.name
            });
        })
        .toArray();
};

export const _calcValueForVariation = (variation: Variation, dimensions: DimensionList, logic: LogicJSON, context: Context, dtaasEndPoint: string): Promise<string> => {
    let dataElementsValues: DataElementValues = dimensions
        .map((dimension: Dimension, index: number) => {
            let dimensionName = dimension.name;
            let variationCoord: string = variation.points.first().get(index);
            let valueSetValue: ValueSetValue = dimension.valueSet.find((value: ValueSetValue) => value.id === variationCoord);

            return { name: dimensionName, value: valueSetValue.dn };
        })
        .toArray();

    removeDimensionsFromDerivedLogic(dimensions, context);

    return testSingleInputLogic(logic, dataElementsValues, context.derivedLogic, [], dtaasEndPoint, { dataElements: context.dataElements }).then((testResult: TestResult) => {
        let result = testResult.result && testResult.result.toString();
        if (result && result.includes(DATA_ELEMENT_SPECIAL_VALUE_PREFIX)) {
            result = "";
        }
        return result;
    });
};

export const updateNarrationFromLogic = (accountId: string, projectName: string, sceneId: string, narrationId: string, logic: LogicJSON, affectedKeys: string[]) => {
    return (dispatch, getState) => {
        dispatch(setLoadingForUpdateNarrationLibrary(true, accountId, projectName));

        const state = getState();

        let project = StateReaderUtils.getProject(state, projectName);
        let wireframes = StateReaderUtils.getWireFrame(getState(), projectName);
        let dataElements: DataElement[] = StateReaderUtils.getProjectDataElements(state, projectName);
        let mappingTables: DataTable[] = StateReaderUtils.getProjectAssetsByType(project, state.assets, ASSET_TYPES.mappingTable);
        let derivedLogic = StateReaderUtils.getAllProgramLogic(state, projectName, LogicContainers.Derived);
        let variations = StateReaderUtils.getNarrationTableVariations(wireframes, narrationId);
        let variationDataMap = wireframes.narrations.byId[narrationId].variationDataMap;
        let dimensions: DimensionList = StateReaderUtils.getNarrationTableDimensions(wireframes, narrationId);
        const dtaasEndPoint = StateReaderUtils.getdtaasEndPoint(state);

        let tempDataElements: DataElement[] = createDataElementsFromDimensions(dimensions);

        let effectiveDataElements: DataElement[] = [...dataElements, ...tempDataElements];

        let finalVariationDataUpdates: VariationDataUpdates = {};

        let promises: Promise<void>[] = affectedKeys.map((key) => {
            if (NarrationsModelUtils.isExpandedPoint(key)) {
                return Promise.resolve();
            }

            let variation = variations.get(key);

            return _calcValueForVariation(variation, dimensions, logic, { derivedLogic, dataElements: effectiveDataElements, mappingTables }, dtaasEndPoint).then((result) => {
                let { variationDataUpdates } = NarrationsModelUtils.setText(key, variationDataMap, variation.variationData, result);
                if (variationDataUpdates) {
                    Object.assign(finalVariationDataUpdates, variationDataUpdates);
                }
            });
        });

        Promise.all(promises).then(() => {
            dispatch(updateNarrationStructureAndVariationData(accountId, projectName, sceneId, narrationId, null, finalVariationDataUpdates, true));
        });
    };
};

export const addOrRemoveCreative = (accountId: string, projectName: string, sceneId: string, narrationId: string, addOrRemove: AddOrRemove) => {
    return (dispatch, getState) => {
        let state = getState();
        const wireframes = StateReaderUtils.getWireFrame(state, projectName);
        const structure = wireframes.narrations.byId[narrationId].structure;
        const variations = wireframes.narrations.byId[narrationId].variations;
        const variationDataMap = wireframes.narrations.byId[narrationId].variationDataMap;
        const supportCreativeOverride = addOrRemove === AddOrRemove.ADD;
        const { structureUpdate, variationDataUpdates } = NarrationsModelUtils.setSupportCreativeOverride(structure, variations, variationDataMap, supportCreativeOverride);
        dispatch(updateNarrationStructureAndVariationData(accountId, projectName, sceneId, narrationId, structureUpdate, variationDataUpdates, false));
    };
};

export const overrideWithCreative = (accountId: string, projectName: string, sceneId: string, narrationId: string, variationKey: string, overrideId: string) => {
    return (dispatch, getState) => {
        let state = getState();
        const wireframes = StateReaderUtils.getWireFrame(state, projectName);
        let variationDataMap = wireframes.narrations.byId[narrationId].variationDataMap;
        let variation = wireframes.narrations.byId[narrationId].variations.get(variationKey);
        if (variation) {
            let { structureUpdate, variationDataUpdates } = NarrationsModelUtils.setOverrideId(variationKey, variationDataMap, variation.variationData, overrideId);
            dispatch(updateNarrationStructureAndVariationData(accountId, projectName, sceneId, narrationId, structureUpdate, variationDataUpdates, false));
        }
    };
};
