import {
    IS_LOADING_USERS,
    IS_FETCHING_USER,
    IS_SETTING_FEATURE_FLAG,
    FETCH_USERS_SUCCESS,
    UPDATE_USER_SUCCESS,
    ADD_USER_SUCCESS,
    FETCH_USER_SUCCESS,
    FETCH_ROLES_SUCCESS,
    DELETE_USER_SUCCESS,
    SET_SUCCESS_MESSAGE,
    GET_ALL_PROJECTS_SUCCESS,
    IS_LOADING_ALL_PROJECTS
} from "./adminActions";

type actionType = { type: string | symbol; payload: any };

function adminReducer(state, action: actionType) {
    let userAllNames;
    let userId;
    let index;

    switch (action.type) {
        case IS_LOADING_ALL_PROJECTS:
            return {
                ...state,
                admin: {
                    ...state.admin,
                    isLoadingAllProjects: action.payload
                }
            };
        case GET_ALL_PROJECTS_SUCCESS:
            return {
                ...state,
                admin: {
                    ...state.admin,
                    allProjects: action.payload.projects
                }
            };
        case IS_SETTING_FEATURE_FLAG:
            return {
                ...state,
                admin: {
                    ...state.admin,
                    isSettingFeatureFlag: action.payload
                }
            };
        case IS_LOADING_USERS:
            return {
                ...state,
                admin: {
                    ...state.admin,
                    isLoadingUsers: action.payload
                }
            };
        case IS_FETCHING_USER:
            return {
                ...state,
                admin: {
                    ...state.admin,
                    isFetchingUser: action.payload
                }
            };
        case FETCH_USERS_SUCCESS:
        // action.payload is array of cognito user objects
            userAllNames = [...action.payload].sort();
            return {
                ...state,
                users: {
                    ...state.users,
                    allNames: userAllNames
                }
            };
        case ADD_USER_SUCCESS:
        // action.payload is user object
            userId = action.payload.userPool.Username;
            userAllNames = [...state.users.allNames, userId].sort();
            return {
                ...state,
                users: {
                    ...state.users,
                    allNames: userAllNames,
                    selectedUser: { ...action.payload }
                }
            };
        case DELETE_USER_SUCCESS:
        // action.payload is user id
            userId = action.payload;
            userAllNames = state.users.allNames;
            index = userAllNames.indexOf(userId);
            userAllNames = [...userAllNames.slice(0, index), ...userAllNames.slice(index + 1)];
            return {
                ...state,
                users: {
                    ...state.users,
                    allNames: userAllNames,
                    selectedUser: {}
                }
            };
        case FETCH_ROLES_SUCCESS:
            return {
                ...state,
                users: {
                    ...state.users,
                    roles: action.payload
                }
            };
        case FETCH_USER_SUCCESS:
        // action.payload is user object
            return {
                ...state,
                users: {
                    ...state.users,
                    selectedUser: { ...action.payload }
                }
            };
        case UPDATE_USER_SUCCESS:
        // action.payload is user object
            return {
                ...state,
                users: {
                    ...state.users,
                    selectedUser: { ...action.payload }
                }
            };
        case SET_SUCCESS_MESSAGE:
            return {
                ...state,
                users: {
                    ...state.users,
                    successMessage: action.payload?.success,
                    warningMessage: action.payload?.warning
                }
            };
        default:
            return state;
    }
}

export default adminReducer;
