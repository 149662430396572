import type { AnyEntitiesByContextById, AnyEntity, AnyEntityContextPair, GetDependencyIdFunction } from "./baseEntity";
import { BaseEntity } from "./baseEntity";
import StateReaderUtils from "../common/StateReaderUtils";
import type { GetValidationResultFunction, Issue, ValidationResult } from "../validations/validationManager";
import { IssueCodes } from "../validations/validationManager";
import type { Collector, CollectorArgs, Context, EntityInstance, IEntity } from "./definitions";
import { EntityTypes } from "./definitions";
import type { DiffResult, GetDiffResultFunc } from "../versionDiff/diffManager";
import { ChangeType } from "../versionDiff/diffManager";
import { diffEntities } from "../versionDiff/diffUtils";
import type { PrioritizedList } from "../common/types";
import { deepCloneObj } from "../common/generalUtils";

export default class PrioritizedListEntity extends BaseEntity implements IEntity {
    constructor() {
        super(EntityTypes.PRIORITIZED_LIST);
    }

    static generateId = (prioritizedListId: string, sceneId: string): string => {
        return prioritizedListId + "_" + sceneId;
    };

    collector: Collector = ({ context, previousContext }: CollectorArgs): EntityInstance[] => {
        return !previousContext ? this.validationCollector(context) : this.diffCollector({ context, previousContext });
    };

    validationCollector: Collector = (context: Context): EntityInstance[] => {
        let prioritizedLists: EntityInstance[] = [];
        const wireframes = StateReaderUtils.getWireFrame(context.state, context.projectName, context.stage, context.version);
        const scenes = StateReaderUtils.getProjectWireframeScenes(context.state, context.projectName, context.stage, context.version);

        if (scenes) {
            Object.keys(scenes).forEach((sceneId) => {
                let scenePrioritizedLists = StateReaderUtils.getScenePrioritizedList(wireframes, sceneId);
                scenePrioritizedLists.forEach((prioritizedList: PrioritizedList) => {
                    prioritizedLists.push({
                        id: PrioritizedListEntity.generateId(prioritizedList.id, sceneId),
                        validate: (prevValidationResult: ValidationResult, getValidationResultFunction: GetValidationResultFunction): ValidationResult => {
                            return this.doValidate(prioritizedList, sceneId, getValidationResultFunction);
                        }
                    });
                });
            });
        }
        return prioritizedLists;
    };

    diffCollector: Collector = ({ context, previousContext }: CollectorArgs): EntityInstance[] => {
        let prioritizedLists: EntityInstance[] = [];

        const currentWireframes = StateReaderUtils.getWireFrame(context.state, context.projectName, context.stage, context.version);
        const previousWireframes = StateReaderUtils.getWireFrame(previousContext.state, previousContext.projectName, previousContext.stage, previousContext.version);
        const currentPrioritizedLists = StateReaderUtils.getProjectPrioritizedLists(currentWireframes);
        const previousPrioritizedLists = StateReaderUtils.getProjectPrioritizedLists(previousWireframes);

        const pListsMap: AnyEntitiesByContextById = this.entityListsToMap(previousPrioritizedLists, currentPrioritizedLists, (pList) => pList.id);

        pListsMap.forEach((pListsPair: AnyEntityContextPair, pListId: string) => {
            const { currentEntity: currentPList, previousEntity: previousPList } = pListsPair;
            prioritizedLists.push({
                id: pListId,
                diff: (getDiffResult: GetDiffResultFunc): DiffResult => this.doDiff(getDiffResult, currentPList, previousPList, pListId)
            });
        });

        return prioritizedLists;
    };

    doValidate = (prioritizedList: PrioritizedList, sceneId: string, getValidationResult: GetValidationResultFunction): ValidationResult => {
        const type: EntityTypes = this.getType();
        const id: string = PrioritizedListEntity.generateId(prioritizedList.id, sceneId);
        let prioritizedListValidationResult: ValidationResult = { type, id, issues: [], name: prioritizedList.name };

        //check priority list candidates validity
        if (prioritizedList.rules) {
            const getDependencyId: GetDependencyIdFunction = (dependency) => `${dependency.key}_${sceneId}`;
            let issue: Issue = this.getDependenciesIssue(
                prioritizedList.rules,
                EntityTypes.PRIORITIZED_LIST_CANDIDATE,
                getDependencyId,
                getValidationResult,
                IssueCodes.PRIORITIZED_LIST_CANDIDATES_ERROR
            );
            if (issue) {
                prioritizedListValidationResult.issues.push(issue);
                prioritizedListValidationResult.severity = issue.severity;
            }
        }

        return prioritizedListValidationResult;
    };

    doDiff = (getDiffResult: GetDiffResultFunc, currPList: AnyEntity, prevPList: AnyEntity, pListId: string): DiffResult => {
        const changeType: ChangeType = diffEntities(this.normalizePriorityList(prevPList), this.normalizePriorityList(currPList));

        return {
            type: this.getType(),
            id: pListId,
            name: this.getPropsFromPreviousOrCurrent(prevPList, currPList, "name"),
            changeType,
            changes: [],
            isShown: changeType !== ChangeType.None
        };
    };

    private normalizePriorityList = (priorityList: AnyEntity): AnyEntity => {
        if (!priorityList) {
            return;
        }

        let normalizedPriorityList = { ...priorityList };

        delete normalizedPriorityList.validation;
        delete normalizedPriorityList.graphQLId;
        delete normalizedPriorityList.graphQLUpdated;
        if (Array.isArray(normalizedPriorityList.slots)) {
            normalizedPriorityList.slots = deepCloneObj(normalizedPriorityList.slots);
            for (let slot of normalizedPriorityList.slots) {
                delete slot.graphQLId;
                delete slot.graphQLUpdated;
                if (Array.isArray(slot.placeholders)) {
                    // each slot is a group
                    for (let ph of slot.placeholders) {
                        delete ph.x;
                        delete ph.y;
                        delete ph.width;
                        delete ph.height;
                        delete ph.validationStatus;
                        delete ph.graphQLId;
                        delete ph.graphQLUpdated;
                    }
                }
                else {
                    // each slot is a single placeholder
                    delete slot.x;
                    delete slot.y;
                    delete slot.width;
                    delete slot.height;
                    delete slot.validationStatus;
                }
            }
        }
        return normalizedPriorityList;
    };
}
