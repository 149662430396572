import type { AnyEntitiesByContextById, AnyEntity, AnyEntityContextPair, GetDependencyIdFunction } from "./baseEntity";
import { BaseEntity } from "./baseEntity";
import type { GetValidationResultFunction, Issue, ValidationResult } from "../validations/validationManager";
import { IssueCodes, IssueSeverity } from "../validations/validationManager";
import type { Collector, CollectorArgs, Context, EntityInstance, IEntity } from "./definitions";
import { EntityTypes } from "./definitions";
import StateReaderUtils from "../common/StateReaderUtils";
import type { ChangeDetail, DiffResult, GetDiffResultFunc } from "../versionDiff/diffManager";
import { ChangeType } from "../versionDiff/diffManager";
import { updateDiffResultWithChanges } from "../versionDiff/diffUtils";

export default class ParameterEntity extends BaseEntity implements IEntity {
    constructor() {
        super(EntityTypes.PARAMETER);
    }

    static generateId = (parameterName: string, sceneId: string): string => {
        return parameterName + "_" + sceneId;
    };

    static getParametersFromScenes = (scenes: any[]): AnyEntity[] => {
        let parameters: AnyEntity[] = [];
        if (scenes) {
            Object.keys(scenes).forEach((sceneId) => {
                scenes[sceneId].sceneParts &&
                    scenes[sceneId].sceneParts.forEach((scenePart) => {
                        scenePart.parameters &&
                            parameters.push(
                                ...scenePart.parameters.map((param) => ({
                                    sceneId,
                                    ...param
                                }))
                            );
                    });
            });
        }
        return parameters;
    };

    collector: Collector = (args: CollectorArgs): EntityInstance[] => {
        const { context, previousContext } = args;
        let parameters: EntityInstance[] = [];

        const previousScenes = previousContext && StateReaderUtils.getProjectWireframeScenes(previousContext.state, previousContext.projectName, previousContext.stage, previousContext.version);
        const currentScenes = StateReaderUtils.getProjectWireframeScenes(context.state, context.projectName, context.stage, context.version);
        const previousParameters: AnyEntity[] = ParameterEntity.getParametersFromScenes(previousScenes);
        const currentParameters: AnyEntity[] = ParameterEntity.getParametersFromScenes(currentScenes);

        const idFunc = (param) => ParameterEntity.generateId(param.name, param.sceneId);
        const parametersMap: AnyEntitiesByContextById = this.entityListsToMap(previousParameters, currentParameters, idFunc);
        parametersMap.forEach((parametersPair: AnyEntityContextPair, paramId: string) => {
            const { currentEntity: currentParameter, previousEntity: previousParameter } = parametersPair;
            parameters.push({
                id: paramId,
                validate: (prevValidationResult: ValidationResult, getValidationResultFunction: GetValidationResultFunction): ValidationResult => {
                    return this.doValidate(currentParameter.name, currentParameter.sceneId, getValidationResultFunction, context);
                },
                diff: (getDiffResult: GetDiffResultFunc): DiffResult => {
                    return this.doDiff(getDiffResult, currentParameter, previousParameter);
                }
            });
        });

        return parameters;
    };

    doValidate = (parameterName: string, sceneId: string, getValidationResult: GetValidationResultFunction, context: Context): ValidationResult => {
        const type: EntityTypes = this.getType();
        const id: string = ParameterEntity.generateId(parameterName, sceneId);
        let result: ValidationResult = { type, id, issues: [], name: parameterName };
        let wireframes = StateReaderUtils.getWireFrame(context.state, context.projectName, context.stage, context.version);
        let sceneLogic = StateReaderUtils.getAllProgramLogicFromWireframes(wireframes, sceneId);

        //validate parameter logic
        if (sceneLogic && sceneLogic[parameterName]) {
            const getDependencyId: GetDependencyIdFunction = () => id;
            let issue: Issue = this.getDependenciesIssue([undefined], EntityTypes.LOGIC, getDependencyId, getValidationResult, IssueCodes.PARAMETER_LOGIC_ERROR);
            if (issue) {
                result.issues.push(issue);
                result.severity = issue.severity;
            }
        }
        else {
            result.issues.push({
                code: IssueCodes.PARAMETER_LOGIC_MISSING,
                severity: IssueSeverity.WARNING,
                details: {}
            });
            result.severity = IssueSeverity.WARNING;
        }

        return result;
    };

    doDiff = (getDiffResult: GetDiffResultFunc, currParameter: AnyEntity, prevParameter: AnyEntity): DiffResult => {
        const paramName = (currParameter && currParameter.name) || (prevParameter && prevParameter.name) || undefined;
        const paramScenId = (currParameter && currParameter.sceneId) || (prevParameter && prevParameter.sceneId) || undefined;
        const id: string = ParameterEntity.generateId(paramName, paramScenId);
        let result: DiffResult = {
            type: this.getType(),
            id,
            name: paramName,
            changeType: ChangeType.None,
            changes: [],
            isShown: false
        };

        const getDependencyId: GetDependencyIdFunction = (id) => id;
        const change: ChangeDetail = this.getDependenciesDiff([id], EntityTypes.LOGIC, getDependencyId, getDiffResult, true);

        return updateDiffResultWithChanges(result, change);
    };
}
