import type { AnyEntity, AnyEntityById, GetDependencyIdFunction } from "./baseEntity";
import { BaseEntity } from "./baseEntity";
import StateReaderUtils from "../common/StateReaderUtils";
import type { GetValidationResultFunction, Issue, ValidationResult } from "../validations/validationManager";
import ValidationManager, { IssueCodes, IssueSeverity } from "../validations/validationManager";
import type { Collector, CollectorArgs, Context, EntityInstance, IEntity } from "./definitions";
import { EntityTypes } from "./definitions";
import AnalyticsEntity from "./analyticsEntity";
import type { ChangeDetail, DiffResult, GetDiffResultFunc } from "../versionDiff/diffManager";
import { ChangeType } from "../versionDiff/diffManager";
import { getProgramPropertyCollectionId, updateDiffResultWithChanges } from "../versionDiff/diffUtils";
import { DataElementOrigins } from "../../../common/types/dataElement";
import { ASSET_TYPES } from "../vlx/consts";
import type { Asset } from "../../../common/types/asset";
import type AssetEntity from "./assetEntity";
import LogicEntity from "./logicEntity";
import type { Program } from "../../../common/types/program";
import { LogicContainers } from "../../../common/commonConst";
import { featureFlagConst } from "@sundaysky/smartvideo-hub-config";
import DataTableEntity from "./dataTableEntity";
import AnimationEntity from "./animationEntity";
import RecordingEntity from "./recordingEntity";
import MediaEntity from "./mediaEntity";

const { STORY_LOGIC } = featureFlagConst;

export type AssetsDoDiffParams = {
    previousProject: AnyEntity;
    currentProject: AnyEntity;
    previousContext: Context;
    currentContext: Context;
    entity: AssetEntity;
    assetType: string;
    getDiffResult: GetDiffResultFunc;
    result: DiffResult;
};

export default class ProgramEntity extends BaseEntity implements IEntity {
    constructor() {
        super(EntityTypes.PROGRAM);
    }

    collector: Collector = (args: CollectorArgs): EntityInstance[] => {
        const { context, previousContext } = args;
        let programs: EntityInstance[] = [];
        const project = StateReaderUtils.getProject(context.state, context.projectName);
        if (project) {
            programs.push({
                id: project.projectName,
                validate: (prevValidationResult: ValidationResult, getValidationResultFunction: GetValidationResultFunction): ValidationResult => {
                    return this.doValidate(project, getValidationResultFunction, context);
                },
                diff: (getDiffResult: GetDiffResultFunc): DiffResult => this.doDiff(getDiffResult, project.projectName, previousContext, context)
            });
        }
        return programs;
    };

    doValidate = (project: Program, getValidationResult: GetValidationResultFunction, context: Context): ValidationResult => {
        const type: EntityTypes = this.getType();
        const id: string = project.projectName;
        let programValidationResult: ValidationResult = { type, id, issues: [] };

        // check SF-ID in program settings.
        // As of now, program settings validation includes only SF-ID validation. This is why program settings not a separate entity
        if (!project.programId) {
            const sfIdIssue: Issue = {
                code: IssueCodes.PROGRAM_SETTINGS_ERROR,
                severity: IssueSeverity.ERROR,
                details: ""
            };
            programValidationResult.issues.push(sfIdIssue);
            programValidationResult.severity = ValidationManager.getHighestSeverity([programValidationResult.severity, sfIdIssue.severity]);
        }

        // check scene library
        let allScenes = StateReaderUtils.getProjectWireframeScenes(context.state, context.projectName, context.stage, context.version);
        if (allScenes) {
            const getSceneDependencyId: GetDependencyIdFunction = (scene) => scene.id;
            let sceneIssue: Issue = this.getDependenciesIssue(Object.values(allScenes), EntityTypes.SCENE, getSceneDependencyId, getValidationResult, IssueCodes.PROGRAM_SCENES_ERROR);
            if (sceneIssue) {
                programValidationResult.issues.push(sceneIssue);
                programValidationResult.severity = ValidationManager.getHighestSeverity([programValidationResult.severity, sceneIssue.severity]);
            }
        }

        // check stories
        let allStories = StateReaderUtils.getProjectWireframesStories(context.state, context.projectName, context.stage, context.version);
        if (allStories) {
            const getStoryDependencyId: GetDependencyIdFunction = (story) => story.id;
            let storyIssue: Issue = this.getDependenciesIssue(Object.values(allStories), EntityTypes.STORY, getStoryDependencyId, getValidationResult, IssueCodes.PROGRAM_STORY_ERROR);
            if (storyIssue) {
                programValidationResult.issues.push(storyIssue);
                programValidationResult.severity = ValidationManager.getHighestSeverity([programValidationResult.severity, storyIssue.severity]);
            }
        }

        // check story selection logic
        const storySelectionLogic = StateReaderUtils.getProgramStorySelectionLogic(context.state, context.projectName, context.stage, context.version);
        if (storySelectionLogic) {
            const getSSLDependencyId: GetDependencyIdFunction = () => LogicEntity.STORY_SELECTION_LOGIC_ID;
            let storyIssue: Issue = this.getDependenciesIssue([storySelectionLogic], EntityTypes.LOGIC, getSSLDependencyId, getValidationResult, IssueCodes.PROGRAM_STORY_SELECTION_LOGIC_ERROR);
            if (storyIssue) {
                programValidationResult.issues.push(storyIssue);
                programValidationResult.severity = ValidationManager.getHighestSeverity([programValidationResult.severity, storyIssue.severity]);
            }
        }
        else {
            if (StateReaderUtils.isFeatureEnable(context.state, STORY_LOGIC)) {
                let noLogicIssue: Issue = {
                    code: IssueCodes.PROGRAM_STORY_SELECTION_LOGIC_ERROR,
                    severity: IssueSeverity.ERROR,
                    details: ""
                };
                programValidationResult.issues.push(noLogicIssue);
                programValidationResult.severity = ValidationManager.getHighestSeverity([programValidationResult.severity, noLogicIssue.severity]);
            }
        }

        // check analytics
        const wireframes = StateReaderUtils.getWireFrame(context.state, context.projectName, context.stage, context.version);
        const analyticsFieldsArray = StateReaderUtils.getCustomAnalyticsFieldsArray(wireframes);
        if (analyticsFieldsArray) {
            const getAnalyticsDependencyId: GetDependencyIdFunction = (analyticsFieldKey) => AnalyticsEntity.generateId(analyticsFieldKey);
            let storyIssue: Issue = this.getDependenciesIssue(analyticsFieldsArray, EntityTypes.ANALYTICS, getAnalyticsDependencyId, getValidationResult, IssueCodes.PROGRAM_ANALYTICS_ERROR);
            if (storyIssue) {
                programValidationResult.issues.push(storyIssue);
                programValidationResult.severity = ValidationManager.getHighestSeverity([programValidationResult.severity, storyIssue.severity]);
            }
        }

        // todo - check recordings - here or under scene

        // todo - check assets - not now

        return programValidationResult;
    };

    doDiff = (getDiffResult: GetDiffResultFunc, id: string, previousContext: Context, currentContext: Context): DiffResult => {
        let result: DiffResult = {
            type: this.getType(),
            id,
            name: id,
            changeType: ChangeType.None,
            changes: [],
            isShown: false
        };

        const previousProject = previousContext && StateReaderUtils.getProject(previousContext.state, previousContext.projectName);
        const currentProject = StateReaderUtils.getProject(currentContext.state, currentContext.projectName);

        // stories
        const previousStories: AnyEntityById = StateReaderUtils.getProjectWireframesStories(previousContext.state, previousContext.projectName, previousContext.stage, previousContext.version);
        const currentStories: AnyEntityById = StateReaderUtils.getProjectWireframesStories(currentContext.state, currentContext.projectName, currentContext.stage, currentContext.version);
        const storyIds: string[] = Array.from(new Set([...Object.keys(previousStories), ...Object.keys(currentStories)]));
        const storyChange: ChangeDetail = this.getDependenciesDiff(storyIds, EntityTypes.STORY, (id) => id, getDiffResult);
        updateDiffResultWithChanges(result, storyChange);

        // scenes
        const previousScenes: AnyEntityById = StateReaderUtils.getProjectWireframeScenes(previousContext.state, previousContext.projectName, previousContext.stage, previousContext.version);
        const currentScenes: AnyEntityById = StateReaderUtils.getProjectWireframeScenes(currentContext.state, currentContext.projectName, currentContext.stage, currentContext.version);
        const sceneIds: string[] = Array.from(new Set([...Object.keys(previousScenes), ...Object.keys(currentScenes)]));
        const sceneChange: ChangeDetail = this.getDependenciesDiff(sceneIds, EntityTypes.SCENE, (id) => id, getDiffResult);
        updateDiffResultWithChanges(result, sceneChange);

        // story selection logic
        const storySelectionLogicId = getProgramPropertyCollectionId(LogicContainers.StorySelection);
        const SSLChange: ChangeDetail = this.getDependenciesDiff([storySelectionLogicId], EntityTypes.LOGIC, (id) => id, getDiffResult, true);
        if (SSLChange) {
            // Story Selection Logic has only one child
            SSLChange.children[0].isShown = SSLChange.changeType !== ChangeType.None;
        }
        updateDiffResultWithChanges(result, SSLChange);

        // data library
        const previousAllDataElements: AnyEntity[] = StateReaderUtils.getProjectDataElements(previousContext.state, previousContext.projectName, previousContext.stage, previousContext.version);
        const currentAllDataElements: AnyEntity[] = StateReaderUtils.getProjectDataElements(currentContext.state, currentContext.projectName, currentContext.stage, currentContext.version);

        // // data elements
        const previousDataElements: AnyEntity[] = previousAllDataElements.filter((dataElement) => dataElement.origin === DataElementOrigins.Feed);
        const currentDataElements: AnyEntity[] = currentAllDataElements.filter((dataElement) => dataElement.origin === DataElementOrigins.Feed);
        const dataElementIds: string[] = Array.from(new Set([...previousDataElements.map((de) => de.id), ...currentDataElements.map((de) => de.id)]));
        const dataElementChange: ChangeDetail = this.getDependenciesDiff(dataElementIds, EntityTypes.DATA_ELEMENT, (id) => id, getDiffResult);
        updateDiffResultWithChanges(result, dataElementChange);

        // // creative data element
        const previousCreativeDataElements: AnyEntity[] = previousAllDataElements.filter((dataElement) => dataElement.origin === DataElementOrigins.Creative);
        const currentCreativeDataElements: AnyEntity[] = currentAllDataElements.filter((dataElement) => dataElement.origin === DataElementOrigins.Creative);
        const creativeDataElementIds: string[] = Array.from(new Set([...previousCreativeDataElements.map((de) => de.id), ...currentCreativeDataElements.map((de) => de.id)]));
        const creativeDataElementChange: ChangeDetail = this.getDependenciesDiff(creativeDataElementIds, EntityTypes.DATA_ELEMENT, (id) => id, getDiffResult);
        updateDiffResultWithChanges(result, creativeDataElementChange);

        // // studio data
        const previousStudioDataElements: AnyEntity[] = previousAllDataElements.filter((dataElement) => dataElement.origin === DataElementOrigins.Derived);
        const currentStudioDataElements: AnyEntity[] = currentAllDataElements.filter((dataElement) => dataElement.origin === DataElementOrigins.Derived);
        const studioDataElementIds: string[] = Array.from(new Set([...previousStudioDataElements.map((de) => de.id), ...currentStudioDataElements.map((de) => de.id)]));
        const studioDataElementChange: ChangeDetail = this.getDependenciesDiff(studioDataElementIds, EntityTypes.DERIVED_DATA_ELEMENT, (id) => id, getDiffResult);
        updateDiffResultWithChanges(result, studioDataElementChange);

        const assetsDoDiffParams: AssetsDoDiffParams = {
            previousProject,
            currentProject,
            previousContext,
            currentContext,
            assetType: undefined,
            entity: undefined,
            getDiffResult,
            result
        };

        // // data tables
        this.assetsDoDiff({
            ...assetsDoDiffParams,
            assetType: ASSET_TYPES.mappingTable,
            entity: new DataTableEntity()
        });

        // asset library
        // // media
        this.assetsDoDiff({
            ...assetsDoDiffParams,
            assetType: ASSET_TYPES.curated,
            entity: new MediaEntity()
        });

        // // animation
        this.assetsDoDiff({
            ...assetsDoDiffParams,
            assetType: ASSET_TYPES.animation,
            entity: new AnimationEntity()
        });

        // narration library
        this.assetsDoDiff({
            ...assetsDoDiffParams,
            assetType: ASSET_TYPES.recording,
            entity: new RecordingEntity()
        });

        // analytics
        const previousWireframes = StateReaderUtils.getWireFrame(previousContext.state, previousContext.projectName, previousContext.stage, previousContext.version);
        const currentWireframes = StateReaderUtils.getWireFrame(currentContext.state, currentContext.projectName, currentContext.stage, currentContext.version);
        const previousAnalyticsFieldsArray: string[] = StateReaderUtils.getCustomAnalyticsFieldsArray(previousWireframes) || [];
        const currentAnalyticsFieldsArray: string[] = StateReaderUtils.getCustomAnalyticsFieldsArray(currentWireframes) || [];
        const analyticsFieldKeys: string[] = Array.from(new Set([...previousAnalyticsFieldsArray, ...currentAnalyticsFieldsArray]));
        const getAnalyticsDependencyId: GetDependencyIdFunction = (analyticsFieldKey) => AnalyticsEntity.generateId(analyticsFieldKey);
        const analyticsChange: ChangeDetail = this.getDependenciesDiff(analyticsFieldKeys, EntityTypes.ANALYTICS, getAnalyticsDependencyId, getDiffResult);
        updateDiffResultWithChanges(result, analyticsChange);

        return result;
    };

    private assetsDoDiff = ({ previousProject, currentProject, previousContext, currentContext, entity, assetType, getDiffResult, result }: AssetsDoDiffParams) => {
        const previousAssets: Asset[] = StateReaderUtils.getAssetsInVersionMode(previousProject, previousContext.state.assets, previousContext.state.snapshots, previousContext.version, assetType);
        const currentAssets: Asset[] = StateReaderUtils.getAssetsFromSnapshotOrDraft(
            currentProject,
            currentContext.state.assets,
            currentContext.state.snapshots,
            undefined,
            currentContext.version,
            assetType
        );
        const assetIds: string[] = Array.from(new Set([...previousAssets.map(entity.getAssetId), ...currentAssets.map(entity.getAssetId)]));
        const assetChange: ChangeDetail = this.getDependenciesDiff(assetIds, entity.getType(), (id) => id, getDiffResult);
        updateDiffResultWithChanges(result, assetChange);
    };
}
