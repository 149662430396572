import { GENERATE_DIFF_MODEL, CLEAR_DIFF_MODEL, TOGGLE_DIFF_MODEL_BY_ID, EXPAND_OR_COLLAPSE_DIFF_MODEL } from "./diffActions";
import type { DiffEntity, DiffModel } from "./diffUtils";
import { getDiffModel } from "./diffUtils";
import type { IDiffTable } from "./diffTable";
import { deepCloneObj } from "../common/generalUtils";
import StateReaderUtils from "../common/StateReaderUtils";
import type { Context } from "../entities/definitions";
import DiffManager from "./diffManager";
import AnimationEntity from "../entities/animationEntity";
import AnalyticsEntity from "../entities/analyticsEntity";
import DataElementEntity from "../entities/dataElementEntity";
import LogicEntity from "../entities/logicEntity";
import ParameterEntity from "../entities/parameterEntity";
import PlaceholderEntity from "../entities/placeholderEntity";
import ScenePartEntity from "../entities/scenePartEntity";
import DataTableEntity from "../entities/dataTableEntity";
import MediaEntity from "../entities/mediaEntity";
import StudioDataElementEntity from "../entities/studioDataElementEntity";
import SceneEntity from "../entities/sceneEntity";
import StoryEntity from "../entities/storyEntity";
import ProgramEntity from "../entities/programEntity";
import PrioritizedListEntity from "../entities/prioritizedListEntity";
import GroupEntity from "../entities/groupEntity";
import NarrationEntity from "../entities/narrationEntity";
import RecordingEntity from "../entities/recordingEntity";

type Action = { type: string | symbol; payload: any };

type ToggleDiffModelParams = {
    diffModel: DiffModel;
    id?: string;
    expandAll?: boolean;
};

const getDiffTable = (state: any, projectName: string, previousVersion: number, currentVersion: number | "draft") => {
    // assert both versions exist on state
    const previousWireFrames = StateReaderUtils.getDiffWireframes(state, projectName, previousVersion);
    const currentWireFrames = currentVersion === "draft" ? StateReaderUtils.getWireFrame(state, projectName, currentVersion) : StateReaderUtils.getDiffWireframes(state, projectName, currentVersion);
    if (!previousWireFrames) {
        throw `Failed to find previous version (${previousVersion}) for diff 😞`;
    }
    if (!currentWireFrames) {
        throw `Failed to find current version (${currentVersion}) for diff 😞`;
    }

    const previousContext: Context = {
        state: state,
        projectName: projectName,
        stage: "diff",
        version: previousVersion
    };

    const currentContext: Context = {
        state: state,
        projectName: projectName,
        stage: currentVersion === "draft" ? "draft" : "diff",
        version: currentVersion === "draft" ? undefined : currentVersion
    };

    let diffManager = new DiffManager([
        // add entities here
        new AnimationEntity(),
        new AnalyticsEntity(),
        new DataElementEntity(),
        new LogicEntity(),
        new ParameterEntity(),
        new PlaceholderEntity(),
        new ScenePartEntity(),
        new DataTableEntity(),
        new MediaEntity(),
        new StudioDataElementEntity(),
        new SceneEntity(),
        new StoryEntity(),
        new ProgramEntity(),
        new PrioritizedListEntity(),
        new GroupEntity(),
        new NarrationEntity(),
        new RecordingEntity()
    ]);

    return diffManager.init(currentContext, previousContext);
};

const diffReducer = (state: any, action: Action) => {
    switch (action.type) {
        case GENERATE_DIFF_MODEL: {
            const { programName, currentVersion, previousVersion } = action.payload;
            const diffTable: IDiffTable = getDiffTable(state, programName, previousVersion, currentVersion);
            const diffModel: DiffModel = getDiffModel(diffTable);
            return {
                ...state,
                diffModel: {
                    currentVersion,
                    previousVersion,
                    model: diffModel
                }
            };
        }
        case CLEAR_DIFF_MODEL: {
            return {
                ...state,
                diffModel: {
                    currentVersion: undefined,
                    previousVersion: undefined,
                    model: undefined
                }
            };
        }
        case TOGGLE_DIFF_MODEL_BY_ID: {
            const id: string = action.payload;
            const updatedModel: DiffModel = toggleDiffModel({ diffModel: state.diffModel.model, id });
            return {
                ...state,
                diffModel: {
                    ...state.diffModel,
                    model: updatedModel
                }
            };
        }
        case EXPAND_OR_COLLAPSE_DIFF_MODEL: {
            const expandAll: boolean = action.payload;
            const updatedModel: DiffModel = toggleDiffModel({ diffModel: state.diffModel.model, expandAll });
            return {
                ...state,
                diffModel: {
                    ...state.diffModel,
                    model: updatedModel
                }
            };
        }
        default: {
            return state;
        }
    }
};

const toggleDiffModel = ({ diffModel, id, expandAll }: ToggleDiffModelParams): DiffModel => {
    let updatedModel: DiffModel = deepCloneObj(diffModel);
    let queue: DiffModel = [...updatedModel];

    while (queue.length) {
        let currentEntity: DiffEntity | undefined = queue.pop();
        if (currentEntity) {
            // Expand/collapse all
            if (typeof expandAll === "boolean") {
                currentEntity.isOpen = expandAll;
            }
            // Toggle by ID
            else if (currentEntity.id === id) {
                currentEntity.isOpen = !currentEntity.isOpen;
                break;
            }
            // Add currentEntity's children to queue
            if (currentEntity.changes.length) {
                queue = queue.concat(currentEntity.changes);
            }
        }
    }

    return updatedModel;
};

export default diffReducer;
