import type { FeatureFlagId } from "@sundaysky/smartvideo-hub-config";
import type { FeatureFlagData, ExcludedProgramData, ExcludedAccountData } from "../../../common/types/featureFlag";
import BaseServices from "../common/BaseServices";
import { EDITING_MODE, STAGE_MODE } from "../common/Consts";


export type FeatureFlags = Record<FeatureFlagId, FeatureFlagData>

export class FeatureFlagsServices extends BaseServices {
    constructor(authService, builderModeRetriever) {
        super(authService, builderModeRetriever);
    }

    getFeatureFlags() {
        return this.get<FeatureFlags>(
            [EDITING_MODE, STAGE_MODE],
            "/rest/featureFlags/"
        );
    }

    getFeatureFlag<T extends FeatureFlagId>(featureFlagId: T) {
        return this.get<{ [id in T]: FeatureFlagData } | undefined>(
            [EDITING_MODE],
            `/rest/featureFlag/${featureFlagId}`
        );
    }

    setFeatureFlag(
        featureFlagId: FeatureFlagId,
        defaultValue: boolean,
        excludedPrograms: ExcludedProgramData[],
        excludedAccounts: ExcludedAccountData[]
    ) {
        const data = { defaultValue, excludedPrograms, excludedAccounts };
        return this.put(
            [EDITING_MODE],
            `/rest/featureFlag/${featureFlagId}`,
            data
        );
    }
}
