import type {
    AnyEntitiesByContextById,
    AnyEntity,
    AnyEntityContextPair,
    GetDependencyIdFunction
} from "./baseEntity";
import {
    BaseEntity
} from "./baseEntity";
import type {
    GetValidationResultFunction,
    Issue,
    ValidationResult
} from "../validations/validationManager";
import {
    IssueCodes,
    IssueSeverity
} from "../validations/validationManager";
import type { Collector, CollectorArgs, Context, EntityInstance, IEntity } from "./definitions";
import { EntityTypes } from "./definitions";
import StateReaderUtils from "../common/StateReaderUtils";
import type { ChangeDetail, DiffResult, GetDiffResultFunc } from "../versionDiff/diffManager";
import { ChangeType } from "../versionDiff/diffManager";
import { updateDiffResultWithChanges } from "../versionDiff/diffUtils";

export default class PlaceholderEntity extends BaseEntity implements IEntity {
    constructor() {
        super(EntityTypes.PLACEHOLDER);
    }

    static generateId = (placeholderName: string, sceneId: string): string => {
        return placeholderName + "_" + sceneId;
    };

    static getScenePlaceholders = (scenes: AnyEntity[]): AnyEntity[] => {
        let placeholders: AnyEntity[] = [];
        if (scenes) {
            Object.keys(scenes).forEach((sceneId) => {
                scenes[sceneId].sceneParts &&
                    scenes[sceneId].sceneParts.forEach((scenePart) => {
                        scenePart.placeholders && placeholders.push(...scenePart.placeholders.map((ph) => ({ sceneId, ...ph })));
                    });
            });
        }
        return placeholders;
    };

    collector: Collector = (args: CollectorArgs): EntityInstance[] => {
        const { context, previousContext } = args;
        let placeholders: EntityInstance[] = [];

        const previousScenes = previousContext && StateReaderUtils.getProjectWireframeScenes(previousContext.state, previousContext.projectName, previousContext.stage, previousContext.version);
        const currentScenes = StateReaderUtils.getProjectWireframeScenes(context.state, context.projectName, context.stage, context.version);
        const previousPlaceholders: AnyEntity[] = PlaceholderEntity.getScenePlaceholders(previousScenes);
        const currentPlaceholders: AnyEntity[] = PlaceholderEntity.getScenePlaceholders(currentScenes);

        const idFunc = (ph) => PlaceholderEntity.generateId(ph.name, ph.sceneId);
        const placeholdersMap: AnyEntitiesByContextById = this.entityListsToMap(previousPlaceholders, currentPlaceholders, idFunc);

        placeholdersMap.forEach((placeholdersPair: AnyEntityContextPair, phId: string) => {
            const { currentEntity: currentPlaceholder, previousEntity: previousPlaceholder } = placeholdersPair;
            placeholders.push({
                id: phId,
                validate: (prevValidationResult: ValidationResult, getValidationResultFunction: GetValidationResultFunction): ValidationResult => {
                    return this.doValidate(currentPlaceholder.name, currentPlaceholder.sceneId, getValidationResultFunction, context);
                },
                diff: (getDiffResult: GetDiffResultFunc): DiffResult => {
                    return this.doDiff(getDiffResult, currentPlaceholder, previousPlaceholder, phId);
                }
            });
        });

        return placeholders;
    };

    doValidate = (placeholderName: string, sceneId: string, getValidationResult: GetValidationResultFunction, context: Context): ValidationResult => {
        const type: EntityTypes = this.getType();
        const id: string = PlaceholderEntity.generateId(placeholderName, sceneId);
        let result: ValidationResult = { type, id, issues: [], name: placeholderName };
        let wireframes = StateReaderUtils.getWireFrame(context.state, context.projectName, context.stage, context.version);
        let sceneLogic = StateReaderUtils.getAllProgramLogicFromWireframes(wireframes, sceneId);

        //validate ph logic
        if (sceneLogic && sceneLogic[placeholderName]) {
            const getDependencyId: GetDependencyIdFunction = () => id;
            let issue: Issue = this.getDependenciesIssue([undefined], EntityTypes.LOGIC, getDependencyId, getValidationResult, IssueCodes.PLACEHOLDER_LOGIC_ERROR);
            if (issue) {
                result.issues.push(issue);
                result.severity = issue.severity;
            }
        }
        else {
            result.issues.push({
                code: IssueCodes.PLACEHOLDER_LOGIC_MISSING,
                severity: IssueSeverity.WARNING,
                details: {}
            });
            result.severity = IssueSeverity.WARNING;
        }

        return result;
    };

    doDiff = (getDiffResult: GetDiffResultFunc, currPlaceholder: AnyEntity, prevPlaceholder: AnyEntity, phId: string): DiffResult => {
        const phName = (currPlaceholder && currPlaceholder.name) || prevPlaceholder.name;
        let result: DiffResult = {
            type: this.getType(),
            id: phId,
            name: phName,
            changeType: ChangeType.None,
            changes: [],
            isShown: false
        };

        if (!prevPlaceholder && currPlaceholder) {
            result.changeType = ChangeType.Add;
            result.isShown = true;
            return result;
        }

        if (!currPlaceholder && prevPlaceholder) {
            result.changeType = ChangeType.Delete;
            result.isShown = true;
            return result;
        }

        const getDependencyId: GetDependencyIdFunction = (id) => id;
        const changeDetail: ChangeDetail = this.getDependenciesDiff([phId], EntityTypes.LOGIC, getDependencyId, getDiffResult, true);

        return updateDiffResultWithChanges(result, changeDetail);
    };
}
