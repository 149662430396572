import { CELL } from "../../components/HubEditor/rightPanels/HubMappingTable";
import type { Asset } from "../../../common/types/asset";
import { AssetTypes } from "../../../common/types/asset";
import type { DataTable, DataTableBody } from "../../../common/types/dataTable";
import { downloadUrl } from "../download/downloadActions";
import AssetUtils from "../common/assetUtils";

class MappingTablesUtils {
    static extractMappingTablesFromAssets = (assets: Asset[]): DataTable[] => {
        return assets.filter((asset) => asset.type === AssetTypes.mappingTable) as DataTable[];
    };

    static findMappingTableByID = (mappingTableId: string, mappingTablesArray: DataTable[]) => {
        return mappingTablesArray.find((MappingTable) => {
            return mappingTableId === MappingTable.assetId;
        });
    };

    static getTitleFromType = (type) => {
        for (let key in CELL) {
            if (type === CELL[key].cellType) {
                return CELL[key].title;
            }
        }
    };

    static getTypeFromTitle = (title) => {
        for (let key in CELL) {
            if (title === CELL[key].title) {
                return CELL[key].cellType;
            }
        }
    };

    static getCharFromType = (type) => {
        for (let key in CELL) {
            if (type === CELL[key].cellType) {
                return CELL[key].char;
            }
        }
        return "";
    };

    static fetchData = (mappingTable: DataTable, isStageView: boolean, downloadFromRESTAction: (url: string, cb: (result: DataTableBody) => void) => void): Promise<DataTableBody> => {
        const url = AssetUtils.getDownloadUrl(mappingTable, isStageView);
        if (MappingTablesUtils.isDirectUrl(url)) {
            return fetch(url).then(res => res.json());
        }
        else {
            return new Promise((resolve) => {
                downloadFromRESTAction(url, (mappingTableData: DataTableBody) => {
                    resolve(mappingTableData);
                });
            });
        }
    };

    static isDirectUrl(url: string) {
        return !url.startsWith("/rest/");
    }
}

export default MappingTablesUtils;
