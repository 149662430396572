import type { DataElement } from "../../../common/types/dataElement";
import { DataElementOrigins } from "../../../common/types/dataElement";

import {
    ANIMATION_REPOSITORY,
    CURATED_REPOSITORY,
    DATA_TABLE_OUTPUT_TYPES,
    HOTSPOT_ID_PROPERTY_NAME,
    LOGIC_DATA_TYPES,
    LOGIC_MEDIA_TYPES,
    LOGIC_TYPE,
    VLX_PARAMTYPE,
    VLX_SCOPE
} from "./consts";

import { getAssetFromActionableData, getElementInfoFromActionableData, isLogicValueHotspot } from "./editorLogicUtils";
import { getValueFromActionableData } from "../Logics/ActionableDataUtils";

import { resolveCandidateKeyFromName } from "../builder/editorsContainerLogic/prioritizedListUtils";
import { onscreenTypes } from "../../../common/commonConst";

import md5 from "md5";
import { DATA_ELEMENT_ORIGIN } from "../../components/legacyCommon/Consts";
import { ActionableDataValueType, LogicType } from "../../../common/types/logic";
import { mapNumToLetter } from "../../../common/generalUtils";
import type { ActionableData, CompoundValue, LogicJSON, PrioritizedSlotActionableData } from "../../../common/types/logic";

let logicOperators = require("./logicOperatos.json");
let logicFunctions = require("./logicFunctions.json");

function stringify(val: string) {
    return `'${val.replace(/'/g, "\\'")}'`;
}

export function getInputLogicType(inputLogic: LogicJSON) {
    return inputLogic?.outputType || "string";
}

let wrapParamValue = function(param) {
    if (param.value === undefined || param.value === null || param.value === true || param.value === false || typeof param.value === "number" || param.type === "number") {
        return `${param.value}`;
    }
    return `"${param.value}"`;
};

/**
 * Builds a string, which the VLX would know to resolve, and read the data element value
 * in the following format: De(<dataElement.displayName>)
 * @param logicElement - the *DataElement* object (as stored in data elements table)
 * @param programDataFields - an object containing { dataElements: Array<DataElements> and assets: Array<Asset> }
 * containing arrays of data fields used in the logic
 * @return {string} - The string the VLX is expecting to properly resolve a data element`
 */
function wrapDataElement(logicElement: ActionableData, programDataFields): string {
    // In case of data elements and mapping tables,
    // get the actual item instead of the logic object stored value
    // (so that we will be updated with the latest changes made upon the object, like rename for example)
    const extractedMetadata = getElementInfoFromActionableData(logicElement, programDataFields);
    let mediaType = extractedMetadata.mediaType || logicElement.mediaType;
    let dataElementName = extractedMetadata.refName || logicElement.name;

    let functionName = `${mediaType}De`;
    let currentValue = `${functionName}('${dataElementName}') `;
    if (logicElement.actions && logicElement.actions.length > 0) {
        currentValue = wrapActions(currentValue, logicElement.actions, programDataFields);
    }
    return currentValue;
}

export function evalScopeVariable(variableName, scope) {
    return { eval: wrapScopeVariable(variableName, scope) };
}

export function wrapScopeVariable(variableName, scope) {
    return scope ? `de('${scope}.${variableName}')` : `de('${variableName}')`;
}

export function wrapPrioritizedList(plName, scope) {
    return `deConditionKeys('${scope}.${plName}')`;
}

export function wrapPrioritizedListSlot(plName, slotIdx, scope) {
    return `${wrapPrioritizedList(plName, scope)}[${slotIdx}]`;
}

export function wrapPrioritizedListNumberOfSlots(plName, scope) {
    let prioritizedList = wrapPrioritizedList(plName, scope);
    return macro(wrapFunction(logicFunctions["# of valid slots ="].name, prioritizedList));
}

export function wrapPrioritizedListSlotName(plName, slotIdx, scope) {
    return { eval: wrapPrioritizedListSlot(plName, slotIdx, scope) };
}

function wrapPrioritizedListSlotPath(plName: string, slotIdx: number) {
    const slotCharacter = mapNumToLetter(slotIdx);
    return `${plName}.slot${slotCharacter}`;
}

function wrapActions(currentValue, actions, programDataFields) {
    let wrappedValue = currentValue;
    actions.forEach((action) => {
        let params = action.params
            ? action.params.map((param) => {
                if (param.type === VLX_PARAMTYPE.TimeZone) {
                    return "stringDe('TimeZone') ";
                }
                else if (param.type === LOGIC_DATA_TYPES.DataElement) {
                    return wrapDataElement(param, programDataFields);
                }
                else {
                    return wrapParamValue(param);
                }
            })
            : [];
        wrappedValue = wrapFunction(action.name, [wrappedValue, ...params]);
    });
    return wrappedValue;
}

function macro(variable) {
    return `[[${variable}]]`;
}

function wrapFunction(func, ...args) {
    return `${func}(${args})`;
}

function getLhs(lhs: ActionableData, func, rhs, dataFields) {
    if (!lhs) {
        return "";
    }
    // lhs must be either a data element or a mapping table
    let variable;
    const extractedMetadata = getElementInfoFromActionableData(lhs, dataFields);

    if (lhs && (lhs.type === LOGIC_DATA_TYPES.DataElement || lhs.type === LOGIC_DATA_TYPES.MappingTable)) {
        variable = wrapDataElement(lhs, dataFields);
    }
    else if (lhs.type === ActionableDataValueType.Prioritized) {
        variable = wrapPrioritizedList(extractedMetadata.refName, VLX_SCOPE.Scene);
    }
    else if (lhs.type === ActionableDataValueType.PrioritizedSlot && func.replaceCandidateName) {
        variable = wrapPrioritizedListSlot(extractedMetadata.refName, (lhs as PrioritizedSlotActionableData).slotIdx, VLX_SCOPE.Scene);
    }
    else if (lhs.type === ActionableDataValueType.PrioritizedSlot) {
        const variablePath = wrapPrioritizedListSlotPath(extractedMetadata.refName, (lhs as PrioritizedSlotActionableData).slotIdx);
        variable = wrapScopeVariable(variablePath, VLX_SCOPE.Scene);
    }
    if (typeof func === "object") {
        if (func.handleRhsAsArg) {
            return wrapFunction(func.name, [variable, rhs]);
        }
        else {
            return wrapFunction(func.name, [variable]);
        }
    }
    return variable;
}

function getRhs(lhs, rhs, dataFields) {
    // handle return array (from CombinedInput)
    if (rhs instanceof Array) {
        return rhs.reduce((acc, item) => {
            if (acc) {
                acc += "+";
            }
            acc += getRhs(lhs, item, dataFields);
            return acc;
        }, "");
    }

    if (rhs && typeof rhs === "object") {
        if (rhs.type === LOGIC_DATA_TYPES.DataElement || rhs.type === LOGIC_DATA_TYPES.MappingTable) {
            return wrapDataElement(rhs, dataFields);
        }
        else if (rhs.type === LOGIC_DATA_TYPES.Const) {
            // handle return object (from NumberInput)
            if (rhs.mediaType === LOGIC_MEDIA_TYPES.Number) {
                return Number(rhs.name);
            }
            else {
                return stringify(rhs.name);
            }
        }
        else if (rhs.type === LOGIC_DATA_TYPES.ValueSetValue) {
            let elementInfo = getElementInfoFromActionableData(rhs, dataFields);
            if (elementInfo) {
                if (elementInfo.mediaType === LOGIC_MEDIA_TYPES.Number) {
                    return Number(elementInfo.displayName);
                }
                else {
                    return stringify(elementInfo.displayName);
                }
            }
        }

        return undefined;
    }

    if (typeof rhs === "string") {
        if (lhs && lhs.mediaType === LOGIC_MEDIA_TYPES.Number && (Number(rhs) || Number(rhs) === 0)) {
            return Number(rhs);
        }
        else {
            return stringify(rhs);
        }
    }
    else {
        return rhs;
    }
}

function buildRuleWhen(whens, dataFields) {
    return whens.reduce((acc, whenItem) => {
        if (typeof whenItem === "object") {
            let rhs = getRhs(whenItem.lhs, whenItem.rhs, dataFields);
            const operator = logicOperators[whenItem.operator];
            const func = logicFunctions[whenItem.operator];
            if (func && func.replaceCandidateName && typeof rhs === "string" && whenItem.lhs.mediaType === LOGIC_TYPE.Prioritized) {
                rhs = resolveCandidateKeyFromName(rhs, whenItem.lhs.id, dataFields.prioritizedListLogic);
            }
            const lhs = getLhs(whenItem.lhs, func, rhs, dataFields);

            acc += lhs;

            const shouldConcatenateRhs = !logicFunctions[whenItem.operator] || (logicFunctions[whenItem.operator].expectsRhs && !logicFunctions[whenItem.operator].handleRhsAsArg);

            if (shouldConcatenateRhs) {
                acc += " " + operator + " " + rhs;
            }
        }
        else if (typeof whenItem === "string") {
            acc += " " + logicOperators[whenItem] + " ";
        }

        return acc;
    }, "");
}

function valueIsDerived(value: ActionableData, dataElements: DataElement[]) {
    return Boolean(dataElements && Array.isArray(dataElements) && dataElements.find((de) => de.id === value.id && de.origin === DATA_ELEMENT_ORIGIN.DERIVED));
}

function valueIsOfOrigin(value: ActionableData, dataElements: DataElement[], origin: DataElementOrigins): boolean {
    return Boolean(dataElements && Array.isArray(dataElements) && dataElements.find((de) => de.id === value.id && de.origin === origin));
}

function isOfAssetType(type: LogicType): boolean {
    return Boolean(type === LogicType.Image || type === LogicType.Video || type === LogicType.Audio);
}

export function buildValue(
    value: any,
    phType: LogicType,
    useAbsolutePath = false,
    programDataFields,
    supportMultipleStories = false,
    breakHotspotValue = false,
    isValueOfBackgroundAsset = false
): any {
    //isValueOfBackgroundAsset is temp solution. We will ba able to remove this flag once:
    // 1. RAAS will except empty string (and not only null) value for background asset (video/audio/image).
    // 2. RAAS  will except "source" (and not only "href") in background assets resolving.

    if (typeof value === "undefined" || value === null) {
        return isValueOfBackgroundAsset ? null : "";
    }

    if (breakHotspotValue && isLogicValueHotspot(value)) {
        return { actionRef: buildValue(getHotspotId(value), LogicType.Text, useAbsolutePath, programDataFields, supportMultipleStories) };
    }

    if (phType === LogicType.Compound) {
        let compoundItemValue = {};
        value.forEach((item: CompoundValue) => {
            let outputType = item.outputType;
            if (isLogicValueHotspot(item.value)) {
                outputType = breakHotspotValue ? LogicType.Text : onscreenTypes.Hotspot;
            }
            if (item.outputType === LogicType.Ratio || item.outputType === LogicType.Quality) {
                outputType = LogicType.Text;
            }
            compoundItemValue[item.id] = {
                type: outputType,
                value: buildValue(item.value, item.outputType as LogicType, useAbsolutePath, programDataFields, supportMultipleStories, breakHotspotValue)
            };
        });
        return compoundItemValue;
    }

    if (phType === LogicType.Prioritized) {
        let slotContentValue = {};

        value.forEach((item) => {
            const outputType = isLogicValueHotspot(item.value) ? onscreenTypes.Hotspot : item.outputType;
            slotContentValue[item.displayName] = {
                type: outputType,
                value: buildValue(item.value, item.outputType, useAbsolutePath, programDataFields)
            };
        });

        return slotContentValue;
    }

    if (phType === LogicType.NextScene && supportMultipleStories) {
        return md5(JSON.stringify(value));
    }

    if (phType === LogicType.Story) {
        return buildStoryValue(value, programDataFields.storyIdToStoryAssetName);
    }

    if (value instanceof Array) {
        return value.reduce((acc, item) => {
            acc += buildValue(item, phType, useAbsolutePath, programDataFields);
            return acc;
        }, "");
    }

    const dataType = valueIsDerived(value, programDataFields.dataElements) ? DATA_ELEMENT_ORIGIN.DERIVED : value.type;
    const isUrlThatRepresentsMediaAsset = value.mediaType === LOGIC_MEDIA_TYPES.URL && isOfAssetType(phType);
    const isCreativeAssetDE =
        isOfAssetType(value.mediaType) && isOfAssetType(phType) && dataType === LOGIC_DATA_TYPES.DataElement && valueIsOfOrigin(value, programDataFields.dataElements, DataElementOrigins.Creative);

    if (dataType === DATA_ELEMENT_ORIGIN.DERIVED && isUrlThatRepresentsMediaAsset) {
        // studio data of URL type shouldn'be wrapped with "source". the placeholder that uses them will wrap them
        let currentValue = wrapDataElement(value, programDataFields);
        return { eval: currentValue };
    }
    else if (dataType === LOGIC_DATA_TYPES.DataElement || dataType === LOGIC_DATA_TYPES.MappingTable || dataType === DATA_ELEMENT_ORIGIN.DERIVED) {
        let currentValue = wrapDataElement(value, programDataFields);
        if (isUrlThatRepresentsMediaAsset || isCreativeAssetDE) {
            return isValueOfBackgroundAsset ? { eval: `({ href: ${currentValue}})` } : { eval: `({ source: ${currentValue}})` };
        }
        else if (value.mediaType === LOGIC_TYPE.Color) {
            return { eval: currentValue };
        }
        else if (value.mediaType === DATA_TABLE_OUTPUT_TYPES.Url) {
            return { eval: `({ source: ${currentValue}})` };
        }
        else {
            return macro(currentValue);
        }
    }
    else if (dataType === LOGIC_DATA_TYPES.Asset) {
        const assetRef = {
            "repo-url": value.name,
            repository: CURATED_REPOSITORY
        };
        return isValueOfBackgroundAsset ? { href: assetRef } : { source: assetRef };
    }
    else if (dataType === LOGIC_DATA_TYPES.Animation) {
        // Find animation
        const animationAsset = getAssetFromActionableData(value, programDataFields.assets);
        const animationPath = useAbsolutePath && animationAsset ? animationAsset.self : getValueFromActionableData(value) + "/";
        return {
            href: {
                "repo-url": animationPath,
                repository: ANIMATION_REPOSITORY
            }
        };
    }
    else if (dataType === LOGIC_DATA_TYPES.Variable) {
        return macro(wrapScopeVariable(value.path || value.name, VLX_SCOPE.Scene));
    }
    else {

        // Backward compatibility - there shouldn't be a slot path on actionable data anymore.
        if (value.slotPath) {
            return macro(wrapScopeVariable(value.slotPath, VLX_SCOPE.Scene));
        }

        else {
            return value.name !== undefined && value.name !== null ? value.name : value;
        }
    }
}

function buildStoryValue(value: string, storyIdToStoryAssetName: { [storyId: string]: string }) {
    let storyAssetName = storyIdToStoryAssetName[value];
    return storyAssetName;
}

function getHotspotId(value) {
    const idIndex = value.findIndex((item) => item.id === HOTSPOT_ID_PROPERTY_NAME);
    const idValue = value.slice(idIndex, idIndex + 1);
    return idValue[0].value;
}

/***
 *
 * @param inputLogic - The logic object to be converted in the VLX structure
 * @param useAbsolutePath - Should animation path be absulote, usually false, true only for animatic previews (As they are created by the "Studio" (hub :]) app
 * @param programDataFields : { dataElements: Array<DataElements>, assets: <Assets> }
 * @param supportMultipleStories Does the project support the new feature of multiple stories
 * @param isValueOfBackgroundAsset: are the rules being build belong to background asset (audio/ video/ image)
 * @return {*}
 */
export function buildInputRules(inputLogic: LogicJSON, programDataFields = {}, useAbsolutePath = false, supportMultipleStories = false, breakHotspotValue = false, isValueOfBackgroundAsset = false) {
    if (!inputLogic) {
        return [{ value: "" }];
    }

    let rules = [];

    if (inputLogic.rules) {
        rules = inputLogic.rules.map((rule) => {
            // when always is true, override rule.whens
            let when = rule.always === true ? "true" : buildRuleWhen(rule.whens, programDataFields);
            let value = buildValue(rule.value, inputLogic.outputType, useAbsolutePath, programDataFields, supportMultipleStories, breakHotspotValue, isValueOfBackgroundAsset);

            return { when, value, key: rule.key };
        });
    }

    // Add default value as a last rule, unless prioritized
    if (inputLogic.outputType !== LOGIC_TYPE.Prioritized) {
        rules.push({
            value: buildValue(inputLogic.defaultValue, inputLogic.outputType, useAbsolutePath, programDataFields, supportMultipleStories, breakHotspotValue, isValueOfBackgroundAsset)
        });
    }

    return rules;
}

function wrapAnalyticsValue(analyticsKey, value) {
    if (Array.isArray(value)) {
        return [`${analyticsKey}=`, ...value];
    }
    if (!value) {
        return `${analyticsKey}=`;
    }
    return [`${analyticsKey}=`, value];
}

// Builds logic in the final value form of `key=${value}`
export function buildAnalyticsRules(inputAnalyticsLogic: LogicJSON, analyticsKey: string, programDataFields) {
    if (!inputAnalyticsLogic) {
        return [{ value: wrapAnalyticsValue(analyticsKey, "") }];
    }

    let rules = [];

    if (inputAnalyticsLogic.rules) {
        rules = inputAnalyticsLogic.rules.map((rule) => {
            let when = buildRuleWhen(rule.whens, programDataFields);
            let value = buildValue(wrapAnalyticsValue(analyticsKey, rule.value), LogicType.AnalyticCustomField, false, programDataFields);

            return { when, value, key: rule.key };
        });
    }

    rules.push({
        value: buildValue(wrapAnalyticsValue(analyticsKey, inputAnalyticsLogic.defaultValue), LogicType.AnalyticCustomField, false, programDataFields)
    });
    return rules;
}
