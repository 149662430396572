import { ProgramType } from "../../../common/types/program";
import { GqlClientEditorProgramType, GqlClientLifecycleStage, GqlClientProgramType } from "../../graphql/graphqlGeneratedTypes/graphqlClient";
import type { SelectItem } from "../framework/types";

export type ValidationResult = {
    result: boolean;
    message?: string;
};

export enum ProgramTypeDisplayName {
    CRM = "Owned Media",
    Ads = "Paid Media"
}

export const programTypeMenuItems: SelectItem[] = [
    { key: ProgramType.CRM, value: ProgramType.CRM, text: ProgramTypeDisplayName.CRM },
    { key: ProgramType.Ads, value: ProgramType.Ads, text: ProgramTypeDisplayName.Ads }
];

export const publishTargetMenuItems: SelectItem[] = [
    { key: GqlClientLifecycleStage.UAT, value: GqlClientLifecycleStage.UAT, text: "UAT" },
    { key: GqlClientLifecycleStage.PROD, value: GqlClientLifecycleStage.PROD, text: "Production" }
];

export const getProgramTypeDisplayName = (type: ProgramType): ProgramTypeDisplayName => {
    switch (type) {
        case ProgramType.Ads:
            return ProgramTypeDisplayName.Ads;
        case ProgramType.CRM:
            return ProgramTypeDisplayName.CRM;
    }
};

export const getGQLProgramType = (type: ProgramType): GqlClientProgramType => {
    switch (type) {
        case ProgramType.Ads:
            return GqlClientProgramType.ADS;
        case ProgramType.CRM:
            return GqlClientProgramType.CRM;
    }
};

export const getGQLEditorProgramType = (type: ProgramType): GqlClientEditorProgramType => {
    switch (type) {
        case ProgramType.Ads:
            return GqlClientEditorProgramType.ADS;
        case ProgramType.CRM:
            return GqlClientEditorProgramType.CRM;
    }
};

export const programNameValidation = (programName: string): ValidationResult => {
    //empty
    if (!programName) {
        return {
            result: false,
            message: "Enter program name"
        };
    }
    return {
        result: true
    };
};

export enum PROGRAM_ERROR_TEXT {
    None = " ",
    NoValue = "Value must not be blank",
    IsInUse = "This name already exists. Use a different name.",
    NoIndustry = ""
}
