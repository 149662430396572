import BaseServices from "./BaseServices";
import { EDITING_MODE, STAGE_MODE } from "./Consts";
import type {
    PreviewNarrationBody,
    PreviewNarrationResponse,
    PreviewPrepareBody,
    PreviewPrepareResponse,
    PreviewSpecBody,
    PreviewSpecResponse,
    PreviewVideoBody,
    ExportVideoBody
} from "../../../common/types/restApiTypes";
import type { PreviewVideoResponse } from "../../../common/external/videoSpecResponse";

class VideoPreviewServices extends BaseServices {
    constructor(authService, builderModeRetriever) {
        super(authService, builderModeRetriever);
    }

    prepareInlineStory(accountId: string, programId: string, data: PreviewPrepareBody): Promise<PreviewPrepareResponse> {
        return this.post<PreviewPrepareResponse>([EDITING_MODE, STAGE_MODE], `/rest/${accountId}/programs/${programId}/preview/prepare`, data);
    }

    getVideo(accountId: string, programId: string, data: PreviewVideoBody): Promise<PreviewVideoResponse> {
        return this.post<PreviewVideoResponse>([EDITING_MODE, STAGE_MODE], `/rest/${accountId}/programs/${programId}/preview/video`, data);
    }

    getVideoDescriptionPdf(accountId: string, programId: string, data: ExportVideoBody): Promise<{pdf: Buffer}> {
        return this.post<{pdf: Buffer}>([EDITING_MODE, STAGE_MODE], `/rest/${accountId}/programs/${programId}/export/description`, data);
    }

    getVideoSpec(accountId: string, programId: string, data: PreviewSpecBody): Promise<PreviewSpecResponse> {
        return this.post<PreviewSpecResponse>([EDITING_MODE, STAGE_MODE], `/rest/${accountId}/programs/${programId}/preview/spec`, data);
    }

    getMockNarrationPreview(data: PreviewNarrationBody): Promise<PreviewNarrationResponse> {
        return this.post<PreviewSpecResponse>([EDITING_MODE, STAGE_MODE], "/rest/preview/narration", data);
    }
}

export default VideoPreviewServices;
