import type { Scene } from "../../../common/types/scene";

export const newScene = (sceneName: string): Omit<Scene, "id"> => {
    return {
        name: sceneName,
        backgroundImage: null,
        sceneParts: [],
        creationTime: new Date().getTime().toString()
    };
};
