import type { AnyEntitiesByContextById, AnyEntity, AnyEntityById, AnyEntityContextPair, GetDependencyIdFunction } from "./baseEntity";
import { BaseEntity } from "./baseEntity";
import type { GetValidationResultFunction, Issue, ValidationResult } from "../validations/validationManager";
import ValidationManager, { IssueCodes } from "../validations/validationManager";
import type { Collector, CollectorArgs, Context, EntityInstance, IEntity } from "./definitions";
import { EntityTypes } from "./definitions";
import StateReaderUtils from "../common/StateReaderUtils";
import { ASSET_TYPES } from "../vlx/consts";
import { getRecordingNarrationId } from "../common/exportNarrationsUtils";
import { LogicContainers } from "../../../common/commonConst";
import type { ChangeDetail, DiffResult, GetDiffResultFunc } from "../versionDiff/diffManager";
import DiffManager, { ChangeType } from "../versionDiff/diffManager";
import type { PrioritizedList } from "../common/types";
import PrioritizedListEntity from "./prioritizedListEntity";
import RecordingEntity from "./recordingEntity";
import NarrationEntity from "./narrationEntity";

export default class ScenePartEntity extends BaseEntity implements IEntity {
    constructor() {
        super(EntityTypes.SCENE_PART);
    }

    private generateName = (scenePartIndex: number): string => {
        return `Frame#${scenePartIndex + 1}`;
    };

    collector: Collector = ({ context, previousContext }: CollectorArgs): EntityInstance[] => {
        return !previousContext ? this.validationCollector(context) : this.diffCollector({ context, previousContext });
    };

    validationCollector: Collector = (context): EntityInstance[] => {
        let sceneParts: EntityInstance[] = [];
        let allScenes = StateReaderUtils.getProjectWireframeScenes(context.state, context.projectName, context.stage, context.version);
        if (allScenes) {
            Object.keys(allScenes).forEach((sceneId) => {
                allScenes[sceneId].sceneParts &&
                    allScenes[sceneId].sceneParts.forEach((scenePart, index) => {
                        const narrationPartsInScenePart: string[] = [];
                        scenePart.NarrationParts &&
                            scenePart.NarrationParts.forEach((narrationPart) => {
                                narrationPartsInScenePart.push(narrationPart.id);
                            });
                        sceneParts.push({
                            id: `${sceneId}_${scenePart.scenePart}`,
                            validate: (prevValidationResult: ValidationResult, getValidationResultFunction: GetValidationResultFunction): ValidationResult => {
                                return this.doValidate(scenePart, sceneId, allScenes[sceneId].name, getValidationResultFunction, context, narrationPartsInScenePart, index);
                            }
                        });
                    });
            });
        }
        return sceneParts;
    };

    doValidate = (
        scenePart: any,
        sceneId: string,
        sceneName: string,
        getValidationResult: GetValidationResultFunction,
        context: Context,
        narrationPartsInScenePart: string[],
        partIndex: number
    ): ValidationResult => {
        const type: EntityTypes = this.getType();
        const id: string = scenePart.scenePart;
        let scenePartValidationResult: ValidationResult = { type, id, issues: [], name: this.generateName(partIndex) };

        // check placeholders validity
        if (scenePart.placeholders) {
            const getDependencyId: GetDependencyIdFunction = (dependency) => dependency.name + "_" + sceneId;
            let issue: Issue = this.getDependenciesIssue(scenePart.placeholders, EntityTypes.PLACEHOLDER, getDependencyId, getValidationResult, IssueCodes.SCENE_PART_PLACEHOLDER_ERROR);
            if (issue) {
                scenePartValidationResult.issues.push(issue);
                scenePartValidationResult.severity = issue.severity;
            }
        }

        // check groups validity
        if (scenePart.groups) {
            const getDependencyId: GetDependencyIdFunction = (dependency) => dependency.id;
            let issue: Issue = this.getDependenciesIssue(scenePart.groups, EntityTypes.GROUP, getDependencyId, getValidationResult, IssueCodes.SCENE_PART_GROUP_ERROR);
            if (issue) {
                scenePartValidationResult.issues.push(issue);
                scenePartValidationResult.severity = ValidationManager.getHighestSeverity([scenePartValidationResult.severity, issue.severity]);
            }
        }

        // check prioritizedLists validity
        if (scenePart.prioritizedLists) {
            const getDependencyId: GetDependencyIdFunction = (dependency: PrioritizedList) => PrioritizedListEntity.generateId(dependency.id, sceneId);
            let issue: Issue = this.getDependenciesIssue(scenePart.prioritizedLists, EntityTypes.PRIORITIZED_LIST, getDependencyId, getValidationResult, IssueCodes.SCENE_PART_PRIORITIZED_LIST_ERROR);
            if (issue) {
                scenePartValidationResult.issues.push(issue);
                scenePartValidationResult.severity = ValidationManager.getHighestSeverity([scenePartValidationResult.severity, issue.severity]);
            }
        }

        // check parameters validity
        if (scenePart.parameters) {
            const getDependencyId: GetDependencyIdFunction = (dependency) => dependency.name + "_" + sceneId;
            let issue: Issue = this.getDependenciesIssue(scenePart.parameters, EntityTypes.PARAMETER, getDependencyId, getValidationResult, IssueCodes.SCENE_PART_PARAMETER_ERROR);
            if (issue) {
                scenePartValidationResult.issues.push(issue);
                scenePartValidationResult.severity = ValidationManager.getHighestSeverity([scenePartValidationResult.severity, issue.severity]);
            }
        }

        let project = StateReaderUtils.getProject(context.state, context.projectName);
        const narrationsVersion = StateReaderUtils.getNarrationsVersionFromState(context.state, context.projectName, context.stage, context.version);

        if (narrationsVersion > 0) {
            // check narrations validity
            if (scenePart.NarrationParts) {
                const getDependencyId: GetDependencyIdFunction = new NarrationEntity().getEntityId;
                let issue: Issue = this.getDependenciesIssue(scenePart.NarrationParts, EntityTypes.NARRATION, getDependencyId, getValidationResult, IssueCodes.SCENE_PART_NARRATION_ERROR);
                if (issue) {
                    scenePartValidationResult.issues.push(issue);
                    scenePartValidationResult.severity = ValidationManager.getHighestSeverity([scenePartValidationResult.severity, issue.severity]);
                }
            }

            // check recordings validity
            const allRecordings = StateReaderUtils.getProjectAssetsByType(project, context.state.assets, ASSET_TYPES.recording);
            const projectRFR = StateReaderUtils.getProgramRFR(context.state, context.projectName, context.stage, context.version);

            // Todo - Or: improve performance
            const scenePartRecordings = allRecordings.filter(
                (rec) => rec.RFR >= projectRFR && rec.narrationMetadata && narrationPartsInScenePart && narrationPartsInScenePart.includes(getRecordingNarrationId(rec.name))
            );

            if (scenePartRecordings) {
                const getDependencyId: GetDependencyIdFunction = new RecordingEntity().getAssetId;
                let issue: Issue = this.getDependenciesIssue(scenePartRecordings, EntityTypes.RECORDING, getDependencyId, getValidationResult, IssueCodes.SCENE_PART_RECORDING_ERROR);
                if (issue) {
                    scenePartValidationResult.issues.push(issue);
                    scenePartValidationResult.severity = ValidationManager.getHighestSeverity([scenePartValidationResult.severity, issue.severity]);
                }
            }
        }

        return scenePartValidationResult;
    };

    diffCollector: Collector = ({ context, previousContext }: CollectorArgs): EntityInstance[] => {
        let scenePartInstances: EntityInstance[] = [];

        const allPreviousScenes = StateReaderUtils.getProjectWireframeScenes(previousContext.state, previousContext.projectName, previousContext.stage, previousContext.version);
        const allPreviousSceneParts: AnyEntityById = ScenePartEntity.extractScenePartsByIdFromAllScenes(allPreviousScenes);

        const allCurrentScenes = StateReaderUtils.getProjectWireframeScenes(context.state, context.projectName, context.stage, context.version);
        const allCurrentSceneParts: AnyEntityById = ScenePartEntity.extractScenePartsByIdFromAllScenes(allCurrentScenes);

        const scenePartsMap: AnyEntitiesByContextById = this.entityObjectsToMap(allPreviousSceneParts, allCurrentSceneParts);
        scenePartsMap.forEach((scenePartPair: AnyEntityContextPair, scenePartId: string) => {
            scenePartInstances.push({
                id: scenePartId,
                diff: (getDiffResult: GetDiffResultFunc): DiffResult => this.doDiff(getDiffResult, scenePartPair.previousEntity, scenePartPair.currentEntity)
            });
        });

        return scenePartInstances;
    };

    doDiff = (getDiffResult: GetDiffResultFunc, previousScenePart: AnyEntity, currentScenePart: AnyEntity): DiffResult => {
        const belongsToScene = this.getPropsFromPreviousOrCurrent(currentScenePart, previousScenePart, "belongsToScene");
        const latestKnownIndex = this.getPropsFromPreviousOrCurrent(currentScenePart, previousScenePart, "spIdx");
        const id = this.getPropsFromPreviousOrCurrent(currentScenePart, previousScenePart, "scenePart");

        // sceneParts are a wrapper for other entities.
        // They can't be compared, but we do want to know if sp was deleted or added.
        // Unless metadata is added to scenepart, it's ChangeType should not be Edit.
        let changeType: ChangeType = ChangeType.None;
        if (!previousScenePart && currentScenePart) {
            changeType = ChangeType.Add;
        }
        else if (previousScenePart && !currentScenePart) {
            changeType = ChangeType.Delete;
        }

        const result: DiffResult = {
            type: this.getType(),
            id,
            name: this.generateName(latestKnownIndex),
            changeType,
            changes: [],
            isShown: changeType !== ChangeType.None
        };

        if (result.changeType === ChangeType.None) {
            let changeList: ChangeType[] = [];

            // placeholders
            const placeholderIds: string[] = this.extractEntityIdsFromSceneParts(previousScenePart, currentScenePart, "placeholders", (ph: AnyEntity): string => ph.id || ph.name);
            const getPlaceholderDependencyId: GetDependencyIdFunction = (id) => `${id}_${belongsToScene}`;
            const placeholderDiff: ChangeDetail = this.getDependenciesDiff(placeholderIds, EntityTypes.PLACEHOLDER, getPlaceholderDependencyId, getDiffResult, false);
            if (placeholderDiff) {
                result.changes.push(placeholderDiff);
                changeList.push(placeholderDiff.changeType);
            }

            // parameters
            const parameterIds: string[] = this.extractEntityIdsFromSceneParts(previousScenePart, currentScenePart, "parameters", (pr: AnyEntity): string => pr.id || pr.name);
            const getParameterDependencyId: GetDependencyIdFunction = (id) => `${id}_${belongsToScene}`;
            const parameterDiff: ChangeDetail = this.getDependenciesDiff(parameterIds, EntityTypes.PARAMETER, getParameterDependencyId, getDiffResult, false);
            if (parameterDiff) {
                result.changes.push(parameterDiff);
                changeList.push(parameterDiff.changeType);
            }

            // groups
            const groupIds: string[] = this.extractEntityIdsFromSceneParts(previousScenePart, currentScenePart, "groups", (grp: AnyEntity): string => grp.id);
            const getGroupDependencyId: GetDependencyIdFunction = (id) => id;
            const groupDiff: ChangeDetail = this.getDependenciesDiff(groupIds, EntityTypes.GROUP, getGroupDependencyId, getDiffResult, false);
            if (groupDiff) {
                result.changes.push(groupDiff);
                changeList.push(groupDiff.changeType);
            }

            // prioritized lists
            const prioritizedListIds: string[] = this.extractEntityIdsFromSceneParts(previousScenePart, currentScenePart, "prioritizedLists", (pl: AnyEntity): string => pl.id);
            const getPLDependencyId: GetDependencyIdFunction = (id) => id;
            const prioritizedListDiff: ChangeDetail = this.getDependenciesDiff(prioritizedListIds, EntityTypes.PRIORITIZED_LIST, getPLDependencyId, getDiffResult, false);
            if (prioritizedListDiff) {
                result.changes.push(prioritizedListDiff);
                changeList.push(prioritizedListDiff.changeType);
            }

            // narrations
            const narrationIds: string[] = this.extractEntityIdsFromSceneParts(previousScenePart, currentScenePart, "NarrationParts", new NarrationEntity().getEntityId);
            const getNarrationDependencyId: GetDependencyIdFunction = (id) => id;
            const narrationsDiff: ChangeDetail = this.getDependenciesDiff(narrationIds, EntityTypes.NARRATION, getNarrationDependencyId, getDiffResult, false);
            if (narrationsDiff) {
                result.changes.push(narrationsDiff);
                changeList.push(narrationsDiff.changeType);
            }

            result.changeType = DiffManager.getChangeType(changeList, false);
            result.isShown = result.changeType !== ChangeType.None;
        }

        return result;
    };

    static extractScenePartsByIdFromAllScenes = (allScenes): AnyEntityById => {
        let allSceneParts: AnyEntityById = {};
        Object.entries(allScenes).forEach(([sceneId, sceneData]: [string, AnyEntity]) => {
            if (sceneId !== LogicContainers.Master && sceneData.sceneParts) {
                sceneData.sceneParts.forEach((scenePart: AnyEntity, index: number) => {
                    let dupSp = {
                        ...scenePart,
                        belongsToScene: sceneId,
                        spIdx: index
                    };
                    allSceneParts[`${sceneId}_${scenePart.scenePart}`] = dupSp;
                });
            }
        });
        return allSceneParts;
    };

    private extractEntityIdsFromSceneParts = (previousScenePart: AnyEntity, currentScenePart: AnyEntity, prop: string, identFunc: (AnyEntity) => string) => {
        const allPropsArray = [...((previousScenePart && previousScenePart[prop]) || []), ...((currentScenePart && currentScenePart[prop]) || [])];
        return Array.from(new Set(allPropsArray.map(identFunc)));
    };
}
