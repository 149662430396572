import type { GetValidationResultFunction, ValidationResult } from "../validations/validationManager";
import { IssueCodes, IssueSeverity } from "../validations/validationManager";
import type { Collector, CollectorArgs, Context, EntityInstance, IEntity } from "./definitions";
import { EntityTypes } from "./definitions";
import type { AnyEntitiesByContextById, AnyEntity, AnyEntityContextPair } from "./baseEntity";
import { BaseEntity } from "./baseEntity";
import { LOGIC_TYPE, VALIDATION_STATUS } from "../vlx/consts";
import StateReaderUtils from "../common/StateReaderUtils";
import { validateInputLogic } from "../vlx/Validations/logicValidations";
import type { LogicValidationContext } from "../vlx/Validations/logicValidations";
import SceneAnimationEntity from "./sceneAnimationEntity";
import SceneValidationEntity from "./sceneValidationEntity";
import AnalyticsEntity from "./analyticsEntity";
import DataElementEntity from "./dataElementEntity";
import { findDataElementById, findDataElementByName } from "../DataElements/DataElementsManager";
import { LogicContainers } from "../../../common/commonConst";
import type { Asset } from "../../../common/types/asset";
import { MediaTypes } from "../../../common/types/asset";
import type { ChangeType, DiffResult } from "../versionDiff/diffManager";
import { diffEntities, getProgramPropertyCollectionId, getStoryPropertyCollectionId } from "../versionDiff/diffUtils";
import type { Story } from "../../../common/types/story";
import type { DataElement } from "../../../common/types/dataElement";
import type { ValidationShape, LogicJSON, Program } from "../common/types";
import { isAnEmptyObject } from "../../../common/generalUtils";
import { convertArrayOfObjectsToObjectWithKeys } from "../../../common/arrayUtils";
import memoizeOne from "memoize-one";
import { featureFlagConst } from "@sundaysky/smartvideo-hub-config";
import { LogicContext } from "../../../common/types/logic";
import {
    createNewStoryQualityRatioLogic,
    shouldUseVideoRatioAndQualityLogic
} from "../Logics/StoryRatioAndQualityLogic";
const { CRM_STORY_VIDEO_RATIO_QUALITY_LOGIC } = featureFlagConst;

type DoDiffLogic = {
    id: string;
    previousLogic: any;
    currentLogic: any;
    name: string;
};

export default class LogicEntity extends BaseEntity implements IEntity {
    constructor() {
        super(EntityTypes.LOGIC);
    }

    static STORY_SELECTION_LOGIC_ID = "ssl_id";
    static VIDEO_RATIO_QUALITY_LOGIC_VALIDATION_ID = "ratio_quality_logic_validation_id";

    static convertTypeToName = (programLogicType: string) => {
        switch (programLogicType) {
            case LogicContainers.StorySelection:
                return "Story Selection";
            default:
                return programLogicType;
        }
    };

    static buildAssetsForContext = memoizeOne((assetsArr: Asset[]) => {
        return convertArrayOfObjectsToObjectWithKeys(assetsArr, ["name", "type"], (name, type) => `${type}/${name}`);
    });

    collector: Collector = (args: CollectorArgs): EntityInstance[] => {
        const { context, previousContext } = args;

        let allLogicObjects: EntityInstance[] = [];
        if (!previousContext) {
            allLogicObjects = this.validationCollector(context);
        }
        else {
            allLogicObjects = this.diffCollector(previousContext, context);
        }
        return allLogicObjects;
    };

    validationCollector = (context: Context): EntityInstance[] => {
        let allLogicObjects: EntityInstance[] = [];
        const { allScenesIds, logic, wireFrame, allDataElements, useVideoRatioAndQualityLogic } = this.getDataFromContext(context);

        if (logic) {
            Object.keys(logic).forEach((logicContainerKey) => {
                if (logicContainerKey === LogicContainers.Derived) {
                    Object.keys(logic.derived).forEach((derivedId) => {
                        let derivedObj = findDataElementById(derivedId, allDataElements) || findDataElementByName(derivedId, allDataElements);
                        if (derivedObj) {
                            allLogicObjects.push({
                                id: derivedObj.id,
                                validate: (prevValidationResult: ValidationResult, getValidationResultFunction: GetValidationResultFunction): ValidationResult => {
                                    return this.doValidate(logic.derived[derivedId], getValidationResultFunction, context, derivedObj.id);
                                }
                            });
                        }
                    });
                }
                else if (logicContainerKey === LogicContainers.Program) {
                    Object.keys(logic[LogicContainers.Program]).forEach((programLogicType) => {
                        if (programLogicType === LogicContainers.StorySelection) {
                            let storySelectionLogic: LogicJSON = logic[LogicContainers.Program][LogicContainers.StorySelection][LogicContainers.StorySelection];
                            allLogicObjects.push({
                                id: LogicEntity.STORY_SELECTION_LOGIC_ID,
                                validate: (prevValidationResult: ValidationResult, getValidationResultFunction: GetValidationResultFunction): ValidationResult => {
                                    return this.doValidate(storySelectionLogic, getValidationResultFunction, context, LogicEntity.STORY_SELECTION_LOGIC_ID);
                                }
                            });
                        }
                    });
                }
                else if (logicContainerKey === LogicContainers.Story) {
                    Object.keys(logic.story).forEach((storyId) => {
                        let story: Story = StateReaderUtils.getStoryById(wireFrame, storyId);
                        if (logic.story[storyId] && logic.story[storyId].decisionPoint) {
                            Object.keys(logic.story[storyId].decisionPoint).forEach((dp) => {
                                const DPValidationId: string = getStoryPropertyCollectionId(storyId, dp);
                                allLogicObjects.push({
                                    id: DPValidationId,
                                    validate: (prevValidationResult: ValidationResult, getValidationResultFunction: GetValidationResultFunction): ValidationResult => {
                                        return this.doValidate(logic.story[storyId].decisionPoint[dp], getValidationResultFunction, context, DPValidationId);
                                    }
                                });
                            });
                        }
                        if (logic.story[storyId] && logic.story[storyId][LogicContainers.Soundtrack] && logic.story[storyId][LogicContainers.Soundtrack][MediaTypes.Audio]) {
                            allLogicObjects.push({
                                id: getStoryPropertyCollectionId(storyId, LogicContainers.Soundtrack),
                                validate: (prevValidationResult: ValidationResult, getValidationResultFunction: GetValidationResultFunction): ValidationResult => {
                                    return this.doValidate(
                                        logic.story[storyId][LogicContainers.Soundtrack][MediaTypes.Audio],
                                        getValidationResultFunction,
                                        context,
                                        `${storyId}_${LogicContainers.Soundtrack}`,
                                        true
                                    );
                                }
                            });
                        }
                        if (
                            story.backgroundAssetType &&
                            logic.story[storyId] &&
                            logic.story[storyId][LogicContainers.BackgroundAsset] &&
                            logic.story[storyId][LogicContainers.BackgroundAsset][story.backgroundAssetType]
                        ) {
                            allLogicObjects.push({
                                id: getStoryPropertyCollectionId(storyId, LogicContainers.BackgroundAsset),
                                validate: (prevValidationResult: ValidationResult, getValidationResultFunction: GetValidationResultFunction): ValidationResult => {
                                    return this.doValidate(
                                        logic.story[storyId][LogicContainers.BackgroundAsset][story.backgroundAssetType],
                                        getValidationResultFunction,
                                        context,
                                        `${storyId}_${LogicContainers.BackgroundAsset}`,
                                        true
                                    );
                                }
                            });
                        }
                        if (useVideoRatioAndQualityLogic) {
                            const logicObj = StateReaderUtils.getStoryVideoRatioQualityLogicWireframe(wireFrame, storyId);
                            const ratioAndQualityLogic = logicObj && !isAnEmptyObject(logicObj) ? logicObj : createNewStoryQualityRatioLogic().serializeLogicData();
                            const storyVideoRatioQualityLogicValidationId = getStoryPropertyCollectionId(story.id, LogicEntity.VIDEO_RATIO_QUALITY_LOGIC_VALIDATION_ID);
                            allLogicObjects.push({
                                id: storyVideoRatioQualityLogicValidationId,
                                validate: (prevValidationResult: ValidationResult, getValidationResultFunction: GetValidationResultFunction): ValidationResult => {
                                    return this.doValidate(ratioAndQualityLogic, getValidationResultFunction, context, storyVideoRatioQualityLogicValidationId);
                                }
                            });
                        }
                    });
                }
                else if (logicContainerKey === LogicContainers.Analytics) {
                    Object.keys(logic.analytics).forEach((analyticsFieldKey) => {
                        let analyticsId: string = AnalyticsEntity.generateId(analyticsFieldKey);
                        let analyticsLogic = logic.analytics[analyticsFieldKey];
                        if (analyticsLogic && !analyticsLogic.loading) {
                            allLogicObjects.push({
                                id: analyticsId,
                                validate: (prevValidationResult: ValidationResult, getValidationResultFunction: GetValidationResultFunction): ValidationResult => {
                                    return this.doValidate(analyticsLogic, getValidationResultFunction, context, analyticsId);
                                }
                            });
                        }
                    });
                }
                else if (allScenesIds && allScenesIds.indexOf(logicContainerKey) !== -1) {
                    // Scene Animation
                    let sceneAnimationId = SceneAnimationEntity.generateId(logicContainerKey);
                    let sceneAnimationLogic = logic[logicContainerKey][sceneAnimationId];

                    allLogicObjects.push({
                        id: sceneAnimationId,
                        validate: (prevValidationResult: ValidationResult, getValidationResultFunction: GetValidationResultFunction): ValidationResult => {
                            return this.doValidate(sceneAnimationLogic || {}, getValidationResultFunction, context, sceneAnimationId, false, logicContainerKey);
                        }
                    });

                    // Scene Validation
                    let sceneValidationId = SceneValidationEntity.generateId(logicContainerKey);
                    let sceneValidationLogic = logic[logicContainerKey][sceneValidationId];
                    if (sceneValidationLogic) {
                        allLogicObjects.push({
                            id: sceneValidationId,
                            validate: (prevValidationResult: ValidationResult, getValidationResultFunction: GetValidationResultFunction): ValidationResult => {
                                return this.doValidate(sceneValidationLogic, getValidationResultFunction, context, sceneValidationId, false, logicContainerKey);
                            }
                        });
                    }
                    else {
                        // no logic object in scene validation is valid
                        allLogicObjects.push({
                            id: sceneValidationId,
                            validate: (): ValidationResult => {
                                return { type: this.getType(), id: sceneValidationId, issues: [] };
                            }
                        });
                    }

                    // Everything else
                    Object.keys(logic[logicContainerKey]).forEach((sceneItemLogic) => {
                        if (sceneItemLogic !== sceneAnimationId && sceneItemLogic !== sceneValidationId && sceneItemLogic !== this.generateNextSceneId(logicContainerKey)) {
                            let logicElement: LogicJSON = logic[logicContainerKey][sceneItemLogic];
                            if (logicElement.outputType === LOGIC_TYPE.Prioritized) {
                                logicElement.rules.forEach((candidate) => {
                                    let id: string = `${candidate.key}_${logicContainerKey}`;
                                    let singleCandidateLogicObject = { ...logicElement, rules: [candidate] };
                                    allLogicObjects.push({
                                        id: id, // candidate key _ sceneId
                                        validate: (prevValidationResult: ValidationResult, getValidationResultFunction: GetValidationResultFunction): ValidationResult => {
                                            return this.doValidate(singleCandidateLogicObject, getValidationResultFunction, context, id);
                                        }
                                    });
                                });
                                allLogicObjects.push({
                                    id: `${sceneItemLogic}_${logicContainerKey}`,
                                    validate: (prevValidationResult: ValidationResult, getValidationResultFunction: GetValidationResultFunction): ValidationResult => {
                                        return this.doValidate(logicElement, getValidationResultFunction, context, `${sceneItemLogic}_${logicContainerKey}`);
                                    }
                                });
                            }
                            else {
                                let id: string = `${sceneItemLogic}_${logicContainerKey}`;
                                allLogicObjects.push({
                                    id: id, //ph name _ sceneId
                                    validate: (prevValidationResult: ValidationResult, getValidationResultFunction: GetValidationResultFunction): ValidationResult => {
                                        return this.doValidate(logicElement, getValidationResultFunction, context, id, false, logicContainerKey);
                                    }
                                });
                            }
                        }
                    });
                }
            });
        }

        return allLogicObjects;
    };

    diffCollector = (previousContext: Context, currentContext: Context): EntityInstance[] => {
        let allLogicObjects: EntityInstance[] = [];

        const {
            logic: currentLogic,
            allScenesIds: currentAllScenesIds,
            wireFrame: currentWireFrame,
            allDataElements: currentAllDataElements,
            useVideoRatioAndQualityLogic
        } = this.getDataFromContext(currentContext);

        const { logic: previousLogic, allScenesIds: previousAllScenesIds, wireFrame: previousWireFrame, allDataElements: previousAllDataElements } = this.getDataFromContext(previousContext);

        const previousAndCurrentSceneIds = Array.from(new Set([...currentAllScenesIds, ...previousAllScenesIds]));

        Object.keys({ ...previousLogic, ...currentLogic }).forEach((logicContainerKey: string) => {
            // handle studio data
            if (logicContainerKey === LogicContainers.Derived) {
                const derivedEntitiesMap: AnyEntitiesByContextById = this.entityObjectsToMap(previousLogic.derived, currentLogic.derived);
                derivedEntitiesMap.forEach((derivedPair: AnyEntityContextPair, derivedId: string) => {
                    const previousDerivedObj = findDataElementById(derivedId, previousAllDataElements) || findDataElementByName(derivedId, previousAllDataElements);
                    const currentDerivedObj = findDataElementById(derivedId, currentAllDataElements) || findDataElementByName(derivedId, currentAllDataElements);
                    allLogicObjects.push({
                        id: derivedId,
                        diff: (): DiffResult =>
                            this.doDiff({
                                id: derivedId,
                                name: "",
                                previousLogic: DataElementEntity.normalizeDataElement(previousDerivedObj),
                                currentLogic: DataElementEntity.normalizeDataElement(currentDerivedObj)
                            })
                    });
                });
            }

            // handle stories
            else if (logicContainerKey === LogicContainers.Story) {
                const storiesMap: AnyEntitiesByContextById = this.entityObjectsToMap(previousLogic.story, currentLogic.story);
                storiesMap.forEach((storiesPair: AnyEntityContextPair, storyId: string) => {
                    const previousStory: Story = StateReaderUtils.getStoryById(previousWireFrame, storyId);
                    const currentStory: Story = StateReaderUtils.getStoryById(currentWireFrame, storyId);

                    allLogicObjects.push({
                        id: storyId,
                        diff: (): DiffResult =>
                            this.doDiff({
                                id: storyId,
                                previousLogic: { ...previousStory, ...storiesPair.previousEntity }, // merge all data about individual story
                                currentLogic: { ...currentStory, ...storiesPair.currentEntity },
                                name: (previousStory && previousStory.name) || currentStory.name
                            })
                    });

                    //decision points / story logic
                    const dpID = getStoryPropertyCollectionId(storyId, LogicContainers.DecisionPoint);
                    allLogicObjects.push({
                        id: dpID,
                        diff: (): DiffResult =>
                            this.doDiff({
                                id: dpID,
                                previousLogic: storiesPair.previousEntity ? storiesPair.previousEntity.decisionPoint : undefined,
                                currentLogic: storiesPair.currentEntity ? storiesPair.currentEntity.decisionPoint : undefined,
                                name: "Story logic"
                            })
                    });

                    //soundtrack
                    const soundtrackId = getStoryPropertyCollectionId(storyId, LogicContainers.Soundtrack);
                    allLogicObjects.push({
                        id: soundtrackId,
                        diff: (): DiffResult =>
                            this.doDiff({
                                id: soundtrackId,
                                previousLogic: storiesPair.previousEntity && storiesPair.previousEntity.soundtrack ? storiesPair.previousEntity.soundtrack.audio : undefined,
                                currentLogic: storiesPair.currentEntity && storiesPair.currentEntity.soundtrack ? storiesPair.currentEntity.soundtrack.audio : undefined,
                                name: "Soundtrack"
                            })
                    });

                    //background asset
                    const backgroundId = getStoryPropertyCollectionId(storyId, LogicContainers.BackgroundAsset);
                    allLogicObjects.push({
                        id: backgroundId,
                        diff: (): DiffResult =>
                            this.doDiff({
                                id: backgroundId,
                                previousLogic: storiesPair.previousEntity ? storiesPair.previousEntity.backgroundAsset : undefined,
                                currentLogic: storiesPair.currentEntity ? storiesPair.currentEntity.backgroundAsset : undefined,
                                name: "Background"
                            })
                    });

                    //ratio and quality
                    if (useVideoRatioAndQualityLogic) {
                        const storyVideoRatioQualityLogicValidationId = getStoryPropertyCollectionId(storyId, LogicEntity.VIDEO_RATIO_QUALITY_LOGIC_VALIDATION_ID);
                        allLogicObjects.push({
                            id: storyVideoRatioQualityLogicValidationId,
                            diff: (): DiffResult =>
                                this.doDiff({
                                    id: storyVideoRatioQualityLogicValidationId,
                                    previousLogic: storiesPair.previousEntity?.[LogicContainers.VideoSettings]?.[LogicContext.StoryRatioAndQuality],
                                    currentLogic: storiesPair.currentEntity?.[LogicContainers.VideoSettings]?.[LogicContext.StoryRatioAndQuality],
                                    name: "Ratio and Quality logic"
                                })
                        });
                    }
                });
            }
            else if (logicContainerKey === LogicContainers.Program) {
                const programEntitiesMap: AnyEntitiesByContextById = this.entityObjectsToMap(previousLogic[LogicContainers.Program], currentLogic[LogicContainers.Program]);
                programEntitiesMap.forEach((programLogicTypedPair: AnyEntityContextPair, programLogicType: string) => {
                    let previousProgramLogic: LogicJSON = StateReaderUtils.getAllProgramLogicItemsFromWireframeByType(previousWireFrame, programLogicType);
                    let currentProgramLogic: LogicJSON = StateReaderUtils.getAllProgramLogicItemsFromWireframeByType(currentWireFrame, programLogicType);

                    let diffId = getProgramPropertyCollectionId(programLogicType);
                    // Story selection logic
                    allLogicObjects.push({
                        id: diffId,
                        diff: (): DiffResult =>
                            this.doDiff({
                                id: diffId,
                                previousLogic: previousProgramLogic,
                                currentLogic: currentProgramLogic,
                                name: LogicEntity.convertTypeToName(programLogicType)
                            })
                    });
                });
            }

            // handle analytics
            else if (logicContainerKey === LogicContainers.Analytics) {
                const analyticsEntitiesMap: AnyEntitiesByContextById = this.entityObjectsToMap(previousLogic.analytics, currentLogic.analytics);
                analyticsEntitiesMap.forEach((analyticsPair: AnyEntityContextPair, analyticsFieldKey: string) => {
                    const analyticsId: string = AnalyticsEntity.generateId(analyticsFieldKey);
                    allLogicObjects.push({
                        id: analyticsId,
                        diff: (): DiffResult =>
                            this.doDiff({
                                id: analyticsId,
                                previousLogic: analyticsPair.previousEntity,
                                currentLogic: analyticsPair.currentEntity,
                                name: analyticsFieldKey
                            })
                    });
                });
            }

            // handle scenes
            else if (previousAndCurrentSceneIds.includes(logicContainerKey)) {
                let sceneAnimationId: string = "";
                let sceneValidationId: string = "";

                if (logicContainerKey !== LogicContainers.Master) {
                    // Scene Animation
                    sceneAnimationId = SceneAnimationEntity.generateId(logicContainerKey);
                    allLogicObjects.push({
                        id: sceneAnimationId,
                        diff: (): DiffResult =>
                            this.doDiff({
                                id: sceneAnimationId,
                                previousLogic: previousLogic[logicContainerKey] && previousLogic[logicContainerKey][sceneAnimationId],
                                currentLogic: currentLogic[logicContainerKey] && currentLogic[logicContainerKey][sceneAnimationId],
                                name: sceneAnimationId
                            })
                    });

                    // Scene Validation
                    sceneValidationId = SceneValidationEntity.generateId(logicContainerKey);
                    allLogicObjects.push({
                        id: sceneValidationId,
                        diff: (): DiffResult =>
                            this.doDiff({
                                id: sceneValidationId,
                                previousLogic: previousLogic[logicContainerKey] && previousLogic[logicContainerKey][sceneValidationId],
                                currentLogic: currentLogic[logicContainerKey] && currentLogic[logicContainerKey][sceneValidationId],
                                name: sceneValidationId
                            })
                    });
                }

                // logic objects in scene - placeholders, animation guidelines, descriptive-transcript
                const previousPlaceholderLogic: AnyEntity = { ...previousLogic[logicContainerKey] };
                delete previousPlaceholderLogic[sceneAnimationId];
                delete previousPlaceholderLogic[sceneValidationId];

                const currentPlaceholderLogic: AnyEntity = { ...currentLogic[logicContainerKey] };
                delete currentPlaceholderLogic[sceneAnimationId];
                delete currentPlaceholderLogic[sceneValidationId];

                const placeholdersLogicMap: AnyEntitiesByContextById = this.entityObjectsToMap(previousPlaceholderLogic, currentPlaceholderLogic);
                placeholdersLogicMap.forEach((logicObjPair: AnyEntityContextPair, logicId: string) => {
                    const id: string = `${logicId}_${logicContainerKey}`;
                    allLogicObjects.push({
                        id: id,
                        diff: (): DiffResult =>
                            this.doDiff({
                                id: id,
                                previousLogic: logicObjPair.previousEntity,
                                currentLogic: logicObjPair.currentEntity,
                                name: id
                            })
                    });
                });
            }
        });

        return allLogicObjects;
    };

    doValidate = (
        logicObj: LogicJSON,
        getValidationResult: GetValidationResultFunction,
        context: Context,
        id: string,
        isOptionalDefaultValueFlag?: boolean,
        containingSceneId?: string
    ): ValidationResult => {
        const type: EntityTypes = this.getType();
        let result: ValidationResult = { type, id, issues: [] };
        let dataElements: DataElement[] = StateReaderUtils.getProjectDataElements(context.state, context.projectName, context.stage, context.version);
        let stateProject: Program = StateReaderUtils.getProject(context.state, context.projectName);
        let version: number | undefined = context.version || StateReaderUtils.getLatestSnapshotNumberForStage(context.state, context.projectName, context.stage);
        let assetsArr: Asset[] = StateReaderUtils.getAssetsFromSnapshotOrDraft(stateProject, context.state.assets, context.state.snapshots, undefined, version);
        let stories = StateReaderUtils.getProjectWireframesStories(context.state, context.projectName, context.stage, context.version);
        let scenes = StateReaderUtils.getProjectWireframeScenes(context.state, context.projectName, context.stage, context.version);
        let wireframes = StateReaderUtils.getWireFrame(context.state, context.projectName, context.stage, context.version);
        let prioritizedLists = StateReaderUtils.getScenePrioritizedList(wireframes, containingSceneId);
        const assets = LogicEntity.buildAssetsForContext(assetsArr);

        const logicValidationContext: LogicValidationContext = { dataElements, assets, stories, scenes, prioritizedLists, containingSceneId, getValidationResult, isOptionalDefaultValueFlag };
        let validationObj: ValidationShape = validateInputLogic(logicObj, logicValidationContext);
        if (validationObj.status !== VALIDATION_STATUS.Valid) {
            let severity = validationObj.status === VALIDATION_STATUS.Invalid ? IssueSeverity.ERROR : IssueSeverity.WARNING;
            result.issues.push({
                code: IssueCodes.INVALID_LOGIC,
                severity: severity,
                details: { report: validationObj.report, message: validationObj.message }
            });
            result.severity = severity;
        }

        return result;
    };

    doDiff = ({ previousLogic, currentLogic, id, name }: DoDiffLogic): DiffResult => {
        const type: EntityTypes = this.getType();
        const changeType: ChangeType = diffEntities(this.normalizeLogicObject(previousLogic), this.normalizeLogicObject(currentLogic));

        return {
            type,
            id,
            name,
            changeType: changeType,
            changes: [],
            isShown: false
        };
    };

    private getDataFromContext = (context: Context) => {
        const allScenes = StateReaderUtils.getProjectWireframeScenes(context.state, context.projectName, context.stage, context.version);
        const allScenesIds = allScenes && Object.keys(allScenes);
        const logic: any = StateReaderUtils.getProjectWireframesLogic(context.state, context.projectName, context.stage, context.version);
        const wireFrame: object = StateReaderUtils.getWireFrame(context.state, context.projectName, context.stage, context.version);
        const allDataElements = StateReaderUtils.getProjectDataElements(context.state, context.projectName, context.stage, context.version);
        const programType = StateReaderUtils.getProjectType(context.state, context.projectName);
        const isCRMStoryVideoRatioQualityLogicFFEnabled = StateReaderUtils.isFeatureEnable(context.state, CRM_STORY_VIDEO_RATIO_QUALITY_LOGIC);
        const useVideoRatioAndQualityLogic = shouldUseVideoRatioAndQualityLogic(programType, isCRMStoryVideoRatioQualityLogicFFEnabled ? [CRM_STORY_VIDEO_RATIO_QUALITY_LOGIC] : []);

        return {
            allScenesIds,
            logic,
            wireFrame,
            allDataElements,
            useVideoRatioAndQualityLogic
        };
    };

    private normalizeLogicObject = (logicObject: LogicJSON): {} => {
        if (!logicObject) {
            return;
        }
        if (logicObject.loading === true && Object.keys(logicObject).length === 1) {
            // {loading: true} is the init for new placeholders.
            return;
        }
        // if logicObject is not object literal
        if (logicObject !== Object(logicObject)) {
            return logicObject;
        }

        let normalizedObj = {};

        Object.keys(logicObject).forEach((key: string) => {
            if (key !== "validation" && key !== "loading") {
                if (Array.isArray(logicObject[key])) {
                    logicObject[key].forEach((item, index) => {
                        if (!normalizedObj[key]) {
                            normalizedObj[key] = [];
                        }
                        normalizedObj[key][index] = this.normalizeLogicObject(item);
                    });
                }
                else if (logicObject[key] && typeof logicObject[key] === "object") {
                    normalizedObj[key] = this.normalizeLogicObject(logicObject[key]);
                }
                else {
                    normalizedObj[key] = logicObject[key];
                }
            }
        });

        return normalizedObj;
    };

    private generateNextSceneId = (logicContainerKey: string): string => {
        return logicContainerKey + "_nextScene";
    };
}
