import { stableSort } from "../../components/legacyCommon/utils";
import StateReaderUtils from "./StateReaderUtils";
import { DataElementContentTypes } from "../../../common/types/dataElement";
import { defaultNarratorId } from "../../../common/commonConst";
import { ASSET_TYPES } from "../vlx/consts";
import AssetUtils from "./assetUtils";
import { DATA_ELEMENT_SPECIAL_VALUE_PREFIX } from "../../components/legacyCommon/Consts";
import { PRIORITIZED_LIST_VALUE_SET_SOURCES } from "../projects/projectWireframes/projectNarrations/PrioritizedListValueSetAdapter";
import md5 from "md5";
import { findDataElementById } from "../DataElements/DataElementsManager";
import type { RecordingAsset } from "../../../common/types/asset";
import { Asset } from "../../../common/types/asset";
import type { ValueSetValue } from "../../../common/types/logic";
import type { Dimension, DimensionList, Variation, VariationList } from "../projects/projectWireframes/projectNarrations/NarrationEntities";
import type { Scene } from "../../../common/types/scene";
import { Narration } from "../../../common/types/narration";
import type { NarrationPartDataData } from "../dtaas/dtaasServices";

const styles = {
    headerDark: {
        fill: {
            fgColor: {
                rgb: "FFCFE2F3"
            }
        },
        font: {
            bold: true
        },
        alignment: {
            vertical: "center",
            horizontal: "center"
        }
    },
    cellGreen: {
        fill: {
            fgColor: {
                rgb: "FF38761d"
            }
        },
        font: {
            size: 11
        }
    },
    cellRed: {
        fill: {
            fgColor: {
                rgb: "FFa61c00"
            }
        }
    }
};

type NarrationSummary = { name: string, sceneId: string, scenePartId: string, id: string };

function getRecordingAssetName(narrationId, narratorId, text) {
    return narrationId + "~" + narratorId + "~" + md5(text);
}

function getRecordingAssetNameByKey(narrationId: string, narratorId: string, key: string) {
    return `${narrationId}~${narratorId}~${key}`;
}

function getRecordingNarrationId(assetName) : string {
    if (assetName) {
        const indexOf = assetName.indexOf("~");
        return indexOf > 0 && indexOf + 1 < assetName.length ? assetName.substring(0, indexOf) : undefined;
    }
    return undefined;
}

// Get the specifications for the Excel columns for each narration
function getColumnSpecifications(wireframes, storyId, narrationId) {
    let dimensions = StateReaderUtils.getNarrationTableDimensions(wireframes, narrationId);
    let narrator = StateReaderUtils.getNarrator(wireframes, storyId);

    let getSpecification = (columnName, width) => ({
        displayName: columnName,
        headerStyle: styles.headerDark,
        cellStyle: function(value, row) {
            return styles.cellGreen;
        },
        width: width
    });

    let dataElementsColumns = dimensions.reduce((specifications, dimension) => {
        specifications[dimension.name] = getSpecification("DataElement_" + dimension.name, 220);
        return specifications;
    }, {});
    dataElementsColumns["approved_narration"] = getSpecification("Approved Narration", 260);
    dataElementsColumns["comment"] = getSpecification("SSky Comments", 120);
    dataElementsColumns["dynamic_ost"] = getSpecification("Dynamic OST", 120);
    dataElementsColumns["narrator_mock"] = getSpecification("Narrator\nMock", 70);
    if (narrator) {
        dataElementsColumns["narrator_" + narrator] = getSpecification("Narrator\n" + narrator, 70);
    }
    dataElementsColumns["audio_file"] = getSpecification("Audio\nFile", 70);
    return dataElementsColumns;
}

// Get the data for the Excel rows for each narration
function getData(wireframes, assets, program, storyId, narrationId) {
    let dimensions = StateReaderUtils.getNarrationTableDimensions(wireframes, narrationId);
    let variations = StateReaderUtils.getNarrationTableVariations(wireframes, narrationId);
    let narrator = StateReaderUtils.getNarrator(wireframes, storyId);

    // Collect the data from all the variations
    let data = [];
    let i = 0;
    let variationKeys = {};
    variations.forEach((variation, variationKey) => {
        if (!variation.variationData.muted) {
            variationKeys[variationKey] = 0; // This is used to get audio files
            let first = true;
            variation.points.forEach((point) => {
                data[i] = {};
                point.forEach((valueId, coordinate) => {
                    let dimensionName = dimensions.get(coordinate).name;
                    let value = dimensions.get(coordinate).valueSet.find((valueSetValue) => valueSetValue.id === valueId);
                    data[i][dimensionName] = value.dn;
                });
                data[i].comment = variation.variationData.comment;
                data[i].approved_narration = variation.variationData.text;
                data[i].variationKey = variationKey; // not exported, because it isn't defined in specifications obj
                data[i].narrator_mock = first ? "TBR" : "Yes";

                if (narrator) {
                    data[i]["narrator_" + narrator] = first ? "TBR" : "Yes";
                }

                // Add audio_file which is the filename of the corresponding asset
                let { accountId, projectName } = program;
                let assetName = getRecordingAssetName(narrationId, defaultNarratorId, variation.variationData.text);
                let assetId = StateReaderUtils.getAssetUniqueId(accountId, projectName, ASSET_TYPES.recording, assetName);
                let asset = assets.byId[assetId];
                if (asset) {
                    data[i].audio_file = asset.filename;
                }

                i++;
                first = false;
            });
        }
    });

    // Sort the data primarily by the first column, then the second column, etc.
    // an empty cell will be first
    data.sort((row1, row2) => {
        for (let i = 0; i < dimensions.size; ++i) {
            let indexMap = dimensions.get(i).indexMap;
            let dimensionName = dimensions.get(i).name;
            let result = indexMap.get(row1[dimensionName]) - indexMap.get(row2[dimensionName]);
            if (result !== 0 || i === dimensions.size - 1) {
                return result;
            }
        }
    });

    // Cells with value DATA_ELEMENT_VALUE_OTHER_OR_MISSING should be normalized to null
    data.forEach((row) => {
        dimensions.forEach((dimension) => {
            if (row[dimension.name].startsWith(DATA_ELEMENT_SPECIAL_VALUE_PREFIX)) {
                row[dimension.name] = null;
            }
            else if (dimension.type === DataElementContentTypes.Number) {
                row[dimension.name] = Number(row[dimension.name]).toString();
            }
        });
    });

    // Sort the (already sorted) data by the audio file number - this is the main sort.
    stableSort(data, (row1, row2) => {
        return row1.audio_file - row2.audio_file;
    });

    return data;
}

let getAllNarrations = function(scenes: Scene[]): NarrationSummary[] {
    // Get all narrations from all scene parts from all scenes.
    return scenes.reduce((allNarrations, scene) => {
        let count = 0;
        let sceneNarrations = scene.sceneParts.reduce((sceneNarrations, scenePart) => {
            let narrations =
                scenePart.NarrationParts &&
                scenePart.NarrationParts.map((nar) => {
                    let letter = String.fromCharCode("A".charCodeAt(0) + count++);
                    return { name: scene.name + "-" + letter, sceneId: scene.id, scenePartId: scenePart.scenePart, id: nar.id };
                });
            if (narrations) {
                sceneNarrations = sceneNarrations.concat(narrations);
            }
            return sceneNarrations;
        }, []);
        allNarrations = allNarrations.concat(sceneNarrations);
        return allNarrations;
    }, []);
};

function getDtaasSchemaInputs(wireframes, narrationId) {
    let dimensions = StateReaderUtils.getNarrationTableDimensions(wireframes, narrationId);
    return dimensions.map((dim) => ({
        id: dim.id,
        displayName: dim.name,
        name: dim.id,
        type: dim.type
    }));
}

function cachedGetRecording(recordings: RecordingAsset[]) {
    type Cache = { [recordingName: string]: RecordingAsset };

    let cache: Cache = recordings.reduce((acc: Cache, asset: RecordingAsset) => {
        acc[asset.name] = asset;
        return acc;
    }, {});

    return (narrationId: string, narratorId: string, text: string) => {
        if (!text || !narrationId || !narratorId) {
            return undefined;
        }
        let recordingAssetName = getRecordingAssetName(narrationId, narratorId, text);

        let recording: RecordingAsset = cache[recordingAssetName];
        return recording && AssetUtils.getLocation(recording, false);
    };
}

function getDataForDtaas(wireframes: object, narrationId: string, narratorId: string, recordings: RecordingAsset[]) {
    let dimensions: DimensionList = StateReaderUtils.getNarrationTableDimensions(wireframes, narrationId);
    let variations: VariationList = StateReaderUtils.getNarrationTableVariations(wireframes, narrationId);
    let dataElements = StateReaderUtils.getProjectDataElementsFromWireframes(wireframes);

    const getRecording = cachedGetRecording(recordings);
    const uniqueCoordinateValueSetFetchId = (coordinate: number, valueId: string) => coordinate + "/" + valueId;

    // We create a map from dimensions coordinate + valueSet id to value. It is created once to avoid costly find() inside the next forEach()
    const valueFetchMap: { [key: string]: ValueSetValue } = dimensions.reduce((acc, dimension: Dimension, coordinate: number) => {
        dimension.valueSet.forEach((valueSetValue: ValueSetValue) => {
            acc[uniqueCoordinateValueSetFetchId(coordinate, valueSetValue.id)] = valueSetValue;
        });
        return acc;
    }, {});

    // Collect the data from all the variations
    let data = [];
    let i = 0;
    let variationKeys = {};
    variations.forEach((variation: Variation, variationKey: string) => {
        variationKeys[variationKey] = 0; // This is used to get audio files
        variation.points.forEach((point) => {
            data[i] = {};

            point.forEach((valueId: string, coordinate: number) => {
                let dimensionId = dimensions.get(coordinate).id;
                // When dimension is candidate name, we use the ID, since this is what VLX resolves to
                let isCandidateName = dimensionId.startsWith(PRIORITIZED_LIST_VALUE_SET_SOURCES.CandidateNames);
                let value: ValueSetValue = valueFetchMap[uniqueCoordinateValueSetFetchId(coordinate, valueId)];
                let isSpecialValue = value.dn.startsWith(DATA_ELEMENT_SPECIAL_VALUE_PREFIX);
                data[i][dimensionId] = isCandidateName && !isSpecialValue ? value.id : value.dn;
            });
            data[i].comment = variation.variationData.muted ? null : variation.variationData.text;
            data[i].value = getRecording(narrationId, narratorId, data[i].comment);

            // Check for overriding CDE id, locate data element and save the overriding CDE name
            if (!variation.variationData.muted && variation.variationData.overrideId) {
                const overrideCDE = findDataElementById(variation.variationData.overrideId, dataElements);
                if (overrideCDE && overrideCDE.displayName) {
                    data[i].overrideCDEName = overrideCDE.displayName;
                }
            }

            i++;
        });
    });

    // Cells with value DATA_ELEMENT_VALUE_OTHER_OR_MISSING should be normalized to null
    // Cells with string type should be lower-cased
    // Cells with number type should be converted to String
    data.forEach((row) => {
        dimensions.forEach((dimension) => {
            if (row[dimension.id].startsWith(DATA_ELEMENT_SPECIAL_VALUE_PREFIX)) {
                row[dimension.id] = null;
            }
            else if (dimension.type === DataElementContentTypes.String) {
                row[dimension.id] = row[dimension.id].toLowerCase();
            }
            else if (dimension.type === DataElementContentTypes.Number) {
                row[dimension.id] = Number(row[dimension.id]).toString();
            }
        });
    });

    return data;
}

function createDtaasObj(wireframes, narratorId, recordings): NarrationPartDataData[] {
    let scenes: Scene[] = Object.values(wireframes.scenes);
    let narrations = getAllNarrations(scenes);

    // Create the dtaas object
    return narrations.map((narration) => {
        return {
            id: narration.id,
            sceneId: narration.sceneId,
            scenePartId: narration.scenePartId,
            scheme: {
                inputs: getDtaasSchemaInputs(wireframes, narration.id),
                outputs: [
                    {
                        name: "comment",
                        id: "comment",
                        type: "string"
                    },
                    {
                        name: "value",
                        id: "value",
                        type: "string"
                    }
                ]
            },
            data: getDataForDtaas(wireframes, narration.id, narratorId, recordings)
        };
    });
}

// This function sends to the server an object that describes an excel file, which is the input to the npm package node-excel-export.
// This package requires an access to 'fs', and therefore cannot be imported to the client, but only to the server.
function createExcelObj(wireframes, scenes, assets, program, storyId) {
    let narrations = getAllNarrations(scenes);

    //Excel allows a max of 31 chars in the sheet name
    const MAX_CHAR = 31;
    function cutSceneName(narrationName: string) {
        const narrationNameArr = narrationName.split("-"); //might be "-" in scene name as well
        let narrationPartName = narrationNameArr[narrationNameArr.length - 1];
        let sceneName;
        if (narrationNameArr.length > 2) {
            sceneName = narrationNameArr.slice(0, -1).join("-");
        }
        else {
            sceneName = narrationNameArr[0];
        }
        const endIndex = MAX_CHAR - 1 - narrationPartName.length - 1; //31 - "-" - "A" -1 for the index (starting from 0)
        const shorterSceneName = sceneName.slice(0, endIndex);
        return shorterSceneName + "-" + narrationPartName;
    }

    // Create the excel definition object.
    return narrations.map((narration) => {
        return {
            name: narration.name.length > MAX_CHAR ? cutSceneName(narration.name) : narration.name,
            merges: [],
            specification: getColumnSpecifications(wireframes, storyId, narration.id),
            data: getData(wireframes, assets, program, storyId, narration.id)
        };
    });
}

export { getRecordingAssetName, getRecordingAssetNameByKey, getRecordingNarrationId, createDtaasObj, createExcelObj };
