import type { IValidationTable } from "./validationTable";
import ValidationTable from "./validationTable";
import type { IValidationTableRecord } from "./validationTableRecord";
import ValidationTableRecord from "./validationTableRecord";
import type { Context, EntityInstance, EntityTypes, IEntity } from "../entities/definitions";
import { RecordStatus } from "../entities/definitions";

export type GetValidationResultFunction = (type: EntityTypes, id: string) => ValidationResult;
export type ValidateFunction = (prevValidationResult: ValidationResult, getValidationResult: GetValidationResultFunction) => ValidationResult;

export enum IssueCodes {
    MISSING = "MISSING",
    CYCLIC_DEPENDENCY = "CYCLIC_DEPENDENCY",

    DATA_ELEMENT_VALUE_SET = "DATA_ELEMENT_VALUE_SET",

    DERIVED_DATA_ELEMENT_CYCLIC_DEPENDENCY = "DERIVED_DATA_ELEMENT_CYCLIC_DEPENDENCY",

    DATA_TABLE_SCHEME_ERROR = "DATA_TABLE_SCHEME_ERROR",

    NARRATION_ORPHANED_DIMENSIONS = "NARRATION_ORPHANED_DIMENSIONS",
    NARRATION_ORPHANED_VARIATIONS = "NARRATION_ORPHANED_VARIATIONS",
    NARRATION_EMPTY_LINES = "NARRATION_EMPTY_LINES",
    NARRATION_DATA_ELEMENTS_ERROR = "NARRATION_DATA_ELEMENTS_ERROR",
    NARRATION_DERIVED_DATA_ELEMENTS_ERROR = "NARRATION_DERIVED_DATA_ELEMENTS_ERROR",
    NARRATION_CREATIVE_OVERRIDE_ERROR = "NARRATION_CREATIVE_OVERRIDE_ERROR",

    PRIORITIZED_LIST_EMPTY_CANDIDATE_NAME = "PRIORITIZED_LIST_EMPTY_CANDIDATE_NAME",
    PRIORITIZED_LIST_INVALID_CANDIDATE_NAME = "PRIORITIZED_LIST_INVALID_CANDIDATE_NAME",
    PRIORITIZED_LIST_DUPLICATE_CANDIDATE_NAME = "PRIORITIZED_LIST_DUPLICATE_CANDIDATE_NAME",
    PRIORITIZED_LIST_CANDIDATES_ERROR = "PRIORITIZED_LIST_CANDIDATES_ERROR",

    INVALID_LOGIC = "INVALID_LOGIC",

    ANALYTICS_LOGIC_ERROR = "ANALYTICS_LOGIC_ERROR",
    ANALYTICS_NAME_ERROR = "ANALYTICS_NAME_ERROR",
    DERIVED_LOGIC_ERROR = "DERIVED_LOGIC_ERROR",
    GROUP_LOGIC_ERROR = "GROUP_LOGIC_ERROR",
    PARAMETER_LOGIC_ERROR = "PARAMETER_LOGIC_ERROR",
    PARAMETER_LOGIC_MISSING = "PARAMETER_LOGIC_MISSING",
    PLACEHOLDER_LOGIC_ERROR = "PLACEHOLDER_LOGIC_ERROR",
    PLACEHOLDER_LOGIC_MISSING = "PLACEHOLDER_LOGIC_MISSING",
    PRIORITIZED_LIST_LOGIC_ERROR = "PRIORITIZED_LIST_LOGIC_ERROR",
    SCENE_ANIMATION_LOGIC_ERROR = "SCENE_ANIMATION_LOGIC_ERROR",
    SCENE_VALIDATION_LOGIC_ERROR = "SCENE_VALIDATION_LOGIC_ERROR",
    STORY_LOGIC_ERROR = "STORY_LOGIC_ERROR",
    STORY_SOUNDTRACK_LOGIC_ERROR = "STORY_SOUNDTRACK_LOGIC_ERROR",
    STORY_BACKGROUND_ASSET_LOGIC_ERROR = "STORY_BACKGROUND_ASSET_LOGIC_ERROR",
    STORY_VIDEO_RATIO_QUALITY_LOGIC_ERROR = "STORY_VIDEO_RATIO_QUALITY_LOGIC_ERROR",
    STORY_VIDEO_DURATION_OPTIONS_ERROR = "STORY_VIDEO_DURATION_OPTIONS_ERROR",
    STORY_VIDEO_NUM_OF_PRODUCT_ERROR = "STORY_VIDEO_NUM_OF_PRODUCT_ERROR",
    STORY_VIDEO_RATIO_QUALITY_OPTIONS_ERROR = "STORY_VIDEO_RATIO_QUALITY_OPTIONS_ERROR",

    PROGRAM_SETTINGS_ERROR = "PROGRAM_SETTINGS_ERROR",
    PROGRAM_ANALYTICS_ERROR = "PROGRAM_ANALYTICS_ERROR",
    PROGRAM_SCENES_ERROR = "PROGRAM_SCENES_ERROR",
    PROGRAM_STORY_ERROR = "PROGRAM_STORY_ERROR",
    PROGRAM_STORY_SELECTION_LOGIC_ERROR = "PROGRAM_STORY_SELECTION_LOGIC_ERROR",

    SCENE_PART_ERROR = "SCENE_PART_ERROR",
    SCENE_PART_PLACEHOLDER_ERROR = "SCENE_PART_PLACEHOLDER_ERROR",
    SCENE_PART_NARRATION_ERROR = "SCENE_PART_NARRATION_ERROR",
    SCENE_PART_PARAMETER_ERROR = "SCENE_PART_PARAMETER_ERROR",
    SCENE_PART_GROUP_ERROR = "SCENE_PART_GROUP_ERROR",
    SCENE_PART_PRIORITIZED_LIST_ERROR = "SCENE_PART_PRIORITIZED_LIST_ERROR",
    SCENE_PART_RECORDING_ERROR = "SCENE_PART_RECORDING_ERROR",

    STORY_SCENE_ERROR = "STORY_SCENE_ERROR",

    DERIVED_LOGIC_MISSING = "DERIVED_LOGIC_MISSING",

    RECORDING_IS_PENDING = "RECORDING_IS_PENDING",
    RECORDING_IS_REJECTED = "RECORDING_IS_REJECTED",
    RECORDING_IS_PENDING_APPROVAL = "RECORDING_IS_PENDING_APPROVAL"
}

export enum IssueSeverity {
    ERROR = "ERROR",
    WARNING = "WARNING"
}

export type ValidationResult = {
    type: EntityTypes;
    id: string;
    issues: Issue[];
    severity?: IssueSeverity;
    name?: string;
};

export type Issue = {
    code: IssueCodes;
    severity: IssueSeverity;
    details: any;
    children?: ValidationResult[];
};

export default class ValidationManager {
    private readonly entities: IEntity[];

    constructor(entities: IEntity[]) {
        this.entities = entities;
    }

    public init = (prevTable: IValidationTable, context: Context): IValidationTable => {
        let table = new ValidationTable();

        //Populate table with instances
        this.entities &&
            this.entities.forEach((entity: IEntity) => {
                let entityInstances: EntityInstance[] = entity.collector({ context });
                entityInstances &&
                    entityInstances.forEach((entityInstance: EntityInstance) => {
                        const tableKey = ValidationTable.getTableKey(entity.getType(), entityInstance.id);
                        const prevRecord = prevTable.getById(tableKey);
                        const record = new ValidationTableRecord(prevRecord && prevRecord.prevValidationResult, entityInstance.validate);
                        table.putById(tableKey, record);
                    });
            });

        //Fill table with validation results
        let allRecords = table.getAllRecords();
        if (allRecords) {
            allRecords.forEach((record: IValidationTableRecord) => {
                if (!record.validationResult) {
                    record.validationResult = this.fulfillRecord(table, record);
                }
            });
        }

        return table;
    };

    private fulfillRecord = (table: IValidationTable, record: IValidationTableRecord): ValidationResult => {
        record.status = RecordStatus.CALCULATING;
        let result: ValidationResult = record.validate(record.prevValidationResult, (type: EntityTypes, id: string) => {
            return this.resolveValidationResultById(table, type, id);
        });
        record.status = RecordStatus.DONE;
        return result;
    };

    private resolveValidationResultById = (table: IValidationTable, type: EntityTypes, id: string): ValidationResult => {
        let record: IValidationTableRecord = table.getById(ValidationTable.getTableKey(type, id));

        if (!record) {
            return {
                type,
                id,
                issues: [
                    {
                        code: IssueCodes.MISSING,
                        severity: IssueSeverity.ERROR,
                        details: {}
                    }
                ],
                severity: IssueSeverity.ERROR
            };
        }

        let validationResult: ValidationResult = record.validationResult;

        if (record.status === RecordStatus.CALCULATING) {
            validationResult = {
                type,
                id,
                issues: [
                    {
                        code: IssueCodes.CYCLIC_DEPENDENCY,
                        severity: IssueSeverity.ERROR,
                        details: {}
                    }
                ],
                severity: IssueSeverity.ERROR
            };
            return validationResult;
        }

        if (!validationResult) {
            validationResult = this.fulfillRecord(table, record);
            record.validationResult = validationResult;
        }

        return validationResult;
    };

    public readValidationResultById = (table: IValidationTable, type: EntityTypes, id: string) => {
        let record: IValidationTableRecord = table.getById(ValidationTable.getTableKey(type, id));
        if (record) {
            return record.validationResult;
        }
    };

    // Finds highest severity in array. Assumes only ERROR and WARNING are possible
    public static getHighestSeverity(severities: IssueSeverity[]): IssueSeverity {
        for (const severity of severities) {
            if (severity === IssueSeverity.ERROR) {
                return IssueSeverity.ERROR;
            }
        }
        return IssueSeverity.WARNING;
    }
}
