import {
    BuilderTypePaths,
    CreativeTypePaths,
    EditorTypePaths,
    FrameworkTypePaths,
    legacyAccountAccountIdProgramProgramIdNavigationItemTypePath,
    legacyAccountIdDashboardsAccountDashboardPath,
    legacyAccountIdDashboardsPath,
    legacyAccountIdPath,
    legacyAllProgramsOverviewPath,
    legacyBuilderBuilderTypePath,
    legacyEditorSteps,
    legacyPreviewPath,
    legacyProgramOverviewPath,
    legacyRoot,
    legacyRootPath,
    newNavAccountPath,
    newNavEditorPath,
    newNavVideoPagePath,
    newNavYourVideosPath,
    programIdForAllPrograms,
    SettingsTypePaths,
    StudioMainCategoriesPaths,
    newNavEditorRootPath, legacyProgramBasePath
} from "@sundaysky/smartvideo-hub-urls";
import memoizeOne from "memoize-one";
import PropTypes from "prop-types";
import { matchPath } from "react-router-dom";
import { getNavigationItemsByUserRoles, roles } from "../../../common/rolesPermissionsConst";

import type { Stages } from "../../../common/types/lifecycle";
import { getVersionFromPathname } from "../../components/main/mainContainerUtils";

import { isNewNav } from "../../utils/newNavUtils";


export interface HubRouterPathParams {
    projectName?: string;
    programName?: string;
    accountId?: string;
    version?: string;
    stage?: string;
    navigationItem?: StudioMainCategoriesPaths;
    navigationItemType?: BuilderTypePaths | SettingsTypePaths | FrameworkTypePaths | CreativeTypePaths;
    builderType?: BuilderTypePaths;
    settingsType?: SettingsTypePaths;
    frameworkType?: FrameworkTypePaths;
    creativeTab?: CreativeTypePaths;
    editorType?: EditorTypePaths;
    draftId?: string;
    programId?: string;
    videoId?: string;
    reviewId?: string;
    reviewVideoId?: string;
}

export interface LocationState {
    from: "builder"
}

export interface PathsObject {
    path: string;
    isExact?: boolean;
    key?: string;
}

export const RouterPropType: object = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

export const removeLastSlash = (str: string): string => {
    return str.replace(/\/$/, "");
};

export const setProgramAndNavigationItemToUrl = (accountId: string, programId: string, navigationItem: StudioMainCategoriesPaths): string => {
    return legacyAccountAccountIdProgramProgramIdNavigationItemTypePath({
        accountId,
        programId,
        // @ts-ignore
        navigationItemType: navigationItem
    });
};

export const getEditorUrlPartWithQueryParams = (industry: string, storyName: string): string => {
    // 'industry' and 'storyName' can be empty strings
    return industry && storyName
        ? `${encodeURI(`${StudioMainCategoriesPaths.Editor}?industry=${industry}&useCase=${storyName}`)}`
        : StudioMainCategoriesPaths.Editor;
};

export const updateEditorPath = (accountId: string, programId: string, step: EditorTypePaths): string => {
    return isNewNav
        ? newNavEditorPath({ accountId, videoId: programId })
        : legacyEditorSteps({ accountId, programId, navigationItem: StudioMainCategoriesPaths.Editor, editorType: step });
};

export const navigateToEditorPath = (accountId: string, programId: string, step: EditorTypePaths): string => {
    return isNewNav
        ? newNavEditorRootPath({ accountId, videoId: programId, editorType: step as "content" })
        : legacyEditorSteps({ accountId, programId, navigationItem: StudioMainCategoriesPaths.Editor, editorType: step });
};

export const navigateToEditorOverviewUrl = (accountId: string, programId: string): string => {
    return isNewNav
        ? newNavVideoPagePath({ accountId, videoId: programId })
        : legacyProgramOverviewPath({ accountId, programId, navigationItemType: StudioMainCategoriesPaths.Overview });
};

export const getEditorUrl = (accountId: string, programId: string, industry: string, storyName: string): string => {
    return legacyAccountAccountIdProgramProgramIdNavigationItemTypePath({
        accountId,
        programId,
        navigationItemType: StudioMainCategoriesPaths.Editor
    }) + (
        industry && storyName
            ? `${encodeURI(`?industry=${industry}&useCase=${storyName}`)}`
            : ""
    );
};

export const addVersionToUrl = (url: string, version: string, isItemExist?: boolean): string => {
    return setToUrl(url, 7, 2, "version/" + version, isItemExist);
};

export const removeVersionFromUrl = (url: string): string => {
    return setToUrl(url, 7, 2, undefined, true);
};

const legacyBuilderVersionPath = legacyBuilderBuilderTypePath.slash("version/:versionNumber");
export const addBuilderTypeToUrl = (accountId: string, programId: string, storiesVersion: number): string => {
    const builderType = storiesVersion
        ? BuilderTypePaths.Story
        : BuilderTypePaths.Scene;
    const version = getVersionFromPathname(window.location.pathname);

    return version
        ? legacyBuilderVersionPath({
            accountId,
            programId,
            navigationItemType: StudioMainCategoriesPaths.Builder,
            builderType,
            versionNumber: version
        })
        : legacyBuilderBuilderTypePath({
            accountId,
            programId,
            navigationItemType: StudioMainCategoriesPaths.Builder,
            builderType
        });
};

export const getHomePageByUserRoles = (userRoles: string[], accountId: string) => {
    if (userRoles) {
        if (isSharedFrameworkViewerUser(userRoles)) {
            return legacyRootPath.slash(`account/${accountId}/notfound`)();
        }
        if (userRoles.length === 1 && userRoles.includes(roles.animator)) {
            return legacyPreviewPath();
        }
        if (accountId) {
            return getUserHomePageUrl(accountId);
        }
    }
    return legacyRootPath();
};

export const getUserHomePageUrl = (accountId: string): string => {
    return isNewNav
        ? newNavYourVideosPath({ accountId })
        : legacyAllProgramsOverviewPath({ accountId });
};

export const getStoryBuilderPageUrl = (accountId: string, projectName: string, stage?: Stages, version?: string): string => {
    let url = legacyRootPath.slash(
        `account/${accountId}/program/${projectName}/${StudioMainCategoriesPaths.Builder}/${BuilderTypePaths.Story}`
    )();
    if (stage) {
        url += "/stage/" + stage;
    }
    else if (version) {
        url += "/version/" + version;
    }
    return url;
};

const startIndex = (legacyRoot as string) === "/"
    ? 0
    : (legacyRoot as string).split("/").length - 1;

const setToUrl = (url: string, index: number, indexesToDelete: number, item: string, isExists: boolean): string => {
    const adjustedIndex = startIndex + index;
    let urlArray: string[] = url.split("/");
    item ? urlArray.splice(adjustedIndex, isExists ? indexesToDelete : 0, item) : urlArray.splice(adjustedIndex, isExists ? indexesToDelete : 0);
    return urlArray.join("/");
};

export const dashboardsPaths = [
    legacyRootPath.slash("dashboards").pattern,
    legacyRootPath.slash(":accountId/dashboards").pattern
];

export const dashboardPaths: string[] = [
    legacyRootPath.pattern,
    legacyAccountIdPath.pattern
];

// new UI URLs:

export const mainContainerPathByUserRoles = memoizeOne((userRoles: roles[]) => {
    if (isSharedFrameworkViewerUser(userRoles)) {
        return [{ path: sharedFrameworkViewerPath, isExact: true }];
    }
    return [
        {
            path: legacyRootPath.slash(`account/:accountId/program/:programName(${programIdForAllPrograms})/:navigationItem(${StudioMainCategoriesPaths.Overview})`).pattern,
            isExact: true
        },
        {
            path: legacyRootPath.slash(`account/:accountId/program/:programName/:navigationItem${getEnumStringForPath(getNavigationItemsByUserRoles(userRoles))}`).pattern,
            isExact: false
        }
    ];
});

const getEnumStringForPath = (urlPathParameterOptions: string[] | Array<StudioMainCategoriesPaths>): string => {
    let enumString = "";
    urlPathParameterOptions.forEach((urlPathParameterOption: StudioMainCategoriesPaths, index: number) => {
        if (index < urlPathParameterOptions.length - 1) {
            enumString = enumString + urlPathParameterOption + "|";
        }
        else {
            enumString = enumString + urlPathParameterOption;
        }
    });
    return `(${enumString})`;
};

export const studioPath = legacyRootPath.slash("account/:accountId/program/:programName/:navigationItem").pattern;
export const studioVersionBasePath = legacyRootPath.slash("account/:accountId/program/:programName/:navigationItem/:navigationItemType/version/:version").pattern;
export const studioStageBasePath = legacyRootPath.slash("account/:accountId/program/:programName/:navigationItem/:navigationItemType/stage/:stage").pattern;
export const studioBasePath = legacyRootPath.slash(
    `account/:accountId/program/:programName/:navigationItem${getEnumStringForPath(Object.values(StudioMainCategoriesPaths))}/:navigationItemType`
).pattern;
export const buildersBasePath = legacyRootPath.slash(`account/:accountId/program/:programName/:navigationItem(${StudioMainCategoriesPaths.Builder})`).pattern;

export const builderPaths = (version: string, stage: string): PathsObject[] => [
    {
        path: legacyRootPath.slash(`account/:accountId/program/:programName/:navigationItem(${StudioMainCategoriesPaths.Builder})/version/:version`).pattern,
        key: version
    },
    {
        path: legacyRootPath.slash(`account/:accountId/program/:programName/:navigationItem(${StudioMainCategoriesPaths.Builder})/stage/:stage`).pattern,
        key: stage
    },
    {
        path: legacyRootPath.slash(
            `account/:accountId/program/:programName/:navigationItem(${StudioMainCategoriesPaths.Builder})/:builderType(${
                BuilderTypePaths.Scene
            }|${
                BuilderTypePaths.Story
            }|${
                BuilderTypePaths.Narrations
            }|${
                BuilderTypePaths.VersionManager
            })`
        ).pattern,
        key: "draft"
    },
    {
        path: legacyRootPath.slash(
            `account/:accountId/program/:programName/:navigationItem(${StudioMainCategoriesPaths.Builder})/:builderType(${
                BuilderTypePaths.Scene
            }|${
                BuilderTypePaths.Story
            }|${
                BuilderTypePaths.Narrations
            }|${
                BuilderTypePaths.VersionManager
            })/stage/:stage`
        ).pattern,
        key: stage
    },
    {
        path: legacyRootPath.slash(
            `account/:accountId/program/:programName/:navigationItem(${StudioMainCategoriesPaths.Builder})/:builderType(${
                BuilderTypePaths.Scene
            }|${
                BuilderTypePaths.Story
            }|${
                BuilderTypePaths.Narrations
            }|${
                BuilderTypePaths.VersionManager
            })/version/:version`
        ).pattern,
        key: version
    }
];

export const creativePaths = [
    legacyRootPath.slash(`account/:accountId/program/:programName/:navigationItem(${StudioMainCategoriesPaths.Creative})`).pattern,
    legacyRootPath.slash(
        `account/:accountId/program/:programName/:navigationItem(${StudioMainCategoriesPaths.Creative})/:creativeTab(${CreativeTypePaths.CreativeTable}|${CreativeTypePaths.CreativeVersionManager})`
    ).pattern
];

export const settingsPathsForOwnedMedia = [
    legacyRootPath.slash(`account/:accountId/program/:programName/:navigationItem(${StudioMainCategoriesPaths.Settings})`).pattern,
    legacyRootPath.slash(
        `account/:accountId/program/:programName/:navigationItem(${StudioMainCategoriesPaths.Settings})/:settingsType(${
            SettingsTypePaths.AnalyticsSettings
        }|${
            SettingsTypePaths.GeneralSettings
        }|${
            SettingsTypePaths.StoryTemplateSettings
        })`
    ).pattern
];

export const settingsPathsForPaidMedia = [
    legacyRootPath.slash(`account/:accountId/program/:programName/:navigationItem(${StudioMainCategoriesPaths.Settings})`).pattern,
    legacyRootPath.slash(
        `account/:accountId/program/:programName/:navigationItem(${StudioMainCategoriesPaths.Settings})/:settingsType(${SettingsTypePaths.GeneralSettings}|${SettingsTypePaths.StoryTemplateSettings})`
    ).pattern
];

// region Framework
export const frameworkLegacyPaths = {
    Base: legacyRootPath.slash(`account/:accountId/program/:programName/:navigationItem(${StudioMainCategoriesPaths.Framework})`).pattern,
    Canvas: legacyRootPath.slash(`account/:accountId/program/:programName/:navigationItem(${StudioMainCategoriesPaths.Framework})/:frameworkType(${FrameworkTypePaths.LegacyCanvas})`).pattern,
    Overview: legacyRootPath.slash(`account/:accountId/program/:programName/:navigationItem(${StudioMainCategoriesPaths.Framework})/:frameworkType(${FrameworkTypePaths.LegacyOverview})`).pattern,
    Strategy: legacyRootPath.slash(`account/:accountId/program/:programName/:navigationItem(${StudioMainCategoriesPaths.Framework})/:frameworkType(${FrameworkTypePaths.Strategy})`).pattern,
    StoryMap: legacyRootPath.slash(`account/:accountId/program/:programName/:navigationItem(${StudioMainCategoriesPaths.Framework})/:frameworkType(${FrameworkTypePaths.StoryMap})`).pattern
};

export const getFrameworkTab = (
    tabId: FrameworkTypePaths,
    accountId: string,
    programName: string
) => legacyRootPath.slash(
    `account/${accountId}/program/${programName}/${StudioMainCategoriesPaths.Framework}/${tabId}`
).pattern;

const sharedFrameworkViewerPath = legacyRootPath.slash(
    `account/:accountId/program/:programName/:navigationItem(${StudioMainCategoriesPaths.Framework})/:frameworkType(${
        FrameworkTypePaths.Strategy
    }|${
        FrameworkTypePaths.StoryMap
    })/draft/:draftId`
).pattern;

export const getEditorPaths = () => legacyEditorSteps.pattern;

// region redirect from editor2 to editor
export const editorLegacyPaths = {
    base: legacyRootPath.slash(`account/:accountId/program/:programName/:navigationItem(${StudioMainCategoriesPaths.Editor2})`).pattern,
    content: legacyRootPath.slash(`account/:accountId/program/:programName/:navigationItem(${StudioMainCategoriesPaths.Editor2})/:editorType(${EditorTypePaths.Content})`).pattern
};
export const getEditorStep = (
    stepId: EditorTypePaths,
    accountId: string,
    programName: string
) => legacyRootPath.slash(
    `/account/${accountId}/program/${programName}/${StudioMainCategoriesPaths.Editor}/${stepId}`
).pattern;
// endregion

export const getFrameworkPaths = (sharedFrameworkViewerUser?: boolean): string[] => {
    if (sharedFrameworkViewerUser) {
        return [
            sharedFrameworkViewerPath
        ];
    }
    else {
        return [
            legacyRootPath.slash(`account/:accountId/program/:programName/:navigationItem(${StudioMainCategoriesPaths.Framework})/:frameworkType(${FrameworkTypePaths.Drafts})`).pattern,
            legacyRootPath.slash(
                `account/:accountId/program/:programName/:navigationItem(${StudioMainCategoriesPaths.Framework})/:frameworkType(${
                    FrameworkTypePaths.Strategy
                }|${
                    FrameworkTypePaths.StoryMap
                })/draft/:draftId`
            ).pattern
        ];
    }
};

export const isSharedFrameworkViewerUser = (userRoles: string[]): boolean => (userRoles || []).length === 1 && userRoles[0] === roles.sharedFrameworkViewer;
// endregion

export const previewPaths = (version: string, stage: string): PathsObject[] => [
    {
        path: legacyRootPath.slash(`account/:accountId/program/:programName/:navigationItem(${StudioMainCategoriesPaths.Builder})/${BuilderTypePaths.Preview}`).pattern,
        key: "draft"
    },
    {
        path: legacyRootPath.slash(`account/:accountId/program/:programName/:navigationItem(${StudioMainCategoriesPaths.Builder})/${BuilderTypePaths.Preview}/stage/:stage`).pattern,
        key: stage
    },
    {
        path: legacyRootPath.slash(`account/:accountId/program/:programName/:navigationItem(${StudioMainCategoriesPaths.Builder})/${BuilderTypePaths.Preview}/version/:version`).pattern,
        key: version
    }
];

export const urlsToSupportForLegacyPrograms = [
    legacyRootPath.slash(":accountId/projects/:projectName").pattern,
    legacyRootPath.slash(":accountId/projects/:projectName/curated").pattern,
    legacyRootPath.slash(":accountId/projects/:projectName/:stage/preview").pattern
];

export const urlsToRedirectToHomePage = [
    legacyRootPath.pattern,
    legacyRootPath.slash("dashboards").pattern,
    legacyAccountIdDashboardsPath.pattern,
    legacyRootPath.slash(":accountId/dashboards/accountOverview/:programName").pattern,
    legacyRootPath.slash(":accountId/dashboards/accountOverview").pattern,
    legacyRootPath.slash(":accountId/dashboards/accountOverview/:programName/sideBySide").pattern,
    legacyRootPath.slash(":accountId/dashboards/accountDashboard/:programName/sideBySide").pattern,
    legacyAccountIdDashboardsAccountDashboardPath.pattern,
    legacyRootPath.slash(":accountId/dashboards/accountDashboard/:programName").pattern,
    legacyRootPath.slash(":accountId/projects/:projectName/wireframes").pattern,
    legacyRootPath.slash(":accountId/projects/:projectName/wireframes/narrations").pattern,
    legacyRootPath.slash(":accountId/projects/:projectName/wireframes/stage/:stage").pattern,
    legacyRootPath.slash(":accountId/projects/:projectName/wireframes/version/:version").pattern,
    legacyRootPath.slash(":accountId/projects/:projectName/wireframes/storyPreview/version/:version").pattern,
    legacyRootPath.slash(":accountId/projects/:projectName/wireframes/storyPreview/:stage").pattern,
    legacyRootPath.slash(":accountId/framework").pattern,
    legacyRootPath.slash(":accountId/projects/:projectName/version-manager").pattern,
    legacyRootPath.slash(":accountId/projects/:programName/analytics").pattern
];

const NOT_ACCOUNT_IDS = new Set([
    "login",
    "preview",
    "admin",
    "cc-library-tool",
    "review-gallery",
    "creative-toolkit",
    "editor",
    "dashboards",
    "projects"
]);
export const getAccountIdFromPath = () => {
    const match = matchPath<{accountId: string}>(
        window.location.pathname,
        {
            path: legacyRootPath.slash("account/:accountId").pattern,
            strict: false,
            exact: false
        }
    );
    if (match) {
        return match.params.accountId;
    }

    const legacyMatch = matchPath<{accountId: string}>(
        window.location.pathname,
        {
            path: legacyAccountIdPath.pattern,
            strict: false,
            exact: false
        }
    );
    if (
        legacyMatch
        && !NOT_ACCOUNT_IDS.has(legacyMatch.params.accountId)
    ) {
        return legacyMatch.params.accountId;
    }

    const newNavMatch = matchPath<{accountId: string}>(
        window.location.pathname,
        {
            path: newNavAccountPath.pattern,
            strict: false,
            exact: false
        }
    );

    if (newNavMatch) {
        return newNavMatch.params.accountId;
    }
};

export const getProgramIdFromPath = (): string | undefined => {
    const legacyMatch = legacyProgramBasePath.match(
        window.location.pathname,
        {
            strict: false,
            end: false
        }
    );
    if (legacyMatch) {
        return legacyMatch.params.programId;
    }

    const newNavMatch = newNavVideoPagePath.match(
        window.location.pathname,
        {
            strict: false,
            end: false
        }
    );
    if (newNavMatch) {
        return newNavMatch.params.videoId;
    }
};
