import { reportErrorToSplunk, reportInfoToSplunk } from "./logReportUtils";
import { localEditorSelectedLibraryVar } from "../../components/editor/State";
import { assetMediaVar, videosAndFoldersVar } from "../../components/model";

export const MEMORY_MEASUREMENT_CUSTOM_ATTRIBUTE = "data-memoryMeasurementMetadata";

function bytesToMB(bytes) {
    return (bytes / (1024 * 1024)).toFixed(2);
}

function measurementInterval() {
    const INTERVAL_IN_MINUTES = 3;
    return (INTERVAL_IN_MINUTES * 60 * 1000);
}


export function reportSingleMemoryMeasurement() {
    reportMemoryMeasurement(false);
}

export function initScheduledMemoryMeasurements() {
    reportMemoryMeasurement(true);
}

function reportMemoryMeasurement(scheduleNextMeasurements: boolean) {
    try {
        /* @ts-ignore */
        const result = performance.memory;
        const memoryUsage = {
            totalJSHeapSizeInMb: bytesToMB(result.totalJSHeapSize),
            usedJSHeapSizeInMb: bytesToMB(result.usedJSHeapSize),
            jsHeapSizeLimitInMb: bytesToMB(result.jsHeapSizeLimit)
        };

        const memoryUsageMetadata = {
            ...window[MEMORY_MEASUREMENT_CUSTOM_ATTRIBUTE],
            mediaAssets: assetMediaVar?.().assetLibraryMedia?.length || null,
            videos: videosAndFoldersVar?.().videoItemsById?.size || null,
            videoFolders: videosAndFoldersVar?.().foldersById?.size || null
        };

        reportInfoToSplunk("Memory usage", {
            memoryUsage,
            memoryUsageMetadata,
            leftPanelSelectedLibrary: localEditorSelectedLibraryVar?.().library ?? null
        });

    }
    catch (error) {
        reportErrorToSplunk("Failed to measure memory usage", {
            requestUrl: window.location.href,
            crashed: false,
            error
        });
    }

    if (scheduleNextMeasurements) {
        const interval = measurementInterval();
        setTimeout(() => reportMemoryMeasurement(scheduleNextMeasurements), interval);
    }
}
