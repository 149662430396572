import type { AnyEntitiesByContextById, AnyEntity, AnyEntityContextPair, GetDependencyIdFunction } from "./baseEntity";
import { BaseEntity } from "./baseEntity";
import StateReaderUtils from "../common/StateReaderUtils";
import type { GetValidationResultFunction, Issue, ValidationResult } from "../validations/validationManager";
import { IssueCodes } from "../validations/validationManager";
import type { Collector, CollectorArgs, EntityInstance, IEntity } from "./definitions";
import { EntityTypes } from "./definitions";
import { LogicContainers } from "../../../common/commonConst";
import type { ChangeDetail, DiffResult, GetDiffResultFunc } from "../versionDiff/diffManager";
import DiffManager, { ChangeType } from "../versionDiff/diffManager";
import SceneValidationEntity from "./sceneValidationEntity";
import SceneAnimationEntity from "./sceneAnimationEntity";
import { diffEntities, exposeDiffResult } from "../versionDiff/diffUtils";
import { ADAVarName } from "../vlx/consts";

export default class SceneEntity extends BaseEntity implements IEntity {
    constructor() {
        super(EntityTypes.SCENE);
    }

    collector: Collector = (args: CollectorArgs): EntityInstance[] => {
        const { context, previousContext } = args;
        let scenes: EntityInstance[] = [];
        let allPreviousScenes =
            (previousContext && StateReaderUtils.getProjectWireframeScenes(previousContext.state, previousContext.projectName, previousContext.stage, previousContext.version)) || {};
        let allCurrentScenes = StateReaderUtils.getProjectWireframeScenes(context.state, context.projectName, context.stage, context.version);
        const scenesMap: AnyEntitiesByContextById = this.entityObjectsToMap(allPreviousScenes, allCurrentScenes);
        scenesMap.forEach((scenesPair: AnyEntityContextPair, sceneId: string) => {
            scenes.push({
                id: sceneId,
                validate: (prevValidationResult: ValidationResult, getValidationResultFunction: GetValidationResultFunction): ValidationResult => {
                    return this.doValidate(scenesPair.currentEntity, getValidationResultFunction);
                },
                diff: (getDiffResult: GetDiffResultFunc) => this.doDiff(getDiffResult, scenesPair.previousEntity, scenesPair.currentEntity, sceneId)
            });
        });

        return scenes;
    };

    doValidate = (scene: any, getValidationResult: GetValidationResultFunction): ValidationResult => {
        const type: EntityTypes = this.getType();
        const id: string = scene.id;
        let sceneValidationResult: ValidationResult = { type, id, issues: [], name: scene.name };

        if (id !== LogicContainers.Master) {
            // Check scene validation logic
            const sceneValidationLogicId = `${scene.id}_validation`;
            const getValidationDependencyId: GetDependencyIdFunction = () => sceneValidationLogicId;
            let validationIssue: Issue = this.getDependenciesIssue([undefined], EntityTypes.SCENE_VALIDATION, getValidationDependencyId, getValidationResult, IssueCodes.SCENE_VALIDATION_LOGIC_ERROR);
            if (validationIssue) {
                sceneValidationResult.issues.push(validationIssue);
                sceneValidationResult.severity = validationIssue.severity;
            }

            // Check scene animation logic
            const sceneAnimationLogicId = `${scene.id}_animation`;
            const getAnimationDependencyId: GetDependencyIdFunction = () => sceneAnimationLogicId;
            let animationIssue: Issue = this.getDependenciesIssue([undefined], EntityTypes.SCENE_ANIMATION, getAnimationDependencyId, getValidationResult, IssueCodes.SCENE_ANIMATION_LOGIC_ERROR);
            if (animationIssue) {
                sceneValidationResult.issues.push(animationIssue);
                sceneValidationResult.severity = animationIssue.severity;
            }
        }

        //check scene parts validity
        if (scene.sceneParts) {
            const getDependencyId: GetDependencyIdFunction = (dependency) => `${scene.id}_${dependency.scenePart}`;
            let issue: Issue = this.getDependenciesIssue(scene.sceneParts, EntityTypes.SCENE_PART, getDependencyId, getValidationResult, IssueCodes.SCENE_PART_ERROR);
            if (issue) {
                sceneValidationResult.issues.push(issue);
                sceneValidationResult.severity = issue.severity;
            }
        }

        return sceneValidationResult;
    };

    doDiff = (getDiffResult: GetDiffResultFunc, previousScene, currentScene, id): DiffResult => {
        const name = (currentScene && currentScene.name) || previousScene.name;
        let result: DiffResult = {
            type: this.getType(),
            id,
            name,
            changeType: ChangeType.None,
            changes: [],
            isShown: false
        };

        if (id === LogicContainers.Master) {
            // diff master - doesn't have sceneparts so we'll cut straight to it's logic
            const logicIds: string[] = this.collectLogicIdsFromMaster(previousScene, currentScene);
            const getLogicDependencyId: GetDependencyIdFunction = (id) => `${id}_${LogicContainers.Master}`;
            const diff: ChangeDetail = this.getDependenciesDiff(logicIds, EntityTypes.LOGIC, getLogicDependencyId, getDiffResult, false);
            if (diff) {
                result.changeType = diff.changeType;
                result.changes.push(diff);
            }
        }
        else {
            // regular scene
            let changeList: ChangeType[] = [];

            // diff scene itself, without sceneparts
            const previousSceneWithoutSceneParts = previousScene && { ...previousScene, sceneParts: undefined };
            const currentSceneWithoutSceneParts = currentScene && { ...currentScene, sceneParts: undefined };
            result.changeType = diffEntities(previousSceneWithoutSceneParts, currentSceneWithoutSceneParts);

            if (result.changeType === ChangeType.Add || result.changeType === ChangeType.Delete) {
                result.isShown = true;
            }
            else {
                // diff scene validation
                const getValidationDependencyId: GetDependencyIdFunction = (id) => SceneValidationEntity.generateId(id);
                const sceneValidationDiff: ChangeDetail = this.getDependenciesDiff([id], EntityTypes.LOGIC, getValidationDependencyId, getDiffResult, true);
                if (sceneValidationDiff) {
                    sceneValidationDiff.children[0] = exposeDiffResult(sceneValidationDiff.children[0], "Scene Validation", null);
                    changeList.push(sceneValidationDiff.changeType);
                    result.changes.push(sceneValidationDiff);
                }

                // diff scene animation
                const getAnimationLogicId: GetDependencyIdFunction = (id) => SceneAnimationEntity.generateId(id);
                const sceneAnimationDiff: ChangeDetail = this.getDependenciesDiff([id], EntityTypes.LOGIC, getAnimationLogicId, getDiffResult, true);
                if (sceneAnimationDiff) {
                    sceneAnimationDiff.children[0] = exposeDiffResult(sceneAnimationDiff.children[0], "Scene Animation", null);
                    changeList.push(sceneAnimationDiff.changeType);
                    result.changes.push(sceneAnimationDiff);
                }

                // diff descriptive-transcript
                const getDescTransLogicId: GetDependencyIdFunction = (id) => `${ADAVarName}_${id}`;
                const sceneDescTransDiff: ChangeDetail = this.getDependenciesDiff([id], EntityTypes.LOGIC, getDescTransLogicId, getDiffResult, true);
                if (sceneDescTransDiff) {
                    sceneDescTransDiff.children[0] = exposeDiffResult(sceneDescTransDiff.children[0], "Descriptive Transcript", null);
                    changeList.push(sceneDescTransDiff.changeType);
                    result.changes.push(sceneDescTransDiff);
                }

                // handle scene parts
                const scenePartsIds: string[] = this.collectScenePartIds(previousScene, currentScene);
                const getScenePartDependencyId: GetDependencyIdFunction = (scenePartId) => `${id}_${scenePartId}`;
                const scenePartsDiff: ChangeDetail = this.getDependenciesDiff(scenePartsIds, EntityTypes.SCENE_PART, getScenePartDependencyId, getDiffResult, false);
                if (scenePartsDiff) {
                    changeList.push(scenePartsDiff.changeType);
                    result.changes.push(scenePartsDiff);
                }

                result.changeType = DiffManager.getChangeType(changeList, false);
                result.isShown = result.changeType !== ChangeType.None;
            }
        }

        return result;
    };

    private collectScenePartIds = (previousScene: AnyEntity, currentScene: AnyEntity): string[] => {
        const scenePartIdent = (sp) => sp.scenePart;
        return Array.from(new Set([...((previousScene && previousScene.sceneParts.map(scenePartIdent)) || []), ...((currentScene && currentScene.sceneParts.map(scenePartIdent)) || [])]));
    };

    private collectLogicIdsFromMaster = (previousMaster: AnyEntity, currentMaster: AnyEntity): string[] => {
        const phIdent = (ph): string => ph.id || ph.name;

        let prevPlaceholders;
        let prevParameters;
        let currentPlaceholders;
        let currentParameters = [];
        if (previousMaster && previousMaster.sceneParts[0]) {
            prevPlaceholders = previousMaster.sceneParts[0].placeholders.map(phIdent);
            prevParameters = previousMaster.sceneParts[0].parameters.map(phIdent);
        }
        if (currentMaster && currentMaster.sceneParts[0]) {
            currentPlaceholders = currentMaster.sceneParts[0].placeholders.map(phIdent);
            currentParameters = currentMaster.sceneParts[0].parameters.map(phIdent);
        }

        return Array.from(new Set([...prevPlaceholders, ...prevParameters, ...currentPlaceholders, ...currentParameters]));
    };
}
