import { LOADING_USER_DATA_SUCCESS, LOADING_ACTIVE_ACCOUNT_SUCCESS, SET_ACTIVE_ACCOUNT_ID } from "./userActions";
function userReducer(state, action) {
    let byId;
    let allIds;
    switch (action.type) {
        case LOADING_USER_DATA_SUCCESS:
            byId = action.payload.accounts.reduce((acc, cur) => {
                acc[cur.accountId] = cur;
                return acc;
            }, {});
            allIds = action.payload.accounts.reduce((acc, cur) => {
                acc.push(cur.accountId);
                return acc;
            }, []);
            return Object.assign({}, state, {
                activeAccountId: action.payload.selectedAccountId,
                userName: action.payload.userName,
                userRoles: action.payload.userRoles,
                accounts: { byId: byId, allIds: allIds, isInitialized: true },
                resolverEndPoint: action.payload.resolverEndPoint,
                dtaasEndPoint: action.payload.dtaasEndPoint,
                narrationServerEndpoint: action.payload.narrationServerEndpoint,
                mockNarrationServerEndpoint: action.payload.mockNarrationServerEndpoint,
                tableauBaseURL: action.payload.tableauBaseURL,
                tableauWorkbookName: action.payload.tableauWorkbookName,
                isLoading: false,
                error: false
            });
        case LOADING_ACTIVE_ACCOUNT_SUCCESS:
            byId = Object.assign(Object.assign({}, state.accounts.byId), { [action.payload.accountId]: action.payload });
            allIds = state.accounts.allIds.indexOf(action.payload.accountId) < 0 ? [...state.accounts.allIds, action.payload.accountId] : state.accounts.allIds;
            return Object.assign({}, state, {
                activeAccountId: action.payload.accountId,
                accounts: { byId: byId, allIds: allIds },
                isLoading: false,
                error: false
            });
        case SET_ACTIVE_ACCOUNT_ID:
            return Object.assign({}, state, {
                activeAccountId: action.payload
            });
        default:
            return state;
    }
}
export default userReducer;
