import BaseServices from "./BaseServices";
import { EDITING_MODE, STAGE_MODE } from "./Consts";
import type { ConfirmForgotPasswordFunction, InitiateForgotPasswordFunction } from "@sundaysky/smartvideo-hub-auth";

export class OktaForgotPasswordServices extends BaseServices {
    constructor(authService, builderModeRetriever) {
        super(authService, builderModeRetriever);
    }

    initiateForgotPassword: InitiateForgotPasswordFunction = (user: string) => {
        return this.post([EDITING_MODE, STAGE_MODE], "/users/initiateForgotPassword", { user });
    };

    confirmForgotPassword: ConfirmForgotPasswordFunction = (user: string, verificationCode: string, newPassword: string) => {
        return this.post([EDITING_MODE, STAGE_MODE], "/users/confirmForgotPassword", { user, verificationCode, newPassword });
    };
}
