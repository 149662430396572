import BaseServices from "./BaseServices";
import { EDITING_MODE, STAGE_MODE } from "./Consts";
import type { LandingPagePreviewBody, LandingPagePreviewResponse } from "../../../common/types/restApiTypes";

class LandingPagePreviewServices extends BaseServices {
    constructor(authService, builderModeRetriever) {
        super(authService, builderModeRetriever);
    }

    getLandingPagePreviewUrl(accountId: string, programId: string, data: LandingPagePreviewBody): Promise<LandingPagePreviewResponse> {
        return this.post<LandingPagePreviewResponse>([EDITING_MODE, STAGE_MODE], `/rest/${accountId}/programs/${programId}/landingPage/preview`, data);
    }
}

export default LandingPagePreviewServices;
