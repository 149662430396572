import type {
    LoadingProgramWireframeSuccess } from "./projectsWireframesActions";
import {
    ADD_CUSTOM_ANALYTIC_FIELD_SUCCESS,
    ADDING_PROJECT_WIREFRAMES_PRESET_SUCCESS,
    ADDING_PROJECT_WIREFRAMES_SCENE_PART_STORYBOARD_FRAME_SUCCESS,
    ADDING_PROJECT_WIREFRAMES_SCENE_PART_SUCCESS,
    ADDING_PROJECT_WIREFRAMES_SCENE_SUCCESS,
    ADDING_SURVEY_SUCCESS,
    CHANGE_ACTIVE_PRESET,
    CHANGING_BULK_SCENE_PARTS_SUCCESS,
    CHANGING_PROJECT_WIREFRAMES_PRESET_SUCCESS,
    CHANGING_PROJECT_WIREFRAMES_SCENE_PART_SUCCESS,
    CHANGING_PROJECT_WIREFRAMES_SCENE_SUCCESS,
    CHANGING_PROJECT_WIREFRAMES_STORY_SUCCESS,
    CREATE_PROJECT_MASTER_ANIMATION_GUIDELINE_SUCCESS,
    DELETE_CUSTOM_ANALYTIC_FIELD_SUCCESS,
    DELETING_PROJECT_MASTER_ANIMATION_GUIDELINE_SUCCESS,
    DELETING_PROJECT_WIREFRAMES_INPUT_LOGICS_SUCCESS,
    DELETING_PROJECT_WIREFRAMES_PRESET_SUCCESS,
    DELETING_PROJECT_WIREFRAMES_SCENE_PART_SUCCESS,
    DELETING_PROJECT_WIREFRAMES_SCENE_SUCCESS,
    DELETING_SURVEY_SUCCESS,
    LOADING_PROGRAM_WIREFRAMES_SUCCESS,
    LOADING_PROJECT_WIREFRAMES_FROM_UIASSET,
    LOADING_PROJECT_WIREFRAMES_PRESETS_SUCCESS,
    LOADING_PROJECT_WIREFRAMES_SCENE_INPUT_LOGIC,
    LOADING_SURVEYS_SUCCESS,
    RENAME_PROJECT_MASTER_ANIMATION_GUIDELINE_SUCCESS,
    SET_CUSTOM_ANALYTIC_FIELD_SUCCESS,
    SET_CUSTOM_ANALYTICS_ORDER_SUCCESS,
    UPDATE_PROJECT_MASTER_SUCCESS,
    UPDATING_PROJECT_WIREFRAMES_SCENE_INPUT_LOGIC_SUCCESS,
    UPDATING_SURVEY_SUCCESS,
    UPDATE_WIREFRAME_GRAPHQL_PARENT_PROGRAM_VERSION_ID
} from "./projectsWireframesActions";
import { UPDATE_PROJECT_COMPLETE } from "../projectsActions";
import StateReaderUtils from "../../common/StateReaderUtils";
import { cleanInputLogic } from "../../vlx/editorLogicUtils";
import { LogicContainers } from "../../../../common/commonConst";
import type { LogicJSON, WireframeLogic, WireframeLogicContainer } from "../../../../common/types/logic";
import { LogicType } from "../../../../common/types/logic";
import { immutablyReplaceValue } from "../../../../common/generalUtils";

function cleanWireframes(wireframes) {
    if (wireframes && wireframes.logic) {
        Object.keys(wireframes.logic).forEach((logicContainer) => {
            if (StateReaderUtils.isLogicContainerAScene(logicContainer) || logicContainer === LogicContainers.Master) {
                let allSceneLogic = wireframes.logic[logicContainer];
                Object.values(allSceneLogic).forEach((logic: LogicJSON) => {
                    //@ts-ignore
                    if (logic && logic.outputType === "parameter") {
                        logic.outputType = LogicType.Text;
                    }
                });
            }
        });
    }
}

function updateLogic(state, projectName, logicContainer, logicOwner, logicData, replace) {
    if (!logicData) {
        return state;
    }

    const byName = state.wireframes.byName;
    const allNames = state.wireframes.allNames;

    const currentProjectLogic = byName[projectName] ? byName[projectName].logic : {};

    const currentSceneLogic = byName[projectName] && byName[projectName].logic ? byName[projectName].logic[logicContainer] : {};

    const currentInputLogic = byName[projectName] && byName[projectName].logic && byName[projectName].logic[logicContainer] ? byName[projectName].logic[logicContainer][logicOwner] : {};

    const newLogic = replace ? logicData : { ...currentInputLogic, ...logicData };

    return {
        ...state,
        wireframes: {
            byName: {
                ...byName,
                [projectName]: {
                    ...byName[projectName],
                    logic: {
                        ...currentProjectLogic,
                        [logicContainer]: {
                            ...currentSceneLogic,
                            [logicOwner]: newLogic
                        }
                    }
                }
            },
            allNames: allNames
        },
        error: false
    };
}

/**
 * Updates the logic in the state. If the logic belongs to a placeholder, update the validation on the placeholder section
 * @param state The Redux state
 * @param projectName The project name
 * @param logicContainer The container of all the logic. e.g. sceneId, 'derived', 'analytics', etc.
 * @param logicOwner The element which the logic belongs to. e.g. placeholder name, derived DE Id, Decision Point Id etc.
 * @param logicData The logic
 * @param replace boolean
 */
function updateSceneInputLogic(state, projectName, logicContainer, logicOwner, logicData, replace) {
    if (logicData && typeof logicData === "object" && !logicData.loading) {
        // Cleaning input logic with redundant stuff (unneeded RHS, etc.)
        logicData = cleanInputLogic(logicData);
    }

    return updateLogic(state, projectName, logicContainer, logicOwner, logicData, replace);
}

function deleteSceneInputLogic(state, projectName, sceneId, inputName) {
    let byName = state.wireframes.byName;
    let allNames = state.wireframes.allNames;

    let newstate = Object.assign({}, state, {
        wireframes: {
            byName: Object.assign({}, byName, {
                [projectName]: Object.assign({}, byName[projectName], {
                    logic: Object.assign({}, byName[projectName] ? byName[projectName].logic : {}, {
                        [sceneId]: Object.assign({}, byName[projectName] && byName[projectName].logic ? byName[projectName].logic[sceneId] : {})
                    })
                })
            }),
            allNames: allNames
        },
        error: false
    });

    delete newstate.wireframes.byName[projectName].logic[sceneId][inputName];
    return newstate;
}

function cleanWireframeLogicItems(logicData: Omit<WireframeLogic, "program" | "story">): Omit<WireframeLogic, "program" | "story"> {
    if (!logicData) return {};

    const wireframeLogic = Object.keys(logicData).reduce((acc, logicContainer) => {
        const logicItems: WireframeLogicContainer = logicData[logicContainer];
        const logicByInput = Object.keys(logicItems).reduce((acc, inputName) => {
            // Cleaning input logic with redundant stuff (unneeded RHS, etc.)
            acc[inputName] = cleanInputLogic(logicItems[inputName]);
            return acc;
        }, {});
        acc[logicContainer] = logicByInput;
        return acc;
    }, {});
    return wireframeLogic;
}

const constructInitialWireframe = (state, projectName, data, stage, version) => {
    const wireframeKey = getWireframeKey(stage, version);
    // Copy object (so if loading a different stage we won't be affected)
    let byName = state[wireframeKey].byName ? Object.assign({}, state[wireframeKey].byName) : {};

    let allNames;
    // If project is missing from the 'allName' array extend it
    if (state[wireframeKey].allNames.indexOf(projectName) > -1) {
        allNames = state[wireframeKey].allNames;
    }
    else {
        allNames = [...state[wireframeKey].allNames, projectName];
    }

    if (stage === "diff") {
        if (!byName[projectName]) {
            byName[projectName] = {};
        }
        byName[projectName] = {
            ...byName[projectName],
            [version]: data
        };
    }
    else {
        byName[projectName] = data;
    }
    return { [wireframeKey]: { byName, allNames } } ;
};

function getWireframeKey(stage, version) {
    if (stage) {
        return `${stage}wireframes`;
    }
    else if (version) {
        return "versionwireframes";
    }
    return "wireframes";
}

function wireFramesReducer(state, action) {
    switch (action.type) {
        case CHANGING_PROJECT_WIREFRAMES_SCENE_SUCCESS:
        case ADDING_PROJECT_WIREFRAMES_SCENE_SUCCESS: {
            let { projectName, sceneId, sceneData } = action.payload;
            const wireframesPath = StateReaderUtils.getWireframesPathOnState(projectName);

            let newState;
            newState = immutablyReplaceValue(state, [...wireframesPath, "scenes", sceneId], sceneData);
            newState = immutablyReplaceValue(newState, ["lastRelevantNarrationUpdateTimeStamp"], Date.now());

            return newState;
        }
        case CHANGING_PROJECT_WIREFRAMES_STORY_SUCCESS: {
            let { projectName, storyId, storyData } = action.payload;
            let byName = state.wireframes.byName;
            let allNames = state.wireframes.allNames;
            let currentStories = byName[projectName] ? byName[projectName].stories : {};

            return Object.assign({}, state, {
                wireframes: {
                    byName: Object.assign({}, byName, {
                        [projectName]: Object.assign({}, byName[projectName], {
                            stories: Object.assign({}, currentStories, { [storyId]: storyData })
                        })
                    }),
                    allNames: allNames
                },
                error: false
            });
        }
        case DELETING_PROJECT_WIREFRAMES_SCENE_SUCCESS: {
            let { projectName, sceneId } = action.payload;
            let byName = state.wireframes.byName;
            let allNames = state.wireframes.allNames;

            let modifiedScenesList = Object.assign({}, byName[projectName].scenes);
            delete modifiedScenesList[sceneId];

            byName = { ...byName, [projectName]: Object.assign({}, byName[projectName], { scenes: modifiedScenesList }) };

            return Object.assign({}, state, {
                wireframes: { byName, allNames },
                error: false
            });
        }
        case LOADING_PROJECT_WIREFRAMES_FROM_UIASSET: {
            let { projectName, data, stage, version } = action.payload;
            if (stage || version) {
                data && (data.snapshotNumber = version);
            }
            cleanWireframes(data);
            return {
                ...state,
                ...constructInitialWireframe(state, projectName, data, stage, version),
                error: false,
                middlePanelCollapsed: state.middlePanelCollapsed === undefined ? true : state.middlePanelCollapsed
            };
        }
        case ADDING_PROJECT_WIREFRAMES_SCENE_PART_SUCCESS: {
            let { projectName, sceneId, scenePart } = action.payload;
            let byName = state.wireframes.byName;
            let allNames = state.wireframes.allNames;

            let sceneData = Object.assign({}, {}, byName[projectName].scenes[sceneId]);
            sceneData.sceneParts = [...sceneData.sceneParts, scenePart];

            let byNameProj = Object.assign({}, byName[projectName], {
                scenes: Object.assign({}, byName[projectName].scenes, { [sceneId]: sceneData })
            });

            byName = {
                ...byName,
                [projectName]: byNameProj
            };

            return Object.assign({}, state, {
                wireframes: { byName, allNames },
                error: false
            });
        }
        case CHANGING_PROJECT_WIREFRAMES_SCENE_PART_SUCCESS: {
            const { projectName, sceneId, scenePart, scenePartIndex } = action.payload;
            const byName = state.wireframes.byName;
            const allNames = state.wireframes.allNames;

            let sceneParts = [...byName[projectName].scenes[sceneId].sceneParts];
            sceneParts[scenePartIndex] = scenePart;

            return {
                ...state,
                lastRelevantNarrationUpdateTimeStamp: Date.now(),
                wireframes: {
                    byName: {
                        ...byName,
                        [projectName]: {
                            ...byName[projectName],
                            scenes: {
                                ...byName[projectName].scenes,
                                [sceneId]: {
                                    ...byName[projectName].scenes[sceneId],
                                    sceneParts
                                }
                            }
                        }
                    },
                    allNames
                },
                error: false
            };
        }
        case CHANGING_BULK_SCENE_PARTS_SUCCESS: {
            let { projectName, bulkSceneParts } = action.payload;
            let byName = state.wireframes.byName;
            let allNames = state.wireframes.allNames;
            let scenes = { ...byName[projectName].scenes };

            Object.keys(bulkSceneParts).forEach((bulkId) => {
                let ids = bulkId.split("/");
                let sceneId = ids[0];
                let scenePartId = ids[1];
                let scenePartIndex = scenes[sceneId].sceneParts.findIndex((scenePart) => scenePart.scenePart === scenePartId);

                scenes[sceneId] = scenes[sceneId] === byName[projectName].scenes[sceneId] ? { ...scenes[sceneId] } : scenes[sceneId];

                scenes[sceneId].sceneParts = [...scenes[sceneId].sceneParts];
                scenes[sceneId].sceneParts[scenePartIndex] = bulkSceneParts[bulkId];
            });

            byName[projectName] = {
                ...byName[projectName],
                scenes: scenes
            };

            return {
                ...state,
                lastRelevantNarrationUpdateTimeStamp: Date.now(),
                wireframes: { byName, allNames },
                error: false
            };
        }
        case DELETING_PROJECT_WIREFRAMES_SCENE_PART_SUCCESS: {
            let { projectName, sceneId, scenePartIndex } = action.payload;
            let byName = state.wireframes.byName;
            let allNames = state.wireframes.allNames;

            let sceneData = byName[projectName].scenes[sceneId];

            byName[projectName] = Object.assign({}, byName[projectName], {
                scenes: Object.assign({}, byName[projectName].scenes, {
                    [sceneId]: Object.assign({}, sceneData, {
                        sceneParts: byName[projectName].scenes[sceneId].sceneParts.filter((scenePartItem) => scenePartItem.scenePart !== scenePartIndex)
                    })
                })
            });

            return Object.assign({}, state, {
                wireframes: { byName, allNames },
                error: false
            });
        }
        case LOADING_PROJECT_WIREFRAMES_SCENE_INPUT_LOGIC: {
            let { projectName, sceneId, inputName } = action.payload;
            return updateSceneInputLogic(state, projectName, sceneId, inputName, { loading: true }, false);
        }
        case UPDATING_PROJECT_WIREFRAMES_SCENE_INPUT_LOGIC_SUCCESS: {
            let { projectName, sceneId, inputName, logicData } = action.payload;
            return updateSceneInputLogic(state, projectName, sceneId, inputName, logicData, true);
        }
        case LOADING_PROGRAM_WIREFRAMES_SUCCESS: {
            let { projectName, initialWireframes, logicData, programLogic, storiesLogic, stage, version } = action.payload as LoadingProgramWireframeSuccess;
            if (stage || version) {
                initialWireframes && (initialWireframes.snapshotNumber = version);
            }
            let wireFrameLogic: WireframeLogic = cleanWireframeLogicItems(logicData);
            if (programLogic) {
                wireFrameLogic[LogicContainers.Program] = programLogic;
            }
            if (storiesLogic) {
                wireFrameLogic[LogicContainers.Story] = storiesLogic;
            }
            initialWireframes.logic = wireFrameLogic;
            return {
                ...state,
                ...constructInitialWireframe(state, projectName, initialWireframes, stage, version),
                error: false,
                middlePanelCollapsed: state.middlePanelCollapsed === undefined ? true : state.middlePanelCollapsed
            };
        }
        case DELETING_PROJECT_WIREFRAMES_INPUT_LOGICS_SUCCESS: {
            let { projectName, sceneId, logicName } = action.payload;
            return deleteSceneInputLogic(state, projectName, sceneId, logicName);
        }
        case ADDING_PROJECT_WIREFRAMES_SCENE_PART_STORYBOARD_FRAME_SUCCESS: {
            let { projectName, sceneId, scenePartData, scenePartIndex } = action.payload;
            let byName = state.wireframes.byName;
            let allNames = state.wireframes.allNames;

            let sceneData = byName[projectName].scenes[sceneId];
            sceneData.sceneParts[scenePartIndex] = scenePartData;
            byName[projectName] = {
                ...byName[projectName],
                scenes: {
                    ...byName[projectName].scenes,
                    [sceneId]: sceneData
                }
            };

            return {
                ...state,
                wireframes: { byName, allNames },
                error: false
            };
        }
        case CHANGE_ACTIVE_PRESET: {
            return Object.assign({}, state, {
                activePresetId: action.payload.presetId
            });
        }
        case ADDING_PROJECT_WIREFRAMES_PRESET_SUCCESS: {
            let projectName = action.payload.projectName;
            let presetId = action.payload.preset.id;
            let presetData = action.payload.preset;
            let byName = state.presets.byName;
            let allNames = state.presets.allNames;

            byName[projectName] = Object.assign({}, byName[projectName], Object.assign({}, byName[projectName] ? byName[projectName] : {}, { [presetId]: presetData }));

            return Object.assign({}, state, {
                presets: { byName, allNames },
                error: false,
                activePresetId: presetId
            });
        }
        case DELETING_PROJECT_WIREFRAMES_PRESET_SUCCESS: {
            let { projectName, presetId } = action.payload;
            let byName = state.presets.byName;
            let allNames = state.presets.allNames;

            let modifiedPresetList = Object.assign({}, byName[projectName]);
            delete modifiedPresetList[presetId];

            byName[projectName] = Object.assign({}, modifiedPresetList);

            return Object.assign({}, state, {
                presets: { byName, allNames },
                error: false,
                activePresetId: null
            });
        }
        case CHANGING_PROJECT_WIREFRAMES_PRESET_SUCCESS: {
            let { projectName, presetId, presetData } = action.payload;

            let newState = immutablyReplaceValue(state, ["presets", "byName", projectName, presetId], presetData, true);
            newState = immutablyReplaceValue(newState, ["activePresetId"], presetId);

            return newState;
        }
        case LOADING_PROJECT_WIREFRAMES_PRESETS_SUCCESS: {
            let { projectName, presets } = action.payload;
            let byName = state.presets.byName;
            let allNames;

            if (state.presets.allNames.indexOf(projectName) > -1) {
                allNames = state.presets.allNames;
            }
            else {
                allNames = [...state.presets.allNames, projectName];
            }

            byName = {
                ...byName,
                [projectName]: presets
            };
            return Object.assign({}, state, {
                presets: { byName, allNames },
                error: false
            });
        }
        case SET_CUSTOM_ANALYTICS_ORDER_SUCCESS: {
            let { projectName, analyticsOrder, updated } = action.payload;
            let byName = state.wireframes.byName;
            let allNames = state.wireframes.allNames;

            byName[projectName] = {
                ...byName[projectName],
                customAnalyticsOrder: analyticsOrder
            };

            return {
                ...state,
                projects: {
                    byName: {
                        ...state.projects.byName,
                        [projectName]: {
                            ...state.projects.byName[projectName],
                            graphQLUpdated: updated
                        }
                    },
                    allNames: state.projects.allNames
                },
                wireframes: { byName, allNames },
                error: false
            };
        }
        case SET_CUSTOM_ANALYTIC_FIELD_SUCCESS: {
            let { projectName, analyticFieldUpdates } = action.payload;

            const logic = analyticFieldUpdates.data;
            delete analyticFieldUpdates.data;

            let newState = state;
            if (logic) {
                newState = updateLogic(state, projectName, LogicContainers.Analytics, analyticFieldUpdates.id, logic, true);
            }

            let byName = { ...newState.wireframes.byName };
            let allNames = newState.wireframes.allNames;

            byName[projectName] = {
                ...byName[projectName],
                customAnalyticFields: {
                    ...byName[projectName].customAnalyticFields,
                    [analyticFieldUpdates.id]: {
                        ...byName[projectName].customAnalyticFields[analyticFieldUpdates.id],
                        ...analyticFieldUpdates
                    }
                }
            };

            return {
                ...newState,
                wireframes: { byName, allNames },
                error: false
            };
        }
        case ADD_CUSTOM_ANALYTIC_FIELD_SUCCESS: {
            let { projectName, analyticField } = action.payload;

            const newState = updateLogic(state, projectName, LogicContainers.Analytics, analyticField.id, analyticField.data, false);

            let byName = { ...newState.wireframes.byName };
            let allNames = newState.wireframes.allNames;

            byName[projectName] = {
                ...byName[projectName],
                customAnalyticsOrder: [...byName[projectName].customAnalyticsOrder, analyticField.id],
                customAnalyticFields: {
                    ...byName[projectName].customAnalyticFields,
                    [analyticField.id]: analyticField
                }
            };
            return {
                ...newState,
                wireframes: { byName, allNames },
                error: false
            };
        }
        case DELETE_CUSTOM_ANALYTIC_FIELD_SUCCESS: {
            let { projectName, analyticFieldId } = action.payload;

            const newState = deleteSceneInputLogic(state, projectName, LogicContainers.Analytics, analyticFieldId);

            let byName = { ...newState.wireframes.byName };
            let allNames = newState.wireframes.allNames;

            const customAnalyticsOrder = byName[projectName].customAnalyticsOrder;
            const index = customAnalyticsOrder.indexOf(analyticFieldId);
            const newOrder = [...customAnalyticsOrder.slice(0, index), ...customAnalyticsOrder.slice(index + 1)];

            const customAnalyticFields = { ...byName[projectName].customAnalyticFields };
            delete customAnalyticFields[analyticFieldId];

            byName[projectName] = {
                ...byName[projectName],
                customAnalyticsOrder: newOrder,
                customAnalyticFields: customAnalyticFields
            };

            return {
                ...newState,
                wireframes: { byName, allNames },
                error: false
            };
        }
        case DELETING_PROJECT_MASTER_ANIMATION_GUIDELINE_SUCCESS: {
            const { projectName, animationName } = action.payload;
            const wireframe = StateReaderUtils.getWireFrame(state, projectName);
            const { allNames, byName } = state.wireframes;

            const projectScenes = wireframe && wireframe.scenes || {};
            const masterData = StateReaderUtils.getProjectMasterData(state, projectName) || {};
            const masterParameters = masterData?.parameters || [];
            const newMasterParameters = [ ...masterParameters ];
            const index = masterParameters.findIndex(param => param.name === animationName);
            if (index > -1) {
                newMasterParameters.splice(index, 1);
            }
            const projectLogic = StateReaderUtils.getProjectWireframesLogic(state, projectName) || {};
            const projectMasterLogic = StateReaderUtils.getAllProgramLogicFromWireframes(wireframe, LogicContainers.Master) || {};
            const newProjectMasterLogic = { ...projectMasterLogic };
            delete newProjectMasterLogic[animationName];

            const updatedByName = {
                ...byName,
                [projectName]: {
                    ...byName[projectName],
                    scenes: {
                        ...projectScenes,
                        [LogicContainers.Master]: {
                            ...projectScenes[LogicContainers.Master],
                            sceneParts: [{
                                ...masterData,
                                parameters: newMasterParameters
                            }]
                        }
                    },
                    logic:  { ...projectLogic,
                        [LogicContainers.Master]: newProjectMasterLogic
                    }
                }
            };
            return {
                ...state,
                wireframes: {
                    byName: updatedByName,
                    allNames
                }
            };
        }
        case CREATE_PROJECT_MASTER_ANIMATION_GUIDELINE_SUCCESS: {
            const { projectName, animationGuideline } = action.payload;
            const wireframe = StateReaderUtils.getWireFrame(state, projectName);
            const { allNames, byName } = state.wireframes;

            const projectScenes = wireframe && wireframe.scenes || {};
            const masterData = StateReaderUtils.getProjectMasterData(state, projectName) || {};
            const masterParameters = masterData?.parameters || [];

            const updatedByName = {
                ...byName,
                [projectName]: {
                    ...byName[projectName],
                    scenes: {
                        ...projectScenes,
                        [LogicContainers.Master]: {
                            ...projectScenes[LogicContainers.Master],
                            sceneParts: [{
                                ...masterData,
                                parameters: [ ...masterParameters, animationGuideline ]
                            }]
                        }
                    }
                }
            };
            return {
                ...state,
                wireframes: {
                    byName: updatedByName,
                    allNames
                }
            };
        }
        case RENAME_PROJECT_MASTER_ANIMATION_GUIDELINE_SUCCESS: {
            const { projectName, updatedAnimationGuidelineIndex, updatedAnimationGuideline } = action.payload;
            const wireframe = StateReaderUtils.getWireFrame(state, projectName);
            const { allNames, byName } = state.wireframes;

            const projectScenes = wireframe && wireframe.scenes || {};
            const masterData = StateReaderUtils.getProjectMasterData(state, projectName) || {};
            const masterParameters = masterData?.parameters || [];
            const currentMasterAnimationGuideline = masterParameters[updatedAnimationGuidelineIndex];
            const oldName = currentMasterAnimationGuideline.name;
            const newMasterAnimationGuideline = { ...currentMasterAnimationGuideline, ...updatedAnimationGuideline };
            const newMasterParameters = [ ...masterParameters ];
            newMasterParameters.splice(updatedAnimationGuidelineIndex, 1, newMasterAnimationGuideline);

            const projectLogic = StateReaderUtils.getProjectWireframesLogic(state, projectName) || {};
            const projectMasterLogic = StateReaderUtils.getAllProgramLogicFromWireframes(wireframe, LogicContainers.Master) || {};
            const newProjectMasterLogic = { ...projectMasterLogic };
            const oldLogic = projectMasterLogic[oldName];
            if (oldLogic) {
                newProjectMasterLogic[updatedAnimationGuideline.name] = oldLogic;
                delete newProjectMasterLogic[oldName];
            }
            const updatedByName = {
                ...byName,
                [projectName]: {
                    ...byName[projectName],
                    scenes: {
                        ...projectScenes,
                        [LogicContainers.Master]: {
                            ...projectScenes[LogicContainers.Master],
                            sceneParts: [{
                                ...masterData,
                                parameters: newMasterParameters
                            }]
                        }
                    },
                    logic:  { ...projectLogic,
                        [LogicContainers.Master]: newProjectMasterLogic
                    }
                }
            };
            return {
                ...state,
                wireframes: {
                    byName: updatedByName,
                    allNames
                }
            };
        }

        case UPDATE_PROJECT_MASTER_SUCCESS: {
            const { projectName, masterData } = action.payload;
            const { allNames, byName } = state.wireframes;
            const projectScenes = (byName[projectName] && byName[projectName].scenes) || {};
            const updatedByName = {
                ...byName,
                [projectName]: {
                    ...byName[projectName],
                    scenes: {
                        ...projectScenes,
                        [LogicContainers.Master]: {
                            ...projectScenes[LogicContainers.Master],
                            sceneParts: [masterData]
                        }
                    }
                }
            };
            return {
                ...state,
                wireframes: {
                    byName: updatedByName,
                    allNames
                }
            };
        }
        case UPDATE_PROJECT_COMPLETE: {
            const project = action.payload.project;
            const projectName = project && project.projectName;

            let byName = state.wireframes.byName;
            let projectWireframe = byName && byName[projectName];
            let allNames = state.wireframes.allNames;

            if (!project || !projectName || !projectWireframe) {
                return state;
            }

            const updatedFields = Object.keys(project);

            if (updatedFields.includes("creativeVersion") || updatedFields.includes("creativeDDE") || updatedFields.includes("creativeNameLabel")) {
                let projectWireframeNew = Object.assign({}, projectWireframe, project);
                byName = {
                    ...byName,
                    [projectName]: projectWireframeNew
                };

                return Object.assign({}, state, { wireframes: { byName, allNames } });
            }

            return state;
        }
        case ADDING_SURVEY_SUCCESS:
        case UPDATING_SURVEY_SUCCESS: {
            let projectName = action.payload.projectName;
            let surveyId = action.payload.surveyId;
            let surveyData = action.payload.surveyData;
            let wireframes = state.wireframes.byName[projectName];

            let newSurveys = wireframes.surveys ? [...wireframes.surveys] : [];
            let index = newSurveys.findIndex((survey) => survey.surveyId === surveyId);

            if (index > -1) {
                newSurveys[index] = {
                    ...newSurveys[index],
                    surveyData
                };
            }
            else {
                newSurveys.push({
                    ...surveyData,
                    id: surveyId
                });
            }

            return {
                ...state,
                wireframes: {
                    byName: {
                        ...state.wireframes.byName,
                        [projectName]: {
                            ...wireframes,
                            surveys: newSurveys
                        }
                    },
                    allNames: state.wireframes.allNames
                },
                error: false
            };
        }
        case DELETING_SURVEY_SUCCESS: {
            let { projectName, surveyId } = action.payload;
            let wireframes = state.wireframes.byName[projectName];
            let newSurveys = wireframes.surveys ? [...wireframes.surveys] : [];
            let index = newSurveys.findIndex((survey) => survey.surveyId === surveyId);
            newSurveys.splice(index, 1);

            return {
                ...state,
                wireframes: {
                    byName: {
                        ...state.wireframes.byName,
                        [projectName]: {
                            ...wireframes,
                            surveys: newSurveys
                        }
                    },
                    allNames: state.wireframes.allNames
                },
                error: false
            };
        }
        case LOADING_SURVEYS_SUCCESS: {
            let { projectName, surveys } = action.payload;
            let wireframes = state.wireframes.byName[projectName];

            return {
                ...state,
                wireframes: {
                    byName: {
                        ...state.wireframes.byName,
                        [projectName]: {
                            ...wireframes,
                            surveys: surveys
                        }
                    },
                    allNames: state.wireframes.allNames
                },
                error: false
            };
        }
        case UPDATE_WIREFRAME_GRAPHQL_PARENT_PROGRAM_VERSION_ID: {
            let { projectName, graphQLParentProgramVersionId } = action.payload;
            const wireframesPath = StateReaderUtils.getWireframesPathOnState(projectName);

            let newState;
            newState = immutablyReplaceValue(state, [...wireframesPath, "graphQLParentProgramVersionId"], graphQLParentProgramVersionId);

            return newState;
        }
        default:
            return state;
    }
}

export default wireFramesReducer;
