import { createAction } from "redux-actions";
import { errorMessageVar } from "../../components/model";
import { handleError, handleUnexpectedError } from "./errorHandlingUtils";
import type { DrawerFilters } from "../../components/main/mainContainerUtils";

export const ERROR = "ERROR";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const PUSH_HISTORY = "PUSH_HISTORY";
export const SET_PAGE_TITLE = "SET_PAGE_TITLE";
export const SET_LOADING = "SET_LOADING";
export const SET_SOFT_LOADING = "SET_SOFT_LOADING";
export const SET_LOADING_FOR_UPDATE_NARRATION_LIBRARY = "SET_LOADING_FOR_UPDATE_NARRATION_LIBRARY";
export const SET_BREADCRUMBS_ARRAY = "SET_BREADCRUMBS_ARRAY";
export const SET_REVERT_TO_VERSION = "SET_REVERT_TO_VERSION";
export const ENABLE_HUB_STICKY_BAR_LOGO_MENU = "ENABLE_HUB_STICKY_BAR_LOGO_MENU";
export const SET_HUB_STICKY_BAR_LOGO_MENU_ITEMS_ARRAY = "SET_HUB_STICKY_BAR_LOGO_MENU_ITEMS_ARRAY";
export const TOGGLE_MIDDLE_PANEL_COLLAPSE = "TOGGLE_MIDDLE_PANEL_COLLAPSE";
export const DATA_LIBRARY_SELECTED_TAB = "DATA_LIBRARY_SELECTED_TAB";
export const SET_FORCE_LOAD = "SET_FORCE_LOAD";
export const REPORT_ESCAPE_CLICK = "REPORT_ESCAPE_CLICK";
export const SET_DRAWER_FILTER = "SET_DRAWER_FILTER";

export const setDrawerFilter = createAction(SET_DRAWER_FILTER, (drawerFilter: DrawerFilters) => ({ drawerFilter }));
export const reportErrorSuccess = createAction(ERROR, (userErrorMessage: string) => ({ userErrorMessage }));
export const clearError = createAction(CLEAR_ERROR, () => {});
export const pushHistory = createAction(PUSH_HISTORY, (title, link, context) => ({ context, title, link }));
export const setPageTitle = createAction(SET_PAGE_TITLE, (pageTitle) => ({ pageTitle }));
export const setLoading = createAction(SET_LOADING, (isLoading, accountId: string, projectName: string, stage: string, version: string) => ({
    isLoading,
    loadingProgress: undefined,
    accountId,
    projectName,
    stage,
    version
}), undefined);
export const setSoftLoading = createAction(SET_SOFT_LOADING, (isLoading) => ({ isLoading }));
export const setLoadingForUpdateNarrationLibrary = createAction(SET_LOADING_FOR_UPDATE_NARRATION_LIBRARY, (isLoading, accountId, projectName) => ({ isLoading }));
export const setBreadcrumbsArray = createAction(SET_BREADCRUMBS_ARRAY, (breadCrumbsArray) => ({ breadCrumbsArray }));
export const setHubStickyBarLogoMenuItemsArray = createAction(SET_HUB_STICKY_BAR_LOGO_MENU_ITEMS_ARRAY, (hubStickyBarLogoMenuItemsArray) => ({ hubStickyBarLogoMenuItemsArray }));
export const enableHubStickyBarLogoMenu = createAction(ENABLE_HUB_STICKY_BAR_LOGO_MENU, (isEnableHubStickyBarLogoMenu) => ({ isEnableHubStickyBarLogoMenu }));
export const toggleMiddlePanelCollapse = createAction(TOGGLE_MIDDLE_PANEL_COLLAPSE);
export const setDataLibrarySelectedTabId = createAction(DATA_LIBRARY_SELECTED_TAB, (selectedTabId) => selectedTabId);
export const setForceLoad = createAction(SET_FORCE_LOAD, (forceLoad) => forceLoad);
export const setRevertToVersion = createAction(SET_REVERT_TO_VERSION, (versionNumber) => versionNumber);
export const reportEscapeClick = createAction(REPORT_ESCAPE_CLICK, (isEscapeClick) => ({ isEscapeClick }));

export const onEscapeClick = function(isEscapeClick, cb) {
    return function(dispatch) {
        if (isEscapeClick) {
            dispatch(reportEscapeClick(true));
            cb();
        }
    };
};

export const _reportError = async (
    {
        error,
        reportErrorToUser = true,
        userErrorMessage,
        reportErrorSuccess
    }
    : {
        error: any;
        reportErrorToUser: boolean;
        userErrorMessage?: string;
        reportErrorSuccess: (message: string) => void;
    }
) => {
    const isRedirected = await handleError({ error });

    // In case the user was not redirected we will notify him with the error
    if (!isRedirected && reportErrorToUser) {
        const response = error.response;
        let errorMessageForUser: string;
        if (userErrorMessage) {
            errorMessageForUser = userErrorMessage;
        }
        //rest user error parsing - backward compatible.
        else if (error?.response?.data?.errorId) {
            errorMessageForUser = (
                "Error ID:"
                + response.data.errorId
                + "\n\nDescription:"
                + (response.data.message || response.data.error)
                + "\n\nDetails:"
                + response.data.metadata
            );
        }
        else {
            errorMessageForUser = String(error).toString();
        }
        reportErrorSuccess(errorMessageForUser);
    }
};

export const reportError = function(error: any, reportErrorToUser: boolean = true, userErrorMessage?: string) {
    return async function(dispatch) {
        return _reportError({
            error,
            reportErrorToUser,
            userErrorMessage,
            reportErrorSuccess: message => dispatch(reportErrorSuccess(message))
        });
    };
};

export const reportErrorRV = function(error: any, reportErrorToUser: boolean = true, userErrorMessage?: string) {
    return _reportError({
        error,
        reportErrorToUser,
        userErrorMessage,
        reportErrorSuccess: message => errorMessageVar(message)
    });
};

export const reportUnhandledError = function(error) {
    return async function() {
        await handleUnexpectedError({ error });
    };
};
