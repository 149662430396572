import type {
    EntityIdentifier,
    EntityOrder,
    Program,
    ProgramEntities,
    Roles,
    SelectItem
} from "./types";
import {
    EntityTypeNames,
    Role
} from "./types";
import type { CardContent, CardData, EntityCardData, Icon } from "../../components/framework/EntityCard";
import { ENTITY_COLOR_MAPPER, entityTypeToKeyMap, PERSONALIZATION_TYPES_MAP } from "./defaultValues";
import type { RefObject } from "react";
import memoize from "memoize-one";
import type {
    GqlClientAudience,
    GqlClientAudienceValue,
    GqlClientChannel,
    GqlClientFeedDataElement,
    GqlClientGoal,
    GqlClientKpi,
    GqlClientPersonalization,
    GqlClientProgramVersion,
    GqlClientScene,
    GqlClientStory,
    GqlClientTouchpoint
} from "../../graphql/graphqlGeneratedTypes/graphqlClient";
import { activeDraftLocalStorageId } from "./consts";

export const scrollIntoView = (ref: RefObject<HTMLElement>, alignToTop: boolean = true): void => {
    ref && ref.current && ref.current.scrollIntoView({ block: alignToTop ? "start" : "end", inline: "nearest", behavior: "smooth" });
};

export const convertListToSelectItems = (list: any[], valueProp: string = "id", textProp: string = "name", sort: boolean = false): SelectItem[] => {
    const selectItems: SelectItem[] = list.map((elem) => ({
        key: typeof elem === "string" ? elem : elem.id,
        value: typeof elem === "string" ? elem : elem[valueProp],
        text: typeof elem === "string" ? elem : elem[textProp],
        dataTestId: `testId-menu-item-${(elem as any)[textProp] || elem}`
    }));

    return sort ? selectItems.sort((a, b) => a.text.localeCompare(b.text)) : selectItems;
};

export const getEntityTypeName = (entity: { __typename: string }): EntityTypeNames => {
    if (!entity) return null;

    const { __typename: type } = entity;
    switch (type) {
        case "Audience":
        case "AudiencePersonalization":
            return EntityTypeNames.AUDIENCE;
        case "AudienceValue":
            return EntityTypeNames.AUDIENCE_VALUE;
        case "Channel":
        case "ChannelPersonalization":
            return EntityTypeNames.CHANNEL;
        case "Scene":
            return EntityTypeNames.SCENE;
        case "Story":
            return EntityTypeNames.STORY;
        case "Touchpoint":
        case "TouchpointPersonalization":
            return EntityTypeNames.TOUCHPOINT;
        case "KPI":
            return EntityTypeNames.KPI;
        case "Goal":
            return EntityTypeNames.GOAL;
        case "DataElement":
        case "FeedDataElement":
            return EntityTypeNames.DATA_ELEMENT;
        default:
            return null;
    }
};

// region CardData
export const initCardData = (entity: any): CardData => {
    const type = getEntityTypeName(entity);
    return {
        entityId: entity.id,
        entityType: type,
        role: Role.Support, // default Role for overall view
        color: ENTITY_COLOR_MAPPER[type],
        header: { title: entity.name, icons: [] },
        contents: []
    };
};

export const getPersonalizationDescriptionOrUsedInScenes = (personalizations: GqlClientPersonalization[], leadId: string): CardContent => {
    let personalizationDescription: string;
    let sceneNames: string[] = [];
    personalizations.forEach(({ scene, description }) => {
        sceneNames.push(scene.name);
        if (leadId === scene.id) personalizationDescription = description;
    });

    return personalizationDescription
        ? { title: "Personalization description:", descriptionText: personalizationDescription }
        : { title: "Used in scenes:", descriptionText: `${sceneNames.join("\n")}` };
};

export const getPersonalizationIconsForHeader = (personalizationTypes: string[]): Icon[] => {
    return personalizationTypes.map((pt) => ({
        prefix: PERSONALIZATION_TYPES_MAP[pt].iconPrefix,
        name: PERSONALIZATION_TYPES_MAP[pt].iconName
    }));
};

export const getPersonalizationIconsForDescription = (personalizationTypes: string[]): Icon[] => {
    return personalizationTypes.map((pt) => ({
        prefix: PERSONALIZATION_TYPES_MAP[pt].iconPrefix,
        name: PERSONALIZATION_TYPES_MAP[pt].iconName,
        text: PERSONALIZATION_TYPES_MAP[pt].name
    }));
};

export const getRole = (entity: any, roles: Roles): Role => {
    const { leadId, supporterIds } = roles;
    if (!leadId && getEntityTypeName(entity) === EntityTypeNames.STORY) {
        // set stories as supporters when there's no lead (default view)
        return Role.Support;
    }
    return entity.id === leadId ? Role.Lead : supporterIds.includes(entity.id) ? Role.Support : Role.Extra;
};

// endregion

const compareGoalsByPriority = (goalA: GqlClientGoal, goalB: GqlClientGoal) => goalA.priority - goalB.priority;
const compareEntityByName = (entityA, entityB) => entityA.name.localeCompare(entityB.name);
export const orderEntityCardDataByProperty = (allEntities: { [id: string]: any }, cardData: EntityCardData[], propertyName: string): EntityCardData[] => {
    return cardData.sort((dataA: EntityCardData, dataB: EntityCardData) => {
        return allEntities[dataA.parent.entityId][propertyName] - allEntities[dataB.parent.entityId][propertyName];
    });
};

const transformEntityArray = <T extends { id: string }>(entities: T[]): { [id: string]: T } => {
    return entities.reduce((acc, curr: T) => {
        acc[curr.id] = curr;
        return acc;
    }, {});
};

export const transformProgramVersion = memoize(
    (programVersion: Partial<GqlClientProgramVersion>): Program => {
        if (!programVersion) {
            return {
                stories: {},
                scenes: {},
                goals: {},
                audiences: {},
                channels: {},
                touchpoints: {},
                dataElements: {}
            };
        }

        return {
            ...programVersion,
            stories: transformEntityArray<GqlClientStory>(programVersion.stories),
            scenes: transformEntityArray<GqlClientScene>(programVersion.scenes),
            goals: transformEntityArray<GqlClientGoal>(programVersion.goals),
            audiences: transformEntityArray<GqlClientAudience>(programVersion.audiences),
            channels: transformEntityArray<GqlClientChannel>(programVersion.channels),
            touchpoints: transformEntityArray<GqlClientTouchpoint>(programVersion.touchpoints),
            dataElements: transformEntityArray<GqlClientFeedDataElement>(programVersion.feedDataElements)
        };
    }
);

export const getStories = (program: ProgramEntities): GqlClientStory[] => Object.values(program.stories);
export const getGoals = (program: ProgramEntities): GqlClientGoal[] => Object.values(program.goals).sort(compareGoalsByPriority);
export const getAudiences = (program: ProgramEntities): GqlClientAudience[] => Object.values(program.audiences).sort(compareEntityByName);
export const getTouchpoints = (program: ProgramEntities): GqlClientTouchpoint[] => Object.values(program.touchpoints).sort(compareEntityByName);
export const getChannels = (program: ProgramEntities): GqlClientChannel[] => Object.values(program.channels).sort(compareEntityByName);
export const getScenes = (program: ProgramEntities): GqlClientScene[] => Object.values(program.scenes).sort(compareEntityByName);
export const getDataElements = (program: ProgramEntities): GqlClientFeedDataElement[] => Object.values(program.dataElements).sort(compareEntityByName);

export const getGoalFromKpiId = (kpiId: string, goals: { [id: string]: GqlClientGoal }): GqlClientGoal => Object.values(goals).find(({ kpis }) => kpis.some((kpi) => kpi.id === kpiId));

export const getAudienceFromValueId = (audienceValueId: string, audiences: { [id: string]: GqlClientAudience }): GqlClientAudience =>
    Object.values(audiences).find(({ audienceValues }) => audienceValues.some((val) => val.id === audienceValueId));

// resolves entity from type and id. useful when requesting to edit a kpi or audience value
export const resolveEntityParentFromIdentity = ({ id, type }: EntityIdentifier, program: ProgramEntities) => {
    if (type === EntityTypeNames.KPI) {
        return getGoalFromKpiId(id, program.goals);
    }
    if (type === EntityTypeNames.AUDIENCE_VALUE) {
        return getAudienceFromValueId(id, program.audiences);
    }
    const entities = program[entityTypeToKeyMap[type]];
    return Array.isArray(entities) ? entities.find((entity) => entity.id === id) : entities[id];
};

const getKpiEntity = (kpiId: string, goals: { [id: string]: GqlClientGoal }): GqlClientKpi => {
    const goalsData = Object.values(goals);
    for (const { kpis } of goalsData) {
        const kpi = kpis.find((kpi) => kpi.id === kpiId);
        if (kpi) return kpi;
    }
    return null;
};

const getAudienceValueEntity = (valueId: string, audiences: { [id: string]: GqlClientAudience }): GqlClientAudienceValue => {
    const audiencesData = Object.values(audiences);
    for (const { audienceValues } of audiencesData) {
        const value = audienceValues.find((value) => value.id === valueId);
        if (value) return value;
    }
    return null;
};

export const resolveEntityFromIdentity = ({ id, type }: EntityIdentifier, program: ProgramEntities) => {
    if (type === EntityTypeNames.KPI) {
        return getKpiEntity(id, program.goals);
    }
    else if (type === EntityTypeNames.AUDIENCE_VALUE) {
        return getAudienceValueEntity(id, program.audiences);
    }
    else {
        const entities = program[entityTypeToKeyMap[type]];
        return Array.isArray(entities) ? entities.find((entity) => entity.id === id) : entities[id];
    }
};

// Here we update the entity order array by the actual entities from the db.
// The reason we do it here, is that when we add/remove entities we don't update the program version order columns.
// passing intersectOrder will filter out items that don't appear in it.
export const updateOrderFromEntities = (initialEntityOrder: EntityOrder, entities: { [id: string]: any }, intersectOrder?: EntityOrder): EntityOrder => {
    if (initialEntityOrder && entities) {
        const newOrder: EntityOrder = [...initialEntityOrder];
        const entityIDs: string[] = Object.keys(entities);
        entityIDs.forEach((key) => {
            // Add IDs that don't exist in the order.
            if (!newOrder.includes(key)) {
                newOrder.push(key);
            }
        });

        // Remove from order the IDs that no longer exist and use intersectOrder
        const filterPredicate = intersectOrder ? (id) => entityIDs.includes(id) && intersectOrder.includes(id) : (id) => entityIDs.includes(id);
        return newOrder.filter(filterPredicate);
    }
    return initialEntityOrder;
};

export const memoUpdateScenesOrder = memoize(
    (initialSceneOrder: EntityOrder, scenes: { [id: string]: GqlClientScene }, intersectOrder?: EntityOrder) => {
        return updateOrderFromEntities(initialSceneOrder, scenes, intersectOrder);
    }
);
export const memoUpdateStoriesOrder = memoize(
    (initialStoryOrder: EntityOrder, stories: { [id: string]: GqlClientStory }, intersectOrder?: EntityOrder) => {
        return updateOrderFromEntities(initialStoryOrder, stories, intersectOrder);
    }
);

export const setActiveDraftIdLocalStorage = (draftId: string) => {
    localStorage.setItem(activeDraftLocalStorageId, draftId);
};

export const getActiveDraftIdLocalStorage = (): string => {
    return localStorage.getItem(activeDraftLocalStorageId);
};
