import { createAction } from "redux-actions";
import { reportError, setSoftLoading } from "../common/commonActions";
import { loadProjectSnapshotPromise } from "../projects/projectLifecycle/projectLifecycleActions";
import { loadProjectWireframes } from "../projects/projectWireframes/projectsWireframesActions";
import StateReaderUtils from "../common/StateReaderUtils";
import type { Snapshot } from "../../../common/types/snapshot";

// Actions
export const GENERATE_DIFF_MODEL = "GENERATE_DIFF_MODEL";
export const generateDiffModelAction = createAction(GENERATE_DIFF_MODEL, (programName: string, currentVersion: number | "draft", previousVersion: number): object => ({
    programName,
    currentVersion,
    previousVersion
}));

export const TOGGLE_DIFF_MODEL_BY_ID = "TOGGLE_DIFF_MODEL_BY_ID";
export const toggleDiffModelByIdAction = createAction(TOGGLE_DIFF_MODEL_BY_ID, (id: string): string => id);

export const EXPAND_OR_COLLAPSE_DIFF_MODEL = "EXPAND_OR_COLLAPSE_DIFF_MODEL";
export const expandOrCollapseDiffModelAction = createAction(EXPAND_OR_COLLAPSE_DIFF_MODEL, (expandAll: boolean): boolean => expandAll);

export const CLEAR_DIFF_MODEL = "CLEAR_DIFF_MODEL";
export const clearDiffModelAction = createAction(CLEAR_DIFF_MODEL);

// Thunks
export const generateDiffModel = (accountId: string, programName: string, currentVersion: number | "draft", previousVersion: number, failureCb?: () => void): any => {
    return async (dispatch, getState, services) => {
        dispatch(setSoftLoading(true));

        try {
            // load snapshots
            const state = getState();
            const currentSnapshot: Snapshot = StateReaderUtils.getSnapshotByNumber(state, programName, currentVersion);
            const previousSnapshot: Snapshot = StateReaderUtils.getSnapshotByNumber(state, programName, previousVersion);

            const loadSnapshotPromises: any[] = [];
            if (currentVersion !== "draft" && (!currentSnapshot || !currentSnapshot.assets)) {
                loadSnapshotPromises.push(loadProjectSnapshotPromise(services, state, dispatch, accountId, programName, currentVersion));
            }
            if (!previousSnapshot || !previousSnapshot.assets) {
                loadSnapshotPromises.push(loadProjectSnapshotPromise(services, state, dispatch, accountId, programName, previousVersion));
            }
            await Promise.all(loadSnapshotPromises);

            // load wireframes
            const currentStage = currentVersion === "draft" ? undefined : "diff";
            const currentVersionStr = currentVersion === "draft" ? undefined : currentVersion.toString();

            const loadDiffDataPromises: Promise<void>[] = [
                dispatch(loadProjectWireframes(accountId, programName, currentStage, currentVersionStr, false, false)),
                dispatch(loadProjectWireframes(accountId, programName, "diff", previousVersion.toString(), false, false))
            ];

            await Promise.all(loadDiffDataPromises);

            dispatch(generateDiffModelAction(programName, currentVersion, previousVersion));
        }
        catch (err) {
            dispatch(reportError(err));
            if (typeof failureCb === "function") {
                failureCb();
            }
        }
        finally {
            dispatch(setSoftLoading(false));
        }
    };
};

export const toggleDiffModelById = (id: string) => {
    return (dispatch): void => {
        dispatch(toggleDiffModelByIdAction(id));
    };
};

export const expandOrCollapseDiffModel = (expandAll: boolean) => {
    return (dispatch): void => {
        dispatch(expandOrCollapseDiffModelAction(expandAll));
    };
};
