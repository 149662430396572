import { convertLogicTypeToDataElementType } from "../vlx/editorLogicUtils";
import { v4 as uuid } from "uuid";
import { getValueSet } from "./ValueSetUtils";
import type { LogicJSON, Rule } from "../../../common/types/logic";
import { LogicType } from "../../../common/types/logic";
import type { Story } from "../../../common/types/story";

export type Context = {
    derivedLogic?: any;
    mappingTables?: any[];
    dataElements?: any[];
    stories?: { [storyId: string]: Story };
};

export function parseLogicObject(logic: LogicJSON) {
    return createLogic(logic);
}

export function createNewStorySelectionLogic() {
    return createLogic({ outputType: LogicType.Story, defaultValue: null, defaultValueShow: true, rules: [] });
}

export function createNewDecisionPointLogic() {
    return createLogic({ outputType: LogicType.NextScene, defaultValue: null, isLast: true, rules: [] });
}

export function createNewSoundtrackLogic() {
    return createLogic({ outputType: LogicType.Audio, defaultValue: null, defaultValueShow: false, rules: [] });
}

export function createNewBackgroundVideoLogic() {
    return createLogic({ outputType: LogicType.Video, defaultValue: null, defaultValueShow: false, rules: [] });
}

export function createNewBackgroundImageLogic() {
    return createLogic({ outputType: LogicType.Image, defaultValue: null, defaultValueShow: false, rules: [] });
}

export function createLogic(logicObj: LogicJSON) {
    let outputType = logicObj.outputType;
    let defaultValue = logicObj.defaultValue;
    let defaultValueShow = logicObj.defaultValueShow;
    let last = logicObj.isLast;
    let rules = logicObj.rules || [];

    return {
        getOutputType: function() {
            return outputType;
        },
        getDefaultValue: function() {
            return defaultValue;
        },
        isDefaultValueShow: function() {
            return this.defaultValueShow;
        },
        isLast: function() {
            return last;
        },
        isRuleExists: function(ruleKey: string) {
            return rules.some((rule) => rule.key === ruleKey);
        },
        getValueSet: (context?: Context) => {
            return getValueSet(logicObj, convertLogicTypeToDataElementType(outputType), context);
        },
        getRules: function() {
            return rules;
        },
        getRule: function(ruleKey: string) {
            return rules.find((rule) => rule.key === ruleKey);
        },
        /**
         * Gets all logic values (including default value)
         * @return {any[]} array of values
         */
        getValues: function() {
            let values = rules.map((rule: Rule) => rule.value);
            values.push(defaultValue);
            return values;
        },
        /**
         * Gets all logic pairs of rule keys and values (including default value)
         * @return {any[]} array of object containing rule.key and value
         */
        getAllKeysAndValues: function(): Rule[] {
            let keysAndValues = rules.map((rule: Rule) => {
                return { ...rule };
            });
            keysAndValues.push({ key: undefined, isLast: last, show: defaultValueShow, value: defaultValue, whens: [] });
            return keysAndValues;
        },
        setOutputType: function(outputTypeArg) {
            outputType = outputTypeArg;
        },
        setDefaultValue: function(defaultValueArg) {
            defaultValue = defaultValueArg;
        },
        setDefaultValueShow: function(defaultValueShowArg) {
            defaultValueShow = defaultValueShowArg;
        },
        setLast: function(lastArg) {
            last = lastArg;
        },
        setRules: function(rulesArg) {
            rules = rulesArg;
        },
        addRuleWithValue: function(valueArg) {
            let newRules = [...rules];
            newRules.push({ key: uuid(), whens: [], value: valueArg });
            rules = newRules;
        },
        updateRule: function(ruleId, ruleData) {},
        updateRuleValue: function(ruleKey, ruleNewValue) {
            let ruleIndex = rules.findIndex((rule) => {
                return rule.key === ruleKey;
            });
            if (ruleIndex > -1) {
                let newRule = { ...rules[ruleIndex] };
                newRule.value = ruleNewValue;
                let newRules = [...rules];
                newRules.splice(ruleIndex, 1, newRule);
                rules = newRules;
            }
        },
        removeRule: function(ruleKey) {
            let ruleIndex = rules.findIndex((rule) => {
                return rule.key === ruleKey;
            });
            if (ruleIndex > -1) {
                let newRules = [...rules];
                newRules.splice(ruleIndex, 1);
                rules = newRules;
            }
        },
        serializeLogicData: function(): LogicJSON {
            return {
                outputType: outputType,
                rules: rules,
                defaultValueShow: defaultValueShow,
                defaultValue: defaultValue,
                isLast: last
            };
        }
    };
}

/**
 * Gets the values from all the possible cases + default value
 * @param {LogicJSON} logicObj a logic object
 */
export function getAllValues(logicObj: LogicJSON) {
    let logic = parseLogicObject(logicObj);

    let values = logic.getRules().map((rule: Rule) => rule.value);
    values.push(logic.getDefaultValue());

    return values;
}

export function isLogicEmpty(logicObject: LogicJSON, isOptionalDefaultValueFlag?: boolean): boolean {
    // TODO this function does not cover compoundValues
    if (!logicObject) return true;

    const noDefaultValue = logicObject.defaultValue === undefined || logicObject.defaultValue === null || (Array.isArray(logicObject.defaultValue) && logicObject.defaultValue.length === 0);

    if (isOptionalDefaultValueFlag) {
        return (!logicObject.rules && noDefaultValue && logicObject.defaultValueShow) || (logicObject.rules && logicObject.rules.length === 0 && noDefaultValue && logicObject.defaultValueShow);
    }

    return (!logicObject.rules && noDefaultValue) || (logicObject.rules && logicObject.rules.length === 0 && noDefaultValue);
}
