import BaseServices from "./BaseServices";
import JSZip from "jszip";
import type { Links, VideoStream } from "../../../common/external/videoSpecResponse";
import { MediaType } from "../../../common/external/videoSpecResponse";
import { EDITING_MODE, STAGE_MODE } from "./Consts";

export type StatusResponse = "finished" | "error" | "in progress";

export type FileResponse = Blob | String;

class DownloadUtils extends BaseServices {
    static downloadFileFromUrl(url, name) {
        let element = document.createElement("a");
        element.href = url;
        element.setAttribute("download", name);
        element.setAttribute("target", "_blank");
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    //I use this approach since in some cases download attribute does not set the name right.
    downloadFileFromContent(url, name, contentType) {
        this.get([EDITING_MODE, STAGE_MODE], url, { "Content-Type": contentType }).then(function(content) {
            if (contentType === "application/json" && content instanceof Object) {
                content = JSON.stringify(content);
            }

            let element = document.createElement("a");
            element.setAttribute("href", "data:" + contentType + ";charset=utf-8," + encodeURIComponent(content as string));
            element.setAttribute("download", name);
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        });
    }

    createAndDownloadFile(name, content, contentType) {
        if (contentType === "application/json" && content instanceof Object) {
            content = JSON.stringify(content, null, "    ");
        }

        let element = document.createElement("a");
        element.setAttribute("href", "data:" + contentType + ";charset=utf-8," + encodeURIComponent(content));
        element.setAttribute("download", name);
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    createAndDownloadZipFile(filesNamesAndContentArray, zipName?: string) {
        const zip = new JSZip();
        // create a file and a folder
        filesNamesAndContentArray.forEach((fileInfoObj) => {
            if (fileInfoObj) {
                zip.file(fileInfoObj.fileName, fileInfoObj.fileContent);
            }
        });
        if (JSZip.support.uint8array) {
            zip.generateAsync({ type: "base64" }).then(function(base64) {
                let d = new Date();
                let element = document.createElement("a");
                element.setAttribute("href", "data:application/zip;base64," + base64);
                element.setAttribute("download", (zipName || `DataTables-${d.toISOString()}`) + ".zip");
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
            });
        }
    }

    static createAndDownloadBlobFile(name, content, contentType) {
        var a = document.createElement("a");
        document.body.appendChild(a);
        var file = new Blob([content], { type: contentType });
        a.href = window.URL.createObjectURL(file);
        a.download = name;
        a.click();
        document.body.removeChild(a);
    }

    /**
     * This method is used to download video content with name.
     * @param url video url
     * @param name desired file name including file type. e.g 'video.mp4'
     */
    downloadVideoFile = (url, name): Promise<StatusResponse> => {
        return new Promise((resolve, reject) => {
            let NumberOfAttempts = 2;
            run(url, name);
            function run(url, name) {
                window.URL = window.URL || (window as any).webkitURL;
                var xhr = new XMLHttpRequest();
                var a = document.createElement("a");
                xhr.open("GET", url, true);
                xhr.responseType = "blob";
                xhr.onload = function() {
                    let status = xhr.status;
                    if (status === 200) {
                        document.body.appendChild(a);
                        var file = new Blob([xhr.response], { type: "application/octet-stream" });
                        a.href = window.URL.createObjectURL(file);
                        a.download = name;
                        a.click();
                        document.body.removeChild(a);
                        resolve("finished");
                    }
                    //Another Try to download in case we get a timeout.
                    else if (status === 504 && --NumberOfAttempts) {
                        run(url, name);
                    }
                    else {
                        reject("error");
                    }
                };
                //Another Try to download.
                xhr.onerror = function() {
                    if (--NumberOfAttempts) {
                        run(url, name);
                    }
                    else {
                        reject("error");
                    }
                };
                xhr.send();
            }
        });
    };

    /**
     * This method is used to get a file from a url.
     * @param url video url
     * @param type file type. e.g 'application/octet-stream' or 'text/vtt'
     */
    getFileFromUrl = (url, type: string): Promise<FileResponse> => {
        return new Promise((resolve, reject) => {
            let NumberOfAttempts = 2;
            run(url, type);
            function run(url, type) {
                window.URL = window.URL || (window as any).webkitURL;
                var xhr = new XMLHttpRequest();
                xhr.open("GET", url, true);
                xhr.responseType = "blob";
                xhr.onload = function() {
                    let status = xhr.status;
                    if (status === 200) {
                        var file = new Blob([xhr.response], { type });
                        resolve(file);
                    }
                    //Another Try to download in case we get a timeout.
                    else if (status === 504 && --NumberOfAttempts) {
                        run(url, type);
                    }
                    else {
                        reject("error in getting file from url: " + url);
                    }
                };
                //Another Try to download.
                xhr.onerror = function() {
                    if (--NumberOfAttempts) {
                        run(url, type);
                    }
                    else {
                        reject("error in getting file from url: " + url);
                    }
                };
                xhr.send();
            }
        });
    };

    /**
     * This method is used to download mp4 video content with name from raas response.
     * @param links _link object from raas response
     * @param videoName the desired video file name
     */
    downloadMp4Video = (links: Links, videoName): Promise<StatusResponse> => {
        return new Promise((resolve, reject) => {
            let response: StatusResponse = "error";
            let videoStream;
            if (links && Array.isArray(links["video-stream"])) {
                links["video-stream"].forEach((stream) => {
                    if (stream.id === "mp4" || stream.format === MediaType.Mp4 || stream["media-type"] === MediaType.Mp4) {
                        videoStream = stream;
                    }
                });
            }
            else if (links && typeof links["video-stream"] === "object" && links["video-stream"]["media-type"] === MediaType.Mp4) {
                videoStream = links["video-stream"];
            }
            if (videoStream) {
                this.downloadVideoFile(videoStream.href + "?download=true", this.generateTitle(videoStream, videoName))
                    .then((res) => {
                        response = res;
                        resolve(response);
                    })
                    .catch((err) => {
                        response = err;
                        reject(response);
                    });
            }
            else {
                reject(response);
            }
        });
    };

    /**
     * This method is used to generate video title with time stamp.
     * @param videoStream object from raas response
     * @param videoName - the desired video file name
     */
    generateTitle(videoStream: VideoStream, videoName: string): string {
        let d = new Date();
        let videoMediaType = videoStream["media-type"] || videoStream["format"];
        let mediaType = videoMediaType.substring(videoMediaType.indexOf("/") + 1);
        return (videoName || "temp") + "-" + d.toISOString() + "." + mediaType;
    }

    getUserStatusByRaasStatus(raasStatus: StatusResponse) {
        switch (raasStatus) {
            case "finished":
                return "finished";
            case "error":
                return "error";
            default:
                return "in progress";
        }
    }
}

export default DownloadUtils;
