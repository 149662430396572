import { EntityTypeNames } from "./types";
import type { IconName, IconPrefix } from "@fortawesome/fontawesome-common-types";

export const INDUSTRIES: string[] = [
    "Classified",
    "E-Commerce",
    "Financial Services - Retail Banking",
    "Financial Services - Credit Cards",
    "Financial Services - Other",
    "Health Care",
    "Insurance",
    "Management Consulting",
    "Media",
    "Real Estate",
    "Retail",
    "Technology",
    "Telecommunications",
    "Travel",
    "Utilities",
    "Other"
];

export const USE_CASES: string[] = [
    "Awareness",
    "Consideration",
    "Acquisition",
    "Welcome and Onboarding",
    "Cross-sell Recommendations",
    "Loyalty Program Engagement",
    "Digital Engagement",
    "Education",
    "Customer Care",
    "Bills & Statements"
];

export const KPIS: { [goal: string]: string[] } = {
    "Increase Brand awareness": ["Video completion rate", "GRP", "Awareness Lift", "Brand Recall Lift"],
    "Improve Brand Favorability": ["Brand Perception Study"],
    "Launch a new product": ["Recall Study", "Site Visits", "Store Traffic", "Online Sales", "Offline Sales", "Video completion rate"],
    "Increase Purchase Consideration": ["View-Through Rate", "Consideration Lift Study", "Add to cart", "Site Visits", "Store traffic"],
    "Convert abandoned carts": ["Sales (online and offline)", "Cost per Acquisition"],
    "Increase purchase size": ["Sales (online and offline)", "Cost per Acquisition"],
    "Cross-sell products": ["Sales (online and offline)", "Cost per Acquisition"],
    "Cost Reduction": ["Deflect Calls", "Reactivate Dormant Customers", "Payments/Payees"],
    "Digital Adoption": ["Download Mobile App", "Paperless Enrollment", "Auto-Pay Adoption", "Mobile App Usage"],
    "Customer Engagement": ["Net Promoter Score", "Rewards/Loyalty Participation", "Enroll in Loyalty Program"],
    Retention: ["Reduce Churn", "Retain Movers", "Increase Renewals"],
    "Direct Response": ["Increase Product Engagement", "Enrollment in loyalty program"],
    Efficiency: ["Time to market", "Resolution time"],
    Loyalty: ["Membership Sign Up", "Rewards Program Engagement", "ROAS"]
};

export const GOALS: string[] = Object.keys(KPIS);

export const GOAL_PRIORITIES: number[] = [1, 2, 3];

export const CHANNELS: string[] = ["Programmatic Exchange", "Facebook", "Youtube", "App", "Email", "Portal", 
    "Set top box", "SMS", "Website", "Contact Center", "IVR", "Chatbot", 
    "QR code", "e-G1 system", "MS1 Brain system"];

export const PLATFORMS: string[] = ["Mobile", "Desktop"];

export const STATUSES: string[] = [
    "Pending approval", // Note: this is the dropdown default value, consult with Product before changing order
    "Approved",
    "Rejected",
    "Missing"
];

export const CHANNEL_STATUSES: string[] = [
    "Pending approval", // Note: this is the dropdown default value, consult with Product before changing order
    "Approved",
    "Rejected"
];

export const AUDIENCE_ATTRIBUTES: string[] = [
    "Product Type",
    "Brand Category",
    "Mobile App Downloaded",
    "Portal Enrolled",
    "Loyalty Program Enrolled",
    "Card Type",
    "Page Visited",
    "Upgrade Eligible",
    "Product Category"
];

export const TOUCHPOINTS: string[] = ["Order Confirmation", "24 hours post - purchase", "30 days after purchase", "First login"];

enum PersonalizationTypeID {
    LookAndFeel = "Look and Feel (Images, styles, colors, animations)",
    Messaging = "Messaging (On screen text or narration)",
    Validation = "Validation (Logic for scene inclusion or exclusion)"
}

type PERSONALIZATION_MAP = { [key in PersonalizationTypeID]: PersonalizationTypeData };

export const PERSONALIZATION_TYPES_MAP: PERSONALIZATION_MAP = Object.freeze({
    [PersonalizationTypeID.LookAndFeel]: {
        name: "Video, image & aspect ratio",
        iconPrefix: "far",
        iconName: "paintbrush",
        id: PersonalizationTypeID.LookAndFeel
    },
    [PersonalizationTypeID.Messaging]: {
        name: "On-screen text & narrations",
        iconPrefix: "far",
        iconName: "comment-alt-lines",
        id: PersonalizationTypeID.Messaging
    },
    [PersonalizationTypeID.Validation]: {
        name: "Validation",
        iconPrefix: "far",
        iconName: "check-circle",
        id: PersonalizationTypeID.Validation
    }
});

type PersonalizationTypeData = {
    iconPrefix: IconPrefix;
    iconName: IconName;
    name: string;
    id: PersonalizationTypeID;
};

export const ATTRIBUTE_TYPES: EntityTypeNames[] = [EntityTypeNames.AUDIENCE, EntityTypeNames.TOUCHPOINT, EntityTypeNames.CHANNEL];

export enum ColorPalette {
    // entities
    OrangeStory = "#FF7043",
    YellowScene = "#FFAB00",
    TealAudienceAttribute = "#26A69A",
    PinkGoal = "#EC407A",
    PurpleChannel = "#7E57C2",
    GreenTouchpoint = "#4CAF50",
    GrayDataElement = "#90A4AE",

    // icons
    BlueIcon = "#2979FF",
    GrayIcon = "#666666",
    GrayExtraIcon = "#999999",

    // approve & reject
    RedRejected = "#FF3333",
    GreenApproved = "#339900",

    // other
    BlackHeaderText = "#333333",
    WhiteBackground = "#FFFFFF",
    LivePreviewPanelBackground = "#e5e5e5",
    LivePreviewBackground = "#c4c4c4",
    GrayExtraBackground = "#DDDDDD",
    GrayExtraBorder = "#B4B4B4",
    GrayDescriptionText = "#666666",
}

export const ENTITY_COLOR_MAPPER = {
    [EntityTypeNames.AUDIENCE]: ColorPalette.TealAudienceAttribute,
    [EntityTypeNames.AUDIENCE_VALUE]: ColorPalette.TealAudienceAttribute,
    [EntityTypeNames.CHANNEL]: ColorPalette.PurpleChannel,
    [EntityTypeNames.DATA_ELEMENT]: ColorPalette.GrayDataElement,
    [EntityTypeNames.GOAL]: ColorPalette.PinkGoal,
    [EntityTypeNames.KPI]: ColorPalette.PinkGoal,
    [EntityTypeNames.SCENE]: ColorPalette.YellowScene,
    [EntityTypeNames.STORY]: ColorPalette.OrangeStory,
    [EntityTypeNames.TOUCHPOINT]: ColorPalette.GreenTouchpoint
};

export const entityTypeToKeyMap = {
    [EntityTypeNames.GOAL]: "goals",
    [EntityTypeNames.SCENE]: "scenes",
    [EntityTypeNames.DATA_ELEMENT]: "dataElements",
    [EntityTypeNames.STORY]: "stories",
    [EntityTypeNames.CHANNEL]: "channels",
    [EntityTypeNames.TOUCHPOINT]: "touchpoints",
    [EntityTypeNames.AUDIENCE]: "audiences"
};

export const entityTypeDescription = {
    [EntityTypeNames.GOAL]: "Why: Desired audience behavior change that is measurable against key performance indicators to determine incremental business value",
    [EntityTypeNames.SCENE]: "What: The content and messages that make up your stories",
    [EntityTypeNames.DATA_ELEMENT]: "How: Information that powers audience attributes, story, and scene individualization",
    [EntityTypeNames.STORY]: "What: The overarching message conveyed to the Audience, made up of relevant topics that support your Goals",
    [EntityTypeNames.CHANNEL]: "Where and When: Delivery channel(s) and touch point(s) where and when the Story is delivered and served to the Audience",
    [EntityTypeNames.TOUCHPOINT]: "Where and When: Delivery channel(s) and touch point(s) where and when the Story is delivered and served to the Audience",
    [EntityTypeNames.AUDIENCE]: "Who: Characteristics of the audience that define segmentation and individualize stories and scenes"
};
