import BaseService from "./BaseServices";
import { EDITING_MODE, STAGE_MODE } from "./Consts";
import type { LogReportContent } from "../../../common/types/commonTypes";

class CommonServices extends BaseService {
    constructor(authService, builderModeRetriever) {
        super(authService, builderModeRetriever);
    }
    reportLog(data: LogReportContent) {
        return this.post<LogReportContent>([EDITING_MODE, STAGE_MODE], "/rest/log", data);
    }
}

export default CommonServices;
