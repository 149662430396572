import type { List, OrderedMap } from "immutable";
import type {
    AnyEntitiesByContextById,
    AnyEntity,
    AnyEntityContextPair,
    GetDependencyIdFunction
} from "./baseEntity";
import {
    BaseEntity
} from "./baseEntity";
import type {
    GetValidationResultFunction,
    Issue,
    ValidationResult
} from "../validations/validationManager";
import ValidationManager, {
    IssueCodes,
    IssueSeverity
} from "../validations/validationManager";
import type { Collector, CollectorArgs, Context, EntityInstance, IEntity } from "./definitions";
import { EntityTypes } from "./definitions";
import StateReaderUtils from "../common/StateReaderUtils";
import { DATA_ELEMENT_ORIGIN, DATA_ELEMENT_STATUS } from "../../components/legacyCommon/Consts";
import type { DiffResult } from "../versionDiff/diffManager";
import { ChangeType } from "../versionDiff/diffManager";
import type { NarrationPart } from "../../../common/types/scene";
import { diffEntities } from "../versionDiff/diffUtils";
import type {
    CreativeDataElementsUsedInNarrationsOverrides } from "../vlx/editorLogicUtils";
import {
    getCreativeDataElementsUsedInNarrationsOverrides,
    getNarrationPartInvalidCreativeOverrides
} from "../vlx/editorLogicUtils";
import AssetUtils from "../common/assetUtils";

type NarrationDimensions = List<AnyEntity>;

type NarrationVariations = OrderedMap<string, AnyEntity>;

export type DoDiffParams = {
    currentNarrationPart: AnyEntity;
    previousNarrationPart: AnyEntity;
    currentWireframe: AnyEntity;
    previousWireframe: AnyEntity;
    narrationId: string;
    narrationName: string;
};

interface INarrationValidationResult extends ValidationResult {
    dimensions: any;
    variations: any;
    dataElementIdsInUse: any;
    invalidNarrationCreativeDataElementIds: string[];
}

export default class NarrationEntity extends BaseEntity implements IEntity {
    constructor() {
        super(EntityTypes.NARRATION);
    }

    getEntityId = (narration: NarrationPart) => AssetUtils.getCrossVersionId(narration.id);

    collector: Collector = (args: CollectorArgs): EntityInstance[] => {
        const { context, previousContext } = args;
        let narrations: EntityInstance[] = [];

        const previousWireframe = previousContext && StateReaderUtils.getWireFrame(previousContext.state, previousContext.projectName, previousContext.stage, previousContext.version);
        const currentWireframe = StateReaderUtils.getWireFrame(context.state, context.projectName, context.stage, context.version);
        const narrationCDEsUsage: CreativeDataElementsUsedInNarrationsOverrides = getCreativeDataElementsUsedInNarrationsOverrides(currentWireframe);

        const previousNarrationParts: NarrationPart[] = StateReaderUtils.getProjectNarrationParts(previousWireframe);
        const currentNarrationParts: NarrationPart[] = StateReaderUtils.getProjectNarrationParts(currentWireframe);

        const narrationsVersion: number = StateReaderUtils.getNarrationsVersion(currentWireframe);

        if (narrationsVersion > 0) {
            const narrationsMap: AnyEntitiesByContextById = this.entityListsToMap(previousNarrationParts, currentNarrationParts, this.getEntityId);
            narrationsMap.forEach((narrationsPair: AnyEntityContextPair, narrationId: string) => {
                const { currentEntity: currentNarrationPart, previousEntity: previousNarrationPart } = narrationsPair;
                const narrationName: string = this.getNarrationName(currentNarrationPart, previousNarrationPart, currentWireframe, previousWireframe);
                if (!previousContext) {
                    // Validation case
                    if (currentNarrationPart.id) {
                        narrations.push({
                            id: narrationId,
                            validate: (prevValidationResult: INarrationValidationResult, getValidationResultFunction: GetValidationResultFunction): ValidationResult => {
                                return this.doValidate(currentNarrationPart.id, narrationName, prevValidationResult, getValidationResultFunction, context, narrationCDEsUsage);
                            }
                        });
                    }
                }
                else {
                    // Diff case
                    narrations.push({
                        id: narrationId,
                        diff: (): DiffResult => {
                            return this.doDiff({
                                currentNarrationPart,
                                previousNarrationPart,
                                currentWireframe,
                                previousWireframe,
                                narrationId,
                                narrationName
                            });
                        }
                    });
                }
            });
        }

        return narrations;
    };

    private getNarrationName = (currentNarrationPart: AnyEntity, previousNarrationPart: AnyEntity, currentWireframe: AnyEntity, previousWireframe: AnyEntity): string => {
        const wireframe = currentNarrationPart ? currentWireframe : previousWireframe;
        const narrationPart = currentNarrationPart ? currentNarrationPart : previousNarrationPart;
        return StateReaderUtils.getNarrationLetterInScene(wireframe.scenes[narrationPart.sceneId], narrationPart.id);
    };

    doValidate = (
        narrationId: string,
        narrationName: string,
        prevValidationResult: INarrationValidationResult,
        getValidationResultFunction: GetValidationResultFunction,
        context: Context,
        narrationCDEsUsage: CreativeDataElementsUsedInNarrationsOverrides
    ): INarrationValidationResult => {
        const type: EntityTypes = this.getType();
        let wireframes = StateReaderUtils.getWireFrame(context.state, context.projectName, context.stage, context.version);
        if (wireframes.narrations) {
            let dimensions = StateReaderUtils.getNarrationTableDimensions(wireframes, narrationId);
            let variations = StateReaderUtils.getNarrationTableVariations(wireframes, narrationId);
            let dataElementIdsInUse = StateReaderUtils.getNarrationTableDataElements(wireframes, narrationId);
            let allDataElements = StateReaderUtils.getProjectDataElements(context.state, context.projectName, context.stage, context.version);
            let invalidNarrationCreativeDataElementIds: string[] = getNarrationPartInvalidCreativeOverrides(narrationCDEsUsage, narrationId);

            if (
                prevValidationResult &&
                prevValidationResult.dimensions === dimensions &&
                prevValidationResult.variations === variations &&
                prevValidationResult.dataElementIdsInUse === dataElementIdsInUse &&
                prevValidationResult.invalidNarrationCreativeDataElementIds.join() === invalidNarrationCreativeDataElementIds.join()
            ) {
                return prevValidationResult;
            }

            let issues = this.detectIssues(narrationId, getValidationResultFunction, dimensions, variations, dataElementIdsInUse, allDataElements, invalidNarrationCreativeDataElementIds);
            let severity: IssueSeverity = ValidationManager.getHighestSeverity(issues.map((issue) => issue.severity));

            return {
                type,
                id: narrationId,
                name: narrationName,
                issues,
                severity,
                dimensions,
                variations,
                dataElementIdsInUse,
                invalidNarrationCreativeDataElementIds
            };
        }

        return {
            type,
            id: narrationId,
            issues: [],
            dimensions: null,
            variations: null,
            dataElementIdsInUse: null,
            invalidNarrationCreativeDataElementIds: []
        };
    };

    private detectIssues = (
        narrationId: string,
        getValidationResultFunction: GetValidationResultFunction,
        dimensions: any,
        variations: any,
        dataElementIdsInUse: any,
        allDataElements: any,
        invalidNarrationCreativeDataElementIds: string[]
    ): Issue[] => {
        let issues: Issue[] = [];

        const orphanedCount: number = variations.count((variation) => variation.orphaned);
        const orphanedDimensions: any = dimensions.filter((dimension) => dimension.orphaned);
        const emptyLineCount: number = variations.count((variation) => !variation.variationData.muted && !variation.variationData.text && !variation.variationData.overrideId);

        if (orphanedDimensions.size > 0) {
            issues.push({
                code: IssueCodes.NARRATION_ORPHANED_DIMENSIONS,
                severity: IssueSeverity.ERROR,
                details: { id: narrationId, orphanedDimensions }
            });
        }

        if (orphanedCount > 0) {
            issues.push({
                code: IssueCodes.NARRATION_ORPHANED_VARIATIONS,
                severity: IssueSeverity.ERROR,
                details: { id: narrationId, orphanedCount }
            });
        }

        if (emptyLineCount > 0) {
            issues.push({
                code: IssueCodes.NARRATION_EMPTY_LINES,
                severity: IssueSeverity.WARNING,
                details: { id: narrationId, emptyLineCount }
            });
        }

        if (invalidNarrationCreativeDataElementIds && invalidNarrationCreativeDataElementIds.length > 0) {
            issues.push({
                code: IssueCodes.NARRATION_CREATIVE_OVERRIDE_ERROR,
                severity: IssueSeverity.ERROR,
                details: { id: narrationId, deIds: invalidNarrationCreativeDataElementIds }
            });
        }

        if (dataElementIdsInUse.size > 0) {
            dataElementIdsInUse = dataElementIdsInUse.toArray();
            let dataElementObjInUse = allDataElements.filter((de) => de.origin === DATA_ELEMENT_ORIGIN.FEED && dataElementIdsInUse.some((deID) => de.id === deID));
            let pendingDataElementObjInUse = dataElementObjInUse.filter((de) => de.status === DATA_ELEMENT_STATUS.MISSING || de.status === DATA_ELEMENT_STATUS.PENDING);
            let derivedDataElementObjInUse = allDataElements.filter((de) => de.origin === DATA_ELEMENT_ORIGIN.DERIVED && dataElementIdsInUse.some((deID) => de.id === deID));
            const getDependencyId: GetDependencyIdFunction = (dataElement) => dataElement.id;
            let dataElementIssue: Issue = this.getDependenciesIssue(
                dataElementObjInUse,
                EntityTypes.DATA_ELEMENT,
                getDependencyId,
                getValidationResultFunction,
                IssueCodes.NARRATION_DATA_ELEMENTS_ERROR
            );
            let derivedDataElementIssue: Issue = this.getDependenciesIssue(
                derivedDataElementObjInUse,
                EntityTypes.DERIVED_DATA_ELEMENT,
                getDependencyId,
                getValidationResultFunction,
                IssueCodes.NARRATION_DERIVED_DATA_ELEMENTS_ERROR
            );

            if (dataElementIssue) {
                let names = dataElementIssue.children.map((childIssue) => allDataElements.find((de) => childIssue.id === de.id).displayName);
                dataElementIssue.details = { names };
                issues.push(dataElementIssue);
            }

            if (derivedDataElementIssue) {
                let names = derivedDataElementIssue.children.map((childIssue) => allDataElements.find((de) => childIssue.id === de.id).displayName);
                derivedDataElementIssue.details = { names };
                issues.push(derivedDataElementIssue);
            }

            if (pendingDataElementObjInUse && pendingDataElementObjInUse.length > 0) {
                let names = pendingDataElementObjInUse.map((deInUse) => deInUse.displayName);
                issues.push({
                    code: IssueCodes.NARRATION_DATA_ELEMENTS_ERROR,
                    severity: IssueSeverity.WARNING,
                    details: { names }
                });
            }
        }

        return issues;
    };

    doDiff = ({ currentNarrationPart, previousNarrationPart, currentWireframe, previousWireframe, narrationId, narrationName }: DoDiffParams): DiffResult => {
        // using diffEntities() will compare the narrations parts as object literals (if both exist), which is incorrect
        // For narrations we need to compare their dimensions and variations to decide if they are equal

        let changeType: ChangeType;

        if (!previousNarrationPart && currentNarrationPart) {
            changeType = ChangeType.Add;
        }
        else if (previousNarrationPart && !currentNarrationPart) {
            changeType = ChangeType.Delete;
        }
        else {
            changeType = this.compareNarrationsData(currentNarrationPart, previousNarrationPart, currentWireframe, previousWireframe);
        }

        return {
            id: narrationId,
            name: narrationName,
            type: this.getType(),
            changeType,
            changes: [],
            isShown: changeType !== ChangeType.None
        };
    };

    private compareNarrationsData = (currentNarrationPart: AnyEntity, previousNarrationPart: AnyEntity, currentWireframe: AnyEntity, previousWireframe: AnyEntity): ChangeType => {
        const previousNarrationPartDimensions: NarrationDimensions = StateReaderUtils.getNarrationTableDimensions(previousWireframe, previousNarrationPart.id);
        const currentNarrationPartDimensions: NarrationDimensions = StateReaderUtils.getNarrationTableDimensions(currentWireframe, currentNarrationPart.id);
        if (diffEntities(previousNarrationPartDimensions.toJS(), currentNarrationPartDimensions.toJS()) === ChangeType.Edit) {
            // If dimensions aren't equal, no need to check variations
            return ChangeType.Edit;
        }

        const previousNarrationPartVariations: NarrationVariations = StateReaderUtils.getNarrationTableVariations(previousWireframe, previousNarrationPart.id);
        const currentNarrationPartVariations: NarrationVariations = StateReaderUtils.getNarrationTableVariations(currentWireframe, currentNarrationPart.id);
        return diffEntities(previousNarrationPartVariations.toJS(), currentNarrationPartVariations.toJS());
    };
}
