import type {
    GetProgramPresetsQueryResult,
    GetProgramPresetsWithoutValuesQueryResult,
    GetProgramPresetValuesQueryResult,
    GqlClientPresetDataElementValue,
    GqlClientPresetSystemElementValue
} from "../../graphql/graphqlGeneratedTypes/graphqlClient";
import type { Preset, PresetData, PresetMap } from "../../../common/types/preset";
import { getIdFromGqlId } from "./convertGqlEntityToWireframesUtils";
import { CREATIVES_PRESET_ID } from "./Consts";


export const convertGqlPresetsResult = (gqlResult: GetProgramPresetsQueryResult): PresetMap => {
    const gqlPresets = gqlResult?.data?.program?.programVersion?.presets;
    return gqlPresets?.reduce((acc, preset) => {
        const presetId: string = getIdFromGqlId(preset.id);
        let presetData = {};
        if (!preset.isCreative) {
            presetData = [...preset.systemElementValues, ...preset.dataElementValues].reduce((data, dataElement) => {
                const elementId: string = (dataElement as GqlClientPresetSystemElementValue)?.systemElementId || getIdFromGqlId((dataElement as GqlClientPresetDataElementValue)?.id);
                data[elementId] = {
                    id: elementId,
                    type: dataElement?.type,
                    value: parseDataElementValue(dataElement)
                };
                if (dataElement?.valueId !== null && dataElement?.valueId !== undefined) {
                    data[elementId].valueId = dataElement?.valueId;
                }
                return data;
            }, {});

            acc[presetId] = {
                id: presetId,
                name: preset.name,
                data: presetData,
                graphQLId: preset.id,
                graphQLUpdated: preset.updated

            };
        }
        else {
            const creativePresetData = preset.dataElementValues?.reduce((data, dataElement) => {
                const dataElementId: string = getIdFromGqlId(dataElement?.id);
                data[dataElementId] = parseDataElementValue(dataElement);
                return data;
            }, {});

            acc[CREATIVES_PRESET_ID] = {
                id: CREATIVES_PRESET_ID,
                name: "Creatives Preset",
                data: creativePresetData,
                graphQLId: preset.id,
                graphQLUpdated: preset.updated
            };
        }
        return acc;
    }, {});
};

const parseDataElementValue = (dataElement) => {
    return (dataElement?.value !== null && dataElement?.value !== undefined) ? JSON.parse(dataElement.value) : dataElement.value;
};

export const convertGqlPresetsWithoutValuesResult = (gqlResult: GetProgramPresetsWithoutValuesQueryResult, existingPresets: PresetMap): PresetMap => {
    const gqlPresets = gqlResult?.data?.program?.programVersion?.presets;
    return gqlPresets?.reduce((acc, preset) => {
        const presetId: string = getIdFromGqlId(preset.id);
        if (!preset.isCreative) {
            const existingPreset = existingPresets?.[presetId];
            acc[presetId] = {
                id: presetId,
                name: preset.name,
                data: existingPreset?.graphQLId === preset.id ? existingPreset.data : {},
                graphQLId: preset.id,
                graphQLUpdated: preset.updated

            };
        }
        else {
            const existingPreset = existingPresets?.[CREATIVES_PRESET_ID];
            acc[CREATIVES_PRESET_ID] = {
                id: CREATIVES_PRESET_ID,
                name: "Creatives Preset",
                data: existingPreset?.graphQLId === preset.id ? existingPreset.data : {},
                graphQLId: preset.id,
                graphQLUpdated: preset.updated
            };
        }
        return acc;
    }, {});
};

export const convertGqlPresetValuesResultAndAddToPresetMap = (gqlResult: GetProgramPresetValuesQueryResult, presets: PresetMap): PresetMap => {
    const preset = gqlResult?.data?.program?.programVersion?.preset;
    let existingPreset: Preset;
    let presetData: PresetData;
    if (!preset.isCreative) {
        const presetId: string = getIdFromGqlId(preset.id);
        presetData = [...preset.systemElementValues, ...preset.dataElementValues].reduce((data, dataElement) => {
            const elementId: string = (dataElement as GqlClientPresetSystemElementValue)?.systemElementId || getIdFromGqlId((dataElement as GqlClientPresetDataElementValue)?.id);
            data[elementId] = {
                id: elementId,
                type: dataElement?.type,
                value: parseDataElementValue(dataElement)
            };
            if (dataElement?.valueId !== null && dataElement?.valueId !== undefined) {
                data[elementId].valueId = dataElement?.valueId;
            }
            return data;
        }, {});
        existingPreset = presets[presetId];
    }
    else {
        presetData = preset.dataElementValues?.reduce((data, dataElement) => {
            const dataElementId: string = getIdFromGqlId(dataElement?.id);
            data[dataElementId] = parseDataElementValue(dataElement);
            return data;
        }, {});
        existingPreset = presets[CREATIVES_PRESET_ID];
    }
    return {
        ...presets,
        [existingPreset.id]: {
            ...existingPreset,
            data: presetData
        }
    };
};
