import type { LogicJSON as dbLogicJSON } from "../../../common/types/logic";
import type { Program as dbProgram } from "../../../common/types/program";
import type { PrioritizedList as ScenePrioritizedList } from "../../../common/types/scene";
import type { ApolloClientInstance } from "../../apollo/client";
import type {
    GqlClientNarrationRecordingStatus,
    GqlClientProgramDynamoMigrationStatus
} from "../../graphql/graphqlGeneratedTypes/graphqlClient";
import type { UserServices } from "../user/UserServices";
import type CommonServices from "./commonServices";
import type ProjectServices from "../projects/ProjectsServices";
import type ProjectAssetsServices from "../projects/projectAssets/projectAssetsServices";
import type { ProjectLifecycleServices } from "../projects/projectLifecycle/projectLifecycleServices";
import type DataElementServices from "../DataElements/DataElementServices";
import type ProjectWireframesServices from "../projects/projectWireframes/projectsWireframesServices";
import type ProjectNarrationsServices from "../projects/projectWireframes/projectNarrations/projectNarrationsServices";
import type DtaasServices from "../dtaas/dtaasServices";
import type { FeatureFlagsServices } from "../featureFlags/featureFlagsServices";
import type { Snapshot } from "../../../common/types/snapshot";
import type { DataElementTypes } from "../../../common/types/dataElement";

export interface PrioritizedList extends ScenePrioritizedList, dbLogicJSON {
    maxSlots: number;
}

export interface ValidationShape {
    status: string;
    report: any[];
    message?: string;
}

export type TestResult = {
    result: any;
    case: any;
};

export interface LogicJSON extends dbLogicJSON {
    loading?: boolean;
}

export interface Program extends dbProgram {
    devSnapshots: Snapshot[],
    uatSnapshots: Snapshot[],
    prodSnapshots: Snapshot[]
}

export type CreativeNarrationRecording = {
    //fields from GqlCreativeNarrationRecording
    id: string;
    narrationText: string;
    cdeId: string;
    rfr: number;
    //fields from GqlCreativeNarrationRecordingVersion
    status?: GqlClientNarrationRecordingStatus;
    mockNarrationUrl?: string;
    calculatedHash: string;
    location?: string;
};

export enum Order {
    Asc = "asc",
    Desc = "desc"
}

export type TableHeader = {
    id: string;
    value: string;
    sortable: boolean;
    sortComparator?: (cellA: Cell, cellB: Cell, orderBy: string, order: Order, pass: number) => number;
};
export type Cell = {
    value: string | JSX.Element;
    sortValue?: string;
};
export type TableAction = {
    id: string;
    onClick: (...args: any) => void;
    text: string;
    className?: string;
    disabled: boolean;
    showMenuIcon?: boolean;
};

export type ThunkServices = {
    graphQlClient: ApolloClientInstance;
    common: CommonServices;
    featureFlags: FeatureFlagsServices;
    dataElements: DataElementServices;
    wireframes: ProjectWireframesServices;
    projectServices: ProjectServices;
    projectAssetsServices: ProjectAssetsServices;
    projectLifeCycleServices: ProjectLifecycleServices;
    projectNarrationsServices: ProjectNarrationsServices;
    dtaasServices: DtaasServices;
    userServices: UserServices;
};

export type ProgramDynamoMigrationStatus = Omit<GqlClientProgramDynamoMigrationStatus, "programId" | "accountId" | "updated" | "created" | "id" | "__typename">

export enum BulkId {
    MostEntities = "mostEntities",
    Assets = "assets",
    RecordingDocs = "recordingDocs",
    Animations = "animations",
    Narrators = "narrators",
    LifecycleHistory = "lifecycleHistory",
    DataTables = "dataTables"
}

export type WithGraphQLId<K> = K & { graphQLId: string };

export type ValueSetSource = "DataElements" | "candidateNames" | "numberOfValidSlots";

export interface ValueSetProvider {
    getSource: () => ValueSetSource,
    getId: () => string,
    getName: () => string,
    getType: () => DataElementTypes,
    getValueSet: () => any
}
