import BaseServices from "./BaseServices";
import { EDITING_MODE, STAGE_MODE } from "./Consts";
import type { TableauUserRegistrationStatus } from "../../../common/types/restApiTypes";


class TableauRestService extends BaseServices {
    constructor(authService, builderModeRetriever) {
        super(authService, builderModeRetriever);
    }

    registerUser(): Promise<TableauUserRegistrationStatus> {
        return this.put<TableauUserRegistrationStatus>([EDITING_MODE, STAGE_MODE], "/rest/analytics/users", {});
    }

}

export default TableauRestService;
