import type { GetProgramLifecycleHistoryQueryResult, GqlClientLifecycleHistoryEntry } from "../../graphql/graphqlGeneratedTypes/graphqlClient";
import type { LifecycleHistory } from "../../../common/types/lifecycle";
import { Stages } from "../../../common/types/lifecycle";

export const convertSingleGqlLifecycleHistoryEntry = (lifecycleHistoryEntry: Partial<GqlClientLifecycleHistoryEntry>) => ({
    stage: lifecycleHistoryEntry.lifecycleStage,
    snapshotNumber: lifecycleHistoryEntry.snapshotNumber,
    promotedDate: new Date(lifecycleHistoryEntry.created).valueOf(),
    promotedBy: lifecycleHistoryEntry.createdBy,
    graphQlId: lifecycleHistoryEntry.id
});

export const convertGqlLifecycleHistoryResult = (gqlResult: GetProgramLifecycleHistoryQueryResult): LifecycleHistory => {
    const gqlLifecycleHistory = gqlResult?.data?.program?.lifecycleHistory;
    let accInitialValue = Object.values(Stages).reduce((acc, stage) => {
        acc[stage.toString()] = [];
        return acc;
    }, {});
    const lifecycleHistoryByStaged = gqlLifecycleHistory?.reduce((acc, lifecycleHistoryEntry: Partial<GqlClientLifecycleHistoryEntry>) => {
        if (lifecycleHistoryEntry?.lifecycleStage && acc[lifecycleHistoryEntry.lifecycleStage]) {
            acc[lifecycleHistoryEntry.lifecycleStage].push(convertSingleGqlLifecycleHistoryEntry(lifecycleHistoryEntry));
        }
        return acc;
    }, accInitialValue);
    
    return Object.keys(lifecycleHistoryByStaged).map((key: Stages) => {
        return {
            name: key,
            snapshots: lifecycleHistoryByStaged[key].sort((a, b) => b.promotedDate - a.promotedDate)
        };
    });
};
