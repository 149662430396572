import commonReducer from "./commonReducer";
import projectsReducer from "../projects/projectsReducer";
import projectAssetsReducer from "../projects/projectAssets/projectAssetsReducer";
import adminReducer from "../admin/adminReducer";
import userReducer from "../user/userReducer";
import previewReducer from "../preview/previewReducer";
import projectLifeCycleReducer from "../projects/projectLifecycle/projectLifecycleReducer";
import featureFlagsReducer from "../featureFlags/featureFlagsReducer";
import accountOverviewReducer from "../dashboards/accountOverview/accountOverviewReducer";
import wireFramesReducer from "../projects/projectWireframes/projectsWireframesReducer";
import projectNarrationsReducer from "../projects/projectWireframes/projectNarrations/projectNarrationsReducer";
import programOverviewReducer from "../dashboards/programOverview/programOverviewReducer";
import dataElementReducer from "../DataElements/DataElementReducers";
import projectCombinedReducer from "../projects/projectWireframes/projectCombinedReducer";
import storyReducer from "../stories/StoryReducer";
import validationReducer from "../validations/validationReducer";
import diffReducer from "../versionDiff/diffReducer";
import ValidationTable from "../validations/validationTable";
import { DrawerFilters } from "../../components/main/mainContainerUtils";

var reducers = [
    commonReducer,
    projectsReducer,
    adminReducer,
    projectAssetsReducer,
    userReducer,
    previewReducer,
    projectLifeCycleReducer,
    featureFlagsReducer,
    wireFramesReducer,
    programOverviewReducer,
    accountOverviewReducer,
    dataElementReducer, //MUST BE AFTER wireFramesReducer
    projectNarrationsReducer, //MUST BE AFTER dataElementReducer
    storyReducer, //MUST BE AFTER wireFramesReducer
    diffReducer,
    projectCombinedReducer, //MUST BE AFTER dataElementReducer
    validationReducer // MUST BE LAST!
];

function getInitialState() {
    return {
        studioProgramsDrawerFilter: DrawerFilters.AllButArchive,
        loadingCount: 0,
        activeAccountId: undefined,
        accounts: {
            byId: {},
            allIds: [],
            isInitialized: false
        },
        projects: {
            byName: {},
            allNames: [],
            isInitialized: false
        },
        assets: {
            byId: {},
            allIds: []
        },
        snapshots: {
            byId: {},
            allIds: []
        },
        dashboardAccountInfo: {},
        dashboardScenes: {
            byId: {},
            allIds: []
        },
        dashboardPrograms: {
            byId: {},
            allIds: []
        },
        error: false,
        errorMessage: "",
        browserHistory: {
            activeId: 0,
            byLink: {},
            allLinks: []
        },
        wireframes: {
            byName: {},
            allNames: []
        },
        devwireframes: {
            byName: {},
            allNames: []
        },
        uatwireframes: {
            byName: {},
            allNames: []
        },
        prodwireframes: {
            byName: {},
            allNames: []
        },
        versionwireframes: {
            byName: {},
            allNames: []
        },
        diffwireframes: {
            byName: {},
            allNames: []
        },
        diffModel: {
            currentVersion: undefined,
            previousVersion: undefined,
            model: undefined
        },
        presets: {
            byName: {},
            allNames: []
        },
        userName: "",
        users: {
            allNames: [],
            selectedUser: {}
        },
        admin: {
            isLoadingUsers: false,
            isFetchingUser: false,
            isSettingFeatureFlag: false,
            isLoadingAllProjects: false,
            allProjects: []
        },
        projectSummaries: [],
        versions: [],
        renderErrorMessage: "",
        versionManager: {},
        hubStickyBarLogoMenuItemsArray: [],
        enableHubStickyBarLogoMenu: false,
        validationData: new ValidationTable(),
        recordingDocs: [],
        creativeRecordingAssets: {
            byId: {},
            allIds: []
        }
    };
}

function rootReduce(state, action) {
    if (state === undefined) {
        return getInitialState();
    }
    return reducers.reduce((state, reducer) => reducer(state, action), state);
}
export default rootReduce;
