import type {
    GqlClientAudiencePersonalization,
    GqlClientChannelPersonalization,
    GqlClientKpi,
    GqlClientKpiSupportersFragment,
    GqlClientTouchpointPersonalization } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import {
    KpiSupportersFragmentDoc
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import type { ApolloClient } from "@apollo/client";
import type { Roles } from "../../types";
import type { CardData } from "../../../../components/framework/EntityCard";
import { getRole, initCardData } from "../../utils";

const getSupporters = (kpi: GqlClientKpi, client: ApolloClient<any>): string[] => {
    /** supporters for kpis:
     * the parent goal of the kpi
     * scenes related to the kpi
     * the audience values connected to those scenes
     * parent audiences of those values
     * stories related to those scenes
     * channels related to those stories
     * touchpoints related to those stories
     * channels related to those scenes
     * data elements related to those scenes
     * touchpoints related to those scenes
     */
    let supporterIds = [];
    const { goal, scenes } = kpi;
    supporterIds.push(goal.id);
    scenes.forEach(({ id, __typename }) => {
        supporterIds.push(id);
        const sceneData: GqlClientKpiSupportersFragment = client.readFragment({
            id: `${__typename}:${id}`,
            fragment: KpiSupportersFragmentDoc
        });
        sceneData.feedDataElements.forEach(({ id }) => supporterIds.push(id));
        sceneData.stories.forEach(({ id, touchpoints, channels }) => {
            supporterIds.push(id);
            touchpoints.forEach(({ id }) => supporterIds.push(id));
            channels.forEach(({ id }) => supporterIds.push(id));
        });
        sceneData.personalizations.forEach((personalization) => {
            const { audiencePersonalizationValues, audience } = personalization as GqlClientAudiencePersonalization;
            const { channel } = personalization as GqlClientChannelPersonalization;
            const { touchpoint } = personalization as GqlClientTouchpointPersonalization;
            audience && supporterIds.push(audience.id);
            audiencePersonalizationValues && audiencePersonalizationValues.forEach(({ audienceValue }) => supporterIds.push(audienceValue.id));
            channel && supporterIds.push(channel.id);
            touchpoint && supporterIds.push(touchpoint.id);
        });
    });
    return supporterIds;
};

const getCardData = (kpi: GqlClientKpi, roles?: Roles, idx?: number): CardData => {
    let kpiData: CardData = initCardData(kpi);
    kpiData.header.title = `${idx + 1}. ${kpiData.header.title}`;
    kpiData.contents = [
        { title: "Objective:", descriptionText: kpi.objective ? `${kpi.objective}` : "None" },
        { title: "Description:", descriptionText: kpi.description ? `${kpi.description}` : "None" }
    ];

    if (roles) kpiData.role = getRole(kpi, roles);
    return kpiData;
};

const KPI = {
    getSupporters,
    getCardData
};

export default KPI;
