import type { CordClientAuthTokenResponse } from "../../../common/types/restApiTypes";
import BaseServices from "./BaseServices";
import { EDITING_MODE, STAGE_MODE } from "./Consts";


class CordServices extends BaseServices {
    constructor(authService, builderModeRetriever) {
        super(authService, builderModeRetriever);
    }

    cordClientAuthToken(accountId: string): Promise<CordClientAuthTokenResponse> {
        return this.get<CordClientAuthTokenResponse>([EDITING_MODE, STAGE_MODE], `/rest/${accountId}/cordClientAuthToken`);
    }
}

export default CordServices;
