import { LOGIC_DATA_TYPES } from "../vlx/consts";
import traverse from "traverse";
import type { ActionableData, ReadonlyValueSet, ValueSet } from "../../../common/types/logic";
import type {
    BaseDataElement,
    CreativeDataElement,
    DataElement,
    DataElementContentType,
    DataElementTypes,
    DerivedDataElement,
    FeedDataElement } from "../../../common/types/dataElement";
import {
    CompatibilityVersions,
    DataElementContentTypes,
    DataElementOrigins,
    DataElementStatuses,
    TagNames
} from "../../../common/types/dataElement";
import { adsDataElements } from "../../../common/commonConst";
import { generateValueId } from "../Logics/ValueSetUtils";

export interface DataElementPropType {
    id: string;
    name: string;
    type: DataElementContentTypes;
    origin: DataElementOrigins;
    displayName: string;
    compatibilityVersion: CompatibilityVersions;
    description: string;
    status: DataElementStatuses;
    usedInScenes: string;
    useValueSet: boolean;
    valueSet: string[];
    tags: DataElementTag[];
}

export interface DataElementTagPropType {
    tag: string;
    name: string;
    origins: DataElementOrigins[];
    types: DataElementContentTypes[];
}

export type DataElementTag = {
    tag: TagNames; // Keep same as key
    name: string; // For human readable applications and logging
    origins: DataElementOrigins[]; // On what data element origins this tag can be applied
    types: DataElementContentType[]; // On what data element types this tag can be applied
};

export type TagsType = { [key: string]: DataElementTag };
export type ValueSetGetterFunction = () => ReadonlyValueSet;
export const CurrentCompatibilityVersion: CompatibilityVersions = CompatibilityVersions.IdSupport;
export const DataElementTags: TagsType = {
    PII: {
        tag: TagNames.PII,
        name: "Personal Identifiable Information",
        origins: [DataElementOrigins.Feed, DataElementOrigins.Derived],
        types: Object.values(DataElementContentTypes)
    }
};
export const dataElementObjectType: symbol = Symbol("_OBJECT_DATA_ELEMENT");

export const ASSIGN_TO_ALL_STORIES_LABEL = "All Stories";

export function buildDataElement(args: {
    id: string;
    type: DataElementTypes;
    name?: string;
    graphQLId?: string,
    graphQLUpdated?: string,
    displayName?: string;
    lastUpdated?: Date;
    description?: string;
    origin: DataElementOrigins;
    status?: DataElementStatuses;
    valueSet?: ValueSet;
    useValueSet?: boolean;
    compatibilityVersion?: CompatibilityVersions;
    updatedTime?: number;
    tags?: TagNames[];
    assignedStories?: string[];
    assignedToAllStories?: boolean;
}): DataElement {
    const displayName: string = args.displayName || args.name || null;
    const id: string = args.id || null;
    let status: DataElementStatuses = args.status || DataElementStatuses.Pending;
    if ((args.origin && args.origin !== DataElementOrigins.Feed) || adsDataElements.find((de) => de.displayName === displayName)) {
        status = undefined;
    }

    let dataElement: BaseDataElement = {
        id,
        name: args.name || null,
        displayName,
        compatibilityVersion: args.compatibilityVersion || CurrentCompatibilityVersion,
        type: args.type || DataElementContentTypes.String,
        description: args.description || null,
        origin: args.origin || DataElementOrigins.Feed,
        tags: [...(args.tags || [])],
        updatedTime: args.updatedTime || null,
        _objectType: dataElementObjectType
    };

    if (args.graphQLId) {
        dataElement.graphQLId = args.graphQLId;
    }
    if (args.graphQLUpdated) {
        dataElement.graphQLUpdated = args.graphQLUpdated;
    }

    if (dataElement.origin === DataElementOrigins.Feed) {
        return {
            ...dataElement,
            origin: DataElementOrigins.Feed,
            status,
            valueSet: args.valueSet ? Object.freeze<ValueSet>(args.valueSet.map((val) => Object.freeze(val))) : null,
            useValueSet: args.useValueSet || false
        };
    }
    else if (dataElement.origin === DataElementOrigins.Derived) {
        return {
            ...dataElement
        } as DerivedDataElement;
    }
    else if (args.origin === DataElementOrigins.Creative) {
        return {
            ...dataElement,
            origin: DataElementOrigins.Creative,
            valueSet: args.valueSet ? Object.freeze<ValueSet>(args.valueSet.map((val) => Object.freeze(val))) : null,
            useValueSet: args.useValueSet || false,
            assignedStories: args.assignedStories || [],
            assignedToAllStories: args.assignedToAllStories || false
        };
    }
    else if (args.origin === DataElementOrigins.System) {
        return {
            ...dataElement,
            origin: DataElementOrigins.System,
            valueSet: args.valueSet ? Object.freeze<ValueSet>(args.valueSet.map((val) => Object.freeze(val))) : null,
            useValueSet: args.useValueSet || false
        };
    }
}

/***
 * Finds a data Element by it's unique id
 * @param dataElementId- the id by which to search for data elements
 * @param dataElementsArray - Array<DataElement> - The list of all available data elements
 */
export function findDataElementById(dataElementId: string, dataElementsArray: DataElement[]): DataElement {
    return (dataElementsArray || []).find((de: DataElement) => {
        // If this data element doesn't have an id, use the name as an id
        if (!de.compatibilityVersion || de.compatibilityVersion === CompatibilityVersions.DisplayName || de.compatibilityVersion < CompatibilityVersions.RowForDataElement) {
            return de.name === dataElementId;
        }
        return de.id === dataElementId;
    });
}

/***
 * Finds a data Element by it's name attribute
 * @param dataElementName - the name by which to search for data element
 * @param dataElementsArray - Array<DataElement> - The list of all available data elements
 */
export function findDataElementByName(dataElementName: string, dataElementsArray: Array<DataElement>): DataElement {
    return (dataElementsArray || []).find((de) => de.name === dataElementName);
}

/***
 * Converts data elements as storend in an object in the DB to an array of items as stored in redux
 * @param obj - {DataElementDBObject}
 * @return Array<DataElement>
 */
export function convertDataElementsObjToArray(obj: any[]): Array<DataElement> {
    const version = { obj };
    return traverse(obj).reduce(function(acc, node) {
        if (node && node.type && node.type !== "object") {
            let name = version ? node.name : this.path.join(".");
            acc.push(
                buildDataElement({
                    ...node,
                    name,
                    type: node.type,
                    description: node.description,
                    origin: node.origin,
                    status: node.status,
                    displayName: version ? node.displayName : name
                })
            );
        }
        return acc;
    }, []);
}

/**
 * Update the name of an existing data element, returns a shallow copy of the provided data element
 * with updated filed
 * @param dataElement The data element to be updated
 * @param id - The ID to be stored in the data element
 * @return data element with new id
 */
export function changeID(dataElement: DataElement, id: string): DataElement {
    return Object.freeze({
        ...dataElement,
        id: id
    });
}

/**
 * Update the name of an existing data element, returns a shallow copy of the provided data element
 * with updated filed
 * @param dataElement The data element to be updated
 * @param name - The new name to be stored in the data element
 * @return {{name: string, id?: string, displayName: string, compatibilityVersion: CompatibilityVersion, contentType: DataElementContentTypes, description?: string, origin: DataElementOrigin}}
 */
export function changeName(dataElement: DataElement, name: string): DataElement {
    return Object.freeze({
        ...dataElement,
        displayName: name.trim()
    });
}

/**
 * Updates the description of an existing data element, returns a shallow copy of the provided data element
 * @param dataElement - The data element to be updated
 * @param desc - The new value of description to be stored in the data element
 * @return {{name: string, id?: string, displayName: string, compatibilityVersion: CompatibilityVersion, contentType: DataElementContentTypes, description?: string, origin: DataElementOrigin}}
 */
export function changeDescription(dataElement: DataElement, desc: string): DataElement {
    return Object.freeze({
        ...dataElement,
        description: desc
    });
}

/**
 *  Updates the type of an existing data element
 *  returns a copy of the data element with the change of the type
 * @param dataElement - The data element to be updated
 * @param newType - The new value of type to be updated for the data element
 * @return DataElement
 */
export function changeContentType(dataElement: DataElement, newType: DataElementTypes): DataElement {
    return Object.freeze({
        ...dataElement,
        type: newType
    });
}

/**
 * Change the assigned to all stories indicator
 * @param dataElement - The data element to be updated with the new assigned to all stories indicator
 * @param assignedToAllStories - true/false - the new indicator
 * @return {Readonly<{name: string, id?: string, displayName: string, compatibilityVersion: CompatibilityVersions, type: DataElementContentTypes, description?: string, origin: DataElementOrigins}>}
 */
export function changeAssignedToAllStories(dataElement: CreativeDataElement, assignedToAllStories: boolean): CreativeDataElement {
    return Object.freeze({
        ...dataElement,
        assignedToAllStories
    });
}

/**
 * Change the assigned stories ids array
 * @param dataElement - The data element to be updated with the new assigned stories
 * @param storiesIds - The new stories ids array
 * @return {Readonly<{name: string, id?: string, displayName: string, compatibilityVersion: CompatibilityVersions, type: DataElementContentTypes, description?: string, origin: DataElementOrigins}>}
 */
export function changeAssignedStories(dataElement: CreativeDataElement, storiesIds: string[]): CreativeDataElement {
    return Object.freeze({
        ...dataElement,
        assignedStories: storiesIds
    });
}

/***
 * Adds a story Id to the assigned stories array on a data element
 * @param dataElement - The data element to assign to the story
 * @param storyId - The story id to add
 * @return {Readonly<{}>}
 */
export function addAssignedStory(dataElement: CreativeDataElement, storyId: string): CreativeDataElement {
    if (dataElement.origin !== DataElementOrigins.Creative) {
        throw `Cannot assign story to data element originating from "${dataElement.origin}"`;
    }

    if ((dataElement.assignedStories || []).find((sId) => sId === storyId)) {
        throw `Cannot assign story ${storyId} more than once`;
    }

    return Object.freeze({
        ...dataElement,
        assignedStories: [...(dataElement.assignedStories || []), storyId]
    });
}

/***
 * Removes a story Id from the assigned stories array on a data element
 * @param dataElement - The data element to un-assign to the story
 * @param storyId - The story id to remove
 * @return {Readonly<{}>}
 */
export function removeAssignedStory(dataElement: CreativeDataElement, storyId: string): CreativeDataElement {
    let assignedStories = dataElement.assignedStories;
    if ((assignedStories || []).find((sId) => sId === storyId)) {
        let index = assignedStories.indexOf(storyId);
        assignedStories.splice(index, 1);
    }
    else {
        throw `Cannot find assign story ${storyId}`;
    }

    return Object.freeze({
        ...dataElement,
        assignedStories
    });
}

/**
 * Updates the status of an existing data element object, returns a new data element with the new status.
 * @param dataElement - Existing data element to be updated
 * @param newStatus - The new status to which update the data element
 * @return {Readonly<{name: string, id?: string, displayName: string, compatibilityVersion: CompatibilityVersion, type: DataElementContentTypes, description?: string, origin: DataElementOrigin}>}
 */
export function changeStatus(dataElement: DataElement, newStatus: DataElementStatuses): DataElement {
    return Object.freeze({
        ...dataElement,
        status: newStatus
    });
}

/**
 * Updates the string array of places an existing data element object is used in, returns a new data element with the new list of items as string.
 * @param dataElement - Existing data element to be updates
 * @param usedInString - Ths string containing the used scenes in which this data element is used
 * @return {Readonly<{
	id?: string,
	name: string,
	type: DataElementContentType,
	origin: DataElementOrigin,
	displayName: string,
	compatibilityVersion: CompatibilityVersion,
	description?: string,
	status?: DataElementStatus,
	usedIn: string
    }
 */
export function changeAndSetUsedInScene(dataElement: DataElement, usedInString: string): DataElement {
    return Object.freeze({
        ...dataElement,
        usedInScenes: usedInString
    });
}

/**
 * Updates a value set for data element
 * @param dataElement - Existing data element to be updates
 * @param valueSet - The new value set
 * @return {Readonly<{
 *  id?: string,
 *  name: string,
 *  type: DataElementContentType,
 *  origin: DataElementOrigin,
 *  displayName: string,
 *  compatibilityVersion: CompatibilityVersion,
 *  description?: string,
 *  status?: DataElementStatus,
 *  usedIn: string}>
 * }
 */
export function changeValueSet(dataElement: DataElement, valueSet: ValueSet): DataElement {
    Object.freeze(valueSet);
    return Object.freeze({
        ...dataElement,
        valueSet: valueSet
    });
}

/**
 * Updates an indicator of weather to use the value set of this data element, or ignore it
 * @param dataElement - Existing data element to be updates
 * @param useValueSet - an indicator of weather to use the value set or ignore it
 * @return {Readonly<{
 *  id?: string,
 *  name: string,
 *  type: DataElementContentType,
 *  origin: DataElementOrigin,
 *  displayName: string,
 *  compatibilityVersion: CompatibilityVersion,
 *  description?: string,
 *  status?: DataElementStatus,
 *  usedIn: string}>
 * }
 *
 */
export function changeUseValueSet(dataElement: DataElement, useValueSet: boolean): DataElement {
    return Object.freeze({
        ...dataElement,
        useValueSet: useValueSet
    });
}

/**
 * Updates a getter for value set. This getter should not be saved on the redux state!
 * @param dataElement - Existing data element to be updates
 * @param valueSetGetter - an indicator of weather to use the value set or ignore it
 * @return {Readonly<{
 *  id?: string,
 *  name: string,
 *  type: DataElementContentType,
 *  origin: DataElementOrigin,
 *  displayName: string,
 *  compatibilityVersion: CompatibilityVersion,
 *  description?: string,
 *  status?: DataElementStatus,
 *  usedIn: string}>
 * }
 */
export function changeGetterForValueSet<T extends DataElement>(dataElement: T, valueSetGetter: ValueSetGetterFunction): T {
    return Object.freeze({
        ...dataElement,
        getValueSet: valueSetGetter
    }) as Readonly<T> as T;
}

/***
 * Returns the displayName of a data element
 * @param dataElement
 * @return {string}
 */
export function getDataElementDisplayName(dataElement: DataElement): string {
    if (!dataElement) {
        return "";
    }

    return dataElement.displayName || "";
}

/***
 * Returns the name of an origin
 * @param origin an origin
 * @param isFullDemoFlowBuilder - boolean
 * @return {string}
 */
export function getOriginDisplayName(origin: DataElementOrigins, isFullDemoFlowBuilder: boolean): string {
    return getOriginDisplayNameAndIndefiniteArticle(origin, isFullDemoFlowBuilder).displayName;
}

/***
 * Returns the name and indefinite article of an origin
 * @param origin an origin
 * @param isFullDemoFlowBuilder - boolean
 * @return {{
 *  displayName: string,
 *  indefiniteArticle: string}
 * }
 */
export function getOriginDisplayNameAndIndefiniteArticle(origin: DataElementOrigins, isFullDemoFlowBuilder: boolean): { displayName: string, indefiniteArticle: string } {
    switch (origin) {
        case DataElementOrigins.Creative:
            return { displayName: "Content Set Data Element", indefiniteArticle: "a" };
        case DataElementOrigins.Derived:
            return isFullDemoFlowBuilder ? { displayName: "Audience", indefiniteArticle: "an" } : { displayName: "Studio Data Element", indefiniteArticle: "a" };
        case DataElementOrigins.Feed:
        case DataElementOrigins.System:
        default:
            return { displayName: "Data Element", indefiniteArticle: "a" };
    }
}

/***
 * Returns the type of a data element
 * @param dataElement
 * @return {string}
 */
export function getDataElementType(dataElement: DataElement): string {
    if (!dataElement) {
        return "";
    }

    return dataElement.type || "";
}

/***
 * Returns the stories' ids which the data element is assigned to
 * @param dataElement
 * @return {string[]}
 */
export function getDataElementAssignedStories(dataElement: CreativeDataElement): string[] {
    if (!dataElement) {
        return [];
    }

    return dataElement.assignedStories || [];
}

/***
 * Returns whether the data element is assigned to all stories
 * @param dataElement
 * @return {boolean}
 */
export function getDataElementAssignedToAllStories(dataElement: CreativeDataElement): boolean {
    if (!dataElement) {
        return false;
    }

    return dataElement.assignedToAllStories || false;
}

/***
 * Returns whether the data element is assigned to a story ("all stories" included)
 * @param dataElement
 * @param storyId
 * @return {boolean}
 */
export function isDataElementAssignedToStory(dataElement: CreativeDataElement, storyId: string): boolean {
    const assignedStories: string[] = getDataElementAssignedStories(dataElement);
    const assignedToAllStories: boolean = getDataElementAssignedToAllStories(dataElement);
    return assignedToAllStories || assignedStories.includes(storyId);
}

/***
 * Returns the origin of a data element
 * @param dataElement
 * @return {string}
 */
export function getDataElementOrigin(dataElement: DataElement): string {
    if (!dataElement) {
        return "";
    }

    return dataElement.origin || "";
}

/***
 * Returns the usedInScenes of a data element
 * @param dataElement
 * @return {string}
 */
export function getDataElementUsedInScene(dataElement: DataElement): string {
    if (!dataElement) {
        return "";
    }

    return dataElement.usedInScenes || "";
}

/***
 * Returns the description of a data element
 * @param dataElement
 * @return {string}
 */
export function getDataElementDescription(dataElement: DataElement): string {
    if (!dataElement) {
        return "";
    }

    return dataElement.description || "";
}

/***
 * Returns the status of a data element
 * @param dataElement
 * @return {string}
 */
export function getDataElementStatus(dataElement: FeedDataElement): string {
    if (!dataElement) {
        return "";
    }

    return dataElement.status || "";
}

/***
 * Returns the updatedTime of a data element
 * @param dataElement
 * @return {string}
 */
export function getDataElementUpdatedTime(dataElement: DataElement): number {
    if (!dataElement) {
        return 0;
    }

    return dataElement.updatedTime || 0;
}

/**
 * For backward compatibility this functions looks for the right data element
 * based on the value stored in the logic, either by name or by id,
 * depending on what is stored in the logic value
 * @param logicValue - actionable data value, of type = DataElement
 * @param dataElementsArray - Array<DataElement> - The list of all available data elements
 * in the project
 */
export function findDataElementByLogicValue(logicValue: ActionableData, dataElementsArray: Array<DataElement>): DataElement {
    //     // Make sure the type is data element
    if (typeof logicValue !== "object" || !logicValue.type || logicValue.type !== LOGIC_DATA_TYPES.DataElement) {
        return null;
    }
    // If this is a new logic type search by id
    if (logicValue.hasOwnProperty("id") && logicValue.id) {
        return findDataElementById(logicValue.id, dataElementsArray);
    }

    // If this is an old logic, the name field will be holding either an id or a pre-migrated de name (<v0.2)
    // try looking up the id, if not found look by the name field.
    return findDataElementById(logicValue.name, dataElementsArray) || findDataElementByName(logicValue.name, dataElementsArray);
}

/***
 * Returns a unique identifier of the data element (based on it's computability version)
 * @param dataElement
 * @return {string}
 */
export function getDataElementId(dataElement: DataElement): string {
    if (!dataElement.compatibilityVersion || typeof dataElement.compatibilityVersion === "string" || dataElement.compatibilityVersion < CompatibilityVersions.RowForDataElement || !dataElement.id) {
        return dataElement.name;
    }
    return dataElement.id;
}

/**
 * Returns the identifier of the logic object of associated with this derived data element
 * covers all older versions of data elements, and starts using ids for v0.3+
 * @param dataElement - A derived data element object
 * @return {string} The id of the logic obeject stored under /derived/{id}
 */
export function getDerivedDataElementLogicId(dataElement: DataElement): string {
    if (!dataElement.compatibilityVersion || typeof dataElement.compatibilityVersion === "string" || dataElement.compatibilityVersion < CompatibilityVersions.IdSupport || !dataElement.id) {
        return dataElement.name;
    }
    return dataElement.id;
}

/**
 * Returns the derived data element for a derived data element key
 * @param derivedKey - derived data element key
 * @param dataElementsArray - Array<DataElement> - The list of all available data elements
 */
export function getDataElementFromDerivedKey(derivedKey, dataElementsArray: Array<DataElement>): DataElement {
    if (dataElementsArray) {
        return dataElementsArray.find((de) => getDerivedDataElementLogicId(de) === derivedKey);
    }
    return undefined;
}

/**
 * Returns whether the data element type supported by valueSet
 * @param dataElement - A data element object
 * @return {boolean} whether the data element type supported by valueSet
 */
export function isValueSetSupportedForDataElementType(dataElement: DataElement): boolean {
    return dataElement.type === DataElementContentTypes.String || dataElement.type === DataElementContentTypes.Number || dataElement.type === DataElementContentTypes.Boolean;
}

/***
 * Check whether or not a tag can be applied to a data element based on it's origins and types
 * @param dataElement - The data element to be checked for appliance
 * @param tag - The tage to be checked
 * @return {boolean} True if this tag is allowed to be added to this data element; false otherwise
 */
export function supportsTag(dataElement: DataElement, tag: TagNames): boolean {
    if (!dataElement || !tag) return false;

    if (!(DataElementTags[tag].origins || []).some((o) => o === dataElement.origin)) {
        return false;
    }

    if (!(DataElementTags[tag].types || []).some((t) => t === dataElement.type)) {
        return false;
    }

    return true;
}

/***
 * Adds a tag to a data element
 * @param dataElement - The data element on which the tag will be added
 * @param tag - The tag to added on the
 * @return {Readonly<{}>}
 */
export function addTag(dataElement: DataElement, tag: TagNames): DataElement {
    if (!(DataElementTags[tag].origins || []).some((o) => o === dataElement.origin)) {
        throw `Cannot apply tag: ${tag}, 
                on data element originating from "${dataElement.origin}"`;
    }

    if (!(DataElementTags[tag].types || []).some((t) => t === dataElement.type)) {
        throw `Cannot apply tag: ${tag}, 
                on data element of type "${dataElement.type}"`;
    }

    if ((dataElement.tags || []).findIndex((deTag) => deTag == tag) >= 0) {
        throw `Cannot apply tag ${tag} more than once`;
    }

    return Object.freeze({
        ...dataElement,
        tags: [...(dataElement.tags || []), tag]
    });
}

/***
 * Returns a new data element without the tag to be removed
 * @param dataElement - A data element object
 * @param tag - The tag to be removed
 * @return {*} a new data element without the tag, or the same data element if the tag does not exist
 */
export function removeTag(dataElement: DataElement, tag: TagNames): DataElement {
    // Make sure that all occurrences are deleted (in case there are more than one)
    // (this is to fix a bug i had where i added the same tag over and over,
    // but in case it ever happens again, this will make sure the whole tag is deleted)
    let index = (dataElement.tags || []).findIndex((deTag) => deTag == tag);
    while (index >= 0) {
        // If tag does not exits, return the same data element (to help observers know it wasn't mutated)
        if (index < 0) return dataElement;
        // Slice out the index
        const tags = [...dataElement.tags.slice(0, index), ...dataElement.tags.slice(index + 1)];
        dataElement = Object.freeze({ ...dataElement, tags });

        index = (dataElement.tags || []).findIndex((deTag) => deTag == tag);
    }
    return dataElement;
}

/***
 * Returns true if the provided data element has a certain tag in the list of its tags
 * @param dataElement -
 * @param tag - On of the DataElementTagKeys
 * @return {boolean} - True if the data elements was assigned with this tag, false otherwise
 */
export function hasTag(dataElement: DataElement, tag: TagNames): boolean {
    return (dataElement.tags || []).findIndex((deTag) => deTag == tag) >= 0;
}

/**
 * Returns an array with all tags (and their meta data) this data element
 * @param dataElement
 */
export function getDataElementTags(dataElement: DataElement): Array<DataElementTag> {
    if (!dataElement.tags || !dataElement.tags.length) {
        return [];
    }

    return dataElement.tags.map((tagKey) => DataElementTags[tagKey]);
}

/**
 * Extracts the display name of the tag object
 * @param dataElementTag - A tag meta object (@see: getDataElementTags)
 * @return {*} A human readable text explaining the tag
 */
export function getDataElementTagDisplayName(dataElementTag: DataElementTag): string {
    return dataElementTag.name;
}

/**
 * Extracts the key identifying this tag (EX: PII)
 * @param dataElementTag - A tag meta object (@see: getDataElementTags)
 * @return {*} A string used as the key to extract the tag meta object and the string stored in the tags array of the data element
 */
export function getDataElementTagKey(dataElementTag: DataElementTag): TagNames {
    return dataElementTag.tag;
}

/***
 * Filter data elements by it's origin
 * @param dataElementsArray - Array<DataElement> - The list of all available data elements
 * @param originArray- Array<string> - The list of wanted origins
 */
export function filterDataElementsArrayByOrigin(dataElementsArray: DataElement[], originArray: string[]): DataElement[] {
    return (dataElementsArray || []).filter((de: DataElement) => {
        return originArray.includes(getDataElementOrigin(de));
    });
}

/***
 * Filter data elements by it's type
 * @param dataElementsArray - Array<DataElement> - The list of all available data elements
 * @param typeArray- Array<string> - The list of wanted types
 */
export function filterDataElementsArrayByType(dataElementsArray: DataElement[], typeArray: string[]): DataElement[] {
    return (dataElementsArray || []).filter((de: DataElement) => {
        return typeArray.includes(getDataElementType(de));
    });
}

export function convertDataElementToStudioData(dataElement: FeedDataElement): DerivedDataElement {
    let derivedElement: DerivedDataElement = buildDataElement({
        origin: DataElementOrigins.Derived,
        id: dataElement.id,
        name: dataElement.name,
        displayName: dataElement.displayName,
        type: dataElement.type,
        description: dataElement.description,
        compatibilityVersion: dataElement.compatibilityVersion,
        updatedTime: dataElement.updatedTime,
        tags: dataElement.tags
    }) as DerivedDataElement;

    return derivedElement;
}

/**
 * Normalizes the data element from legacy structures to the newest structure.
 * Currently only the value set is normalized
 * @param dataElement a new data element with the newest structure
 */
export const normalizeDataElement = (dataElement: DataElement): DataElement => {
    let normalizedDataElement: DataElement = { ...dataElement };

    // Correct value set on update
    if (dataElement.origin === DataElementOrigins.Feed) {
        if (dataElement.valueSet && dataElement.valueSet.length > 0 && typeof dataElement.valueSet[0] === "string") {
            //@ts-ignore
            normalizedDataElement.valueSet = dataElement.valueSet.map((value) => ({ dn: value, id: generateValueId() }));
        }
    }

    return normalizedDataElement;
};
