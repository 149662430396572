import type {
    DataElementTypes
} from "../../../../common/types/dataElement";
import {
    CreativeDataElementContentTypes,
    DataElementContentTypes,
    DataElementStatuses
} from "../../../../common/types/dataElement";
import {
    GqlClientAnimationGuidelineType,
    GqlClientBackgroundAssetType,
    GqlClientDataElementType,
    GqlClientFeedDataElementStatus,
    GqlClientLifecycleStage,
    GqlClientNarrationRecordingErrorReason,
    GqlClientPlaceholderType,
    GqlClientProgramType
} from "../../../graphql/graphqlGeneratedTypes/graphqlClient";
import type { GqlClientRationAndQualityOption } from "../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { ParameterTypes, PlaceholderType } from "../../../../common/types/scene";
import { MediaTypes, NarrationRecordingErrorReason } from "../../../../common/types/asset";
import { ProgramType } from "../../../../common/types/program";
import { Stages } from "../../../../common/types/lifecycle";
import type { StoryRatioAndQualityOption } from "../../../../common/types/story";
import { VideoAspectRatio, VideoQualityProfile } from "../../../../common/external/videoSpec";

export const convertDataElementType = (type: DataElementTypes): GqlClientDataElementType => {
    switch (type) {
        case DataElementContentTypes.Boolean:
            return GqlClientDataElementType.BOOLEAN;
        case DataElementContentTypes.String:
            return GqlClientDataElementType.STRING;
        case DataElementContentTypes.Number:
            return GqlClientDataElementType.NUMBER;
        case DataElementContentTypes.Date:
            return GqlClientDataElementType.DATETIME;
        case DataElementContentTypes.Url:
            return GqlClientDataElementType.URL;
        case CreativeDataElementContentTypes.Audio:
            return GqlClientDataElementType.AUDIO;
        case CreativeDataElementContentTypes.Image:
            return GqlClientDataElementType.IMAGE;
        case CreativeDataElementContentTypes.Video:
            return GqlClientDataElementType.VIDEO;
        case CreativeDataElementContentTypes.Color:
            return GqlClientDataElementType.COLOR;
        case CreativeDataElementContentTypes.Narration:
            return GqlClientDataElementType.NARRATION;
        default:
            return undefined;
    }
};

export const convertDataElementTypeReverse = (type: GqlClientDataElementType): DataElementTypes => {
    switch (type) {
        case GqlClientDataElementType.BOOLEAN:
            return DataElementContentTypes.Boolean;
        case GqlClientDataElementType.STRING:
            return DataElementContentTypes.String;
        case GqlClientDataElementType.NUMBER:
            return DataElementContentTypes.Number;
        case GqlClientDataElementType.DATETIME:
            return DataElementContentTypes.Date;
        case GqlClientDataElementType.URL:
            return DataElementContentTypes.Url;
        case GqlClientDataElementType.AUDIO:
            return CreativeDataElementContentTypes.Audio;
        case GqlClientDataElementType.IMAGE:
            return CreativeDataElementContentTypes.Image;
        case GqlClientDataElementType.VIDEO:
            return CreativeDataElementContentTypes.Video;
        case GqlClientDataElementType.COLOR:
            return CreativeDataElementContentTypes.Color;
        case GqlClientDataElementType.NARRATION:
            return CreativeDataElementContentTypes.Narration;
        default:
            return undefined;
    }
};

export const convertDataElementStatus = (status: DataElementStatuses): GqlClientFeedDataElementStatus => {
    switch (status) {
        case DataElementStatuses.Approved:
            return GqlClientFeedDataElementStatus.APPROVED;
        case DataElementStatuses.Pending:
            return GqlClientFeedDataElementStatus.PENDING;
        case DataElementStatuses.Missing:
            return GqlClientFeedDataElementStatus.MISSING;
        default:
            return undefined;
    }
};

export const convertDataElementStatusReverse = (status: GqlClientFeedDataElementStatus): DataElementStatuses => {
    switch (status) {
        case GqlClientFeedDataElementStatus.APPROVED:
            return DataElementStatuses.Approved;
        case GqlClientFeedDataElementStatus.PENDING:
            return DataElementStatuses.Pending;
        case GqlClientFeedDataElementStatus.MISSING:
            return DataElementStatuses.Missing;
        default:
            return undefined;
    }
};

export const convertPlaceholderType = (type: PlaceholderType): GqlClientPlaceholderType => {
    switch (type) {
        case PlaceholderType.HOTSPOT:
            return GqlClientPlaceholderType.HOTSPOT;
        case PlaceholderType.IMAGE:
            return GqlClientPlaceholderType.IMAGE;
        case PlaceholderType.TEXT:
            return GqlClientPlaceholderType.TEXT;
        case PlaceholderType.VIDEO:
            return GqlClientPlaceholderType.VIDEO;
        default:
            return undefined;
    }
};

export const convertPlaceholderTypeReverse = (type: GqlClientPlaceholderType): PlaceholderType => {
    switch (type) {
        case GqlClientPlaceholderType.HOTSPOT:
            return PlaceholderType.HOTSPOT;
        case GqlClientPlaceholderType.IMAGE:
            return PlaceholderType.IMAGE;
        case GqlClientPlaceholderType.TEXT:
            return PlaceholderType.TEXT;
        case GqlClientPlaceholderType.VIDEO:
            return PlaceholderType.VIDEO;
        default:
            return undefined;
    }
};

export const convertAnimationGuidelineType = (type: ParameterTypes): GqlClientAnimationGuidelineType => {
    switch (type) {
        case ParameterTypes.color:
            return GqlClientAnimationGuidelineType.COLOR;
        case ParameterTypes.parameter:
            return GqlClientAnimationGuidelineType.TEXT;
        default:
            return undefined;
    }
};

export const convertAnimationGuidelineTypeReverse = (type: GqlClientAnimationGuidelineType): ParameterTypes => {
    switch (type) {
        case GqlClientAnimationGuidelineType.COLOR:
            return ParameterTypes.color;
        case GqlClientAnimationGuidelineType.TEXT:
            return ParameterTypes.parameter;
        default:
            return undefined;
    }
};

export const convertBackgroundAssetType = (type: MediaTypes): GqlClientBackgroundAssetType => {
    switch (type) {
        case MediaTypes.Video:
            return GqlClientBackgroundAssetType.VIDEO;
        case MediaTypes.Image:
            return GqlClientBackgroundAssetType.IMAGE;
        default:
            return undefined;
    }
};



export const convertBackgroundAssetTypeReverse = (type: GqlClientBackgroundAssetType): MediaTypes => {
    switch (type) {
        case GqlClientBackgroundAssetType.VIDEO:
            return MediaTypes.Video;
        case GqlClientBackgroundAssetType.IMAGE:
            return MediaTypes.Image;
        default:
            return undefined;
    }
};

export const convertStoryRationAndQualityOptionsTypeReverse = (ratioAndQualityOption: GqlClientRationAndQualityOption): StoryRatioAndQualityOption => {
    const convertRatio = (ratio: string): VideoAspectRatio => {
        switch (ratio) {
            case VideoAspectRatio.SQUARE:
                return VideoAspectRatio.SQUARE;
            case VideoAspectRatio.FULL_LANDSCAPE:
                return VideoAspectRatio.FULL_LANDSCAPE;
            case VideoAspectRatio.FULL_PORTRAIT:
                return VideoAspectRatio.FULL_PORTRAIT;
            default:
                return undefined;
        }
    };
    const convertQuality = (quality: string): VideoQualityProfile => {
        switch (quality) {
            case VideoQualityProfile.SD:
                return VideoQualityProfile.SD;
            case VideoQualityProfile.SD_INTERMEDIATE:
                return VideoQualityProfile.SD_INTERMEDIATE;
            case VideoQualityProfile.SD_HIGH:
                return VideoQualityProfile.SD_HIGH;
            case VideoQualityProfile.HD:
                return VideoQualityProfile.HD;
            case VideoQualityProfile.HD_READY:
                return VideoQualityProfile.HD_READY;
            case VideoQualityProfile.HD_READY_INTERMEDIATE:
                return VideoQualityProfile.HD_READY_INTERMEDIATE;
            case VideoQualityProfile.HD_READY_HIGH:
                return VideoQualityProfile.HD_READY_HIGH;
            default:
                return undefined;
        }
    };

    return {
        ratio: convertRatio(ratioAndQualityOption?.ratio),
        quality: convertQuality(ratioAndQualityOption?.quality)
    };
};

export const convertProgramType = (programType: ProgramType): GqlClientProgramType => {
    switch (programType) {
        case ProgramType.CRM:
            return GqlClientProgramType.CRM;
        case ProgramType.Ads:
            return GqlClientProgramType.ADS;
        default:
            return undefined;
    }
};



export const convertProgramTypeReverse = (programType: GqlClientProgramType): ProgramType => {
    switch (programType) {
        case GqlClientProgramType.CRM:
            return ProgramType.CRM;
        case GqlClientProgramType.ADS:
            return ProgramType.Ads;
        default:
            return undefined;
    }
};

export const convertStageType = (stage: Stages): GqlClientLifecycleStage => {
    switch (stage) {
        case Stages.dev:
            return GqlClientLifecycleStage.DEV;
        case Stages.UAT:
            return GqlClientLifecycleStage.UAT;
        case Stages.prod:
            return GqlClientLifecycleStage.PROD;
        default:
            return undefined;
    }
};

export const convertStageTypeReverse = (stage: GqlClientLifecycleStage): Stages => {
    switch (stage) {
        case GqlClientLifecycleStage.DEV:
            return Stages.dev;
        case GqlClientLifecycleStage.UAT:
            return Stages.UAT;
        case GqlClientLifecycleStage.PROD:
            return Stages.prod;
        default:
            return undefined;
    }
};

export const convertNarrationRecordingErrorReason = (errorReason: GqlClientNarrationRecordingErrorReason): NarrationRecordingErrorReason => {
    switch (errorReason) {
        case GqlClientNarrationRecordingErrorReason.BADINTONATION:
            return NarrationRecordingErrorReason.BADINTONATION;
        case GqlClientNarrationRecordingErrorReason.CORRUPTED:
            return NarrationRecordingErrorReason.CORRUPTED;
        case GqlClientNarrationRecordingErrorReason.OTHER:
            return NarrationRecordingErrorReason.OTHER;
        case GqlClientNarrationRecordingErrorReason.TEXTDOESNOTMATCH:
            return NarrationRecordingErrorReason.TEXTDOESNOTMATCH;
        default:
            return undefined;
    }
};

export const convertNarrationRecordingErrorReasonReverse = (errorReason: NarrationRecordingErrorReason): GqlClientNarrationRecordingErrorReason => {
    switch (errorReason) {
        case NarrationRecordingErrorReason.BADINTONATION:
            return GqlClientNarrationRecordingErrorReason.BADINTONATION;
        case NarrationRecordingErrorReason.CORRUPTED:
            return GqlClientNarrationRecordingErrorReason.CORRUPTED;
        case NarrationRecordingErrorReason.OTHER:
            return GqlClientNarrationRecordingErrorReason.OTHER;
        case NarrationRecordingErrorReason.TEXTDOESNOTMATCH:
            return GqlClientNarrationRecordingErrorReason.TEXTDOESNOTMATCH;
        default:
            return undefined;
    }
};