import Story from "./Story";
import HubEditorUtils from "../../components/HubEditor/HubEditorsUtils";
import type { Program } from "../../../common/types/program";
import type { Story as StoryJSON } from "../../../common/types/story";

const StoryManager = {
    /**
     * Creates new story, returns a shallow copy of the provided story
     * @param storyData - the story properties
     */
    createNewStory: function(storyData: Partial<StoryJSON>, program: Program) {
        return Object.freeze(new Story(storyData, program));
    },

    /**
     * Update the name of an existing story, returns a shallow copy of the provided story
     * with updated filed
     * @param story - The story to be updated
     * @param name - The new name to be stored in the story
     */
    changeStoryName: function(story, name) {
        return Object.freeze({
            ...story,
            name: name.trim()
        });
    },

    /**
     * Updates the description of an existing story, returns a shallow copy of the provided story
     * @param story - The story to be updated
     * @param desc - The new value of description to be stored in the story
     */
    changeStoryDescription: function(story, desc) {
        return Object.freeze({
            ...story,
            description: desc
        });
    },

    /**
     * Update the storyBackgroundAssetTypes of an existing story, returns a shallow copy of the provided story
     * with updated filed
     * @param story - The story to be updated
     * @param storyBackgroundAssetType - The new storyBackgroundAssetTypes to be stored in the story
     */
    changeStoryBackgroundAssetTypes: function(story, storyBackgroundAssetType) {
        return Object.freeze({
            ...story,
            backgroundAssetType: storyBackgroundAssetType
        });
    },

    /**
     * Create a copy of the story, returns a shallow copy of the provided story
     * @param story - The story to be updated
     * @param initialDecisionPointId - the new initialDecisionPointId
     */
    duplicateStory: function(oldStory: StoryJSON, name): StoryJSON {
        return Object.freeze({
            ...oldStory,
            name,
            id: undefined,
            graphQLId: undefined
        });
    },

    /**
     * The function validates story name
     * @param name - story name
     * @param storiesArray - all other stories in program
     * @return {{storyNameValid: boolean, storyNameHelperText: string}}
     */
    validateStoryName: (name, storiesArray, currentStoryId = undefined) => {
        let storyNameValid = true;
        let storyNameHelperText = "";

        if (!name) {
            storyNameValid = false;
            storyNameHelperText = "Enter a story name";
        }
        else if (!HubEditorUtils.isAlphaNumeric(name)) {
            storyNameValid = false;
            storyNameHelperText = "Only A-Z, 0-9";
        }
        else if (storiesArray.find((s) => s.name.toLowerCase() === name.toLowerCase() && s.id !== currentStoryId)) {
            storyNameValid = false;
            storyNameHelperText = "Story name is unique in a program";
        }

        return { storyNameValid, storyNameHelperText };
    },

    /**
     * The function validates story description
     * @param description - story description
     * @return {{storyDescriptionValid: boolean, storyDescriptionHelperText: string}}
     */
    validateStoryDescription: (description) => {
        let storyDescriptionValid = true;
        let storyDescriptionHelperText = "";

        return { storyDescriptionValid, storyDescriptionHelperText };
    }
};

export default StoryManager;
