import type { HOC, IFrameworkEntity, onSaveArgs, Roles } from "../../types";
import type {
    GqlClientAudiencePersonalization,
    GqlClientCreateStoryMutationVariables,
    GqlClientCreateStoryWithReachAndScenesInput,
    GqlClientStory,
    GqlClientStorySupportersFragment,
    GqlClientUpdateStoryForFrameworkInput,
    GqlClientUpdateStoryForFrameworkMutationVariables,
    GqlClientUpdateStoryMutationVariables,
    GqlClientUpdateStoryWithReachAndScenesInput
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { StorySupportersFragmentDoc, withCreateStory, withDeleteStory, withUpdateStory, withUpdateStoryForFramework } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import type { ApolloClient, ApolloError } from "@apollo/client";
import type { CardData } from "../../../../components/framework/EntityCard";
import { getRole, initCardData } from "../../utils";
import type { StoryDialogProps } from "../../../../components/framework/dialogs/StoryDialog";
import type { withGqlMutationProps } from "../../../../components/framework/WithGqlMutations";
import type { ReorderEntitiesDialogProps } from "../../../../components/framework/dialogs/ReorderEntitiesDialog";

const onSave = (args: onSaveArgs<GqlClientStory, GqlClientCreateStoryMutationVariables, GqlClientUpdateStoryMutationVariables | GqlClientUpdateStoryForFrameworkMutationVariables>) => {
    const { data, createCB, updateCB, updateDisplaySceneOrderCB, programVersionId, dryRun } = args;
    const { id, updated, name, description, channels, scenes, touchpoints, displaySceneOrder } = data;

    if (updateDisplaySceneOrderCB) {
        const updatedSceneOrder: GqlClientUpdateStoryForFrameworkInput = { id, updated, displaySceneOrder };
        updateDisplaySceneOrderCB({ variables: { input: updatedSceneOrder, dryRun } });
        return;
    }

    const isNew = !id;

    const channelIds = channels.map(({ id }) => id);
    const touchpointIds = touchpoints.map(({ id }) => id);
    const sceneIds = scenes.map(({ id }) => id);

    if (isNew) {
        const newStory: GqlClientCreateStoryWithReachAndScenesInput = { name, description, sceneIds, channelIds, touchpointIds };
        createCB({ variables: { programVersionId, input: newStory } });
    }
    else {
        const updatedStory: GqlClientUpdateStoryWithReachAndScenesInput = { id, updated, name, description, channelIds, touchpointIds, sceneIds };
        updateCB({ variables: { input: updatedStory, dryRun } });
    }
};

const getSupporters = (story: GqlClientStory, client: ApolloClient<any>): string[] => {
    /** supporters for story:
     * channels related to the story
     * touchpoints related to the story
     * scenes related to the story
     * kpis that are related to that scene
     * parent goals of those kpis
     * values that are related to the scene
     * audiences related to the scene
     * parent audiences of those values
     * data elements related to those scenes
     * channels related to those scenes
     * touchpoints related to those scenes
     */
    let supporterIds = [];
    const { channels, touchpoints, scenes } = story;
    channels.forEach(({ id }) => supporterIds.push(id));
    touchpoints.forEach(({ id }) => supporterIds.push(id));
    scenes.forEach(({ id, __typename }) => {
        supporterIds.push(id);
        const sceneData: GqlClientStorySupportersFragment = client.readFragment({
            id: `${__typename}:${id}`,
            fragment: StorySupportersFragmentDoc
        });
        if (sceneData) {
            sceneData.kpis.forEach(({ id, goal }) => {
                supporterIds.push(id);
                supporterIds.push(goal.id);
            });
            sceneData.feedDataElements.forEach(({ id }) => supporterIds.push(id));
            sceneData.personalizations.forEach((personalization) => {
                const { audiencePersonalizationValues, audience } = personalization as GqlClientAudiencePersonalization;
                audience && supporterIds.push(audience.id);
                audiencePersonalizationValues && audiencePersonalizationValues.forEach(({ audienceValue }) => supporterIds.push(audienceValue.id));
                audience && audience.feedDataElements && supporterIds.push(...audience.feedDataElements.map(({ id }) => id));
            });
        }
    });
    return supporterIds;
};

const getCardData = (story: GqlClientStory, roles?: Roles): CardData => {
    let storyData: CardData = initCardData(story);
    storyData.contents = [{ title: "Description:", descriptionText: story.description ? `${story.description}` : "None" }];

    if (roles) storyData.role = getRole(story, roles);
    return storyData;
};

const Story: IFrameworkEntity<StoryDialogProps> & { mutations: { updateSceneOrder: HOC } } = {
    mutations: {
        create: withCreateStory<withGqlMutationProps<StoryDialogProps>>({
            name: "createStory",
            options: ({ setMutationResult, setError }) => ({
                onCompleted: ({ createStoryWithReachAndScenes }) => setMutationResult(createStoryWithReachAndScenes.result),
                onError: (error: ApolloError) => {
                    setError(error);
                }
            })
        }),
        update: withUpdateStory<withGqlMutationProps<StoryDialogProps>>({
            name: "updateStory",
            options: ({ setMutationResult, setError }) => ({
                onCompleted: ({ updateStoryWithReachAndScenes }) => setMutationResult(updateStoryWithReachAndScenes.result),
                onError: (error: ApolloError) => {
                    setError(error);
                }
            })
        }),
        updateSceneOrder: withUpdateStoryForFramework<withGqlMutationProps<ReorderEntitiesDialogProps>>({
            name: "updateSceneOrderInStory",
            options: ({ setMutationResult, setError }) => ({
                onCompleted: ({ updateStoryForFramework }) => {
                    setMutationResult(updateStoryForFramework.result);
                },
                onError: (error: ApolloError) => {
                    setError(error);
                }
            })
        }),
        delete: withDeleteStory<withGqlMutationProps<StoryDialogProps>>({ name: "deleteStory" })
    },
    getSupporters,
    getCardData,
    onSave
};

export default Story;
