import type { IFrameworkEntity, onSaveArgs, Roles } from "../../types";
import type {
    GqlClientCreateTouchpointInput,
    GqlClientCreateTouchpointMutationVariables,
    GqlClientTouchpoint,
    GqlClientTouchpointSupportersFragment,
    GqlClientUpdateTouchpointInput,
    GqlClientUpdateTouchpointMutationVariables
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { TouchpointSupportersFragmentDoc, withCreateTouchpoint, withDeleteTouchpoint, withUpdateTouchpoint } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import type { TouchpointDialogProps } from "../../../../components/framework/dialogs/TouchpointDialog";
import type { ApolloClient, ApolloError } from "@apollo/client";
import type { CardData } from "../../../../components/framework/EntityCard";
import { getPersonalizationDescriptionOrUsedInScenes, getPersonalizationIconsForDescription, getPersonalizationIconsForHeader, getRole, initCardData } from "../../utils";
import type { withGqlMutationProps } from "../../../../components/framework/WithGqlMutations";

const onSave = (args: onSaveArgs<Partial<GqlClientCreateTouchpointInput & GqlClientUpdateTouchpointInput>, GqlClientCreateTouchpointMutationVariables, GqlClientUpdateTouchpointMutationVariables>) => {
    const { data, createCB, updateCB, programVersionId, dryRun } = args;
    const { id, name, description, updated } = data;
    const isNew: boolean = !id;
    if (isNew) {
        const newTouchpoint: GqlClientCreateTouchpointInput = { name, description };
        createCB({ variables: { programVersionId, input: newTouchpoint } });
    }
    else {
        const updatedTouchpoint: GqlClientUpdateTouchpointInput = { id, name, description, updated };
        updateCB({ variables: { input: updatedTouchpoint, dryRun } });
    }
};

const getSupporters = (touchpoint: GqlClientTouchpoint, client: ApolloClient<any>) => {
    /** supporters for touchpoints:
     * stories related to the touchpoint
     * scenes that uses touchpoint as personalization
     * kpis that are related to those scenes
     * parent goals of those kpis
     * additional stories that are related to those scenes
     */
    let supporterIds = [];
    touchpoint.stories.forEach(({ id }) => supporterIds.push(id));
    touchpoint.touchpointPersonalizations.forEach(({ scene }) => {
        supporterIds.push(scene.id);
        const sceneData: GqlClientTouchpointSupportersFragment = client.readFragment({
            id: `${scene.__typename}:${scene.id}`,
            fragment: TouchpointSupportersFragmentDoc
        });
        sceneData.kpis.forEach(({ id, goal }) => {
            supporterIds.push(id);
            supporterIds.push(goal.id);
        });
        sceneData.stories.forEach(({ id }) => supporterIds.push(id));
    });
    return supporterIds;
};

const getCardData = (touchpoint: GqlClientTouchpoint, roles?: Roles): CardData => {
    let touchpointData: CardData = initCardData(touchpoint);
    touchpointData.contents = [{ title: "Description:", descriptionText: touchpoint.description ? `${touchpoint.description}` : "None" }];

    if (roles) {
        touchpointData.role = getRole(touchpoint, roles);
        if (touchpoint.touchpointPersonalizations.length) {
            const uniquePersonalizationTypes: Set<string> = new Set([]);
            touchpoint.touchpointPersonalizations.forEach((pers) => pers.type.forEach((type) => uniquePersonalizationTypes.add(type)));

            const personalizationTypes = Array.from(uniquePersonalizationTypes);
            touchpointData.header.icons = getPersonalizationIconsForHeader(personalizationTypes);
            personalizationTypes.length && touchpointData.contents.push({ title: "Personalization type:", descriptionIcons: getPersonalizationIconsForDescription(personalizationTypes) });
            touchpointData.contents.push(getPersonalizationDescriptionOrUsedInScenes(touchpoint.touchpointPersonalizations, roles.leadId));
        }
    }

    return touchpointData;
};

const Touchpoint: IFrameworkEntity<TouchpointDialogProps> = {
    mutations: {
        create: withCreateTouchpoint<withGqlMutationProps<TouchpointDialogProps>>({
            name: "createTouchpoint",
            options: ({ setMutationResult, setError }) => ({
                onCompleted: ({ createTouchpoint }) => {
                    setMutationResult(createTouchpoint.result);
                },
                onError: (error: ApolloError) => {
                    setError(error);
                }
            })
        }),
        update: withUpdateTouchpoint<withGqlMutationProps<TouchpointDialogProps>>({
            name: "updateTouchpoint",
            options: ({ setMutationResult, setError }) => ({
                onCompleted: ({ updateTouchpoint }) => {
                    setMutationResult(updateTouchpoint.result);
                },
                onError: (error: ApolloError) => {
                    setError(error);
                }
            })
        }),
        delete: withDeleteTouchpoint<withGqlMutationProps<TouchpointDialogProps>>({
            name: "deleteTouchpoint"
        })
    },
    onSave,
    getSupporters,
    getCardData
};

export default Touchpoint;
