/**
 creative recording assets compose information from postgres and dynamo.
 */
import type { AssetHistoryItem, RecordingAsset } from "../../../../common/types/asset";
import { AssetTypes, MediaTypes, NarrationRecordingErrorReason, RecordingOrigin } from "../../../../common/types/asset";
import type { GqlClientGetAllCreativesNarrationRecordingsQuery } from "../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { GqlClientNarrationRecordingErrorReason } from "../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { STATUS_REQUEST } from "../../../components/legacyCommon/Consts";
import type { CreativeDataElementsUsedInNarrationsOverrides, SceneAndNarrationData } from "../../vlx/editorLogicUtils";

type QueryProgram = GqlClientGetAllCreativesNarrationRecordingsQuery["program"];
type QueryCreative = QueryProgram["creatives"][0];
type QueryCreativeVersionDraft = QueryCreative["creativeVersionDraft"];
type QueryCreativeNarrationRecording = QueryCreativeVersionDraft["creativeNarrationRecordings"][0]
type QueryCreativeNarrationRecordingVersion = QueryCreativeNarrationRecording["creativeNarrationRecordingVersions"][0]

export const processCreativeRecordingAssets = (
    allCreativesNarrationRecordings: GqlClientGetAllCreativesNarrationRecordingsQuery,
    creativeDataElementsUsedInNarrationsOverrides: CreativeDataElementsUsedInNarrationsOverrides,
): RecordingAsset[] => {
    const { program: { creatives } } = allCreativesNarrationRecordings;

    let creativeRecordingAssets: RecordingAsset[] = [];
    creatives.forEach((creative: QueryCreative) => {
        if (creative.creativeVersionDraft.creativeNarrationRecordings.length) {
            creative.creativeVersionDraft.creativeNarrationRecordings.forEach((creativeRecording: QueryCreativeNarrationRecording) => {
                // handle ids
                const creativeName = creative.name;
                const { rfr: creativeRfr } = creative.creativeVersionDraft;

                // handle sceneAndNarrationData
                const sceneAndNarrationDatas: SceneAndNarrationData[] = creativeDataElementsUsedInNarrationsOverrides[creativeRecording.cdeId];
                const sceneAndNarrationData: SceneAndNarrationData = sceneAndNarrationDatas && sceneAndNarrationDatas.length ? sceneAndNarrationDatas[0] : emptySceneAndNarrationData;

                // transform creative recording
                const recAsset: RecordingAsset = transformCreativeNarrationRecording(creativeRecording, creativeName, creativeRfr, sceneAndNarrationData);
                creativeRecordingAssets.push(recAsset);
            });
        }
    });

    return creativeRecordingAssets;
};

const transformCreativeNarrationRecording = (
    creativeNarrationRecording: QueryCreativeNarrationRecording,
    creativeName: string,
    creativeRfr: number,
    sceneAndNarrationData: SceneAndNarrationData
): RecordingAsset => {
    const { id, filename, created, narrationText, updated, rfr, creativeNarrationRecordingActiveVersion, creativeNarrationRecordingVersions } = creativeNarrationRecording;
    const { sceneName, narrationLetter } = sceneAndNarrationData;
    const recordingStatus = creativeNarrationRecordingActiveVersion ? creativeNarrationRecordingActiveVersion.status : STATUS_REQUEST;
    const assetHistory = getAssetHistory(creativeNarrationRecordingVersions, `${filename}.mp3`);
    let activeVersionIndex;
    if (creativeNarrationRecordingActiveVersion) {
        activeVersionIndex = assetHistory.find((a) => a.id === creativeNarrationRecordingActiveVersion.id).version;
    }
    else {
        activeVersionIndex = 0;
    }

    const recordingErrorReason = creativeNarrationRecordingActiveVersion ? getNarrationRecordingErrorReason(creativeNarrationRecordingActiveVersion.errorReason) : NarrationRecordingErrorReason.OTHER;

    return {
        // Asset data
        activeVersion: activeVersionIndex, // active version index
        activeVersionId: creativeNarrationRecordingActiveVersion ? creativeNarrationRecordingActiveVersion.id : "",
        assetId: id,
        createdTime: new Date(created).getTime(),
        filename: `${filename}.mp3`,
        inUse: calcInUse(rfr, creativeRfr),
        mediaType: MediaTypes.Audio,
        name: narrationText,
        type: AssetTypes.recording,
        updatedTime: new Date(updated).getTime(),
        version: creativeNarrationRecordingVersions ? creativeNarrationRecordingVersions.length : 0,

        // RecordingAsset data
        RFR: rfr,
        creativeRFR: creativeRfr,
        status: recordingStatus,
        recordingErrorReason: recordingErrorReason,
        recordingStatus: recordingStatus,
        origin: RecordingOrigin.Creative,
        narrationMetadata: {
            text: narrationText,
            sceneName: sceneName,
            narrationPart: narrationLetter,
            contentSet: creativeName
        },
        history: { Items: assetHistory },
        updated: updated,
        activeVersionUpdated: creativeNarrationRecordingActiveVersion ? creativeNarrationRecordingActiveVersion.updated : "",
        downloadSelf: creativeNarrationRecordingActiveVersion ? creativeNarrationRecordingActiveVersion.url : undefined
    };
};

const calcInUse = (recordingRfr: number, creativeRfr: number): boolean => {
    return recordingRfr >= creativeRfr;
};

const getAssetHistory = (creativeRecordingAssetVersions: Array<QueryCreativeNarrationRecordingVersion>, filename: string): AssetHistoryItem[] => {
    let result: AssetHistoryItem[] = [];
    if (creativeRecordingAssetVersions) {
        result = creativeRecordingAssetVersions
            .map((version, index) => {
                return {
                    id: version.id,
                    version: index + 1,
                    format: version.format,
                    updateTime: new Date(version.created).getTime(),
                    mediaType: MediaTypes.Audio,
                    type: AssetTypes.recording,
                    size: version.size,
                    recordingStatus: version.status,
                    location: version.location,
                    mediaSubType: version.mediaSubType,
                    uploadedBy: version.createdBy,
                    downloadSelf: version.url,
                    filename: filename,
                    recordingErrorReason: getNarrationRecordingErrorReason(version.errorReason)
                };
            })
            .reverse();
    }
    return result;
};

const emptySceneAndNarrationData: SceneAndNarrationData = {
    sceneId: "",
    narrationId: "",
    sceneName: "",
    narrationLetter: "A"
};

export const getNarrationRecordingErrorReason = (errorReason: GqlClientNarrationRecordingErrorReason): NarrationRecordingErrorReason => {
    switch (errorReason) {
        case GqlClientNarrationRecordingErrorReason.BADINTONATION: {
            return NarrationRecordingErrorReason.BADINTONATION;
        }
        case GqlClientNarrationRecordingErrorReason.CORRUPTED: {
            return NarrationRecordingErrorReason.CORRUPTED;
        }
        case GqlClientNarrationRecordingErrorReason.TEXTDOESNOTMATCH: {
            return NarrationRecordingErrorReason.TEXTDOESNOTMATCH;
        }
        case GqlClientNarrationRecordingErrorReason.OTHER: {
            return NarrationRecordingErrorReason.OTHER;
        }
        default: {
            return NarrationRecordingErrorReason.OTHER;
        }
    }
};

export const getGqlNarrationRecordingErrorReason = (recordingErrorReason: NarrationRecordingErrorReason): GqlClientNarrationRecordingErrorReason => {
    switch (recordingErrorReason) {
        case NarrationRecordingErrorReason.BADINTONATION: {
            return GqlClientNarrationRecordingErrorReason.BADINTONATION;
        }
        case NarrationRecordingErrorReason.CORRUPTED: {
            return GqlClientNarrationRecordingErrorReason.CORRUPTED;
        }
        case NarrationRecordingErrorReason.TEXTDOESNOTMATCH: {
            return GqlClientNarrationRecordingErrorReason.TEXTDOESNOTMATCH;
        }
        case NarrationRecordingErrorReason.OTHER: {
            return GqlClientNarrationRecordingErrorReason.OTHER;
        }
        default: {
            return GqlClientNarrationRecordingErrorReason.OTHER;
        }
    }
};

export const resolveActiveVersionId = (assetHistory: AssetHistoryItem[], activeVersionIdx: number): string => assetHistory.find(({ version }) => version === activeVersionIdx).id;
