import type { Context, EntityInstance, EntityTypes, IEntity } from "../entities/definitions";
import { RecordStatus } from "../entities/definitions";
import type { IDiffTable } from "./diffTable";
import DiffTable from "./diffTable";
import type { IDiffTableRecord } from "./diffTableRecord";
import DiffTableRecord from "./diffTableRecord";
import type { DiffModel } from "./diffUtils";

export enum ChangeType {
    Add = "add",
    Edit = "edit",
    Delete = "delete",
    None = "none"
}

// DiffResult type represent the changes in an entity
export type DiffResult = {
    type: EntityTypes;
    id: string;
    name: string;
    changeType: ChangeType;
    changes: ChangeDetail[];
    isShown: boolean;
};

// ChangeDetails type is used to describe an a collection of changes
export type ChangeDetail = {
    changeType: ChangeType;
    details: string;
    children: DiffResult[];
};

export type GetDiffResultFunc = (type: EntityTypes, id: string) => DiffResult;
export type DiffFunc = (getDiffResult: GetDiffResultFunc) => DiffResult;

export type StoreDiffModel = {
    currentVersion: number | "draft";
    previousVersion: number;
    model: DiffModel;
};

export default class DiffManager {
    private readonly entities: IEntity[];

    constructor(entities: IEntity[]) {
        this.entities = entities;
    }

    public init = (context: Context, previousContext: Context): IDiffTable => {
        let table: DiffTable = new DiffTable();

        //Populate table with instances
        this.entities &&
            this.entities.forEach((entity: IEntity) => {
                let entityInstances: EntityInstance[] = entity.collector({ context, previousContext });
                entityInstances &&
                    entityInstances.forEach((entityInstance: EntityInstance) => {
                        const tableKey: string = DiffTable.getTableKey(entity.getType(), entityInstance.id);
                        const record: DiffTableRecord = new DiffTableRecord(entityInstance.diff);
                        table.putByKey(tableKey, record);
                    });
            });

        // Fill table with diff results
        let allRecords: IDiffTableRecord[] = table.getAllRecords();
        allRecords.forEach((record: IDiffTableRecord) => {
            if (!record.diffResult) {
                record.diffResult = this.fulfillRecord(table, record);
            }
        });

        return table;
    };

    private fulfillRecord = (table: IDiffTable, record: IDiffTableRecord): DiffResult => {
        record.status = RecordStatus.CALCULATING;
        let result: DiffResult = record.diff((type: EntityTypes, id: string) => this.resolveDiffResultById(table, type, id));
        record.status = RecordStatus.DONE;
        return result;
    };

    private resolveDiffResultById = (table: IDiffTable, type: EntityTypes, id: string): DiffResult => {
        let record: IDiffTableRecord = table.getByKey(DiffTable.getTableKey(type, id));
        if (!record) {
            return {
                type,
                id,
                name: `not found? ${id}`,
                changeType: ChangeType.None,
                changes: [],
                isShown: false
            };
        }

        let diffResult: DiffResult = record.diffResult;
        if (record.status === RecordStatus.CALCULATING) {
            diffResult = {
                type,
                id,
                name: `calculating? ${id}`,
                changeType: ChangeType.None,
                changes: [],
                isShown: false
            };
            return diffResult;
        }

        if (!diffResult) {
            diffResult = this.fulfillRecord(table, record);
            record.diffResult = diffResult;
        }

        return diffResult;
    };

    public readDiffResultById = (table: IDiffTable, type: EntityTypes, id: string): DiffResult => {
        const record: IDiffTableRecord = table.getByKey(DiffTable.getTableKey(type, id));
        if (record) {
            return record.diffResult;
        }
    };

    /**
     * resolve the change of a parent from a list of it's children's changes.
     * @param changes - list of changes
     * @param assumeChildChangeType - used when there's a 1:1 link between parent and child.
     * for example a placeholder and it's logic object or analytics and it's logic object.
     * @returns 'Edit' if there's a change in one of the children, unless assumeChildChange and all children are add or delete
     */
    public static getChangeType(changes: ChangeType[], assumeChildChangeType: boolean): ChangeType {
        if (!changes.length) {
            return ChangeType.None;
        }
        const allSameType: boolean = changes.every((change: ChangeType) => change === changes[0]);
        if (allSameType && changes[0] === ChangeType.None) {
            return ChangeType.None;
        }
        else if (allSameType) {
            return assumeChildChangeType ? changes[0] : ChangeType.Edit;
        }
        else {
            // filter out None changes and try again
            const filteredChanges: ChangeType[] = changes.filter((change: ChangeType) => change !== ChangeType.None);
            const allSameTypeFiltered: boolean = filteredChanges.every((change: ChangeType) => change === filteredChanges[0]);
            if (allSameTypeFiltered) {
                return assumeChildChangeType ? filteredChanges[0] : ChangeType.Edit;
            }
            else {
                return ChangeType.Edit;
            }
        }
    }
}
