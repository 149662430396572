import { createAction } from "redux-actions";
import { reportError } from "../common/commonActions";
import type AdminServices from "./adminServices";
import type { UserData } from "../../../common/types/userData";
import type { SuccessMessageType } from "./adminTypes";
import { SuccessAction } from "./adminTypes";
import type { ResponseStatusType } from "../../../common/types/restApiTypes";
import { AnalyticsOperation } from "../../../common/types/restApiTypes";

export const GET_ALL_PROJECTS_SUCCESS = "GET_ALL_PROJECTS_SUCCESS";
export const IS_LOADING_ALL_PROJECTS = "IS_LOADING_ALL_PROJECTS";
export const getAllProjectsSuccess = createAction(GET_ALL_PROJECTS_SUCCESS, projects => ({ projects: projects }));
export const isLoadingAllProjects = createAction(IS_LOADING_ALL_PROJECTS, status => status);

export const IS_LOADING_USERS = "IS_LOADING_USERS";
export const isLoadingUsers = createAction(IS_LOADING_USERS, status => status);
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const fetchAllUsersSuccess = createAction(FETCH_USERS_SUCCESS, users => users);

export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const updateUserSuccess = createAction(UPDATE_USER_SUCCESS, userData => userData);

export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const addUserSuccess = createAction(ADD_USER_SUCCESS, userData => userData);

export const IS_FETCHING_USER = "IS_FETCHING_USER";
export const isFetchingUser = createAction(IS_FETCHING_USER, status => status);
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const fetchUserSuccess = createAction(FETCH_USER_SUCCESS, userName => userName);

export const FETCH_ROLES_SUCCESS = "FETCH_ROLES_SUCCESS";
export const fetchRolesSuccess = createAction(FETCH_ROLES_SUCCESS, roles => roles);

export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const deleteUserSuccess = createAction(DELETE_USER_SUCCESS, userName => userName);

export const SET_SUCCESS_MESSAGE = "SET_SUCCESS_MESSAGE";
export const setSuccessMessageDispatcher = createAction(SET_SUCCESS_MESSAGE, message => message);

export const IS_SETTING_FEATURE_FLAG = "IS_SETTING_FEATURE_FLAG";
export const isSettingFeatureFlag = createAction(IS_SETTING_FEATURE_FLAG, status => status);

export const setSuccessMessage = message => (dispatch, state, services) => {
    dispatch(setSuccessMessageDispatcher(message));
};

export const getAllUsers = () => {
    return (dispatch, state, services: { adminServices: AdminServices }) => {
        dispatch(isLoadingUsers(true));
        services.adminServices.listUsers()
            .then((data) => {
                dispatch(fetchAllUsersSuccess(data));
                dispatch(isLoadingUsers(false));
            })
            .catch((err) => {
                dispatch(reportError(err));
                dispatch(isLoadingUsers(false));
            });
    };
};

export const updateUser = (userData: UserData) => {
    return (dispatch, state, services: { adminServices: AdminServices }) => {
        services.adminServices.updateUser(userData)
            .then((response) => {
                const successMessage: SuccessMessageType = { success: SuccessAction.UPDATED };
                successMessage.warning = extractTableauWarning(response.status);

                dispatch(updateUserSuccess(response.data));
                dispatch(setSuccessMessageDispatcher(successMessage));
            })
            .catch((err) => {
                dispatch(setSuccessMessageDispatcher(null));
                dispatch(reportError(err));
            });
    };
};

export const addUser = (userData: UserData) => {
    return (dispatch, state, services: { adminServices: AdminServices }) => {
        services.adminServices.addUser(userData)
            .then((response) => {
                const successMessage: SuccessMessageType = { success: SuccessAction.CREATED };
                successMessage.warning = extractTableauWarning(response.status);

                dispatch(addUserSuccess(response.data));
                dispatch(setSuccessMessageDispatcher(successMessage));
            })
            .catch((err) => {
                dispatch(setSuccessMessageDispatcher(null));
                dispatch(reportError(err));
            });
    };
};

export const getUser = (userName: string) => {
    return (dispatch, state, services: { adminServices: AdminServices }) => {
        dispatch(isFetchingUser(true));
        services.adminServices.getUser(userName)
            .then((data) => {
                dispatch(fetchUserSuccess(data));
                dispatch(isFetchingUser(false));
                dispatch(setSuccessMessageDispatcher(null));
            })
            .catch((err) => {
                dispatch(reportError(err));
                dispatch(isFetchingUser(false));
                dispatch(setSuccessMessageDispatcher(null));
            });
    };
};

export const getAllRoles = () => {
    return (dispatch, state, services: { adminServices: AdminServices }) => {
        services.adminServices.listRoles()
            .then((data) => {
                dispatch(fetchRolesSuccess(data));
            })
            .catch((err) => {
                dispatch(reportError(err));
            });
    };
};

export const deleteUser = (userName: string) => {
    return (dispatch, state, services: { adminServices: AdminServices }) => {
        services.adminServices.deleteUser(userName)
            .then((response) => {
                const successMessage: SuccessMessageType = { success: SuccessAction.DELETED };
                successMessage.warning = extractTableauWarning(response.status);
                dispatch(deleteUserSuccess(response.data));
                dispatch(setSuccessMessageDispatcher(successMessage));
            })
            .catch((err) => {
                dispatch(setSuccessMessageDispatcher(null));
                dispatch(reportError(err));
            });
    };
};

export const userResetPassword = (userName: string, cb?: (password: string) => void) => {
    return (dispatch, state, services: { adminServices: AdminServices }) => {
        services.adminServices.userResetPassword(userName)
            .then((response) => {
                if (typeof cb === "function") {
                    cb(response.password);
                }
            })
            .catch((err) => {
                dispatch(reportError(err));
            });
    };
};

export const reActivateUser = (userData: UserData) => {
    return (dispatch, state, services: { adminServices: AdminServices }) => {
        let deleteUserTableauWarning;
        services.adminServices.deleteUser(userData.email)
            .then((deleteResponse) => {
                services.adminServices.addUser(userData)
                    .then((addResponse) => {
                        const successMessage: SuccessMessageType = { success: SuccessAction.RE_ACTIVATE };
                        successMessage.warning = extractTableauWarning(addResponse.status);
                        dispatch(updateUserSuccess(addResponse.data));
                        dispatch(setSuccessMessageDispatcher(successMessage));
                    })
                    .catch((err) => {
                        // In case only delete is successful and add fails
                        deleteUserTableauWarning = extractTableauWarning(deleteResponse.status);
                        dispatch(deleteUserSuccess(deleteResponse.data));
                        dispatch(setSuccessMessageDispatcher(null));
                        dispatch(reportError(err));
                    });
            })
            .catch((err) => {
                dispatch(setSuccessMessageDispatcher(null));
                dispatch(reportError(err));
            });
    };
};

export const getAllProjects = () => {
    return (dispatch, state, services: { adminServices: AdminServices }) => {
        dispatch(isLoadingAllProjects(true));
        services.adminServices.getAllProjects()
            .then((data) => {
                dispatch(getAllProjectsSuccess(data));
                dispatch(isLoadingAllProjects(false));
            })
            .catch((err) => {
                dispatch(isLoadingAllProjects(false));
                dispatch(reportError(err));
            });
    };
};


const extractTableauWarning = (responseData: ResponseStatusType): string => {
    if (!responseData?.hasErrorInAnalytics) {
        return undefined;
    }

    switch (responseData.analyticsOperation) {
        case AnalyticsOperation.Create:
        case AnalyticsOperation.Update:
        case AnalyticsOperation.Delete:
            return `Tableau user couldn't be ${responseData.analyticsOperation}d`;
        case AnalyticsOperation.Query:
            return "Tableau user status is unclear";
        default:
            return "Tableau user couldn't be handled";
    }
};
