import type { AnyEntitiesByContextById, AnyEntity, AnyEntityContextPair, GetDependencyIdFunction } from "./baseEntity";
import { BaseEntity } from "./baseEntity";
import type { Collector, CollectorArgs, EntityInstance, IEntity } from "./definitions";
import { EntityTypes } from "./definitions";
import type { GetValidationResultFunction, Issue, ValidationResult } from "../validations/validationManager";
import { IssueCodes, IssueSeverity } from "../validations/validationManager";
import StateReaderUtils from "../common/StateReaderUtils";
import type { ChangeDetail, DiffResult, GetDiffResultFunc } from "../versionDiff/diffManager";
import { ChangeType } from "../versionDiff/diffManager";
import { updateDiffResultWithChanges } from "../versionDiff/diffUtils";
import type { CustomAnalyticField } from "../../../common/types/analytics";

export default class AnalyticsEntity extends BaseEntity implements IEntity {
    constructor() {
        super(EntityTypes.ANALYTICS);
    }

    static generateId = (analyticFieldKey: string): string => {
        return `${analyticFieldKey}_analytics`;
    };

    collector: Collector = (args: CollectorArgs): EntityInstance[] => {
        const { context, previousContext } = args;
        let analytics: EntityInstance[] = [];

        const previousWireframes = previousContext && StateReaderUtils.getWireFrame(previousContext.state, previousContext.projectName, previousContext.stage, previousContext.version);
        const currentWireframes = StateReaderUtils.getWireFrame(context.state, context.projectName, context.stage, context.version);

        const previousAnalyticsFieldsArray: string[] = StateReaderUtils.getCustomAnalyticsFieldsArray(previousWireframes) || [];
        const currentAnalyticsFieldsArray: string[] = StateReaderUtils.getCustomAnalyticsFieldsArray(currentWireframes) || [];

        const previousAnalytics: AnyEntity[] = previousAnalyticsFieldsArray.map((aPrevAnalyticsKey: string) => ({
            analyticsId: aPrevAnalyticsKey,
            ...StateReaderUtils.getCustomAnalyticsFieldData(previousWireframes, aPrevAnalyticsKey)
        }));
        const currentAnalytics: AnyEntity[] = currentAnalyticsFieldsArray.map((aCurrentAnalyticsKey: string, index: number) => ({
            analyticsId: aCurrentAnalyticsKey,
            ...StateReaderUtils.getCustomAnalyticsFieldData(currentWireframes, aCurrentAnalyticsKey),
            index
        }));

        const analyticsMap: AnyEntitiesByContextById = this.entityListsToMap(previousAnalytics, currentAnalytics, (analyticObj) => analyticObj.analyticsId);
        analyticsMap.forEach((analyticsPair: AnyEntityContextPair, analyticFieldKey: string) => {
            let isNewAnalyticFieldData = Boolean(analyticsPair.currentEntity && analyticsPair.currentEntity.isNew);
            let index = analyticsPair.currentEntity && analyticsPair.currentEntity.index;
            analytics.push({
                id: AnalyticsEntity.generateId(analyticFieldKey),
                validate: (prevValidationResult: ValidationResult, getValidationResultFunction: GetValidationResultFunction): ValidationResult => {
                    return this.doValidate(analyticFieldKey, getValidationResultFunction, isNewAnalyticFieldData, index);
                },
                diff: (getDiffResult: GetDiffResultFunc): DiffResult => this.doDiff(getDiffResult, analyticFieldKey, currentWireframes?.customAnalyticFields)
            });
        });

        return analytics;
    };

    doValidate = (analyticFieldKey: string, getValidationResult: GetValidationResultFunction, isNewAnalyticFieldData: boolean, index: number): ValidationResult => {
        const type: EntityTypes = this.getType();
        const id: string = AnalyticsEntity.generateId(analyticFieldKey);
        let result: ValidationResult = { type, id, issues: [], name: `custom field #${index + 1}` };

        //validate analytics logic
        const getDependencyId: GetDependencyIdFunction = () => id;
        let issue: Issue = this.getDependenciesIssue([undefined], EntityTypes.LOGIC, getDependencyId, getValidationResult, IssueCodes.ANALYTICS_LOGIC_ERROR);
        if (issue) {
            result.issues.push(issue);
            result.severity = issue.severity;
        }
        if (isNewAnalyticFieldData) {
            result.issues.push({ code: IssueCodes.ANALYTICS_NAME_ERROR, severity: IssueSeverity.ERROR, details: {} });
            result.severity = IssueSeverity.ERROR;
        }

        return result;
    };

    doDiff = (getDiffResult: GetDiffResultFunc, analyticFieldKey: string, customAnalyticFields?: Record<string, CustomAnalyticField>): DiffResult => {
        const id: string = AnalyticsEntity.generateId(analyticFieldKey);
        let result: DiffResult = {
            type: this.getType(),
            id,
            name: (customAnalyticFields && customAnalyticFields[analyticFieldKey]) ? customAnalyticFields[analyticFieldKey].name : analyticFieldKey,
            changeType: ChangeType.None,
            changes: [],
            isShown: false
        };

        const getDependencyId: GetDependencyIdFunction = (id) => id;
        const change: ChangeDetail = this.getDependenciesDiff([id], EntityTypes.LOGIC, getDependencyId, getDiffResult, true);

        return updateDiffResultWithChanges(result, change);
    };
}
