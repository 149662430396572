"use strict";

import { LOGIC_MEDIA_TYPES, VALIDATION_STATUS } from "../consts";

/***
 * Constructs a simple validations which expects to receive an object
 * and run the provided test on it, if the result wouldn't be
 * successful an object will be returned with the provided message, the status and the item
 * @param text The text which will be assigned to the result if it would not be valid
 * @param test The test function (which might receive item, input type, array of data elements and an array of assets
 * @param skipItem - if set to true the failing item wont be added to the returned object (to avoid circular object structure)
 * @return {function(*=, *=, *=, *=)} the validation closure
 */
function validate(text, test, skipItem = false) {
    return (item, inputType, context) => {
        const result = test(item, inputType, context);
        // To allow validate functions run batches inside, if an object is returned
        // fill in a report and escalate to calc the status
        if (typeof result === "object" && result.status && result.status !== VALIDATION_STATUS.Valid) {
            return result;
        }

        if (result && result !== VALIDATION_STATUS.Valid) {
            return {
                status: result,
                message: text,
                item: skipItem ? null : item
            };
        }
    };
}

/**
 * Construct a validation on a single objects, which consists from
 * a list of other validations
 */
function validateBatch(text, selector, validations) {
    return (item, outputType: string = LOGIC_MEDIA_TYPES.String, context) => {
        const _report = runLinearValidations(validations, selector(item), outputType, context);
        const finalResult = escalate(_report);
        if (finalResult === VALIDATION_STATUS.Valid) return;
        return {
            status: finalResult,
            message: text,
            item: selector(item),
            report: _report
        };
    };
}

/**
 * The identity function (used below)
 * @param item - an item which would be returned
 */
const getIdentity = (item) => item;

/**
 * The defaultExtractor object (used below)
 * @returns object with "getItem", "getOutputType", "skipItem" - all are functions
 */
const defaultExtractor = {
    getItem: (item) => item,
    getOutputType: (outputType, item) => outputType,
    skipItem: (item) => false
};

/**
 * Construct a validation consisting from a bach of validations on an array of items
 * @param text The text which would be supplied to a failing test
 * @param selector A function which would pick the array on which
 * the tests would be run on from an item
 * @param validations An array of either validate, validateBatch,
 * or validateArrayBatch functions
 * @param emptyResult the default result to be retrieved in case of a failure to
 * initiate the tests
 * @param extractor - object that can extract value. see defaultExtractor for example
 * @return {function(*=)} The constructed validations closure,
 * ready to be run on an object and would return the following object
 * {
 *    message: <string - the text provided>,
 *   status: <VALIDATION_STATUS>,
 *   item: <object - the item which failed this batch>
 *   report: <Array - array of failed tests in similar structure>
 * }
 */
function validateArrayBatch(text, selector, validations, emptyResult = { status: VALIDATION_STATUS.Valid }, extractor = defaultExtractor) {
    return (item, outputType: string = LOGIC_MEDIA_TYPES.String, context) => {
        const items = selector(item);
        let _report = [emptyResult];
        if (items && Array.isArray(items) && items.length) {
            for (let i = 0; i < items.length; i++) {
                if (!extractor.skipItem(items[i])) {
                    _report.push(validateBatch(`${text} - #${i}`, getIdentity, validations)(extractor.getItem(items[i]), extractor.getOutputType(outputType, items[i]), context));
                }
            }
        }
        const finalResult = escalate(_report);
        if (finalResult === VALIDATION_STATUS.Valid) return;
        return {
            report: _report,
            status: finalResult,
            message: text,
            item: items
        };
    };
}

/**
 * @return The worst validation status Invalid > Partial > Valid
 */
function escalate(arrayOfResults) {
    if (!arrayOfResults || !arrayOfResults.length) {
        return VALIDATION_STATUS.Valid;
    }

    return arrayOfResults.reduce((accumulator, result) => {
        if (!result) return accumulator;
        if (accumulator === VALIDATION_STATUS.Invalid || result.status === VALIDATION_STATUS.Invalid) {
            return VALIDATION_STATUS.Invalid;
        }
        if (result.status === VALIDATION_STATUS.Partial) {
            return VALIDATION_STATUS.Partial;
        }
        return accumulator;
    }, VALIDATION_STATUS.Valid);
}

/***
 * This runs validations in a linear order so that the first failing (VALIDATION_STATUS.Invalid)
 * result in an array of validations will stop the following validations after it.
 * VALIDATION_STATUS.Partial are treated as warnings, VALIDATION_STATUS.Valid are skipped
 */
function runLinearValidations(validations, item, outputType, context) {
    let result = [];

    for (let i = 0; i < validations.length; i++) {
        const validationResult = validations[i](item, outputType, context);

        if (!validationResult) continue;

        result.push(validationResult);

        // Terminate after first failure
        if (validationResult.status === VALIDATION_STATUS.Invalid) {
            break;
        }
    }
    return result;
}

export { validate, validateBatch, validateArrayBatch, runLinearValidations, escalate, getIdentity };
