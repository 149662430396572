import { isValidColor } from "../../vlx/editorLogicUtils";

const FULL_CHANNEL_INTENSITY = 0xFF;
const MIN_CHANNEL_INTENSITY = 0x00;

type RGBAObj = {
    r: number;
    g: number;
    b: number;
    a?: number;
};

/**
 * describes a style
 * either one Tint (color) or gradient between two tints
 * Pascal case used since that's what RAAS expects.
 */
export type Style = {
    IsOneTint: boolean;
    Tint1: string;
    Tint2: string;
    Ratio: number;
};

const getRedFromColorInt = (colorInt: number): number => (colorInt >> 24) & 0xFF;

const getGreenFromColorInt = (colorInt: number): number => (colorInt >> 16) & 0xFF;

const getBlueFromColorInt = (colorInt: number): number => (colorInt >> 8) & 0xFF;

const getAlphaFromColorInt = (colorInt: number): number => colorInt & 0xFF;


const hexToRgb = (hex: string): RGBAObj => {
    const colorInt: number = parseInt(hex.replace("#", "") + "FF", 16);
    return {
        r: getRedFromColorInt(colorInt),
        g: getGreenFromColorInt(colorInt),
        b: getBlueFromColorInt(colorInt)
    };
};

const hexToRgba = (hex: string): RGBAObj => {
    const colorInt: number = parseInt(hex.replace("#", ""), 16);
    return {
        r: getRedFromColorInt(colorInt),
        g: getGreenFromColorInt(colorInt),
        b: getBlueFromColorInt(colorInt),
        a: getAlphaFromColorInt(colorInt)
    };
};

const hexToColorChannels = (hex: string) : RGBAObj => {
    if (hex.length == 9) {
        return hexToRgba(hex);
    }
    else {
        return hexToRgb(hex);
    }
};

const colorChannelsToHex = (channels: RGBAObj): string => {
    const handleSingleChannel = (channel: number): string => {
        const hx = channel.toString(16);
        return hx.padStart(2, "0");
    };
    return `#${handleSingleChannel(channels.r)}${handleSingleChannel(channels.g)}${handleSingleChannel(channels.b)}${channels.a !== undefined ? handleSingleChannel(channels.a) : ""}`;
};

const averageAlpha = (colorA: RGBAObj, colorB: RGBAObj) : number => {
    if (colorA.a === undefined && colorB.a === undefined) {
        return undefined;
    }
    
    return Math.round((colorA.a !== undefined ? colorA.a : FULL_CHANNEL_INTENSITY) + (colorB.a !== undefined ? colorB.a : FULL_CHANNEL_INTENSITY) / 2);
};

const averageRGBA = (colorA: RGBAObj, colorB: RGBAObj): RGBAObj => {
    return {
        r: Math.round((colorA.r + colorB.r) / 2),
        g: Math.round((colorA.g + colorB.g) / 2),
        b: Math.round((colorA.b + colorB.b) / 2),
        a: averageAlpha(colorA, colorB)
    };
};

const calcMiddleTint = (Tint1: string, Tint2: string): string => {
    return colorChannelsToHex(averageRGBA(hexToColorChannels(Tint1), hexToColorChannels(Tint2)));
};

export const styleIsEmpty = (style: Style | string): boolean => !style || (typeof style === "object" && !style.Tint1);

/**
 * render linear gradient for use in inline CSS.
 * @param style
 * @returns a color or gradient
 */
export const inlineColor = (style: Style | string): string => {
    if (!style) {
        return "";
    }
    if (!isValidColor(style)) {
        return "";
    }
    if (typeof style === "string") {
        return style;
    }
    if (typeof style === "object" && style.IsOneTint) {
        return `${style.Tint1}`;
    }
    else {
        const middleTint = calcMiddleTint(style.Tint1, style.Tint2);
        return `linear-gradient(to right, ${style.Tint1}, ${middleTint} ${style.Ratio * 100}%, ${style.Tint2})`;
    }
};

export const isFullyTransparentColor = (hex: string) : boolean => {
    const alpha = hexToColorChannels(hex).a;
    return alpha === MIN_CHANNEL_INTENSITY;
};

export const toggleTransparency = (color: string) => {
    const channels = hexToColorChannels(color);

    if (channels.a != undefined && channels.a !== MIN_CHANNEL_INTENSITY && channels.a !== FULL_CHANNEL_INTENSITY) {
        return color;
    }
    channels.a = channels.a === MIN_CHANNEL_INTENSITY ? FULL_CHANNEL_INTENSITY : MIN_CHANNEL_INTENSITY;
    return colorChannelsToHex(channels);
};
