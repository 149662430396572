import Immutable from "immutable";
import { getDataElementId, getDataElementDisplayName } from "../../../DataElements/DataElementsManager";
import { DATA_ELEMENT_VALUE_MISSING, DATA_ELEMENT_VALUE_OTHER, DATA_ELEMENT_VALUE_OTHER_OR_MISSING } from "../../../../components/legacyCommon/Consts";
import { DataElementOrigins } from "../../../../../common/types/dataElement";
import type { ValueSetSource, ValueSetProvider } from "../../../common/types";

export const createDataElementValueSetAdapter = (dataElement): ValueSetProvider => {
    const getSource = (): ValueSetSource => {
        return "DataElements";
    };

    const getId = () => {
        return getDataElementId(dataElement);
    };

    const getName = () => {
        return getDataElementDisplayName(dataElement);
    };

    const getType = () => {
        return dataElement.type;
    };

    const getValueSet = () => {
        let valueSet = dataElement.getValueSet() || [];

        let supportsMissing = false;
        let supportsOther = false;

        //FUTURE: In the future, this might be set by the user
        let distinguishBetweenOtherAndMissing = false;

        if (dataElement.origin === DataElementOrigins.Derived) {
            //Value set might include 'null', which is the derived element's way of saying that it can return nothing
            let valueSetWithoutNull = valueSet.filter((value) => value !== null);
            if (valueSetWithoutNull.length < valueSet.length) {
                valueSet = valueSetWithoutNull;
                supportsMissing = true;
            }
        }
        else if (dataElement.origin === DataElementOrigins.System) {
            //Value set cannot support missing or other
        }
        else if (dataElement.origin === DataElementOrigins.Feed || dataElement.origin === DataElementOrigins.Creative) {
            if (valueSet.length > 0) {
                supportsMissing = true;
                supportsOther = true;
            }
        }

        return Immutable.List(valueSet).withMutations((mutableValueSet) => {
            if (distinguishBetweenOtherAndMissing) {
                if (supportsOther) {
                    mutableValueSet.push({ id: "_vs_other", dn: DATA_ELEMENT_VALUE_OTHER });
                }

                if (supportsMissing) {
                    mutableValueSet.push({ id: "_vs_missing", dn: DATA_ELEMENT_VALUE_MISSING });
                }
            }
            else {
                if (supportsOther || supportsMissing) {
                    mutableValueSet.push({ id: "_vs_other_or_missing", dn: DATA_ELEMENT_VALUE_OTHER_OR_MISSING });
                }
            }
        });
    };

    return {
        getSource,
        getId,
        getName,
        getType,
        getValueSet
    };
};
