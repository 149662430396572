import type { VLXInputLogic, VLXValueInputLogic } from "../../../common/types/vlxTypes";
import { CURATED_REPOSITORY, LOGIC_DATA_TYPES, SCENES_REPOSITORY } from "./consts";
import { buildInputRules, buildValue, getInputLogicType } from "./logixBuilder";
import { buildDerivedDataElements } from "./dataElements";
import { getMappingTableNameFromValue } from "./editorLogicUtils";
import { buildActionableData } from "../Logics/ActionableDataUtils";
import traverse from "traverse";
import StateReaderUtils from "../common/StateReaderUtils";
import { defaultNarratorId, LogicContainers } from "../../../common/commonConst";
import { getAllValues } from "../Logics/Logic";
import { DataElementTags, findDataElementById, getDataElementDisplayName, getDataElementId, getDataElementType, hasTag } from "../DataElements/DataElementsManager";
import { getDynamicSystemDataElements, SYSTEM_DATA_STORY_ID } from "../../../common/SystemDataElementsUtils";
import { createJsonHierarchyForVar } from "./utils";
import { MediaTypes } from "../../../common/types/asset";
import type { DecisionPointValue, LogicJSON } from "../../../common/types/logic";
import { LogicContext, LogicType } from "../../../common/types/logic";
import type { Program } from "../../../common/types/program";
import { ProgramType } from "../../../common/types/program";
import type { DataElement } from "../../../common/types/dataElement";
import { DataElementOrigins } from "../../../common/types/dataElement";
import { featureFlagConst } from "@sundaysky/smartvideo-hub-config";
import { isAnEmptyObject } from "../../../common/generalUtils";
import { shouldUseVideoRatioAndQualityLogic } from "../Logics/StoryRatioAndQualityLogic";
import { generateVlxStorySettingProjectId } from "../../../common/vlxUtils";

const { ADS_REPORT_DE_TO_VIDEOSPEC } = featureFlagConst;

const defaultFormat = "m3u8";

type Transition = { duration: number; type: string; style?: string };

interface Background {
    image?: VLXInputLogic;
    audio?: VLXInputLogic;
    video?: VLXInputLogic;
}

interface LogicDataElements {
    [logicDataElementName: string]: VLXValueInputLogic;
}

interface AnalyticsDataSources {
    logicDataElements?: LogicDataElements;
}

interface Settings {
    analytics?: any;
    cache?: any;
    projectid?: any;
    output?: any;
    background?: Background;
    [LogicContext.StoryRatioAndQuality]?: any;
    settings?: {
        enableChaptering: boolean;
    }
}

export interface StoryLogicAsset {
    lineup: any;
    narrator?: any;
    settings?: Settings;
    creativeSettings?: any;
    sceneOrder?: any;
    tableData?: any;
    transition?: any;
    variables?: any;
    participatingSceneIds?: any;
    analyticsDataSources?: AnalyticsDataSources;
}

function createStoryLogic(
    wireframes,
    sceneIdToAssetName,
    program: Program,
    accountName: string,
    ignoreNextSceneLogic,
    currentStory,
    analytics?,
    assets?,
    narrationsVersion?,
    storiesVersion?,
    creativeVersion?,
    enabledFeatureFlags = []
): StoryLogicAsset {
    const programDataFields = {
        assets,
        dataElements: StateReaderUtils.getProjectDataElementsFromWireframes(wireframes)
    };

    let storyLogic: StoryLogicAsset = {
        lineup: undefined,
        narrator: undefined,
        settings: {
            analytics: undefined,
            cache: undefined,
            projectid: generateVlxStorySettingProjectId(accountName, program.displayName),
            output: {
                formats: {
                    video: {
                        format: defaultFormat
                    }
                }
            },
            background: {},
            settings: {
                enableChaptering: Boolean(program.chapteringEnabled)
            }
        },
        creativeSettings: undefined,
        sceneOrder: undefined,
        tableData: undefined,
        transition: undefined,
        variables: undefined,
        participatingSceneIds: undefined
    };

    if (creativeVersion > 0) {
        const creativeDDEId = StateReaderUtils.getCreativeDDEFromWireframes(wireframes);
        const creativeDDE = creativeDDEId && findDataElementById(creativeDDEId, programDataFields.dataElements);
        const creativeDDEDisplayName = creativeDDE && getDataElementDisplayName(creativeDDE);
        const storyId = currentStory.id;
        if (creativeDDEDisplayName && storyId) {
            storyLogic.creativeSettings = { storyId, creativeDDE: creativeDDEDisplayName };
        }
    }

    const supportMultipleStories = storiesVersion > 0;

    if (currentStory.activateCaching) {
        storyLogic.settings.cache = {};
    }
    // Check for analytics (FF and data)
    if (analytics) {
        const LogixAnalytics = Object.assign(
            {
                story: analytics.storyData.story,
                storyid: analytics.storyData.storyid
            },
            analytics.customFields
        );
        storyLogic.settings.analytics = LogixAnalytics;
    }

    let transition: Transition = StateReaderUtils.getTransition(wireframes);

    if (transition) {
        // Override bad transition. need to replace "type" with "style"
        storyLogic.transition = {};
        storyLogic.transition.style = transition.style ? transition.style : transition.type;
        storyLogic.transition.duration = transition.duration;
    }

    function createBackgroundAsset(type): void {
        if (type && currentStory["background" + type]) {
            storyLogic.settings.background[type] = {
                href: {
                    "repo-url": currentStory["background" + type],
                    repository: CURATED_REPOSITORY
                }
            };
        }
    }

    function createBackgroundAssetWithLogic(context, mediaType: MediaTypes): void {
        // we use media type as logic object id.
        let backgroundAssetLogic: LogicJSON = StateReaderUtils.getStoryLogicItemFromWireframeByType(wireframes, currentStory.id, context, mediaType);
        if (backgroundAssetLogic && Object.keys(backgroundAssetLogic).length) {
            let BackgroundAsset: object = {};
            createJsonHierarchyForVar(BackgroundAsset, mediaType, {
                rules: buildInputRules(backgroundAssetLogic, programDataFields, false, false, false, true),
                type: getInputLogicType(backgroundAssetLogic)
            });
            storyLogic.settings.background[mediaType] = BackgroundAsset[mediaType];
        }
    }

    function addVideoRatioAndQualityLogicIfEnabled(): void {
        const shouldAddVideoRatioAndQualityLogic = shouldUseVideoRatioAndQualityLogic(program.programType, enabledFeatureFlags);
        if (shouldAddVideoRatioAndQualityLogic) {
            const videoRatioQualityLogic: LogicJSON = StateReaderUtils.getStoryVideoRatioQualityLogicWireframe(wireframes, currentStory.id);
            if (videoRatioQualityLogic && !isAnEmptyObject(videoRatioQualityLogic)) {
                let videoRatioQuality: object = {};
                createJsonHierarchyForVar(videoRatioQuality, LogicContext.StoryRatioAndQuality, {
                    rules: buildInputRules(videoRatioQualityLogic, programDataFields, false, false, false, false),
                    type: getInputLogicType(videoRatioQualityLogic)
                });
                storyLogic.settings.output[LogicContext.StoryRatioAndQuality] = videoRatioQuality[LogicContext.StoryRatioAndQuality];
            }
        }
    }

    function createNarrator(): void {
        if (narrationsVersion > 0) {
            storyLogic.narrator = defaultNarratorId;
        }
        else if (currentStory.narrator) {
            storyLogic.narrator = currentStory.narrator;
        }
    }

    function createLineup(): void {
        let sceneIds = supportMultipleStories ? currentStory.participatingSceneIds : currentStory.lineup;
        storyLogic.lineup = sceneIds.map((sceneId) => {
            return {
                name: wireframes.scenes[sceneId].name,
                scene: {
                    hsrc: sceneIdToAssetName ? sceneIdToAssetName[sceneId] : "",
                    repository: SCENES_REPOSITORY
                }
            };
        });
    }

    function createNextSceneLogic(): void {
        storyLogic.sceneOrder = { start: {} };
        currentStory.lineup.forEach((sceneId, index) => {
            const key = index === 0 ? "start" : sceneId;
            storyLogic.sceneOrder[key] = {
                scenes: [wireframes.scenes[sceneId].name],
                next: {
                    type: "state",
                    rules: buildInputRules(wireframes.logic[sceneId][sceneId + "_nextScene"], programDataFields)
                }
            };
        });
    }

    function createSceneOrderLogic(): void {
        let allDPLogic = StateReaderUtils.getDecisionPointLogicItemsForStoryFromWireframes(wireframes, currentStory.id);

        storyLogic.sceneOrder = {
            start: {
                scenes: [],
                next: {
                    type: "state",
                    rules: buildInputRules(allDPLogic[currentStory.initialDecisionPointId], programDataFields, false, supportMultipleStories)
                }
            }
        };

        currentStory.participatingDPs.forEach((DPId: string) => {
            let DPLogic: LogicJSON = allDPLogic[DPId];
            let values = getAllValues(DPLogic);
            values.forEach((value: DecisionPointValue | null) => {
                let stateName = buildValue(value, LogicType.NextScene, false, null, supportMultipleStories);
                storyLogic.sceneOrder[stateName] = {
                    scenes: value && value.sceneId && wireframes.scenes[value.sceneId] ? [wireframes.scenes[value.sceneId].name] : [],
                    next: value && {
                        type: "state",
                        rules: buildInputRules(allDPLogic[value.nextDPLogicId], programDataFields, false, supportMultipleStories)
                    }
                };
            });
        });
    }

    function createVariables(): void {
        let context = { ...programDataFields, derivedLogic: wireframes.logic[LogicContainers.Derived] };
        storyLogic.variables = buildDerivedDataElements(context);
        if (storiesVersion > 0) {
            let storyDE = getDynamicSystemDataElements().find((de) => de.id === SYSTEM_DATA_STORY_ID);
            storyLogic.variables.splice(0, 0, {
                name: storyDE.name,
                value: currentStory.name
            });
        }
    }

    function createMappingTables(): void {
        let mappingTablesSet = traverse(wireframes.logic).reduce((acc, node) => {
            if (node && node.type === LOGIC_DATA_TYPES.MappingTable) {
                let mappingTableName = getMappingTableNameFromValue(node);
                if (mappingTableName) {
                    acc[mappingTableName] = node;
                }
            }
            return acc;
        }, {});

        storyLogic.tableData = { tables: Object.keys(mappingTablesSet) };
    }

    if (currentStory.backgroundAssetType) {
        //for stories with background asset with logic
        createBackgroundAssetWithLogic(LogicContainers.BackgroundAsset, currentStory.backgroundAssetType);
    }
    else if (currentStory.backgroundType) {
        //backward compatibility - for stories with background asset with no logic
        createBackgroundAsset(currentStory.backgroundType);
    }

    if (currentStory.backgroundaudio) {
        //backward compatibility - for stories with soundtrack with no logic
        createBackgroundAsset("audio");
    }
    else {
        //for stories with soundtrack with logic
        createBackgroundAssetWithLogic(LogicContainers.Soundtrack, MediaTypes.Audio);
    }

    createNarrator();
    createVariables();
    createMappingTables();
    if (!ignoreNextSceneLogic) {
        if (supportMultipleStories) {
            createSceneOrderLogic();
        }
        else {
            createNextSceneLogic();
        }
    }
    createLineup();

    const addAnalyticsDataToVideoSpec = program.programType !== ProgramType.Ads || enabledFeatureFlags.includes(ADS_REPORT_DE_TO_VIDEOSPEC);

    if (addAnalyticsDataToVideoSpec) {
        //calculate logicDataElements section.
        // Convert all data elements to actionable data
        const nonPIIDataElements: DataElement[] = (programDataFields.dataElements || []).filter((dataElement: DataElement) => {
            return dataElement.origin !== DataElementOrigins.Creative && !hasTag(dataElement, DataElementTags.PII.tag);
        });
        // Convert all actionable data to the format expected by the VLX
        const actionableDataElements = nonPIIDataElements.map(dataElement =>
            buildActionableData({
                dataType: LOGIC_DATA_TYPES.DataElement,
                mediaType: getDataElementType(dataElement),
                displayName: getDataElementDisplayName(dataElement),
                id: getDataElementId(dataElement)
            })
        );
        // Add to the analyticsDataSources object to be passed after translation to the VID SPEC and then to the analytics services.
        const logicDataElements: LogicDataElements =
            actionableDataElements.reduce((acc, actionableData) => {
                const value = buildValue(actionableData,
                    null,
                    false,
                    programDataFields
                );

                return {
                    ...acc,
                    [actionableData.displayName]: { value, type: "string" }
                };
            }, {});
        //adding analyticsDataSources section to story.
        storyLogic.analyticsDataSources = {};
        //add logicDataElements to analyticsDataSources section.
        storyLogic.analyticsDataSources.logicDataElements = logicDataElements;

        
    }
    addVideoRatioAndQualityLogicIfEnabled();
    return storyLogic;
}

export default createStoryLogic;
