"use strict";

import { DATA_TABLE_OUTPUT_TYPES, EXCLUDED_HOTSPOT_KEYS, LOGIC_DATA_TYPES, LOGIC_MEDIA_TYPES, LOGIC_TYPE, TEXT_ELEMENTS, VALIDATION_STATUS, VALIDATION_TEXT, VLX_PARAMTYPE } from "../consts";
import {
    convertLogicMediaTypToVlxParamType,
    getAllActionableDataFromValue,
    getAvailableOperatorsForObject,
    getElementInfoFromActionableData,
    getLhsMediaType,
    getMappingTableNameFromValue,
    getMappingTablePropertyFromValue,
    getRhsMediaType,
    getRhsValueSet,
    hasRhs,
    isLogicValueHotspot,
    isValidColor
} from "../editorLogicUtils";
import { isLogicEmpty } from "../../Logics/Logic";
import { escalate, getIdentity, runLinearValidations, validate, validateArrayBatch, validateBatch } from "./validationsFramework";
import { STATUS_REQUEST } from "../../../components/legacyCommon/Consts";
import { valueExists } from "../../../components/legacyCommon/utils";
import { parseStringToRelevantMediaType } from "../utils";
import { findDataElementById, findDataElementByLogicValue } from "../../DataElements/DataElementsManager";
import HubEditorsUtils from "../../../components/HubEditor/HubEditorsUtils";
import { getDataElementValidationResult, getDataTableValidationResult, getPriorityListValidationResult } from "../../validations/validationReducer";
import { getReduxState } from "../../../components/store";
import ValidationUtils from "../../validations/validationUtils";
import type { GetValidationResultFunction } from "../../validations/validationManager";
import ValidationManager from "../../validations/validationManager";
import { EntityTypes } from "../../entities/definitions";
import type { DataElement } from "../../../../common/types/dataElement";
import { DataElementOrigins, DataElementStatuses } from "../../../../common/types/dataElement";
import type { ActionableData, DecisionPointValue, LogicJSON, LogicMediaTypes, PrioritizedSlotActionableData, Rule, When } from "../../../../common/types/logic";
import { ActionableDataValueType, LogicMediaType, LogicType } from "../../../../common/types/logic";
import type { Asset } from "../../../../common/types/asset";
import { AssetTypes } from "../../../../common/types/asset";
import memoizeOne from "memoize-one";
import { getPrioritizedListIdFromActionableDataId } from "../../builder/editorsContainerLogic/prioritizedListUtils";
import PrioritizedListEntity from "../../entities/prioritizedListEntity";
import type { PrioritizedList, ValidationShape } from "../../common/types";
import type { Story } from "../../../../common/types/story";
import type { Scene } from "../../../../common/types/scene";
import type { DataTable } from "../../../../common/types/dataTable";

export type LogicValidationContext = {
    dataElements: DataElement[];
    assets: { [assetId: string] : Asset }; // assetId = type/name (see getAssetId in StateReaderUtils)
    isOptionalDefaultValueFlag: boolean;
    getValidationResult?: GetValidationResultFunction;
    stories: { [storyId: string] : Story };
    scenes: { [sceneId: string] : Scene };
    prioritizedLists?: PrioritizedList[]; // The prioritized lists of the scene to which the logic belongs, if it belongs to one.
    containingSceneId?: string; // The scene that contains the logic, if it is inside one.
};

/***
 * VLX params types and Logic MediaType are different when it comes to 'Number' -_-
 * @return {boolean}
 */
const sameMediaType = (mediaA, mediaB) => {
    return mediaA === mediaB || (mediaA === VLX_PARAMTYPE.Numeric && mediaB === LOGIC_MEDIA_TYPES.Number) || (mediaB === VLX_PARAMTYPE.Numeric && mediaA === LOGIC_MEDIA_TYPES.Number);
};

/**
 * Checks weather a given element reference (i.e. Actionable Data) refers to an existing element.
 * The types of elements it checks are data element, data tables and value set values
 * @param element An Actionable data
 * @param context Context
 */
function elementIsPresent(element: ActionableData, context: LogicValidationContext) : boolean {
    let { dataElements, assets } = context;
    // Avi - Sep 18 - This is a HACK! for backward compatibility of line breaks (HB-2233)
    if (JSON.stringify(element) === JSON.stringify(TEXT_ELEMENTS[0])) return true;

    if (!element || !element.mediaType) return false;

    if (element.type === LOGIC_DATA_TYPES.Const) return true;

    if (element.type === LOGIC_DATA_TYPES.ValueSetValue) {
        //Find the latest value
        const dataElement = findDataElementById(element.id, dataElements);
        if (dataElement) {
            let valueSet = dataElement.getValueSet();
            let value = valueSet && valueSet.find((value) => value && value.id === element.name);
            if (value) {
                return true;
            }
        }

        return false;
    }

    if (element.type === LOGIC_DATA_TYPES.DataElement) {
        const dataElement = findDataElementByLogicValue(element, dataElements);
        return !!dataElement;
    }

    if (element.type === LOGIC_DATA_TYPES.Asset) {
        return !!assets[AssetTypes.mappingTable + "/" + element.name];
    }

    return true;
}

function convertLogicTypeToAssetType(logicType: LogicType) : AssetTypes | null {
    switch (logicType) {
        case LogicType.Animation:
            return AssetTypes.animation;
        case LogicType.Image:
        case LogicType.Video:
        case LogicType.Audio:
        case LogicType.BackgroundAsset:
            return AssetTypes.curated;
        default:
            return null;
    }
}

const getAssetsArray = memoizeOne((assets: LogicValidationContext["assets"]): Asset[] => {
    return Object.values(assets);
});

function isDataElementApproved(de, dataElements) {
    let element = findDataElementByLogicValue(de, dataElements);

    // Only feed data elements have status
    if (element && element.origin === DataElementOrigins.Feed && (element.status === DataElementStatuses.Missing || element.status === DataElementStatuses.Pending)) {
        return false;
    }

    return true;
}

function isDataElementValid(dataElement, getValidationResult) {
    let entityType =
        getValidationResult && (dataElement.origin === DataElementOrigins.Feed || dataElement.origin === DataElementOrigins.Creative ? EntityTypes.DATA_ELEMENT : EntityTypes.DERIVED_DATA_ELEMENT);
    let dataElementValidationResult = getValidationResult ? getValidationResult(entityType, dataElement.id) : getDataElementValidationResult(getReduxState(), dataElement.id, dataElement.origin);
    return { valid: !(dataElementValidationResult && dataElementValidationResult.issues.length > 0), severity: dataElementValidationResult && dataElementValidationResult.severity };
}

function isDerivedDataElementApproved(de, dataElements) {
    // Derived data element doesn't have statuses!!
    // Todo: refactor to remove this nonsense function
    return true;
}

function isDataTableValid(element: ActionableData, context: LogicValidationContext) {
    let { assets, getValidationResult } = context;
    let dataTableName = getMappingTableNameFromValue(element);

    const dataTable: DataTable = assets[AssetTypes.mappingTable + "/" + dataTableName] as DataTable;

    const res = dataTable?.mappingTableScheme?.outputs.some((output) => (output.id && element.id ? output.id : output.name) === getMappingTablePropertyFromValue(element));

    let validationResult = getValidationResult ? getValidationResult(EntityTypes.DATA_TABLE, dataTableName) : getDataTableValidationResult(getReduxState(), dataTableName);
    return res && !(validationResult && validationResult.issues.length > 0);
}

function isPrioritizedListValid(prioritizedList: PrioritizedList, containingSceneId: string, getValidationResult) {
    let entityType = EntityTypes.PRIORITIZED_LIST;
    let validationTableId = PrioritizedListEntity.generateId(prioritizedList.id, containingSceneId);
    let listValidationResult = getValidationResult ? getValidationResult(entityType, validationTableId) : getPriorityListValidationResult(getReduxState(), validationTableId);
    return { valid: !(listValidationResult?.issues.length > 0), severity: listValidationResult?.severity };
}

function isActionableDataValid(actionableData: ActionableData, context: LogicValidationContext) {
    const logicUtilsContext = { dataElements: context.dataElements, assets: getAssetsArray(context.assets) };
    let mediaType = convertLogicMediaTypToVlxParamType(getElementInfoFromActionableData(actionableData, logicUtilsContext).mediaType as LogicMediaTypes);
    let valid = true;
    if (actionableData.actions?.length) {
        actionableData.actions.forEach(action => {
            if (mediaType !== action.input[0]) {
                valid = false;
            }
            mediaType = action.output;
        });
    }
    return valid;
}


/*****************************************************************************/
/*******             List of validations go here                       *******/
/*****************************************************************************/
function isBlank(str) {
    return typeof str === "string" && (!str || /^\s*$/.test(str));
}

const nextSceneDefaultValueValidations = [
    validate(VALIDATION_TEXT.ValueIsEmpty, (item) => {
        if (item === undefined || item === null || item === "" || isBlank(item) || (Array.isArray(item) && item.every((str) => isBlank(str)))) {
            return VALIDATION_STATUS.Invalid;
        }
        return VALIDATION_STATUS.Valid;
    })
];

const storyValueValidations = [
    validate(VALIDATION_TEXT.ValueIsEmpty, (item: string, inputType: LogicType.Story, context: LogicValidationContext) => {
        let { stories } = context;
        let story: Story = stories[item];

        return story ? VALIDATION_STATUS.Valid : VALIDATION_STATUS.Invalid;
    })
];

const singleValueValidations = [
    validate(VALIDATION_TEXT.ValueIsEmpty, (item, inputType) => {
        if (item === undefined || item === null || item === "" || isBlank(item) || (Array.isArray(item) && item.every((str) => isBlank(str)))) {
            if (inputType === LogicType.Quality || inputType === LogicType.Ratio) {
                return VALIDATION_STATUS.Invalid;
            }
            else {
                return VALIDATION_STATUS.Partial;
            }
        }
        return VALIDATION_STATUS.Valid;
    }),

    validate(VALIDATION_TEXT.ValueCanBeComplexStringOnlyForStringPlaceholders, (item, inputType) => {
        if (!item) {
            return VALIDATION_STATUS.Valid;
        }

        if (inputType === LOGIC_TYPE.DataElementString || inputType === LOGIC_TYPE.Text) {
            return Array.isArray(item) ? VALIDATION_STATUS.Valid : VALIDATION_STATUS.Invalid;
        }
        return VALIDATION_STATUS.Valid;
    }),

    validate(VALIDATION_TEXT.SelectedMediaTypeDoesNotMatchPlaceholderType, (item: any, inputType: LogicType) => {
        if (!item) {
            return VALIDATION_STATUS.Valid;
        }

        // Text placeholders can display anything (sometimes they get 'string' also)
        if (
            inputType === LogicType.DataElementString ||
            inputType === LogicType.Text ||
            inputType === LogicType.DataElementNumber ||
            inputType === LogicType.NextScene ||
            inputType === LogicType.DataElementBoolean ||
            inputType === LogicType.Story ||
            inputType === LogicType.Quality ||
            inputType === LogicType.Ratio
        ) {
            return VALIDATION_STATUS.Valid;
        }

        if (inputType === LogicType.Color && isValidColor(item)) {
            return VALIDATION_STATUS.Valid;
        }

        if (Array.isArray(item)) return VALIDATION_STATUS.Valid;

        if (item.mediaType !== inputType) {
            // Check if maybe there is an action chaining the output media type
            if (item.actions && item.actions.length) {
                let last_action = item.actions[item.actions.length - 1];
                if (last_action.output && sameMediaType(last_action.output, inputType)) {
                    return VALIDATION_STATUS.Valid;
                }
            }

            const allowedMediaTypes = [DATA_TABLE_OUTPUT_TYPES.Media, DATA_TABLE_OUTPUT_TYPES.Url];
            if (allowedMediaTypes.includes(item.mediaType) && (inputType === LOGIC_TYPE.Video || inputType === LOGIC_TYPE.Image || inputType === LOGIC_TYPE.Audio)) {
                return VALIDATION_STATUS.Valid;
            }
            return VALIDATION_STATUS.Invalid;
        }
    }),

    validate(VALIDATION_TEXT.SelectedDataElementIsMissingOrPending, (item, inputType: LogicType, context: LogicValidationContext) => {
        let { dataElements } = context;
        if (!item) {
            return VALIDATION_STATUS.Valid;
        }
        if (Array.isArray(item)) {
            let filtered = item.filter((elem) => elem.type === LOGIC_DATA_TYPES.DataElement);
            if (!filtered) return VALIDATION_STATUS.Valid;

            return filtered.some((elem) => !isDataElementApproved(elem, dataElements)) ? VALIDATION_STATUS.Partial : VALIDATION_STATUS.Valid;
        }

        return !isDataElementApproved(item, dataElements) ? VALIDATION_STATUS.Partial : VALIDATION_STATUS.Valid;
    }),

    validate(VALIDATION_TEXT.DataElementsOrAssetsNotValid, (item, inputType: LogicType, context: LogicValidationContext) => {
        // check for compound value
        if (!item || inputType === LogicType.Quality || inputType === LogicType.Ratio) {
            return VALIDATION_STATUS.Valid;
        }

        if (item.id && item.displayName && item.outputType && item.show !== undefined) return VALIDATION_STATUS.Valid;

        if (Array.isArray(item)) {
            let filtered = item.filter((elem) => typeof elem !== "string");
            if (!filtered) {
                return VALIDATION_STATUS.Valid;
            }
            return filtered.some((elem) => !elementIsPresent(elem, context)) ? VALIDATION_STATUS.Invalid : VALIDATION_STATUS.Valid;
        }

        // item.defaultValueShow can no longer be undefined. Only old logic can.
        if (item.defaultValueShow !== undefined && !item.defaultValueShow) {
            return VALIDATION_STATUS.Valid;
        }

        return typeof item !== "string" ? VALIDATION_STATUS.Valid : VALIDATION_STATUS.Invalid;
    }),

    validate(VALIDATION_TEXT.DataTableNotValid, (item, inputType: LogicType, context: LogicValidationContext) => {
        if (!item) {
            return VALIDATION_STATUS.Valid;
        }
        if (Array.isArray(item)) {
            let filtered = item.filter((elem) => elem.type === LOGIC_DATA_TYPES.MappingTable);
            if (!filtered) return VALIDATION_STATUS.Valid;

            return filtered.some((elem) => !isDataTableValid(elem, context)) ? VALIDATION_STATUS.Invalid : VALIDATION_STATUS.Valid;
        }
        else {
            if (item.type === LOGIC_DATA_TYPES.MappingTable) {
                return !isDataTableValid(item, context) ? VALIDATION_STATUS.Invalid : VALIDATION_STATUS.Valid;
            }
        }
        return VALIDATION_STATUS.Valid;
    }),

    validate(VALIDATION_TEXT.AssetIsRequestWaitingForFulfilment, (item, inputType: LogicType, context: LogicValidationContext) => {
        let { assets } = context;
        if (!item) {
            return VALIDATION_STATUS.Valid;
        }

        const assetType: AssetTypes = convertLogicTypeToAssetType(inputType);

        const currentAsset = assets[assetType + "/" + item.name];
        return currentAsset && currentAsset.status === STATUS_REQUEST ? VALIDATION_STATUS.Partial : VALIDATION_STATUS.Valid;
    }),

    validate(VALIDATION_TEXT.DataElementValueSetNotValid, (item, mediaType, context: LogicValidationContext) => {
        let { dataElements, getValidationResult } = context;
        if (!item) {
            return VALIDATION_STATUS.Valid;
        }
        if (Array.isArray(item)) {
            let filtered = item.filter((elem) => elem.type === LOGIC_DATA_TYPES.DataElement);
            if (!filtered) return VALIDATION_STATUS.Valid;
            let severities = [];

            return filtered.some((elem) => {
                let dataElement = findDataElementByLogicValue(elem, dataElements);
                let result = isDataElementValid(dataElement, getValidationResult);
                if (result && !result.valid) {
                    severities.push(result.severity);
                }
                return !result.valid && (dataElement.origin === DataElementOrigins.Feed || dataElement.origin === DataElementOrigins.Creative);
            })
                ? ValidationUtils.convertIssueSeverityToValidationStatus(ValidationManager.getHighestSeverity(severities))
                : VALIDATION_STATUS.Valid;
        }
        else {
            if (item.type === LOGIC_DATA_TYPES.DataElement) {
                let dataElement = findDataElementByLogicValue(item, dataElements);
                let result = isDataElementValid(dataElement, getValidationResult);
                if (!result.valid && (dataElement.origin === DataElementOrigins.Feed || dataElement.origin === DataElementOrigins.Creative)) {
                    return ValidationUtils.convertIssueSeverityToValidationStatus(result.severity);
                }
            }
        }

        return VALIDATION_STATUS.Valid;
    }),

    validate(VALIDATION_TEXT.DerivedDataElementLogicIsNotValid, (item, mediaType, context: LogicValidationContext) => {
        let { dataElements, getValidationResult } = context;
        if (!item) {
            return VALIDATION_STATUS.Valid;
        }
        if (Array.isArray(item)) {
            let filtered = item.filter((elem) => elem.type === LOGIC_DATA_TYPES.DataElement);
            if (!filtered) return VALIDATION_STATUS.Valid;
            let severities = [];

            return filtered.some((elem) => {
                let dataElement = findDataElementByLogicValue(elem, dataElements);
                let result = isDataElementValid(dataElement, getValidationResult);
                if (result && !result.valid) {
                    severities.push(result.severity);
                }
                return dataElement.origin === DataElementOrigins.Derived && !result.valid;
            })
                ? ValidationUtils.convertIssueSeverityToValidationStatus(ValidationManager.getHighestSeverity(severities))
                : VALIDATION_STATUS.Valid;
        }
        else {
            if (item.type === LOGIC_DATA_TYPES.DataElement) {
                let dataElement = findDataElementByLogicValue(item, dataElements);
                let result = isDataElementValid(dataElement, getValidationResult);
                if (dataElement.origin === DataElementOrigins.Derived && !result.valid) {
                    return ValidationUtils.convertIssueSeverityToValidationStatus(result.severity);
                }
            }
        }

        return VALIDATION_STATUS.Valid;
    }),

    validate(VALIDATION_TEXT.DerivedDataElementIsNotValid, (item, mediaType, context: LogicValidationContext) => {
        let { dataElements } = context;
        if (!item) {
            return VALIDATION_STATUS.Valid;
        }
        if (Array.isArray(item)) {
            let filtered = item.filter((elem) => elem.type === LOGIC_DATA_TYPES.DataElement);
            if (!filtered) return VALIDATION_STATUS.Valid;

            return filtered.some((elem) => !isDerivedDataElementApproved(elem, dataElements)) ? VALIDATION_STATUS.Invalid : VALIDATION_STATUS.Valid;
        }

        return !isDerivedDataElementApproved(item, dataElements) ? VALIDATION_STATUS.Invalid : VALIDATION_STATUS.Valid;
    }),

    validate(VALIDATION_TEXT.ValueFunctionOrFormatterTypeMismatch, (item: any, mediaType: LogicType, context: LogicValidationContext) => {
        const actionables = getAllActionableDataFromValue(item);
        return actionables.every(actionableData => isActionableDataValid(actionableData, context)) ? VALIDATION_STATUS.Valid : VALIDATION_STATUS.Invalid;
    })
];

const compoundValueExtractor = {
    getItem: (item) => item.value,
    getOutputType: (outputType, item) => (item.outputType ? item.outputType : outputType),
    skipItem: (item) => !item.show
};

const hotspotValueExtractor = {
    ...compoundValueExtractor,
    skipItem: (item) => !item.show || EXCLUDED_HOTSPOT_KEYS.includes(item.id)
};

const compoundAndSingleValueValidations = [
    validate(VALIDATION_TEXT.CompoundValuesMustBeValid, (itemsOrItem, inputType: LogicType, context: LogicValidationContext) => {
        if (inputType === LogicType.Compound || inputType === LogicType.Prioritized) {
            // groups and other compounds
            const isHotspot = isLogicValueHotspot(itemsOrItem);
            return validateArrayBatch(
                isHotspot ? VALIDATION_TEXT.HotspotValuesMustBeValid : VALIDATION_TEXT.CompoundValuesMustBeValid,
                getIdentity,
                compoundAndSingleValueValidations,
                { status: VALIDATION_STATUS.Valid },
                isHotspot ? hotspotValueExtractor : compoundValueExtractor
            )(itemsOrItem, inputType, context);
        }
        else {
            // pass-through
            return validateBatch(VALIDATION_TEXT.SingleValueMustBeValid, getIdentity, singleValueValidations)(itemsOrItem, inputType, context);
        }
    })
];

const operatorValidations = [
    validate(VALIDATION_TEXT.InvalidOperatorForLeftHandSideOperand, (operation, outputType, context: LogicValidationContext) => {
        const logicUtilsContext = { dataElements: context.dataElements, assets: getAssetsArray(context.assets) };
        const lhsMediaType = getLhsMediaType(operation.lhs, logicUtilsContext);
        if (operation.lhs && operation.operator && !getAvailableOperatorsForObject(lhsMediaType).includes(operation.operator)) {
            return VALIDATION_STATUS.Invalid;
        }
        return VALIDATION_STATUS.Valid;
    }),

    validate(VALIDATION_TEXT.OperationMustBeComplete, (op) => {
        if (op.lhs && op.operator && (valueExists(op.rhs) || !hasRhs(op.operator))) {
            return VALIDATION_STATUS.Valid;
        }
        return VALIDATION_STATUS.Invalid;
    }),

    validate("Value must be one of value set", (operation, outputType, context: LogicValidationContext) => {
        let { dataElements } = context;
        let valueSet = getRhsValueSet(operation.operator, operation.lhs, dataElements);
        if (!valueSet) {
            return VALIDATION_STATUS.Valid;
        }
        if (typeof operation.rhs === "string") {
            if (!valueSet.some((value) => value && value.displayName === operation.rhs)) {
                return VALIDATION_STATUS.Invalid;
            }
            return VALIDATION_STATUS.Valid;
        }
        else if (Array.isArray(operation.rhs) && operation.rhs.length === 1) {
            if (typeof operation.rhs[0] === "string" && !valueSet.some((value) => value && value.displayName === operation.rhs[0])) {
                return VALIDATION_STATUS.Invalid;
            }
            return VALIDATION_STATUS.Valid;
        }
        else if (!Array.isArray(operation.rhs) && typeof operation.rhs === "object") {
            if (operation.rhs.type === LOGIC_DATA_TYPES.Const) {
                return valueSet.some((value) => value && parseStringToRelevantMediaType(value.displayName, operation.rhs.mediaType) === operation.rhs.name)
                    ? VALIDATION_STATUS.Valid
                    : VALIDATION_STATUS.Invalid;
            }
            else if (operation.rhs.type === LOGIC_DATA_TYPES.ValueSetValue) {
                return valueSet.some((value) => value && value.name === operation.rhs.name) ? VALIDATION_STATUS.Valid : VALIDATION_STATUS.Invalid;
            }
            return VALIDATION_STATUS.Valid;
        }
        return VALIDATION_STATUS.Invalid;
    }),

    validate(VALIDATION_TEXT.RightHandSideOperandTypeMismatch, (op: When, type, context: LogicValidationContext) => {
        if (op.lhs && op.operator && valueExists(op.rhs)) {
            let rhsMediaType = undefined;

            if (Array.isArray(op.rhs) || typeof op.rhs === "string") {
                // When the rhs is an array it must be a type of string
                rhsMediaType = LogicMediaType.String;
            }
            else {
                const logicUtilsContext = { dataElements: context.dataElements, assets: getAssetsArray(context.assets) };
                rhsMediaType = getElementInfoFromActionableData(op.rhs, logicUtilsContext).mediaType;
            }

            const logicUtilsContext = { dataElements: context.dataElements, assets: getAssetsArray(context.assets) };
            if (getRhsMediaType(op.operator, getLhsMediaType(op.lhs, logicUtilsContext)) !== rhsMediaType) {
                return VALIDATION_STATUS.Invalid;
            }
        }

        return VALIDATION_STATUS.Valid;
    }),

    validate(VALIDATION_TEXT.SelectedDataElementIsMissingOrPending, (op, type, context: LogicValidationContext) => {
        let { dataElements } = context;
        return isDataElementApproved(op.lhs, dataElements) ? VALIDATION_STATUS.Valid : VALIDATION_STATUS.Partial;
    }),

    validate(VALIDATION_TEXT.DataElementValueSetNotValid, (op, type, context: LogicValidationContext) => {
        let { dataElements, getValidationResult } = context;
        if (op.lhs.type === LOGIC_DATA_TYPES.DataElement) {
            let dataElement = findDataElementByLogicValue(op.lhs, dataElements);
            if (dataElement && (dataElement.origin === DataElementOrigins.Feed || dataElement.origin === DataElementOrigins.Creative)) {
                let result = isDataElementValid(dataElement, getValidationResult);
                if (!result.valid) {
                    return ValidationUtils.convertIssueSeverityToValidationStatus(result.severity);
                }
            }
        }
        return VALIDATION_STATUS.Valid;
    }),

    validate(VALIDATION_TEXT.DerivedDataElementLogicIsNotValid, (op, type, context: LogicValidationContext) => {
        let { dataElements, getValidationResult } = context;
        if (op.lhs.type === LOGIC_DATA_TYPES.DataElement) {
            let dataElement = findDataElementByLogicValue(op.lhs, dataElements);
            if (dataElement && dataElement.origin === DataElementOrigins.Derived) {
                let result = isDataElementValid(dataElement, getValidationResult);
                if (!result.valid) {
                    return ValidationUtils.convertIssueSeverityToValidationStatus(result.severity);
                }
            }
        }
        return VALIDATION_STATUS.Valid;
    }),

    validate(VALIDATION_TEXT.DataTableNotValid, (op, type, context: LogicValidationContext) => {
        if (op.lhs.type === LOGIC_DATA_TYPES.MappingTable) {
            if (!isDataTableValid(op.lhs, context)) {
                return VALIDATION_STATUS.Invalid;
            }
        }
        return VALIDATION_STATUS.Valid;
    }),

    validate(VALIDATION_TEXT.PrioritizedListIsMissing, (op: When, type, context: LogicValidationContext) => {
        if (op.lhs.type === ActionableDataValueType.PrioritizedSlot || op.lhs.type === ActionableDataValueType.Prioritized) {
            const { prioritizedLists } = context;
            const listActionableData = op.lhs as ActionableData;
            const exists = (prioritizedLists || []).some(pl => pl.id === getPrioritizedListIdFromActionableDataId(listActionableData.id));
            return exists ? VALIDATION_STATUS.Valid : VALIDATION_STATUS.Invalid;
        }
        return VALIDATION_STATUS.Valid;
    }),

    validate(VALIDATION_TEXT.PrioritizedListLogicIsInvalid, (op: When, type, context: LogicValidationContext) => {
        if (op.lhs.type === ActionableDataValueType.PrioritizedSlot || op.lhs.type === ActionableDataValueType.Prioritized) {
            const { prioritizedLists, getValidationResult } = context;
            const listActionableData = op.lhs as ActionableData;
            const prioritizedList = (prioritizedLists || []).find(pl => pl.id === getPrioritizedListIdFromActionableDataId(listActionableData.id));
            const result = isPrioritizedListValid(prioritizedList, context.containingSceneId, getValidationResult);
            if (!result.valid) {
                return ValidationUtils.convertIssueSeverityToValidationStatus(result.severity);
            }
        }
        return VALIDATION_STATUS.Valid;
    }),

    validate(VALIDATION_TEXT.PrioritizedListSlotIsMissing, (op: When, type, context: LogicValidationContext) => {
        if (op.lhs.type === ActionableDataValueType.PrioritizedSlot) {
            const { prioritizedLists } = context;
            const listActionableData = op.lhs as PrioritizedSlotActionableData;
            const prioritizedList = (prioritizedLists || []).find(pl => pl.id === getPrioritizedListIdFromActionableDataId(listActionableData.id));
            return prioritizedList.maxSlots > listActionableData.slotIdx ? VALIDATION_STATUS.Valid : VALIDATION_STATUS.Invalid;
        }
        return VALIDATION_STATUS.Valid;
    }),

    validate(VALIDATION_TEXT.OperandFunctionOrFormatterTypeMismatch, (op: When, type: LogicType, context: LogicValidationContext) => {
        const actionables = getAllActionableDataFromValue(op.rhs);
        if (op.lhs) {
            actionables.push(op.lhs);
        }
        return actionables.every(actionableData => isActionableDataValid(actionableData, context)) ? VALIDATION_STATUS.Valid : VALIDATION_STATUS.Invalid;
    })
];

const ruleOperatorValidations = [
    validate(VALIDATION_TEXT.RuleMustHaveValidCondition, (condition, outputType, context: LogicValidationContext) => {
        if (typeof condition === "string") {
            return VALIDATION_STATUS.Valid;
        }
        return validateBatch(VALIDATION_TEXT.OperatorMustBeValid, getIdentity, operatorValidations)(condition, outputType, context);
    })
];

const logicWhenValidations = [
    validate(VALIDATION_TEXT.RuleMustHaveAtLeastOneCondition, (rule) => (!rule.always && (!rule.whens || !rule.whens.length) ? VALIDATION_STATUS.Invalid : VALIDATION_STATUS.Valid)),
    validateArrayBatch(VALIDATION_TEXT.RuleOperatorsMustBeValid, (rule) => rule.whens, ruleOperatorValidations)
];

const logicThenValidation = [
    validate(VALIDATION_TEXT.CaseMustHaveAValue, (logicRule) => {
        return (logicRule.show !== undefined && !logicRule.show) || (logicRule.value !== null && logicRule.value !== undefined) ? VALIDATION_STATUS.Valid : VALIDATION_STATUS.Partial;
    }),

    validate(VALIDATION_TEXT.RuleValueMustBeValid, (rule, outputType, context: LogicValidationContext) => {
        if (rule.show !== undefined && !rule.show) {
            return VALIDATION_STATUS.Valid;
        }
        return validateBatch(VALIDATION_TEXT.ShownRuleValueMustBeValid, (rule) => rule.value, compoundAndSingleValueValidations)(rule, outputType, context);
    })
];

const nextSceneValueValidations = [
    validate(
        VALIDATION_TEXT.ValueIsEmpty,
        // item can be string only for backward compatible reasons!
        (item: DecisionPointValue | string, outputType: LogicType.NextScene, context: LogicValidationContext) => {
            let scenes = context.scenes || {};
            let sceneId;
            if (item) {
                if (item.hasOwnProperty("nextDPLogicId") && item.hasOwnProperty("sceneId")) {
                    sceneId = (item as DecisionPointValue).sceneId;
                }
                else {
                    sceneId = item;
                }
            }

            return scenes[sceneId] ? VALIDATION_STATUS.Valid : VALIDATION_STATUS.Invalid;
        }
    )
];

const logicRuleValidations = [...logicWhenValidations, ...logicThenValidation];

const nextSceneLogicRuleValidations = [...logicWhenValidations, validateBatch(VALIDATION_TEXT.RuleValueMustBeValid, (rule: Rule) => rule.value, nextSceneValueValidations)];

const storyRuleValidations = [...logicWhenValidations, validateBatch(VALIDATION_TEXT.RuleValueMustBeValid, (rule: Rule) => rule.value, storyValueValidations)];

const prioritizedListValidations = [
    validate(VALIDATION_TEXT.CandidateNameMustBeValid, (input) =>
        input.rules.some((rule) => {
            if (rule.name !== undefined) {
                if (rule.name === null || !HubEditorsUtils.isAlphaNumeric(rule.name)) {
                    return true;
                }
                if (input.rules.filter((r) => r.name === rule.name).length > 1) {
                    return true;
                }
            }
            return false;
        })
            ? VALIDATION_STATUS.Invalid
            : VALIDATION_STATUS.Valid
    )
];

// This set of validations validates placeholders
const placeholderValidations = [
    validate(VALIDATION_TEXT.PlaceholderMustHaveAName, (placeholder) => (!placeholder ? VALIDATION_STATUS.Invalid : VALIDATION_STATUS.Valid), true),

    validate(
        VALIDATION_TEXT.InputMustHaveADefaultValue,
        (input, outputType, context: LogicValidationContext) => {
            if (isLogicEmpty(input, context.isOptionalDefaultValueFlag)) return VALIDATION_STATUS.Invalid;

            return VALIDATION_STATUS.Valid;
        },
        true
    ),

    validate(
        VALIDATION_TEXT.DefaultValueShouldBeValid,
        (input, outputType, context: LogicValidationContext) => {
            // item.defaultValueShow can no longer be undefined. Only old logic can.
            if (input.defaultValueShow !== undefined && !input.defaultValueShow) {
                return VALIDATION_STATUS.Valid;
            }
            return validateBatch(VALIDATION_TEXT.DefaultValueShouldBeValid, (item) => item.defaultValue, compoundAndSingleValueValidations)(input, outputType, context);
        },
        true
    ),

    validateArrayBatch(VALIDATION_TEXT.AllCasesMustBeValid, (input) => input.rules, logicRuleValidations, {
        status: VALIDATION_STATUS.Valid
    }),

    validateArrayBatch(VALIDATION_TEXT.CandidateNameMustBeValid, (input) => (input.outputType === LOGIC_TYPE.Prioritized ? [input] : null), prioritizedListValidations, {
        status: VALIDATION_STATUS.Valid
    })
];

const sceneAnimationValidations = [
    validate(
        VALIDATION_TEXT.InputMustHaveADefaultValue,
        (input) => {
            if (isLogicEmpty(input)) {
                return VALIDATION_STATUS.Partial;
            }
            return VALIDATION_STATUS.Valid;
        },
        true
    ),

    validate(
        VALIDATION_TEXT.DefaultValueShouldBeValid,
        (input, outputType, context: LogicValidationContext) => {
            // item.defaultValueShow can no longer be undefined. Only old logic can.
            if (input.defaultValueShow !== undefined && !input.defaultValueShow) {
                return VALIDATION_STATUS.Valid;
            }
            return validateBatch(VALIDATION_TEXT.DefaultValueShouldBeValid, (item) => item.defaultValue, compoundAndSingleValueValidations)(input, outputType, context);
        },
        true
    ),

    validateArrayBatch(VALIDATION_TEXT.AllCasesMustBeValid, (input) => input.rules, logicRuleValidations, {
        status: VALIDATION_STATUS.Valid
    })
];

const sceneValidationValidations = [
    validateArrayBatch(VALIDATION_TEXT.AllCasesMustBeValid, (input) => input.rules, logicWhenValidations, {
        status: VALIDATION_STATUS.Valid
    })
];

const customAnalyticsValidations = [
    validate(
        VALIDATION_TEXT.PleaseChooseAnElement,
        (input) => {
            if (isLogicEmpty(input)) return VALIDATION_STATUS.Invalid;
            return VALIDATION_STATUS.Valid;
        },
        true
    ),

    validate(
        VALIDATION_TEXT.InvalidDataElementSelected,
        (element, logicType, context: LogicValidationContext) => {
            let { dataElements } = context;
            if (elementIsPresent(element.defaultValue, context) && isDataElementApproved(element.defaultValue, dataElements)) {
                return VALIDATION_STATUS.Valid;
            }

            return VALIDATION_STATUS.Invalid;
        },
        true
    )
];

const nextSceneValidations = [
    validate(
        VALIDATION_TEXT.InputMustHaveADefaultValue,
        (input) => {
            if (!input.isLast && isLogicEmpty(input)) {
                return VALIDATION_STATUS.Invalid;
            }
            return VALIDATION_STATUS.Valid;
        },
        true
    ),

    validate(
        VALIDATION_TEXT.DefaultValueShouldBeValid,
        (input, outputType, context) => {
            // item.defaultValueShow can no longer be undefined. Only old logic can.
            if (input.isLast || (input.defaultValueShow !== undefined && !input.defaultValueShow)) {
                return VALIDATION_STATUS.Valid;
            }
            return validateBatch(VALIDATION_TEXT.DefaultValueShouldBeValid, (item) => item.defaultValue, nextSceneDefaultValueValidations)(input, outputType, context);
        },
        true
    ),

    validateArrayBatch(VALIDATION_TEXT.AllCasesMustBeValid, (input) => input.rules, nextSceneLogicRuleValidations, {
        status: VALIDATION_STATUS.Valid
    })
];

const storyValidations = [
    validate(
        VALIDATION_TEXT.InputMustHaveADefaultValue,
        (input: LogicJSON) => {
            return isLogicEmpty(input) ? VALIDATION_STATUS.Invalid : VALIDATION_STATUS.Valid;
        },
        true
    ),

    validate(
        VALIDATION_TEXT.DefaultValueShouldBeValid,
        (input: LogicJSON, outputType: LogicType.Story, context: LogicValidationContext) => {
            return validateBatch(VALIDATION_TEXT.DefaultValueShouldBeValid, (item: LogicJSON) => item.defaultValue, storyValueValidations)(input, outputType, context);
        },
        true
    ),

    validateArrayBatch(VALIDATION_TEXT.AllCasesMustBeValid, (item: LogicJSON) => item.rules, storyRuleValidations, {
        status: VALIDATION_STATUS.Valid
    })
];

function runValidations(logicObject: LogicJSON, context: LogicValidationContext, validationsArray) {
    let validationObj: ValidationShape = {
        report: [],
        status: !logicObject ? VALIDATION_STATUS.Invalid : VALIDATION_STATUS.Valid
    };

    if (!logicObject) return validationObj;

    validationObj.report = runLinearValidations(validationsArray, logicObject, logicObject.outputType, context);

    validationObj.status = escalate(validationObj.report);

    if (validationObj.status !== VALIDATION_STATUS.Valid) {
        validationObj.message = validationObj.report[0].message;
    }

    return validationObj;
}

/*****************************************************************************/

function runPlaceholderValidations(logicObject, context: LogicValidationContext) {
    let validationObj = {
        report: [],
        status: isLogicEmpty(logicObject) ? VALIDATION_STATUS.Partial : VALIDATION_STATUS.Valid
    };

    let emptyLogicReport = {
        status: VALIDATION_STATUS.Partial,
        message: VALIDATION_TEXT.InputMustHaveADefaultValue
    };

    if (isLogicEmpty(logicObject, context.isOptionalDefaultValueFlag)) {
        validationObj.report.push(emptyLogicReport);
        return validationObj;
    }

    return runValidations(logicObject, context, placeholderValidations);
}

function runSceneAnimationValidations(logicObject, context: LogicValidationContext) {
    return runValidations(logicObject, context, sceneAnimationValidations);
}

function runSceneValidationValidations(logicObject, context: LogicValidationContext) {
    return runValidations(logicObject, context, sceneValidationValidations);
}

function runCustomAnalyticsValidation(logicObject, context: LogicValidationContext) {
    return runValidations(logicObject, context, customAnalyticsValidations);
}

function runNextSceneValidation(logicObject, context: LogicValidationContext) {
    return runValidations(logicObject, context, nextSceneValidations);
}

function runStoryValidations(logicObject: LogicJSON, context: LogicValidationContext) {
    return runValidations(logicObject, context, storyValidations);
}

function validateInputLogic(logicObject: LogicJSON, context: LogicValidationContext) {
    try {
        if (logicObject.outputType === LogicType.AnalyticCustomField) {
            return runCustomAnalyticsValidation(logicObject, context);
        }
        if (logicObject.outputType === LogicType.NextScene) {
            return runNextSceneValidation(logicObject, context);
        }
        if (logicObject.outputType === LogicType.Animation) {
            return runSceneAnimationValidations(logicObject, context);
        }
        if (logicObject.outputType === LogicType.SceneValidation) {
            return runSceneValidationValidations(logicObject, context);
        }
        if (logicObject.outputType === LogicType.Story) {
            return runStoryValidations(logicObject, context);
        }
        else {
            return runPlaceholderValidations(logicObject, context);
        }
    }
    catch (exception) {
        if (window.location.host !== "studio.sundaysky.com") {
            throw exception;
        }
    }
}

export { validateInputLogic, runPlaceholderValidations, runCustomAnalyticsValidation, runNextSceneValidation, VALIDATION_TEXT };
