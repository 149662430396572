import type { TextFieldProps } from "@mui/material";
import type { ChangeEvent, FormEvent, ReactNode } from "react";
import { useCallback, useEffect, useRef, useState } from "react";


export type UseTextFieldOutput = {
    value: string;
    setValue: (value: string) => void;
    isDirty: boolean;
    setDirty: (value: boolean) => void;
    errorText: ReactNode;
    setErrorText: (value: ReactNode) => void;
    isValid: boolean;
    reset: () => void;
    focus: () => void;
    textFieldProps: Pick<
        TextFieldProps,
        "value" | "helperText" | "error" | "inputRef" | "onChange" | "onInvalid" | "onBlur"
    >;
}

export const useTextField = (props: {
    initialValue?: string;
    validate?: (value: string) => ReactNode;
}): UseTextFieldOutput => {
    const cleanInitialValue = props.initialValue || "";
    const [value, setValue] = useState(cleanInitialValue);
    const [isDirty, setDirty] = useState(!!cleanInitialValue);
    const [errorText, setErrorText] = useState(props.validate?.(cleanInitialValue));
    const inputRef = useRef<HTMLInputElement>();

    useEffect(
        () => setValue(cleanInitialValue),
        [setValue, cleanInitialValue]
    );

    useEffect(
        () => setErrorText(props.validate?.(value)),
        [setErrorText, value] // skipping `props.validate` for now
    );

    const handleChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setDirty(true);
            setValue(e.target.value);
        },
        [setDirty, setValue]
    );

    const handleInvalid = useCallback(
        <T>(e: FormEvent<T>) => {
            e.preventDefault();
            setDirty(true);
        },
        [setDirty]
    );

    const handleBlur = useCallback(
        () => {
            setDirty(true);
        },
        [setDirty]
    );

    const reset = useCallback(
        () => {
            setValue(cleanInitialValue);
            setDirty(!!cleanInitialValue);
            setErrorText(props.validate?.(cleanInitialValue));
        },
        [
            cleanInitialValue,
            setValue,
            setDirty,
            setErrorText
        ]
    );

    return {
        value,
        setValue,
        isDirty,
        setDirty,
        errorText,
        setErrorText,
        isValid: !isDirty || !errorText,
        reset,
        focus: () => inputRef?.current?.focus(),
        textFieldProps: {
            value,
            helperText: isDirty && errorText || " ",
            error: isDirty && !!errorText,
            inputRef,
            onChange: handleChange,
            onInvalid: handleInvalid,
            onBlur: handleBlur
        }
    };
};
