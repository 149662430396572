import BaseServices from "../common/BaseServices";
import { EDITING_MODE, STAGE_MODE } from "../common/Consts";
import type { AccountsDBProgram, Program, ProgramSummary } from "../../../common/types/program";
import type { Snapshot } from "../../../common/types/snapshot";
import type { RecordingDocItem } from "../../../common/types/asset";

class ProjectServices extends BaseServices {
    constructor(authService, builderModeRetriever) {
        super(authService, builderModeRetriever);
    }

    getProject(accountID, projectName, gqlCovered = false) {
        return this.get<Program>([EDITING_MODE, STAGE_MODE], "/rest/" + accountID + "/projects/" + projectName, undefined, { params: { gqlCovered } });
    }

    getProjects(accountID) {
        var _that = this;
        return new Promise(function(resolve, reject) {
            _that
                .get([EDITING_MODE, STAGE_MODE], "/rest/" + accountID + "/projects")
                .then(function(projectsArray) {
                    resolve(projectsArray);
                })
                .catch(function(error) {
                    reject(error);
                });
        });
    }

    getNewPrograms(accountID: string) {
        return this.get<AccountsDBProgram[]>([EDITING_MODE, STAGE_MODE], "/rest/" + accountID + "/newProjects");
    }

    addProject(accountID, project) {
        const projectThumbnail = project.projectThumbnail;

        return this.post<Program>([EDITING_MODE], "/rest/" + accountID + "/projects", project)
            .then((res) => {
                if (projectThumbnail) {
                    return this.addProjectThumbnail(accountID, res.projectName, projectThumbnail);
                }
                return res;
            });
    }

    deleteProject(accountID, projectName) {
        var _that = this;
        return new Promise(function(resolve, reject) {
            _that
                .delete([EDITING_MODE], "rest/" + accountID + "/projects/" + projectName)
                .then(function(res) {
                    resolve(res);
                })
                .catch(function(err) {
                    reject(err);
                });
        });
    }

    updateProject(accountID, projectName, project) {
        return this.put([EDITING_MODE], "/rest/" + accountID + "/projects/" + projectName, project);
    }

    checkNewProjectNamePattern(newProjectName) {
        return typeof newProjectName === "string" && newProjectName.match(/^[\w-]+$/);
    }

    addProjectThumbnail(accountId, projectName, thumbnail) {
        var fd = new FormData();
        fd.append("file", thumbnail);
        return this.put<Program>([EDITING_MODE], "/rest/" + accountId + "/projects/" + projectName + "/thumbnail/" + thumbnail.name + "/content", fd, { "Content-Type": "multipart/form-data" });
    }

    getProjectSnapshots(accountId, projectName) {
        return this.get<{ Items: Omit<Snapshot, "assets">[]}>([EDITING_MODE, STAGE_MODE], "/rest/" + accountId + "/projects/" + projectName + "/snapshots");
    }

    loadProjectSummaries(accountId) {
        return this.get<ProgramSummary[]>([EDITING_MODE, STAGE_MODE], "/rest/" + accountId + "/projectSummaries/");
    }

    generateRecordingDoc(accountId, projectName, recordingAssets, programVersionId) {
        return this.post([EDITING_MODE, STAGE_MODE], `/rest/${accountId}/projects/${projectName}/generateRecordingDocs`, recordingAssets, undefined, { params: { programVersionId } });
    }

    getRecordingDocs(accountId, projectName) {
        return this.get<RecordingDocItem[]>([EDITING_MODE, STAGE_MODE], `/rest/${accountId}/projects/${projectName}/recordingDocs`);
    }
}

export default ProjectServices;
