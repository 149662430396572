import { GET_ACCOUNT_DASHBOARD_DATA_SUCCESS, GET_ACCOUNT_VIDEO_VIEWS_SUCCESS } from "./accountOverviewActions";
const accountOverviewReducer = (state, action) => {
    let allProgramsIds = state.dashboardPrograms.allIds;
    let programsById = state.dashboardPrograms.byId;
    let allScenesIds = state.dashboardScenes.allIds;
    let scenesById = state.dashboardScenes.byId;
    switch (action.type) {
        case GET_ACCOUNT_DASHBOARD_DATA_SUCCESS: {
            const [accountInfo, accountPrograms, accountScenes] = action.payload;
            accountPrograms.forEach((program) => {
                if (programsById[program.id]) {
                    programsById[program.id] = Object.assign(Object.assign({}, programsById[program.id]), { program });
                }
                else {
                    allProgramsIds.push(program.id);
                    programsById[program.id] = program;
                }
            });
            accountScenes.forEach((scene) => {
                if (scenesById[scene.id]) {
                    scenesById[scene.id] = Object.assign(Object.assign({}, scenesById[scene.id]), { scene });
                }
                else {
                    allScenesIds.push(scene.id);
                    scenesById[scene.id] = scene;
                }
                if (scene.usedInPrograms) {
                    scene.usedInPrograms.forEach((pId) => {
                        if (!programsById[pId]) {
                            allProgramsIds.push(pId);
                            programsById[pId] = {};
                        }
                        if (!programsById[pId].scenes) {
                            programsById[pId].scenes = [];
                        }
                        if (programsById[pId].scenes.indexOf(scene.id) < 0) {
                            programsById[pId].scenes.push(scene.id);
                        }
                    });
                }
            });
            return Object.assign(Object.assign({}, state), { accountInfo, dashboardPrograms: { allIds: allProgramsIds, byId: programsById }, dashboardScenes: { allIds: allScenesIds, byId: scenesById } });
        }
        case GET_ACCOUNT_VIDEO_VIEWS_SUCCESS:
            return Object.assign(Object.assign({}, state), { accountVideoViews: action.payload.accountVideoViews });
        default:
            return state;
    }
};
export default accountOverviewReducer;
