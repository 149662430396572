import { createAction } from "redux-actions";
import { reportError } from "../../common/commonActions";
import type { CreateVideoSessionParams } from "./programOverviewServices";

export const GENERATE_MOVIE_SUCCESS = "GENERATE_MOVIE_SUCCESS";
export const GET_PRESET_DATA_ELEMENTS_SUCCESS = "GET_PRESET_DATA_ELEMENTS_SUCCESS";
export const SAVE_PRESET_SUCCESS = "SAVE_PRESET_SUCCESS";
export const GET_ANALYTICS_SUCCESS = "GET_ANALYTICS_SUCCESS";
export const GET_PROGRAM_DETAILS_SUCCESS = "GET_PROGRAM_DETAILS_SUCCESS";
export const UPLOAD_IMAGE = "UPLOAD_IMAGE_SUCCESS";
export const SECOND_UPLOAD_IMAGE = "SECOND_UPLOAD_IMAGE";
export const CREATE_SHARE_URL_SUCCESS = "CREATE_SHARE_URL_SUCCESS";
export const RESET_ANALYTICS = "RESET_ANALYTICS";

export const generatingMovieSuccess = createAction(GENERATE_MOVIE_SUCCESS, function(previewDashboardSessionId, stateObjName, stateShareUrlObjName = "shareUrl") {
    return { sessionId: previewDashboardSessionId.body, stateObjName: stateObjName, shortUrl: previewDashboardSessionId.shortUrl, stateShareUrlObjName: stateShareUrlObjName };
});

export const savePresetSuccess = createAction(SAVE_PRESET_SUCCESS, function(previewDashboardSessionId) {
    return { sessionId: previewDashboardSessionId };
});

export const getPresetDataElementsSuccess = createAction(GET_PRESET_DATA_ELEMENTS_SUCCESS, function(presetDataElements, stateObjName) {
    return { presetDataElements: presetDataElements, stateObjName: stateObjName };
});

export const getAnalyticsSuccess = createAction(GET_ANALYTICS_SUCCESS, function(analyticsEmbedContainerChartUrl) {
    return { chartUrl: analyticsEmbedContainerChartUrl };
});

export const resetAnalyticsUrl = createAction(RESET_ANALYTICS, function() {
    return { chartUrl: undefined };
});

export const getProgramDetailsSuccess = createAction(GET_PROGRAM_DETAILS_SUCCESS, function(programId, programDetails) {
    return { programId: programId, programDetails: programDetails };
});

export const uploadImageSuccess = createAction(UPLOAD_IMAGE, function({ url, thumbnail }) {
    return { url, thumbnail };
});

export const secondUploadImageSuccess = createAction(SECOND_UPLOAD_IMAGE, function({ url, thumbnail }) {
    return { url, thumbnail };
});

export const uploadImage = function(accountId, programId, image) {
    return function(dispatch, state, services) {
        services.programOverviewServices
            .uploadImage(accountId, programId, image)
            .then(function(res) {
                dispatch(uploadImageSuccess(res));
            })
            .catch(function(err) {
                dispatch(reportError(err));
            });
    };
};

export const secondUploadImage = function(accountId, programId, image) {
    return function(dispatch, state, services) {
        services.programOverviewServices
            .uploadImage(accountId, programId, image)
            .then(function(res) {
                dispatch(secondUploadImageSuccess(res));
            })
            .catch(function(err) {
                dispatch(reportError(err));
            });
    };
};

export const createShareUrlSuccess = createAction(CREATE_SHARE_URL_SUCCESS, function(shareUrl) {
    return { shareUrl: shareUrl };
});

export const getProgramDetails = function(accountId, programId) {
    return function(dispatch, state, services) {
        services.programOverviewServices
            .getProgramDetails(accountId, programId)
            .then(function(res) {
                dispatch(getProgramDetailsSuccess(programId, res));
            })
            .catch(function(err) {
                dispatch(reportError(err));
            });
    };
};

export const getPresetDataElements = function(accountId, programId, presetId, stateObjName = "presetDataElements") {
    return function(dispatch, state, services) {
        services.programOverviewServices
            .getPresetDataElements(accountId, programId, presetId)
            .then(function(res) {
                dispatch(getPresetDataElementsSuccess(res, stateObjName));
            })
            .catch(function(err) {
                dispatch(reportError(err));
            });
    };
};

export const getSecondPresetDataElements = function(accountId, programId, presetId) {
    return getPresetDataElements(accountId, programId, presetId, "secondPresetDataElements");
};

export const createVideoSession = function({
    accountId,
    programId,
    presetId,
    dataElements,
    stateObjName = "previewDashboardSessionObj",
    generateShareUrl,
    previewSettings,
    videoFormat,
    requestId
}: CreateVideoSessionParams) {
    return function(dispatch, state, services) {
        services.programOverviewServices
            .createVideoSession({ accountId, programId, presetId, dataElements, generateShareUrl, previewSettings, videoFormat, requestId })
            .then(function(res) {
                dispatch(generatingMovieSuccess(res, stateObjName));
            })
            .catch(function(err) {
                dispatch(reportError(err));
            });
    };
};

export const createShareUrl = function(accountId, programId, presetId, dataElements) {
    return function(dispatch, state, services) {
        services.programOverviewServices
            .createShareUrl(accountId, programId, presetId, dataElements)
            .then(function(res) {
                dispatch(createShareUrlSuccess(res));
            })
            .catch(function(err) {
                dispatch(reportError(err));
            });
    };
};

export const createSecondVideoSession = function({ accountId, programId, presetId, dataElements, previewSettings, requestId }) {
    return createVideoSession({ accountId, programId, presetId, dataElements, stateObjName: "secondPreviewDashboardSessionObj", generateShareUrl: false, previewSettings, requestId });
};

export const createShareVideoSession = function({ accountId, programId, presetId, dataElements, previewSettings }) {
    return createVideoSession({ accountId, programId, presetId, dataElements, stateObjName: "shareDashboardSessionObj", generateShareUrl: true, previewSettings });
};

export const getAnalytics = function(accountId, programId, programFlow) {
    return function(dispatch, state, services) {
        // Resets the chartURL until the result from the server arrives,
        // to avoid seeing a wrong dashboard when toggling between programs.
        dispatch(resetAnalyticsUrl());
        services.programOverviewServices
            .getAnalytics(accountId, programId, programFlow)
            .then(function(res) {
                dispatch(getAnalyticsSuccess(res));
            })
            .catch(function(err) {
                dispatch(reportError(err, false));
            });
    };
};

export const savePreset = function(accountId, programId, presetId, dataElements, presetName) {
    return function(dispatch, state, services) {
        services.programOverviewServices
            .savePreset(accountId, programId, presetId, dataElements, presetName)
            .then(function(res) {
                dispatch(savePresetSuccess(res));
            })
            .catch(function(err) {
                dispatch(reportError(err));
            });
    };
};
