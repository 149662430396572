import axios from "axios";
import type { PollingResponse, PollingTaskCreationResponse } from "../../../common/types/polling";

export type UpdateProgressCb = (progress: number, data: any) => void;

const POLLING_INTERVAL_MILLIS = 2000;

const waitForTaskToCompleteInternal = async <T>(pollingUrl: string, intervalMillis: number, resolve, reject, updateProgressCb?: UpdateProgressCb) => {
    axios
        .get<PollingResponse<T>>(pollingUrl)
        .then((response) => {
            if (response && response.status === 200 && response.data) {
                const { status, progress, data, error } = response.data;
                switch (status) {
                    case "Done": {
                        if (updateProgressCb) {
                            updateProgressCb(100, data);
                        }
                        resolve(data);
                        break;
                    }
                    case "Failed":
                    case "Not Found": {
                        if (updateProgressCb) {
                            updateProgressCb(undefined, undefined);
                        }
                        reject(error);
                        break;
                    }
                    case "Pending": {
                        if (updateProgressCb) {
                            updateProgressCb(progress, data);
                        }
                        setTimeout(waitForTaskToCompleteInternal, intervalMillis, pollingUrl, intervalMillis, resolve, reject, updateProgressCb);
                        break;
                    }
                    default: {
                        if (updateProgressCb) {
                            updateProgressCb(undefined, undefined);
                        }
                        reject(`Error: Unknown status ${status}`);
                    }
                }
            }
            else {
                if (updateProgressCb) {
                    updateProgressCb(undefined, undefined);
                }
                reject("Error");
            }
        })
        .catch((error) => {
            if (updateProgressCb) {
                updateProgressCb(undefined, undefined);
            }
            reject(error);
        });
};

export class PollingHandler {
    public static waitForTaskToComplete = async <T = any>(doSomething: Promise<PollingTaskCreationResponse>, updateProgressCb?: UpdateProgressCb): Promise<T> => {
        const response: PollingTaskCreationResponse = await doSomething;
        const pollingUrl: string = response.pollingUrl;
        return new Promise((resolve, reject) => {
            waitForTaskToCompleteInternal<T>(pollingUrl, POLLING_INTERVAL_MILLIS, resolve, reject, updateProgressCb);
        });
    };
}
