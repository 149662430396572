import { StudioMainCategoriesPaths } from "@sundaysky/smartvideo-hub-urls";

export const EDITING_MODE = "edit";
export const STAGE_MODE = "stage";

export const POSTGRES_CREATIVES_PRESET_ID = "2269852e-f11f-4d6a-90b4-d725901cf52a";
export const IGNORE_UPDATED = "$IGNORE$";
export const IGNORE_SERVER_ERRORS = "ignoreServerErrors";
export const MOCK_SCENE_ENTITY_LOGIC_ID = "SCENE_ENTITY_LOGIC_TEMP_POSTGRES_ID";
export const MASTER_ANIMATION_GUIDELINE_ID = "MASTER_ANIMATION_GUIDELINE_TEMP_POSTGRES_ID";
export const GQL_ID_SEPARATOR = "|";
export const CREATIVES_PRESET_ID = "creatives_preset_id";
