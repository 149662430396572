import {
    legacyRoot,
    newNavAiVideoCreationPath,
    newNavAnalyticsPath,
    newNavAnalyticsReportSetupPath,
    newNavAnalyticsReportsPath,
    newNavEditorPath,
    newNavRoot,
    newNavStoryTemplatesPath,
    newNavUserManagementPath,
    newNavVideoPagePath,
    newNavVideoReviewPath,
    newNavVideosArchivePath,
    newNavYourVideosPath,
    type Path
} from "@sundaysky/smartvideo-hub-urls";


export const isNewNav = (newNavRoot as string) === "/"
    ? !window.location.pathname.startsWith(legacyRoot)
    : window.location.pathname.startsWith(newNavRoot);

export const programMicrocopyCapitalized = isNewNav ? "Video" : "Program";
export const programMicrocopyLowercase = programMicrocopyCapitalized.toLowerCase();

const REPORT_CONTENT_LOAD_PAGES: [Path, string][] = [
    [newNavYourVideosPath as Path, "Your Videos"],
    [newNavVideosArchivePath as Path, "Your Videos - Archive"],
    [newNavVideoPagePath as Path, "Video Page"],
    [newNavEditorPath as Path, "Studio"],
    [newNavVideoReviewPath as Path, "Video Review"],
    [newNavStoryTemplatesPath as Path, "Story Templates"],
    [newNavAiVideoCreationPath as Path, "AI Copilot"],
    [newNavAnalyticsPath as Path, "Analytics"],
    [newNavAnalyticsReportsPath as Path, "Analytics - Reports"],
    [newNavAnalyticsReportSetupPath as Path, "Analytics - Report Setup"],
    [newNavUserManagementPath as Path, "User Management"]
];

export function pagePathToName(pathname: string): string | null {
    for (const [path, name] of REPORT_CONTENT_LOAD_PAGES) {
        if (path.match(pathname)) return name;
    }
    return null;
}
