import { matchPath } from "react-router";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { newNavVideoPagePath } from "@sundaysky/smartvideo-hub-urls";
import { PendoService } from "../../components/pendoService";

export const usePendoUpdateVideoIdEffect = () => {
    // this triggers the hook upon URL change
    useLocation();

    const getVideoId = () => {
        let videoId = "";

        const match = matchPath(
            window.location.pathname,
            {
                path: newNavVideoPagePath.pattern,
                strict: false,
                exact: false
            }
        );
        if (match) {
            videoId = match.params["videoId"];
        }
        return videoId;
    };

    const videoId = getVideoId();

    useEffect(() => {
        PendoService.getInstance().updateVideoId(videoId);
    }, [videoId]);
};
