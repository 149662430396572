module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("\n        <!doctype html>\n        <html lang=\"en\" dir=\"ltr\">\n            <head>\n                <meta charset=\"utf-8\" />\n                <meta name=\"viewport\" content=\"width=device-width, initial-scale=1.0\"/>\n  <link href=\"https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap\" rel=\"stylesheet\">\n<link rel=\"stylesheet\" href=\"https://use.fontawesome.com/releases/v5.4.2/css/all.css\" />\n<link rel=\"stylesheet\" href=\"https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.2/css/all.min.css\">\n<link rel=\"stylesheet\" href=\"https://cdn.jsdelivr.net/gh/highlightjs/cdn-release@10.1.1/build/styles/github.min.css\"/>\n<link rel=\"stylesheet\" href=\"https://cdn.jsdelivr.net/npm/magnific-popup@1.1.0/dist/magnific-popup.css\"/>\n<link rel=\"stylesheet\" href=\"")
    ; __append(escapeFn( require('./assets/lotus-utilities.css') ))
    ; __append("\" />\n<link rel=\"stylesheet\" href=\"")
    ; __append(escapeFn( require('./assets/sidenav.css') ))
    ; __append("\" />\n\n<script src=\"https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.min.js\"></script>\n<script src=\"https://cdn.jsdelivr.net/npm/magnific-popup@1.1.0/dist/jquery.magnific-popup.min.js\"></script>\n<script src=\"https://cdn.jsdelivr.net/gh/highlightjs/cdn-release@10.1.1/build/highlight.min.js\"></script>\n<script src=\"https://cdn.jsdelivr.net/npm/@ryangjchandler/spruce@2.x.x/dist/spruce.umd.js\"></script>\n<script src=\"https://cdn.jsdelivr.net/gh/alpinejs/alpine@v2.8.2/dist/alpine.min.js\"></script>\n<script src=\"")
    ; __append(escapeFn( require('./assets/apidata-min.js') ))
    ; __append("\"></script>\n\n<script>\n  window.LotusConfig = {\n    icons: {\n      \"7327725592221\": \"")
    ; __append(escapeFn( require('./assets/category_getting_started.svg') ))
    ; __append("\",\n      \"7327725592221-hover\": \"")
    ; __append(escapeFn( require('./assets/category_getting_started_active.svg') ))
    ; __append("\",\n  \n      \"7327821087261\": \"")
    ; __append(escapeFn( require('./assets/Global_Video_Settings.svg') ))
    ; __append("\",\n      \"7327821087261-hover\": \"")
    ; __append(escapeFn( require('./assets/Global_Video_Settings_active.svg') ))
    ; __append("\",\n  \n      \"7328158382493\": \"")
    ; __append(escapeFn( require('./assets/category_configuring_content.svg') ))
    ; __append("\",\n      \"7328158382493-hover\": \"")
    ; __append(escapeFn( require('./assets/category_configuring_content_active.svg') ))
    ; __append("\",\n  \n      \"7328258609821\": \"")
    ; __append(escapeFn( require('./assets/category_previewing_publishing.svg') ))
    ; __append("\",\n      \"7328258609821-hover\": \"")
    ; __append(escapeFn( require('./assets/category_previewing_publishing_active.svg') ))
    ; __append("\",\n  \n      \"7328326340893\": \"")
    ; __append(escapeFn( require('./assets/text_and_buttons.svg') ))
    ; __append("\",\n      \"7328326340893-hover\": \"")
    ; __append(escapeFn( require('./assets/text_and_buttons_active.svg') ))
    ; __append("\",\n  \n      \"7328400801181\": \"")
    ; __append(escapeFn( require('./assets/category_working_w_media.svg') ))
    ; __append("\",\n      \"7328400801181-hover\": \"")
    ; __append(escapeFn( require('./assets/category_working_w_media_active.svg') ))
    ; __append("\",\n  \n      \"7328430451229\": \"")
    ; __append(escapeFn( require('./assets/category_working_w_sound.svg') ))
    ; __append("\",\n      \"7328430451229-hover\": \"")
    ; __append(escapeFn( require('./assets/category_working_w_sound_active.svg') ))
    ; __append("\",\n  \n      \"7328600539677\": \"")
    ; __append(escapeFn( require('./assets/category_personalizing_video.svg') ))
    ; __append("\",\n      \"7328600539677-hover\": \"")
    ; __append(escapeFn( require('./assets/category_personalizing_video_active.svg') ))
    ; __append("\",\n  \n      \"7328690582045\": \"")
    ; __append(escapeFn( require('./assets/Distribution.svg') ))
    ; __append("\",\n      \"7328690582045-hover\": \"")
    ; __append(escapeFn( require('./assets/Distribution_active.svg') ))
    ; __append("\",\n  \n      \"7329002259485\": \"")
    ; __append(escapeFn( require('./assets/category_analytics.svg') ))
    ; __append("\",\n      \"7329002259485-hover\": \"")
    ; __append(escapeFn( require('./assets/category_analytics_active.svg') ))
    ; __append("\",\n  \n      \"7329224440221\": \"")
    ; __append(escapeFn( require('./assets/category_getting_support.svg') ))
    ; __append("\",\n      \"7329224440221-hover\": \"")
    ; __append(escapeFn( require('./assets/category_getting_support_active.svg') ))
    ; __append("\",\n  \n      \"4406057631889\": \"")
    ; __append(escapeFn( require('./assets/category_whats_new.svg') ))
    ; __append("\",\n      \"4406057631889-hover\": \"")
    ; __append(escapeFn( require('./assets/category_whats_new_active.svg') ))
    ; __append("\",\n  \n        \"4402963580561\": \"")
    ; __append(escapeFn( require('./assets/category_extensions.svg') ))
    ; __append("\",\n      \"4402963580561-hover\": \"")
    ; __append(escapeFn( require('./assets/category_extensions_active.svg') ))
    ; __append("\",\n    }\n  };\n  LotusConfig.css = {\n    activeClass: 'is-active',\n    hiddenClass: 'is-hidden'\n  };\n  window.addEventListener('load', () => {\n    const breadcrumbs = document.querySelector('.breadcrumbs');\n\n    //Recolor breadcrumbs on article page\n    if (LotusUtils.isArticlePage() && breadcrumbs\n      || LotusUtils.isSearchResultsPage() > -1 && breadcrumbs\n    ) {\n      breadcrumbs.classList.add('breadcrumbs--article_page');\n    }\n\n    //TOC button text\n    if (LotusUtils.isArticlePage()) {\n      const sectionName = document.querySelector('.js-section-name').textContent;\n      const moreArticlesButton = document.querySelector('.related-articles__link');\n      if (moreArticlesButton && sectionName) {\n        moreArticlesButton.textContent = \"More articles from this section \";\n      }\n    }\n  });\n\n  //Redirect from the section link to category page and add icon to the mobile sidenav button\n  $(window).on('sidenavRendered', function(e, sidenav) {\n    const sectionTitles = Array.from(document.querySelectorAll('.sidenav__section-name'));\n    if (LotusUtils.isCategoryPage() && sectionTitles) {\n      $('#scroll-container').scroll(function() {\n        $('.sidenav__categories-list').css(\n          'top', $(this).scrollTop()\n        );\n      });\n      const mobileToggle = document.querySelector('.sidenav-btn');\n      if (mobileToggle) {\n        mobileToggle.insertAdjacentHTML('afterbegin', `<i class=\"fas fa-bars\"></i>`);\n      }\n    }\n  });\n\n</script>\n\n<script src=\"")
    ; __append(escapeFn( require('./assets/lotus-utils.js') ))
    ; __append("\"></script>\n<script src=\"")
    ; __append(escapeFn( require('./assets/dropdown.js') ))
    ; __append("\"></script>\n<script src=\"")
    ; __append(escapeFn( require('./assets/search-results.js') ))
    ; __append("\"></script>\n<script src=\"")
    ; __append(escapeFn( require('./assets/notification.js') ))
    ; __append("\"></script>\n<script src=\"")
    ; __append(escapeFn( require('./assets/lotus-icons.js') ))
    ; __append("\"></script>\n<script src=\"")
    ; __append(escapeFn( require('./assets/sidenav-min.js') ))
    ; __append("\"></script>\n<script src=\"")
    ; __append(escapeFn( require('./assets/sidenav-icons.js') ))
    ; __append("\"></script>\n\n<link rel=\"stylesheet\" href=\"")
    ; __append(escapeFn( require('./assets/toc.css') ))
    ; __append("\" />\n<script src=\"")
    ; __append(escapeFn( require('./assets/toc-min.js') ))
    ; __append("\"></script>\n<script src=\"")
    ; __append(escapeFn( require('./assets/category-logic.js') ))
    ; __append("\"></script>\n<script src=\"")
    ; __append(escapeFn( require('./assets/show-all-articles-category.js') ))
    ; __append("\"></script>\n\n\n                <link rel=\"stylesheet\" href=\"")
    ; __append(escapeFn( require('./style.css') ))
    ; __append("\">\n                <style>\n                    body {\n                        overflow: hidden;\n                        height: fit-content;\n                    }\n                    h4 {\n                        font-size: 16px;\n                        scroll-margin-top: calc(1em - 1px); /* Account for divider before h4 */\n                    }\n                </style>\n                <title>")
    ; __append(escapeFn( title ))
    ; __append("</title>\n            </head>\n            <body>\n                ")
    ; __append( body )
    ; __append("\n                <script type=\"text/javascript\" src=\"")
    ; __append(escapeFn( require('./script.js') ))
    ; __append("\"></script>\n                <script type=\"text/javascript\">\n                    const currentArticleId = \"")
    ; __append(escapeFn( articleId ))
    ; __append("\";\n                    document.querySelectorAll(\"a[href^='http']\").forEach((a) => {\n                        a.addEventListener(\"click\", (e) => {\n                            e.preventDefault();\n                            const match = a.href.match(/https:\\/\\/help.sundaysky.com\\/hc\\/en-us\\/articles\\/(\\d+).*?/);\n                            if (match) {\n                                const url = new URL(a.href);\n                                window.parent.postMessage({\n                                    type: \"openArticle\",\n                                    articleId: match[1],\n                                    sectionId: url.hash.replace(\"#\", \"\")\n                                }, \"*\");\n                            }\n                            else {\n                                window.open(a.href, \"_blank\");\n                            }\n                        });\n                    });\n                    document.querySelectorAll(\"a[href^='#']\").forEach((a) => {\n                        a.addEventListener(\"click\", (e) => {\n                            e.preventDefault();\n                            window.parent.postMessage({\n                                type: \"openArticle\",\n                                articleId: currentArticleId,\n                                sectionId: a.getAttribute(\"href\").substring(1)\n                            }, \"*\");\n                        });\n                    });\n                    new ResizeObserver(() => {\n                        window.parent.postMessage({\n                            type: \"articleResize\",\n                            height: document.body.scrollHeight,\n                        }, \"*\");\n                    }).observe(document.body);\n                </script>\n            </body>\n        </html>\n")
  }
  return __output;

}