import type { BasicLogData, LogReportContent } from "../../../common/types/commonTypes";
import { ReportEventType } from "../../../common/types/commonTypes";
import UrlUtils from "./urlUtils";
import StateReaderUtils from "./StateReaderUtils";
import { getStageFromPathname, getVersionFromPathname } from "../../components/main/mainContainerUtils";
import { getReduxState } from "../../components/store";
import { getService } from "../../components/services";
import type CommonServices from "./commonServices";
import { activeAccountIdVar, userDataVar } from "../../components/model";

export type ReportErrorData = {
    stackMessage?: string | string[];
    error?: any;
    requestUrl?: string;
    errorStackMessage?: any;
    operation?: {
        name: string;
        variables: any;
    };
    metadata?: any;
    crashed: boolean;
};
export const reportErrorToSplunk = (errorMessage: string, metadataObj: ReportErrorData): void => {
    const data: LogReportContent = getLogReportContent(errorMessage, metadataObj, ReportEventType.Error, getReduxState());
    getService<CommonServices>("commonServices").reportLog(data);
};

export const reportInfoToSplunkRedux = (message: string, data: any): void => {
    const reportData: LogReportContent = getLogReportContent(message, data, ReportEventType.Info, getReduxState());
    getService<CommonServices>("commonServices").reportLog(reportData);
};

export const reportInfoToSplunk = (message: string, data: any): void => {
    const reportData: LogReportContent = getLogReportContent(message, data, ReportEventType.Info);
    getService<CommonServices>("commonServices").reportLog(reportData);
};


export const getLogReportContent = (message: string, reportData: any, eventType: ReportEventType, state?: any): LogReportContent => {
    const FS = (window as any).FS;
    const fsUrl: string = FS && FS.getCurrentSessionURL ? FS.getCurrentSessionURL(true) : "";
    const fsSession: string = FS && FS.getCurrentSession ? FS.getCurrentSession() : "";
    const accountProgram = UrlUtils.getAccountAndProgramFromPath();
    let programId: string = accountProgram?.program;
    let accountId: string;
    let accountName: string;

    if (state !== undefined) {
        accountId = accountProgram?.accountId;
        accountName = StateReaderUtils.getActiveAccount(state)?.displayName || {};
    }
    else {
        accountId = activeAccountIdVar();
        const dataVar = userDataVar();
        accountName = dataVar?.accounts.find(
            account => account.accountId === accountId
        ).name;
    }

    const basicInfo: BasicLogData = {
        accountId,
        accountName,
        projectName: programId,
        stage: getStageFromPathname(window?.location?.pathname),
        version: getVersionFromPathname(window?.location?.pathname),
        fsUrl,
        fsSession
    };

    const metadata = {
        ...reportData,
        ...basicInfo
    };

    Object.keys(metadata).forEach((key: string) => {
        if (reportData && !reportData[key]) {
            reportData[key] = "";
        }
    });

    return {
        msg: message,
        metadata,
        eventType
    };
};
