import type { ValidateFunction, ValidationResult } from "./validationManager";
import { RecordStatus } from "../entities/definitions";

export interface IValidationTableRecord {
    prevValidationResult: ValidationResult;
    validate: ValidateFunction;
    validationResult: ValidationResult;
    status: RecordStatus;
}

export default class ValidationTableRecord implements IValidationTableRecord {
    prevValidationResult: ValidationResult;
    public readonly validate: ValidateFunction;
    validationResult: ValidationResult;
    status: RecordStatus;

    constructor(prevValidationResult: ValidationResult, validate: ValidateFunction) {
        this.prevValidationResult = prevValidationResult;
        this.validate = validate;
        this.validationResult = null;
        this.status = RecordStatus.PENDING;
    }
}
