import Immutable from "immutable";
import md5 from "md5";
import { DataElementContentTypes } from "../../../../../common/types/dataElement";
import { DATA_ELEMENT_VALUE_OTHER_OR_MISSING } from "../../../../components/legacyCommon/Consts";
const Structure = Immutable.Record({ stack: undefined, representativeKey: undefined, creativeOverride: undefined });
const Layer = Immutable.Record({ valueSetSource: undefined, valueSetId: undefined, name: "", type: undefined, valueSet: undefined, merges: undefined });
const Dimension = Immutable.Record({ id: "", name: "", type: undefined, valueSet: undefined, indexMap: Immutable.Map(), orphaned: false, removed: false, hasOrphanedValues: false });
const Variation = Immutable.Record({ points: undefined, rp: undefined, orphaned: false, dataOrphaned: false, variationData: undefined });
const VariationData = Immutable.Record({ text: "", comment: "", muted: false, overrideId: "" });
////////////////////////
//                    //
// EXPORTED FUNCTIONS //
//                    //
////////////////////////
//
// Returns the default structure (i.e., for an initial empty table)
//
const defaultStructure = () => {
    return new Structure({ stack: Immutable.List(), representativeKey: generateKey(emptyPointSet()) });
};
//
// Returns the default variation data map (i.e., for an initial empty table)
//
const defaultVariationDataMap = () => {
    return Immutable.Map();
};
//
// Builds and returns the dimensions for the given structure
//
const buildDimensions = (structure, valueSetProviders) => {
    return buildDimensionsFromStack(structure.stack, valueSetProviders);
};
//
// Builds and returns the variations for a given structure, etc.
//
const buildVariations = (structure, variationDataMap, dimensions, valueSetProviders) => {
    return buildVariationsFromStack(structure.stack, variationDataMap, dimensions, valueSetProviders);
};
//
// Sets the representative variation
//
const setRepresentativeKey = (representativeKey) => {
    return {
        structureUpdate: { representativeKey }
    };
};
//
// Sets the text for a given variation
//
const setText = (variationKey, variationDataMap, defaultVariationData, text) => {
    if (variationDataMap.has(variationKey)) {
        return {
            variationDataUpdates: { [variationKey]: { text } }
        };
    }
    return {
        variationDataUpdates: { [variationKey]: { text, comment: defaultVariationData.comment, muted: defaultVariationData.muted, overrideId: defaultVariationData.overrideId } }
    };
};
//
// Sets the comment for a given variation
//
const setComment = (variationKey, variationDataMap, defaultVariationData, comment) => {
    if (variationDataMap.has(variationKey)) {
        return {
            variationDataUpdates: { [variationKey]: { comment } }
        };
    }
    return {
        variationDataUpdates: { [variationKey]: { text: defaultVariationData.text, comment, muted: defaultVariationData.muted, overrideId: defaultVariationData.overrideId } }
    };
};
//
// Mutes a given variation
//
const mute = (variationKey, variationDataMap, defaultVariationData) => {
    if (variationDataMap.has(variationKey)) {
        return {
            variationDataUpdates: { [variationKey]: { muted: true } }
        };
    }
    return {
        variationDataUpdates: { [variationKey]: { text: defaultVariationData.text, comment: defaultVariationData.comment, muted: true, overrideId: defaultVariationData.overrideId } }
    };
};
//
// Unmutes a given variation
//
const unmute = (variationKey, variationDataMap, defaultVariationData) => {
    if (variationDataMap.has(variationKey)) {
        return {
            variationDataUpdates: { [variationKey]: { muted: false } }
        };
    }
    return {
        variationDataUpdates: { [variationKey]: { text: defaultVariationData.text, comment: defaultVariationData.comment, muted: false, overrideId: defaultVariationData.overrideId } }
    };
};
//
// Sets the creative override id for a given variation
//
const setOverrideId = (variationKey, variationDataMap, defaultVariationData, overrideId) => {
    if (variationDataMap.has(variationKey)) {
        return {
            variationDataUpdates: { [variationKey]: { overrideId } }
        };
    }
    return {
        variationDataUpdates: { [variationKey]: { text: defaultVariationData.text, comment: defaultVariationData.comment, muted: defaultVariationData.muted, overrideId } }
    };
};
//
// Adds a new dimension to the structure
//
const addDimension = (structure, valueSetProviders, valueSetProvider) => {
    if (findDimensionIndex(structure.stack, valueSetProvider) >= 0) {
        return {};
    }
    let valueSet = valueSetProvider.getValueSet();
    let merges = Immutable.OrderedSet();
    let layer = structure.stack.last();
    if (layer) {
        merges = join(layer.merges, valueSet);
    }
    let stack = structure.stack.push(new Layer({
        valueSetSource: valueSetProvider.getSource(),
        valueSetId: valueSetProvider.getId(),
        name: valueSetProvider.getName(),
        type: valueSetProvider.getType(),
        valueSet,
        merges: merges
    }));
    let representativePoints = getPointsForKey(structure.stack, valueSetProviders, structure.representativeKey);
    let expandedPoints = join(Immutable.OrderedSet.of(representativePoints), valueSet).first();
    let representativeKey = generateKey(expandedPoints);
    return {
        structureUpdate: { stack, representativeKey }
    };
};
//
// Marks a dimension as removed from the dimensions
//
const removeDimension = (dimensions, index) => {
    let dimension = dimensions.get(index);
    return dimensions.set(index, new Dimension({
        id: dimension.id,
        name: dimension.name,
        type: dimension.type,
        valueSet: dimension.valueSet,
        indexMap: dimension.indexMap,
        orphaned: dimension.orphaned,
        removed: true,
        hasOrphanedValues: dimension.hasOrphanedValues
    }));
};
//
// Set creative override flag
//
const setSupportCreativeOverride = (structure, variations, variationDataMap, supportCreativeOverride) => {
    if (structure.creativeOverride === supportCreativeOverride) {
        return {};
    }
    let finalVariationDataUpdates = {};
    if (!supportCreativeOverride) {
        variations.forEach((variation, variationKey) => {
            if (variation && variation.variationData && variation.variationData.overrideId) {
                let { variationDataUpdates } = setOverrideId(variationKey, variationDataMap, variation.variationData, "");
                if (variationDataUpdates) {
                    Object.assign(finalVariationDataUpdates, variationDataUpdates);
                }
            }
        });
    }
    return {
        structureUpdate: { creativeOverride: supportCreativeOverride },
        variationDataUpdates: finalVariationDataUpdates
    };
};
//
// Merges a set of variations
// Receives an array of keys that should be merged
// The order of the keys is maintained in the merge. Specifically the first one will be the surviving entity
//
const merge = (structure, variations, variationKeySets) => {
    let layer = structure.stack.last();
    let variationDataUpdates = {};
    let updatedRepresentativeKey;
    let merges = layer.merges.withMutations((mutableMerges) => {
        //If any of these are already merged, remove them from the merges
        variationKeySets.forEach((variationKeys) => {
            variationKeys.forEach((variationKey) => {
                let variation = variations.get(variationKey);
                if (mutableMerges.has(variation.points)) {
                    mutableMerges.delete(variation.points);
                }
            });
        });
        //Now create the new merge
        variationKeySets.forEach((variationKeys) => {
            let mergedPoints = Immutable.OrderedSet().withMutations((mutableMerge) => {
                variationKeys.forEach((variationKey) => {
                    let variation = variations.get(variationKey);
                    variation.points.forEach((point) => mutableMerge.add(point));
                });
            });
            mutableMerges.add(mergedPoints);
            let mergedKey = generateKey(mergedPoints);
            if (variationKeys.some((variationKey) => variationKey === structure.representativeKey)) {
                updatedRepresentativeKey = mergedKey;
            }
            let survivingVariationData = variations.get(variationKeys[0]).variationData;
            variationDataUpdates[mergedKey] = {
                text: survivingVariationData.text,
                comment: survivingVariationData.comment,
                muted: survivingVariationData.muted,
                overrideId: survivingVariationData.overrideId
            };
        });
    });
    layer = new Layer({ valueSetSource: layer.valueSetSource, valueSetId: layer.valueSetId, name: layer.name, type: layer.type, valueSet: layer.valueSet, merges });
    let stack = structure.stack.set(structure.stack.size - 1, layer);
    let representativeKey = updatedRepresentativeKey || structure.representativeKey;
    return {
        structureUpdate: { stack, representativeKey },
        variationDataUpdates
    };
};
//
// Unmerges variations - receives an array of points that should be removed from merges
//
const unmerge = (structure, variations, points) => {
    let layer = structure.stack.last();
    let variationDataUpdates = {};
    let updatedRepresentativeKey;
    //Remove points from merges
    let merges = Immutable.OrderedSet().withMutations((mutableMerges) => {
        layer.merges.forEach((mergedPoints) => {
            let variationKey = generateKey(mergedPoints);
            let updatedMergedPoints = mergedPoints.subtract(points);
            if (updatedMergedPoints.size > 1) {
                if (updatedMergedPoints.size < mergedPoints.size) {
                    //Something was removed
                    let variation = variations.get(variationKey);
                    let survivingVariationData = variation.variationData;
                    let updatedKey = generateKey(updatedMergedPoints);
                    variationDataUpdates[updatedKey] = {
                        text: survivingVariationData.text,
                        comment: survivingVariationData.comment,
                        muted: survivingVariationData.muted,
                        overrideId: survivingVariationData.overrideId
                    };
                    if (variationKey === structure.representativeKey) {
                        updatedRepresentativeKey = updatedKey;
                    }
                }
                mutableMerges.add(updatedMergedPoints);
            }
            else {
                //All was unmerged
                if (variationKey === structure.representativeKey) {
                    updatedRepresentativeKey = generateKey(Immutable.OrderedSet.of(mergedPoints.first()));
                }
            }
        });
    });
    layer = new Layer({ valueSetSource: layer.valueSetSource, valueSetId: layer.valueSetId, name: layer.name, type: layer.type, valueSet: layer.valueSet, merges });
    let stack = structure.stack.set(structure.stack.size - 1, layer);
    let representativeKey = updatedRepresentativeKey || structure.representativeKey;
    return {
        structureUpdate: { stack, representativeKey },
        variationDataUpdates
    };
};
//
// Accepts all dimensions and variations that have been marked as orphans or removed
// This gets rid of all the pink
//
const acceptOrphansAndRemoved = (structure, variationDataMap, dimensions, valueSetProviders) => {
    let orphanedOrRemovedDimFound = false;
    let stack = Immutable.List().withMutations((mutableStack) => {
        let valueSets = Immutable.List();
        structure.stack.forEach((layer, index) => {
            let dimension = dimensions.get(index);
            if (!dimension.orphaned && !dimension.removed) {
                let valueSetProvider = valueSetProviders.find((valueSetProvider) => valueSetProvider.getSource() === layer.valueSetSource && valueSetProvider.getId() === layer.valueSetId);
                let name = valueSetProvider.getName();
                let type = valueSetProvider.getType();
                let valueSet = valueSetProvider.getValueSet();
                valueSets = valueSets.push(valueSet);
                //Possible optimization: Only call the updateXXX functions if dimension.hasOrphanedValues
                let merges = !orphanedOrRemovedDimFound ? updateSetOfPointsPerValueSet(layer.merges, valueSets) : Immutable.OrderedSet();
                mutableStack.push(new Layer({ valueSetSource: layer.valueSetSource, valueSetId: layer.valueSetId, name, type, valueSet, merges }));
            }
            else {
                orphanedOrRemovedDimFound = true;
            }
        });
    });
    //
    // Find the VariationData entries that should be removed
    //
    let variationDataUpdates = {};
    if (orphanedOrRemovedDimFound) {
        let variations = buildVariationsEx(structure.stack, Immutable.Map(), dimensions, false);
        variationDataUpdates = variationDataMap.reduce((variationDataUpdates, value, key) => {
            return variations.has(key) ? Object.assign(variationDataUpdates, { [key]: null }) : variationDataUpdates;
        }, variationDataUpdates);
    }
    let representativePoints = getPointsForKey(structure.stack, valueSetProviders, structure.representativeKey);
    let reducedPoints = representativePoints && removeOrphanedAndRemovedDimensions(representativePoints, dimensions);
    let representativeKey = generateVerifiedKey(stack, valueSetProviders, reducedPoints);
    return {
        structureUpdate: { stack, representativeKey },
        variationDataUpdates
    };
};
//
// Returns true if the given valueSetProvider is used
//
const isValueSetProviderUsed = (structure, valueSetSource, valueSetId) => {
    return isValueSetProviderUsedEx(structure, (usedValueSetSource, usedValueSetId) => usedValueSetSource === valueSetSource && usedValueSetId === valueSetId);
};
const isValueSetProviderUsedEx = (structure, predicate, ...args) => {
    return structure.stack.some((layer) => predicate(layer.valueSetSource, layer.valueSetId, ...args));
};
//
// Handles changes to a valueSetProvider
//
// The possible changes are:
// 1. Updating a dimension name
// 2. Updating a dimension type
// 3. Updating a dimension value set to a union of the valueSetProvider's value set and the previous dimension's value set
//
const handleValueSetProviderChange = (structure, valueSetProviders) => {
    let stack = structure.stack.withMutations((mutableStack) => {
        valueSetProviders.forEach((valueSetProvider) => {
            structure.stack.forEach((layer, index) => {
                if (layer.valueSetSource === valueSetProvider.getSource() && layer.valueSetId === valueSetProvider.getId()) {
                    let name = valueSetProvider.getName();
                    let type = valueSetProvider.getType();
                    let valueSet = valueSetProvider.getValueSet();
                    layer.valueSet.forEach((value) => {
                        if (!valueSetIncludes(valueSet, value.id)) {
                            valueSet = valueSet.push(value);
                        }
                    });
                    mutableStack.set(index, new Layer({
                        valueSetSource: layer.valueSetSource,
                        valueSetId: layer.valueSetId,
                        name,
                        type,
                        valueSet,
                        merges: layer.merges
                    }));
                }
            });
        });
    });
    return {
        structureUpdate: { stack }
    };
};
//
// Generates a key for an expanded point
// Note that the result is NOT a variatinon, rather just something that can identify a specific point in a merged variation
//
const generateKeyForExpandedPoint = (variationKey, point) => {
    return variationKey + "^" + JSON.stringify(point.toJSON());
};
//
// Checks if the key represents a point as opposed to a variation
//
const isExpandedPoint = (key) => {
    return key.includes("^");
};
//
// Returns the point from such a key
//
const getExpandedPoint = (key) => {
    if (key.includes("^")) {
        let pieces = key.split("^");
        return Immutable.List(JSON.parse(pieces[1]));
    }
};
//
// Updates the structure per the needed updates
//
const updateStructure = (structure, structureUpdate) => {
    return new Structure({
        stack: structureUpdate.stack !== undefined ? structureUpdate.stack : structure.stack,
        representativeKey: structureUpdate.representativeKey !== undefined ? structureUpdate.representativeKey : structure.representativeKey,
        creativeOverride: structureUpdate.creativeOverride !== undefined ? structureUpdate.creativeOverride : structure.creativeOverride
    });
};
//
// Updates the variation data amp per the needed update (handles a single variation data object)
//
const updateVariationDataMap = (variationDataMap, variationKey, variationDataUpdate) => {
    let variationData = null;
    if (variationDataUpdate) {
        let { text, comment, muted, overrideId } = variationDataUpdate;
        let existingVariationData = variationDataMap.get(variationKey);
        variationData = new VariationData({
            text: text !== undefined ? text : existingVariationData ? existingVariationData.text : "",
            comment: comment !== undefined ? comment : existingVariationData ? existingVariationData.comment : "",
            muted: muted !== undefined ? muted : existingVariationData ? existingVariationData.muted : false,
            overrideId: overrideId !== undefined ? overrideId : existingVariationData ? existingVariationData.overrideId : ""
        });
    }
    return applyVariationDataToVariationDataMap(variationDataMap, variationKey, variationData);
};
//
// Applies a VariationData object to the variations
//
const applyVariationDataToVariations = (variations, variationKey, variationData) => {
    let variation = variations.get(variationKey);
    return variation
        ? variations.set(variationKey, new Variation({ points: variation.points, rp: variation.rp, orphaned: variation.orphaned, dataOrphaned: variation.dataOrphaned, variationData: variationData }))
        : variations;
};
//
// Applies a VariationData object to the variationDataMap (this includes removing it)
//
const applyVariationDataToVariationDataMap = (variationDataMap, variationKey, variationData) => {
    if (variationData) {
        return variationDataMap.set(variationKey, variationData);
    }
    else {
        return variationDataMap.delete(variationKey);
    }
};
const valueSetToIndexMap = (valueSet) => {
    return Immutable.Map().withMutations((mutableIndexMap) => {
        let index = 0;
        valueSet.forEach((value) => {
            mutableIndexMap.set(value.id, index++);
        });
    });
};
////////////////////////
//                    //
// DB MAPPINGS        //
//                    //
////////////////////////
const dbExtractDimensionDataFromStructure = (structure) => {
    return structure.stack.toArray().map((layer) => {
        return {
            valueSetSource: layer.valueSetSource,
            valueSetId: layer.valueSetId,
            name: layer.name,
            type: layer.type,
            valueSet: layer.valueSet.toArray()
        };
    });
};
const dbExtractMergeDataFromStructure = (structure) => {
    let indexMaps = [];
    return structure.stack.toArray().map((layer) => {
        indexMaps.push(valueSetToIndexMap(layer.valueSet));
        return {
            merges: layer.merges.toArray().map((points) => {
                return points.toArray().map((point) => {
                    return pointToNumber(point, indexMaps);
                });
            })
        };
    });
};
const dbExtractRepresentativeKeyFromStructure = (structure) => {
    return structure.representativeKey;
};
const dbExtractCreativeOverrideFromStructure = (structure) => {
    return structure.creativeOverride;
};
const dbBuildStructureFromDBData = (dbDimensionData, dbMergeData, dbRepresentativeKey, dbCreativeOverride) => {
    let valueSets = [];
    let stack = Immutable.List().withMutations((mutableStack) => {
        dbDimensionData.forEach((dbDimensionLayer, index) => {
            valueSets.push(Immutable.List(dbDimensionLayer.valueSet));
            let dbMergeLayer = dbMergeData[index];
            //Backward Compatibility
            let valueSetSource = dbDimensionLayer.dataElementId ? "DataElements" : dbDimensionLayer.valueSetSource;
            let valueSetId = dbDimensionLayer.dataElementId ? dbDimensionLayer.dataElementId : dbDimensionLayer.valueSetId;
            let type = dbDimensionLayer.type ? dbDimensionLayer.type : DataElementContentTypes.String;
            let layer = new Layer({
                valueSetSource,
                valueSetId,
                name: dbDimensionLayer.name,
                type,
                valueSet: Immutable.List(dbDimensionLayer.valueSet),
                merges: Immutable.OrderedSet(dbMergeLayer.merges.map((dbPoints) => Immutable.OrderedSet(dbPoints.map((dbPoint) => {
                    //Backward Compatibility
                    if (Array.isArray(dbPoint)) {
                        return Immutable.List(dbPoint);
                    }
                    return numberToPoint(dbPoint, valueSets);
                }))))
            });
            mutableStack.push(layer);
        });
    });
    return new Structure({ stack, representativeKey: dbRepresentativeKey, creativeOverride: dbCreativeOverride });
};
const dbExtractVariationDataUpdates = (variationDataUpdates) => {
    let result = {};
    Object.keys(variationDataUpdates).forEach((key) => {
        let variationDataUpdate = variationDataUpdates[key];
        if (variationDataUpdate) {
            let update = {};
            let textUpdate = variationDataUpdate.text;
            if (textUpdate !== undefined) {
                update.text = textUpdate ? textUpdate : null;
            }
            let commentUpdate = variationDataUpdate.comment;
            if (commentUpdate !== undefined) {
                update.comment = commentUpdate ? commentUpdate : null;
            }
            let mutedUpdate = variationDataUpdate.muted;
            if (mutedUpdate !== undefined) {
                update.muted = !!mutedUpdate;
            }
            let overrideIdUpdate = variationDataUpdate.overrideId;
            if (overrideIdUpdate !== undefined) {
                update.overrideId = overrideIdUpdate ? overrideIdUpdate : null;
            }
            result[key] = update;
        }
        else {
            result[key] = null;
        }
    });
    return result;
};
const dbBuildVariationDataMapFromVariationData = (dbVariationData) => {
    return Immutable.Map().withMutations((mutableMap) => {
        Object.keys(dbVariationData).forEach((variationKey) => {
            let { text, comment, muted, overrideId } = dbVariationData[variationKey];
            mutableMap.set(variationKey, new VariationData({
                text: text ? text : "",
                comment: comment ? comment : "",
                muted: muted ? muted : false,
                overrideId: overrideId ? overrideId : ""
            }));
        });
    });
};
const pointToNumber = (point, indexMaps) => {
    return indexMaps.reduce((acc, cur, idx) => {
        acc *= indexMaps[idx].size;
        acc += indexMaps[idx].get(point.get(idx));
        return acc;
    }, 0);
};
const numberToPoint = (number, valueSets) => {
    return Immutable.List(valueSets.reduce((acc, cur, idx) => {
        let revIdx = valueSets.length - 1 - idx;
        let index = number % valueSets[revIdx].size;
        acc[revIdx] = valueSets[revIdx].get(index).id;
        number /= valueSets[revIdx].size;
        return acc;
    }, []));
};
////////////////////////
//                    //
// SERIALIZATION      //
//                    //
////////////////////////
const serializeStructure = (structure) => {
    return {
        stack: structure.stack.toArray().map((layer) => {
            return {
                valueSetSource: layer.valueSetSource,
                valueSetId: layer.valueSetId,
                name: layer.name,
                type: layer.type,
                valueSet: layer.valueSet.toArray(),
                merges: layer.merges.toArray().map((points) => {
                    return points.toArray().map((point) => {
                        return point.toArray();
                    });
                })
            };
        }),
        representativeKey: structure.representativeKey,
        creativeOverride: structure.creativeOverride
    };
};
const unserializeStructure = (jsStructure) => {
    let stack = Immutable.List().withMutations((mutableStack) => {
        jsStructure.stack.forEach((jsLayer) => {
            //Backward Compatibility
            let valueSetSource = jsLayer.dataElementId ? "DataElements" : jsLayer.valueSetSource;
            let valueSetId = jsLayer.dataElementId || jsLayer.valueSetId;
            let type = jsLayer.type || DataElementContentTypes.String;
            let layer = new Layer({
                valueSetSource,
                valueSetId,
                name: jsLayer.name,
                type,
                valueSet: Immutable.List(updgradeValueSet(jsLayer.valueSet)),
                merges: Immutable.OrderedSet(jsLayer.merges.map((jsPoints) => Immutable.OrderedSet(jsPoints.map((jsPoint) => Immutable.List(jsPoint)))))
            });
            mutableStack.push(layer);
        });
    });
    let representativeKey = jsStructure.representativeKey;
    let creativeOverride = jsStructure.creativeOverride;
    return new Structure({ stack, representativeKey, creativeOverride });
};
const updgradeValueSet = (valueSet) => {
    return valueSet.map((value) => {
        if (typeof value === "string") {
            if (value === DATA_ELEMENT_VALUE_OTHER_OR_MISSING) {
                return { id: "_vs_other_or_missing", dn: DATA_ELEMENT_VALUE_OTHER_OR_MISSING };
            }
            else {
                return { id: "_bc_" + value.toLowerCase(), dn: value };
            }
        }
        return value;
    });
};
const serializeVariationDataMap = (variationDataMap) => {
    let result = {};
    variationDataMap.forEach((variationData, variationKey) => {
        result[variationKey] = {
            text: variationData.text,
            comment: variationData.comment,
            muted: variationData.muted,
            overrideId: variationData.overrideId
        };
    });
    return result;
};
const unserializeVariationDataMap = (jsVariationDataMap) => {
    return Immutable.Map().withMutations((mutableMap) => {
        Object.keys(jsVariationDataMap).forEach((variationKey) => {
            let { text, comment, muted, overrideId } = jsVariationDataMap[variationKey];
            mutableMap.set(variationKey, new VariationData({ text, comment, muted, overrideId }));
        });
    });
};
////////////////////////
//                    //
// INTERNAL FUNCTIONS //
//                    //
////////////////////////
//
// Returns points for the root variation (a set of a single point without dimensions)
//
const emptyPointSet = () => {
    return Immutable.OrderedSet.of(Immutable.List());
};
//
// Generates a key for points
// IMPORTANT: Data is stored in the DB with this key. Any changes made to this algorithm will cause all such data to become unreachable!
//
const generateKey = (points) => {
    return md5(pointsToCompactString(points));
};
//
// Returns a string represenation of points
// The standard toString is not used for two reasons:
// - It uses ambiguous delimiters
// - It might change in the future
//
const pointsToCompactString = (points) => {
    let result = "";
    points.forEach((point) => {
        point.forEach((valueId) => {
            result += valueId + String.fromCharCode(1);
        });
        result += String.fromCharCode(2);
    });
    return result;
};
//
// Returns the index of a valueSetProvider in the dimensions
//
const findDimensionIndex = (stack, valueSetProvider) => {
    return stack.findIndex((layer) => layer.valueSetSource === valueSetProvider.getSource() && layer.valueSetId === valueSetProvider.getId());
};
//
// Experiment...
//
const getPointsForKey = (stack, valueSetProviders, key) => {
    let dimensions = buildDimensionsFromStack(stack, valueSetProviders);
    let variations = buildVariationsEx(stack, Immutable.Map(), dimensions, false);
    let variation = variations.get(key);
    if (variation) {
        return variation.points;
    }
};
const generateVerifiedKey = (stack, valueSetProviders, points) => {
    let dimensions = buildDimensionsFromStack(stack, valueSetProviders);
    let variations = buildVariationsEx(stack, Immutable.Map(), dimensions, false);
    if (points) {
        let key = generateKey(points);
        let variation = variations.get(key);
        if (variation) {
            return key;
        }
    }
    return generateKey(variations.sort(variationComparator(dimensions)).first().points);
};
//
// Builds the dimensions from the stack
//
const buildDimensionsFromStack = (stack, valueSetProviders) => {
    return Immutable.List().withMutations((mutableDimensions) => {
        stack.forEach((layer) => {
            let valueSetProvider = valueSetProviders.find((valueSetProvider) => valueSetProvider.getSource() === layer.valueSetSource && valueSetProvider.getId() === layer.valueSetId);
            let id = generateDimensionId(layer.valueSetSource, layer.valueSetId);
            let name = layer.name;
            let type = layer.type;
            let valueSet = layer.valueSet;
            let indexMap = valueSetToIndexMap(valueSet);
            // EXPERIMENT
            let orphaned = !valueSetProvider || valueSetProvider.getValueSet().size === 0;
            let removed = false;
            let hasOrphanedValues = !orphaned && valueSet.size > valueSetProvider.getValueSet().size;
            mutableDimensions.push(new Dimension({ id, name, type, valueSet, indexMap, orphaned, removed, hasOrphanedValues }));
        });
    });
};
//
// Builds the variations from the stack
//
const buildVariationsFromStack = (stack, variationDataMap, dimensions, valueSetProviders) => {
    let variations = buildVariationsEx(stack, variationDataMap, dimensions, false);
    //Detect orphaned variations due to orphaned/removed dimensions
    if (dimensions.some((dimension) => dimension.orphaned || dimension.removed)) {
        let reducedVariations = buildVariationsEx(stack, variationDataMap, dimensions, true);
        let sortedVariations = variations.sort(variationComparator(dimensions));
        let usedKeys = Immutable.Set();
        let emptyPointSetKey = generateKey(emptyPointSet());
        variations = variations.withMutations((mutableVariations) => {
            sortedVariations.forEach((variation, variationKey) => {
                let reducedPoints = removeOrphanedAndRemovedDimensions(variation.points, dimensions);
                let key = generateKey(reducedPoints);
                if (key !== emptyPointSetKey && reducedVariations.has(key) && !usedKeys.has(key)) {
                    usedKeys = usedKeys.add(key);
                    mutableVariations.set(variationKey, new Variation({ points: variation.points, rp: variation.rp, orphaned: false, dataOrphaned: true, variationData: variation.variationData }));
                }
                else {
                    mutableVariations.set(variationKey, new Variation({ points: variation.points, rp: variation.rp, orphaned: true, dataOrphaned: true, variationData: variation.variationData }));
                }
            });
        });
    }
    //Detect orphaned variations due to removed values
    if (dimensions.some((dimension) => dimension.hasOrphanedValues)) {
        variations = variations.withMutations((mutableVariations) => {
            variations.forEach((variation, variationKey) => {
                if (!variation.orphaned) {
                    if (variation.points.some((point) => point.some((valueId, index) => {
                        let dimension = dimensions.get(index);
                        if (dimension.orphaned || dimension.removed) {
                            return false;
                        }
                        let valueSetProvider = valueSetProviders.find((valueSetProvider) => valueSetProvider.getSource() === stack.get(index).valueSetSource && valueSetProvider.getId() === stack.get(index).valueSetId);
                        let valueSet = valueSetProvider.getValueSet();
                        return !valueSetIncludes(valueSet, valueId);
                    }))) {
                        //TODO: Consider making this conditional
                        //      variationData has text (and possibly is not text of a parent)
                        mutableVariations.set(variationKey, new Variation({
                            points: variation.points,
                            rp: variation.rp,
                            orphaned: true,
                            dataOrphaned: true,
                            variationData: variation.variationData
                        }));
                    }
                }
            });
        });
    }
    return variations.sort(variationComparator(dimensions));
};
//
// Builds the variations with or without orphaned/removed dimensions
// Normally this will called twice once with and once without
// The results will be compared to determine which variations are orphans
//
const buildVariationsEx = (stack, variationDataMap, dimensions, withoutOrphanedAndRemoved) => {
    let masterSet = Immutable.OrderedSet.of(emptyPointSet());
    let orphanedOrRemovedDimFound = false;
    let indexToMerge = -1;
    stack.forEach((layer, index) => {
        let dimension = dimensions.get(index);
        if (withoutOrphanedAndRemoved && (dimension.orphaned || dimension.removed)) {
            orphanedOrRemovedDimFound = true;
        }
        else {
            masterSet = join(masterSet, dimension.valueSet);
            if (!orphanedOrRemovedDimFound) {
                indexToMerge = index;
            }
        }
    });
    if (indexToMerge >= 0) {
        let layer = stack.get(indexToMerge);
        masterSet = applyMerges(masterSet, layer.merges);
    }
    return Immutable.Map().withMutations((mutableVariations) => {
        masterSet.forEach((points) => {
            let key = generateKey(points);
            let rp = extractRepresentativePoint(dimensions, points);
            let variationData = findVariationDataForPoints(dimensions, points, variationDataMap);
            mutableVariations.set(key, new Variation({ points, rp, orphaned: false, dataOrphaned: false, variationData: variationData || new VariationData() }));
        });
    });
};
//
// Returns an updated points to reflect orphaned and removed dimensions
//
const removeOrphanedAndRemovedDimensions = (points, dimensions) => {
    return Immutable.OrderedSet().withMutations((mutablePoints) => {
        points.forEach((point) => {
            let reducedPoint = Immutable.List().withMutations((mutableReducedPoint) => {
                dimensions.forEach((dimension, index) => {
                    if (!dimension.orphaned && !dimension.removed) {
                        mutableReducedPoint.push(point.get(index));
                    }
                });
            });
            mutablePoints.add(reducedPoint);
        });
    });
};
function generateDimensionId(source, id) {
    return `${source}__${id}`;
}
const findVariationDataForPoints = (dimensions, points, variationDataMap) => {
    let key = findBestKeyForPoints(dimensions, points, (key) => variationDataMap.has(key));
    return variationDataMap.get(key);
};
const findBestKeyForPoints = (dimensions, points, predicate) => {
    let bestKey;
    //Try exact match
    let key = generateKey(points);
    if (predicate(key)) {
        bestKey = key;
    }
    //Try removing dimensions one by one
    if (!bestKey && points.size > 0) {
        for (let dim = 0; dim < dimensions.size && !bestKey; ++dim) {
            let reducedPoints = points.map((point) => point.skipLast(dim + 1));
            key = generateKey(reducedPoints);
            if (predicate(key)) {
                bestKey = key;
            }
        }
    }
    return bestKey;
};
//
// Returns true if a value exists in a value set
//
const valueSetIncludes = (valueSet, valueId) => {
    return valueSet.some((valueSetValue) => valueSetValue.id === valueId);
};
//
// Updates a set of points per given value sets
// This effectively removes points that are empty following removal of any point that contained a value that no longer exists
//
const updateSetOfPointsPerValueSet = (setOfPoints, valueSets) => {
    return Immutable.OrderedSet().withMutations((mutableSet) => {
        setOfPoints.forEach((points) => {
            let updatedPoints = updatePointsPerValueSet(points, valueSets);
            if (updatedPoints.size > 0) {
                mutableSet.add(updatedPoints);
            }
        });
    });
};
//
// Updates points per given value sets
// This effectively removes any point that contains a value that no longer exists
// As a result, the returned set might be empty
//
const updatePointsPerValueSet = (points, valueSets) => {
    return Immutable.OrderedSet().withMutations((mutablePoints) => {
        points.forEach((point) => {
            if (point.every((valueId, index) => valueSetIncludes(valueSets.get(index), valueId))) {
                mutablePoints.add(point);
            }
        });
    });
};
const join = (masterSet, valueSet) => {
    return Immutable.OrderedSet().withMutations((mutableSet) => {
        masterSet.forEach((points) => {
            valueSet.forEach((value) => {
                let expandedPoints = Immutable.OrderedSet().withMutations((mutablePoints) => {
                    points.forEach((point) => {
                        mutablePoints.add(point.concat(value.id));
                    });
                });
                mutableSet.add(expandedPoints);
            });
        });
    });
};
const applyMerges = (masterSet, merges) => {
    let mergedMasterSet = null;
    Immutable.Map().withMutations((mutableExistingPointMap) => {
        masterSet.forEach((masterPoints) => {
            updatePointMap(mutableExistingPointMap, masterPoints);
        });
        mergedMasterSet = masterSet.withMutations((mutableSet) => {
            merges.forEach((points) => {
                //Alternatively, normalize points here and then revert to original code
                points.forEach((point) => {
                    let existingPoints = mutableExistingPointMap.get(point);
                    if (existingPoints) {
                        mutableSet.delete(existingPoints);
                        mutableExistingPointMap.delete(point);
                        if (existingPoints.size > 1) {
                            existingPoints = existingPoints.delete(point);
                            mutableSet.add(existingPoints);
                            updatePointMap(mutableExistingPointMap, existingPoints);
                        }
                    }
                });
                mutableSet.add(points);
                updatePointMap(mutableExistingPointMap, points);
            });
        });
    });
    return mergedMasterSet;
};
const updatePointMap = (mutableMap, points) => {
    points.forEach((point) => {
        mutableMap.set(point, points);
    });
};
const extractRepresentativePoint = (dimensions, points) => {
    //return points.min(pointComparator(dimensions));
    return points.first();
};
const variationComparator = (dimensions) => {
    let comparator = pointComparator(dimensions);
    return (entryA, entryB) => {
        return comparator(entryA.rp, entryB.rp);
    };
};
const pointComparator = (dimensions) => {
    return (pointA, pointB) => {
        let result = 0;
        dimensions.forEach((dimension, index) => {
            let valueA = pointA.get(index);
            let valueB = pointB.get(index);
            let ordinalA = dimension.indexMap.get(valueA);
            let ordinalB = dimension.indexMap.get(valueB);
            result = ordinalA - ordinalB;
            if (result !== 0) {
                return false;
            }
        });
        return result;
    };
};
const NarrationsModelUtils = {
    defaultStructure,
    defaultVariationDataMap,
    buildDimensions,
    buildVariations,
    setRepresentativeKey,
    setText,
    setComment,
    mute,
    unmute,
    setOverrideId,
    addDimension,
    removeDimension,
    setSupportCreativeOverride,
    merge,
    unmerge,
    acceptOrphansAndRemoved,
    isValueSetProviderUsed,
    isValueSetProviderUsedEx,
    handleValueSetProviderChange,
    generateKey,
    generateKeyForExpandedPoint,
    isExpandedPoint,
    getExpandedPoint,
    updateStructure,
    updateVariationDataMap,
    applyVariationDataToVariations,
    applyVariationDataToVariationDataMap,
    valueSetToIndexMap,
    dbExtractDimensionDataFromStructure,
    dbExtractMergeDataFromStructure,
    dbExtractRepresentativeKeyFromStructure,
    dbExtractCreativeOverrideFromStructure,
    dbBuildStructureFromDBData,
    dbExtractVariationDataUpdates,
    dbBuildVariationDataMapFromVariationData,
    pointToNumber,
    numberToPoint,
    serializeStructure,
    unserializeStructure,
    serializeVariationDataMap,
    unserializeVariationDataMap,
    generateDimensionId
};
export default NarrationsModelUtils;
