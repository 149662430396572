import type { ComponentType } from "react";
import type { BaseMutationOptions } from "@apollo/client/react";
import type { CardData } from "../../components/framework/EntityCard";
import type { ApolloClient } from "@apollo/client";
import type {
    GqlClientAudience,
    GqlClientChannel,
    GqlClientFeedDataElement,
    GqlClientGoal,
    GqlClientProgramVersion,
    GqlClientScene,
    GqlClientStory,
    GqlClientTouchpoint
} from "../../graphql/graphqlGeneratedTypes/graphqlClient";

export type IFrameworkEntity<TProps, CreateMutation = any, CreateMutationArgs = any, UpdateMutation = any, UpdateMutationArgs = any, DeleteMutation = any, DeleteMutationArgs = any> = {
    queries?: {};
    mutations: {
        create: (WrappedComponent: ComponentType<TProps>) => ComponentType<TProps>;
        update: (WrappedComponent: ComponentType<TProps>) => ComponentType<TProps>;
        delete: (WrappedComponent: ComponentType<TProps>) => ComponentType<TProps>;
    };
    onSave: (args: onSaveArgs | ProgramVersionOnSaveArgs) => void;
    getSupporters?: (entity: any, client: ApolloClient<any>) => string[];
    getCardData?: (entity: any, roles: Roles, index?: number) => CardData;
};

export interface onSaveArgs<TData = any, TCreateCBVariables = any, TUpdateCBVariables = any> {
    data: TData;
    createCB: (opts: BaseMutationOptions<TData, TCreateCBVariables>) => void;
    updateCB: (opts: BaseMutationOptions<TData, TUpdateCBVariables>) => void;
    updateDisplaySceneOrderCB?: (opts: BaseMutationOptions<TData, TUpdateCBVariables>) => void;
    programVersionId: string;
    dryRun?: boolean;
}

export interface ProgramVersionOnSaveArgs<TData = any, TCreateCBVariables = any, TUpdateCBVariables = any> extends Omit<onSaveArgs, "programVersionId"> {
    programId: string;
}

export enum EntityTypeNames {
    AUDIENCE = "Audience",
    AUDIENCE_VALUE = "AudienceValue",
    CHANNEL = "Channel",
    DATA_ELEMENT = "DataElement",
    GOAL = "Goal",
    KPI = "KPI",
    DRAFT = "Draft",
    SCENE = "Scene",
    STORY = "Story",
    TOUCHPOINT = "Touchpoint",
    REACH = "Reach"
}
export type shownColumnsButtonItem = {
    id?: EntityTypeNames;
    value?: string;
    content: string;
    onClick?: (any) => void;
    checked?: boolean;
    indeterminate?: boolean;
};
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
// ToDo: rename to ProgramVersion
export interface Program extends Omit<Partial<GqlClientProgramVersion>, "stories" | "scenes" | "goals" | "audiences" | "channels" | "touchpoints" | "feedDataElements"> {
    stories: { [id: string]: GqlClientStory };
    scenes: { [id: string]: GqlClientScene };
    goals: { [id: string]: GqlClientGoal };
    audiences: { [id: string]: GqlClientAudience };
    channels: { [id: string]: GqlClientChannel };
    touchpoints: { [id: string]: GqlClientTouchpoint };
    dataElements: { [id: string]: GqlClientFeedDataElement };
}

export type ProgramEntities = {
    stories: { [id: string]: GqlClientStory };
    scenes: { [id: string]: GqlClientScene };
    goals: { [id: string]: GqlClientGoal };
    audiences: { [id: string]: GqlClientAudience };
    channels: { [id: string]: GqlClientChannel };
    touchpoints: { [id: string]: GqlClientTouchpoint };
    dataElements: { [id: string]: GqlClientFeedDataElement };
};

export enum Role { // used as classNames in EntityCard
    Lead = "leading",
    Support = "supporting",
    Extra = "extra"
}

export type EntityIdentifier = {
    id: string;
    type: EntityTypeNames;
};

export type Roles = {
    leadId: string;
    supporterIds: string[];
};

export type EntityOrder = string[];

export type DropdownItem = {
    label: string;
    value: string;
};

export type SelectItem = {
    key: string;
    value: string;
    text: string;
};

// endregion

export type HOC = (WrappedComponent: ComponentType) => ComponentType;
