import { createAction } from "redux-actions";
import { legacyLoginPath } from "@sundaysky/smartvideo-hub-urls";
import { reportError } from "../common/commonActions";
import { getAccountIdFromPath } from "../common/routingUtils";
import url from "../common/urlUtils";
import createHistory from "history/createBrowserHistory";
import type { UserData } from "./UserServices";

export const LOADING_USER_DATA_SUCCESS = "LOADING_USER_DATA_SUCCESS";
export const loadingUserDataSuccess = createAction(LOADING_USER_DATA_SUCCESS, function(userData) {
    return userData;
});

export const LOADING_ACTIVE_ACCOUNT_SUCCESS = "LOADING_ACTIVE_ACCOUNT_SUCCESS";
export const loadingActiveAccountSuccess = createAction(LOADING_ACTIVE_ACCOUNT_SUCCESS, function(selectedAccount) {
    return selectedAccount;
});

export const SET_ACTIVE_ACCOUNT_ID = "SET_ACTIVE_ACCOUNT_ID";
export const setActiveAccountId = createAction(SET_ACTIVE_ACCOUNT_ID, (accountId: string) => accountId);


export const loadUserData = (
    cb?: (data: UserData) => void,
    cbError?: (err: unknown) => void
) => async (dispatch, state, services) => {
    try {
        const userData = await services.userServices.getUserData();

        dispatch(loadingUserDataSuccess(userData));
        if (typeof cb === "function") {
            cb(userData);
        }
    }
    catch (err) {
        if (typeof cbError === "function") {
            cbError(err);
        }
        else {
            dispatch(reportError(err));
        }
    }
};



export const loadCurrentAccount = function() {
    return function(dispatch, state, services) {
        const currentAccountIdFromPath = getAccountIdFromPath();
        const currentAccount = currentAccountIdFromPath
            ? state().accounts.byId[currentAccountIdFromPath]
            : undefined;
        if (currentAccount) {
            let data = Object.assign({}, currentAccount);
            dispatch(loadingActiveAccountSuccess(data));
        }
        else {
            services.userServices
                .getCurrentAccount()
                .then(function(data) {
                    dispatch(loadingActiveAccountSuccess(data));
                })
                .catch(function(err) {
                    dispatch(reportError(err));
                });
        }
    };
};

export const switchAccount = function(accountId, newAccount, newLocation, browserHistory) {
    return function(dispatch, state, services) {
        services.userServices
            .switchAccount(newAccount)
            .then(function() {
                if (!newLocation) {
                    newLocation = window.location.pathname.includes(`/${accountId}/`)
                        ? window.location.pathname.replace(accountId, newAccount.accountId)
                        : url.append(window.location.pathname, newAccount.accountId);
                }
                browserHistory.push(newLocation);
            })
            .catch(function(err) {
                dispatch(reportError(err));
            });
    };
};

export const logout = function() {
    return function(dispatch, state, services) {
        services.userServices.logout()
            .finally(() => {
                //using new history object (not react-router-dom) we want to avoid components to render before the reload page.
                const history = createHistory();
                history.push(legacyLoginPath());
                location.reload();
            });
    };
};
