import type { AuthServiceInterface } from "@sundaysky/smartvideo-hub-auth";
import { getPromptToVideoChatEndpoint } from "../../../common/types/restApiTypes";
import type { ChatEventHandler } from "@sundaysky/chatbot-client";
import { ChatbotClient } from "@sundaysky/chatbot-client";
import type { PromptToVideoInput } from "@sundaysky/ai-common-types";
import { addTokenHeadersAndFullStory } from "./BaseServices";
import type { RawAxiosRequestHeaders } from "axios";


export class PromptToVideoChatServices {
    readonly #authService: AuthServiceInterface;

    constructor(authService) {
        this.#authService = authService;
    }

    public async promptToVideoChat(accountId: string, promptToVideoInput: PromptToVideoInput, eventHandler: ChatEventHandler): Promise<void> {
        const endpoint: string = getPromptToVideoChatEndpoint(accountId);

        const token = await this.#authService.getUserAccessToken();
        const headers: RawAxiosRequestHeaders = addTokenHeadersAndFullStory({}, token);
        const client = new ChatbotClient({ endpoint, eventHandler });
        await client.prompt(promptToVideoInput, headers as Record<string, string>);
    }
}

// Singleton
let promptToVideoChatServicesInstance: PromptToVideoChatServices;
export const initPromptToVideoChatServices = (authService: AuthServiceInterface): void => {
    promptToVideoChatServicesInstance = new PromptToVideoChatServices(authService);
};
export const getPromptToVideoChatServices = (): PromptToVideoChatServices => {
    return promptToVideoChatServicesInstance;
};
