import type { AnyEntitiesByContextById, AnyEntityContextPair } from "./baseEntity";
import { BaseEntity } from "./baseEntity";
import StateReaderUtils from "../common/StateReaderUtils";
import type { DataElement } from "../../../common/types/dataElement";
import { DataElementOrigins, FeedDataElement } from "../../../common/types/dataElement";
import type { Collector, IEntity, EntityInstance, CollectorArgs } from "./definitions";
import { EntityTypes } from "./definitions";
import type { ValidationResult } from "../validations/validationManager";
import { IssueCodes, IssueSeverity } from "../validations/validationManager";
import DataElementsTabContentController from "../../components/HubEditor/rightPanels/dataLibrary/DataElementsTabContentController";
import type { DiffResult } from "../versionDiff/diffManager";
import { ChangeType } from "../versionDiff/diffManager";
import { diffEntities } from "../versionDiff/diffUtils";

export default class DataElementEntity extends BaseEntity implements IEntity {
    constructor() {
        super(EntityTypes.DATA_ELEMENT);
    }

    collector: Collector = (args: CollectorArgs): EntityInstance[] => {
        const { context, previousContext } = args;
        const previousDataElements =
            previousContext &&
            StateReaderUtils.getProjectDataElements(previousContext.state, previousContext.projectName, previousContext.stage, previousContext.version).filter(
                (dataElement) => dataElement.origin === DataElementOrigins.Feed || dataElement.origin === DataElementOrigins.Creative
            );
        const currentDataElements = StateReaderUtils.getProjectDataElements(context.state, context.projectName, context.stage, context.version).filter(
            (dataElement) => dataElement.origin === DataElementOrigins.Feed || dataElement.origin === DataElementOrigins.Creative
        );

        const dataElementsMap: AnyEntitiesByContextById = this.entityListsToMap(previousDataElements, currentDataElements, (de) => de.id);

        let dataElementInstances: EntityInstance[] = [];
        dataElementsMap.forEach((entityContextPair: AnyEntityContextPair, key: string) => {
            dataElementInstances.push({
                id: key,
                validate: (): ValidationResult => this.doValidate(entityContextPair.currentEntity),
                diff: (): DiffResult => this.doDiff(entityContextPair.previousEntity, entityContextPair.currentEntity)
            });
        });
        return dataElementInstances;
    };

    doValidate = (dataElement: any): ValidationResult => {
        let valid: boolean = true;
        if (dataElement.useValueSet && dataElement.valueSet && dataElement.valueSet.length > 0) {
            valid = DataElementsTabContentController.validateValueSetForDataElement(dataElement);
        }
        const type: EntityTypes = this.getType();
        const id: string = dataElement.id;
        return valid
            ? { type, id, issues: [] }
            : {
                type,
                id,
                issues: [
                    {
                        code: IssueCodes.DATA_ELEMENT_VALUE_SET,
                        severity: IssueSeverity.ERROR,
                        details: {}
                    }
                ],
                severity: IssueSeverity.ERROR
            };
    };

    doDiff = (previousDataElement: DataElement, currentDataElement: DataElement): DiffResult => {
        const changeType: ChangeType = diffEntities(DataElementEntity.normalizeDataElement(previousDataElement), DataElementEntity.normalizeDataElement(currentDataElement));
        const origin: string = this.getPropsFromPreviousOrCurrent(previousDataElement, currentDataElement, "origin");
        const type: EntityTypes = origin === DataElementOrigins.Creative ? EntityTypes.CREATIVE_DATA_ELEMENT : EntityTypes.DATA_ELEMENT;
        return {
            type: type,
            id: this.getPropsFromPreviousOrCurrent(previousDataElement, currentDataElement, "id"),
            name: this.getPropsFromPreviousOrCurrent(previousDataElement, currentDataElement, "displayName"),
            changeType: changeType,
            changes: [],
            isShown: changeType !== ChangeType.None
        };
    };

    static normalizeDataElement(dataElement: DataElement): any {
        if (!dataElement) {
            return dataElement;
        }

        const normalizedDataElement: any = { ...dataElement };
        if (normalizedDataElement.getValueSet) {
            normalizedDataElement.valueSet = normalizedDataElement.getValueSet();
            delete normalizedDataElement.getValueSet;
        }
        if (normalizedDataElement.origin === DataElementOrigins.Derived || normalizedDataElement.origin === DataElementOrigins.Creative) {
            delete normalizedDataElement.status;
        }
        if (normalizedDataElement.origin === DataElementOrigins.Derived) {
            delete normalizedDataElement.useValueSet;
        }
        if (!normalizedDataElement.assignedStories) {
            normalizedDataElement.assignedStories = [];
        }
        if (normalizedDataElement.origin === DataElementOrigins.Creative && !normalizedDataElement.assignedToAllStories) {
            normalizedDataElement.assignedToAllStories = false;
        }
        delete normalizedDataElement._objectType; // deep-diff shows symbols as change. off they go!
        delete normalizedDataElement.graphQLId;
        delete normalizedDataElement.graphQLUpdated;

        return normalizedDataElement;
    }
}
