import createStoryLogic from "./story";
import type { Story } from "../../../common/types/story";
import type { Asset } from "../../../common/types/asset";
import type { Program } from "../../../common/types/program";
import type { VLXLineupScene } from "../../../common/types/vlxTypes";

function createStoryInlineLogic(
    wireframes,
    assets: Asset[],
    currentStory: Story,
    analytics,
    program: Program,
    accountName: string,
    scenes: VLXLineupScene[],
    ignoreNextSceneLogic: boolean,
    narrationsVersion: number,
    storiesVersion: number,
    creativeVersion: number,
    enabledFeatureFlags: string[]
) {
    let storyInline = createStoryLogic(
        wireframes,
        null,
        program,
        accountName,
        ignoreNextSceneLogic,
        currentStory,
        analytics,
        assets,
        narrationsVersion,
        storiesVersion,
        creativeVersion,
        enabledFeatureFlags
    );
    storyInline.lineup = storyInline.lineup.map((sceneInLineup) => {
        return { name: sceneInLineup.name, scene: scenes.find((scene) => scene.name === sceneInLineup.name) };
    });
    return storyInline;
}
export default createStoryInlineLogic;
