import Moment from "moment";
import type { PropsWithChildren, ReactElement } from "react";
import React, { useEffect, useRef } from "react";
import { PendoService } from "../components/pendoService";
import { pagePathToName } from "./newNavUtils";

export type TimedActivityProps = {
    [k: string]: string | number | boolean;
};

export type TimedActivityOptions = {
    props?: TimedActivityProps;
}

export function reportTimedActivity(name: string, startTime: number, endTime: number, options: TimedActivityOptions = {}): void {
    const activityProps = options.props || {};

    const eventName = "Timed Activity";
    const eventData = {
        name,
        startTime: startTime.toString(),
        endTime: endTime.toString(),
        duration: (endTime - startTime).toString(),
        ...Object.entries(activityProps).reduce((acc, [k, v]) => {
            return { ...acc, [`activity_${k}`]: v.toString() };
        }, {})
    };

    const pendo = PendoService.getInstance();
    pendo.trackEvent(eventName, eventData);
    //console.log(eventName, eventData);
}

export function startActivity(name: string) {
    const startTime = Date.now();

    return (options: TimedActivityOptions = {}) => {
        reportTimedActivity(name, startTime, Date.now(), options);
    };
}

export function useTimedActivity(name: string, isRunning: boolean): void {
    const startTimeRef = useRef<number>(0);

    useEffect(() => {
        if (isRunning) {
            startTimeRef.current = Date.now();
        }
        else if (startTimeRef.current) {
            reportTimedActivity(name, startTimeRef.current, Date.now());
            startTimeRef.current = 0;
        }
    }, [ name, isRunning ]);
}

export type TimedComponentProps = {
    name: string;
};

export function TimedComponent(props: PropsWithChildren<TimedComponentProps>): ReactElement {
    const { name, children } = props;

    useEffect(() => {
        const endActivity = startActivity(name);

        return () => {
            endActivity();
        };
    }, [ name ]);

    return React.createElement(React.Fragment, null, children);
}

// This is used to prevent reporting the same page reloading multiple times (i.e. when filtering videos)
let lastReportedTime = 0;

export function reportPageContentLoad() {
    const changedAt = Date.now();
    const lastLocation = window["data-lastLocation"];
    const target = pagePathToName(lastLocation.currentPathname);
    if (!target) return; // This page is not tracked
    if (lastReportedTime === lastLocation.changedAt) return; // This page load was already reported
    PendoService.getInstance().trackEvent(
        "Page content loaded post navigation",
        {
            origin: lastLocation.lastPathname
                ? pagePathToName(lastLocation.lastPathname) ?? "Other Page"
                : "Direct Navigation",
            target,
            duration: (changedAt - lastLocation.changedAt).toString(),
            dateTime: Moment().toISOString(true),
            fullStoryURL: (window as any)?.FS?.getCurrentSessionURL?.(true)
        }
    );
    lastReportedTime = lastLocation.changedAt;
}
