import { reportInfoToSplunkRedux } from "../common/logReportUtils";
import { reportTimedActivity } from "../../utils/timedActivityReportingUtils";

type GP = {
    current: GlobalPerformance | null;
}

type GlobalPerformance = {
    taskName: string;
    startTime: number;
    endTime?: number;
    totalDuration?: number;
    // server + network =~ total actions time (calculated reducing overlapping times)
    clientWaitDuration?: number;
    // totalDuration - waitWorkDuration
    clientWorkDuration?: number;
    // metadata
    networkActionsKey?: string
    networkActions: RequestPerformance[];
}

type RequestPerformance = {
    kind: "REST" | "GraphQL";
    name: string;
    startTime: number;
    endTime?: number;
    payload?: number;
    // end - start
    totalDuration?: number;
    // if available
    serverDuration?: number;
    // totalDuration - serverDuration
    networkDuration?: number;
    // payload / networkDuration
    networkBandwidth?: number;
}

const globalPerformance: GP = {
    current: null
};

export const startGlobalPerformance = (taskName: string): void => {
    globalPerformance.current = {
        taskName,
        startTime: Date.now(),
        networkActions: []
    };
};

export const endGlobalPerformance = () => {
    const currentGlobalPerformance = globalPerformance.current;
    if (!currentGlobalPerformance) {
        return;
    }
    summarizeGlobalPerformance();
};

//Should we add task name as parameter and validate we measure the write task
//Should not be exported
const summarizeGlobalPerformance = (): void => {
    const currentGlobalPerformance = globalPerformance.current;
    currentGlobalPerformance.endTime = Date.now();
    currentGlobalPerformance.totalDuration = currentGlobalPerformance.endTime - currentGlobalPerformance.startTime;
    currentGlobalPerformance.clientWaitDuration = waitingTimeAccumulator(currentGlobalPerformance.startTime, currentGlobalPerformance.endTime, currentGlobalPerformance.networkActions);
    currentGlobalPerformance.clientWorkDuration = currentGlobalPerformance.totalDuration - currentGlobalPerformance.clientWaitDuration;
    currentGlobalPerformance.networkActionsKey = parseNetworkActionsKey(currentGlobalPerformance.networkActions);
    reportInfoToSplunkRedux ("Task duration", currentGlobalPerformance);
    globalPerformance.current = null;
};

type RequestPerformanceInitialInfo = Pick<RequestPerformance, "kind" | "name" | "startTime" | "endTime" | "serverDuration" | "payload">;

export const addNetworkActionPerformance = (rp: RequestPerformanceInitialInfo): void => {
    reportTimedActivity(`${rp.kind}: ${rp.name}`, rp.startTime, rp.endTime, { props: {
        serverDuration: rp.serverDuration.toString(),
        payload: rp.payload.toString()
    } });

    const currentGlobalPerformance = globalPerformance.current;
    if (!currentGlobalPerformance) {
        return;
    }
    const totalDuration = rp.endTime - rp.startTime;
    let networkDuration;
    let networkBandwidth;
    if (rp.serverDuration) {
        networkDuration = totalDuration - rp.serverDuration;
        if (rp.payload) {
            networkBandwidth = rp.payload / networkDuration;
        }
    }
    const requestPerformance: RequestPerformance = {
        kind: rp.kind,
        name: rp.name,
        startTime: rp.startTime,
        endTime: rp.endTime,
        payload: rp.payload,
        totalDuration,
        serverDuration: rp.serverDuration,
        networkDuration,
        networkBandwidth
    };
    currentGlobalPerformance.networkActions.push(requestPerformance);
};


type MinimalRequestPerformance = Pick<RequestPerformance, "startTime" | "endTime">

/**
 * Accumulates waiting timings from network actions
 *
 * @param startTime         global performance start-time
 * @param endTime           global performance end-time
 * @param networkActions    list of network actions
 */
export const waitingTimeAccumulator = (startTime: number, endTime: number, networkActions: MinimalRequestPerformance[]): number => {
    if (!networkActions || Number.isNaN(startTime) || Number.isNaN(endTime)) {
        return 0;
    }
    const sortedActions: MinimalRequestPerformance[] = networkActions.sort((action1, action2) => action2.startTime > action1.startTime ? -1 : 1);
    return sortedActions.reduce((acc: { duration: number; indexTime: number }, networkAction) => {
        const actionStartTime: number = Math.max(acc.indexTime, networkAction.startTime);
        const actionEndTime: number = Math.min(endTime, networkAction.endTime);
        const actionNonOverlappingDuration: number = Math.max(actionEndTime - actionStartTime, 0);

        acc.duration += actionNonOverlappingDuration;
        acc.indexTime = Math.max(acc.indexTime, actionEndTime);
        return acc;
    }, { duration: 0, indexTime: startTime }).duration;
};

const parseNetworkActionsKey = (networkActions: RequestPerformance[]): string => {
    return networkActions.map((na) => na.name).sort().toString();
};
