import {
    ERROR,
    CLEAR_ERROR,
    PUSH_HISTORY,
    SET_FORCE_LOAD,
    SET_PAGE_TITLE,
    SET_LOADING,
    SET_SOFT_LOADING,
    SET_LOADING_FOR_UPDATE_NARRATION_LIBRARY,
    SET_BREADCRUMBS_ARRAY,
    SET_REVERT_TO_VERSION,
    SET_HUB_STICKY_BAR_LOGO_MENU_ITEMS_ARRAY,
    ENABLE_HUB_STICKY_BAR_LOGO_MENU,
    TOGGLE_MIDDLE_PANEL_COLLAPSE,
    DATA_LIBRARY_SELECTED_TAB,
    REPORT_ESCAPE_CLICK,
    SET_DRAWER_FILTER
} from "./commonActions";

function commonReducer(state, action) {
    switch (action.type) {
        case ERROR: {
            const userErrorMessage = action.payload.userErrorMessage;
            return Object.assign({}, state, {
                error: true,
                errorMessage: userErrorMessage
            });
        }
        case CLEAR_ERROR:
            return Object.assign({}, state, {
                error: false,
                errorMessage: ""
            });
        case PUSH_HISTORY: {
            let link = action.payload.link;
            let title = action.payload.title;
            let byLink = state.browserHistory.byLink;
            let allLinks = state.browserHistory.allLinks;
            let activeId = state.browserHistory.activeId;
            let context = state.browserHistory.context;

            if (!context) {
                context = action.payload.context;
            }
            else if (action.payload.context && context !== action.payload.context) {
                allLinks = [allLinks[activeId]];
                context = action.payload.context;
                byLink = {};
                byLink[allLinks[0].link] = 0;
            }

            if (byLink[link] >= 0) {
                activeId = byLink[link];
                allLinks = [...allLinks.slice(0, activeId), { title: title, link: link }, ...allLinks.slice(activeId + 1)];
            }
            else {
                allLinks = [...allLinks, { title: title, link: link }];
                if (allLinks.length > 10) {
                    byLink = {
                        ...byLink,
                        [allLinks[0].link]: undefined
                    };
                    allLinks = allLinks.slice(1);
                }
                activeId = allLinks.length - 1;
                byLink = {
                    ...byLink,
                    [link]: activeId
                };
            }
            return Object.assign({}, state, {
                browserHistory: {
                    byLink: byLink,
                    allLinks: allLinks,
                    activeId: activeId,
                    context: context
                }
            });
        }
        case SET_FORCE_LOAD:
            return { ...state, forceLoad: action.payload };
        case SET_PAGE_TITLE:
            return Object.assign({}, state, {
                pageTitle: action.payload.pageTitle
            });
        case SET_LOADING:
            var count = action.payload.isLoading ? state.loadingCount + 1 : state.loadingCount - 1;
            return Object.assign({}, state, {
                loadingCount: count
            });
        case SET_SOFT_LOADING:
            return { ...state, softLoading: action.payload.isLoading };
        case SET_LOADING_FOR_UPDATE_NARRATION_LIBRARY:
            if (state.loadingUpdateNarrationLibrary === action.payload.isLoading) {
                return state;
            }
            else {
                return { ...state, loadingUpdateNarrationLibrary: action.payload.isLoading };
            }
        case SET_BREADCRUMBS_ARRAY: {
            return { ...state, breadCrumbsArray: action.payload.breadCrumbsArray };
        }
        case ENABLE_HUB_STICKY_BAR_LOGO_MENU: {
            return { ...state, enableHubStickyBarLogoMenu: action.payload.isEnableHubStickyBarLogoMenu };
        }
        case SET_HUB_STICKY_BAR_LOGO_MENU_ITEMS_ARRAY: {
            return { ...state, hubStickyBarLogoMenuItemsArray: action.payload.hubStickyBarLogoMenuItemsArray };
        }
        case SET_REVERT_TO_VERSION: {
            return { ...state, revertToVersion: action.payload };
        }
        case TOGGLE_MIDDLE_PANEL_COLLAPSE: {
            return { ...state, middlePanelCollapsed: !state.middlePanelCollapsed };
        }
        case DATA_LIBRARY_SELECTED_TAB: {
            return { ...state, dataLibrarySelectedTabId: action.payload };
        }
        case REPORT_ESCAPE_CLICK: {
            let isEscapeClick = action.payload.isEscapeClick;
            // all changes it state due to escape click
            return Object.assign({}, state, {
                shouldTurnOffMergeDPSession: isEscapeClick
            });
        }
        case SET_DRAWER_FILTER: {
            return { ...state, studioProgramsDrawerFilter: action.payload.drawerFilter };
        }
        default:
            return state;
    }
}
export default commonReducer;
