import { EntityTypes } from "./definitions";
import { ASSET_TYPES } from "../vlx/consts";
import type { AssetDoDiffParams, AssetDoValidateParams } from "./assetEntity";
import AssetEntity from "./assetEntity";
import type { RecordingAsset } from "../../../common/types/asset";
import type { DiffResult } from "../versionDiff/diffManager";
import { ChangeType } from "../versionDiff/diffManager";
import { diffEntities } from "../versionDiff/diffUtils";
import AssetUtils from "../common/assetUtils";
import type { Issue, ValidationResult } from "../validations/validationManager";
import { IssueCodes, IssueSeverity } from "../validations/validationManager";
import { STATUS_REQUEST } from "../../components/legacyCommon/Consts";

type NormalizedRecordingAsset = {
    name: string;
    activeVersion: number;
    location: string;
    recordingStatus: string;
    calculatedInUse: boolean;
};

export default class RecordingEntity extends AssetEntity {
    constructor() {
        super(EntityTypes.RECORDING, ASSET_TYPES.recording);
    }

    getAssetId = (asset: RecordingAsset) => AssetUtils.getCrossVersionId(asset.name);

    /** override AssetEntity's doValidate method **/
    doValidate = ({ asset }: AssetDoValidateParams): ValidationResult => {
        const recording: RecordingAsset = asset as RecordingAsset;
        const type: EntityTypes = this.getType();
        const id: string = recording.name;
        let recordingValidationResult: ValidationResult = { type, id, issues: [] };

        // check recording status
        if (recording.status === STATUS_REQUEST) {
            const issue: Issue = { code: IssueCodes.RECORDING_IS_PENDING, severity: IssueSeverity.WARNING, details: {} };
            recordingValidationResult.issues.push(issue);
            recordingValidationResult.severity = issue.severity;
        }
        else {
            // Check recording recordingStatus - todo - uncomment this
            /*
            if (recording.recordingStatus === AssetUtils.recordingStatuses.PENDING_APPROVAL) {
                const issue: Issue = {
                    code: IssueCodes.RECORDING_IS_PENDING_APPROVAL,
                    severity: IssueSeverity.WARNING,
                    details: {}
                };
                recordingValidationResult.issues.push(issue);
                recordingValidationResult.severity = issue.severity;
            }
            else if (recording.recordingStatus === AssetUtils.recordingStatuses.REJECTED) {
                const issue: Issue = {
                    code: IssueCodes.RECORDING_IS_REJECTED,
                    severity: IssueSeverity.ERROR,
                    details: {}
                };
                recordingValidationResult.issues.push(issue);
                recordingValidationResult.severity = issue.severity;
            }
            */
        }

        return recordingValidationResult;
    };

    /** override AssetEntity's doDiff method **/
    doDiff = ({ id, previousAsset, currentAsset, versionView, previousProjectRFR, currentProjectRFR }: AssetDoDiffParams): DiffResult => {
        const normalizedPreviousAsset: NormalizedRecordingAsset = this.normalizeRecordingAsset(previousAsset as RecordingAsset, previousProjectRFR, true);
        const normalizedCurrentAsset: NormalizedRecordingAsset = this.normalizeRecordingAsset(currentAsset as RecordingAsset, currentProjectRFR, versionView);

        // Product: if a recording moved from "In Use" to "No Longer Needed", it's considered as deleted. And vice versa.
        let changeType: ChangeType;

        if (normalizedPreviousAsset && normalizedPreviousAsset.calculatedInUse === false && normalizedCurrentAsset && normalizedCurrentAsset.calculatedInUse === true) {
            changeType = ChangeType.Add;
        }
        else if (normalizedPreviousAsset && normalizedPreviousAsset.calculatedInUse === true && normalizedCurrentAsset && normalizedCurrentAsset.calculatedInUse === false) {
            changeType = ChangeType.Delete;
        }
        else if (!normalizedPreviousAsset && normalizedCurrentAsset && normalizedCurrentAsset.calculatedInUse === false) {
            changeType = ChangeType.None;
        }
        else {
            changeType = diffEntities(normalizedPreviousAsset, normalizedCurrentAsset);
        }

        return {
            type: this.getType(),
            id,
            name: this.getPropsFromPreviousOrCurrent(normalizedPreviousAsset, normalizedCurrentAsset, "name"),
            changeType: changeType,
            changes: [],
            isShown: changeType !== ChangeType.None
        };
    };

    private normalizeRecordingAsset = (recordingAsset: RecordingAsset, projectRFR: number, versionView: boolean): NormalizedRecordingAsset => {
        if (!recordingAsset) {
            return;
        }

        return {
            name: recordingAsset.filename,
            activeVersion: AssetUtils.getVersion(recordingAsset, versionView),
            location: AssetUtils.getLocation(recordingAsset, versionView),
            recordingStatus: recordingAsset.recordingStatus,
            calculatedInUse: recordingAsset.RFR >= projectRFR
        };
    };
}
