import HubEditorsUtils from "../../../components/HubEditor/HubEditorsUtils";
import { deepCloneObj } from "../../common/generalUtils";
import { mapNumToLetter } from "../../../../common/generalUtils";
import { calcGroupCoords, getGroupHeight, getGroupX, getGroupY } from "./groupUtils";
import { ALPHA_NUMERIC_MESSAGE, CANVAS_DIMENSIONS, PRIORITIZED_LIST_PADDING } from "../../../components/legacyCommon/Consts";
import { entityType } from "../../../../common/commonConst";
import { createEmptyCompoundValue } from "../../vlx/editorLogicUtils";
import StateReaderUtils from "../../common/StateReaderUtils";
import type { PrioritizedSlotActionableData } from "../../../../common/types/logic";
import { ActionableDataValueType } from "../../../../common/types/logic";
import type { LogicJSON } from "../../common/types";
import type { PrioritizedList } from "../../../../common/types/scene";
import type { ProgramDataFields } from "../../vlx/consts";

export function validatePrioritizedListNameFromScenes(scene, value, listId) {
    // empty
    if (!value) {
        return {
            result: false,
            message: "Enter a list name"
        };
    }

    // alpha numeric
    if (!HubEditorsUtils.isAlphaNumeric(value)) {
        return {
            result: false,
            message: ALPHA_NUMERIC_MESSAGE
        };
    }

    // is duplicate
    if (scene && scene.sceneParts) {
        for (let scenePart of scene.sceneParts) {
            if (scenePart.prioritizedLists && scenePart.prioritizedLists.some((pl) => pl.name === value && pl.id !== listId)) {
                return {
                    result: false,
                    message: "Name already exists"
                };
            }
        }
    }

    // else
    return {
        result: true
    };
}

function calcOriginPos(val, padding) {
    return val <= padding ? 0 : val - padding;
}

/***
 * calculate the size of prioritized list in canvas
 * 1 - if multiplying height by slot number (with padding) fits in canvas, use that.
 * 2 - else, push PL to top of canvas (y=0) and check if fits.
 * 3 - if all else fails, push to top of canvas and apply scale down factor to make group or placeholder smaller.
 *      in that case height should be as close to canvas width as possible but never larger.
 *
 * @param itemRect - object representing placeholder or group dimensions - { x, y, width, height }
 * @param numSlots - number of slots
 * @param padding - the space between slots and border, default 5
 * @param canvasDim - object representing width and height of scene part canvas, default { width: 480, height: 270 }
 * @return position object {
 *          x,
 *          y,
 *          width,
 *          height,
 *          maxItemHeight
 *          scaleFactor
 *        }
 */
export function calcPrioritizedListSize(itemRect, numSlots, padding = PRIORITIZED_LIST_PADDING, canvasDim = CANVAS_DIMENSIONS) {
    const _floorTwoDecimals = (num) => Math.floor(num * 100) / 100;
    let plRect = {
        x: calcOriginPos(itemRect.x, padding),
        y: calcOriginPos(itemRect.y, padding),
        width: itemRect.width + padding * 2,
        height: padding + (itemRect.height + padding) * numSlots,
        maxItemHeight: itemRect.height,
        scaleFactor: 1
    };
    if (plRect.y + plRect.height <= canvasDim.height) {
        // 1 - PL fits in canvas
        return plRect;
    }
    else if (plRect.height <= canvasDim.height) {
        // 2 - PL fits in canvas when pushed to top of canvas
        return { ...plRect, y: 0 };
    }
    else {
        // 3 - push PL to top of canvas and use scale down factor
        // calc the maximum height possible (net value, padding excluded)
        const maxItemHeight = (canvasDim.height - padding) / numSlots - padding;
        // then calc the scale down factor between maxHeight and item's height
        const scaleFactor = _floorTwoDecimals(maxItemHeight / itemRect.height);
        return {
            x: plRect.x,
            y: 0,
            width: Math.round(itemRect.width * scaleFactor) + padding * 2,
            height: padding + (Math.round(itemRect.height * scaleFactor) + padding) * numSlots,
            maxItemHeight: Math.round(maxItemHeight),
            scaleFactor
        };
    }
}

export function findPrioritizedList(sceneEditorData, sceneId, scenePartIndex, prioritizedListId) {
    return findPrioritizedListFromScenes(sceneEditorData.scenes, sceneId, scenePartIndex, prioritizedListId);
}

export function findPrioritizedListFromScenes(scenes, sceneId, scenePartIndex, prioritizedListId) {
    let scene = scenes[sceneId];
    return scene.sceneParts[scenePartIndex].prioritizedLists && scene.sceneParts[scenePartIndex].prioritizedLists.find((pl) => pl.id === prioritizedListId);
}

function resizeByFactor(itemRect, scaleFactor) {
    return {
        ...itemRect,
        x: itemRect.x,
        y: Math.floor(itemRect.y * scaleFactor),
        width: Math.floor(itemRect.width * scaleFactor),
        height: Math.floor(itemRect.height * scaleFactor)
    };
}

/***
 * calculate the y position of a placeholder in prioritized list
 * @param baseY - object representing prioritized list dimensions - { x, y, width, height, scaleFactor }
 * @param relativeY - the y value of placeholder that's in a group, relative to the group y value. 0 for non grouped placeholders
 * @param slotHeight - the height of each slot
 * @param slotIndex - slot index
 * @param padding - the space between slots and border, default 5
 * @return slots array with positions modified
 */
function calcCascadeYValue(baseY, relativeY = 0, slotHeight, slotIndex, padding = PRIORITIZED_LIST_PADDING) {
    return baseY + padding + relativeY + slotIndex * (slotHeight + padding);
}

/***
 * change the position of groups and placeholders inside prioritized list.
 * @param prioritizedListRect - object representing prioritized list dimensions - { x, y, width, height, scaleFactor }
 * @param slotsOrig - array of groups or placeholders
 * @param padding - the space between slots and border, default 5
 * @return slots array with positions modified
 */
export function cascadePrioritizedListSlots(prioritizedListRect, slotsOrig, padding = PRIORITIZED_LIST_PADDING) {
    const slots = deepCloneObj(slotsOrig);
    return slots.map((slotContent, slotIndex) => {
        if (slotContent.placeholders) {
            // slot is a group
            const slotY = getGroupY(slotContent.placeholders);
            const grpHeight = getGroupHeight(slotY, slotContent.placeholders);
            const slotHeight = Math.min(prioritizedListRect.maxItemHeight, grpHeight);
            const slotX = getGroupX(slotContent.placeholders);

            const updatePlaceholders = slotContent.placeholders.map((ph) => {
                //the relative x value of the placeholder in relation to the group's x value
                const relativeX = Math.floor((ph.x - slotX) * prioritizedListRect.scaleFactor);
                const placeholderX = slotX + relativeX;
                //the relative y value of the placeholder in relation to the group's y value
                const relativeY = Math.floor((ph.y - slotY) * prioritizedListRect.scaleFactor);
                const placeholderY = calcCascadeYValue(prioritizedListRect.y, relativeY, slotHeight, slotIndex, padding);

                const resizedPlaceholder = resizeByFactor(ph, prioritizedListRect.scaleFactor);
                const cascadedPlaceholder = { ...resizedPlaceholder, x: placeholderX, y: placeholderY };
                return cascadedPlaceholder;
            });
            return { ...slotContent, placeholders: updatePlaceholders };
        }
        else {
            // slot is a placeholder
            const slotHeight = Math.min(prioritizedListRect.maxItemHeight, slotContent.height);
            const slotIndexYValue = calcCascadeYValue(prioritizedListRect.y, 0, slotHeight, slotIndex, padding);
            const resizedPlaceholder = resizeByFactor(slotContent, prioritizedListRect.scaleFactor);
            const cascadedPlaceholder = { ...resizedPlaceholder, y: slotIndexYValue };
            return cascadedPlaceholder;
        }
    });
}

export function findPrioritizedListNameFromId(scenes, scenePartIndex, prioritizedListId) {
    const scenePart = scenes.sceneParts[scenePartIndex];
    if (scenePart && scenePart.prioritizedLists) {
        const prioritizedList = scenePart.prioritizedLists.find((pl) => pl.id === prioritizedListId);
        if (prioritizedList) {
            return prioritizedList.name;
        }
    }
}

export function getMaxSlotsForPrioritizedList(sceneEditorData, prioritizedListId) {
    let maxSlotsNumber = 0;
    sceneEditorData.sceneParts.forEach((scenePart) => {
        if (scenePart.prioritizedLists && scenePart.prioritizedLists.length) {
            const pl = scenePart.prioritizedLists.find((pl) => pl.id === prioritizedListId);
            maxSlotsNumber = pl ? Math.max(pl.slots.length, maxSlotsNumber) : maxSlotsNumber;
        }
    });
    return maxSlotsNumber;
}

function calcSlotsCoords(prioritizedListSlots) {
    const slotIsAGroup = prioritizedListSlots.some((slot) => slot.placeholders);
    if (slotIsAGroup) {
        const slotCoords = prioritizedListSlots.map((slot) => calcGroupCoords(slot.placeholders));
        return calcGroupCoords(slotCoords);
    }
    else {
        return calcGroupCoords(prioritizedListSlots);
    }
}

/***
 * adds a slot to prioritized list. Duplicates the _last_ slot and then adjusts slots sizes by
 * 1 - adding slot in bottom
 * 2 - pushing to top and adding slot in bottom
 * 3 - making list smaller, pushing to top and adding slot in bottom. FML
 * @param prioritizedListSlots - array of prioritized list slots
 * @param padding - the space between slots and border, default 5
 * @param canvasDim - object representing width and height of scene part canvas, default { width: 480, height: 270 }
 * @return array of prioritized list slots
 */
function addSlot(prioritizedListSlots, padding, canvasDim) {
    let newPrioritizedListSlots = deepCloneObj(prioritizedListSlots);
    const slotIsAGroup = newPrioritizedListSlots.some((slot) => slot.placeholders);
    let newSlot = deepCloneObj(newPrioritizedListSlots[newPrioritizedListSlots.length - 1]);

    const newSlotHeight = slotIsAGroup ? calcSlotsCoords([newSlot]).height : newSlot.height;
    const currentSlotsCoords = calcSlotsCoords(newPrioritizedListSlots);
    let currentSlotsBottom = currentSlotsCoords.y + currentSlotsCoords.height;

    if (currentSlotsBottom + newSlotHeight - padding < canvasDim.height) {
        // 1 - slot can be placed at bottom of pl
        if (slotIsAGroup) {
            newSlot.placeholders.forEach((ph) => {
                ph.y = currentSlotsBottom + padding;
            });
        }
        else {
            newSlot.y = currentSlotsBottom;
        }
        newPrioritizedListSlots.push(newSlot);
    }
    else if (currentSlotsCoords.height - currentSlotsCoords.y + newSlotHeight - padding < canvasDim.height) {
        // 2 - pushing to top and adding slot in bottom
        // handle the current prioritized list
        newPrioritizedListSlots.forEach((slot) => {
            if (slotIsAGroup) {
                slot.placeholders.forEach((ph) => {
                    ph.y = ph.y - currentSlotsCoords.y + padding;
                });
            }
            else {
                slot.y = slot.y - currentSlotsCoords.y;
            }
        });
        // handle the new slot
        const newSlotsCoords = calcSlotsCoords(newPrioritizedListSlots);
        if (slotIsAGroup) {
            newSlot.placeholders.forEach((ph) => {
                ph.y = newSlotsCoords.height + padding;
            });
        }
        else {
            newSlot.y = newSlotsCoords.height;
        }
        newPrioritizedListSlots.push(newSlot);
    }
    else {
        // 3 - making list smaller, pushing to top and adding slot in bottom
        const _floorTwoDecimals = (num) => Math.floor(num * 100) / 100;
        const scaleFactor = _floorTwoDecimals(canvasDim.height / (currentSlotsCoords.height + currentSlotsCoords.y + newSlotHeight + padding * 2));

        // handle the current prioritized list
        newPrioritizedListSlots = newPrioritizedListSlots.map((slot) => {
            if (slotIsAGroup) {
                slot.placeholders = slot.placeholders.map((ph) => resizeByFactor(ph, scaleFactor));
                return slot;
            }
            else {
                return resizeByFactor(slot, scaleFactor);
            }
        });
        // handle the new slot
        const newSlotsCoords = calcSlotsCoords(newPrioritizedListSlots);
        if (slotIsAGroup) {
            newSlot.placeholders = newSlot.placeholders.map((ph) => {
                let newPh = resizeByFactor(ph, scaleFactor);
                newPh = { ...newPh, y: newSlotsCoords.y + newSlotsCoords.height + padding };
                return newPh;
            });
        }
        else {
            newSlot = resizeByFactor(newSlot, scaleFactor);
            newSlot = { ...newSlot, y: newSlotsCoords.y + newSlotsCoords.height };
        }
        newPrioritizedListSlots.push(newSlot);
    }
    return newPrioritizedListSlots;
}

function updatePrioritizedListSlotNumber(prioritizedList, newNumberOfSlots) {
    const currentNumberOfSlots = prioritizedList.slots.length;
    if (newNumberOfSlots < currentNumberOfSlots) {
        // remove slots from prioritized list
        prioritizedList.slots = prioritizedList.slots.slice(0, newNumberOfSlots);
        return prioritizedList;
    }
    else if (newNumberOfSlots > currentNumberOfSlots) {
        // add slots to list
        const slotsToAdd = newNumberOfSlots - currentNumberOfSlots;
        for (let i = 0; i < slotsToAdd; i++) {
            prioritizedList.slots = addSlot(prioritizedList.slots, PRIORITIZED_LIST_PADDING, CANVAS_DIMENSIONS);
        }
        return prioritizedList;
    }
    // no change in slot number. should never reach here
    return prioritizedList;
}

export function updatePrioritizedListWireFrames(prioritizedListData, { newPrioritizedListName, newNumberOfSlots, newId }) {
    let newPrioritizedListData = deepCloneObj(prioritizedListData);

    // update name
    newPrioritizedListData.name = newPrioritizedListName ? newPrioritizedListName : newPrioritizedListData.name;

    // update id
    newPrioritizedListData.id = newId || newPrioritizedListData.id;

    if (typeof newNumberOfSlots === "number") {
        // update number of slots
        newPrioritizedListData = updatePrioritizedListSlotNumber(newPrioritizedListData, newNumberOfSlots);
    }
    return newPrioritizedListData;
}

export function checkIfPrioritizedListSlotIsGroup(slotObj) {
    return slotObj.placeholders ? true : false;
}

export function isUniquePlaceholderInSlot(pListSlot, uniquePlaceholderName) {
    let numOfPlaceholders = 0;

    pListSlot.placeholders.forEach((ph) => {
        if (ph.name === uniquePlaceholderName) {
            numOfPlaceholders++;
        }
    });

    return numOfPlaceholders === 1;
}

/***
 * Deletes a placeholder from prioritized list slot
 * @param scene - scene object as presented on wireframes
 * @param pListlogic - prioritized list logic as presented on wireframes
 * @param prioritizedListId - the prioritized list id that we delete the placeholder from
 * @param slotIndex - the prioritized list slot index that we delete the placeholder from
 * @param phNameToDelete - placeholder name to delete
 * @param phIndexToDelete - placeholder index to delete
 * @param isUnique - whether it's the last instance of the placeholder in slot
 * @param setSecenePart - scene part modification function
 * @param setInputLogic - logic modification function
 */
export function deletePlaceholderFromPrioritizedList(scene, pListLogic, prioritizedListId, slotIndex, phNameToDelete, phIndexToDelete, isUnique, setScenePart, setInputLogic) {
    scene.sceneParts.forEach((modifiedScenePart, scenePartIndex) => {
        if (modifiedScenePart[entityType.prioritizedLists]) {
            modifiedScenePart[entityType.prioritizedLists].forEach((pList) => {
                if (pList.id === prioritizedListId) {
                    if (isUnique) {
                        // remove ph from wireframes
                        pList.slots.forEach((slot) => {
                            slot.placeholders = slot.placeholders.filter((ph) => ph.name !== phNameToDelete);
                        });

                        // remove ph from logic
                        pListLogic.rules.forEach((rule) => {
                            rule.value = rule.value.filter((ph) => ph.id !== phNameToDelete);
                        });
                    }
                    else {
                        pList.slots[slotIndex].placeholders.splice(phIndexToDelete, 1);
                    }
                }
            });
            setScenePart(scene.id, scenePartIndex, modifiedScenePart, false);
        }
    });

    if (isUnique) {
        setInputLogic(scene.id, prioritizedListId, pListLogic);
    }
}

/**
 * Finds the rule key from candidate name
 * @param candidateName - candidate name (with quotes)
 * @param prioritizedListId - prioritized list Id. can also be slot id (plid suffix ---slotXYZ)
 * @param prioritizedListLogic - list of prioritized list logic.
 * @return string - rule key (with quotes)
 */
export function resolveCandidateKeyFromName(candidateName, prioritizedListId, prioritizedListLogic: ProgramDataFields["prioritizedListLogic"]) {
    // TODO handle candidateName without quotes and take into consideration if single or double quotes
    const rhsWithoutQuotes = candidateName.substring(1, candidateName.length - 1);
    const id = prioritizedListId.includes("---slot") // remove slot suffix
        ? prioritizedListId.substr(0, prioritizedListId.indexOf("---slot"))
        : prioritizedListId;
    const PrioritizedListLogic = (prioritizedListLogic || []).find((item) => item.id === id);
    if (!PrioritizedListLogic) return candidateName;
    const candidate = PrioritizedListLogic.rules.find((rule) => rule.name === rhsWithoutQuotes);
    if (candidate && candidate.key) {
        return `'${candidate.key}'`;
    }
    return candidateName;
}

/**
 * get list of candidate names
 * @param prioritizedList logic object
 * @return list of objects - rule (candidate) names as 'dn' (display name) and keys as 'id'
 */
export function listCandidateNames(prioritizedList) {
    return prioritizedList.rules.map((rule) => ({
        dn: rule.name || "",
        id: rule.key
    }));
}

/**
 * get list of candidate names
 * @param prioritizedLists - list of prioritized list logic object
 * @param prioritizedListId - prioritized list ID
 * @return list of strings - candidate names. if prioritized list wasn't found return undefined
 */
export function listCandidateNamesForPLID(prioritizedLists, prioritizedListId) {
    const prioritizedList = prioritizedLists.find((pl) => pl.id === prioritizedListId);
    if (prioritizedList && prioritizedList.rules) {
        return listCandidateNames(prioritizedList);
    }
}

export function addPlaceHolderToSlots(scene, listId, placeholder) {
    scene.sceneParts.forEach((scenePart) => {
        // modify scene parts
        if (scenePart.prioritizedLists) {
            scenePart.prioritizedLists.forEach((pList) => {
                if (pList.id === listId) {
                    if (pList.slots) {
                        pList.slots.forEach((slot) => {
                            let clonePlaceholder = deepCloneObj(placeholder);
                            if (checkIfPrioritizedListSlotIsGroup(slot)) {
                                clonePlaceholder.height = slot.placeholders[0].height;
                                clonePlaceholder.y = slot.placeholders[0].y;
                                slot.placeholders.push(clonePlaceholder);
                            }
                            else {
                                clonePlaceholder.height = slot.height;
                                clonePlaceholder.y = slot.y;
                                slot.placeholders = [];
                                const existingPH = deepCloneObj(slot);
                                slot.placeholders.push(existingPH, clonePlaceholder);
                            }
                        });
                    }
                }
            });
        }
    });
}

export function addNewPlaceholderLogicToList(scene, listId, placeholder, placeholderType, logicObject) {
    let listLogicClone = deepCloneObj(StateReaderUtils.getInputLogicFromStateProjectLogic(logicObject, scene.id, listId));
    listLogicClone.rules.forEach((rule) => {
        rule.value.push(createEmptyCompoundValue([{ name: placeholder.name, type: placeholderType }])[0]);
    });
    return listLogicClone;
}

export function renamePlaceholderInList(prioritizedList: PrioritizedList, oldName: string, newName: string) {
    if (prioritizedList.slots) {
        prioritizedList.slots.forEach((slot) => {
            if (checkIfPrioritizedListSlotIsGroup(slot)) {
                slot.placeholders.forEach((ph) => {
                    if (ph.name === oldName) {
                        ph.name = newName;
                    }
                });
            }
            else {
                if (slot.name === oldName) {
                    slot.name = newName;
                }
            }
        });
    }
}

export function renamePlaceholderInListLogic(listLogic: LogicJSON, oldName: string, newName: string): LogicJSON {
    return {
        ...listLogic,
        rules: listLogic.rules.map((rule) => ({
            ...rule,
            value: rule.value.map((ph) => {
                const shouldReplace = ph.id === oldName || ph.displayName === oldName;
                return shouldReplace ? { ...ph, id: newName, displayName: newName } : ph;
            })
        }))
    };
}

export const generateActionableData = (prioritizedList: PrioritizedList, slotIndex: number) : Partial<PrioritizedSlotActionableData> => {
    const slotCharacter = mapNumToLetter(slotIndex);
    return {
        id: `${prioritizedList.id}---slot${slotCharacter}`,
        slotIdx: slotIndex,
        type: ActionableDataValueType.PrioritizedSlot
    };
};

export const getPrioritizedListIdFromActionableDataId = (actionableDataId: string) : string => {
    return actionableDataId.split("---")[0];
};
