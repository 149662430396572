import type { IFrameworkEntity, onSaveArgs, Roles } from "../../types";
import type {
    GqlClientAudiencePersonalization,
    GqlClientChannelPersonalization,
    GqlClientCreateGoalMutationVariables,
    GqlClientCreateGoalWithKpIsInput,
    GqlClientGoal,
    GqlClientGoalSupportersFragment,
    GqlClientKpi,
    GqlClientTouchpointPersonalization,
    GqlClientUpdateGoalMutationVariables,
    GqlClientUpdateGoalWithKpIsInput
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { GoalSupportersFragmentDoc, withCreateGoal, withDeleteGoal, withUpdateGoal } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import type { ApolloClient, ApolloError } from "@apollo/client";
import type { CardData } from "../../../../components/framework/EntityCard";
import { getRole, initCardData } from "../../utils";
import type { GoalDialogProps } from "../../../../components/framework/dialogs/GoalDialog";
import type { withGqlMutationProps } from "../../../../components/framework/WithGqlMutations";

const onSave = (args: onSaveArgs<GqlClientCreateGoalWithKpIsInput & GqlClientUpdateGoalWithKpIsInput, GqlClientCreateGoalMutationVariables, GqlClientUpdateGoalMutationVariables>) => {
    const { data, createCB, updateCB, programVersionId, dryRun } = args;
    const { id, description, priority, kpis, name, updated } = data;
    const isNewGoal: boolean = !id;

    const newKpis = kpis
        .filter((kpi) => !kpi.id)
        .map((kpi: GqlClientKpi): any => {
            const { name, description, objective } = kpi;
            return { name, description, objective };
        });

    if (isNewGoal) {
        const newGoal: GqlClientCreateGoalWithKpIsInput = { name, description, priority, newKpis };
        createCB({ variables: { programVersionId, input: newGoal } });
    }
    else {
        const existingKpis = kpis
            .filter((kpi) => kpi.id)
            .map((kpi: GqlClientKpi): any => {
                const { id, updated, name, description, objective } = kpi;
                return { name, objective, description, updated, id };
            });
        const updatedGoal: GqlClientUpdateGoalWithKpIsInput = { id, updated, name, description, priority, newKpis, kpis: existingKpis };
        updateCB({ variables: { input: updatedGoal, dryRun } });
    }
};

const getSupporters = (goal: GqlClientGoal, client: ApolloClient<any>) => {
    /** supporters for goals:
     * the goal's kpis
     * scenes related to those kpis
     * the audience values connected to those scenes
     * parent audiences of those values
     * stories related to those scenes
     * channels related to those stories
     * touchpoints related to those stories
     * channels related to those scenes
     * data elements related to those scenes
     * touchpoints related to those scenes
     */
    let supporterIds = [];
    goal.kpis.forEach(({ id, scenes }) => {
        supporterIds.push(id);
        scenes.forEach(({ id, __typename }) => {
            supporterIds.push(id);
            const sceneData: GqlClientGoalSupportersFragment = client.readFragment({
                id: `${__typename}:${id}`,
                fragment: GoalSupportersFragmentDoc
            });
            sceneData.feedDataElements.forEach(({ id }) => supporterIds.push(id));
            sceneData.stories.forEach(({ id, touchpoints, channels }) => {
                supporterIds.push(id);
                touchpoints.forEach(({ id }) => supporterIds.push(id));
                channels.forEach(({ id }) => supporterIds.push(id));
            });
            sceneData.personalizations.forEach((personalization) => {
                const { audiencePersonalizationValues, audience } = personalization as GqlClientAudiencePersonalization;
                const { channel } = personalization as GqlClientChannelPersonalization;
                const { touchpoint } = personalization as GqlClientTouchpointPersonalization;
                audience && supporterIds.push(audience.id);
                audience && audience.feedDataElements && supporterIds.push(...audience.feedDataElements.map(({ id }) => id));
                audiencePersonalizationValues && audiencePersonalizationValues.forEach(({ audienceValue }) => supporterIds.push(audienceValue.id));
                channel && supporterIds.push(channel.id);
                touchpoint && supporterIds.push(touchpoint.id);
            });
        });
    });
    return supporterIds;
};

export const getCardData = (goal: GqlClientGoal, roles?: Roles): CardData => {
    let goalData: CardData = initCardData(goal);
    goalData.header.title = goal.priority ? `${goal.priority}. ${goalData.header.title}` : goalData.header.title;
    goalData.contents = [{ title: "Description:", descriptionText: goal.description ? `${goal.description}` : "None" }];

    if (roles) goalData.role = getRole(goal, roles);
    return goalData;
};

const Goal: IFrameworkEntity<GoalDialogProps> = {
    mutations: {
        create: withCreateGoal<withGqlMutationProps<GoalDialogProps>>({
            name: "createGoal",
            options: ({ setMutationResult, setError }) => ({
                onCompleted: ({ createGoalWithKPIs }) => {
                    setMutationResult(createGoalWithKPIs.result);
                },
                onError: (error: ApolloError) => {
                    setError(error);
                }
            })
        }),
        update: withUpdateGoal<withGqlMutationProps<GoalDialogProps>>({
            name: "updateGoal",
            options: ({ setMutationResult, setError }) => ({
                onCompleted: ({ updateGoalWithKPIs }) => {
                    setMutationResult(updateGoalWithKPIs.result);
                },
                onError: (error: ApolloError) => {
                    setError(error);
                }
            })
        }),
        delete: withDeleteGoal<withGqlMutationProps<GoalDialogProps>>({
            name: "deleteGoal"
        })
    },
    getSupporters,
    getCardData,
    onSave
};

export default Goal;
