"use strict";
import StateReaderUtils from "../common/StateReaderUtils";
import { programTypes } from "../../../common/commonConst";
import { buildAnalyticsRules } from "./logixBuilder";
import type { Story } from "../../../common/types/story";
import type { Asset } from "../../../common/types/asset";
import type { Program } from "../../../common/types/program";

function createAnalyticsData(accountId: string, program: Program, wireframes, story: Story, assets: Asset[]) {
    // Skip if program type is not CRM (default type is CRM)
    if (!program || (program.programType && program.programType !== programTypes.CRM)) return null;

    const programDataFields = {
        dataElements: StateReaderUtils.getProjectDataElementsFromWireframes(wireframes),
        assets
    };

    let analyticsStoryData;
    if (story) {
        analyticsStoryData = {
            //
            story: `${program.displayName}/${story.name}`, //Todo: figure out what to do with multiple stories
            storyid: story.id
        };
    }
    // Get the current order of custom analytics fields
    const customFieldsArray = StateReaderUtils.getCustomAnalyticsFieldsArray(wireframes);

    let customFields = {};

    // Map fields in the order they came in, with their name
    if (customFieldsArray) {
        customFieldsArray.forEach((customAnalyticId, index) => {

            const customFieldData = StateReaderUtils.getCustomAnalyticsFieldData(wireframes, customAnalyticId);
            if (!customFieldData) {
                return;
            }

            const customAnalyticFields = StateReaderUtils.getCustomAnalyticFields(wireframes);
            const customFieldName: string = (customAnalyticFields && customAnalyticFields[customAnalyticId] && customAnalyticFields[customAnalyticId].name) || customAnalyticId;

            customFields[`custom${index + 1}`] = {
                ...buildAnalyticsRules(customFieldData, customFieldName, programDataFields)[0],
                type: "string"
            };
        });
    }

    const analyticsData = {
        storyData: analyticsStoryData,
        customFields
    };

    return analyticsData;
}
export default createAnalyticsData;
