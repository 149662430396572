import type { CompoundValue } from "../../../common/types/logic";
import { LogicType } from "../../../common/types/logic";
import { createLogic } from "./Logic";
import { VideoAspectRatio, VideoQualityProfile } from "../../../common/external/videoSpec";
import { ProgramType } from "../../../common/types/program";
import { featureFlagConst } from "@sundaysky/smartvideo-hub-config";
const { CRM_STORY_VIDEO_RATIO_QUALITY_LOGIC } = featureFlagConst;

interface RatioValue extends CompoundValue {
    id: "videoAspectRatio",
    displayName: "Ratio",
    outputType: LogicType.Ratio,
    value: null | VideoAspectRatio,
    show: true
}

interface QualityValue extends CompoundValue {
    id: "videoQualityProfile",
    displayName: "Quality",
    outputType: LogicType.Quality,
    value: null | VideoQualityProfile,
    show: true
}

export type QualityRatioLogicValue = [RatioValue, QualityValue];

export const createNewStoryQualityRatioValue = (): QualityRatioLogicValue => {
    return [
        {
            id: "videoAspectRatio",
            displayName: "Ratio",
            outputType: LogicType.Ratio,
            value: null,
            show: true
        },
        {
            id: "videoQualityProfile",
            displayName: "Quality",
            outputType: LogicType.Quality,
            value: null,
            show: true
        }
    ];
};

export const createNewStoryQualityRatioLogic = () => {
    return createLogic({
        outputType: LogicType.Compound,
        defaultValue: createNewStoryQualityRatioValue(),
        defaultValueShow: true,
        rules: []
    });
};

type RatioOption = {
    id: VideoAspectRatio,
    name: string
}

export const RatioOptions: RatioOption[] = [
    { id: VideoAspectRatio.FULL_LANDSCAPE, name: "Full Landscape (16:9)" },
    { id: VideoAspectRatio.FULL_PORTRAIT, name: "Full Portrait (9:16)" },
    { id: VideoAspectRatio.SQUARE, name: "Square (1:1)" }
];

type QualityOption = {
    id: VideoQualityProfile,
    name: string
}

export const QualityOptions: QualityOption[] = [
    { id: VideoQualityProfile.SD, name: "SD" },
    { id: VideoQualityProfile.SD_INTERMEDIATE, name: "SD Intermediate" },
    { id: VideoQualityProfile.SD_HIGH, name: "SD High" },
    { id: VideoQualityProfile.HD_READY, name: "HD-Ready" },
    { id: VideoQualityProfile.HD_READY_INTERMEDIATE, name: "HD-Ready Intermediate" },
    { id: VideoQualityProfile.HD_READY_HIGH, name: "HD-Ready High" },
    { id: VideoQualityProfile.HD, name: "HD" }
];

export const shouldUseVideoRatioAndQualityLogic = (programType: ProgramType, enabledFeatureFlags: string[]) =>
    programType === ProgramType.CRM && enabledFeatureFlags.includes(CRM_STORY_VIDEO_RATIO_QUALITY_LOGIC);