import {
    ADD_NEW_STORY_SUCCESS,
    ADD_SCENE_TO_STORY_SUCCESS,
    DELETE_STORY_SUCCESS,
    DUPLICATE_STORY,
    MERGE_DECISION_POINTS_SUCCESS,
    MIGRATE_TO_MULTIPLE_STORIES_SUCCESS,
    REMOVE_SCENE_FROM_STORY,
    UPDATE_DECISION_POINT_LOGIC,
    UPDATE_DECISION_POINT_LOGIC_AND_ADD_NEW_DECISION_POINT_LOGIC,
    UPDATE_STORY_LOGIC_ITEMS,
    UPDATE_STORY_SUCCESS
} from "./StoryActions";
import type { LoadingProgramWireframeSuccess } from "../projects/projectWireframes/projectsWireframesActions";
import { DELETING_PROJECT_WIREFRAMES_SCENE_SUCCESS, LOADING_PROGRAM_WIREFRAMES_SUCCESS } from "../projects/projectWireframes/projectsWireframesActions";
import { LogicContainers } from "../../../common/commonConst";
import { excludeKeysFromObject, immutablyReplaceValue } from "../../../common/generalUtils";
import StateReaderUtils from "../common/StateReaderUtils";
import { extractSceneIdsAndDPsFromDPLogic } from "../Logics/DecisionPointLogic";
import { MediaTypes } from "../../../common/types/asset";
import type { Story } from "../../../common/types/story";
import type { LogicJSON } from "../../../common/types/logic";
import type { Scene } from "../../../common/types/scene";

function getParticipatingSceneIdsAndDPs(initialDPId: string, allDPLogicInStory, allScenes: object): { participatingDPs: string[], participatingSceneIds: string[] } {
    let { participatingSceneIds, participatingDPs } =
        initialDPId && allDPLogicInStory
            ? extractSceneIdsAndDPsFromDPLogic(allDPLogicInStory[initialDPId], allDPLogicInStory)
            : { participatingSceneIds: [], participatingDPs: [] };

    if (initialDPId) {
        participatingDPs.push(initialDPId);
    }

    // There could be scene Ids in the story logic that were already deleted
    participatingSceneIds = participatingSceneIds.filter((sceneId) => !!allScenes[sceneId]);

    return { participatingDPs, participatingSceneIds };
}

function storyReducer(state, action) {
    switch (action.type) {
        case ADD_NEW_STORY_SUCCESS: {
            let { projectName, data } = action.payload;
            let { storyId, storyData, DpLogicData, soundtrackLogicData, backgroundImageLogicData, backgroundVideoLogicData } = data;
            let byName = state.wireframes.byName;
            let allNames = state.wireframes.allNames;
            let currentStories = byName[projectName] ? byName[projectName].stories : {};
            let currentLogic = byName[projectName] ? byName[projectName].logic : {};
            let currentStoriesLogic = currentLogic ? currentLogic[LogicContainers.Story] : {};
            storyData.participatingSceneIds = [];
            storyData.participatingDPs = [storyData.initialDecisionPointId];

            return Object.assign({}, state, {
                wireframes: {
                    byName: Object.assign({}, byName, {
                        [projectName]: Object.assign({}, byName[projectName], {
                            stories: Object.assign({}, currentStories, { [storyId]: storyData }),
                            logic: Object.assign({}, currentLogic, {
                                [LogicContainers.Story]: {
                                    ...currentStoriesLogic,
                                    [storyId]: {
                                        [LogicContainers.DecisionPoint]: {
                                            [storyData.initialDecisionPointId]: DpLogicData
                                        },
                                        [LogicContainers.Soundtrack]: {
                                            [MediaTypes.Audio]: soundtrackLogicData
                                        },
                                        [LogicContainers.BackgroundAsset]: {
                                            [MediaTypes.Image]: backgroundImageLogicData,
                                            [MediaTypes.Video]: backgroundVideoLogicData
                                        }
                                    }
                                }
                            })
                        })
                    }),
                    allNames: allNames
                },
                error: false
            });
        }
        case DUPLICATE_STORY: {
            let { projectName, data } = action.payload;
            let { storyId, storyData, storyLogicItems } = data;
            let byName = state.wireframes.byName;
            let allNames = state.wireframes.allNames;
            let currentStories = byName[projectName] ? byName[projectName].stories : {};
            let currentLogic = byName[projectName] ? byName[projectName].logic : {};
            let currentStoriesLogic = currentLogic ? currentLogic[LogicContainers.Story] : {};
            return Object.assign({}, state, {
                wireframes: {
                    byName: Object.assign({}, byName, {
                        [projectName]: Object.assign({}, byName[projectName], {
                            stories: Object.assign({}, currentStories, { [storyId]: storyData }),
                            logic: Object.assign({}, currentLogic, {
                                [LogicContainers.Story]: {
                                    ...currentStoriesLogic,
                                    [storyId]: storyLogicItems
                                }
                            })
                        })
                    }),
                    allNames: allNames
                },
                error: false
            });
        }
        case DELETE_STORY_SUCCESS: {
            let { projectName, data } = action.payload;
            let { storyId } = data;
            let byName = state.wireframes.byName;
            let allNames = state.wireframes.allNames;

            let currentStories = byName[projectName].stories;
            let updatedStories = excludeKeysFromObject([storyId], currentStories);

            let currentLogic = byName[projectName].logic;
            let currentStoryLogic = byName[projectName].logic[LogicContainers.Story];
            let updatedStoriesLogic = excludeKeysFromObject([storyId], currentStoryLogic);

            return Object.assign({}, state, {
                wireframes: {
                    byName: Object.assign({}, byName, {
                        [projectName]: Object.assign({}, byName[projectName], {
                            stories: Object.assign({}, updatedStories),
                            logic: Object.assign({}, currentLogic, {
                                [LogicContainers.Story]: updatedStoriesLogic
                            })
                        })
                    }),
                    allNames: allNames
                },
                error: false
            });
        }
        case LOADING_PROGRAM_WIREFRAMES_SUCCESS: {
            let { projectName, storiesLogic, stage, version } = action.payload as LoadingProgramWireframeSuccess;

            if (!storiesLogic) {
                return state;
            }

            let wireframes = StateReaderUtils.getWireFrame(state, projectName, stage, version);
            let stories = StateReaderUtils.getStories(wireframes) || {};
            // We loaded storiesLogic in previous projectWireframesReducer.
            // Calculate participating scenes for each story
            let newStories = Object.keys(stories).reduce((acc, storyId) => {
                let story: Story = stories[storyId];
                let allDPLogic = storiesLogic[storyId][LogicContainers.DecisionPoint];
                const { participatingDPs, participatingSceneIds } = getParticipatingSceneIdsAndDPs(story.initialDecisionPointId, allDPLogic, wireframes.scenes);
                acc[storyId] = { ...story, participatingDPs, participatingSceneIds };
                return acc;
            }, {});

            const wireframesPath = StateReaderUtils.getWireframesPathOnState(projectName, stage, version);
            let newState = immutablyReplaceValue(state, [...wireframesPath, "stories"], newStories);
            return newState;
        }
        case UPDATE_DECISION_POINT_LOGIC_AND_ADD_NEW_DECISION_POINT_LOGIC:
        case UPDATE_DECISION_POINT_LOGIC:
        case MERGE_DECISION_POINTS_SUCCESS:
        case REMOVE_SCENE_FROM_STORY: {
            let { projectName, data } = action.payload;
            let { storyId, updatedStoryDecisionPointsLogics, decisionPointLogicIdToDelete, storyDataToUpdate } = data;
            let byName = state.wireframes.byName;
            let allNames = state.wireframes.allNames;
            let wireframes = StateReaderUtils.getWireFrame(state, projectName);
            let currentStories = wireframes ? wireframes.stories : {};

            let currentLogic = wireframes ? wireframes.logic : {};
            let currentStoryLogic = currentLogic[LogicContainers.Story][storyId] || {};
            let currentStoryDPLogic = currentStoryLogic[LogicContainers.DecisionPoint] || {};
            let story = currentStories[storyId];
            let updatedStoryDPLogic = { ...currentStoryDPLogic, ...updatedStoryDecisionPointsLogics };
            if (decisionPointLogicIdToDelete) {
                updatedStoryDPLogic = excludeKeysFromObject([decisionPointLogicIdToDelete], updatedStoryDPLogic);
            }
            let updatedStory = { ...story, ...(storyDataToUpdate ? storyDataToUpdate : {}) };
            const { participatingDPs, participatingSceneIds } = getParticipatingSceneIdsAndDPs(updatedStory.initialDecisionPointId, updatedStoryDPLogic, wireframes.scenes);
            let updatedStories = { ...currentStories, [storyId]: { ...updatedStory, participatingDPs, participatingSceneIds } };
            return {
                ...state,
                wireframes: {
                    byName: Object.assign({}, byName, {
                        [projectName]: Object.assign({}, byName[projectName], {
                            stories: updatedStories,
                            logic: {
                                ...currentLogic,
                                [LogicContainers.Story]: {
                                    ...currentLogic[LogicContainers.Story],
                                    [storyId]: {
                                        ...currentStoryLogic,
                                        [LogicContainers.DecisionPoint]: updatedStoryDPLogic
                                    }
                                }
                            }
                        })
                    }),
                    allNames: allNames
                },
                error: false
            };
        }
        case UPDATE_STORY_SUCCESS: {
            let { projectName, storyId, storyData } = action.payload;
            let byName = state.wireframes.byName;
            let allNames = state.wireframes.allNames;
            let currentStories = byName[projectName] ? byName[projectName].stories : {};

            return Object.assign({}, state, {
                wireframes: {
                    byName: Object.assign({}, byName, {
                        [projectName]: Object.assign({}, byName[projectName], {
                            stories: Object.assign({}, currentStories, { [storyId]: storyData })
                        })
                    }),
                    allNames: allNames
                },
                error: false
            });
        }
        case ADD_SCENE_TO_STORY_SUCCESS: {
            let { projectName, data } = action.payload;
            let { newSceneData, updatedStoryDecisionPointsLogics, storyId, storyDataToUpdate } = data;

            const wireframesPath = StateReaderUtils.getWireframesPathOnState(projectName);
            let newState = state;

            if (newSceneData) {
                newState = immutablyReplaceValue(newState, [...wireframesPath, "scenes", newSceneData.id], newSceneData);
                newState = immutablyReplaceValue(newState, [...wireframesPath, "logic", newSceneData.id], {}, true);
            }

            if (storyDataToUpdate) {
                newState = immutablyReplaceValue(newState, [...wireframesPath, "stories", storyId], storyDataToUpdate, true);
            }

            if (updatedStoryDecisionPointsLogics) {
                newState = immutablyReplaceValue(newState, [...wireframesPath, "logic", LogicContainers.Story, storyId, LogicContainers.DecisionPoint], updatedStoryDecisionPointsLogics, true);

                let story: Story = StateReaderUtils.getProjectWireframesStories(newState, projectName)[storyId];
                const initialDPId: string = storyDataToUpdate.initialDecisionPointId || story.initialDecisionPointId;
                const storyDPLogic: Record<string, LogicJSON> = StateReaderUtils.getDecisionPointLogicItemsForStory(newState, projectName, storyId);
                const scenes: Record<string, Scene> = StateReaderUtils.getWireFrame(newState, projectName).scenes;

                const { participatingDPs, participatingSceneIds } = getParticipatingSceneIdsAndDPs(initialDPId, storyDPLogic, scenes);

                newState = immutablyReplaceValue(newState, [...wireframesPath, "stories", storyId], { participatingDPs, participatingSceneIds }, true);
            }

            newState = immutablyReplaceValue(newState, ["error"], false);

            return newState;

        }
        case DELETING_PROJECT_WIREFRAMES_SCENE_SUCCESS: {
            let { projectName } = action.payload;
            let byName = state.wireframes.byName;
            let allNames = state.wireframes.allNames;

            let wireframes = StateReaderUtils.getWireFrame(state, projectName);
            let stories = StateReaderUtils.getStories(wireframes) || {};

            // Calculate participating scenes for each story
            let newStories = Object.keys(stories).reduce((acc, storyId) => {
                let story: Story = stories[storyId];
                let allDPLogic = wireframes.logic[LogicContainers.Story][storyId][LogicContainers.DecisionPoint];
                const { participatingDPs, participatingSceneIds } = getParticipatingSceneIdsAndDPs(story.initialDecisionPointId, allDPLogic, wireframes.scenes);
                acc[storyId] = { ...story, participatingDPs, participatingSceneIds };
                return acc;
            }, {});

            return {
                ...state,
                wireframes: {
                    byName: {
                        ...byName,
                        [projectName]: {
                            ...byName[projectName],
                            stories: newStories
                        }
                    },
                    allNames: allNames
                },
                error: false
            };
        }
        case UPDATE_STORY_LOGIC_ITEMS: {
            let { projectName, data } = action.payload;
            let { storyId, updatedStoryLogicItems, logicContainerType, storyDataToUpdate } = data;
            let programWireframes = StateReaderUtils.getWireFrame(state, projectName);
            let byName = state.wireframes.byName;
            let allNames = state.wireframes.allNames;
            let currentStories = programWireframes ? programWireframes.stories : {};
            let story = currentStories[storyId] || {};
            let updatedStory = { ...story, ...(storyDataToUpdate ? storyDataToUpdate : {}) };
            let updatedStories = { ...currentStories, [storyId]: updatedStory };
            let currentLogic = byName[projectName] ? byName[projectName].logic : {};
            let currentStoryLogic = currentLogic[LogicContainers.Story][storyId] || {};
            let currentStoryContainerTypeLogic = currentStoryLogic[logicContainerType] || {};
            return {
                ...state,
                wireframes: {
                    byName: Object.assign({}, byName, {
                        [projectName]: Object.assign({}, byName[projectName], {
                            stories: updatedStories,
                            logic: {
                                ...currentLogic,
                                [LogicContainers.Story]: {
                                    ...currentLogic[LogicContainers.Story],
                                    [storyId]: {
                                        ...currentStoryLogic,
                                        [logicContainerType]: {
                                            ...currentStoryContainerTypeLogic,
                                            ...updatedStoryLogicItems
                                        }
                                    }
                                }
                            }
                        })
                    }),
                    allNames: allNames
                },
                error: false
            };
        }
        case MIGRATE_TO_MULTIPLE_STORIES_SUCCESS: {
            let { projectName } = action.payload;
            return {
                ...state,
                projects: {
                    ...state.projects,
                    byName: {
                        ...state.projects.byName,
                        [projectName]: {
                            ...state.projects.byName[projectName],
                            storiesVersion: 1
                        }
                    }
                }
            };
        }
        default:
            return state;
    }
}

export default storyReducer;
