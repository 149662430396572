import { createAction } from "redux-actions";
import { reportError } from "../../common/commonActions";
import { recalculateLogicValidations } from "../../DataElements/DataElementActions";
import { HandleRawRecording } from "./handleRawRecording";
import StateReaderUtils from "../../common/StateReaderUtils";
import type {
    GqlClientAnimationFragment,
    GqlClientAssetFragment,
    GqlClientAssetType,
    GqlClientCreateAssetRequestMutation,
    GqlClientCreateAssetRequestMutationVariables,
    GqlClientCreateAssetVersionMutation,
    GqlClientCreateAssetVersionMutationVariables,
    GqlClientCreateAssetWithVersionMutation,
    GqlClientCreateAssetWithVersionMutationVariables,
    GqlClientCreateCreativeNarrationRecordingVersionMutationVariables,
    GqlClientCreateCreativeNarrationRecordingVersionOutput,
    GqlClientCreateDataTableMutation,
    GqlClientCreateDataTableMutationVariables,
    GqlClientCreateNarrationRecordingVersionMutation,
    GqlClientCreateNarrationRecordingVersionMutationVariables,
    GqlClientCreativeNarrationRecording,
    GqlClientCreativeNarrationRecordingVersion,
    GqlClientDataTableBody,
    GqlClientDataTableFragment,
    GqlClientGetAllCreativesNarrationRecordingsQuery,
    GqlClientGetAnimationVersionsQuery,
    GqlClientGetAnimationVersionsQueryVariables,
    GqlClientGetAssetVersionsQuery,
    GqlClientGetAssetVersionsQueryVariables,
    GqlClientGetNarrationRecordingVersionsQuery,
    GqlClientGetNarrationRecordingVersionsQueryVariables,
    GqlClientNarrationRecording,
    GqlClientNarrationRecordingFragment,
    GqlClientUpdateAnimationInput,
    GqlClientUpdateAnimationMutation,
    GqlClientUpdateAnimationMutationVariables,
    GqlClientUpdateAnimationOutputSuccess,
    GqlClientUpdateAssetMutation,
    GqlClientUpdateAssetMutationVariables,
    GqlClientUpdateAssetOutputSuccess,
    GqlClientUpdateCreativeNarrationRecordingMutationVariables,
    GqlClientUpdateCreativeNarrationRecordingVersionMutationVariables,
    GqlClientUpdateDataTableMutation,
    GqlClientUpdateDataTableMutationVariables,
    GqlClientUpdateNarrationRecordingInput,
    GqlClientUpdateNarrationRecordingMutation,
    GqlClientUpdateNarrationRecordingMutationVariables,
    GqlClientUpdateNarrationRecordingOutputSuccess,
    GqlClientUpdateNarrationRecordingVersionMutation,
    GqlClientUpdateNarrationRecordingVersionMutationVariables } from "../../../graphql/graphqlGeneratedTypes/graphqlClient";
import {
    CreateAssetRequestDocument,
    CreateAssetVersionDocument,
    CreateAssetWithVersionDocument,
    CreateCreativeNarrationRecordingVersionDocument,
    CreateDataTableDocument,
    CreateNarrationRecordingVersionDocument,
    GetAnimationVersionsDocument,
    GetAssetVersionsDocument,
    GetNarrationRecordingVersionsDocument,
    GqlClientCreateCreativeNarrationRecordingVersionResult,
    GqlClientUpdateAnimationResult,
    GqlClientUpdateAssetResult,
    GqlClientUpdateCreativeNarrationRecordingResult,
    GqlClientUpdateCreativeNarrationRecordingVersionResult,
    GqlClientUpdateNarrationRecordingResult,
    UpdateAnimationDocument,
    UpdateAssetDocument,
    UpdateCreativeNarrationRecordingDocument,
    UpdateCreativeNarrationRecordingVersionDocument,
    UpdateDataTableDocument,
    UpdateNarrationRecordingDocument,
    UpdateNarrationRecordingVersionDocument
} from "../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { getGqlNarrationRecordingErrorReason, resolveActiveVersionId } from "./creativeRecordingAssetsUtils";
import type {
    AnimationUpdatedData,
    AssetUpdatedData } from "./gqlUtils";
import {
    convertAssetUpdatedDataToGqlInput,
    convertPostgresAnimationToDynamoAnimation,
    convertPostgresAssetToDynamoAsset,
    initLegacyData
} from "./gqlUtils";
import AssetUtils from "../../common/assetUtils";
import type {
    AnimationHistoryItem,
    AnimationItem,
    Asset,
    ConvertedGqlAsset,
    ConvertedGqlRecordingAsset,
    MediaTypes,
    Narrator,
    RecordingAsset } from "../../../../common/types/asset";
import {
    AssetTypes,
    RecordingOrigin
} from "../../../../common/types/asset";
import type { FetchResult } from "@apollo/client";
import { GQL_ID_SEPARATOR, IGNORE_UPDATED } from "../../common/Consts";
import { fileTypesExcludeFromParse } from "../../../../common/commonConst";
import type { ThunkServices, WithGraphQLId } from "../../common/types";
import { BulkId } from "../../common/types";
import type { AddAssetMetadata, UpdatedAssetMetadata } from "./projectAssetsServices";
import {
    buildDataTableFromGql,
    buildNarrationRecordingFromGql,
    buildNarratorFromGql
} from "../../common/convertGqlEntityToWireframesUtils";
import type { DataTable, DataTableBody } from "../../../../common/types/dataTable";

export type CreativeNarrationAndProjectName = {
    creativeNarrationRecording: GqlClientCreativeNarrationRecording;
    projectName: string;
};

export const UPDATE_ASSET = "UPDATE_ASSET";
export const UPDATE_BULK_ASSETS_SUCCESS = "UPDATE_BULK_ASSETS_SUCCESS";
export const LOADING_ASSETS = "LOADING_ASSETS";
export const LOADING_ASSETS_COUNT_SUCCESS = "LOADING_ASSETS_COUNT_SUCCESS";
export const LOADING_ASSETS_SUCCESS = "LOADING_ASSETS_SUCCESS";
export const UPLOADING_ASSET = "UPLOADING_ASSET";
export const UPLOADING_ASSET_START = "UPLOADING_ASSET_START";
export const UPLOADING_ASSET_SUCCESS = "UPLOADING_ASSET_SUCCESS";
export const UPLOADING_ASSET_ERROR = "UPLOADING_ASSET_ERROR";
export const UPDATE_RECORDING_ASSET_STATUS_SUCCESS = "UPDATE_RECORDING_ASSET_STATUS_SUCCESS";
export const LOADING_ASSET_CONTENT = "LOADING_ASSET_CONTEN";
export const DELETE_ASSET_SUCCESS = "DELETE_ASSET_SUCCESS";
export const CLEAR_UPLOAD_LIST = "CLEAR_UPLOAD_LIST";
export const UPLOAD_RAW_RECORDING_PROGRESS = "UPLOAD_RAW_RECORDING_PROGRESS";
export const UPLOAD_RAW_RECORDING_FINISH = "UPLOAD_RAW_RECORDING_FINISH";
export const UPLOAD_RAW_RECORDING_CLEAR = "UPLOAD_RAW_RECORDING_CLEAR";
export const LOADING_CREATIVE_NARRATION_RECORDING_VERSIONS_SUCCESS = "LOADING_CREATIVE_NARRATION_RECORDING_VERSIONS_SUCCESS";
export const UPLOADING_CREATIVE_NARRATION_RECORDING_VERSIONS_SUCCESS = "UPLOADING_CREATIVE_NARRATION_RECORDING_VERSIONS_SUCCESS";
export const UPDATING_CREATIVE_NARRATION_RECORDING_VERSION_STATUS_SUCCESS = "UPDATING_CREATIVE_NARRATION_RECORDING_VERSIONS_STATUS_SUCCESS";
export const UPDATING_CREATIVE_NARRATION_RECORDING_SUCCESS = "UPDATING_CREATIVE_NARRATION_RECORDING_SUCCESS"; // for changing active version

export const deletingProjectAssetsSuccess = createAction(DELETE_ASSET_SUCCESS, (asset, projectName) => ({ asset, projectName }));
export const updateAsset = createAction(UPDATE_ASSET, (projectName, asset) => ({ projectName, asset, assetType: asset.type }));
export const updateBulkAssetsSuccess = createAction(UPDATE_BULK_ASSETS_SUCCESS, (projectName, responseBody, assetType) => ({ projectName, responseBody, assetType }));
export const loadingProjectAssetsCountSuccess = createAction(LOADING_ASSETS_COUNT_SUCCESS, (projectName, assets, assetType) => ({ projectName, assets, assetType }));
export const loadingProjectAssetsSuccess = createAction(LOADING_ASSETS_SUCCESS, (projectName, assets, versionMode: boolean) =>
    ({ projectName, assets, versionMode }));
export const loadingAssetContent = createAction(LOADING_ASSET_CONTENT, () => {});
export const uploadingProjectAsset = createAction(UPLOADING_ASSET, () => {});
export const uploadingAssetStart = createAction(UPLOADING_ASSET_START, (assetName) => ({ assetName }));
export const uploadingProjectAssetsSuccess = createAction(UPLOADING_ASSET_SUCCESS, (asset, projectName, assetType, prevName) => ({ asset, projectName, assetType, prevName }));
export const uploadingProjectAssetsError = createAction(UPLOADING_ASSET_ERROR, (assetName) => ({ assetName }));
export const clearingUploadingList = createAction(CLEAR_UPLOAD_LIST, () => ({}));
export const updatingRecordingStatusSuccess = createAction(UPDATE_RECORDING_ASSET_STATUS_SUCCESS, (projectName, assetName, version, assetUpdates) => ({
    projectName,
    assetName,
    version,
    assetUpdates
}));
export const uploadRawRecordingProgress = createAction(UPLOAD_RAW_RECORDING_PROGRESS, (projectName, progressStatus) => ({ projectName, progressStatus }));
export const uploadRawRecordingFinish = createAction(UPLOAD_RAW_RECORDING_FINISH, (projectName) => ({ projectName }));
export const uploadRawRecordingClear = createAction(UPLOAD_RAW_RECORDING_CLEAR, (projectName) => ({ projectName }));
export const loadingCreativeRecordingAssetsSuccess = createAction(
    LOADING_CREATIVE_NARRATION_RECORDING_VERSIONS_SUCCESS,
    (projectName: string, creativeNarrationRecordings: GqlClientGetAllCreativesNarrationRecordingsQuery) => ({ projectName, creativeNarrationRecordings })
);
export const uploadingCreativeNarrationRecordingVersionSuccess = createAction(
    UPLOADING_CREATIVE_NARRATION_RECORDING_VERSIONS_SUCCESS,
    ({ creativeNarrationRecordingVersion, projectName }: { creativeNarrationRecordingVersion: GqlClientCreativeNarrationRecordingVersion; projectName: string }) => ({
        creativeNarrationRecordingVersion,
        projectName
    })
);
export const updatingCreativeRecordingStatusSuccess = createAction(
    UPDATING_CREATIVE_NARRATION_RECORDING_VERSION_STATUS_SUCCESS,
    ({ creativeNarrationRecording, projectName }: CreativeNarrationAndProjectName) => ({ creativeNarrationRecording, projectName })
);
export const updatingCreativeRecordingSuccess = createAction(
    UPDATING_CREATIVE_NARRATION_RECORDING_SUCCESS,
    ({ creativeNarrationRecording, projectName }: CreativeNarrationAndProjectName) => ({
        creativeNarrationRecording,
        projectName
    }));

export type UploadAssetContentParams = {
    accountId: string;
    projectName: string;
    type: AssetTypes;
    name: string;
    file: File;
    newAsset: boolean;
    cb?: (...args) => any;
    progress?: (percent) => any;
    asset?: Asset;
    isLegacyProject?: boolean;
};

export const uploadAssetContent = function({
    accountId,
    projectName,
    type: assetType,
    name,
    file,
    newAsset,
    cb,
    progress,
    asset,
    isLegacyProject
}: UploadAssetContentParams): (dispatch, state, services) => void {
    return async (dispatch, getState, services: ThunkServices) => {
        try {
            uploadingProjectAsset();
            dispatch(uploadingAssetStart(file.name));

            if (asset && asset.origin === RecordingOrigin.Creative) {
                await createCreativeRecording(services, dispatch, { accountId, projectName, file, asset, cb });
                return;
            }

            const state = getState();
            let dynamoShapeAsset: ConvertedGqlAsset | Asset | ConvertedGqlRecordingAsset;

            let fileMimeType = await AssetUtils.getFileType(file);
            if (isLegacyProject && fileTypesExcludeFromParse.includes(file.type)) {
                fileMimeType = file.type;
            }

            // client side white list validation of explicit fileTypes (i.e. ["image/png", "image/jpg", "video/x-flv", ...])
            AssetUtils.validateFileType(fileMimeType, undefined, isLegacyProject);

            // client side size limit validation
            AssetUtils.validateSizeLimitExceeded(fileMimeType, file.size);

            // GQL
            if (shouldUseGql(assetType, state, projectName)) {
                let assetFragment: GqlClientAssetFragment | GqlClientNarrationRecordingFragment;
                if (newAsset) {
                    assetFragment = await createAssetWithVersion(services, state, { projectName, file, name, fileMimeType });
                    dynamoShapeAsset = convertPostgresAssetToDynamoAsset(assetFragment);
                }
                else {
                    const gqlStateAsset: ConvertedGqlAsset | ConvertedGqlRecordingAsset = StateReaderUtils.getAsset(state, accountId, projectName, assetType, name);
                    const isEditor = StateReaderUtils.isEditorProgram(state, projectName);
                    dynamoShapeAsset = await createAssetVersion(services, { accountId, projectName, isEditor, asset: gqlStateAsset, file, fileMimeType });
                }
                progress && progress(100);
            }
            // REST
            else {
                dynamoShapeAsset = await services.projectAssetsServices.uploadAssetContent(accountId, projectName, assetType, fileMimeType, name, file, newAsset, "content", undefined, progress);
            }

            dispatch(uploadingProjectAssetsSuccess(dynamoShapeAsset, projectName, assetType, undefined));

            if (cb instanceof Function) {
                cb();
            }
        }
        catch (err) {
            dispatch(uploadingProjectAssetsError(file.name));
            dispatch(reportError(err));
        }
    };
};

const createCreativeRecording = async (services, dispatch, { accountId, projectName, file, asset, cb }): Promise<void> => {
    const fileType: string = await AssetUtils.getFileType(file);
    const newCreativeNarrationRecordingVersionVars: GqlClientCreateCreativeNarrationRecordingVersionMutationVariables = {
        input: {
            creativeNarrationRecordingId: asset.assetId,
            file,
            filename: asset.filename,
            fileType: fileType
        }
    };

    const {
        data: { createCreativeNarrationRecordingVersion }
    } = await services.graphQlClient.mutate({
        mutation: CreateCreativeNarrationRecordingVersionDocument,
        variables: newCreativeNarrationRecordingVersionVars
    });

    const { result, creativeNarrationRecordingVersion } = createCreativeNarrationRecordingVersion as GqlClientCreateCreativeNarrationRecordingVersionOutput;

    if (result === GqlClientCreateCreativeNarrationRecordingVersionResult.SUCCESS) {
        dispatch(
            uploadingCreativeNarrationRecordingVersionSuccess({
                creativeNarrationRecordingVersion,
                projectName
            })
        );
        dispatch(recalculateLogicValidations(accountId, projectName));
        if (cb instanceof Function) {
            cb();
        }
    }
    else {
        dispatch(uploadingProjectAssetsError(file.name));
    }
};

const createAssetWithVersion = async (services, state, { projectName, file, name, fileMimeType }): Promise<GqlClientAssetFragment> => {
    const variables: GqlClientCreateAssetWithVersionMutationVariables = {
        input: {
            programVersionId: StateReaderUtils.getBuilderProgramAndDraftVersionIds(state, projectName).programVersionId,
            name,
            file,
            filename: file.name,
            fileType: fileMimeType,
            ...initLegacyData()
        }
    };

    const {
        data: {
            createAssetWithVersion: { asset }
        }
    }: FetchResult<GqlClientCreateAssetWithVersionMutation> = await services.graphQlClient.mutate({ mutation: CreateAssetWithVersionDocument, variables });

    return asset;
};

const createAssetVersion = async (services: ThunkServices, { accountId, projectName, isEditor, asset, file, fileMimeType }): Promise<ConvertedGqlAsset | ConvertedGqlRecordingAsset> => {
    switch (asset.type) {
        case AssetTypes.curated: {
            const result = await createCuratedAssetVersion(services, { assetId: asset.id, file, fileMimeType });
            return convertPostgresAssetToDynamoAsset(result);
        }
        case AssetTypes.recording: {
            const result = await createRecordingAssetVersion(services, { assetId: asset.graphQLId, file, fileMimeType });
            return buildNarrationRecordingFromGql(accountId, projectName, result, isEditor);
        }
    }
};

const createCuratedAssetVersion = async (services: ThunkServices, { assetId, file, fileMimeType }): Promise<GqlClientAssetFragment> => {
    const variables: GqlClientCreateAssetVersionMutationVariables = {
        input: {
            assetId,
            file,
            filename: file.name,
            fileType: fileMimeType
        }
    };

    const {
        data: {
            createAssetVersion: {
                assetVersion: { asset }
            }
        }
    }: FetchResult<GqlClientCreateAssetVersionMutation> = await services.graphQlClient.mutate({ mutation: CreateAssetVersionDocument, variables });

    return asset;
};

const createRecordingAssetVersion = async (services: ThunkServices, { assetId, file, fileMimeType }): Promise<GqlClientNarrationRecordingFragment> => {
    const variables: GqlClientCreateNarrationRecordingVersionMutationVariables = {
        input: {
            narrationRecordingId: assetId,
            file,
            fileType: fileMimeType
        }
    };

    const mutationResult = await services.graphQlClient.mutate<GqlClientCreateNarrationRecordingVersionMutation>({ mutation: CreateNarrationRecordingVersionDocument, variables });

    return mutationResult.data.createNarrationRecordingVersion.narrationRecordingVersion.narrationRecording;
};

export const clearUploadingList = function() {
    return (dispatch) => {
        dispatch(clearingUploadingList());
    };
};

export const uploadRawRecordingFile = function(accountId, projectName, file, recordingAssets) {
    return (dispatch, state, services) => {
        let sceneName = file.name.split(".")[0];
        let programRFR = StateReaderUtils.getProject(state(), projectName).RFR;
        let assets = recordingAssets.filter((asset) => asset.narrationMetadata && asset.narrationMetadata.sceneName === sceneName && asset.RFR >= programRFR);
        let phrasesAssets = assets.reduce((acc, asset) => {
            acc[asset.narrationMetadata.text] = { name: asset.name, filename: asset.filename };
            return acc;
        }, {});
        const promise = services.projectAssetsServices.uploadRawRecordingFile(accountId, projectName, file, phrasesAssets);
        return HandleRawRecording
            .handleRawRecordingUpload(promise, (result) => result && dispatch(uploadRawRecordingProgress(projectName, result)))
            .then(() => {
                dispatch(uploadRawRecordingFinish(projectName));
                return services.projectAssetsServices.getAllAssets(accountId, projectName, AssetTypes.recording);
            })
            .then((assets) => {
                dispatch(loadingProjectAssetsSuccess(projectName, assets, false));
            })
            .catch((err) => {
                dispatch(reportError(err));
            });
    };
};

// in use only in legacy programs
export const uploadAssetTextContent = (accountId, projectName, assetType, assetName, textContent, newAsset, cb) => {
    return async (dispatch, state, services) => {
        try {
            uploadingProjectAsset();

            const res = await services.projectAssetsServices.uploadTextAssetContent(accountId, projectName, assetName, textContent, newAsset);

            dispatch(uploadingProjectAssetsSuccess(res, projectName, assetType, undefined));

            if (cb instanceof Function) {
                cb();
            }
        }
        catch (err) {
            dispatch(reportError(err));
        }
    };
};

// uses to upload mapping table data
export const uploadAssetJsonContent = function(accountId, projectName, assetType, assetName, jsonContent, type, newAsset, cb) {
    return (dispatch, state, services) => {
        uploadingProjectAsset();
        services.projectAssetsServices
            .uploadJsonAssetContent(accountId, projectName, assetName, jsonContent, type, newAsset)
            .then((res) => {
                dispatch(uploadingProjectAssetsSuccess(res, projectName, assetType, undefined));
                if (cb instanceof Function) {
                    cb(res);
                }
            })
            .catch((err) => {
                dispatch(reportError(err));
            });
    };
};

export const createDataTable = function(projectName: string, name: string, data: DataTableBody) {
    return (dispatch, getState, services: ThunkServices) => {
        const { programVersionId } = StateReaderUtils.getBuilderProgramAndDraftVersionIds(getState(), projectName);
        const variables: GqlClientCreateDataTableMutationVariables = {
            input: {
                programVersionId,
                name,
                data: data as GqlClientDataTableBody
            }
        };

        return services.graphQlClient.mutate<GqlClientCreateDataTableMutation>({
            mutation: CreateDataTableDocument,
            variables
        }).then(result => {
            const gqlDataTable: GqlClientDataTableFragment = result.data.createDataTable.dataTable;
            const dataTable: DataTable = buildDataTableFromGql(gqlDataTable);

            dispatch(uploadingProjectAssetsSuccess(dataTable, projectName, AssetTypes.mappingTable, undefined));
        });
    };
};

export const updateDataTableMutation = (services: ThunkServices, dataTableId: string, dataTableUpdates: Partial<DataTable>, dataTableBody?: DataTableBody) => {
    const variables: GqlClientUpdateDataTableMutationVariables = {
        input: {
            id: dataTableId,
            name: dataTableUpdates.title,
            description: dataTableUpdates.description,
            updated: IGNORE_UPDATED,
            data: dataTableBody
        }
    };

    return services.graphQlClient.mutate<GqlClientUpdateDataTableMutation>({
        mutation: UpdateDataTableDocument,
        variables
    });
};

export const updateDataTable = function(projectName: string, dataTableId: string, dataTableUpdates: Partial<DataTable>, dataTableBody?: DataTableBody) {
    return (dispatch, getState, services: ThunkServices) => {
        return updateDataTableMutation(services, dataTableId, dataTableUpdates, dataTableBody).then(result => {
            if (result.data.updateDataTable.__typename === "UpdateDataTableOutputSuccess") {
                const gqlDataTable: GqlClientDataTableFragment = result.data.updateDataTable.dataTable;
                const dataTable: DataTable = buildDataTableFromGql(gqlDataTable);

                dispatch(uploadingProjectAssetsSuccess(dataTable, projectName, AssetTypes.mappingTable, undefined));
            }
        });
    };
};

// export const deleteDataTable = function(projectName: string, dataTableId: string) {
//     return (dispatch, getState, services: ThunkServices) => {
//         const variables: GqlClientDeleteDataTableMutationVariables = {
//             input: {
//                 id: dataTableId
//             }
//         };
//
//         return services.graphQlClient.mutate<GqlClientDeleteDataTableMutation>({
//             mutation: UpdateDataTableDocument,
//             variables
//         }).then(() => {
//             dispatch(deletingProjectAssetsSuccess(dataTable, projectName, AssetTypes.mappingTable, undefined));
//         });
//     };
// };

export const createAssetRequest = (accountId: string, projectName: string, assetType: AssetTypes, assetName: string, assetMediaType: MediaTypes, newAsset: boolean, cb, desc: string) => {
    return async (dispatch, getState, services) => {
        try {
            uploadingProjectAsset();

            const state = getState();
            let dynamoShapeAsset: ConvertedGqlAsset | Asset;
            // GQL
            if (shouldUseGql(assetType, state, projectName)) {
                const variables: GqlClientCreateAssetRequestMutationVariables = {
                    input: {
                        programVersionId: StateReaderUtils.getBuilderProgramAndDraftVersionIds(state, projectName).programVersionId,
                        name: assetName,
                        type: (assetMediaType as any) as GqlClientAssetType,
                        description: desc,
                        ...initLegacyData()
                    }
                };

                const {
                    data: {
                        createAssetRequest: { asset }
                    }
                }: FetchResult<GqlClientCreateAssetRequestMutation> = await services.graphQlClient.mutate({ mutation: CreateAssetRequestDocument, variables });

                dynamoShapeAsset = convertPostgresAssetToDynamoAsset(asset);
            }
            // REST
            else {
                dynamoShapeAsset = await services.projectAssetsServices.makeAssetRequest(accountId, projectName, assetName, assetMediaType, newAsset, desc);
            }

            dispatch(uploadingProjectAssetsSuccess(dynamoShapeAsset, projectName, assetType, undefined));
            dispatch(recalculateLogicValidations(accountId, projectName));

            if (cb instanceof Function) {
                cb();
            }
        }
        catch (err) {
            dispatch(reportError(err));
        }
    };
};

// uses for uploading narrators of old narrations
export const uploadAsset = function(accountId: string, projectName: string, type: string, name: string, location: string, newAsset, cb) {
    return (dispatch, getState, services) => {
        dispatch(uploadingProjectAsset());
        const isProgramNarratorsDynamoMigrationDone: boolean = StateReaderUtils.isProgramBulkDynamoMigrationDoneByLegacyId(getState(), projectName, BulkId.Narrators);
        if (newAsset) {
            const assetMetadata: AddAssetMetadata = {
                programVersionId: StateReaderUtils.getBuilderProgramAndDraftVersionIds(getState(), projectName).programVersionId,
                isProgramNarratorsDynamoMigrationDone
            };
            services.projectAssetsServices
                .addAssetWaitComplete(accountId, projectName, type, name, location, assetMetadata)
                .then((asset) => {
                    asset = isProgramNarratorsDynamoMigrationDone ? buildNarratorFromGql(accountId, projectName, asset) as Narrator : asset as Narrator;
                    dispatch(uploadingProjectAssetsSuccess(asset, projectName, type, undefined));
                    dispatch(recalculateLogicValidations(accountId, projectName));
                    if (cb instanceof Function) {
                        cb();
                    }
                })
                .catch((err) => {
                    dispatch(reportError(err));
                });
        }
        else {
            const assetMetadata: UpdatedAssetMetadata = {
                gqlAssetId: StateReaderUtils.getAsset(getState(), accountId, projectName, AssetTypes.narrator, name)?.graphQLId,
                assetUpdated: IGNORE_UPDATED,
                isProgramNarratorsDynamoMigrationDone
            };
            services.projectAssetsServices
                .updateAssetWaitComplete(accountId, projectName, type, name, { type: type, name: name, location: location }, assetMetadata)
                .then((asset) => {
                    asset = isProgramNarratorsDynamoMigrationDone ? buildNarratorFromGql(accountId, projectName, asset) as Narrator : asset as Narrator;
                    dispatch(uploadingProjectAssetsSuccess(asset, projectName, type, undefined));
                    if (cb instanceof Function) {
                        cb();
                    }
                })
                .catch((err) => {
                    dispatch(reportError(err));
                });
        }
    };
};

export const updateExistingAsset = (
    accountId: string,
    projectName: string,
    assetType: AssetTypes,
    name: string,
    assetUpdatedData: AssetUpdatedData,
    returnWithHistory: boolean,
    cb?,
    assetData?: RecordingAsset
) => {
    return async (dispatch, getState, services) => {
        try {
            dispatch(uploadingProjectAsset());

            if (assetData && assetData.origin === RecordingOrigin.Creative) {
                await updateCreativeRecording(services, dispatch, { projectName, asset: assetUpdatedData, assetData });
                return;
            }

            const state = getState();
            let dynamoShapeAsset: ConvertedGqlAsset | Asset | WithGraphQLId<AnimationItem>;

            // GQL
            if (shouldUseGql(assetType, state, projectName)) {
                const gqlStateAsset = StateReaderUtils.getAsset(state, accountId, projectName, assetType, name);
                if (assetType === AssetTypes.curated) {
                    const assetFragment: GqlClientAssetFragment = await updateAssetWithGql(services, assetUpdatedData, gqlStateAsset as ConvertedGqlAsset);
                    dynamoShapeAsset = convertPostgresAssetToDynamoAsset(assetFragment);
                }
                else if (assetType === AssetTypes.animation) {
                    const animationFragment: GqlClientAnimationFragment = await updateAnimationWithGql(services, assetUpdatedData, gqlStateAsset as WithGraphQLId<AnimationItem>);
                    dynamoShapeAsset = convertPostgresAnimationToDynamoAnimation(animationFragment);
                }
                else if (assetType === AssetTypes.recording) {
                    const recordingFragment: GqlClientNarrationRecording = await updateRecordingWithGql(services, assetUpdatedData, gqlStateAsset as WithGraphQLId<RecordingAsset>);
                    const isEditor = StateReaderUtils.isEditorProgram(state, projectName);
                    dynamoShapeAsset = buildNarrationRecordingFromGql(accountId, projectName, recordingFragment as GqlClientNarrationRecordingFragment, isEditor);
                }
            }
            // REST
            else {
                dynamoShapeAsset = await services.projectAssetsServices.updateAsset(accountId, projectName, assetType, name, assetUpdatedData, returnWithHistory);
            }

            dispatch(uploadingProjectAssetsSuccess(dynamoShapeAsset, projectName, assetType, name));
            dispatch(recalculateLogicValidations(accountId, projectName));

            if (cb instanceof Function) {
                cb();
            }
        }
        catch (err) {
            dispatch(reportError(err));
        }
    };
};

const updateCreativeRecording = async (services, dispatch, { projectName, asset, assetData }): Promise<void> => {
    const updatedActiveVersionId = resolveActiveVersionId(assetData.history.Items, asset.activeVersion);
    const updateCreativeNarrationRecordingVars: GqlClientUpdateCreativeNarrationRecordingMutationVariables = {
        input: {
            id: assetData.assetId,
            updated: assetData.updated,
            activeCreativeNarrationRecordingVersionId: updatedActiveVersionId
        },
        dryRun: false
    };

    const {
        data: { updateCreativeNarrationRecording }
    } = await services.graphQlClient.mutate({
        mutation: UpdateCreativeNarrationRecordingDocument,
        variables: updateCreativeNarrationRecordingVars
    });

    const { result, creativeNarrationRecording } = updateCreativeNarrationRecording;

    if (result === GqlClientUpdateCreativeNarrationRecordingResult.SUCCESS) {
        dispatch(
            updatingCreativeRecordingSuccess({
                projectName,
                creativeNarrationRecording
            })
        );
    }
};

const updateAssetWithGql = async (services, assetUpdatedData: AssetUpdatedData, gqlStateAsset: ConvertedGqlAsset): Promise<GqlClientAssetFragment> => {
    const variables: GqlClientUpdateAssetMutationVariables = {
        input: {
            id: gqlStateAsset.id,
            updated: IGNORE_UPDATED, // currently ignoring case of CONFLICT
            ...convertAssetUpdatedDataToGqlInput(assetUpdatedData, gqlStateAsset)
        }
    };

    const {
        data: { updateAsset }
    }: FetchResult<GqlClientUpdateAssetMutation> = await services.graphQlClient.mutate({ mutation: UpdateAssetDocument, variables });

    if (updateAsset.result === GqlClientUpdateAssetResult.SUCCESS) {
        return (updateAsset as GqlClientUpdateAssetOutputSuccess).asset;
    }
};

const updateAnimationWithGql = async (services, animationUpdatedData: AnimationUpdatedData, gqlStateAnimation: WithGraphQLId<AnimationItem>): Promise<GqlClientAnimationFragment> => {
    let input: GqlClientUpdateAnimationInput = {
        id: gqlStateAnimation.graphQLId,
        updated: IGNORE_UPDATED // currently ignoring case of CONFLICT
    };
    if (animationUpdatedData.description !== undefined) {
        input.description = animationUpdatedData.description;
    }
    if (animationUpdatedData.activeVersion !== undefined) {
        const designatedActiveVersion: AnimationHistoryItem = gqlStateAnimation.history.Items.find((av: AnimationHistoryItem) => {
            return av.version === animationUpdatedData.activeVersion;
        });
        input.activeAnimationVersionId = (designatedActiveVersion as WithGraphQLId<AnimationHistoryItem>).graphQLId;
    }
    const variables: GqlClientUpdateAnimationMutationVariables = { input };

    const {
        data: { updateAnimation }
    }: FetchResult<GqlClientUpdateAnimationMutation> = await services.graphQlClient.mutate({ mutation: UpdateAnimationDocument, variables });

    if (updateAnimation.result === GqlClientUpdateAnimationResult.SUCCESS) {
        return (updateAnimation as GqlClientUpdateAnimationOutputSuccess).animation;
    }
};

const updateRecordingWithGql = async (
    services: ThunkServices,
    recordingUpdatedData: Pick<RecordingAsset, "activeVersion">,
    gqlStateRecording: WithGraphQLId<RecordingAsset>
): Promise<GqlClientNarrationRecording> => {
    const designatedActiveVersion: WithGraphQLId<any> = gqlStateRecording.history.Items.find((av: WithGraphQLId<any>) => {
        return av.version === recordingUpdatedData.activeVersion;
    });
    let input: GqlClientUpdateNarrationRecordingInput = {
        id: gqlStateRecording.graphQLId,
        activeNarrationRecordingVersionId: designatedActiveVersion.id,
        updated: IGNORE_UPDATED // currently ignoring case of CONFLICT
    };
    const variables: GqlClientUpdateNarrationRecordingMutationVariables = { input };

    const {
        data: { updateNarrationRecording }
    } = await services.graphQlClient.mutate<GqlClientUpdateNarrationRecordingMutation>({ mutation: UpdateNarrationRecordingDocument, variables });

    if (updateNarrationRecording.result === GqlClientUpdateNarrationRecordingResult.SUCCESS) {
        return (updateNarrationRecording as GqlClientUpdateNarrationRecordingOutputSuccess).narrationRecording;
    }
};

export const updateRecordingAssetStatus = function(accountId: string, projectName: string, assetName, version, statusUpdates, asset: RecordingAsset) {
    return (dispatch, getState, services) => {
        const isPostgresOnly: boolean = StateReaderUtils.isProgramBulkDynamoMigrationDoneByLegacyId(getState(), projectName, BulkId.MostEntities);

        if (asset && asset.origin === RecordingOrigin.Creative) {
            const updateCreativeNarrationRecordingVersionVars: GqlClientUpdateCreativeNarrationRecordingVersionMutationVariables = {
                input: {
                    id: asset.activeVersionId,
                    updated: asset.activeVersionUpdated,
                    status: statusUpdates.recordingStatus,
                    errorReason: getGqlNarrationRecordingErrorReason(statusUpdates.recordingErrorReason)
                },
                dryRun: false
            };

            services.graphQlClient
                .mutate({
                    mutation: UpdateCreativeNarrationRecordingVersionDocument,
                    variables: updateCreativeNarrationRecordingVersionVars
                })
                .then(({ data: { updateCreativeNarrationRecordingVersion } }) => {
                    const { result, creativeNarrationRecordingVersion } = updateCreativeNarrationRecordingVersion;
                    if (result === GqlClientUpdateCreativeNarrationRecordingVersionResult.SUCCESS) {
                        dispatch(
                            updatingCreativeRecordingStatusSuccess({
                                projectName,
                                creativeNarrationRecording: creativeNarrationRecordingVersion.creativeNarrationRecording
                            })
                        );
                    }
                })
                .catch((err) => {
                    dispatch(reportError(err));
                });
        }
        else {
            if (!isPostgresOnly) {
                services.projectAssetsServices
                    .updateRecordingAssetStatus(accountId, projectName, assetName, version, statusUpdates)
                    .then(() => {
                        dispatch(updatingRecordingStatusSuccess(projectName, assetName, version, statusUpdates));
                    })
                    .catch((err) => {
                        dispatch(reportError(err));
                    });
            }
            else {
                const updateNarrationRecordingVersionVars: GqlClientUpdateNarrationRecordingVersionMutationVariables = {
                    input: {
                        id: asset.activeVersionId,
                        updated: IGNORE_UPDATED,
                        status: statusUpdates.recordingStatus,
                        errorReason: getGqlNarrationRecordingErrorReason(statusUpdates.recordingErrorReason),
                        narrationRecordingId: asset.graphQLId
                    },
                    dryRun: false
                };

                services.graphQlClient
                    .mutate({
                        mutation: UpdateNarrationRecordingVersionDocument,
                        variables: updateNarrationRecordingVersionVars
                    })
                    .then((gqlOutput: FetchResult<GqlClientUpdateNarrationRecordingVersionMutation>) => {
                        if (gqlOutput.data.updateNarrationRecordingVersion.__typename === "UpdateNarrationRecordingVersionOutputSuccess") {
                            dispatch(updatingRecordingStatusSuccess(projectName, assetName, version, statusUpdates));
                        }
                    })
                    .catch((err) => {});
            }
        }
    };
};

export const loadAsset = (accountId: string, projectName: string, assetType: AssetTypes, assetName: string) => {
    return async (dispatch, getState, services) => {
        try {
            const state = getState();
            let dynamoShapeAsset: ConvertedGqlAsset | Asset | WithGraphQLId<AnimationItem>;

            // GQL
            if (shouldUseGql(assetType, state, projectName)) {
                if (assetType === AssetTypes.curated) {
                    const { id: assetId }: ConvertedGqlAsset = StateReaderUtils.getAsset(state, accountId, projectName, AssetTypes.curated, assetName);
                    const [programId, programVersionId] = assetId.split(GQL_ID_SEPARATOR);

                    const variables: GqlClientGetAssetVersionsQueryVariables = {
                        programId,
                        programVersionId: `${programId}${GQL_ID_SEPARATOR}${programVersionId}`,
                        assetId
                    };

                    const {
                        data: {
                            program: {
                                programVersion: { asset }
                            }
                        }
                    }: FetchResult<GqlClientGetAssetVersionsQuery> = await services.graphQlClient.query({ query: GetAssetVersionsDocument, variables });

                    dynamoShapeAsset = convertPostgresAssetToDynamoAsset(asset);
                }
                else if (assetType === AssetTypes.animation) {
                    const { graphQLId: animationId }: WithGraphQLId<AnimationItem> = StateReaderUtils.getAsset(state, accountId, projectName, AssetTypes.animation, assetName);
                    const [programId, programVersionId] = animationId.split(GQL_ID_SEPARATOR);

                    const variables: GqlClientGetAnimationVersionsQueryVariables = {
                        programId,
                        programVersionId: `${programId}${GQL_ID_SEPARATOR}${programVersionId}`,
                        animationId
                    };

                    const {
                        data: {
                            program: {
                                programVersion: { animation }
                            }
                        }
                    }: FetchResult<GqlClientGetAnimationVersionsQuery> = await services.graphQlClient.query({ query: GetAnimationVersionsDocument, variables });

                    dynamoShapeAsset = convertPostgresAnimationToDynamoAnimation(animation);
                }
                else if (assetType === AssetTypes.recording) {
                    const { graphQLId: narrationRecordingId }: WithGraphQLId<RecordingAsset> = StateReaderUtils.getAsset(state, accountId, projectName, AssetTypes.recording, assetName);
                    const [programId, programVersionId] = narrationRecordingId.split(GQL_ID_SEPARATOR);

                    const variables: GqlClientGetNarrationRecordingVersionsQueryVariables = {
                        programId,
                        programVersionId: `${programId}${GQL_ID_SEPARATOR}${programVersionId}`,
                        narrationRecordingId
                    };

                    const {
                        data: {
                            program: {
                                programVersion: { narrationRecording }
                            }
                        }
                    }: FetchResult<GqlClientGetNarrationRecordingVersionsQuery> = await services.graphQlClient.query({ query: GetNarrationRecordingVersionsDocument, variables });

                    const isEditor = StateReaderUtils.isEditorProgram(state, projectName);
                    dynamoShapeAsset = buildNarrationRecordingFromGql(accountId, projectName, narrationRecording, isEditor);
                }
            }
            // REST
            else {
                dynamoShapeAsset = await services.projectAssetsServices.getAsset(accountId, projectName, assetType, assetName, true);
            }

            dispatch(updateAsset(projectName, dynamoShapeAsset));
        }
        catch (err) {
            dispatch(reportError(err));
        }
    };
};

export const deleteAsset = (accountId: string, projectName: string, assetType: AssetTypes, assetName: string, callBack?: (...args: any) => void) => {
    return async (dispatch, getState, services: ThunkServices) => {
        try {
            dispatch(loadingAssetContent());
            const state = getState();
            let dynamoShapeAsset: ConvertedGqlAsset | Asset;
            // GQL
            // Note: when 'assetType === AssetTypes.mappingTable' this thunk actually deletes the record from Dynamo, and not updates the asset
            if (shouldUseGql(assetType, state, projectName)) {
                const gqlStateAsset: ConvertedGqlAsset = StateReaderUtils.getAsset(state, accountId, projectName, AssetTypes.curated, assetName);
                const assetFragment: GqlClientAssetFragment = await updateAssetWithGql(services, { deleted: true } as any, gqlStateAsset);
                dynamoShapeAsset = convertPostgresAssetToDynamoAsset(assetFragment);
            }
            // REST
            else {
                dynamoShapeAsset = await services.projectAssetsServices.deleteAsset(accountId, projectName, assetType, assetName);
            }

            dispatch(deletingProjectAssetsSuccess(dynamoShapeAsset, projectName));

            callBack && callBack(dynamoShapeAsset.assetId);
        }
        catch (err) {
            dispatch(reportError(err));
        }
    };
};

// uses to load text assets content for legacy programs
export const loadAssetContent = function(accountId, projectName, assetType, assetName, assetVersion, cb) {
    return (dispatch, state, services) => {
        dispatch(loadingAssetContent());
        services.projectAssetsServices
            .getAsset(accountId, projectName, assetType, assetName, false)
            .then((asset) => {
                const version = assetVersion || asset.version;
                const promiseArray = [asset, services.projectAssetsServices.getAssetContent(accountId, projectName, assetType, assetName, version)];
                return Promise.all(promiseArray);
            })
            .then(([asset, content]) => {
                asset.content = content;
                dispatch(updateAsset(projectName, asset));
                if (cb instanceof Function) {
                    cb();
                }
            })
            .catch((err) => {
                dispatch(reportError(err));
            });
    };
};

const shouldUseGql = (assetType: AssetTypes, state, projectName: string): boolean => {
    if (assetType === AssetTypes.curated) {
        return StateReaderUtils.isProgramBulkDynamoMigrationDoneByLegacyId(state, projectName, BulkId.Assets);
    }
    if (assetType === AssetTypes.animation) {
        return StateReaderUtils.isProgramBulkDynamoMigrationDoneByLegacyId(state, projectName, BulkId.Animations);
    }
    if (assetType === AssetTypes.recording) {
        return StateReaderUtils.isProgramBulkDynamoMigrationDoneByLegacyId(state, projectName, BulkId.MostEntities);
    }
    return false;
};
