import { RecordStatus } from "../entities/definitions";
import type { DiffFunc, DiffResult } from "./diffManager";

export interface IDiffTableRecord {
    diff: DiffFunc;
    diffResult: DiffResult;
    status: RecordStatus;
}

export default class DiffTableRecord implements IDiffTableRecord {
    public readonly diff: DiffFunc;
    diffResult: DiffResult;
    status: RecordStatus;

    constructor(diffFunc: DiffFunc) {
        this.diff = diffFunc;
        this.diffResult = null;
        this.status = RecordStatus.PENDING;
    }
}
