import Immutable from "immutable";
import { LOGIC_TYPE } from "../../../vlx/consts";
import { listCandidateNames } from "../../../builder/editorsContainerLogic/prioritizedListUtils";
import { DATA_ELEMENT_VALUE_EMPTY } from "../../../../components/legacyCommon/Consts";
export const PRIORITIZED_LIST_VALUE_SET_SOURCES = Object.freeze({
    CandidateNames: "candidateNames",
    NumberOfValidSlots: "numberOfValidSlots"
});
export const createPrioritizedListCandidatesValueSetAdapter = (prioritizedList, slotName) => {
    const getSource = () => {
        return PRIORITIZED_LIST_VALUE_SET_SOURCES.CandidateNames;
    };
    const getId = () => {
        return `${prioritizedList.id}^${slotName}`;
    };
    const getName = () => {
        return `${prioritizedList.name} - ${slotName}`;
    };
    const getType = () => {
        return LOGIC_TYPE.DataElementString; // TODO better to use LOGIC_MEDIA_TYPES.String or to match dataElements?
    };
    const getValueSet = () => {
        const candidateNames = listCandidateNames(prioritizedList);
        // append empty value for cases where no candidate was selected in the slot
        return Immutable.List(candidateNames).withMutations((mutableValueSet) => {
            mutableValueSet.push({ id: "_vs_empty", dn: DATA_ELEMENT_VALUE_EMPTY });
        });
    };
    return {
        getSource,
        getId,
        getName,
        getType,
        getValueSet
    };
};
export const createPrioritizedListValidSlotsValueSetAdapter = (prioritizedList) => {
    const name = "# of valid slots";
    const getSource = () => {
        return PRIORITIZED_LIST_VALUE_SET_SOURCES.NumberOfValidSlots;
    };
    const getId = () => {
        return `${prioritizedList.id}^${name}`;
    };
    const getName = () => {
        return `${prioritizedList.name} - ${name}`;
    };
    const getType = () => {
        return LOGIC_TYPE.DataElementNumber; // TODO better to use LOGIC_MEDIA_TYPES.String or to match dataElements?
    };
    const getValueSet = () => {
        // for a prioritized list with slots A..C, we have 4 slots (maxSlots = 4).
        // add one in order to create and selection between 0 and 4.
        const slotNumbers = Array.from({ length: prioritizedList.maxSlots + 1 }).map((_, idx) => ({ id: `validSlotNum${idx}`, dn: `${idx}` }));
        return Immutable.List(slotNumbers);
    };
    return {
        getSource,
        getId,
        getName,
        getType,
        getValueSet
    };
};
export const prioritizedListPredicate = (usedValueSetSource, usedValueSetId, prioritizedListId) => usedValueSetId.includes(prioritizedListId) &&
    (usedValueSetSource === PRIORITIZED_LIST_VALUE_SET_SOURCES.CandidateNames || usedValueSetSource === PRIORITIZED_LIST_VALUE_SET_SOURCES.NumberOfValidSlots);
