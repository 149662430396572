import { isFeatureEnabled } from "../../../components/newNav/nooks/featureFlags";
import type { LogReportContent } from "../../../../common/types/commonTypes";
import { ReportEventType } from "../../../../common/types/commonTypes";
import { getReduxState } from "../../../components/store";
import { getService } from "../../../components/services";
import type CommonServices from "../commonServices";
import { featureFlagConst } from "@sundaysky/smartvideo-hub-config";
import { getLogReportContent } from "../logReportUtils";

export enum RecorderLoggingEventType {
    ErrorInOnStopFlow = "ErrorInOnStopFlow",
    PreviewDialogOnSaveImpossibleState = "PreviewDialogOnSaveImpossibleState",
    GetVideoDimensionsError = "GetVideoDimensionsError",
    FailedToRecord = "FailedToRecord",
    FailedToChangeCameraOrMic = "FailedToChangeCameraOrMic",
    CameraPermissionsDenied = "CameraPermissionsDenied",
    MicPermissionsDenied = "MicPermissionsDenied",
    RecordingFailedToStart = "RecordingFailedToStart",
    RecordingFailedToInitialize = "RecordingFailedToInitialize",
    FailedToFetchMediaDevices = "FailedToFetchMediaDevices",
    FailedToOpenPipWindow = "FailedToOpenPipWindow",
    FailedToGetMediaStream = "FailedToGetMediaStream",
    UploadRecordingFailed = "UploadRecordingFailed"
}

export type RecorderLoggingData = {
    recorderEventType: RecorderLoggingEventType;
    metadata?: any;
}

export const reportRecorderError = (e: Error | ErrorEvent, recorderData: RecorderLoggingData): void => {
    let errorDetails;
    // Build error data
    if (e instanceof ErrorEvent) {
        errorDetails = {
            message: e.message,
            name: e.error?.name,
            stack: e.error?.stack
        };
    }
    else {
        errorDetails = {
            message: e.message,
            name: e.name,
            stack: e.stack
        };
    }

    // Get feature flag values
    const featureFlags = {
        ff_EDITOR_RECORDING_TOOL: isFeatureEnabled(featureFlagConst._EDITOR_RECORDING_TOOL),
        ff_EDITOR_RECORDER_TOOL_SKIP_PREVIEW_DIALOG: isFeatureEnabled(featureFlagConst._EDITOR_RECORDER_TOOL_SKIP_PREVIEW_DIALOG),
        ff_EDITOR_ENCODER_RECORDER: isFeatureEnabled(featureFlagConst._EDITOR_ENCODER_RECORDER)
    };
    const data: LogReportContent = getLogReportContent(e.message, { ...recorderData,
        errorDetails,
        featureFlags
    }, ReportEventType.RecorderError, getReduxState());
    getService<CommonServices>("commonServices").reportLog(data);
};

