import type { AnyEntitiesByContextById, AnyEntityContextPair } from "./baseEntity";
import { BaseEntity } from "./baseEntity";
import type { Collector, CollectorArgs, Context, EntityInstance, EntityTypes, IEntity } from "./definitions";
import { ASSET_TYPES } from "../vlx/consts";
import type { DiffResult } from "../versionDiff/diffManager";
import { ChangeType } from "../versionDiff/diffManager";
import type { Asset, RecordingAsset } from "../../../common/types/asset";
import StateReaderUtils from "../common/StateReaderUtils";
import type { GetValidationResultFunction, ValidationResult } from "../validations/validationManager";
import { diffEntities } from "../versionDiff/diffUtils";
import AssetUtils from "../common/assetUtils";

export type AssetDoValidateParams = {
    asset: Asset;
    getValidationResult: GetValidationResultFunction;
    context: Context;
};

export type AssetDoDiffParams = {
    id: string | undefined;
    previousAsset: Asset | undefined;
    currentAsset: Asset | undefined;
    versionView: boolean;
    currentProjectRFR: number | undefined;
    previousProjectRFR: number | undefined;
};

type NormalizedAssetObject = {
    assetId: string;
    title: string;
    activeVersion: number;
};

/**
 * This is a base class for MediaEntity, AnimationEntity, RecordingEntity & DataTableEntity, which all share the same collector.
 * The derived classes can override the doDiff/doValidate methods written in this class.
 * **/
export default class AssetEntity extends BaseEntity implements IEntity {
    assetType: string;

    constructor(type: EntityTypes, assetType: string) {
        super(type);
        this.assetType = assetType;
    }

    getAssetId = (asset: Asset) => asset.name;

    collector: Collector = (args: CollectorArgs): EntityInstance[] => {
        const { context, previousContext } = args;
        let assets: EntityInstance[] = [];

        const project = StateReaderUtils.getProject(context.state, context.projectName);

        const previousProjectRFR: number =
            previousContext &&
            this.assetType === ASSET_TYPES.recording &&
            StateReaderUtils.getProgramRFR(previousContext.state, previousContext.projectName, previousContext.stage, previousContext.version);

        const currentProjectRFR: number = this.assetType === ASSET_TYPES.recording && StateReaderUtils.getProgramRFR(context.state, context.projectName, context.stage, context.version);

        const previousAssets: Asset[] =
            previousContext && StateReaderUtils.getAssetsInVersionMode(project, previousContext.state.assets, previousContext.state.snapshots, previousContext.version, this.assetType);
        let currentAssets: Asset[] = StateReaderUtils.getAssetsFromSnapshotOrDraft(project, context.state.assets, context.state.snapshots, undefined, context.version, this.assetType);

        if (!previousContext && this.assetType === ASSET_TYPES.recording) {
            currentAssets = currentAssets.filter((asset: RecordingAsset) => asset.RFR >= currentProjectRFR);
        }

        const assetsMap: AnyEntitiesByContextById = this.entityListsToMap(previousAssets, currentAssets, this.getAssetId);

        assetsMap.forEach((entityContextPair: AnyEntityContextPair, key: string) => {
            const { previousEntity: previousAsset, currentEntity: currentAsset } = entityContextPair;
            assets.push({
                id: key,
                validate: (prevValidationResult: ValidationResult, getValidationResultFunction: GetValidationResultFunction): ValidationResult => {
                    return this.doValidate({
                        asset: currentAsset,
                        getValidationResult: getValidationResultFunction,
                        context
                    });
                },
                diff: (): DiffResult => {
                    return this.doDiff({
                        id: key,
                        previousAsset,
                        currentAsset,
                        versionView: Boolean(context.version), // returns false if current context is draft
                        currentProjectRFR,
                        previousProjectRFR
                    });
                }
            });
        });

        return assets;
    };

    /** None of the derived classes share the same doValidate logic,
     * so this is an "abstract" method meant to be overridden by the derived classes, if need **/
    doValidate = ({}: AssetDoValidateParams): ValidationResult => {
        return;
    };

    /** Base doDiff logic for asset entities.
     * Derived classes that have a different doDiff logic can override this method. **/
    doDiff = ({ id, previousAsset, currentAsset, versionView }: AssetDoDiffParams): DiffResult => {
        const normalizedPreviousAsset: NormalizedAssetObject = this.normalizeAssetObject(previousAsset, true);
        const normalizedCurrentAsset: NormalizedAssetObject = this.normalizeAssetObject(currentAsset, versionView);
        const changeType: ChangeType = diffEntities(normalizedPreviousAsset, normalizedCurrentAsset);

        return {
            type: this.getType(),
            id,
            name: this.getPropsFromPreviousOrCurrent(normalizedPreviousAsset, normalizedCurrentAsset, "title"),
            changeType: changeType,
            changes: [],
            isShown: changeType !== ChangeType.None
        };
    };

    // 'location' field was removed because if 'activeVersion' field changes, 'location' will be changed too. And vice versa.
    // So they are two indicators for the same change.
    // Also, GQL assets has no 'location' field, so opening the GQL feature flag will show a diff for all assets.
    private normalizeAssetObject = (asset: Asset, versionView: boolean): NormalizedAssetObject => {
        if (!asset) {
            return;
        }

        return {
            assetId: asset.assetId,
            title: asset.title, // not working - stage asset doesn't include title and it's currently not versioned.
            activeVersion: AssetUtils.getVersion(asset, versionView)
        };
    };
}
