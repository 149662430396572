import type { PromptToVideoInput } from "@sundaysky/ai-common-types";
import type { ChatEventHandler } from "@sundaysky/chatbot-client";
import { ChatbotClient } from "@sundaysky/chatbot-client";
import type { AuthServiceInterface } from "@sundaysky/smartvideo-hub-auth";
import { activeAccountIdVar } from "../../components/model";
import { addTokenHeadersAndFullStory } from "./BaseServices";


type GenAIImagePromptInput = PromptToVideoInput & {
    useVoiceOver: boolean;
};

export interface IAIImageGenPromptChatService {
    sendMessage(input: GenAIImagePromptInput, eventHandler: ChatEventHandler): Promise<void>;
}

export class AIImageGenPromptChatService {
    constructor(
        private readonly authService: AuthServiceInterface
    ) {}

    public async sendMessage(
        input: GenAIImagePromptInput,
        eventHandler: ChatEventHandler
    ): Promise<void> {
        const accountId = activeAccountIdVar();
        const token = await this.authService.getUserAccessToken();
        const headers = addTokenHeadersAndFullStory({}, token);
        const client = new ChatbotClient({
            endpoint: `/rest/${accountId}/aiImageGenPromptChat`,
            eventHandler
        });
        await client.prompt(input, headers as Record<string, string>);
    }
}

// Singleton
let aiImageGenPromptChatServiceInstance: IAIImageGenPromptChatService;
export function initAIImageGenPromptChatService(authService: AuthServiceInterface): void {
    aiImageGenPromptChatServiceInstance = new AIImageGenPromptChatService(authService);
}
export function getAIImageGenPromptChatService(): IAIImageGenPromptChatService {
    return aiImageGenPromptChatServiceInstance;
}
