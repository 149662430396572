import type { GqlClientAudienceValue, GqlClientAudienceValueSupportersFragment } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { AudienceValueSupportersFragmentDoc } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import type { ApolloClient } from "@apollo/client";
import type { Roles } from "../../types";
import type { CardData } from "../../../../components/framework/EntityCard";
import { getPersonalizationDescriptionOrUsedInScenes, getPersonalizationIconsForDescription, getRole, initCardData } from "../../utils";

const getSupporters = (audienceValue: GqlClientAudienceValue, client: ApolloClient<any>): string[] => {
    /** supporters for audience value:
     * parent audience
     * scenes related to the value
     * kpis that are related to those scenes
     * parent goals of those kpis
     * stories related to those scenes
     */
    let supporterIds = [];
    const { audience } = audienceValue;
    supporterIds.push(audience.id);
    audienceValue.audiencePersonalizationValues.forEach(({ audiencePersonalization }) => {
        const { scene } = audiencePersonalization;
        supporterIds.push(scene.id);
        const sceneData: GqlClientAudienceValueSupportersFragment = client.readFragment({
            id: `${scene.__typename}:${scene.id}`,
            fragment: AudienceValueSupportersFragmentDoc
        });
        sceneData.kpis.forEach(({ id, goal }) => {
            supporterIds.push(id);
            supporterIds.push(goal.id);
        });
        sceneData.stories.forEach(({ id }) => supporterIds.push(id));
    });
    audience.feedDataElements?.forEach(({ id }) => supporterIds.push(id));
    return supporterIds;
};

const getCardData = (audienceValue: GqlClientAudienceValue, roles: Roles): CardData => {
    let audienceValueData: CardData = initCardData(audienceValue);
    audienceValueData.header.volume = audienceValue.volume ? `${audienceValue.volume}%` : "";

    if (roles) {
        audienceValueData.role = getRole(audienceValue, roles);
        if (audienceValue.audiencePersonalizationValues.length) {
            const uniquePersonalizationTypes: Set<string> = new Set([]);
            audienceValue.audiencePersonalizationValues.forEach((persValue) => persValue.audiencePersonalization.type.forEach((type) => uniquePersonalizationTypes.add(type)));
            uniquePersonalizationTypes.size &&
                audienceValueData.contents.push({ title: "Content variations:", descriptionIcons: getPersonalizationIconsForDescription(Array.from(uniquePersonalizationTypes)) });

            // overrides the unused AudiencePersonalization description with the wanted AudiencePersoanlizationValue description
            const audiencePersonalizations = audienceValue.audiencePersonalizationValues.map(({ audiencePersonalization, description }) => ({ ...audiencePersonalization, description }));
            audienceValueData.contents.push(getPersonalizationDescriptionOrUsedInScenes(audiencePersonalizations, roles.leadId));
        }
    }
    return audienceValueData;
};

const AudienceValue = {
    getSupporters,
    getCardData
};

export default AudienceValue;
