import type { AxiosRequestConfig, AxiosResponse, CancelTokenSource, AxiosError } from "axios";
import axios from "axios";

export type ProgressCallback = (percent: number) => void

/**
 * Instead of using axios timeout for the entire request, abort request only if no data have been received yet
 */
export const axiosGetWithTtfbTimeout = <T = unknown, R = AxiosResponse<T>, D = any>(
    url: string,
    timeToFirstByteTimeout: number,
    config: AxiosRequestConfig<D> = {},
    callback?: ProgressCallback
): Promise<R> => {
    const source: CancelTokenSource = axios.CancelToken.source();
    let firstByteArrived = false;

    const axiosPromise: Promise<R> = axios.get<T, R, D>(url, {
        ...config,
        onDownloadProgress: (progressEvent) => {
            const loaded: number = progressEvent?.loaded;
            const total: number = progressEvent?.total;

            // Check first byte arrived
            if (loaded > 0) {
                firstByteArrived = true;
            }

            // Update progress via callback
            if (typeof callback === "function" && total) {
                callback(100 * loaded / total);
            }

            // Call passed onDownloadProgress
            if (typeof config.onDownloadProgress === "function") {
                config.onDownloadProgress(progressEvent);
            }
        },
        cancelToken: source.token
    });

    // Abort if no bytes received after timeout
    setTimeout(() => {
        if (!firstByteArrived) {
            source.cancel(`First byte not downloaded after ${timeToFirstByteTimeout} milliseconds`);
        }
    }, timeToFirstByteTimeout);

    return axiosPromise;
};

export const isAxiosError = (e: unknown): e is AxiosError => !!e["isAxiosError"];
