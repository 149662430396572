import {
    ADD_PROJECT_SUCCESS,
    DELETE_PROJECT_COMPLETE,
    GENERATE_REC_DOC_SUCCESS,
    GET_PROJECT_VERSIONS_COMPLETE,
    LOAD_PROJECT_SUMMARIES_SUCCESS,
    LOADING_ALL_PROJECTS_SUCCESS,
    LOADING_GQL_PROGRAM_SUCCESS,
    LOADING_NEW_PROGRAMS_SUCCESS,
    LOADING_PROJECT_DETAILS_SUCCESS,
    LOADING_REC_DOC_SUCCESS,
    UPDATE_PROGRAM_LOGIC_ITEMS,
    UPDATE_PROGRAM_SUMMARY_SUCCESS,
    UPDATE_PROJECT_COMPLETE
} from "./projectsActions";
import type { ProgramSummary } from "../../../common/types/program";
import { NotificationCopyToAnotherAccount, ProgramType } from "../../../common/types/program";
import { LogicContainers } from "../../../common/commonConst";
import StateReaderUtils from "../common/StateReaderUtils";
import { findProgramSummaryIndexForProgramId } from "../../components/main/mainContainerUtils";
import { immutablyReplaceValue } from "../../../common/generalUtils";
import type { Snapshot } from "../../../common/types/snapshot";

function projectsReducer(state, action) {
    let projectAllNames;
    let projectsByName;

    switch (action.type) {
        case LOADING_ALL_PROJECTS_SUCCESS: {
            let byName = action.payload.projects.reduce((acc, cur) => {
                acc[cur.projectName] = cur;
                return acc;
            }, {});
            let allNames = action.payload.projects.reduce((acc, cur) => {
                acc.push(cur.projectName);
                return acc;
            }, []);
            return Object.assign({}, state, {
                projects: { byName: byName, allNames: allNames, isInitialized: true },
                error: false
            });
        }
        case UPDATE_PROGRAM_SUMMARY_SUCCESS: {
            const { programId, updatedFields } = action.payload;
            const currProgramSummaries: ProgramSummary[] = StateReaderUtils.getProgramSummaries(state);
            let updatedProgramSummaries = [...currProgramSummaries];
            const programSummeryIndex: number = findProgramSummaryIndexForProgramId(currProgramSummaries, programId);
            if (programSummeryIndex > -1) {
                const currProgramSummary: ProgramSummary = currProgramSummaries[programSummeryIndex];
                updatedProgramSummaries.splice(programSummeryIndex, 1, { ...currProgramSummary, ...updatedFields });
            }
            return {
                ...state,
                projectSummaries: updatedProgramSummaries
            };
        }
        case ADD_PROJECT_SUCCESS: {
            projectsByName = state.projects.byName;
            projectsByName = {
                ...projectsByName,
                [action.payload.projectName]: action.payload
            };

            projectAllNames = [...state.projects.allNames, action.payload.projectName];
            const showCampaignSettings: boolean = action.payload.programType === ProgramType.Ads && StateReaderUtils.isAllowedCampaignSetting(state);
            const showReports: boolean = action.payload.programType !== ProgramType.Ads && StateReaderUtils.isAllowedDetailedReport(state);
            const showExperiments: boolean = action.payload.programType !== ProgramType.Ads && StateReaderUtils.isAllowedExperiments(state);
            const isEditor: boolean = action.payload.isEditor;

            let programSummary: ProgramSummary = {
                id: action.payload.graphQLId,
                displayName: action.payload.displayName,
                isLegacy: action.payload.isLegacy || false,
                programType: action.payload.programType,
                showCreative: false,
                campaignSettings: showCampaignSettings,
                showReports,
                showExperiments,
                isArchive: false,
                isEditor: isEditor,
                projectIds: {
                    builders: action.payload.projectName,
                    dashboards: undefined,
                    canvas: undefined,
                    sfId: action.payload.programId,
                    builderDraftVersionId: action.payload.graphQLBuilderDraftId
                },
                reasonsCopyToOtherAccountIsForbidden: [],
                notificationCopyToAnotherAccount: NotificationCopyToAnotherAccount.None
            };

            let projectSummaries: ProgramSummary[] = [...state.projectSummaries, programSummary];
            projectSummaries = projectSummaries.sort((projectA, projectB) => projectA.displayName.localeCompare(projectB.displayName));

            return {
                ...state,
                projects: { byName: projectsByName, allNames: projectAllNames, isInitialized: true },
                projectSummaries: projectSummaries,
                error: false
            };
        }
        case DELETE_PROJECT_COMPLETE:
            return Object.assign({}, state, {
                projects: [...state.projects.slice(0, action.payload.index), ...state.projects.slice(action.payload.index + 1)],
                error: false
            });
        case UPDATE_PROJECT_COMPLETE: {
            const project = action.payload.project;
            projectsByName = {
                ...state.projects.byName,
                [project.projectName]: {
                    ...state.projects.byName[project.projectName],
                    ...project
                }
            };
            projectAllNames = state.projects.allNames;
            return {
                ...state,
                projects: { byName: projectsByName, allNames: projectAllNames, isInitialized: true },
                error: false
            };
        }
        case LOADING_PROJECT_DETAILS_SUCCESS:
            projectAllNames = state.projects.allNames.indexOf(action.payload.project.projectName) < 0 ? [...state.projects.allNames, action.payload.project.projectName] : state.projects.allNames;

            projectsByName = {
                ...state.projects.byName,
                [action.payload.project.projectName]: {
                    ...state.projects.byName[action.payload.project.projectName],
                    ...action.payload.project
                }
            };

            return Object.assign({}, state, {
                projects: { byName: projectsByName, allNames: projectAllNames, isInitialized: true },
                error: false
            });
        case LOADING_NEW_PROGRAMS_SUCCESS:
            return Object.assign({}, state, {
                newPrograms: action.payload.newPrograms
            });
        case GET_PROJECT_VERSIONS_COMPLETE: {
            const snapshots: Snapshot[] = action.payload.versions;
            const projectName: string = action.payload.projectName;

            let newState = immutablyReplaceValue(state, ["versions"], snapshots);

            const snapshotsById = state.snapshots?.byId || {};
            const allIds = snapshots.map(snapshot => `${projectName}/${snapshot.snapshotNumber}`);
            const byId = snapshots.reduce((acc, snapshot, index) => {
                const snapshotId = allIds[index];
                acc[snapshotId] = snapshotsById[snapshotId]?.assets ? { ...snapshot, assets: snapshotsById[snapshotId].assets } : snapshot;
                return acc;
            }, {});

            newState = immutablyReplaceValue(newState, ["snapshots", "allIds"], allIds);
            newState = immutablyReplaceValue(newState, ["snapshots", "byId"], byId);

            return newState;
        }

        case LOAD_PROJECT_SUMMARIES_SUCCESS: {
            let sortedProjects: ProgramSummary[] = action.payload.projectSummaries.sort((projectA, projectB) => projectA.displayName.localeCompare(projectB.displayName));
            return {
                ...state,
                projectSummaries: sortedProjects
            };
        }
        case LOADING_REC_DOC_SUCCESS: {
            let recordingDocs = [];
            if (action.payload && action.payload.docs && action.payload.docs && action.payload.docs.length > 0) {
                recordingDocs = [...action.payload.docs];
            }
            return Object.assign({}, state, {
                recordingDocs: recordingDocs
            });
        }
        case GENERATE_REC_DOC_SUCCESS: {
            let recordingDocs = state.recordingDocs ? [...state.recordingDocs] : [];
            if (action.payload && action.payload.recordingDoc) {
                let recDoc = { ...action.payload.recordingDoc };
                recordingDocs.push(recDoc);
            }
            return Object.assign({}, state, {
                recordingDocs: recordingDocs
            });
        }
        case UPDATE_PROGRAM_LOGIC_ITEMS: {
            let { projectName, data } = action.payload;
            let { logicContainerType, logicItems, updated } = data;
            let byName = state.wireframes.byName;
            let allNames = state.wireframes.allNames;
            let currentLogic = byName[projectName] ? byName[projectName].logic : {};
            let currentProgramLogic = currentLogic[LogicContainers.Program] || {};

            projectsByName = state.projects.byName;

            if (updated) {
                projectsByName = {
                    ...projectsByName,
                    [projectName]: {
                        ...projectsByName[projectName],
                        graphQLUpdated: updated
                    }
                };
            }

            return {
                ...state,
                projects: {
                    allNames: state.projects.allNames,
                    byName: projectsByName
                },
                wireframes: {
                    byName: {
                        ...byName,
                        [projectName]: {
                            ...byName[projectName],
                            logic: {
                                ...currentLogic,
                                [LogicContainers.Program]: {
                                    ...currentProgramLogic,
                                    [logicContainerType]: {
                                        ...currentProgramLogic[logicContainerType],
                                        ...logicItems
                                    }
                                }
                            }
                        }
                    },
                    allNames: allNames
                },
                error: false
            };
        }
        case LOADING_GQL_PROGRAM_SUCCESS: {
            const { program } = action.payload;
            return {
                ...state,
                activeGqlProgram: program
            };
        }
        default:
            return state;
    }
}
export default projectsReducer;
