import type { AnyEntitiesByContextById, AnyEntity, AnyEntityById, AnyEntityContextPair, GetDependencyIdFunction } from "./baseEntity";
import { BaseEntity } from "./baseEntity";
import type { Collector, CollectorArgs, Context, EntityInstance, IEntity } from "./definitions";
import { EntityTypes } from "./definitions";
import type { GetValidationResultFunction, Issue, ValidationResult } from "../validations/validationManager";
import { IssueCodes } from "../validations/validationManager";
import StateReaderUtils from "../common/StateReaderUtils";
import type { ChangeDetail, DiffResult, GetDiffResultFunc } from "../versionDiff/diffManager";
import { ChangeType } from "../versionDiff/diffManager";
import { diffEntities, updateDiffResultWithChanges } from "../versionDiff/diffUtils";

export default class GroupEntity extends BaseEntity implements IEntity {
    constructor() {
        super(EntityTypes.GROUP);
    }

    collector: Collector = (args: CollectorArgs): EntityInstance[] => {
        const { context, previousContext } = args;

        let allLogicObjects: EntityInstance[] = [];
        if (!previousContext) {
            allLogicObjects = this.validationCollector(context);
        }
        else {
            allLogicObjects = this.diffCollector(previousContext, context);
        }
        return allLogicObjects;
    };

    validationCollector: Collector = (context: Context): EntityInstance[] => {
        let groups: EntityInstance[] = [];
        let allScenes = StateReaderUtils.getProjectWireframeScenes(context.state, context.projectName, context.stage, context.version);
        if (allScenes) {
            Object.keys(allScenes).forEach((scene) => {
                allScenes[scene].sceneParts &&
                    allScenes[scene].sceneParts.forEach((scenePart) => {
                        scenePart.groups &&
                            scenePart.groups.forEach((group) => {
                                groups.push({
                                    id: group.id,
                                    validate: (prevValidationResult: ValidationResult, getValidationResultFunction: GetValidationResultFunction): ValidationResult => {
                                        return this.doValidate(group, allScenes[scene].id, getValidationResultFunction);
                                    }
                                });
                            });
                    });
            });
        }
        return groups;
    };

    doValidate = (group: any, sceneId: string, getValidationResult: GetValidationResultFunction): ValidationResult => {
        const type: EntityTypes = this.getType();
        const id: string = `${group.id}_${sceneId}`;
        let groupValidationResult: ValidationResult = { type, id, issues: [], name: group.name };

        //check placeholders validity
        if (group) {
            const getDependencyId: GetDependencyIdFunction = () => id;
            let issue: Issue = this.getDependenciesIssue([undefined], EntityTypes.LOGIC, getDependencyId, getValidationResult, IssueCodes.GROUP_LOGIC_ERROR);
            if (issue) {
                groupValidationResult.issues.push(issue);
                groupValidationResult.severity = issue.severity;
            }
        }

        return groupValidationResult;
    };

    getGroupsFromWireframes = (context: Context): AnyEntity[] => {
        const scenes: AnyEntityById = StateReaderUtils.getProjectWireframeScenes(context.state, context.projectName, context.stage, context.version);
        let groups: AnyEntity = [];
        Object.values(scenes).forEach((scene) => {
            scene.sceneParts &&
                scene.sceneParts.forEach((scenePart) => {
                    scenePart.groups &&
                        scenePart.groups.forEach((group) => {
                            groups.push({ id: group.id, name: group.name });
                        });
                });
        });
        return groups;
    };

    diffCollector = (previousContext: Context, currentContext: Context): EntityInstance[] => {
        let currentGroups: AnyEntity[] = this.getGroupsFromWireframes(currentContext);
        let previousGroups: AnyEntity[] = this.getGroupsFromWireframes(previousContext);
        let groups: EntityInstance[] = [];

        const groupsMap: AnyEntitiesByContextById = this.entityListsToMap(previousGroups, currentGroups, (groupObj) => groupObj.id);
        groupsMap.forEach((groupsPair: AnyEntityContextPair, groupId: string) => {
            groups.push({
                id: groupId,
                diff: (getDiffResult: GetDiffResultFunc): DiffResult => {
                    return this.doDiff(getDiffResult, groupsPair.currentEntity, groupsPair.previousEntity, groupId);
                }
            });
        });
        return groups;
    };

    doDiff = (getDiffResult: GetDiffResultFunc, currentGroup: any, previousGroup: any, groupId: string): DiffResult => {
        let result: DiffResult = {
            type: this.getType(),
            id: groupId,
            name: (currentGroup && currentGroup.name) || previousGroup.name,
            changeType: ChangeType.None,
            changes: [],
            isShown: false
        };

        result.changeType = diffEntities(previousGroup, currentGroup);
        if (result.changeType === ChangeType.None) {
            const change: ChangeDetail = this.getDependenciesDiff([groupId], EntityTypes.LOGIC, (id) => id, getDiffResult, true);
            return updateDiffResultWithChanges(result, change);
        }
        else {
            result.isShown = true;
            return result;
        }
    };
}
