import type { GetDependencyIdFunction } from "./baseEntity";
import StateReaderUtils from "../common/StateReaderUtils";
import { EntityTypes } from "./definitions";
import type { Issue, ValidationResult } from "../validations/validationManager";
import { IssueCodes } from "../validations/validationManager";
import { ASSET_TYPES } from "../vlx/consts";
import { findDataElementByName } from "../DataElements/DataElementsManager";
import type { AssetDoDiffParams, AssetDoValidateParams } from "./assetEntity";
import AssetEntity from "./assetEntity";
import type { DataTable, DataTableInput } from "../../../common/types/dataTable";
import type { DiffResult } from "../versionDiff/diffManager";
import { ChangeType } from "../versionDiff/diffManager";
import { diffEntities } from "../versionDiff/diffUtils";
import AssetUtils from "../common/assetUtils";

type NormalizedDataTableObject = Pick<DataTable, "assetId" | "location" | "title">

export default class DataTableEntity extends AssetEntity {
    constructor() {
        super(EntityTypes.DATA_TABLE, ASSET_TYPES.mappingTable);
    }

    doDiff = ({ id, previousAsset, currentAsset, versionView }: AssetDoDiffParams): DiffResult => {
        const normalizedPreviousAsset: NormalizedDataTableObject = this.normalizeDataTableObject(previousAsset as DataTable, true);
        const normalizedCurrentAsset: NormalizedDataTableObject = this.normalizeDataTableObject(currentAsset as DataTable, versionView);
        const changeType: ChangeType = diffEntities(normalizedPreviousAsset, normalizedCurrentAsset);

        return {
            type: this.getType(),
            id,
            name: this.getPropsFromPreviousOrCurrent(normalizedPreviousAsset, normalizedCurrentAsset, "title"),
            changeType: changeType,
            changes: [],
            isShown: changeType !== ChangeType.None
        };
    };

    /** override AssetEntity's doValidate method **/
    doValidate = ({ asset, getValidationResult, context }: AssetDoValidateParams): ValidationResult => {
        const mappingTable: DataTable = asset as DataTable;
        const type: EntityTypes = this.getType();
        const id: string = mappingTable.name;
        let mappingTableValidationResult: ValidationResult = { type, id, issues: [] };

        // check mapping table scheme, data elements inputs. Ignoring HubSystem:Story -> https://sundaysky.atlassian.net/browse/HB-5564
        const inputs: DataTableInput[] = mappingTable.mappingTableScheme.inputs.filter((input) => input.id !== "HubSystem:Story");
        const getDependencyId: GetDependencyIdFunction = (input) => {
            if (input.id) {
                return input.id;
            }
            // Backward compatibility
            let allDataElements = StateReaderUtils.getProjectDataElements(context.state, context.projectName, context.stage, context.version);
            let dataElement = findDataElementByName(input.name, allDataElements);
            return dataElement && dataElement.id;
        };
        let issue: Issue = this.getDependenciesIssue(inputs, EntityTypes.DATA_ELEMENT, getDependencyId, getValidationResult, IssueCodes.DATA_TABLE_SCHEME_ERROR);
        if (issue) {
            mappingTableValidationResult.issues.push(issue);
            mappingTableValidationResult.severity = issue.severity;
        }

        // todo - yoav - check mapping table values. Do we want to fetch values every time? Do we want to put all data in redux just to be able to validate?

        return mappingTableValidationResult;
    };

    private normalizeDataTableObject = (dataTable: DataTable, versionView: boolean): NormalizedDataTableObject => {
        if (!dataTable) {
            return;
        }

        return {
            assetId: dataTable.assetId,
            title: dataTable.title, // not working - stage asset doesn't include title and it's currently not versioned.
            location: AssetUtils.getLocation(dataTable, versionView)
        };
    };
}
