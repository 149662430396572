import type { ValidateFunction } from "../validations/validationManager";
import type { DiffFunc } from "../versionDiff/diffManager";

export type Context = { state: any; projectName: string; stage?: string; version?: number | "draft" };
export type CollectorArgs = {
    context: Context;
    previousContext?: Context;
};
export type EntityInstance = { id: string; validate?: ValidateFunction; diff?: DiffFunc };
export type Collector = (CollectorArgs) => EntityInstance[];

export enum EntityTypes {
    ANIMATION = "Animation",
    ASSET = "Asset",
    ANALYTICS = "Analytics",
    DATA_ELEMENT = "DataElement",
    CREATIVE_DATA_ELEMENT = "ContentSetData",
    DATA_TABLE = "DataTable",
    DERIVED_DATA_ELEMENT = "StudioDataElement",
    GROUP = "Group",
    MEDIA = "Media",
    NARRATION = "Narration",
    PARAMETER = "Parameter",
    PLACEHOLDER = "Placeholder",
    PRIORITIZED_LIST = "List",
    PRIORITIZED_LIST_CANDIDATE = "PrioritizedListCandidate",
    PROGRAM = "Program",
    RECORDING = "Recording",
    SCENE = "Scene",
    SCENE_VALIDATION = "SceneValidation",
    SCENE_ANIMATION = "SceneAnimation",
    SCENE_PART = "ScenePart",
    LOGIC = "Logic",
    STORY = "Story",
    OVERVIEW = "Overview",
    SETTINGS = "Settings"
}

export interface IEntity {
    getType: () => EntityTypes;
    collector: Collector;
}

export enum RecordStatus {
    PENDING = "PENDING",
    CALCULATING = "CALCULATING",
    DONE = "DONE"
}
