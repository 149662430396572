import { PollingHandler } from "../../common/pollingHandler";
import type { NarrationUpload } from "../../../../common/narrationUploadTypes";
import type { PollingTaskCreationResponse } from "../../../../common/types/polling";

export class HandleRawRecording {
    static handleRawRecordingUpload(promise: Promise<PollingTaskCreationResponse>, statusHandler: (result: NarrationUploadStatusI) => void): Promise<any> {
        return PollingHandler.waitForTaskToComplete(promise, (progress: number, result: any) => {
            let uploadStatus: NarrationUploadStatusI = NarrationUploadStatus.fromObject(result);
            statusHandler(uploadStatus);
        });
    }
}

export interface NarrationUploadStatusI {
    getStage: () => NarrationUpload.NarrationUploadStage;
    getProgress: () => number;
    getFilename: () => string;
    getTranscript: () => string;
    getConfidence: () => number;
    getSentences: () => NarrationUpload.NarrationSentenceData[];
}

export class NarrationUploadStatus implements NarrationUploadStatusI {
    private readonly _stage: NarrationUpload.NarrationUploadStage;
    private readonly _progress?: number;
    private readonly _filename: string;
    private readonly _transcript?: string;
    private readonly _confidence?: number;
    private readonly _sentences?: NarrationUpload.NarrationSentenceData[];

    constructor(stage: NarrationUpload.NarrationUploadStage, filename: string, progress?: number, transcript?: string, confidence?: number, sentences?: NarrationUpload.NarrationSentenceData[]) {
        this._stage = stage;
        this._progress = progress;
        this._filename = filename;
        this._transcript = transcript;
        this._confidence = confidence;
        this._sentences = sentences;
    }

    static fromObject = (obj): NarrationUploadStatusI => {
        return obj ? new NarrationUploadStatus(obj.stage, obj.filename, obj.progress, obj.transcript, obj.confidence, obj.sentences) : undefined;
    };

    getStage = (): NarrationUpload.NarrationUploadStage => this._stage;
    getStageNumber = (): number => {
        switch (this._stage) {
            case "UploadingToServer":
                return 1;
            case "UploadingToS3":
                return 2;
            case "Transcribe":
                return 3;
            case "Matching":
                return 4;
            case "CuttingAndUploadingRecordings":
                return 5;
            default:
                return 0;
        }
    };
    getProgress = (): number => this._progress;
    getFilename = (): string => this._filename;
    getTranscript = (): string => this._transcript;
    getConfidence = (): number => this._confidence;
    getSentences = (): NarrationUpload.NarrationSentenceData[] => (this._sentences ? [...this._sentences] : []);
}
