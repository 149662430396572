import { LOGIC_DATA_TYPES, LOGIC_MEDIA_TYPES } from "./consts";
import traverse from "traverse";
import { DERIVED } from "../../components/legacyCommon/Consts";
import { getMappingTableNameFromValue } from "./editorLogicUtils";
import { getValueFromActionableData } from "../Logics/ActionableDataUtils";
export function createJsonHierarchyForVar(json, variable, value) {
    variable
        .toString()
        .split(".")
        .reduce((acc, jsonNode, index, array) => {
        if (!acc[jsonNode]) {
            acc[jsonNode] = {};
        }
        if (index === array.length - 1) {
            acc[jsonNode] = value;
        }
        return acc[jsonNode];
    }, json);
    return json;
}
/**
 * Parse a string value to the value of a relevant mediaType
 * @param strValue
 * @param mediaType
 */
export function parseStringToRelevantMediaType(strValue, mediaType) {
    switch (mediaType) {
        case LOGIC_MEDIA_TYPES.Number:
            return Number(strValue);
        case LOGIC_MEDIA_TYPES.Boolean: {
            if (strValue === "true") {
                return true;
            }
            else if (strValue === "false") {
                return false;
            }
            return undefined;
        }
        default:
            return strValue;
    }
}
/**
 * Gets a logic data, and returns a map of objects according to type -> list of scene ids in which it is used
 * @param logicData logic object, where the first layer must be scene ids
 * @type the type of the objects to be extracted
 */
export function extractScenesByType(logicData, type) {
    let elementToScenesMap = traverse(logicData).reduce(function (acc, node) {
        if (node && node.type === type) {
            let dataElementName;
            if (type === LOGIC_DATA_TYPES.MappingTable) {
                dataElementName = getMappingTableNameFromValue(node);
            }
            else {
                dataElementName = getValueFromActionableData(node);
            }
            acc[dataElementName] = acc[dataElementName] || {};
            let sceneId = this.path[0];
            if (sceneId !== DERIVED) {
                acc[dataElementName][sceneId] = sceneId;
            }
        }
        return acc;
    }, {});
    for (let de in elementToScenesMap) {
        if (elementToScenesMap.hasOwnProperty(de)) {
            elementToScenesMap[de] = Object.keys(elementToScenesMap[de]);
        }
    }
    return elementToScenesMap;
}
