import type { GetDependencyIdFunction } from "./baseEntity";
import { BaseEntity } from "./baseEntity";
import StateReaderUtils from "../common/StateReaderUtils";
import type { GetValidationResultFunction, Issue, ValidationResult } from "../validations/validationManager";
import ValidationManager, { IssueCodes, IssueSeverity } from "../validations/validationManager";
import type { Collector, CollectorArgs, IEntity, EntityInstance } from "./definitions";
import { EntityTypes } from "./definitions";
import HubEditorUtils from "../../components/HubEditor/HubEditorsUtils";
import type { Rule } from "../../../common/types/logic";
import { LogicJSON } from "../../../common/types/logic";
import type { PrioritizedList } from "../common/types";

export default class PrioritizedListCandidateEntity extends BaseEntity implements IEntity {
    constructor() {
        super(EntityTypes.PRIORITIZED_LIST_CANDIDATE);
    }

    collector: Collector = (args: CollectorArgs): EntityInstance[] => {
        const { context, previousContext } = args;
        let prioritizedListsCandidates: EntityInstance[] = [];
        const wireframes = StateReaderUtils.getWireFrame(context.state, context.projectName, context.stage, context.version);
        const scenes = StateReaderUtils.getProjectWireframeScenes(context.state, context.projectName, context.stage, context.version);

        if (scenes) {
            Object.keys(scenes).forEach((sceneId) => {
                let scenePrioritizedLists = StateReaderUtils.getScenePrioritizedList(wireframes, sceneId);
                scenePrioritizedLists.forEach((prioritizedList) => {
                    prioritizedList.rules.forEach((prioritizedListCandidate) => {
                        prioritizedListsCandidates.push({
                            id: `${prioritizedListCandidate.key}_${sceneId}`,
                            validate: (prevValidationResult: ValidationResult, getValidationResultFunction: GetValidationResultFunction): ValidationResult => {
                                return this.doValidate(prioritizedListCandidate, getValidationResultFunction, prioritizedList, sceneId);
                            }
                        });
                    });
                });
            });
        }
        return prioritizedListsCandidates;
    };

    doValidate = (prioritizedListCandidate: Rule, getValidationResult: GetValidationResultFunction, prioritizedList: PrioritizedList, sceneId: string): ValidationResult => {
        const type: EntityTypes = this.getType();
        const id: string = prioritizedListCandidate.key;
        let result: ValidationResult = { type, id, issues: [] };

        const name = prioritizedListCandidate.name;

        // check names validity
        if (!name || !HubEditorUtils.isAlphaNumeric(name)) {
            result.issues.push({
                code: name ? IssueCodes.PRIORITIZED_LIST_INVALID_CANDIDATE_NAME : IssueCodes.PRIORITIZED_LIST_EMPTY_CANDIDATE_NAME,
                severity: IssueSeverity.ERROR,
                details: {}
            });
            result.severity = IssueSeverity.ERROR;
        }

        // check candidate names duplicate
        let count = prioritizedList.rules.filter((candidate) => candidate.name === name).length;
        if (count > 1) {
            result.issues.push({
                code: IssueCodes.PRIORITIZED_LIST_DUPLICATE_CANDIDATE_NAME,
                severity: IssueSeverity.ERROR,
                details: {}
            });
            result.severity = IssueSeverity.ERROR;
        }

        // check candidates logic
        if (prioritizedListCandidate.key) {
            const getDependencyId: GetDependencyIdFunction = (prioritizedListCandidate) => `${prioritizedListCandidate.key}_${sceneId}`;
            let issue: Issue = this.getDependenciesIssue([prioritizedListCandidate], EntityTypes.LOGIC, getDependencyId, getValidationResult, IssueCodes.PRIORITIZED_LIST_LOGIC_ERROR);
            if (issue) {
                result.issues.push(issue);
                result.severity = ValidationManager.getHighestSeverity([result.severity, issue.severity]);
            }
        }

        return result;
    };
}
