import { EntityTypes } from "../entities/definitions";
import type { ChangeDetail, DiffResult } from "./diffManager";
import DiffManager, { ChangeType } from "./diffManager";
import { v4 as uuid } from "uuid";
import type { Diff } from "deep-diff";
import { diff } from "deep-diff";
import type { FilteredAndRemoved } from "../../../common/arrayUtils";
import { removeEmptyEntriesFromObject } from "../../../common/generalUtils";
import { getFilteredAndRemainingItems } from "../../../common/arrayUtils";
import type { IDiffTable } from "./diffTable";
import type { IDiffTableRecord } from "./diffTableRecord";

/** TYPES **/
export type DiffEntity = {
    id: string;
    name: string;
    changeType: ChangeType;
    entityType: EntityTypes;
    isOpen: boolean;
    changes: DiffEntity[];
};

export type DiffModel = DiffEntity[];

/** FUNCTIONS **/
export const buildDiffEntity = (diffResult: DiffResult): DiffEntity => {
    const { id, name, changeType, type, changes } = diffResult;
    return {
        id,
        name,
        changeType,
        entityType: type,
        isOpen: false,
        changes: parseDiffEntityChanges(changes)
    };
};

export const parseDiffEntityChanges = (changes: ChangeDetail[]): DiffEntity[] => {
    let entities: DiffEntity[] = [];
    let entitiesByHeader: { [header: string]: DiffEntity[] } = {};

    changes.forEach((change: ChangeDetail) => {
        change.children.forEach((result: DiffResult) => {
            if (result.isShown) {
                const diffEntity: DiffEntity = buildDiffEntity(result);
                const header: string = getDiffHeaderTitle(diffEntity);
                if (header) {
                    if (entitiesByHeader[header]) {
                        entitiesByHeader[header].push(diffEntity);
                    }
                    else {
                        entitiesByHeader[header] = [diffEntity];
                    }
                }
                else {
                    entities.push(diffEntity);
                }
            }
        });
    });

    Object.entries(entitiesByHeader).forEach(([header, diffEntityList]) => {
        entities.push({
            isOpen: false,
            id: `${header}-${uuid()}`,
            name: header,
            changeType: DiffManager.getChangeType(
                diffEntityList.map((diff) => diff.changeType),
                true
            ),
            entityType: null,
            changes: diffEntityList
        });
    });

    return entities;
};

export const diffEntities = (previousEntity: any, currentEntity: any): ChangeType => {
    if (!previousEntity && currentEntity) {
        return ChangeType.Add;
    }
    if (previousEntity && !currentEntity) {
        return ChangeType.Delete;
    }

    const previousReducedEntity = normalizeEntity(previousEntity);
    const currentReducedEntity = normalizeEntity(currentEntity);

    const diffResult: Diff<any, any>[] = diff(previousReducedEntity, currentReducedEntity); // keep diff result for debugging
    return diffResult ? ChangeType.Edit : ChangeType.None;
};

export const updateDiffResultWithChanges = (result: DiffResult, change: ChangeDetail, isShown: boolean = true): DiffResult => {
    if (change) {
        result.changes.push(change);
        result.changeType = change.changeType;
        result.isShown = isShown;
    }
    return result;
};

export const getDiffModel = (table: IDiffTable): DiffModel => {
    let diffModel: DiffModel = [];
    const allTableRecords: IDiffTableRecord[] = table.getAllRecords();

    allTableRecords.forEach((record: IDiffTableRecord) => {
        record.diffResult.type === EntityTypes.PROGRAM && diffModel.push(...parseDiffEntityChanges(record.diffResult.changes));
    });

    return addDiffHeadersToDiffModel(diffModel);
};

export const getDiffHeaderTitle = (entity: DiffEntity): string => {
    switch (entity.entityType) {
        case EntityTypes.SCENE:
            return "Scenes";
        case EntityTypes.STORY:
            return "Stories";
        case EntityTypes.DERIVED_DATA_ELEMENT:
            return "Studio Data";
        case EntityTypes.DATA_TABLE:
            return "Data Tables";
        case EntityTypes.DATA_ELEMENT:
            return "Data Elements";
        case EntityTypes.CREATIVE_DATA_ELEMENT:
            return "Content Set Data";
        case EntityTypes.MEDIA:
            return "Media";
        case EntityTypes.ANIMATION:
            return "Animation";
        case EntityTypes.RECORDING:
            return "Narration Library";
        case EntityTypes.ANALYTICS:
            return "Analytics";
        case EntityTypes.PLACEHOLDER:
        case EntityTypes.GROUP:
        case EntityTypes.PRIORITIZED_LIST:
            return "Placeholders";
        case EntityTypes.PARAMETER:
            return "Animations Guidelines";
        case EntityTypes.NARRATION:
            return "Narrations";
        default:
            return "";
    }
};

const composeDiffHeaderEntity = (model: DiffModel, header: string, names: string[]): DiffModel => {
    let index: number = model.findIndex((entity: DiffEntity) => names.includes(entity.name));
    if (index < 0) return model;

    let { filteredArray, remainingArray }: FilteredAndRemoved = getFilteredAndRemainingItems(model, names, (en) => en.name);

    // wrap filteredArray and insert back into remainingArray
    let wrappingDiffEntity: DiffEntity = model.find((entity) => entity.name === header);
    let isNewDiff: boolean = !wrappingDiffEntity;

    wrappingDiffEntity = wrappingDiffEntity || {
        isOpen: false,
        id: `${header}-${uuid()}`,
        name: header,
        changeType: undefined,
        entityType: null,
        changes: []
    };

    wrappingDiffEntity.changes = [...filteredArray, ...wrappingDiffEntity.changes];

    let changeTypes = [...wrappingDiffEntity.changes.map((diff) => diff.changeType)];
    wrappingDiffEntity.changeType = DiffManager.getChangeType(changeTypes, true);

    if (isNewDiff) {
        remainingArray.splice(index, 0, wrappingDiffEntity);
    }

    return remainingArray;
};

export const addDiffHeadersToDiffModel = (model: DiffModel): DiffModel => {
    // define names that will be further wrapped. ATM only for Data Library and Asset Library.
    const dataLibNames: string[] = ["Data Elements", "Studio Data", "Data Tables", "Content Set Data"];
    let newModel: DiffModel = composeDiffHeaderEntity(model, "Data Library", dataLibNames);

    newModel = composeDiffHeaderEntity(newModel, "All Stories", ["Story Selection"]);

    newModel = composeDiffHeaderEntity(newModel, "Stories", ["All Stories"]);

    const assetLibNames: string[] = ["Media", "Animation"];
    newModel = composeDiffHeaderEntity(newModel, "Asset Library", assetLibNames);

    return newModel;
};

export const getStoryPropertyCollectionId = (storyId: string, propertyName: string): string => {
    return `${storyId}_${propertyName}`;
};

export const getProgramPropertyCollectionId = (propertyName: string): string => {
    return `program_${propertyName}`;
};

export const exposeDiffResult = (diffResult: DiffResult, newName: string, newType: EntityTypes): DiffResult => {
    return {
        ...diffResult,
        name: newName,
        type: newType,
        isShown: true
    };
};

const removeVersionRelatedFields = (entity: any): Omit<any, "graphQLId" | "graphQLUpdated"> => {
    const copy = { ...entity };
    delete copy.graphQLId;
    delete copy.graphQLUpdated;
    return copy;
};

const normalizeEntity = (entity: any) : any => {
    let normalizedEntity = removeVersionRelatedFields(entity);
    normalizedEntity = removeEmptyEntriesFromObject(normalizedEntity);
    return normalizedEntity;
};

