import { CREATE_SHARE_URL_SUCCESS, GENERATE_MOVIE_SUCCESS, GET_ANALYTICS_SUCCESS, GET_PRESET_DATA_ELEMENTS_SUCCESS, GET_PROGRAM_DETAILS_SUCCESS, RESET_ANALYTICS, SECOND_UPLOAD_IMAGE, UPLOAD_IMAGE } from "./programOverviewActions";
function programOverviewReducer(state, action) {
    let allProgramsIds = state.dashboardPrograms.allIds;
    let programsById = state.dashboardPrograms.byId;
    switch (action.type) {
        case GENERATE_MOVIE_SUCCESS:
            return Object.assign({}, state, {
                [[action.payload.stateObjName]]: { sessionId: action.payload.sessionId },
                [[action.payload.stateShareUrlObjName]]: action.payload.shortUrl
            });
        case GET_PRESET_DATA_ELEMENTS_SUCCESS:
            return Object.assign({}, state, {
                [[action.payload.stateObjName]]: action.payload.presetDataElements
            });
        case GET_ANALYTICS_SUCCESS:
            return Object.assign({}, state, {
                analyticsEmbedContainerChartUrl: action.payload.chartUrl
            });
        case RESET_ANALYTICS:
            return Object.assign({}, state, {
                analyticsEmbedContainerChartUrl: action.payload.chartUrl
            });
        case GET_PROGRAM_DETAILS_SUCCESS:
            programsById[action.payload.programId].details = action.payload.programDetails;
            return Object.assign({}, state, {
                dashboardPrograms: { allIds: allProgramsIds, byId: programsById },
                selectedProgramId: action.payload.programId
            });
        case UPLOAD_IMAGE:
            return Object.assign({}, state, {
                imageUrl: action.payload.url,
                imageThumbnail: action.payload.thumbnail
            });
        case SECOND_UPLOAD_IMAGE:
            return Object.assign({}, state, {
                secondImageUrl: action.payload.url,
                secondImageThumbnail: action.payload.thumbnail
            });
        case CREATE_SHARE_URL_SUCCESS:
            return Object.assign({}, state, { shareUrl: action.payload.shareUrl });
        default:
            return state;
    }
}
export default programOverviewReducer;
