import type { IValidationTableRecord } from "./validationTableRecord";
import type { EntityTypes } from "../entities/definitions";

export interface IValidationTable {
    getById(id: string): IValidationTableRecord;
    putById(id: string, record: IValidationTableRecord): void;
    getAllRecords(): IValidationTableRecord[];
}

type ValidationTableType = { [id: string]: IValidationTableRecord };

export default class ValidationTable implements IValidationTable {
    private readonly table: ValidationTableType;

    constructor() {
        this.table = {};
    }

    getById = (id: string): IValidationTableRecord => {
        return this.table[id];
    };

    putById = (id: string, record: IValidationTableRecord): void => {
        this.table[id] = record;
    };

    getAllRecords = (): IValidationTableRecord[] => {
        return Object.values(this.table);
    };

    static getTableKey(type: EntityTypes, id: string): string {
        return type + "/" + id;
    }
}
