import { createAction } from "redux-actions";
import { reportError } from "../common/commonActions";
import { isSettingFeatureFlag } from "../admin/adminActions";
import { GetProgramsDynamoMigrationStatusDocument } from "../../graphql/graphqlGeneratedTypes/graphqlClient";
import type { ThunkServices } from "../common/types";
import queryString from "query-string";

// client only actions
export const ENABLING_ALL_FEATURE_FLAGS_SUCCESS = "ENABLING_ALL_FEATURE_FLAGS_SUCCESS";
export const enablingAllFeatureFlagsSuccess = createAction(ENABLING_ALL_FEATURE_FLAGS_SUCCESS, (enable) => {
    return { enable };
});
export const SET_FEATURE_FLAG_STATUS_IN_STATE = "SET_FEATURE_FLAG_STATUS_IN_STATE";
export const setFeatureFlagStatusInState = createAction(SET_FEATURE_FLAG_STATUS_IN_STATE, (featureFlagId, status) => {
    return { featureFlagId, status };
});
// call to server actions
export const LOADING_FEATURE_FLAGS_SUCCESS = "LOADING_FEATURE_FLAGS_SUCCESS";
export const loadingFeatureFlagsSuccess = createAction(LOADING_FEATURE_FLAGS_SUCCESS, (featureFlags) => {
    return { featureFlags };
});

export const LOADING_PROGRAMS_DYNAMO_MIGRATION_STATUS = "LOADING_PROGRAMS_DYNAMO_MIGRATION_STATUS";
export const loadingProgramsDynamoMigrationStatus = createAction(LOADING_PROGRAMS_DYNAMO_MIGRATION_STATUS, (programsDynamoMigrationStatus) => {
    return { programsDynamoMigrationStatus };
});

export const SET_FEATURE_FLAG_SUCCESS = "SET_FEATURE_FLAG_SUCCESS";
export const setFeatureFlagSuccess = createAction(SET_FEATURE_FLAG_SUCCESS, (featureFlag) => {
    return { featureFlag };
});

export const loadFeatureFlags = () => {
    return (dispatch, state, services: ThunkServices) => {
        let enableAllFeatureFlags = queryString.parse(window?.location?.search).enableAllFeatureFlags;
        if (enableAllFeatureFlags !== undefined) {
            // @ts-ignore - this is a node/typescript limitation, where type is taken from parent https://github.com/microsoft/TypeScript/issues/43094
            enableAllFeatureFlags = enableAllFeatureFlags === "true";
            dispatch(enablingAllFeatureFlagsSuccess(enableAllFeatureFlags));
        }
        else {
            services.featureFlags
                .getFeatureFlags()
                .then((res) => {
                    dispatch(loadingFeatureFlagsSuccess(res));
                })
                .catch((err) => {
                    dispatch(reportError(err));
                });
        }
    };
};

export const loadProgramsMigrationStatus = () => {
    return (dispatch, state, services) => {
        services.graphQlClient.query({
            fetchPolicy: "no-cache",
            query: GetProgramsDynamoMigrationStatusDocument
        }).then((res) => {
            dispatch(loadingProgramsDynamoMigrationStatus(res.data.programsDynamoMigrationStatus));
        });
    };
};
export const setFeatureFlag = (featureFlagId, defaultValue, excludedPrograms, excludedAccounts) => {
    return (dispatch, state, services) => {
        dispatch(isSettingFeatureFlag(true));
        services.featureFlags
            .setFeatureFlag(featureFlagId, defaultValue, excludedPrograms, excludedAccounts)
            .then((res) => {
                dispatch(setFeatureFlagSuccess(res));
                dispatch(isSettingFeatureFlag(false));
            })
            .catch((err) => {
                dispatch(reportError(err));
                dispatch(isSettingFeatureFlag(false));
            });
    };
};
