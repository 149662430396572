import {
    ADD_DATA_ELEMENT_SUCCESS,
    DELETE_DATA_ELEMENTS_SUCCESS,
    UPDATE_DATA_ELEMENT_SUCCESS
} from "./DataElementActions";
import { buildDataElement } from "./DataElementsManager";
import StateReaderUtils from "../common/StateReaderUtils";
import { LogicContainers } from "../../../common/commonConst";
import type { DataElement } from "../../../common/types/dataElement";
import { immutablyReplaceValue } from "../../../common/generalUtils";
import type { LoadingProgramWireframeSuccess } from "../projects/projectWireframes/projectsWireframesActions";
import { LOADING_PROGRAM_WIREFRAMES_SUCCESS } from "../projects/projectWireframes/projectsWireframesActions";

/***
 * Returens a merge state object with the data elements array of the given program
 * @param state - existing redux state object
 * @param programName - the name of the program
 * @param dataElementsArr - the data elements array to be merged upon the state
 * @param stage - stage
 * @param version - version
 * @return {{wireframes: {byName: {}, allNames: *}, error: boolean}} - The new merged state
 */
function reduceDataElementsArray(state, programName: string, dataElementsArr: DataElement[], stage: string, version: string) {
    const wireframesPath: string[] = StateReaderUtils.getWireframesPathOnState(programName, stage, version);
    wireframesPath.push("dataElements");

    let newState = immutablyReplaceValue(state, wireframesPath, dataElementsArr);
    newState.error = false;
    return newState;
}

function updateDataElementSuccess(state, args) {
    const { projectName, dataElement, logic } = args;

    const dataElementsArr = StateReaderUtils.getProjectDataElements(state, projectName) || [];

    const elementIndex = dataElementsArr.findIndex((de) => de.id === dataElement.id); // If not found, will return -1

    let newDataElementsArr = [];
    if (elementIndex < 0) {
        // If this is data element doesn't exist, push it in the end of the list (and create a new one)
        newDataElementsArr = dataElementsArr.concat([buildDataElement({ ...dataElement })]);
    }
    else {
        // Replace data element in the array with updated data
        newDataElementsArr = [...dataElementsArr.slice(0, elementIndex), buildDataElement({ ...dataElement }), ...dataElementsArr.slice(elementIndex + 1)];
    }

    let newState = reduceDataElementsArray(state, projectName, newDataElementsArr, undefined, undefined);

    const { byName } = newState.wireframes;
    const derivedLogic = byName[projectName].logic[LogicContainers.Derived];

    if (logic === undefined) {
        return newState;
    }
    else {
        let newDerivedLogic = { ...derivedLogic };

        if (logic) {
            newDerivedLogic[dataElement.id] = logic;
        }
        else {
            delete newDerivedLogic[dataElement.id];
        }

        return {
            ...newState,
            wireframes: {
                ...newState.wireframes,
                byName: {
                    ...byName,
                    [projectName]: {
                        ...byName[projectName],
                        logic: {
                            ...byName[projectName].logic,
                            [LogicContainers.Derived]: newDerivedLogic
                        }
                    }
                }
            }
        };
    }
}

function deleteDataElementsSuccess(state, args) {
    const { projectName, dataElementIds } = args;

    const dataElementsArr = StateReaderUtils.getProjectDataElements(state, projectName) || [];

    const newDataElementsArr = dataElementsArr.filter((de) => !dataElementIds.includes(de.id));

    if (newDataElementsArr.length === dataElementsArr.length) {
        return state;
    }

    return reduceDataElementsArray(state, projectName, newDataElementsArr, undefined, undefined);
}

function dataElementReducer(state, action) {
    switch (action.type) {
        case LOADING_PROGRAM_WIREFRAMES_SUCCESS: {
            let { projectName, dataElements, stage, version } = action.payload as LoadingProgramWireframeSuccess;
            let dataElementsArr: DataElement[] = dataElements.map(de => buildDataElement(de));
            return reduceDataElementsArray(state, projectName, dataElementsArr, stage, version);
        }
        case ADD_DATA_ELEMENT_SUCCESS:
            return updateDataElementSuccess(state, action.payload);
        case UPDATE_DATA_ELEMENT_SUCCESS:
            return updateDataElementSuccess(state, action.payload);
        case DELETE_DATA_ELEMENTS_SUCCESS:
            return deleteDataElementsSuccess(state, action.payload);
        default:
            return state;
    }
}

export default dataElementReducer;
