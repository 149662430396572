import gql from "graphql-tag";

export const deleteMultipleDataElements = (dataElementGQLIds: string[]) => {
    let paramters: string = dataElementGQLIds.map((_, index) => "$input" + index + ": DeleteDataElementInput!").join(", ");

    let mutation: string = dataElementGQLIds
        .map(
            (_, index) => `delete${index}: deleteDataElement(input: $input${index}) {        
        result
        ...on DeleteDataElementOutputRefusal {
            usedInData
        }
    }`
        )
        .join("\n");

    let fullMutation = `mutation DeleteDataElement(${paramters}) {
            ${mutation}
    }`;

    let variables = dataElementGQLIds.reduce((acc, id, index) => {
        acc["input" + index] = { id: id };
        return acc;
    }, {});

    return {
        mutation: gql`
            ${fullMutation}
        `,
        variables: variables
    };
};
