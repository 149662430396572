import Audience from "./audience";
import AudienceValue from "./audienceValue";
import Channel from "./channel";
import DataElement from "./dataElement";
import Goal from "./goal";
import KPI from "./kpi";
import Scene from "./scene";
import Story from "./story";
import Touchpoint from "./touchpoint";
import Program from "./program";
import ProgramVersion from "./programVersion";

export default {
    Audience,
    AudienceValue,
    Channel,
    DataElement,
    Goal,
    KPI,
    Scene,
    Story,
    Touchpoint,
    Program,
    ProgramVersion
};
