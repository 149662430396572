import type { GetValidationResultFunction, Issue, ValidationResult } from "../validations/validationManager";
import { IssueCodes, IssueSeverity } from "../validations/validationManager";
import type { Collector, CollectorArgs, Context, EntityInstance, IEntity } from "./definitions";
import { EntityTypes } from "./definitions";
import type { AnyEntitiesByContextById, AnyEntity, AnyEntityContextPair, GetDependencyIdFunction } from "./baseEntity";
import { BaseEntity } from "./baseEntity";
import StateReaderUtils from "../common/StateReaderUtils";
import { findDataElementById, getDerivedDataElementLogicId } from "../DataElements/DataElementsManager";
import type { ChangeDetail, DiffResult, GetDiffResultFunc } from "../versionDiff/diffManager";
import { ChangeType } from "../versionDiff/diffManager";
import { diffEntities, updateDiffResultWithChanges } from "../versionDiff/diffUtils";
import DataElementEntity from "./dataElementEntity";
import { DataElementOrigins } from "../../../common/types/dataElement";

export default class StudioDataElementEntity extends BaseEntity implements IEntity {
    constructor() {
        super(EntityTypes.DERIVED_DATA_ELEMENT);
    }

    collector: Collector = (args: CollectorArgs): EntityInstance[] => {
        const { context, previousContext } = args;

        const previousStudioData =
            previousContext &&
            StateReaderUtils.getProjectDataElements(previousContext.state, previousContext.projectName, previousContext.stage, previousContext.version).filter(
                (dataElement) => dataElement.origin === DataElementOrigins.Derived
            );
        const currentStudioData = StateReaderUtils.getProjectDataElements(context.state, context.projectName, context.stage, context.version).filter(
            (dataElement) => dataElement.origin === DataElementOrigins.Derived
        );

        const studioDataMap: AnyEntitiesByContextById = this.entityListsToMap(previousStudioData, currentStudioData, (de) => de.id);
        let studioDataInstances: EntityInstance[] = [];
        studioDataMap.forEach((entityContextPair: AnyEntityContextPair, key: string) => {
            studioDataInstances.push({
                id: key,
                validate: (prevValidationResult: ValidationResult, getValidationResultFunction: GetValidationResultFunction): ValidationResult => {
                    return this.doValidate(entityContextPair.currentEntity, getValidationResultFunction, context);
                },
                diff: (getDiffResult: GetDiffResultFunc): DiffResult => this.doDiff(getDiffResult, entityContextPair.previousEntity, entityContextPair.currentEntity)
            });
        });

        return studioDataInstances;
    };

    doValidate = (derivedDataElement: any, getValidationResult: GetValidationResultFunction, context: Context): ValidationResult => {
        const type: EntityTypes = this.getType();
        const id: string = derivedDataElement.id;
        let result: ValidationResult = { type, id, issues: [] };
        let wireframes: any = StateReaderUtils.getWireFrame(context.state, context.projectName, context.stage, context.version);
        let allDataElements: any = StateReaderUtils.getProjectDataElements(context.state, context.projectName, context.stage, context.version);

        //get derived logic obj and validate it
        let derivedLogic: any = StateReaderUtils.getProjectWireframesSceneInputLogic(
            context.state,
            context.projectName,
            DataElementOrigins.Derived,
            getDerivedDataElementLogicId(derivedDataElement),
            context.stage,
            context.version
        );
        if (derivedLogic) {
            const getDependencyId: GetDependencyIdFunction = (derivedDataElement) => derivedDataElement.id;
            let issue: Issue = this.getDependenciesIssue([derivedDataElement], EntityTypes.LOGIC, getDependencyId, getValidationResult, IssueCodes.DERIVED_LOGIC_ERROR);
            if (issue) {
                result.issues.push(issue);
                result.severity = issue.severity;
            }
        }
        else {
            result.issues.push({
                code: IssueCodes.DERIVED_LOGIC_MISSING,
                severity: IssueSeverity.WARNING,
                details: {}
            });
            result.severity = IssueSeverity.WARNING;
        }

        //search for DERIVED cycles
        const derivedLogicCycles: string[] = StateReaderUtils.getDerivedLogicCycles(wireframes);
        let positionInLogicCycle: number = derivedLogicCycles.indexOf(derivedDataElement.id);
        if (positionInLogicCycle > -1) {
            let cycle = [...derivedLogicCycles.slice(0, positionInLogicCycle), ...derivedLogicCycles.slice(positionInLogicCycle + 1)];
            cycle = cycle.map((dataElementId) => findDataElementById(dataElementId, allDataElements).displayName);

            result.issues.push({
                code: IssueCodes.DERIVED_DATA_ELEMENT_CYCLIC_DEPENDENCY,
                severity: IssueSeverity.ERROR,
                details: { cycle }
            });
            result.severity = IssueSeverity.ERROR;
        }

        return result;
    };

    doDiff = (getDiffResult: GetDiffResultFunc, previousStudioData: AnyEntity, currentStudioData: AnyEntity): DiffResult => {
        const changeType: ChangeType = diffEntities(DataElementEntity.normalizeDataElement(previousStudioData), DataElementEntity.normalizeDataElement(currentStudioData));
        let result: DiffResult = {
            type: this.getType(),
            id: (currentStudioData && currentStudioData.id) || previousStudioData.id,
            name: (currentStudioData && currentStudioData.displayName) || previousStudioData.displayName,
            changeType: changeType,
            changes: [],
            isShown: changeType !== ChangeType.None
        };
        if (result.changeType === ChangeType.None) {
            const getDependencyId: GetDependencyIdFunction = (id) => id;
            const change: ChangeDetail = this.getDependenciesDiff([result.id], EntityTypes.LOGIC, getDependencyId, getDiffResult, true);
            return updateDiffResultWithChanges(result, change);
        }
        return result;
    };
}
