import {
    ADDING_PROJECT_SNAPSHOT_SUCCESS,
    ADDING_PROJECT_SNAPSHOT_UPDATE_PROGRESS,
    ADDING_PROJECT_STAGE_SNAPSHOT_SUCCESS,
    LOADING_PROJECT_SNAPSHOT_SUCCESS,
    SET_PROJECT_BASED_ON_SNAPSHOT,
    SET_PROMOTE_STATUS,
    SETTING_PROJECT_SNAPSHOT_SUCCESS,
    UPDATING_PROJECT_SNAPSHOT_SUCCESS
} from "./projectLifecycleActions";
import { LOADING_PROJECT_LIFECYCLE_STAGE_SUCCESS, LOADING_PROJECT_LIFECYCLE_SUCCESS } from "../projectsActions";
import { immutablyReplaceValue } from "../../../../common/generalUtils";

function projectLifeCycleReducer(state, action) {
    let projectStageProperty;
    let projectName;
    let snapshotId;

    /**
     * Creates a new stage Snapshot object
     * @returns {stageSnapshot:*}  the new stage snapshot
     */
    let createStageSnapshot = function(snapshotId, promotedDate, snapshotNumber, promotedBy) {
        let stageSnapshot: any = { snapshotId };
        if (promotedDate) {
            stageSnapshot.promotedDate = promotedDate;
        }
        if (snapshotNumber) {
            stageSnapshot.snapshotNumber = snapshotNumber;
        }
        if (promotedBy) {
            stageSnapshot.promotedBy = promotedBy;
        }
        return stageSnapshot;
    };

    /**
     * Add a stage snapshot in the projectStagePropertyArray. <b>Returns a new Array</b>
     * @param projectStagePropertyArray
     * @param stageSnapshot
     * @returns a new array with the stage snapshot updated in it
     */
    let updateProjectStageSnapshotInArray = function(projectStagePropertyArray, stageSnapshot) {
        return Array.isArray(projectStagePropertyArray) ? [stageSnapshot, ...projectStagePropertyArray] : [stageSnapshot];
    };

    let snapshotsAllIds = state.snapshots.allIds;
    let projectsByName = state.projects.byName;
    let projectsAllNames = state.projects.allNames;
    let versionManagerState;

    switch (action.type) {
        case LOADING_PROJECT_LIFECYCLE_SUCCESS: {
            let updatedStageSnapshots = {};
            action.payload.projectLifeCycle.forEach(function(lifeCycleItem) {
                projectStageProperty = lifeCycleItem.stage + "Snapshots";
                snapshotId = action.payload.projectName + "/" + lifeCycleItem.snapshotNumber;
                let stageSnapshot = createStageSnapshot(snapshotId, undefined, lifeCycleItem.snapshotNumber, undefined);
                updatedStageSnapshots[projectStageProperty] = updateProjectStageSnapshotInArray(projectsByName[action.payload.projectName][projectStageProperty], stageSnapshot);
            });

            projectsByName = {
                ...projectsByName,
                [action.payload.projectName]: {
                    ...projectsByName[action.payload.projectName],
                    ...updatedStageSnapshots
                }
            };

            return Object.assign({}, state, {
                projects: { byName: projectsByName, allNames: projectsAllNames, isInitialized: true }
            });
        }
        case LOADING_PROJECT_LIFECYCLE_STAGE_SUCCESS: {
            projectName = action.payload.projectName;
            projectStageProperty = action.payload.stage + "Snapshots";

            let projectStageSnapshotArray = projectsByName[action.payload.projectName]?.[projectStageProperty];
            for (let i = action.payload.projectLifeCycle.snapshots.length - 1; i >= 0; --i) {
                let item = action.payload.projectLifeCycle.snapshots[i];
                let stageSnapshot = createStageSnapshot(projectName + "/" + item.snapshotNumber, item.promotedDate, item.snapshotNumber, item.promotedBy);
                projectStageSnapshotArray = updateProjectStageSnapshotInArray(projectStageSnapshotArray, stageSnapshot);
            }

            return immutablyReplaceValue(state, ["projects", "byName", projectName, projectStageProperty], projectStageSnapshotArray);
        }
        case LOADING_PROJECT_SNAPSHOT_SUCCESS: {
            const { projectName, projectSnapShot: snapshot } = action.payload;
            let newState = state;

            snapshotId = projectName + "/" + snapshot.snapshotNumber;
            if (!snapshotsAllIds.includes(snapshotId)) {
                snapshotsAllIds = [...snapshotsAllIds, snapshotId];
                newState = immutablyReplaceValue(newState, ["snapshots", "allIds"], snapshotsAllIds);
            }

            snapshot.snapshotId = snapshotId;
            newState = immutablyReplaceValue(newState, ["snapshots", "byId", snapshotId], snapshot, true);

            return newState;
        }
        case SET_PROJECT_BASED_ON_SNAPSHOT: {
            let snapshotNumber = action.payload.snapshotNumber;
            let project = projectsByName[action.payload.projectName];
            let devProjectId = project.devSnapshots && project.devSnapshots[0] && project.devSnapshots[0].snapshotId;
            snapshotId = snapshotNumber > -1 ? action.payload.projectName + "/" + action.payload.snapshotNumber : devProjectId;
            project.basedOnSnapshot = { snapshotNumber, snapshotId };

            return Object.assign({}, state, {
                projects: { byName: projectsByName, allNames: projectsAllNames, isInitialized: true }
            });
        }
        case ADDING_PROJECT_SNAPSHOT_UPDATE_PROGRESS:
            return Object.assign({}, state, { loadingProgress: action.payload.progress });
        case ADDING_PROJECT_SNAPSHOT_SUCCESS: {
            const currentSnapshot = action.payload.projectSnapShot;
            const allSnapshots = [...state.versions, currentSnapshot];
            const snapshotId = `${action.payload.projectName}/${currentSnapshot.snapshotNumber}`;

            let newState = immutablyReplaceValue(state, ["versions"], allSnapshots);
            newState = immutablyReplaceValue(newState, ["snapshots", "byId", snapshotId], currentSnapshot);
            newState = immutablyReplaceValue(newState, ["snapshots", "allIds"], [...snapshotsAllIds, snapshotId]);

            return newState;
        }
        case UPDATING_PROJECT_SNAPSHOT_SUCCESS: {
            const snapshotDetailsToUpdate = action.payload.snapshotObject;
            const snapshotNumber = action.payload.snapshotNumber;
            snapshotId = `${action.payload.projectName}/${snapshotNumber}`;

            let allVersions = [...state.versions];
            let index = allVersions.findIndex((item) => item.snapshotNumber === snapshotNumber);
            if (index > -1) {
                allVersions[index] = { ...allVersions[index], ...snapshotDetailsToUpdate };
            }

            let newState = immutablyReplaceValue(state, ["versions"], allVersions);
            return immutablyReplaceValue(newState, ["snapshots", "byId", snapshotId], snapshotDetailsToUpdate, true);
        }
        case ADDING_PROJECT_STAGE_SNAPSHOT_SUCCESS: {
            snapshotId = action.payload.projectName + "/" + action.payload.projectSnapShot.snapshotNumber;
            projectStageProperty = action.payload.projectSnapShot.stage + "Snapshots";

            let updatedStageSnapshots = {};
            let stageSnapshot = createStageSnapshot(snapshotId, action.payload.projectSnapShot.promotedDate, action.payload.projectSnapShot.snapshotNumber, action.payload.projectSnapShot.promotedBy);
            updatedStageSnapshots[projectStageProperty] = updateProjectStageSnapshotInArray(projectsByName[action.payload.projectName][projectStageProperty], stageSnapshot);

            projectsByName = {
                ...projectsByName,
                [action.payload.projectName]: {
                    ...projectsByName[action.payload.projectName],
                    ...updatedStageSnapshots
                }
            };

            // if this stage wireframe was loaded (from another version) - remove it from state
            const wireframeKey = `${action.payload.projectSnapShot.stage}wireframes`;
            let byName = state[wireframeKey].byName ? Object.assign({}, state[wireframeKey].byName) : {};
            if (byName[action.payload.projectName]) {
                delete byName[action.payload.projectName];
            }
            let allNames = state[wireframeKey].allNames ? state[wireframeKey].allNames.filter(name => name !== action.payload.projectName) : [];

            return Object.assign({}, state, {
                projects: { byName: projectsByName, allNames: projectsAllNames, isInitialized: true },
                [wireframeKey]: { byName, allNames }
            });
        }
        case SETTING_PROJECT_SNAPSHOT_SUCCESS: {
            snapshotId = action.payload.projectName + "/" + action.payload.projectSnapShot.snapshotNumber;
            projectStageProperty = action.payload.projectSnapShot.stage + "Snapshots";

            let updatedStageSnapshots = {};
            let stageSnapshot = createStageSnapshot(snapshotId, action.payload.projectSnapShot.promotedDate, action.payload.projectSnapShot.snapshotNumber, action.payload.projectSnapShot.promotedBy);
            updatedStageSnapshots[projectStageProperty] = updateProjectStageSnapshotInArray(projectsByName[action.payload.projectName][projectStageProperty], stageSnapshot);

            versionManagerState = state.versionManager || {};
            versionManagerState = {
                ...versionManagerState,
                promoteStatus: true
            };

            projectsByName = {
                ...projectsByName,
                [action.payload.projectName]: {
                    ...projectsByName[action.payload.projectName],
                    ...updatedStageSnapshots,
                    updatedAssetNumber: action.payload.projectSnapShot.updatedAssetNumber
                }
            };

            // if this stage wireframe was loaded (from another version) - remove it from state
            const wireframeKey = `${action.payload.projectSnapShot.stage}wireframes`;
            let byName = state[wireframeKey].byName ? Object.assign({}, state[wireframeKey].byName) : {};
            if (byName[action.payload.projectName]) {
                delete byName[action.payload.projectName];
            }
            let allNames = state[wireframeKey].allNames ? state[wireframeKey].allNames.filter(name => name !== action.payload.projectName) : [];

            return Object.assign({}, state, {
                projects: { byName: projectsByName, allNames: projectsAllNames, isInitialized: true },
                versionManager: versionManagerState,
                [wireframeKey]: { byName, allNames }
            });
        }
        case SET_PROMOTE_STATUS:
            versionManagerState = state.versionManager || {};
            versionManagerState.promoteStatus = action.payload;
            return Object.assign({}, state, { versionManager: versionManagerState });
        default:
            return state;
    }
}
export default projectLifeCycleReducer;
