import { createAction } from "redux-actions";
import { reportError, setLoading, setSoftLoading } from "../../common/commonActions";
import createStoryInlineLogic from "../../vlx/storyInline";
import createSceneLogic from "../../vlx/scene";
import createAnalyticsData from "../../vlx/analytics";
import StateReaderUtils from "../../common/StateReaderUtils";
import { LogicContainers, storyInlineAssetType } from "../../../../common/commonConst";
import { createDtaasObj } from "../../common/exportNarrationsUtils";
import { getProgramValidationResult } from "../../validations/validationReducer";
import { getProgramValidationContent } from "../../vlx/editorLogicUtils";
import { PollingHandler } from "../../common/pollingHandler";
import type { SnapshotData, Stages } from "../../../../common/types/lifecycle";
import type { Asset, RecordingAsset } from "../../../../common/types/asset";
import { AssetTypes } from "../../../../common/types/asset";
import type { Program } from "../../../../common/types/program";
import type { VLXProgram } from "../../../../common/types/vlxTypes";
import { default as createProgramLogic } from "../../vlx/program";
import { featureFlagConst } from "@sundaysky/smartvideo-hub-config";
import { EDITING_MODE, IGNORE_SERVER_ERRORS, IGNORE_UPDATED, STAGE_MODE } from "../../common/Consts";
import type {
    GqlClientAddLifecycleHistoryEntryInput,
    GqlClientAddLifecycleHistoryEntryMutation,
    GqlClientCreateEditorLifecycleEntryInput,
    GqlClientCreateEditorLifecycleEntryMutation,
    GqlClientCreateProgramVersionDraftFromSnapshotInput,
    GqlClientCreateProgramVersionSnapshotInput,
    GqlClientCreateProgramVersionSnapshotMutation,
    GqlClientGetProgramLifecycleHistoryQuery,
    GqlClientGetProgramVersionSnapshotWithAssetsQuery,
    GqlClientGetProgramVersionSnapshotWithAssetsQueryVariables,
    GqlClientUpdateNarrationDtaasPrefixKeyInput,
    GqlClientUpdateNarrationsDtaasPrefixKeyInput,
    GqlClientUpdateNarrationsDtaasPrefixKeyMutation,
    GqlClientUpdateProgramLastSnapshotNumberMutation,
    GqlClientUpdateProgramLastSnapshotNumberMutationVariables,
    GqlClientUpdateProgramVersionSnapshotInput } from "../../../graphql/graphqlGeneratedTypes/graphqlClient";
import {
    AddLifecycleHistoryEntryDocument,
    CreateEditorLifecycleEntryDocument,
    CreateProgramVersionDraftFromSnapshotDocument,
    CreateProgramVersionSnapshotDocument,
    GetProgramLifecycleHistoryDocument,
    GetProgramVersionSnapshotWithAssetsDocument,
    GqlClientUpdateNarrationsDtaasPrefixKeyResult,
    UpdateNarrationsDtaasPrefixKeyDocument,
    UpdateProgramLastSnapshotNumberDocument,
    UpdateProgramVersionSnapshotDocument
} from "../../../graphql/graphqlGeneratedTypes/graphqlClient";
import type { ThunkServices } from "../../common/types";
import { BulkId } from "../../common/types";
import { convertStageType } from "../../builder/graphql/convertTypes";
import type { DtaasServicesUploadResult, NarrationPartDataData, NarrationParts, NarrationPartUploadType, UpdateProgressCbFunc } from "../../dtaas/dtaasServices";
import type { NarrationPart, Scene, ScenePart } from "../../../../common/types/scene";
import { ChangingBulkScenePartsSuccess, updateWireframeGraphQLParentProgramVersionId } from "../projectWireframes/projectsWireframesActions";
import type { FetchResult } from "@apollo/client";
import type { Snapshot, SnapshotAsset } from "../../../../common/types/snapshot";
import { SnapshotSource } from "../../../../common/types/snapshot";
import { buildStateSnapshotAssetsFromGql, buildStateSnapshotFromGql } from "../../common/convertGqlEntityToWireframesUtils";
import { loadingProjectLifeCycleStageSuccess } from "../projectsActions";
import { convertGqlLifecycleHistoryResult } from "../../common/convertGqlLifecycleHistoryUtils";
import { memoizeThunkAction } from "../../common/generalUtils";

export const LOADING_PROJECT_SNAPSHOT = "LOADING_PROJECT_SNAPSHOT";
export const LOADING_PROJECT_SNAPSHOT_SUCCESS = "LOADING_PROJECT_SNAPSHOT_SUCCESS";
export const loadingProjectSnapshotSuccess = createAction(LOADING_PROJECT_SNAPSHOT_SUCCESS, (accountId, projectName, projectSnapShot) => ({ accountId, projectName, projectSnapShot }));
export const ADDING_PROJECT_SNAPSHOT_UPDATE_PROGRESS = "ADDING_PROJECT_SNAPSHOT_UPDATE_PROGRESS";
export const ADDING_PROJECT_SNAPSHOT_SUCCESS = "ADDING_PROJECT_SNAPSHOT_SUCCESS";
export const ADDING_PROJECT_STAGE_SNAPSHOT_SUCCESS = "ADDING_PROJECT_STAGE_SNAPSHOT_SUCCESS";
export const SETTING_PROJECT_SNAPSHOT_SUCCESS = "SETTING_PROJECT_SNAPSHOT_SUCCESS";
export const UPDATING_PROJECT_SNAPSHOT_SUCCESS = "UPDATING_PROJECT_SNAPSHOT_SUCCESS";
export const SET_PROJECT_BASED_ON_SNAPSHOT = "SET_PROJECT_BASED_ON_SNAPSHOT";
export const SET_PROMOTE_STATUS = "SET_PROMOTE_STATUS";
export const addingProjectSnapshotUpdateProgress = createAction(ADDING_PROJECT_SNAPSHOT_UPDATE_PROGRESS, (progress) => ({ progress }));
export const addingProjectSnapshotSuccess = createAction(ADDING_PROJECT_SNAPSHOT_SUCCESS, (accountId, projectName, projectSnapShot) => ({ accountId, projectName, projectSnapShot }));

export const addingProjectStageSnapshotSuccess = createAction(ADDING_PROJECT_STAGE_SNAPSHOT_SUCCESS, (accountId, projectName, projectSnapShot) => ({
    accountId,
    projectName,
    projectSnapShot
}));
export const setProjectLifecycleStageSnapshotSuccess = createAction(SETTING_PROJECT_SNAPSHOT_SUCCESS, (accountId, projectName, projectSnapShot) => ({ accountId, projectName, projectSnapShot }));
export const setProjectBasedOnSnapshot = createAction(SET_PROJECT_BASED_ON_SNAPSHOT, (accountId, projectName, snapshotNumber) => ({ accountId, projectName, snapshotNumber }));
export const updatingProjectSnapshotSuccess = createAction(UPDATING_PROJECT_SNAPSHOT_SUCCESS, (accountId: string, projectName: string, snapshotNumber: number, snapshotObject: Partial<Snapshot>) => ({
    accountId,
    projectName,
    snapshotNumber,
    snapshotObject
}));
export const setPromoteStatus = createAction(SET_PROMOTE_STATUS, (status) => status);

const { STORY_LOGIC } = featureFlagConst;

function addProjectLifecycleStageSnapshotInternal(
    getState,
    services: ThunkServices,
    dispatch,
    accountId: string,
    projectName: string,
    snapshotNumber: number,
    snapshotName: string,
    stage,
    assets: Asset[] | undefined,
    cb,
    userName,
    snapshotComment,
    validationData,
    isLegacyProject,
    programId: string,
    programVersionId: string
) {
    //For not legacy programs 'assets' will be undefined.
    function updateLoadingProgress(progress) {
        dispatch(addingProjectSnapshotUpdateProgress(progress));
    }
    let snapshotProgramVersionId: string;
    return services.projectLifeCycleServices
        .addProjectSnapshot(accountId, projectName, snapshotNumber, snapshotName, assets, updateLoadingProgress, userName, snapshotComment, validationData, isLegacyProject, programVersionId)
        .then(() => {
            let input: GqlClientCreateProgramVersionSnapshotInput = {
                sourceId: programVersionId,
                snapshotNumber: snapshotNumber,
                snapshotName: snapshotName,
                snapshotComment: snapshotComment,
                snapshotValidationData: validationData
            };
            return !isLegacyProject
                ? services.graphQlClient.mutate<GqlClientCreateProgramVersionSnapshotMutation>({
                    mutation: CreateProgramVersionSnapshotDocument,
                    variables: {
                        input: input,
                        [IGNORE_SERVER_ERRORS]: true //reportError in the end of this promise chine will use to report errors to user
                    }
                })
                : Promise.resolve(undefined);
        })
        .then((result: FetchResult<GqlClientCreateProgramVersionSnapshotMutation>) => {
            snapshotProgramVersionId = result?.data?.createProgramVersionSnapshot?.targetProgramVersion?.id;
            const newDraftGraphQLParentProgramVersionId = result?.data?.createProgramVersionSnapshot?.sourceProgramVersion?.parent1ProgramVersion?.id;
            if (newDraftGraphQLParentProgramVersionId) {
                dispatch(updateWireframeGraphQLParentProgramVersionId(projectName, newDraftGraphQLParentProgramVersionId));
            }
            if (stage) {
                return addLifecycleHistoryEntry(getState, services, dispatch, accountId, projectName, stage, snapshotNumber, "promote", userName) as Promise<any>;
            }
        })
        .then((projectLifeCycle: SnapshotData | undefined) => {
            if (projectLifeCycle) {
                dispatch(addingProjectStageSnapshotSuccess(accountId, projectName, projectLifeCycle));
            }
            if (!isLegacyProject) {
                return getProjectSnapshotFromGQL(services, programId, snapshotProgramVersionId);
            }
            else {
                return services.projectLifeCycleServices.getProjectSnapshot(accountId, projectName, snapshotNumber);
            }
        })
        .then((snapshot: Snapshot) => {
            dispatch(addingProjectSnapshotSuccess(accountId, projectName, snapshot));

            return services.wireframes.setProjectWireframesDraftDirtyBit(accountId, projectName, false);
        })
        .then(() => {
            dispatch(setLoading(false, accountId, projectName));
            if (typeof cb === "function") {
                cb(snapshotNumber);
            }
        })
        .catch((err) => {
            dispatch(reportError(err));
            dispatch(setLoading(false, accountId, projectName));
        });
}

async function getNewVersionSnapshotNumber(getState, services: ThunkServices, programId: string, projectName: string) {
    const variables: GqlClientUpdateProgramLastSnapshotNumberMutationVariables = {
        input: {
            id: programId
        },
        //@ts-ignore
        [IGNORE_SERVER_ERRORS]: true
    };
    const snapshotNumberResult: FetchResult<GqlClientUpdateProgramLastSnapshotNumberMutation> = await services.graphQlClient.mutate({ mutation: UpdateProgramLastSnapshotNumberDocument, variables });
    const newVersionSnapshotNumber = snapshotNumberResult.data.updateProgramLastSnapshotNumber.snapshotNumber;
    const latestVersionNumber = StateReaderUtils.getLatestVersionNumber(getState(), projectName);
    if (latestVersionNumber >= newVersionSnapshotNumber) {
        throw Error("snapshot number already exist");
    }
    return newVersionSnapshotNumber;
}

export const createVersion = function(
    userName: string,
    accountId: string,
    projectName: string,
    snapshotName: string,
    snapshotComment: string,
    relateToStage: Stages,
    wireframes: any,
    enabledFeatureFlags: string[],
    narratorId: string,
    selectedVoice: string,
    cb?: (newVersionNumber: number) => void
) {
    return function(dispatch, getState, services) {
        dispatch(setLoading(true, accountId, projectName));

        let state = getState();

        let wireframes = StateReaderUtils.getWireFrame(state, projectName);

        let program: Program = StateReaderUtils.getProject(state, projectName);
        const { programId, programVersionId } = StateReaderUtils.getBuilderProgramAndDraftVersionIds(getState(), projectName);
        let assets: Asset[] = StateReaderUtils.getProjectAssets(program, state.assets);
        const storyIds: string[] = Object.keys(wireframes.stories);

        let scenesLogic;
        let uploadStoryInlineJob;
        let snapshotNumber: number;
        return getNewVersionSnapshotNumber(getState, services, programId, projectName)
            .then((newSnapshotNumber) => {
                snapshotNumber = newSnapshotNumber;
                return createSceneLogic({
                    wireframes,
                    assets,
                    program,
                    masterData: wireframes.scenes[LogicContainers.Master]
                });
            })
            .then((createScenesLogicResult) => {
                scenesLogic = createScenesLogicResult;

                // Create inlineStory.json assets for all stories.

                const program = StateReaderUtils.getProject(state, projectName);
                const accountName = StateReaderUtils.getAccountDisplayName(state.accounts.byId[accountId]);

                // Get all stories
                // Build logic for each one
                let storyInlineLogics = [];
                const creativeVersion = StateReaderUtils.getCreativeVersionFromWireframes(wireframes);
                storyIds.forEach((storyId) => {
                    const currentStory = wireframes.stories[storyId];
                    const analytics = createAnalyticsData(accountId, program, wireframes, currentStory, assets);
                    const narrationsVersion = StateReaderUtils.getNarrationsVersion(wireframes);
                    const storiesVersion = StateReaderUtils.getStoriesVersion(state, projectName);
                    let storyInlineLogic = createStoryInlineLogic(
                        wireframes,
                        assets,
                        currentStory,
                        analytics,
                        program,
                        accountName,
                        scenesLogic,
                        false,
                        narrationsVersion,
                        storiesVersion,
                        creativeVersion,
                        enabledFeatureFlags
                    );
                    storyInlineLogics.push(storyInlineLogic);
                });
                return { storyInlineLogics };
            })
            .then(({ storyInlineLogics }) => {
                // Save the assets to DB
                let uploadStoryInlineContent = storyIds.map((storyId, index) => {
                    let assetName = wireframes.stories[storyId].name;
                    return { assetName, jsonContent: storyInlineLogics[index] };
                });
                uploadStoryInlineJob = PollingHandler.waitForTaskToComplete(
                    services.projectAssetsServices.uploadBulkJsonAssetContent(accountId, projectName, uploadStoryInlineContent, storyInlineAssetType, false)
                );
                return uploadStoryInlineJob;
            })
            .then((storyAssets: Asset[]) => {
                // Create program.json asset.
                // Save it to DB.

                if (StateReaderUtils.isFeatureEnable(state, STORY_LOGIC)) {
                    let storyIdToStoryAssetName = storyIds.reduce((acc, item, index) => {
                        acc[item] = storyAssets[index].name;
                        return acc;
                    }, {});

                    let programLogic: VLXProgram = createProgramLogic(state, projectName, storyIdToStoryAssetName);

                    return services.projectAssetsServices.uploadJsonAssetContent(accountId, projectName, "program", programLogic, AssetTypes.program, false);
                }
            })
            .then((programAsset: Asset) => {
                // Upload narrations to DTaaS.

                const program: Program = StateReaderUtils.getProject(state, projectName);
                const recordings: RecordingAsset[] = StateReaderUtils.getProjectAssetsByType(program, state.assets, AssetTypes.recording);
                const narrationsVersion = StateReaderUtils.getNarrationsVersion(wireframes);

                if (narrationsVersion > 0) {
                    let narrationData = createDtaasObj(wireframes, narratorId, recordings);
                    let narrationParts: Record<string, NarrationPart> = StateReaderUtils.getProjectNarrationParts(wireframes).reduce((acc, narrationPart) => {
                        acc[narrationPart.id] = narrationPart;
                        return acc;
                    }, {});

                    // upload full narration data with hashKeyPrefix
                    return dispatch(uploadToDtaasIfNeeded(accountId, projectName, narratorId, narrationData, selectedVoice, wireframes.scenes, narrationParts, snapshotNumber));
                }
            })
            .then(() => {
                // Add a new snapshot & add assets to it

                // Read program validation result and prepare data for snapshot
                let programValidationResult = getProgramValidationResult(state, projectName);
                let validationData = getProgramValidationContent(programValidationResult);

                return addProjectLifecycleStageSnapshotInternal(
                    getState,
                    services,
                    dispatch,
                    accountId,
                    projectName,
                    snapshotNumber,
                    snapshotName,
                    relateToStage,
                    undefined,
                    cb,
                    userName,
                    snapshotComment,
                    validationData,
                    false,
                    programId,
                    programVersionId
                );
            })
            .catch((err) => {
                dispatch(reportError(err));
                dispatch(setLoading(false, accountId, projectName));
            });
    };
};

//we use this action from legacy pages only (for legacy programs)
export const addProjectLifecycleStageSnapshot = function(accountId, projectName, snapshotName, stage, assets, cb, isLegacyProject) {
    return function(dispatch, getState, services) {
        dispatch(setLoading(true, accountId, projectName));
        const { programId } = StateReaderUtils.getBuilderProgramAndDraftVersionIds(getState(), projectName);
        return getNewVersionSnapshotNumber(getState, services, programId, projectName)
            .then((snapshotNumber) => {
                return addProjectLifecycleStageSnapshotInternal(
                    getState,
                    services,
                    dispatch,
                    accountId,
                    projectName,
                    snapshotNumber,
                    snapshotName,
                    stage,
                    assets,
                    cb,
                    undefined,
                    undefined,
                    undefined,
                    true,
                    undefined,
                    undefined
                );
            })
            .catch((err) => {
                dispatch(reportError(err));
                dispatch(setLoading(false, accountId, projectName));
            });
    };
};

export const setProjectLifecycleStageSnapshot = (accountId, projectName, stage, projectSnapshotNumber, analyticsAction, cb, userName) => {
    return async (dispatch, getState, services) => {
        try {
            await loadProjectSnapshotPromise(services, getState(), dispatch, accountId, projectName, projectSnapshotNumber);

            const projectType = StateReaderUtils.getProjectType(getState(), projectName);
            const projectSFId = StateReaderUtils.getProjectId(getState(), projectName);

            const isEditorProgram = StateReaderUtils.isEditorProgram(getState(), projectName);
            let snapshotData;
            if (isEditorProgram) {
                snapshotData = await createEditorLifecycleEntry(
                    services,
                    dispatch,
                    StateReaderUtils.getProgramId(getState(), projectName),
                    stage,
                    projectSnapshotNumber,
                    userName
                );
            }
            else {
                snapshotData = await addLifecycleHistoryEntry(
                    getState,
                    services,
                    dispatch,
                    accountId,
                    projectName,
                    stage,
                    projectSnapshotNumber,
                    analyticsAction,
                    userName,
                    projectType,
                    projectSFId
                );
            }

            dispatch(setProjectLifecycleStageSnapshotSuccess(accountId, projectName, snapshotData));
            if (typeof cb === "function") {
                cb();
            }
        }
        catch (err) {
            dispatch(reportError(err));
        }
    };
};

const createEditorLifecycleEntry = async (
    services: ThunkServices,
    dispatch,
    programId: string,
    stage: Stages,
    projectSnapshotNumber: number,
    userName: string
): Promise<SnapshotData> => {
    let input: GqlClientCreateEditorLifecycleEntryInput = {
        programId: programId,
        lifecycleStage: convertStageType(stage),
        snapshotNumber: projectSnapshotNumber
    };

    const gqlResult = await services.graphQlClient.mutate<GqlClientCreateEditorLifecycleEntryMutation>({
        mutation: CreateEditorLifecycleEntryDocument,
        variables: {
            input: input,
            [IGNORE_SERVER_ERRORS]: true
        },
        context: {
            allowedModes: [EDITING_MODE, STAGE_MODE]
        }
    });

    const lifecycleHistoryEntry = gqlResult.data.createEditorLifecycleEntry.lifecycleHistoryEntry;
    return {
        stage: stage,
        snapshotNumber: projectSnapshotNumber,
        promotedDate: new Date(lifecycleHistoryEntry.created).valueOf(),
        promotedBy: userName,
        graphQLId: lifecycleHistoryEntry.id
    };
};

const getProjectSnapshotFromGQL = (services: ThunkServices, programId: string, programVersionId: string): Promise<Snapshot> => {
    const variables: GqlClientGetProgramVersionSnapshotWithAssetsQueryVariables = {
        programId,
        programVersionId
    };
    return services.graphQlClient
        .query<GqlClientGetProgramVersionSnapshotWithAssetsQuery>({ query: GetProgramVersionSnapshotWithAssetsDocument, variables })
        .then((result: FetchResult<GqlClientGetProgramVersionSnapshotWithAssetsQuery>) => {
            const gqlProgramVersionSnapshot = result.data.program.programVersion;
            const snapshotAssets: SnapshotAsset[] = buildStateSnapshotAssetsFromGql(
                gqlProgramVersionSnapshot.assets,
                gqlProgramVersionSnapshot.animations,
                gqlProgramVersionSnapshot.dataTables,
                gqlProgramVersionSnapshot.narrationRecordings,
                gqlProgramVersionSnapshot.narrators
            );
            return buildStateSnapshotFromGql(gqlProgramVersionSnapshot, snapshotAssets);
        });
};

export const loadProjectSnapshotPromise = (services: ThunkServices, state, dispatch, accountId, projectName, snapshotNumber) => {
    const snapshotDetails: Snapshot = StateReaderUtils.getSnapshotDetails(state, Number(snapshotNumber));
    let getProjectSnapshotPromise: Promise<Snapshot>;
    if (snapshotDetails?.snapshotSource === SnapshotSource.POSTGRES) {
        const programId: string = StateReaderUtils.getProgramId(state, projectName);
        const programVersionId: string = snapshotDetails.graphQLId;
        getProjectSnapshotPromise = getProjectSnapshotFromGQL(services, programId, programVersionId);
    }
    else {
        getProjectSnapshotPromise = services.projectLifeCycleServices.getProjectSnapshot(accountId, projectName, snapshotNumber);
    }
    return getProjectSnapshotPromise.then((projectSnapshot: Snapshot) => {
        dispatch(loadingProjectSnapshotSuccess(accountId, projectName, projectSnapshot));
    });
};

export const loadProjectSnapshot = function(accountId: string, projectName: string, snapshotNumber: number) {
    return (dispatch, getState, services: ThunkServices) => {
        loadProjectSnapshotPromise(services, getState(), dispatch, accountId, projectName, snapshotNumber).catch((err) => {
            dispatch(reportError(err));
        });
    };
};

export const loadProjectSnapshotLegacy = function(accountId, projectName, snapshotNumber, cb) {
    return (dispatch, getState, services) => {
        dispatch(setLoading(true, accountId, projectName));
        loadProjectSnapshotPromise(services, getState(), dispatch, accountId, projectName, snapshotNumber)
            .then(() => {
                dispatch(setProjectBasedOnSnapshot(accountId, projectName, snapshotNumber));
                if (typeof cb === "function") {
                    cb();
                }
                dispatch(setLoading(false, accountId, projectName));
            })
            .catch((err) => {
                dispatch(reportError(err));
                dispatch(setLoading(false, accountId, projectName));
            });
    };
};

export const updateProjectSnapshot = function(accountId: string, projectName: string, snapshotNumber: number, newSnapshotObject: Partial<Snapshot>, cb?) {
    return function(dispatch, getState, services) {
        dispatch(setLoading(true, accountId, projectName));
        const snapshotDetails: Snapshot = StateReaderUtils.getSnapshotDetails(getState(), snapshotNumber);
        let postgresPromise = Promise.resolve();
        const dispatchUpdateSuccess = (snapshotDetailsToUpdate: Partial<Snapshot>) => {
            dispatch(updatingProjectSnapshotSuccess(accountId, projectName, snapshotNumber, snapshotDetailsToUpdate));
            if (typeof cb === "function") {
                cb();
            }
            dispatch(setLoading(false, accountId, projectName));
        };
        //TODO: when we stop writing new versions to HubProjectSnapshots, for new versions (SnapshotSource.POSTGRES), we will need to call only postgresPromise.
        return services.projectLifeCycleServices
            .updateProjectSnapshot(accountId, projectName, snapshotNumber, newSnapshotObject)
            .then(() => {
                if (snapshotDetails?.snapshotSource === SnapshotSource.POSTGRES) {
                    let input: GqlClientUpdateProgramVersionSnapshotInput = {
                        id: snapshotDetails.graphQLId,
                        updated: IGNORE_UPDATED,
                        snapshotName: newSnapshotObject.snapshotName,
                        snapshotComment: newSnapshotObject.snapshotComment
                    };
                    postgresPromise = services.graphQlClient.mutate({
                        mutation: UpdateProgramVersionSnapshotDocument,
                        variables: {
                            input: input,
                            [IGNORE_SERVER_ERRORS]: true
                        },
                        context: {
                            allowedModes: [EDITING_MODE, STAGE_MODE]
                        }
                    });
                }
                return postgresPromise;
            })
            .then(() => {
                dispatchUpdateSuccess(newSnapshotObject);
            })
            .catch((err) => {
                dispatch(reportError(err));
                dispatch(setLoading(false, accountId, projectName));
            });
    };
};

const addLifecycleHistoryEntry = async (
    getState,
    services: ThunkServices,
    dispatch,
    accountId: string,
    projectName: string,
    stage: Stages,
    projectSnapshotNumber: number,
    analyticsAction: string,
    userName: string,
    projectType?,
    projectSFId?
): Promise<SnapshotData> => {
    // get programId
    let programId: string = StateReaderUtils.getProgramId(getState(), projectName);
    if (!programId) {
        const projectSummaries = await services.projectServices.loadProjectSummaries(accountId);
        programId = StateReaderUtils.getProgramId({ projectSummaries }, projectName);
    }

    const writeLifecycleHistoryPostgresOnly = StateReaderUtils.isProgramBulkDynamoMigrationDone(getState(), programId, BulkId.LifecycleHistory);

    let dynamoLifecycleHistoryEntry: SnapshotData;

    // Write to dynamo. Adding flag to write only to runtime table
    try {
        dynamoLifecycleHistoryEntry = await services.projectLifeCycleServices.setProjectLifecycleStageSnapshot(
            accountId,
            projectName,
            stage,
            projectSnapshotNumber,
            analyticsAction,
            userName,
            projectType,
            projectSFId,
            programId,
            writeLifecycleHistoryPostgresOnly
        );
    }
    catch (err) {
        dispatch(reportError(err));
    }

    // Write to Postgres
    let postgresLifecycleHistoryEntry: SnapshotData;

    try {
        let input: GqlClientAddLifecycleHistoryEntryInput = {
            programId: programId,
            lifecycleStage: convertStageType(stage),
            snapshotNumber: projectSnapshotNumber
        };

        const gqlResult = await services.graphQlClient.mutate<GqlClientAddLifecycleHistoryEntryMutation>({
            mutation: AddLifecycleHistoryEntryDocument,
            variables: {
                input: input,
                [IGNORE_SERVER_ERRORS]: !writeLifecycleHistoryPostgresOnly
            },
            context: {
                allowedModes: [EDITING_MODE, STAGE_MODE]
            }
        });

        const addLifecycleHistoryEntryOutput = gqlResult.data.addLifecycleHistoryEntry;
        postgresLifecycleHistoryEntry = {
            stage: stage,
            snapshotNumber: projectSnapshotNumber,
            promotedDate: new Date(addLifecycleHistoryEntryOutput.lifecycleHistoryEntry.created).valueOf(),
            promotedBy: userName,
            graphQLId: addLifecycleHistoryEntryOutput.lifecycleHistoryEntry.id
        };
    }
    catch (err) {
        // ignore error
    }

    return writeLifecycleHistoryPostgresOnly ? postgresLifecycleHistoryEntry : dynamoLifecycleHistoryEntry;
};

export const uploadToDtaasIfNeeded = function(
    accountId: string,
    projectName: string,
    narratorId: string,
    narrationData: NarrationPartDataData[],
    selectedVoice: string,
    scenes: any,
    narrationParts: NarrationParts,
    version?: number,
    updateProgressCb?: UpdateProgressCbFunc
) {
    return async (dispatch, getState, services: ThunkServices): Promise<DtaasServicesUploadResult> => {
        return services.dtaasServices
            .uploadToDtaasIfNeeded(accountId, projectName, narratorId, narrationData, selectedVoice, scenes, narrationParts, version)
            .then((narrationPartsUploadResultArray: NarrationPartUploadType[]) => {
                if (updateProgressCb) {
                    updateProgressCb(100);
                }

                let updatedNarrationParts: { [key: string]: any } = {};
                let uploadedNeeded: boolean = false;
                narrationPartsUploadResultArray.forEach((narrationPartResult) => {
                    if (narrationPartResult.uploaded) {
                        updatedNarrationParts[narrationPartResult.id] = narrationPartResult;
                        uploadedNeeded = true;
                    }
                });

                if (uploadedNeeded) {
                    let bulkScenePartUpdates: { [key: string]: any } = {};
                    let narrationsToUpdate: GqlClientUpdateNarrationDtaasPrefixKeyInput[] = [];
                    scenes &&
                        Object.values(scenes).forEach((scene: Scene) => {
                            scene &&
                                scene.sceneParts &&
                                scene.sceneParts.forEach((scenePart: ScenePart) => {
                                    scenePart &&
                                        scenePart.NarrationParts &&
                                        scenePart.NarrationParts.forEach((narrationPart: NarrationPart, narrationPartIndex: number) => {
                                            if (updatedNarrationParts[narrationPart.id]) {
                                                const updatedKey = updatedNarrationParts[narrationPart.id].key;
                                                const bulkScenePartUpdateKey = `${scene.id}/${scenePart.scenePart}`;
                                                let newScenePart: ScenePart = bulkScenePartUpdates[bulkScenePartUpdateKey] || { ...scenePart };
                                                newScenePart.NarrationParts = [...newScenePart.NarrationParts];
                                                newScenePart.NarrationParts[narrationPartIndex] = { ...narrationPart, key: updatedKey };
                                                bulkScenePartUpdates[bulkScenePartUpdateKey] = newScenePart;
                                                narrationsToUpdate.push({ id: narrationPart.id, dtaasPrefixKey: updatedKey, updated: IGNORE_UPDATED });
                                            }
                                        });
                                });
                        });

                    // bulk update scene parts
                    const isPostgresOnly: boolean = StateReaderUtils.isProgramBulkDynamoMigrationDoneByLegacyId(getState(), projectName, BulkId.MostEntities);
                    let dynamoPromise;
                    let gqlPromise: Promise<FetchResult<GqlClientUpdateNarrationsDtaasPrefixKeyMutation>>;
                    if (!isPostgresOnly) {
                        dynamoPromise = services.wireframes.bulkUpdateProjectWireframesSceneParts(accountId, projectName, bulkScenePartUpdates);
                        gqlPromise = Promise.resolve(null);
                    }
                    else {
                        dynamoPromise = Promise.resolve(null);
                        let input: GqlClientUpdateNarrationsDtaasPrefixKeyInput = {
                            programVersionId: StateReaderUtils.getBuilderProgramAndDraftVersionIds(getState(), projectName).programVersionId,
                            narrationsToUpdate
                        };
                        gqlPromise = services.graphQlClient.mutate({
                            mutation: UpdateNarrationsDtaasPrefixKeyDocument,
                            variables: {
                                input: input,
                                dryRun: false
                            }
                        });
                    }
                    return Promise.all([dynamoPromise, gqlPromise, bulkScenePartUpdates, narrationPartsUploadResultArray]);
                }
                else {
                    return Promise.all([undefined, undefined, undefined, narrationPartsUploadResultArray]);
                }
            })
            .then(([responseBody, gqlResponse, bulkScenePartUpdates, narrationPartsUploadResultArray]) => {
                if (bulkScenePartUpdates) {
                    const allScenePartsSucceededDynamo = responseBody && Object.keys(responseBody).every((scenePartId) => responseBody[scenePartId].status === 200);
                    const gqlSucceeded =
                        (gqlResponse as FetchResult<GqlClientUpdateNarrationsDtaasPrefixKeyMutation>)?.data?.updateNarrationsDtaasPrefixKey?.result ===
                        GqlClientUpdateNarrationsDtaasPrefixKeyResult.SUCCESS;
                    if (allScenePartsSucceededDynamo || gqlSucceeded) {
                        dispatch(ChangingBulkScenePartsSuccess(accountId, projectName, bulkScenePartUpdates));
                    }
                }

                // Calculate all narration part keys for VLX: narrationPartName -> key
                return narrationPartsUploadResultArray.reduce(
                    (acc, current) => {
                        acc.narrationPartNameToKey[current.narrationPartName] = current.key;
                        acc.narrationPartIdToKey[current.id] = current.key;
                        return acc;
                    },
                    { narrationPartNameToKey: {}, narrationPartIdToKey: {} }
                );
            });
    };
};

export const loadProjectLifecycleHistory = memoizeThunkAction((accountId: string, projectName: string) => {
    return async function(dispatch, getState, services: ThunkServices) {
        let programId: string = StateReaderUtils.getProgramId(getState(), projectName);

        const gqlProgramLifecycleHistory = await services.graphQlClient
            .query<GqlClientGetProgramLifecycleHistoryQuery>({
                fetchPolicy: "no-cache",
                query: GetProgramLifecycleHistoryDocument,
                variables: {
                    programId: programId
                }
            })
            .catch(() => null);
        const lifecycleHistory = convertGqlLifecycleHistoryResult(gqlProgramLifecycleHistory);

        lifecycleHistory.forEach((lifecycleItem) => {
            dispatch(loadingProjectLifeCycleStageSuccess(accountId, projectName, lifecycleItem, lifecycleItem.name));
        });
    };
});

export const revertToVersion = function(accountId: string, projectName: string, snapshotNumber: number, cb: () => void, failureCb: () => void) {
    return function(dispatch, getState, services) {
        dispatch(setSoftLoading(true));
        const snapshotDetails: Snapshot = StateReaderUtils.getSnapshotDetails(getState(), snapshotNumber);
        let input: GqlClientCreateProgramVersionDraftFromSnapshotInput = {
            sourceId: snapshotDetails.graphQLId
        };

        return services.graphQlClient.mutate({
            mutation: CreateProgramVersionDraftFromSnapshotDocument,
            variables: { input },
            context: {
                allowedModes: [EDITING_MODE, STAGE_MODE]
            }
        })
            .then(() => {
                if (typeof cb === "function") {
                    cb();
                }
                dispatch(setSoftLoading(false));
            })
            .catch(() => {
                if (typeof failureCb === "function") {
                    failureCb();
                }
                dispatch(setSoftLoading(false));
            });
    };
};
