import type { AuthServiceInterface } from "@sundaysky/smartvideo-hub-auth";
import type { UserDataResponse } from "../../../common/types/restApiTypes";
import type { BuilderModeRetrieverInterface } from "../../components/BuilderModeRetriever";
import { BaseServices } from "../common/BaseServices";
import { EDITING_MODE, STAGE_MODE } from "../common/Consts";


export type UserData = UserDataResponse;

export type Account = UserDataResponse["accounts"][number];

export class UserServices extends BaseServices {
    constructor(authService: AuthServiceInterface, builderModeRetriever: BuilderModeRetrieverInterface) {
        super(authService, builderModeRetriever);
    }

    getUserData() {
        return this.get<UserData>(
            [EDITING_MODE, STAGE_MODE],
            "/rest/userData/"
        );
    }

    getCurrentAccount() {
        return this.get<Account>(
            [EDITING_MODE, STAGE_MODE],
            "/rest/selectedAccount/"
        );
    }

    switchAccount(newAccount: { accountId: string; name: string; }) {
        return this.put(
            [EDITING_MODE, STAGE_MODE],
            "/rest/selectedAccount/",
            newAccount
        );
    }

    getUserName() {
        return this.getAuthService().getUserName();
    }

    logout() {
        return this.getAuthService().logout();
    }
}
