import type {
    GqlClientCreateProgramVersionDraftInput,
    GqlClientCreateProgramVersionDraftMutationVariables,
    GqlClientUpdateProgramVersionDraftInput,
    GqlClientUpdateProgramVersionDraftMutationVariables } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import {
    GqlClientCopyProgramVersionDraftResult,
    GqlClientCreateProgramVersionDraftResult,
    withCopyProgramVersionDraft,
    withCreateProgramVersionDraft,
    withDeleteProgramVersionDraft,
    withMovePorgramVersionDraft,
    withUpdateProgramVersionDraft
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import type { withGqlMutationProps } from "../../../../components/framework/WithGqlMutations";
import type { HOC, IFrameworkEntity, ProgramVersionOnSaveArgs } from "../../types";
import type { ReorderEntitiesDialogProps } from "../../../../components/framework/dialogs/ReorderEntitiesDialog";
import type { DraftDialogProps } from "../../../../components/framework/dialogs/DraftDialog";
import type { DraftsProps } from "../../../../components/framework/drafts/Drafts";
import { setActiveDraftIdLocalStorage } from "../../utils";
import type { FrameworkContainerProps } from "../../../../components/framework/FrameworkContainer";
import type { ApolloError } from "@apollo/client";

const onSave = (
    args: ProgramVersionOnSaveArgs<
        GqlClientCreateProgramVersionDraftInput & GqlClientUpdateProgramVersionDraftInput,
        GqlClientCreateProgramVersionDraftMutationVariables,
        GqlClientUpdateProgramVersionDraftMutationVariables
    >
) => {
    const { programId, data, createCB, updateCB, dryRun } = args;
    const { id, updated, name, useCase, audienceSize, industry, description, sceneOrder, storyOrder } = data;
    const isNew: boolean = !id;
    if (isNew) {
        const newProgramVersion: GqlClientCreateProgramVersionDraftInput = {
            name,
            useCase,
            audienceSize,
            industry,
            description
        };
        createCB({ variables: { programId, input: newProgramVersion } });
    }
    else {
        const updatedProgramVersion: GqlClientUpdateProgramVersionDraftInput = {
            id,
            updated,
            name,
            useCase,
            audienceSize,
            industry,
            description,
            sceneOrder,
            storyOrder
        };
        updateCB({ variables: { input: updatedProgramVersion, dryRun } });
    }
};

const programVersion: IFrameworkEntity<DraftDialogProps | DraftsProps> & { mutations: { updateEntityOrder: HOC; copy: HOC; move: HOC } } = {
    mutations: {
        create: withCreateProgramVersionDraft<withGqlMutationProps<DraftDialogProps>>({
            name: "createProgramVersion",
            options: ({ setMutationResult, setActiveDraftId, onDraftCreated, setError }) => ({
                onCompleted: ({ createProgramVersionDraft }) => {
                    setMutationResult(createProgramVersionDraft.result);
                    if (createProgramVersionDraft.result == GqlClientCreateProgramVersionDraftResult.SUCCESS) {
                        setActiveDraftIdLocalStorage(createProgramVersionDraft.programVersion.id);
                        setActiveDraftId(createProgramVersionDraft.result);
                        onDraftCreated(createProgramVersionDraft.programVersion.id, false);
                    }
                },
                onError: (error: ApolloError) => {
                    setError(error);
                }
            })
        }),
        update: withUpdateProgramVersionDraft<withGqlMutationProps<DraftDialogProps>>({
            name: "updateProgramVersion",
            options: ({ setMutationResult, setActiveDraftId, setError }) => ({
                onCompleted: ({ updateProgramVersionDraft }) => {
                    setMutationResult(updateProgramVersionDraft.result);
                    if (updateProgramVersionDraft.__typename === "UpdateProgramVersionDraftOutputSuccess") {
                        setActiveDraftIdLocalStorage(updateProgramVersionDraft.programVersion.id);
                        setActiveDraftId(updateProgramVersionDraft.result);
                    }
                },
                onError: (error: ApolloError) => {
                    setError(error);
                }
            })
        }),
        updateEntityOrder: withUpdateProgramVersionDraft<withGqlMutationProps<ReorderEntitiesDialogProps>>({
            name: "updateEntityOrderInDraft",
            options: ({ setMutationResult, setError }) => ({
                onCompleted: ({ updateProgramVersionDraft }) => {
                    setMutationResult(updateProgramVersionDraft.result);
                },
                onError: (error: ApolloError) => {
                    setError(error);
                }
            })
        }),
        copy: withCopyProgramVersionDraft<withGqlMutationProps<FrameworkContainerProps>>({
            name: "copyProgramVersion",
            options: ({ setActiveDraftId, setError }) => ({
                onCompleted: ({ copyProgramVersionDraft }) => {
                    if (copyProgramVersionDraft.result == GqlClientCopyProgramVersionDraftResult.SUCCESS) {
                        setActiveDraftIdLocalStorage(copyProgramVersionDraft.targetProgramVersion.id);
                        setActiveDraftId(copyProgramVersionDraft.result);
                    }
                }
            })
        }),
        move: withMovePorgramVersionDraft<DraftsProps>({
            name: "moveProgramVersion"
        }),
        delete: withDeleteProgramVersionDraft<withGqlMutationProps<DraftsProps>>({
            name: "deleteProgramVersion",
            options: ({ setActiveDraftId }) => ({
                onCompleted: () => {
                    setActiveDraftIdLocalStorage(null);
                    setActiveDraftId(null);
                }
            })
        })
    },
    onSave
};

export default programVersion;
