import { DESCRIPTIVE_TRANSCRIPT, paramType, scope, tag } from "@sundaysky/vlx-runner";
import PropTypes from "prop-types";
import type { LogicJSON } from "../../../common/types/logic";
import type { Asset } from "../../../common/types/asset";
import type { DataElement } from "../../../common/types/dataElement";

export const VLX_PARAMTYPE = paramType;
export const VLX_SCOPE = scope;
export const VLX_FUNCTION_TAGS = tag;
export const ADAVarName = DESCRIPTIVE_TRANSCRIPT;

export const SCENES_REPOSITORY = "scene";
export const CURATED_REPOSITORY = "curated";
export const ANIMATION_REPOSITORY = "animation";

export interface ProgramDataFields {
    dataElements: DataElement[];
    prioritizedListLogic: Array<LogicJSON & { id: string }>
    assets: Asset[];
}

export const LOGIC_DATA_TYPES = Object.freeze({
    Const: "const",
    ValueSetValue: "valueSetValue",
    DataElement: "dataElement",
    Asset: "asset",
    Animation: "animation",
    MappingTable: "mappingTable",
    Variable: "variable"
});

export const LOGIC_MEDIA_TYPES = Object.freeze({
    String: "string",
    Number: "number",
    Date: "datetime",
    URL: "url",
    Boolean: "boolean"
});

export const LogicMediaTypeValues = Object.keys(LOGIC_MEDIA_TYPES).map((key) => LOGIC_MEDIA_TYPES[key]);

export const CURATED_ASSETS_MEDIA_TYPES = Object.freeze({
    Text: "text",
    Image: "image",
    Video: "video",
    Audio: "audio"
});

export const ASSET_TYPES = Object.freeze({
    decisionTable: "decision_table",
    mappingTable: "mapping_table",
    animation: "animation",
    narrator: "narrator",
    curated: "curated",
    recording: "recording",
    story: "story",
    inlineStory: "inlineStory",
    scene: "scene"
});

export const LOGIC_TYPE = Object.freeze({
    SceneValidation: "boolean",
    NextScene: "scene",
    Story: "Story",
    Quality: "quality",
    Ratio: "ratio",
    Animation: "animation",
    Image: "image",
    Video: "video",
    Text: "text",
    Audio: "audio",
    BackgroundAsset: "backgroundAsset",
    DataElementString: "string",
    DataElementNumber: "number",
    DataElementDate: "datetime",
    DataElementBoolean: "de_boolean",
    AnalyticCustomField: "analyticsField",
    Compound: "compound",
    Prioritized: "prioritized",
    PrioritizedSlot: "prioritized-slot",
    Color: "color"
});

export const LogicTypeValues = Object.keys(LOGIC_TYPE).map((key) => LOGIC_TYPE[key]);

export const LOGIC_CONTEXT = Object.freeze({
    Scene: "scene",
    Derived: "derived",
    Placeholder: "placeholder",
    Story: "story",
    StoryRatioAndQuality: "storyRatioAndQuality",
    Narration: "narration",
    Prioritized: "prioritized",
    Soundtrack: "soundtrack",
    BackgroundAsset: "backgroundAsset",
    Program: "program"
});

export const LogicContextValues = Object.keys(LOGIC_CONTEXT).map((key) => LOGIC_CONTEXT[key]);

export const VALIDATION_STATUS = Object.freeze({
    Valid: "valid",
    Partial: "partial",
    Invalid: "invalid"
});

export const VALIDATION_TEXT = Object.freeze({
    ValueIsEmpty: "Value is empty",
    ValueCanBeComplexStringOnlyForStringPlaceholders: "Value can be complex string only for string placeholders",
    SelectedMediaTypeDoesNotMatchPlaceholderType: "Selected media type does not match placeholder type",
    DataElementsOrAssetsNotValid: "Data elements / Assets not valid",
    DataTableNotValid: "Data table is not valid",
    DataElementValueSetNotValid: "Data element value set is not valid",
    AssetIsRequestWaitingForFulfilment: "Asset is Request waiting for fulfilment",
    DerivedDataElementIsNotValid: "Derived Data Element is not valid",
    DerivedDataElementLogicIsNotValid: "Derived Data Element logic is not valid",
    InvalidOperatorForLeftHandSideOperand: "Invalid operator for left-hand side operand",
    OperationMustBeComplete: "Operation must be complete",
    RightHandSideOperandTypeMismatch: "Right-hand side operand type does not match operator",
    OperandFunctionOrFormatterTypeMismatch: "Operand type does not match function or formatter",
    ValueFunctionOrFormatterTypeMismatch: "Value type does not match function or formatter",
    SelectedDataElementIsMissingOrPending: "Selected data element is missing/pending",
    RuleMustHaveValidCondition: "Rule must have valid condition",
    RuleMustHaveAtLeastOneCondition: "Rule must have at least one condition",
    CaseMustHaveAValue: "Case must have a value",
    RuleValueMustBeValid: "Rule value must be valid",
    PlaceholderMustHaveAName: "Placeholder must have a name",
    PrioritizedListIsMissing: "List does not exist",
    PrioritizedListLogicIsInvalid: "List logic is not valid",
    PrioritizedListSlotIsMissing: "List Slot does not exist",
    InputMustHaveADefaultValue: "Input must have a default value",
    PleaseChooseAnElement: "Please choose an element",
    InvalidDataElementSelected: "Invalid data element selected",
    OperatorMustBeValid: "Operator must be valid",
    ShownRuleValueMustBeValid: "Shown rule value must be valid",
    DefaultValueShouldBeValid: "Default value should be valid",
    RuleOperatorsMustBeValid: "Rule Operators must be valid",
    AllCasesMustBeValid: "All Cases must be valid",
    CompoundValuesMustBeValid: "Compound values must be valid",
    HotspotValuesMustBeValid: "Hotspot values must be valid",
    SingleValueMustBeValid: "Single value must be valid",
    CandidateNameMustBeValid: "Candidate name must be valid",
    StoryIsMissing: "Selected story is missing"
});

export const DATA_TABLE_OUTPUT_TYPES = Object.freeze({
    String: "string",
    Url: "external",
    Color: "color",
    Media: "url" // called url for backward compatibility (after moving old type url to be media, and creating new type url as external)
});

export const TEXT_ELEMENTS = [
    {
        name: "\n",
        title: "Line Break",
        value: "\n",
        iconClass: "line-break",
        defaultValue: "\n"
    }
];

export const CYCLIC_ERROR_MESSAGE = "Cyclic Dependency!";

export const HOTSPOT_TYPES = Object.freeze({
    URL: "URL"
});

export const HOTSPOT_ID_PROPERTY_NAME = "id";

export const HOTSPOT_DISPLAY_MAPPERS = Object.freeze({
    [HOTSPOT_TYPES.URL]: {
        url: "Destination URL",
        label: "Label",
        operation: "Reports operation",
        code: "Reports code",
        id: HOTSPOT_ID_PROPERTY_NAME,
        type: "type"
    }
});

export const EXCLUDED_HOTSPOT_KEYS = [HOTSPOT_ID_PROPERTY_NAME, "type", "name", "code"];

export const validationShape = PropTypes.shape({
    status: PropTypes.string,
    report: PropTypes.arrayOf(PropTypes.object),
    message: PropTypes.string
});
