// This class holds all the utilities used to handle scene logic

import { definitions } from "@sundaysky/vlx-runner";
import {
    CURATED_ASSETS_MEDIA_TYPES,
    DATA_TABLE_OUTPUT_TYPES,
    EXCLUDED_HOTSPOT_KEYS,
    HOTSPOT_DISPLAY_MAPPERS,
    HOTSPOT_ID_PROPERTY_NAME,
    HOTSPOT_TYPES,
    LOGIC_DATA_TYPES,
    LOGIC_MEDIA_TYPES,
    LOGIC_TYPE,
    VALIDATION_STATUS,
    VLX_FUNCTION_TAGS,
    VLX_PARAMTYPE
} from "./consts";
import { buildActionableData, getValueFromActionableData } from "../Logics/ActionableDataUtils";
import { parseLogicObject } from "../Logics/Logic";
import type { ActionableData, CompoundValue, HotspotValue, LogicJSON, LogicMediaTypes, PrioritizedSlotActionableData, Rule, ValueSet, VariableActionableData } from "../../../common/types/logic";
import { ActionableDataValueType, LogicMediaType, LogicType } from "../../../common/types/logic";
import { findDataElementById, findDataElementByLogicValue, getDataElementDisplayName, getDataElementId, getDataElementOrigin } from "../DataElements/DataElementsManager";
import { valueExists } from "../../components/legacyCommon/utils";
import { deepCloneObj } from "../common/generalUtils";
import { mapNumToLetter } from "../../../common/generalUtils";
import { v4 as uuid } from "uuid";
import traverse from "traverse";
import { entityType, LogicContainers, onscreenTypes, placeholderType } from "../../../common/commonConst";
import type { ValidationResult } from "../validations/validationManager";
import { IssueCodes, IssueSeverity } from "../validations/validationManager";
import { EntityTypes } from "../entities/definitions";
import type { DataElement } from "../../../common/types/dataElement";
import { CreativeDataElementContentTypes, DataElementContentTypes } from "../../../common/types/dataElement";
import memoizeOne from "memoize-one";
import StateReaderUtils from "../common/StateReaderUtils";
import * as _ from "lodash";
import type { Asset } from "../../../common/types/asset";
import { AssetTypes } from "../../../common/types/asset";
import { getPrioritizedListIdFromActionableDataId } from "../builder/editorsContainerLogic/prioritizedListUtils";
import type { NarrationPart, PlaceholderType, Scene, ScenePart } from "../../../common/types/scene";
import type { DataTable } from "../../../common/types/dataTable";
import type { PrioritizedList } from "../common/types";
import type { PARAMTYPE } from "@sundaysky/vlx-types";
import { isFullyTransparentColor } from "../builder/editorsContainerLogic/styleUtils";

type ElementInfo = { displayName: string; refName?: string; mediaType?: string; origin?: string };
type Context = { dataElements?: DataElement[], assets?: Asset[], prioritizedLists?: PrioritizedList[] };

export const VALIDATION_PROGRAM_SETTINGS_LABEL: string = "Program Settings";
export const VALIDATION_ANIMATED_WIREFRAMES_COMPATIBILITY_LABEL: string = "Animated Wireframes Compatibility";
export const VALIDATION_SEVERITY_LABEL: string = "severity";

export function valueCanBeFromValueSet(value) {
    if (typeof value === "string") {
        return true;
    }
    else if (Array.isArray(value) && value.length === 1) {
        return true;
    }
    else if (!Array.isArray(value) && typeof value === "object") {
        return true;
    }
    return false;
}

export function getMediaTypeByValue(value) {
    if (Array.isArray(value) || typeof value === "string") {
        return LOGIC_MEDIA_TYPES.String;
    }
    else if (typeof value === "object") {
        return value.mediaType;
    }
    else {
        return undefined;
    }
}

const functions = definitions ?? [];

function getAvailableFunctions() {
    return functions;
}

function getLhsMediaType(lhs: ActionableData | null, context: Pick<Context, "dataElements">) : LogicMediaTypes {
    if (!lhs) {
        return null;
    }
    if (lhs.actions?.length) {
        return convertVlxParamTypeToLogicMediaType(lhs.actions[lhs.actions.length - 1].output);
    }
    else {
        return getElementInfoFromActionableData(lhs, context).mediaType as LogicMediaTypes || lhs.mediaType;
    }
}

function getAvailableOperatorsForObject(lhsMediaType: LogicMediaTypes) {
    if (!functions || !functions.length) return null;

    //
    let mediaType = convertLogicMediaTypToVlxParamType(lhsMediaType);

    let actions = functions.filter(
        (func) => func.input.some((input) => input === mediaType) &&
            func.tag.some((tag) => tag === VLX_FUNCTION_TAGS.Operator) &&
            (!func.params || func.params.length < 2) // todo - yoav - remove this condition once in between dates is supported by UI
    );

    // If no actions, return empty array.
    if (actions.length === 0) return [];

    let booleanOps = actions.filter((act) => act.output === VLX_PARAMTYPE.Boolean);

    let numericOps = actions.filter((act) => act.output === VLX_PARAMTYPE.Numeric);

    let stringOps = booleanOps.map((act) => act.text);

    let numericExtended = numericOps.reduce((opsArray, act) => {
        return opsArray.concat([
            `${act.text} =`,
            `${act.text} >`,
            `${act.text} <`
            // `${act.text} < ${act.text} in`,  // todo - removing since these are not supported
            // `${act.text} > ${act.text} in`
        ]);
    }, []);

    stringOps = numericExtended.concat(stringOps);

    if (mediaType === VLX_PARAMTYPE.Numeric) {
        stringOps = stringOps.concat(["smaller than", "greater than", "small or equal than", "great or equal than"]);
    }

    return stringOps;
}

function areActionsEqual(act1, act2) {
    // *Currently* actions are flat objects so STRINGIFY will work
    return JSON.stringify(act1) === JSON.stringify(act2);
    /*TODO:*/
    // if (act1 === act2) return true;
    // if (!act1 && act2 || !act2 && act1) return false;
    // return (act1.name === act2.name &&
    //     act2.args
    // );
}

function areActionsArrayEqual(actArr1, actArr2) {
    if (actArr1 === actArr2) return true;
    if ((actArr1 && !actArr2) || (!actArr1 && actArr2)) return false;
    return actArr1.length === actArr2.length && actArr1.every((act1) => actArr2.some((act2) => areActionsEqual(act1, act2)));
}

function areLogicValuesEqual(value1, value2) {
    if (value1 === undefined && value2) return false;
    if (value2 === undefined && value1) return false;
    if (value1 === value2) return true;
    return (
        value1.name === value2.name &&
        value1.mediaType === value2.mediaType &&
        value1.type === value2.type &&
        value1.value === value2.value &&
        value1.id === value2.id &&
        areActionsArrayEqual(value1.actions, value2.actions)
    );
}

function capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

function filterDataElementsByMediaType(dataElements, inputType) {
    if (!dataElements) {
        return dataElements;
    }
    else if (!inputType || inputType === LOGIC_TYPE.Text) {
        return dataElements.filter((de) => de.type !== "mappingTable");
    }
    else {
        return dataElements.filter((de) => de.type === convertLogicTypeToDataElementType(inputType) || de.type === getCreativeDataElementType(inputType));
    }
}

function getCreativeDataElementType(logicType) {
    switch (logicType) {
        case LOGIC_TYPE.Image:
            return CreativeDataElementContentTypes.Image;
        case LOGIC_TYPE.Video:
            return CreativeDataElementContentTypes.Video;
        case LOGIC_TYPE.Audio:
            return CreativeDataElementContentTypes.Audio;
        case LOGIC_TYPE.Color:
            return CreativeDataElementContentTypes.Color;
    }
}

function convertLogicTypeToDataElementType(logicType) {
    switch (logicType) {
        case LOGIC_TYPE.Compound: // hotspot
        case LOGIC_TYPE.Text:
            return LOGIC_MEDIA_TYPES.String;
        case LOGIC_TYPE.DataElementNumber:
            return LOGIC_MEDIA_TYPES.Number;
        case LOGIC_TYPE.DataElementDate:
            return LOGIC_MEDIA_TYPES.Date;
        case LOGIC_MEDIA_TYPES.Boolean:
        case LOGIC_TYPE.DataElementBoolean:
            return LOGIC_MEDIA_TYPES.Boolean;
        case LOGIC_TYPE.Audio:
        case LOGIC_TYPE.Image:
        case LOGIC_TYPE.Video:
        case LOGIC_MEDIA_TYPES.URL:
            return LOGIC_MEDIA_TYPES.URL;
    }
    return LOGIC_TYPE.Text;
}

function convertVlxParamTypeToLogicMediaType(type): LogicMediaTypes {
    switch (type) {
        case VLX_PARAMTYPE.Boolean:
            return LogicMediaType.Boolean;
        case VLX_PARAMTYPE.Date:
            return LogicMediaType.Date;
        case VLX_PARAMTYPE.Numeric:
            return LogicMediaType.Number;
        case VLX_PARAMTYPE.String:
        default:
            return LogicMediaType.String;
    }
}

function convertLogicMediaTypToVlxParamType(type: LogicMediaTypes): PARAMTYPE {
    switch (type) {
        case LogicMediaType.Number:
            return VLX_PARAMTYPE.Numeric;
        case LogicMediaType.String:
            return VLX_PARAMTYPE.String;
        case LogicMediaType.Date:
            return VLX_PARAMTYPE.Date;
        case LogicMediaType.Boolean:
            return VLX_PARAMTYPE.Boolean;
        case LogicMediaType.Url:
            return VLX_PARAMTYPE.URL;
        case LogicMediaType.Color:
            return VLX_PARAMTYPE.Color;
        case LogicMediaType.List:
            return VLX_PARAMTYPE.List;
        case LogicMediaType.ListSlot:
            return VLX_PARAMTYPE.ListItem;
    }
}

function hasRhs(op) {
    // Todo: validate upon meta-data from VLX
    return op && !["is empty", "is filled", "exists", "does not exist", "is true", "is false", "is false or empty", "then"].some((unary) => unary.toUpperCase() === op.toUpperCase());
}

function getRhsMediaType(operator, lhsMediaType: LogicMediaTypes | null) {
    if (!hasRhs(operator)) return null;

    let op = functions.find((op) => op.text === operator);

    // Assume operators which aren't present are numeric extenders,
    // then return number or string if has 'in'
    if (!op) {
        if (operator.endsWith("in")) {
            return LOGIC_MEDIA_TYPES.String;
        }
        return LOGIC_MEDIA_TYPES.Number;
    }

    // RHS type is the type of the first param if exists
    if (op.params && op.params.length > 0) {
        if (op.params[0].type === VLX_PARAMTYPE.Any) {
            return lhsMediaType || LogicMediaType.String;
        }
        else {
            return convertVlxParamTypeToLogicMediaType(op.params[0].type);
        }
    }

    return convertVlxParamTypeToLogicMediaType(op.output);
}

function getRhsValueSet(operator, lhs, dataElements) {
    // value set is available only for operators 'equals' and 'not equals', and that the left part has no actions on it.
    if ((operator === "equals" || operator === "not equals") && lhs && (!lhs.actions || !lhs.actions.length)) {
        return getValueSetForOperand(lhs, dataElements);
    }

    return null;
}

/**
 * Gets value set from operand if it is a data element.
 * Value set of derived data element is retrieved from its logic, and value set from feed data element is saved as a field on it.
 * @param operand operand object
 * @param dataElements list of data elements
 * @returns {Array} Value set of the data element if exists and if prop useValueSet is true, or null.
 */
function getValueSetForOperand(operand, dataElements): ActionableData[] {
    if (operand.type === LOGIC_DATA_TYPES.DataElement) {
        let dataElement = findDataElementByLogicValue(operand, dataElements);
        // let dataElement = dataElements.find(de => de.name === operand.name);
        //return dataElement && dataElement.getValueSet ? dataElement.getValueSet() : null;
        let values = dataElement && dataElement.getValueSet ? dataElement.getValueSet() : null;
        if (values) {
            let dataElementId = getDataElementId(dataElement);
            let mediaType = convertDataElementTypeToLogicMeidaType(dataElement.type);

            return values
                .filter((value) => value)
                .map((value) => {
                    return buildActionableData({ id: dataElementId, value: value.id, displayName: value.dn, dataType: LOGIC_DATA_TYPES.ValueSetValue, mediaType: mediaType });
                });
        }

        return null;
    }
}

/*
 * Cleanup input logic. This is for backward compatibility of old logic with leftovers, so that they won't fail new validations
 * Remove RHS for when not needed
 */
function cleanInputLogic(logicObject) {
    if (logicObject) {
        let modified = false;
        let cleanLogicObject = Object.assign({}, logicObject);
        if (cleanLogicObject.rules && cleanLogicObject.rules.length > 0) {
            cleanLogicObject.rules.forEach((rule) => {
                if (rule.whens && rule.whens.length > 0) {
                    rule.whens.forEach((when) => {
                        if (valueExists(when.lhs) && when.operator && valueExists(when.rhs)) {
                            if (!hasRhs(when.operator)) {
                                when.rhs = undefined;
                                modified = true;
                            }
                            else {
                                // should have rhs
                                let rhsMediaType = getRhsMediaType(when.operator, when.lhs?.mediaType);
                                if (rhsMediaType === LOGIC_MEDIA_TYPES.Number && typeof when.rhs === "string") {
                                    let rhs = Number(when.rhs);
                                    if (!isNaN(rhs)) {
                                        when.rhs = buildActionableData(rhs, LogicMediaType.Number);
                                        modified = true;
                                    }
                                }
                                else if (rhsMediaType === LOGIC_MEDIA_TYPES.String && typeof when.rhs === "number") {
                                    when.rhs = buildActionableData(when.rhs.toString(), LogicMediaType.String);
                                    modified = true;
                                }
                            }
                        }
                    });
                }
            });
        }
        if (cleanLogicObject.outputType === "parameter") {
            cleanLogicObject.outputType = LogicType.Text;
            modified = true;
        }
        return modified ? cleanLogicObject : logicObject;
    }
    return logicObject;
}

function getLogicValueFromValueSetValue(value, logicType) {
    switch (logicType) {
        case LOGIC_TYPE.Text:
            return [value];
        case LOGIC_TYPE.DataElementNumber:
            return buildActionableData({ dataType: LOGIC_DATA_TYPES.Const, value: Number(value), mediaType: LOGIC_MEDIA_TYPES.Number });
        case LOGIC_TYPE.DataElementBoolean:
            return buildActionableData({ dataType: LOGIC_DATA_TYPES.Const, value: value === "true", mediaType: LOGIC_MEDIA_TYPES.Boolean });
        default:
            return value;
    }
}

function createLogic(outputType: LogicType, defaultValue: any, defaultValueShow?: boolean, isLast?: boolean, rules?: Rule[]): LogicJSON {
    return {
        rules: rules || [],
        defaultValue: defaultValue,
        defaultValueShow: defaultValueShow || true,
        outputType: outputType
    };
}

function createLogicFromValueSet(valueSet: ValueSet, logicType: LogicType): LogicJSON {
    let rulesArr: Rule[] = [];
    valueSet.forEach((value) => {
        rulesArr.push({
            key: uuid(),
            convertedKey: value.id,
            whens: [],
            value: getLogicValueFromValueSetValue(value.dn, logicType)
        });
    });

    return {
        rules: rulesArr,
        defaultValue: logicType === LOGIC_TYPE.DataElementBoolean ? false : null,
        defaultValueShow: logicType === LOGIC_TYPE.DataElementBoolean,
        outputType: logicType
    };
}

function createLogicWithDerived(derived, outputType) {
    let mediaType = convertLogicTypeToDataElementType(outputType);
    let shouldDefaultValueBeAnArray = !(mediaType === LOGIC_MEDIA_TYPES.URL);
    let defaultValueValue = {
        id: derived.id,
        mediaType: mediaType,
        type: LOGIC_DATA_TYPES.DataElement,
        actions: []
    };

    let logicObj: LogicJSON = {
        rules: [],
        defaultValue: defaultValueValue,
        defaultValueShow: true,
        outputType: outputType
    };

    if (shouldDefaultValueBeAnArray) {
        logicObj.defaultValue = [defaultValueValue];
    }

    return logicObj;
}

function validationStatusToString(status) {
    switch (status) {
        case VALIDATION_STATUS.Invalid:
            return "invalid";
        case VALIDATION_STATUS.Partial:
            return "partial";
        case VALIDATION_STATUS.Valid:
        default:
            return "valid";
    }
}

function getAnimationLogicName(sceneId) {
    return `${sceneId}_scene_animation`;
}

/***
 * Returns the name of a mapping table if a value is defined in a mapping table
 * @param valueItem a valid logic's actionable data element
 * @return the name of the mapping table
 */
function getMappingTableNameFromValue(valueItem) {
    if (!valueItem || !valueItem.type || valueItem.type !== LOGIC_DATA_TYPES.MappingTable) {
        return null;
    }
    const value = getValueFromActionableData(valueItem);
    if (!value) {
        return null;
    }
    if (!value || !value.includes(".")) return value;
    return value.split(".")[0];
}

function getMappingTablePropertyFromValue(valueItem) {
    if (!valueItem || !valueItem.type || valueItem.type !== LOGIC_DATA_TYPES.MappingTable) {
        return null;
    }
    else if (valueItem.id && valueItem.id.includes(".") && valueItem.id.split(".").length > 1) {
        return valueItem.id.split(".")[1];
    }
    else if (valueItem.name && valueItem.name.includes(".") && valueItem.name.split(".").length > 1) {
        return valueItem.name.split(".")[1];
    }
    else {
        return null;
    }
}

/**
 * Returns a list of data element ids which are used as inputs to a given mapping table
 */
function getDataElementIdsFromMappingTable(mappingTable) {
    let dataElementIds = [];

    if (mappingTable && mappingTable.mappingTableScheme) {
        let inputs = mappingTable.mappingTableScheme.inputs;
        if (inputs) {
            inputs.forEach((input) => {
                let dataElementId = input.id || input.name;
                dataElementIds.push(dataElementId);
            });
        }
    }

    return dataElementIds;
}

/**
 * This function will return an array from a validation object (containing a report)
 * about the given object
 * @param validationObject {
 *      status: <VALIDATION_STATUS>,
 *      message: <VALIDATION_MESSAGE>,
 *      report: <NESTED_REPORT_ARRAY>,
 *      item: <WHICH ITEM THE REPORT IS ABOUT>
 * }
 */
function getValidationReportOnObject(validationObject, item) {
    if (!validationObject || !validationObject.report) return validationObject;
    // Find all reports about the item
    const itemReports = validationObject.report.filter(
        (vObj) =>
            vObj && (vObj.item === item || (!vObj.item && !item) || (!vObj.item && Array.isArray(item) && item.length === 0) || (vObj.item && vObj.item.length === 0 && item && item.length === 0))
    );
    // Concat the reports to a single array
    return itemReports.reduce((acc, item) => acc.concat(item.report ? item.report : item), []);
}

function convertDataElementTypeToLogicMeidaType(deType) {
    switch (deType) {
        case DataElementContentTypes.Number:
            return LOGIC_MEDIA_TYPES.Number;

        case DataElementContentTypes.Date:
            return LOGIC_MEDIA_TYPES.Date;

        case DataElementContentTypes.Boolean:
            return LOGIC_MEDIA_TYPES.Boolean;

        case CreativeDataElementContentTypes.Video:
        case CreativeDataElementContentTypes.Image:
        case CreativeDataElementContentTypes.Audio:
        case DataElementContentTypes.Url:
            return LOGIC_MEDIA_TYPES.URL;

        case CreativeDataElementContentTypes.Color:
            return LOGIC_TYPE.Color;

        case DataElementContentTypes.String:
        default:
            return LOGIC_MEDIA_TYPES.String;
    }
}

function convertDataTblOutputTypeToLogicMediaType(outputType) {
    switch (outputType) {
        case DATA_TABLE_OUTPUT_TYPES.Media:
            return LOGIC_MEDIA_TYPES.URL;
        case DATA_TABLE_OUTPUT_TYPES.Color:
            return LOGIC_TYPE.Color;
        case DATA_TABLE_OUTPUT_TYPES.Url:
        case DATA_TABLE_OUTPUT_TYPES.String:
        default:
            return LOGIC_MEDIA_TYPES.String;
    }
}

function convertAssetMediaTypeToLogicMediaType(assetMediaType) {
    switch (assetMediaType) {
        case CURATED_ASSETS_MEDIA_TYPES.Text:
            return LOGIC_MEDIA_TYPES.String;
        case CURATED_ASSETS_MEDIA_TYPES.Image:
        case CURATED_ASSETS_MEDIA_TYPES.Video:
        case CURATED_ASSETS_MEDIA_TYPES.Audio:
            return LOGIC_MEDIA_TYPES.URL;
        default:
            return assetMediaType;
    }
}

function getAssetTitleFromName(name, assets) {
    const obj = buildActionableData({ dataType: LOGIC_DATA_TYPES.Asset, value: name });
    const objectMeta: ElementInfo = getElementInfoFromActionableData(obj, { assets });
    return objectMeta.displayName || name;
}

/**
 * Gets an array of all the data element IDs within a logic, including those which are "hidden" within mapping tables.
 * Note: Some of the returned Ids may be of DELETED data elements! This function doesn't check if they exist.
 * Note #2: The returned IDs are the IDs as they appear in the LOGIC. In old projects, theses IDs may be the names of data elements.
 * @param logic the logic object
 * @param mappingTables a list of all the mapping tables
 */
function extractDataElementIdsFromLogic(logic: any, mappingTables): string[] {
    let set = traverse(logic).reduce((acc, node) => {
        if (node && node.type === LOGIC_DATA_TYPES.DataElement) {
            let dataElementId = getValueFromActionableData(node);
            acc.add(dataElementId);
        }
        // Find the relevant inputs (which are data elements)
        else if (mappingTables && node && node.type === LOGIC_DATA_TYPES.MappingTable) {
            let mappingTableName = getMappingTableNameFromValue(node);

            let mappingTable = mappingTables.find((mappingTable) => mappingTable.name === mappingTableName);
            let dataElementIds = getDataElementIdsFromMappingTable(mappingTable);
            dataElementIds.forEach((dataElementId) => acc.add(dataElementId));
        }
        return acc;
    }, new Set<string>());

    return Array.from(set);
}

// Todo - Or: improve performance
/**
 * This function extracts data field meta data (such as media type, refName, and displayName)
 * from logic actionable data representing the element
 * @param actionableDataObject - The actionable data object (representing DataElement / Asset / Mapping Table in the logic)
 * @param dataFields: { assets: Array<Asset>, dataElements: Array<DataElement> } - An Object data fields of the project (dataElements, assets)
 * @returns object: {
 *      displayName: string - representing the user displayed name of the field,
 *      refName: string - representing the id OR s3-reference-name of the field
 *      mediaType: <LogicMediaType> - representing the LOGIC_MEDIA_TYPE of the output element
 *   }
 */
function getElementInfoFromActionableData(actionableDataObject: ActionableData, dataFields: Context): ElementInfo {
    const error = {
        displayName: actionableDataObject.name,
        mediaType: actionableDataObject.mediaType
    };
    switch (actionableDataObject.type) {
        case LOGIC_DATA_TYPES.DataElement: {
        // Find the latest element
            const currentItem = findDataElementByLogicValue(actionableDataObject, dataFields.dataElements);
            if (!currentItem) return error;
            const displayName = getDataElementDisplayName(currentItem);
            const origin = getDataElementOrigin(currentItem);
            return {
                displayName,
                refName: displayName,
                mediaType: convertDataElementTypeToLogicMeidaType(currentItem.type),
                origin
            };
        }
        case LOGIC_DATA_TYPES.ValueSetValue: {
        //Find the latest value
            const currentItem = findDataElementById(actionableDataObject.id, dataFields.dataElements);
            if (currentItem) {
                let valueSet = currentItem.getValueSet();
                let value = valueSet && valueSet.find((value) => value && value.id === actionableDataObject.name);
                if (value) {
                    return {
                        displayName: value.dn,
                        refName: value.dn,
                        mediaType: currentItem.type
                    };
                }
            }

            return {
                displayName: actionableDataObject.displayName,
                refName: actionableDataObject.displayName,
                mediaType: actionableDataObject.mediaType
            };
        }
        case LOGIC_DATA_TYPES.MappingTable: {
        // Find latest mapping table
            const dataTable: DataTable = (dataFields.assets || []).find(
                (asset) => asset.name === getMappingTableNameFromValue(actionableDataObject) && asset.type === AssetTypes.mappingTable && !asset.archived && !asset.deleted
            ) as DataTable;

            if (!dataTable) return error;

            // If we don't have inputs, return the mapping table itself
            if (!getMappingTablePropertyFromValue(actionableDataObject)) {
                return {
                    displayName: dataTable.title,
                    refName: dataTable.name
                };
            }

            // Find the latest output
            const output =
                dataTable &&
                Array.isArray(dataTable.mappingTableScheme.outputs) &&
                dataTable.mappingTableScheme.outputs.find((output) => (output.id && actionableDataObject.id ? output.id : output.name) === getMappingTablePropertyFromValue(actionableDataObject));

            if (!output) return error;

            return {
            // Store updated mediaType
                displayName: `${dataTable.title} - ${output.name}`,
                refName: `${dataTable.name}.${output.id ? output.id : output.name}`,
                mediaType: convertDataTblOutputTypeToLogicMediaType(output.type)
            };
        }
        case LOGIC_TYPE.Prioritized: {
            const prioritizedList = (dataFields.prioritizedLists || []).find(list => list.id === actionableDataObject.id);
            return {
                displayName: prioritizedList?.name || "UNKNOWN LIST",
                refName: prioritizedList?.name,
                mediaType: actionableDataObject.mediaType
            };
        }
        case LOGIC_TYPE.PrioritizedSlot: {
            const listActionableData = actionableDataObject as PrioritizedSlotActionableData;
            const listId = getPrioritizedListIdFromActionableDataId(listActionableData.id);
            const prioritizedList = (dataFields.prioritizedLists || []).find(list => list.id === listId);
            return {
                displayName: `${prioritizedList?.name || "UNKNOWN LIST"} - ${mapNumToLetter(listActionableData.slotIdx)}`,
                refName: prioritizedList?.name,
                mediaType: listActionableData.type
            };
        }
        case ActionableDataValueType.Variable: {
            return {
                displayName: actionableDataObject.name,
                refName: (actionableDataObject as VariableActionableData).path,
                mediaType: actionableDataObject.mediaType
            };
        }
        case LOGIC_DATA_TYPES.Asset:
        case LOGIC_DATA_TYPES.Animation: {
            const element = (dataFields.assets || []).find((asset) => asset.name === actionableDataObject.name && !asset.archived && !asset.deleted);
            if (!element) return error;
            return {
                displayName: element.title,
                refName: element.name,
                mediaType: convertAssetMediaTypeToLogicMediaType(element.mediaType)
            };
        }
        case LOGIC_DATA_TYPES.Const:
        default: {
            const { mediaType, name } = actionableDataObject;
            return { displayName: name, refName: name, mediaType };
        }
    }
}

/**
 * Find the asset the actionable data is representing
 * @param actionableDataObj - An <ActionableData> object
 * @param assetsArr - An array of all system assets
 */
function getAssetFromActionableData(actionableDataObj, assetsArr) {
    const assetName = getValueFromActionableData(actionableDataObj);

    if (!assetName) return null;

    return (assetsArr || []).find((asset) => asset.name === assetName);
}

// groups and compoundValues

function compareCompoundValues(v: CompoundValue | CompoundValue[], u: CompoundValue | CompoundValue[]) {
    if (Array.isArray(u)) {
        return -1;
    }
    else if (Array.isArray(v)) {
        return 1;
    }
    else {
        return v.displayName.localeCompare(u.displayName);
    }
}

/***
 * returns true if testee has characteristics of compound value
 * testee.show can be undefined so it isn't checked.
 * @param testee - item to test if it's compound value
 */
function isCompoundValue(testee) {
    return testee.id && testee.displayName && testee.value !== undefined && testee.outputType;
}

/***
 * returns true if testee is array and has characteristics of compound value, recursively
 * @param testee - item to test if it's array of compound values
 */
function isArrayOfCompoundValues(testee) {
    return testee && Array.isArray(testee) && testee.length > 0 && testee.every((value) => isCompoundValue(value) || isArrayOfCompoundValues(value));
}

/***
 * Creates empty compound value
 * @param phArray - [{ "name": "placeHolderName--abc", "type": "text", "id": "phId" }, ...}]
 * id is optional
 * @returns emptyCompoundValue - array of compound values
 * [{ "id": "xx",
      "displayName": "placeHolderName--abc",
      "outputType": "text",
      "value": null
    }]
 */
function createEmptyCompoundValue(phArray): CompoundValue[] {
    const emptyCompoundValue = phArray.map((ph) => {
        if (ph.type === onscreenTypes.Hotspot) {
            return {
                id: ph.id || ph.name,
                displayName: ph.name,
                value: createEmptyCompoundValue(createHotspotInputArray(HOTSPOT_TYPES.URL)),
                outputType: LOGIC_TYPE.Compound,
                show: true
            };
        }
        return {
            id: ph.id || ph.name,
            displayName: ph.name,
            outputType: ph.type,
            value: getDefaultValue(ph.id),
            show: true
        };
    });
    return emptyCompoundValue;
}

function getDefaultValue(itemKey) {
    if (itemKey === HOTSPOT_ID_PROPERTY_NAME) return uuid();
    if (itemKey === "type") return HOTSPOT_TYPES.URL;
    return null;
}

/***
 * finds a value in a recursive compound value
 * return a _ref_ to the value for future change
 * @param compoundValue - the compound value
 * @param phId - the id of the value we're looking for
 * @returns value object who's id matches phId or undefined
 */
function findInCompoundValue(compoundValue, phId) {
    let ref = undefined;
    traverse(compoundValue).forEach((value) => {
        if (value && value.id === phId) {
            ref = value;
        }
    });
    return ref;
}

/***
 * Creates LogicData for group.
 * If inheritLogicDataFromThis is supplied, the logic will populate rules and values in the logic object
 * @param sceneLogic (required)- the scene's logic
 * @param phArray (required) - [{ "name": "placeHolderName--abc", "type": "text"}, ...}]
 * @param inheritLogicDataFromThis - string, name of placeholder.
 * @returns LogicData - logic object
 */
function createInitialCompoundLogic(sceneLogic, phArray, inheritLogicDataFromThis = "") : LogicJSON {
    // build rules for group
    // in each rule, preserves whens and keys. replace value[combined input] with value that contains multiple shows:
    // value[{id, outputType, value[combined input]}]

    const emptyCompoundValue = createEmptyCompoundValue(phArray);

    // if there are rules, use them
    let newRules = sceneLogic[inheritLogicDataFromThis] && sceneLogic[inheritLogicDataFromThis].rules ? deepCloneObj(sceneLogic[inheritLogicDataFromThis].rules) : [];

    // for each rule, add compoundValue and insert the value from selected PH
    newRules.forEach((rule) => {
        const valueToPreserve = deepCloneObj(rule.value);
        rule.value = deepCloneObj(emptyCompoundValue);
        if (inheritLogicDataFromThis && sceneLogic[inheritLogicDataFromThis]) {
            rule.key = uuid();
            rule.value.forEach((vl) => {
                if (vl.id === inheritLogicDataFromThis) {
                    vl.value = valueToPreserve;
                    vl.show = rule.show;
                }
            });
        }
    });

    // set defaultValue
    let newDefaultValue = deepCloneObj(emptyCompoundValue);
    if (sceneLogic[inheritLogicDataFromThis] && sceneLogic[inheritLogicDataFromThis].defaultValue) {
        let defValueRef = newDefaultValue.find((val) => val.id === inheritLogicDataFromThis);
        defValueRef.value = deepCloneObj(sceneLogic[inheritLogicDataFromThis].defaultValue);
    }
    // set defaultValueShow
    const newDefaultValueShow = sceneLogic[inheritLogicDataFromThis] && sceneLogic[inheritLogicDataFromThis].defaultValueShow ? sceneLogic[inheritLogicDataFromThis].defaultValueShow : true;

    const logicData: LogicJSON = {
        outputType: LogicType.Compound,
        defaultValueShow: newDefaultValueShow,
        defaultValue: newDefaultValue,
        rules: newRules
    };

    return logicData;
}

/***
 * Add placeholder(s) to group logic.
 * @param groupLogicData
 * @param phArray - [{ "name": "placeHolderName--abc", "type": "text"}, ...}]
 * @return updatedGroupLogicData
 */
function addPlaceholdersToGroup(groupLogicData, phArray) {
    const emptyCompoundValue = createEmptyCompoundValue(phArray);
    let updatedGroupLogicData = deepCloneObj(groupLogicData);
    if (Array.isArray(updatedGroupLogicData.rules) && updatedGroupLogicData.rules.length > 0) {
        updatedGroupLogicData.rules.forEach((rule) => {
            rule.value = rule.value.concat(emptyCompoundValue);
        });
    }
    if (isArrayOfCompoundValues(updatedGroupLogicData.defaultValue)) {
        updatedGroupLogicData.defaultValue = updatedGroupLogicData.defaultValue.concat(emptyCompoundValue);
    }
    delete updatedGroupLogicData.validation;
    return updatedGroupLogicData;
}

/***
 * Search wireframes for entity by ID and entityType and extract the placeholders' name and type.
 * useful when adding rules' value or defaultValue for groups, prioritized lists or hotspots. (see createEmptyCompoundValue)
 * @param sceneWireFramesData - WireFramesData.scenes[sceneId]
 * @param entityId - the relevant entityId or placeholder name
 * @param typeOfEntity - one of entityType - placeholders, groups, prioritizedLists
 * @return nameAndTypeArr - [{ "name": "placeHolderName--abc", "type": "text"}, ...}]
 */
function extractPlaceholderNamesAndTypesFromWireframes(sceneWireFramesData, entityId, typeOfEntity): {name: string, type: PlaceholderType}[] {
    let placeholdersNamesSet = new Set([]);
    let nameAndTypeArr = [];
    sceneWireFramesData.sceneParts.forEach((scenePart) => {
        if (typeOfEntity === entityType.groups && scenePart.groups) {
            const group = scenePart.groups.find((grp) => grp.id === entityId);
            if (group) {
                const placeholdersInGroup = group.placeholders;
                placeholdersInGroup.forEach((ph) => {
                    if (!placeholdersNamesSet.has(ph.name)) {
                        placeholdersNamesSet.add(ph.name);
                        nameAndTypeArr.push({ name: ph.name, type: ph.type });
                    }
                });
            }
        }
        else if (typeOfEntity === entityType.prioritizedLists && scenePart.prioritizedLists) {
            const pList = scenePart.prioritizedLists.find((list) => list.id === entityId);
            if (pList && pList.slots) {
                pList.slots.forEach((slot) => {
                    if (slot.placeholders) {
                        slot.placeholders.forEach((ph) => {
                            if (!placeholdersNamesSet.has(ph.name)) {
                                placeholdersNamesSet.add(ph.name);
                                nameAndTypeArr.push({ name: ph.name, type: ph.type });
                            }
                        });
                    }
                    else {
                        if (!placeholdersNamesSet.has(slot.name)) {
                            placeholdersNamesSet.add(slot.name);
                            nameAndTypeArr.push({ name: slot.name, type: slot.type });
                        }
                    }
                });
            }
        }
        else if (typeOfEntity === entityType.placeholders && scenePart.placeholders && scenePart.placeholders.find((ph) => ph.name === entityId && ph.type === onscreenTypes.Hotspot)) {
            nameAndTypeArr = createHotspotInputArray(HOTSPOT_TYPES.URL);
        }
    });
    return nameAndTypeArr;
}

/***
 * Rename a placeholder in compound value
 * @param compoundValue - array of compound value objects
 * @param phId - Id of placeholder to replace
 * @param newName - name of placeholder
 * @return newCompoundValue - newCompoundValue duplicate with changes
 */
function renamePlaceholderInCompoundValue(compoundValue, phId, newName) {
    let newCompoundValue = deepCloneObj(compoundValue);
    newCompoundValue.forEach((val) => {
        if (val.id === phId) {
            val.displayName = newName;
            val.id = newName; // TODO remove when placeholder id is constant throughout placeholder life
        }
    });
    return newCompoundValue;
}

/***
 * Rename placeholder in group logic (rules and defaultValue)
 * @param groupLogicData - The groups' logicData
 * @param phId - Id of placeholder to delete
 * @param newName - name of placeholder
 * @param renameAll - should we keep the old name and logic once too or rename all
 * @param isDuplicated - whether the placeholder has more than a signle instance in scene part
 * @return newGroupLogicData - logicData duplicate
 */
function renamePlaceholderInGroup(groupLogicData, phId, newName, renameAll, isDuplicated) {
    let newGroupLogicData = deepCloneObj(groupLogicData);

    // handle default value
    if (renameAll) {
        newGroupLogicData.defaultValue = renamePlaceholderInCompoundValue(newGroupLogicData.defaultValue, phId, newName);
    }
    else {
        const oldPlaceholderValue = newGroupLogicData.defaultValue.find((item) => item.id === phId);
        newGroupLogicData.defaultValue = renamePlaceholderInCompoundValue(newGroupLogicData.defaultValue, phId, newName);

        //Keep the old logic too, since we don't want to rename all.
        if (oldPlaceholderValue && isDuplicated) {
            newGroupLogicData.defaultValue.push(oldPlaceholderValue);
        }
    }

    // handle rules
    newGroupLogicData.rules.forEach((rule) => {
        if (renameAll) {
            rule.value = renamePlaceholderInCompoundValue(rule.value, phId, newName);
        }
        else {
            const oldVal = deepCloneObj(rule.value.find((item) => item.id === phId));
            rule.value = renamePlaceholderInCompoundValue(rule.value, phId, newName);
            oldVal && isDuplicated && rule.value.push(oldVal);
        }
    });
    delete newGroupLogicData.validation;
    return newGroupLogicData;
}

/***
 * Delete placeholder from compoundValue
 * @param compoundValue - array of compound value objects
 * @param phId - Id of placeholder to delete
 * @return newCompoundValue - newCompoundValue duplicate with changes
 */
function removePlaceHolderFromCompoundValue(compoundValue, phId) {
    let newCompoundValue = deepCloneObj(compoundValue);
    return newCompoundValue.filter((val) => val.id !== phId);
}

/***
 * Delete placeholder from group logic (rules and defaultValue)
 * @param groupLogicData - The groups' logicData
 * @param phId - Id of placeholder to delete
 * @return newGroupLogicData - logicData duplicate
 */
function removePlaceHolderFromGroup(groupLogicData, phId) {
    let newGroupLogicData = deepCloneObj(groupLogicData);

    // handle default value
    newGroupLogicData.defaultValue = removePlaceHolderFromCompoundValue(newGroupLogicData.defaultValue, phId);

    // handle rules
    newGroupLogicData.rules.forEach((rule) => {
        rule.value = removePlaceHolderFromCompoundValue(rule.value, phId);
    });
    delete newGroupLogicData.validation;
    return newGroupLogicData;
}

/***
 * Generate placeholder logic data object from group logic
 * Useful when breaking group to separate placeholders
 * @param groupLogicData - The groups' logicData
 * @param phId - Id of placeholder to delete
 * @return placeholderLogicData - logicData object for single placeholder. has rules from group and relevant value
 */
function generatePlaceholderLogicDataFromGroup(groupLogicData, phId) {
    // get outputType from rules or defaultValue
    if (!groupLogicData) return;
    let outputType;
    if (Array.isArray(groupLogicData.rules) && groupLogicData.rules.length > 0 && isArrayOfCompoundValues(groupLogicData.rules[0].value)) {
        outputType = groupLogicData.rules[0].value.find((ph) => ph.id === phId).outputType;
    }
    else if (isArrayOfCompoundValues(groupLogicData.defaultValue)) {
        outputType = groupLogicData.defaultValue.find((ph) => ph.id === phId).outputType;
    }
    else {
        throw `Error: failed to find placeholder '${phId}' outputType in group`;
    }

    const defaultValueShow = groupLogicData.defaultValueShow;
    const defaultValue = groupLogicData.defaultValue.find((ph) => ph.id === phId).value;

    // replace each rule.value with the placeholder's value
    let phRules = deepCloneObj(groupLogicData.rules);
    phRules.forEach((rule) => {
        rule.value = rule.value.find((ph) => ph.id === phId).value;
        rule.key = uuid();
    });

    const placeholderLogicData = {
        outputType,
        defaultValueShow,
        defaultValue,
        rules: phRules
    };

    return placeholderLogicData;
}

/***
 * Find the validation for value.
 * @param validation (required) - validation object, see validationShape in consts for shape
 * @param value - the value who's report we're looking for
 * @returns the last validation object who's item matches the value
 */
function getValueValidationForCompoundValue(validation, value) {
    // find all invalid or partial validation in validation object hierarchy.
    let allNonValidResults = [];
    traverse(validation).forEach(function(node) {
        if (node && node.item !== undefined && node.message && node.status !== VALIDATION_STATUS.Valid) {
            allNonValidResults.push(node);
        }
    });

    // filter out irrelevant validations by matching value
    const relevantValidations = allNonValidResults.filter((x) => x.item === value);

    // return the last relevant validation
    return relevantValidations.pop();
}

/***
 * Creates LogicData for Prioritized List. It is empty at the beginning.
 * @param phArray (required) - [{ "name": "placeHolderName--abc", "type": "text"}, ...}]
 * returns a logic object
 */
function createInitialPrioritizedListLogic(phArray): LogicJSON {
    const emptyCompoundValue = createEmptyCompoundValue(phArray);
    const firstRule: Rule = {
        value: deepCloneObj(emptyCompoundValue),
        key: uuid(),
        name: "",
        whens: []
    };
    return { outputType: LogicType.Prioritized, rules: [firstRule], defaultValue: null };
}

/**
 * This function replaces every place where we use certain scene in the next scene logic with the newly created id
 * @param logicObject - A logic object sored as logic is stored in DB ie { defaultValue: <string>, rules: Array<rule>, outputType: <string> }
 */
function replaceSceneValues(logicObject, sceneToNewIdMap) {
    if (logicObject.outputType !== LOGIC_TYPE.NextScene) {
        return logicObject;
    }

    const newLogic = { ...logicObject };

    // Replace default value to the new scene (it is stored as a string, with the scene id)
    if (logicObject.defaultValue && sceneToNewIdMap[logicObject.defaultValue]) {
        newLogic.defaultValue = sceneToNewIdMap[logicObject.defaultValue];
    }

    // Replace the value for each rule, to the new id
    (newLogic.rules || []).forEach((rule) => {
        if (rule.value && sceneToNewIdMap[rule.value]) {
            rule.value = sceneToNewIdMap[rule.value];
        }
    });

    return newLogic;
}

function filterTransformTextPlaceholders(placeholderList) {
    return placeholderList
        .filter((ph) => ph.type === LOGIC_TYPE.Text || ph.source === placeholderType.parameter)
        .map((ph) => {
            let out: any = {
                name: ph.name,
                type: LOGIC_DATA_TYPES.Variable,
                mediaType: LOGIC_MEDIA_TYPES.String,
                source: ph.source
            };
            if (ph.groupId) {
                out.groupId = ph.groupId;
            }

            return out;
        });
}

function isPlaceholderOfType(scene, name, type) {
    if (scene && Array.isArray(scene.sceneParts)) {
        for (let scenePart of scene.sceneParts) {
            if (scenePart[placeholderType.onscreen]) {
                for (let ph of scenePart[placeholderType.onscreen]) {
                    if (ph.name === name && ph.type === type) return true;
                }
            }
        }
    }
    return false;
}

/**
 * HOTSPOT START
 */

function createHotspotInputArray(hotspotType) {
    // if (hotspotType) {} // modify hotspot props by the type here...
    let hotspotDisplayMapper = HOTSPOT_DISPLAY_MAPPERS[hotspotType];
    let hotspotInputs = Object.keys(hotspotDisplayMapper).map((key) => ({
        id: key,
        name: hotspotDisplayMapper[key],
        type: LOGIC_TYPE.Text
    }));
    return hotspotInputs;
}

function createHotspotLogicObject(hotspotType) {
    // if (hotspotType) {} // modify hotspot props by the type here...
    const phArray = createHotspotInputArray(hotspotType);
    return createInitialCompoundLogic({}, phArray);
}

function isLogicValueHotspot(logicValue) {
    if (Array.isArray(logicValue) && logicValue.length) {
        return Object.keys(HOTSPOT_DISPLAY_MAPPERS).some((hotspotType) => {
            const hotspotMap = HOTSPOT_DISPLAY_MAPPERS[hotspotType];
            return logicValue.every((val) => {
                return !!hotspotMap[val.id];
            });
        });
    }
    return false;
}

function isLogicValueContainsHotspot(logicValue) {
    if (Array.isArray(logicValue) && logicValue.length) {
        return logicValue.some((logicObj) => isLogicValueHotspot(logicObj.value));
    }
    return false;
}

function getHotspotDefaultLogicObj(inputName, defaultValue, defaultValueShow) {
    const hotspotNameAndType = { name: inputName, type: onscreenTypes.Hotspot };
    let compoundValue = createEmptyCompoundValue([hotspotNameAndType])[0];
    compoundValue.value = defaultValue;
    if (defaultValueShow !== undefined) {
        compoundValue.show = defaultValueShow;
    }
    return compoundValue;
}
/***
 * Finds all hotspots values in logic object
 * @param array of logicObjc
 * @return array includes all Hotspot Values In Logic Objects
 */
export const findHotspotValuesInLogicItems = memoizeOne((logicItems: LogicJSON[]): HotspotValue[] => {
    return logicItems
        .map((logicItem) => findHotspotValuesInLogicItem(logicItem))
        .filter((valueArray) => !!valueArray)
        .flat();
});

/***
 * Finds all hotspots values in logic object
 * @param logicObjc
 * @return array includes all Hotspot Values In Logic Object
 */
export const findHotspotValuesInLogicItem = memoizeOne((logicObjc: LogicJSON): HotspotValue[] => {
    let logicValues = parseLogicObject(logicObjc).getValues();
    return logicValues
        .map((value) => findHotspotValuesInValue(value))
        .filter((valuesArray) => !!valuesArray)
        .flat();
});

export const findHotspotValuesInValue = (value): HotspotValue[] => {
    if (isLogicValueHotspot(value)) {
        return [value];
    }
    return Array.isArray(value) && value.filter((v) => isLogicValueHotspot(v.value)).map((item) => item.value);
};

function findHotspotValuesInRule(rule: Rule): HotspotValue[] {
    return rule && findHotspotValuesInValue(rule.value);
}

function excludeHotspotKeys(hotspot) {
    let modifiedHotspot = deepCloneObj(hotspot);
    EXCLUDED_HOTSPOT_KEYS.forEach((key) => {
        delete modifiedHotspot[key];
    });
    return modifiedHotspot;
}

/**
 * HOTSPOT END
 */

const getProgramValidationContent = (programValidations: ValidationResult) => {
    let validations = {};
    let issues = programValidations && programValidations.issues;

    issues &&
        issues.forEach((issue) => {
            switch (issue.code) {
                case IssueCodes.PROGRAM_ANALYTICS_ERROR: {
                    validations["Analytics Settings"] = { errors: [], warnings: [] };
                    issue.children &&
                        issue.children.forEach((analytics) => {
                            let analyticsName = analytics.name;
                            analytics.issues &&
                                analytics.issues.forEach((analyticsIssue) => {
                                    if (analyticsIssue.code === IssueCodes.ANALYTICS_NAME_ERROR) {
                                        validations["Analytics Settings"].errors.push(`Error - Missing field name in ${analyticsName}`);
                                    }
                                    else if (analyticsIssue.severity === IssueSeverity.ERROR) {
                                        validations["Analytics Settings"].errors.push(`Error in ${analyticsName} logic`);
                                    }
                                    else {
                                        validations["Analytics Settings"].warnings.push(`Warning in ${analyticsName} logic`);
                                    }
                                });
                        });
                    break;
                }

                case IssueCodes.PROGRAM_SETTINGS_ERROR: {
                    validations[VALIDATION_PROGRAM_SETTINGS_LABEL] = { errors: [], warnings: [] };
                    // Assumption: Program Settings validation includes only SF-ID validation
                    validations[VALIDATION_PROGRAM_SETTINGS_LABEL].errors.push("Missing program SalesForce ID");
                    break;
                }

                case IssueCodes.PROGRAM_STORY_ERROR: {
                    validations["Stories"] = { errors: [], warnings: [] };

                    issue.children &&
                        issue.children.forEach((story) => {
                            let storyName = story.name;
                            story.issues &&
                                story.issues.forEach((storyIssue) => {
                                    if (storyIssue.code === IssueCodes.STORY_LOGIC_ERROR) {
                                        if (storyIssue.severity === IssueSeverity.ERROR) {
                                            validations["Stories"].errors.push(`Error in ${storyName} logic`);
                                        }
                                        else {
                                            validations["Stories"].warnings.push(`Warning in ${storyName} logic`);
                                        }
                                    }
                                    if (storyIssue.code === IssueCodes.STORY_SOUNDTRACK_LOGIC_ERROR) {
                                        if (storyIssue.severity === IssueSeverity.ERROR) {
                                            validations["Stories"].errors.push(`Error in ${storyName} soundtrack logic`);
                                        }
                                        else {
                                            validations["Stories"].warnings.push(`Warning in ${storyName} soundtrack logic`);
                                        }
                                    }
                                    if (storyIssue.code === IssueCodes.STORY_BACKGROUND_ASSET_LOGIC_ERROR) {
                                        if (storyIssue.severity === IssueSeverity.ERROR) {
                                            validations["Stories"].errors.push(`Error in ${storyName} background asset logic`);
                                        }
                                        else {
                                            validations["Stories"].warnings.push(`Warning in ${storyName} background asset logic`);
                                        }
                                    }
                                    if (storyIssue.code === IssueCodes.STORY_VIDEO_RATIO_QUALITY_LOGIC_ERROR) {
                                        if (storyIssue.severity === IssueSeverity.ERROR) {
                                            validations["Stories"].errors.push(`Error in ${storyName} ratio and quality logic`);
                                        }
                                        else {
                                            validations["Stories"].warnings.push(`Warning in ${storyName} ratio and quality logic`);
                                        }
                                    }
                                    if (storyIssue.code === IssueCodes.STORY_VIDEO_DURATION_OPTIONS_ERROR) {
                                        if (storyIssue.severity === IssueSeverity.ERROR) {
                                            validations["Stories"].errors.push(`Error in ${storyName} duration options definition`);
                                        }
                                        else {
                                            validations["Stories"].warnings.push(`Warning in ${storyName} duration options definition`);
                                        }
                                    }
                                    if (storyIssue.code === IssueCodes.STORY_VIDEO_NUM_OF_PRODUCT_ERROR) {
                                        if (storyIssue.severity === IssueSeverity.ERROR) {
                                            validations["Stories"].errors.push(`Error in ${storyName} number of products definition`);
                                        }
                                        else {
                                            validations["Stories"].warnings.push(`Warning in ${storyName} number of products definition`);
                                        }
                                    }
                                    if (storyIssue.code === IssueCodes.STORY_VIDEO_RATIO_QUALITY_OPTIONS_ERROR) {
                                        if (storyIssue.severity === IssueSeverity.ERROR) {
                                            validations["Stories"].errors.push(`Error in ${storyName} ratio and quality options definition`);
                                        }
                                        else {
                                            validations["Stories"].warnings.push(`Warning in ${storyName} ratio and quality options definition`);
                                        }
                                    }
                                });
                        });
                    if (validations["Stories"].errors.length === 0 && validations["Stories"].warnings.length === 0) {
                        delete validations["Stories"];
                    }
                    break;
                }

                case IssueCodes.PROGRAM_SCENES_ERROR: {
                    issue.children &&
                        issue.children.forEach((scene) => {
                            validations[scene.name] = getSceneValidationContent(scene.issues, scene.id);
                        });
                    break;
                }

                case IssueCodes.PROGRAM_STORY_SELECTION_LOGIC_ERROR: {
                    validations["Stories"] = validations["Stories"] || { errors: [], warnings: [] };

                    if (issue.severity === IssueSeverity.ERROR && issue.children) {
                        validations["Stories"].errors.push("Error in story selection logic");
                    }
                    else if (issue.severity === IssueSeverity.WARNING && issue.children) {
                        validations["Stories"].warnings.push("Warning in story selection logic");
                    }
                    else {
                        validations["Stories"].errors.push("Error - Missing story selection logic");
                    }
                    break;
                }
            }
        });

    if (Object.keys(validations).length > 0) {
        validations[VALIDATION_SEVERITY_LABEL] = programValidations.severity;
    }

    return validations;
};

const getSceneValidationContent = (issues, sceneId) => {
    let errors = [];
    let warnings = [];
    let recordingErrors = 0;
    let recordingWarnings = 0;

    issues &&
        issues.forEach((issue) => {
            if (issue.code === IssueCodes.SCENE_ANIMATION_LOGIC_ERROR) {
                if (issue.severity === IssueSeverity.ERROR) {
                    errors.push("Error in scene animation logic");
                }
                else {
                    warnings.push("Warning in scene animation logic");
                }
            }
            else if (issue.code === IssueCodes.SCENE_VALIDATION_LOGIC_ERROR) {
                if (issue.severity === IssueSeverity.ERROR) {
                    errors.push("Error in scene validation logic");
                }
                else {
                    warnings.push("Warning in scene validation logic");
                }
            }
            else if (issue.code === IssueCodes.SCENE_PART_ERROR) {
                issue.children &&
                    issue.children.forEach((scenePart) => {
                        let scenePartName = scenePart.name;
                        scenePart.issues &&
                            scenePart.issues.forEach((scenePartIssue) => {
                                scenePartIssue.children &&
                                    scenePartIssue.children.forEach((entity) => {
                                        const isMaster = sceneId === LogicContainers.Master;
                                        if (entity.type === EntityTypes.RECORDING) {
                                            if (entity.severity === IssueSeverity.ERROR) {
                                                recordingErrors++;
                                            }
                                            else {
                                                recordingWarnings++;
                                            }
                                        }
                                        else if (entity.severity === IssueSeverity.ERROR) {
                                            errors.push(`Error${isMaster ? "" : ` in ${scenePartName}`} with ${entity.type} ${entity.name} logic`);
                                        }
                                        else {
                                            warnings.push(`Warning ${isMaster ? "" : ` in ${scenePartName}`} with ${entity.type} ${entity.name} logic`);
                                        }
                                    });
                            });
                    });
            }
        });

    if (recordingWarnings > 0) {
        warnings.unshift(`${recordingWarnings} narration recording file${recordingWarnings > 1 ? "s are" : " is"} missing`);
    }

    if (recordingErrors > 0) {
        errors.unshift(`${recordingErrors} narration recording file${recordingErrors > 1 ? "s are" : " is"} missing`);
    }

    return {
        errors,
        warnings
    };
};

const stringIsHexColor = (str) => /^(#[0-9A-F]{6})$|(#[0-9A-F]{8})$/i.test(str);

// valid color (style) in the studio can be an object with four props: IsOneTint, Tint1, Tint2, and Ratio
const objectIsColor = (potentialColor: any): boolean =>
    potentialColor &&
    typeof potentialColor === "object" &&
    Object.keys(potentialColor).length === 4 &&
    potentialColor.hasOwnProperty("IsOneTint") &&
    potentialColor.hasOwnProperty("Tint1") &&
    potentialColor.hasOwnProperty("Tint2") &&
    potentialColor.hasOwnProperty("Ratio") &&
    typeof potentialColor.IsOneTint === "boolean" &&
    typeof potentialColor.Tint1 === "string" &&
    stringIsHexColor(potentialColor.Tint1) &&
    typeof potentialColor.Tint2 === "string" &&
    stringIsHexColor(potentialColor.Tint2) &&
    typeof potentialColor.Ratio === "number" &&
    potentialColor.Ratio >= 0 &&
    potentialColor.Ratio <= 1;

// colors (styles) in the studio can be a hex string or object with four props: IsOneTint, Tint1, Tint2, and Ratio
const isValidColor = (potentialColor) => (typeof potentialColor === "string" && stringIsHexColor(potentialColor)) || objectIsColor(potentialColor);

export const isTransparentColor = (potentialColor) => (typeof potentialColor === "string" && stringIsHexColor(potentialColor) && isFullyTransparentColor(potentialColor));

export type SceneAndNarrationData = {
    sceneId: string;
    narrationId: string;
    sceneName: string;
    narrationLetter: string;
};
export type CreativeDataElementsUsedInNarrationsOverrides = {
    [cdeId: string]: SceneAndNarrationData[];
};
// The function returns for each narration data element id an array of either scene ids or objects containing the scene and narration that uses it (according to the shallowUsage flag).
const getCreativeDataElementsUsedInNarrationsOverrides = (wireframes, dataElementIdsMap = {}, shallowUsage: boolean = false): CreativeDataElementsUsedInNarrationsOverrides => {
    if (!wireframes || !wireframes.narrations) {
        return dataElementIdsMap;
    }

    function addDataElement(deId: string, sceneId: string, narrationId: string, sceneName: string, narrationLetter: string) {
        dataElementIdsMap[deId] = dataElementIdsMap[deId] || [];
        if (shallowUsage) {
            dataElementIdsMap[deId].push(sceneId);
        }
        else {
            dataElementIdsMap[deId].push({ sceneId, narrationId, sceneName, narrationLetter });
        }
    }

    Object.values(wireframes.scenes).forEach((scene: Scene) => {
        scene.sceneParts.forEach((scenePart: ScenePart) => {
            scenePart.NarrationParts &&
                scenePart.NarrationParts.forEach((narrationPart: NarrationPart) => {
                    let supportCreativeOverrides: boolean = StateReaderUtils.getNarrationTableCreativeOverride(wireframes, narrationPart.id);
                    let dataElementsIdsInUse: string[] = supportCreativeOverrides && StateReaderUtils.getNarrationTableOverrideIds(wireframes, narrationPart.id);
                    if (dataElementsIdsInUse) {
                        let narrationLetter: string = StateReaderUtils.getNarrationLetterInScene(scene, narrationPart.id);
                        dataElementsIdsInUse.forEach((deId: string) => {
                            addDataElement(deId, scene.id, narrationPart.id, scene.name, narrationLetter);
                        });
                    }
                });
        });
    });
    for (let deId in dataElementIdsMap) {
        dataElementIdsMap[deId] = _.uniqWith(dataElementIdsMap[deId], _.isEqual);
    }
    return dataElementIdsMap;
};

const getNarrationPartInvalidCreativeOverrides = memoizeOne((narrationCDEsUsage: CreativeDataElementsUsedInNarrationsOverrides, narrationId: string): string[] => {
    return Object.keys(narrationCDEsUsage).reduce((acc, deId) => {
        if (narrationCDEsUsage && narrationCDEsUsage[deId] && narrationCDEsUsage[deId].length > 1) {
            let usages: string[] = narrationCDEsUsage[deId].map((usage: SceneAndNarrationData) => usage.narrationId);
            usages.includes(narrationId) && acc.push(deId);
        }
        return acc;
    }, []);
});

/**
 * Normalizes a logic - deleting validation, and any itemData that was previously saved on the logic object.
 * In addition, fixes all references to data elements by their names to ids
 * @param logic
 * @param dataElements
 */
const normalizeLogic = (logic: LogicJSON | undefined, dataElements: DataElement[]): LogicJSON => {
    if (!logic) {
        return logic;
    }

    // Make sure we don't send any validation to server (circular object may fail JSON.stringify)
    //@ts-ignore
    if (logic.validation) {
        //@ts-ignore
        delete logic.validation;
    }

    traverse(logic).forEach((node) => {
        if (node && node.type && node.itemData) {
            delete node.itemData;
        }
        if (node && node.type === ActionableDataValueType.DataElement) {
            if (!node.id) {
                let dataElement = dataElements.find((de) => de.id === node.name || de.name === node.name);
                if (dataElement) {
                    node.id = dataElement.id;
                    delete node.name;
                }
            }
        }
    });

    return logic;
};

function getAllActionableDataFromValue(value: any): ActionableData[] {
    if (Array.isArray(value)) {
        return value.filter(exp => exp.type);
    }
    else if (value?.type) {
        return [value];
    }
    return [];
}

export {
    getAvailableFunctions,
    getAvailableOperatorsForObject,
    areLogicValuesEqual,
    capitalizeFirstLetter,
    filterDataElementsByMediaType,
    hasRhs,
    getRhsMediaType,
    getRhsValueSet,
    getLhsMediaType,
    validationStatusToString,
    getAnimationLogicName,
    getMappingTableNameFromValue,
    getMappingTablePropertyFromValue,
    getDataElementIdsFromMappingTable,
    getValidationReportOnObject,
    convertDataElementTypeToLogicMeidaType,
    convertDataTblOutputTypeToLogicMediaType,
    cleanInputLogic,
    extractDataElementIdsFromLogic,
    getElementInfoFromActionableData,
    getAssetTitleFromName,
    isCompoundValue,
    isArrayOfCompoundValues,
    createEmptyCompoundValue,
    createInitialCompoundLogic,
    extractPlaceholderNamesAndTypesFromWireframes,
    removePlaceHolderFromGroup,
    generatePlaceholderLogicDataFromGroup,
    addPlaceholdersToGroup,
    getValueValidationForCompoundValue,
    getAssetFromActionableData,
    renamePlaceholderInGroup,
    createInitialPrioritizedListLogic,
    replaceSceneValues,
    createLogic,
    createLogicFromValueSet,
    convertLogicTypeToDataElementType,
    convertLogicMediaTypToVlxParamType,
    createLogicWithDerived,
    filterTransformTextPlaceholders,
    createHotspotLogicObject,
    findInCompoundValue,
    isLogicValueHotspot,
    createHotspotInputArray,
    getHotspotDefaultLogicObj,
    isLogicValueContainsHotspot,
    isPlaceholderOfType,
    findHotspotValuesInRule,
    excludeHotspotKeys,
    getSceneValidationContent,
    getProgramValidationContent,
    isValidColor,
    getCreativeDataElementsUsedInNarrationsOverrides,
    getNarrationPartInvalidCreativeOverrides,
    normalizeLogic,
    compareCompoundValues,
    getAllActionableDataFromValue
};
