import { isEditorRoute } from "../../components/main/mainContainerUtils";
import type { AxiosError, AxiosRequestConfig, AxiosResponse, RawAxiosRequestHeaders } from "axios";
import axios from "axios";
import HubNotifications from "./hubNotifications";
import {
    studioApiVersion,
    studioApiVersionHeaderName,
    X_SSKY_PAYLOAD_SIZE_HEADER,
    X_SSKY_SERVER_TIMING_HEADER,
    studioClientVersionHeaderName
} from "../../../common/commonConst";
import { addNetworkActionPerformance } from "../globals/globalPerformance";
import type { AuthServiceInterface } from "@sundaysky/smartvideo-hub-auth";
import type { BuilderModeRetrieverInterface } from "../../components/BuilderModeRetriever";
import { ERROR_ID_KEY, getErrorIdFromRestError, getSskyErrorCodeFromRestError } from "../../../common/errors";
import { Base64 } from "js-base64";

const CLIENT_VERSION = process.env.CLIENT_VERSION; // injected via Webpack DefinePlugin

export const addTokenHeadersAndFullStory = function(headers: RawAxiosRequestHeaders, token: string): RawAxiosRequestHeaders {
    headers = headers || {};
    headers[studioApiVersionHeaderName] = studioApiVersion;
    headers["studio-client-name"] = "Studio";
    if (token) {
        headers["Authorization"] = "Bearer " + token;
    }
    const activeAccountId: string = BaseServices?.getActiveAccountId();
    if (activeAccountId) {
        headers["account-id"] = activeAccountId;
    }
    const activeAccountName: string = BaseServices?.getActiveAccountName?.();
    if (activeAccountName) {
        headers["account-name"] = Base64.encode(activeAccountName);
    }
    if (CLIENT_VERSION) {
        headers[studioClientVersionHeaderName] = CLIENT_VERSION;
    }
    const fullStorySessionURL = window && (window as any).FS && typeof (window as any).FS.getCurrentSessionURL === "function" && (window as any).FS.getCurrentSessionURL(true);
    if (fullStorySessionURL) {
        headers["x-ssky-fsurl"] = Buffer.from(fullStorySessionURL).toString("base64");
    }
    return headers;
};

const extractActionName = (httpMethod: string, url: string): string => {
    const urlWithoutQueryParams: string = url.split("?")[0];
    const lastPathPart: string = urlWithoutQueryParams.split("/").filter(Boolean).pop();
    return `${httpMethod} ${lastPathPart}`;
};

const sendPerformanceInfo = (httpMethod: string, url: string, startTime: number, endTime: number, response: AxiosResponse): void => {
    const name: string = extractActionName(httpMethod, url);
    const parsedServerDuration: number = Number(response?.headers?.[X_SSKY_SERVER_TIMING_HEADER]);
    const serverDuration: number = !Number.isNaN(parsedServerDuration) ? parsedServerDuration : 0;
    const parsedPayload: number = Number(response?.headers?.[X_SSKY_PAYLOAD_SIZE_HEADER]);
    const payload: number = !Number.isNaN(parsedPayload) ? parsedPayload : 0;
    addNetworkActionPerformance({ kind: "REST", name, startTime, endTime, serverDuration, payload });
};

const shouldLogResponseData = (error: AxiosError): boolean => {
    return (
        error.response.status === 502
        || (
            typeof error.response?.data === "string"
            && error.response.data.toLocaleLowerCase()?.includes("radware")
        )
    );
};

const handleErrorInEditorContext = async (error): Promise<void> => {
    // dynamic import to prevent circular dependency error
    const {
        handleEditorError,
        setNoInternetNotification
    } = await import("../../components/editor/Nooks");

    const isNetworkConnectionError = error.message === "Network Error";

    if (isNetworkConnectionError) {
        setNoInternetNotification();
    }
    else {
        const sskyCode = getSskyErrorCodeFromRestError(error);
        const details = {
            [ERROR_ID_KEY]: getErrorIdFromRestError(error),
            method: error.config?.method,
            url: error.config?.url
        };

        if (shouldLogResponseData(error)) {
            details["responseData"] = error.response?.data;
        }

        handleEditorError({ error: typeof error === "string" ? new Error(error) : error, sskyCode, details });
    }
};

const handleErrorInNonEditorContext = (error): void => {
    if (error.response?.status === 403) {
        const message = error.response.data?.message ?? "Forbidden";
        HubNotifications.error(message);
    }
};

export class BaseServices {
    private readonly authService: AuthServiceInterface;
    private readonly getUserAccessToken: () => Promise<string>;
    private readonly builderModeRetriever: BuilderModeRetrieverInterface;

    static getActiveAccountId;
    static getActiveAccountName;

    static setActiveAccountIdGetter(getActiveAccountId) {
        BaseServices.getActiveAccountId = getActiveAccountId;
    }

    static setActiveAccountNameGetter(getActiveAccountName) {
        BaseServices.getActiveAccountName = getActiveAccountName;
    }

    constructor(authService: AuthServiceInterface, builderModeRetriever: BuilderModeRetrieverInterface) {
        this.authService = authService;
        this.getUserAccessToken = async () => {
            try {
                return await this.authService.getUserAccessToken();
            }
            catch (e) {
                return null;
            }
        };

        this.builderModeRetriever = builderModeRetriever;
    }

    getAuthService() {
        return this.authService;
    }

    handleError(reject, error: Error | string) {
        if (isEditorRoute(window.location.pathname)) {
            handleErrorInEditorContext(error).finally(() => {
                reject(error);
            });
        }
        else {
            handleErrorInNonEditorContext(error);
            reject(error);
        }
    }

    get<T>(allowedModes, url: string, headers?: RawAxiosRequestHeaders, config?: AxiosRequestConfig): Promise<T> {
        const startTime: number = Date.now();
        return new Promise<T>((resolve, reject) => {
            const currentMode = this.builderModeRetriever.getMode();

            if (allowedModes && allowedModes.includes(currentMode)) {
                config = config || {};
                this.getUserAccessToken()
                    .then((token: string) => {
                        headers = addTokenHeadersAndFullStory(headers, token);
                        config.headers = headers;
                        return axios.get<T>(url, config);
                    })
                    .then((response: AxiosResponse<T>) => {
                        sendPerformanceInfo("GET", url, startTime, Date.now(), response);
                        resolve(response.data);
                    })
                    .catch((error) => {
                        this.handleError(reject, error);
                    });
            }
            else {
                this.handleError(reject, `Action not allowed in mode ${currentMode}`);
            }
        });
    }

    put<T>(allowedModes, url: string, data, headers?: RawAxiosRequestHeaders, config?: AxiosRequestConfig, cb?: Function): Promise<T> {
        const startTime: number = Date.now();
        const currentMode = this.builderModeRetriever.getMode();

        return new Promise<T>((resolve, reject) => {
            if (allowedModes && allowedModes.includes(currentMode)) {
                config = config || {};
                headers = headers || {};
                headers["Content-Type"] = headers["Content-Type"] || "application/json";

                this.getUserAccessToken()
                    .then((token: string) => {
                        headers = addTokenHeadersAndFullStory(headers, token);
                        config.headers = headers;

                        if (typeof cb === "function") {
                            config.onUploadProgress = (progressEvent) => {
                                let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                                cb(percentCompleted);
                            };
                        }

                        return axios.put<T>(url, data, config);
                    })
                    .then((response: AxiosResponse<T>) => {
                        sendPerformanceInfo("PUT", url, startTime, Date.now(), response);
                        resolve(response.data);
                    })
                    .catch(error => {
                        this.handleError(reject, error);
                    });
            }
            else {
                this.handleError(reject, `Action not allowed in mode ${currentMode}`);
            }
        });
    }

    post<T>(allowedModes, url: string, data, headers?: RawAxiosRequestHeaders, config?: AxiosRequestConfig, cb?: Function): Promise<T> {
        const startTime: number = Date.now();
        return new Promise<T>((resolve, reject) => {
            const currentMode = this.builderModeRetriever.getMode();

            if (allowedModes && allowedModes.includes(currentMode)) {
                config = config || {};
                headers = headers || {};
                headers["Content-Type"] = headers["Content-Type"] || "application/json";

                this.getUserAccessToken()
                    .then((token: string) => {
                        headers = addTokenHeadersAndFullStory(headers, token);
                        config.headers = headers;

                        if (typeof cb === "function") {
                            config.onUploadProgress = (progressEvent) => {
                                let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                                cb(percentCompleted);
                            };
                        }

                        return axios.post<T>(url, data, config);
                    })
                    .then((response: AxiosResponse<T>) => {
                        sendPerformanceInfo("POST", url, startTime, Date.now(), response);
                        resolve(response.data);
                    })
                    .catch(error => {
                        this.handleError(reject, error);
                    });
            }
            else {
                this.handleError(reject, `Action not allowed in mode ${currentMode}`);
            }
        });
    }

    delete<T>(allowedModes, url: string, headers?: RawAxiosRequestHeaders, config?: AxiosRequestConfig): Promise<T> {
        const startTime: number = Date.now();
        return new Promise<T>((resolve, reject) => {
            config = config || {};
            headers = headers || {};
            const currentMode = this.builderModeRetriever.getMode();

            if (allowedModes && allowedModes.includes(currentMode)) {
                this.getUserAccessToken()
                    .then((token: string) => {
                        headers = addTokenHeadersAndFullStory(headers, token);
                        config.headers = headers;

                        return axios.delete<T>(url, config);
                    })
                    .then((response: AxiosResponse<T>) => {
                        sendPerformanceInfo("DELETE", url, startTime, Date.now(), response);
                        resolve(response.data);
                    })
                    .catch((error) => {
                        this.handleError(reject, error);
                    });
            }
            else {
                this.handleError(reject, `Action not allowed in mode ${currentMode}`);
            }
        });
    }

    generateQueryParams(obj) {
        if (Object.keys(obj).length === 0) {
            return "";
        }

        let qParams = "?";
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                qParams += key + "=" + obj[key] + "&";
            }
        }
        return qParams.length > 1 ? qParams.substring(0, qParams.length - 1) : "";
    }
}

export default BaseServices;
