import type { GetDependencyIdFunction } from "./baseEntity";
import { BaseEntity } from "./baseEntity";
import StateReaderUtils from "../common/StateReaderUtils";
import type { GetValidationResultFunction, Issue, ValidationResult } from "../validations/validationManager";
import { IssueCodes } from "../validations/validationManager";
import type { Collector, CollectorArgs, IEntity, EntityInstance } from "./definitions";
import { EntityTypes } from "./definitions";
import { removeMasterFromScenes } from "../common/generalUtils";

export default class SceneValidationEntity extends BaseEntity implements IEntity {
    constructor() {
        super(EntityTypes.SCENE_VALIDATION);
    }

    static generateId = (sceneId: string): string => {
        return sceneId + "_validation";
    };

    collector: Collector = (args: CollectorArgs): EntityInstance[] => {
        const { context, previousContext } = args;
        let scenes: EntityInstance[] = [];
        let allScenes = StateReaderUtils.getProjectWireframeScenes(context.state, context.projectName, context.stage, context.version);
        if (allScenes) {
            allScenes = removeMasterFromScenes(allScenes);
            const wireframes = StateReaderUtils.getWireFrame(context.state, context.projectName, context.stage, context.version);
            Object.values(allScenes).forEach((scene: any) => {
                const sceneLogic = StateReaderUtils.getAllProgramLogicFromWireframes(wireframes, scene.id);
                const sceneValidationLogicId = SceneValidationEntity.generateId(scene.id);
                const sceneValidationLogic = sceneLogic && sceneLogic[sceneValidationLogicId];
                if (sceneValidationLogic) {
                    scenes.push({
                        id: sceneValidationLogicId,
                        validate: (prevValidationResult: ValidationResult, getValidationResultFunction: GetValidationResultFunction): ValidationResult => {
                            return this.doValidate(sceneValidationLogic, getValidationResultFunction, scene);
                        }
                    });
                }
                else {
                    // no logic object in scene validation is valid
                    scenes.push({
                        id: sceneValidationLogicId,
                        validate: (): ValidationResult => {
                            return { type: this.getType(), id: sceneValidationLogicId, issues: [] };
                        }
                    });
                }
            });
        }
        return scenes;
    };

    doValidate = (sceneValidationLogic: any, getValidationResult: GetValidationResultFunction, scene: any): ValidationResult => {
        const type: EntityTypes = this.getType();
        const id = SceneValidationEntity.generateId(scene.id);
        let sceneValidationValidationResult: ValidationResult = { type, id, issues: [] };

        // Check scene validation logic
        if (sceneValidationLogic && sceneValidationLogic.outputType) {
            const getDependencyId: GetDependencyIdFunction = () => id;
            let issue: Issue = this.getDependenciesIssue([sceneValidationLogic], EntityTypes.LOGIC, getDependencyId, getValidationResult, IssueCodes.SCENE_VALIDATION_LOGIC_ERROR);
            if (issue) {
                sceneValidationValidationResult.issues.push(issue);
                sceneValidationValidationResult.severity = issue.severity;
            }
        }
        return sceneValidationValidationResult;
    };
}
