import StateReaderUtils from "../../common/StateReaderUtils";
import { LOADING_PROJECT_WIREFRAMES_FROM_UIASSET, DELETING_PROJECT_WIREFRAMES_INPUT_LOGICS_SUCCESS, UPDATING_PROJECT_WIREFRAMES_SCENE_INPUT_LOGIC_SUCCESS, LOADING_PROGRAM_WIREFRAMES_SUCCESS } from "./projectsWireframesActions";
import StudioDataManager from "../../DataElements/StudioDataManager";
import { DataElementOrigins } from "../../../../common/types/dataElement";
import { LogicContainers } from "../../../../common/commonConst";
import { LOADING_PROJECT_DETAILS_SUCCESS, UPDATE_PROJECT_COMPLETE } from "../projectsActions";
import { immutablyReplaceValue } from "../../../../common/generalUtils";
function projectCombinedReducer(state, action) {
    function getDerivedLogicCycles(wireframes) {
        let dataElements = StateReaderUtils.getProjectDataElementsFromWireframes(wireframes);
        let derivedDataElements = dataElements.filter((de) => de.origin === DataElementOrigins.Derived);
        // Todo: need to get mappingTables from the state, and put them on the context
        let context = {
            dataElements,
            derivedLogic: wireframes && wireframes.logic && wireframes.logic[LogicContainers.Derived]
        };
        let studioDataManager = new StudioDataManager(context);
        studioDataManager.buildDerivedGraph(derivedDataElements);
        return studioDataManager.detectCycles();
    }
    switch (action.type) {
        case UPDATING_PROJECT_WIREFRAMES_SCENE_INPUT_LOGIC_SUCCESS:
        case DELETING_PROJECT_WIREFRAMES_INPUT_LOGICS_SUCCESS:
        case LOADING_PROGRAM_WIREFRAMES_SUCCESS: {
            let logicContainer = action.payload.sceneId;
            let projectName = action.payload.projectName;
            let stage = action.payload.stage;
            let version = action.payload.version;
            if (logicContainer !== LogicContainers.Derived && !action.payload.calculateDeriveCycles) {
                return state;
            }
            let wireframes = StateReaderUtils.getWireFrame(state, projectName, stage, version);
            let wireframesPath = StateReaderUtils.getWireframesPathOnState(projectName, stage, version);
            let cycles = getDerivedLogicCycles(wireframes);
            return immutablyReplaceValue(state, [...wireframesPath, "derivedLogicCycles"], cycles);
        }
        case LOADING_PROJECT_WIREFRAMES_FROM_UIASSET: {
            let { projectName, stage, version } = action.payload;
            let wireframes = StateReaderUtils.getWireFrame(state, projectName, stage, version);
            let wireframesPath = StateReaderUtils.getWireframesPathOnState(projectName, stage, version);
            const cycles = getDerivedLogicCycles(wireframes);
            return immutablyReplaceValue(state, [...wireframesPath, "derivedLogicCycles"], cycles);
        }
        case UPDATE_PROJECT_COMPLETE: {
            let { RFR, RFRUpdateTimestamp, projectName } = action.payload.project;
            let wireframes = StateReaderUtils.getWireFrame(state, projectName);
            // if draft wireframes doesn't exists, i.e. stage mode, the RFR is loaded from the wireframes UI asset and not from the project
            if (!wireframes) {
                return state;
            }
            return Object.assign(Object.assign({}, state), { wireframes: {
                    byName: Object.assign(Object.assign({}, state.wireframes.byName), { [projectName]: Object.assign(Object.assign({}, wireframes), { programRFR: RFR, programRFRTimestamp: RFRUpdateTimestamp }) }),
                    allNames: state.wireframes.allNames
                }, error: false });
        }
        default:
            return state;
    }
}
export default projectCombinedReducer;
