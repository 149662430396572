import BaseServices from "../common/BaseServices";
import { EDITING_MODE, STAGE_MODE } from "../common/Consts";
import BuilderModeRetriever from "../../components/BuilderModeRetriever";
import type { VideoSpecResponse } from "../../../common/external/videoSpecResponse";
import type { RawAxiosRequestHeaders } from "axios";
import axios from "axios";

function generateHeaders(apiKeys: string): RawAxiosRequestHeaders {
    const headers: RawAxiosRequestHeaders = {};
    if (apiKeys) {
        headers["x-api-key"] = apiKeys;
    }
    headers["x-ssky-origin"] = "HUB_Preview";
    headers["Content-Type"] = "application/json";
    return headers;
}

type VideoSpec = object; // placeholder for when we'll have full videospec shape

class PreviewServices extends BaseServices {

    constructor(authService?, builderModeRetriever?) {
        super(authService, builderModeRetriever ? builderModeRetriever : new BuilderModeRetriever());
    }

    preview(jsonSpec: VideoSpec, url: string, apiKeys: string) {
        const headers: RawAxiosRequestHeaders = generateHeaders(apiKeys);
        return axios.post(url, jsonSpec, { headers })
            .then(res => res.data)
            .catch(err => {
                this.handleError(() => {}, err);
                throw err;
            });
    }

    getPreviewData(url: string): Promise<VideoSpecResponse> {
        return axios.get<VideoSpecResponse>(url).then(res => res.data);
    }

    getStatus(url: string): Promise<VideoSpecResponse> {
        return this.getPreviewData(url);
    }

    getLog(url: string): Promise<VideoSpecResponse> {
        return this.getPreviewData(url);
    }

    getJsonSpecFileAfterDrop(url: string): Promise<VideoSpecResponse> {
        return this.getPreviewData(url);
    }

    getProjectApiKeyAndEndPoint(accountID: string, projectName: string) {
        return this.get([EDITING_MODE, STAGE_MODE], "/rest/" + accountID + "/projects/" + projectName + "/config");
    }
}

export default PreviewServices;
