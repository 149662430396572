import type {
    CreativeNarrationAndProjectName } from "./projectAssetsActions";
import {
    CLEAR_UPLOAD_LIST,
    DELETE_ASSET_SUCCESS,
    LOADING_ASSET_CONTENT,
    LOADING_ASSETS_COUNT_SUCCESS,
    LOADING_ASSETS_SUCCESS,
    LOADING_CREATIVE_NARRATION_RECORDING_VERSIONS_SUCCESS,
    UPDATE_ASSET,
    UPDATE_BULK_ASSETS_SUCCESS,
    UPDATE_RECORDING_ASSET_STATUS_SUCCESS,
    UPDATING_CREATIVE_NARRATION_RECORDING_SUCCESS,
    UPDATING_CREATIVE_NARRATION_RECORDING_VERSION_STATUS_SUCCESS,
    UPLOAD_RAW_RECORDING_CLEAR,
    UPLOAD_RAW_RECORDING_FINISH,
    UPLOAD_RAW_RECORDING_PROGRESS,
    UPLOADING_ASSET,
    UPLOADING_ASSET_ERROR,
    UPLOADING_ASSET_START,
    UPLOADING_ASSET_SUCCESS,
    UPLOADING_CREATIVE_NARRATION_RECORDING_VERSIONS_SUCCESS
} from "./projectAssetsActions";
import StateReaderUtils from "../../common/StateReaderUtils";
import { ASSET_TYPES } from "../../vlx/consts";
import type {
    GqlClientCreative,
    GqlClientCreativeNarrationRecording,
    GqlClientGetAllCreativesNarrationRecordingsQuery
} from "../../../graphql/graphqlGeneratedTypes/graphqlClient";
import type { AssetTypes } from "../../../../common/types/asset";
import { immutablyReplaceValue } from "../../../../common/generalUtils";

function assetsReducer(state, action) {
    let { byName: projectsByName, allNames: projectsAllNames } = state.projects;
    let { byId: assetsById, allIds: allAssetsIds } = state.assets;
    let uploadingAssets = Array.isArray(state.uploadingAssets) ? [...state.uploadingAssets] : [];

    const deleteAsset = (projectName, assetId) => {
        let index = allAssetsIds.indexOf(assetId);
        let newAllAssetsIds = allAssetsIds;
        if (index > -1) {
            newAllAssetsIds = [...allAssetsIds.slice(0, index), ...allAssetsIds.slice(index + 1)];
            // TODO : is this ok?
            delete assetsById[assetId];

            let assets = projectsByName[projectName].assets;
            let indexInProject = assets.indexOf(assetId);

            projectsByName[projectName].assets = [...assets.slice(0, indexInProject), ...assets.slice(indexInProject + 1)];
        }
        return newAllAssetsIds;
    };

    const removeAssetsOfType = (projectName: string, assetType: AssetTypes) => {
        const partialId = projectName + "/" + assetType;
        allAssetsIds = allAssetsIds.filter(assetId => !assetId.includes(partialId));
        const filteredProjectAssetIds = projectsByName[projectName].assets.filter(assetId => !assetId.includes(partialId));
        assetsById = allAssetsIds.reduce((acc, assetId) => {
            acc[assetId] = assetsById[assetId];
            return acc;
        }, {});

        projectsByName = {
            ...projectsByName,
            [projectName]: {
                ...projectsByName[projectName],
                assets: filteredProjectAssetIds
            }
        };
    };

    const updateAssets = (projectName, assets, shouldReplaceCurrent) => {
        const allAssetsIdsSet = new Set(allAssetsIds);
        const projectAssetsSet = new Set(projectsByName[projectName].assets || []);
        assetsById = assetsById ? { ...assetsById } : {};

        for (let i = 0, len = assets.length; i < len; i++) {
            // fastest JS loop
            const asset = assets[i];
            const assetId = StateReaderUtils.getAssetUniqueId(projectsByName[projectName].accountId, projectName, asset.type, asset.name);

            allAssetsIdsSet.add(assetId);
            projectAssetsSet.add(assetId);

            assetsById[assetId] = shouldReplaceCurrent ? asset : { ...assetsById[assetId], ...asset };
        }

        allAssetsIds = [...allAssetsIdsSet];

        projectsByName = {
            ...projectsByName,
            [projectName]: {
                ...projectsByName[projectName],
                assets: [...projectAssetsSet]
            }
        };
    };

    const updateCreativesWithCreativeNarrationRecording = (creativeRecordingAssets: any, creativeNarrationRecording: GqlClientCreativeNarrationRecording, projectName: string): GqlClientCreative[] => {
        const [programId, creativeId, creativeVersionId, creativeNarrationRecordingVersionId]: string[] = creativeNarrationRecording.id.split("|");

        let updatedCreatives: GqlClientCreative[] = [...creativeRecordingAssets.program.creatives];
        const creativeToUpdateIdx: number = updatedCreatives.findIndex(({ id }) => id === `${programId}|${creativeId}`);
        const currentCreativeNarrationRecordings: GqlClientCreativeNarrationRecording[] = updatedCreatives[creativeToUpdateIdx].creativeVersionDraft.creativeNarrationRecordings;
        const creativeNarrationRecordingToUpdateIdx: number = currentCreativeNarrationRecordings.findIndex(({ id }) => id === creativeNarrationRecording.id);

        let updatedCreativeNarrationRecordings: GqlClientCreativeNarrationRecording[] = [...currentCreativeNarrationRecordings];
        updatedCreativeNarrationRecordings.splice(creativeNarrationRecordingToUpdateIdx, 1, creativeNarrationRecording);

        const updatedCreative: GqlClientCreative = {
            ...updatedCreatives[creativeToUpdateIdx],
            creativeVersionDraft: {
                ...updatedCreatives[creativeToUpdateIdx].creativeVersionDraft,
                creativeNarrationRecordings: updatedCreativeNarrationRecordings
            }
        };

        updatedCreatives.splice(creativeToUpdateIdx, 1, updatedCreative);

        return updatedCreatives;
    };

    switch (action.type) {
        case UPLOADING_ASSET:
        case LOADING_ASSET_CONTENT: {
            return {
                ...state,
                error: false
            };
        }

        case LOADING_ASSETS_COUNT_SUCCESS: {
            let assetType = action.payload.assetType ? action.payload.assetType : "all";

            let projectTotalAssetCount = projectsByName[action.payload.projectName].totalAssetCount || {};
            projectsByName = {
                ...projectsByName,
                [action.payload.projectName]: {
                    ...projectsByName[action.payload.projectName],
                    totalAssetCount: {
                        ...projectTotalAssetCount,
                        [assetType]: action.payload.assets.totalCount
                    }
                }
            };

            return {
                ...state,
                projects: { byName: projectsByName, allNames: projectsAllNames, isInitialized: true },
                assets: { byId: assetsById, allIds: allAssetsIds },
                error: false
            };
        }

        case DELETE_ASSET_SUCCESS: {
            let assetStateId = action.payload.asset.projectId + "/" + action.payload.asset.assetId;
            let newAllAssetsIds = deleteAsset(action.payload.projectName, assetStateId);

            return {
                ...state,
                projects: { byName: projectsByName, allNames: projectsAllNames, isInitialized: true },
                assets: { byId: assetsById, allIds: newAllAssetsIds }
            };
        }

        case LOADING_ASSETS_SUCCESS: {
            const { projectName, assets, versionMode } = action.payload;

            updateAssets(projectName, assets.Items, true);

            let nextPageAssetUrl = assets.nextPageAssetUrl;
            if (nextPageAssetUrl) {
                projectsByName = {
                    ...projectsByName,
                    [projectName]: {
                        ...projectsByName[projectName],
                        nextPageAssetUrl: nextPageAssetUrl
                    }
                };
            }

            let newState = immutablyReplaceValue(state, ["assets", "byId"], assetsById);
            newState = immutablyReplaceValue(newState, ["assets", "allIds"], allAssetsIds);
            if (!versionMode) {
                newState = immutablyReplaceValue(newState, ["projects", "byName"], projectsByName);
            }
            newState.error = false;
            return newState;
        }

        case LOADING_CREATIVE_NARRATION_RECORDING_VERSIONS_SUCCESS: {
            const { projectName, creativeNarrationRecordings } = action.payload;

            const allIds = Array.from(new Set([...state.creativeRecordingAssets.allIds, projectName]));

            return {
                ...state,
                creativeRecordingAssets: {
                    allIds: allIds,
                    byId: {
                        ...state.creativeRecordingAssets.byId,
                        [projectName]: creativeNarrationRecordings
                    }
                }
            };
        }

        case UPLOADING_ASSET_START: {
            let assetName = action.payload.assetName;
            let uploadAsset = uploadingAssets.find((uploadingAsset) => uploadingAsset.fileName.toLowerCase() === assetName.toLowerCase());
            uploadAsset
                ? (uploadAsset.status = "loading")
                : uploadingAssets.unshift({
                    fileName: assetName,
                    status: "loading"
                });

            return {
                ...state,
                uploadingAssets: [...uploadingAssets]
            };
        }

        case UPLOADING_CREATIVE_NARRATION_RECORDING_VERSIONS_SUCCESS: {
            const { creativeNarrationRecordingVersion, projectName } = action.payload;

            const { creativeNarrationRecording: updatedCreativeNarrationRecording } = creativeNarrationRecordingVersion;

            const creativeRecordingAssets = StateReaderUtils.getCreativeRecordingAssets(state, projectName);
            const updatedCreatives = updateCreativesWithCreativeNarrationRecording(creativeRecordingAssets, updatedCreativeNarrationRecording, projectName);

            const updatedProgram: GqlClientGetAllCreativesNarrationRecordingsQuery = {
                ...creativeRecordingAssets,
                program: {
                    ...creativeRecordingAssets.program,
                    creatives: updatedCreatives
                }
            };

            // handle uploading asset list
            const index: number = uploadingAssets.findIndex(({ fileName }) => fileName.toLowerCase() === `${updatedCreativeNarrationRecording.filename}.mp3`.toLowerCase());
            if (index !== -1) {
                uploadingAssets[index] = {
                    ...uploadingAssets[index],
                    status: "success"
                };
            }

            return {
                ...state,
                uploadingAssets: [...uploadingAssets],
                creativeRecordingAssets: {
                    ...state.creativeRecordingAssets,
                    byId: {
                        ...state.creativeRecordingAssets.byId,
                        [projectName]: {
                            ...updatedProgram
                        }
                    }
                }
            };
        }

        case UPLOADING_ASSET_ERROR: {
            const { assetName } = action.payload;
            const index = uploadingAssets.findIndex((uploadingAsset) => uploadingAsset.fileName.toLowerCase() === assetName.toLowerCase());
            if (index !== -1) {
                uploadingAssets[index] = {
                    ...uploadingAssets[index],
                    status: "error"
                };
            }

            return {
                ...state,
                uploadingAssets: [...uploadingAssets]
            };
        }

        case CLEAR_UPLOAD_LIST: {
            return {
                ...state,
                uploadingAssets: []
            };
        }

        case UPDATE_ASSET:
        case UPLOADING_ASSET_SUCCESS: {
            const { projectName, asset, assetType: payloadAssetType, prevName } = action.payload;

            updateAssets(projectName, [asset], true);

            let updatedAssetNumber = projectsByName[projectName].updatedAssetNumber === 0 ? projectsByName[projectName].updatedAssetNumber + 1 : projectsByName[projectName].updatedAssetNumber;

            projectsByName = {
                ...projectsByName,
                [projectName]: {
                    ...projectsByName[projectName],
                    updatedAssetNumber: updatedAssetNumber
                }
            };

            let assetType = payloadAssetType || "all";
            if (!projectsByName[projectName].totalAssetCount) {
                projectsByName[projectName].totalAssetCount = { [assetType]: 0 };
            }
            projectsByName[projectName].totalAssetCount[assetType]++;

            if (prevName && prevName !== asset.name) {
                let prevAssetId = asset.projectId + "/" + asset.type + "/" + prevName;
                allAssetsIds = deleteAsset(projectName, prevAssetId);
            }

            const index = uploadingAssets.findIndex((uploadingAsset) => asset.filename && uploadingAsset.fileName.toLowerCase() === asset.filename.toLowerCase());

            if (index !== -1) {
                uploadingAssets[index] = {
                    ...uploadingAssets[index],
                    status: "success"
                };
            }

            return {
                ...state,
                projects: { byName: projectsByName, allNames: projectsAllNames, isInitialized: true },
                assets: { byId: assetsById, allIds: allAssetsIds },
                uploadingAssets: [...uploadingAssets]
            };
        }

        case UPDATE_RECORDING_ASSET_STATUS_SUCCESS: {
            const { projectName, assetName, version, assetUpdates } = action.payload;

            const program = projectsByName[projectName];
            const assetId = StateReaderUtils.getAssetUniqueId(program.accountId, projectName, ASSET_TYPES.recording, assetName);

            updateAssets(projectName, [{ ...assetUpdates, type: ASSET_TYPES.recording, name: assetName }], false);

            const asset = assetsById[assetId];
            if (asset.activeVersionItem) {
                asset.activeVersionItem = { ...asset.activeVersionItem, ...assetUpdates };
            }
            if (asset.history) {
                const index = asset.history.Items.findIndex((item) => item.version === version);
                asset.history = {
                    ...asset.history,
                    Items: [...asset.history.Items.slice(0, index), { ...asset.history.Items[index], ...assetUpdates }, ...asset.history.Items.slice(index + 1)]
                };
            }

            return {
                ...state,
                projects: { byName: projectsByName, allNames: projectsAllNames, isInitialized: true },
                assets: { byId: assetsById, allIds: allAssetsIds },
                uploadingAssets: uploadingAssets
            };
        }

        case UPDATING_CREATIVE_NARRATION_RECORDING_VERSION_STATUS_SUCCESS: {
            const { projectName, creativeNarrationRecording }: CreativeNarrationAndProjectName = action.payload;

            const creativeRecordingAssets = StateReaderUtils.getCreativeRecordingAssets(state, projectName);
            const updatedCreatives = updateCreativesWithCreativeNarrationRecording(creativeRecordingAssets, creativeNarrationRecording, projectName);

            const updatedProgram: GqlClientGetAllCreativesNarrationRecordingsQuery = {
                ...creativeRecordingAssets,
                program: {
                    ...creativeRecordingAssets.program,
                    creatives: updatedCreatives
                }
            };

            return {
                ...state,
                creativeRecordingAssets: {
                    ...state.creativeRecordingAssets,
                    byId: {
                        ...state.creativeRecordingAssets.byId,
                        [projectName]: {
                            ...updatedProgram
                        }
                    }
                }
            };
        }

        case UPDATING_CREATIVE_NARRATION_RECORDING_SUCCESS: {
            const { projectName, creativeNarrationRecording }: CreativeNarrationAndProjectName = action.payload;

            const creativeRecordingAssets = StateReaderUtils.getCreativeRecordingAssets(state, projectName);
            const updatedCreatives = updateCreativesWithCreativeNarrationRecording(creativeRecordingAssets, creativeNarrationRecording, projectName);

            const updatedProgram: GqlClientGetAllCreativesNarrationRecordingsQuery = {
                ...creativeRecordingAssets,
                program: {
                    ...creativeRecordingAssets.program,
                    creatives: updatedCreatives
                }
            };

            return {
                ...state,
                creativeRecordingAssets: {
                    ...state.creativeRecordingAssets,
                    byId: {
                        ...state.creativeRecordingAssets.byId,
                        [projectName]: {
                            ...updatedProgram
                        }
                    }
                }
            };
        }

        case UPDATE_BULK_ASSETS_SUCCESS: {
            const { projectName, responseBody, assetType } = action.payload;

            const assets = [];
            Object.keys(responseBody).forEach((assetName) => {
                if (responseBody[assetName].status === 200) {
                    const assetUpdates = responseBody[assetName].response;
                    assets.push({
                        ...assetUpdates,
                        type: assetType,
                        name: assetName
                    });
                }
            });

            updateAssets(projectName, assets, false);

            return {
                ...state,
                projects: { byName: projectsByName, allNames: projectsAllNames, isInitialized: true },
                assets: { byId: assetsById, allIds: allAssetsIds },
                error: false
            };
        }

        case UPLOAD_RAW_RECORDING_PROGRESS: {
            return {
                ...state,
                rawRecordingUploadStatus: action.payload.progressStatus,
                rawRecordingUploadFinish: false,
                error: false
            };
        }

        case UPLOAD_RAW_RECORDING_FINISH: {
            return {
                ...state,
                rawRecordingUploadFinish: true,
                error: false
            };
        }

        case UPLOAD_RAW_RECORDING_CLEAR: {
            return {
                ...state,
                rawRecordingUploadStatus: undefined,
                rawRecordingUploadFinish: undefined,
                error: false
            };
        }

        default:
            return state;
    }
}

export default assetsReducer;
