import { v4 as uuid } from "uuid";
import { MediaTypes } from "../../../common/types/asset";
import type { Story as StoryJSON } from "../../../common/types/story";
import type { Program } from "../../../common/types/program";
import { ProgramType } from "../../../common/types/program";

class Story implements StoryJSON {
    name: string;
    description: string;
    initialDecisionPointId: string;
    displaySceneOrder: string[];
    backgroundAssetType: MediaTypes;
    activateCaching: boolean;
    participatingDPs: string[];
    participatingSceneIds: string[];

    constructor(storyData: Partial<StoryJSON>, program: Program) {
        this.name = storyData.name;
        this.description = storyData.description;
        this.initialDecisionPointId = uuid();
        this.displaySceneOrder = [];
        this.backgroundAssetType = MediaTypes.Image;
        this.activateCaching = program.programType === ProgramType.Ads;
        this.participatingSceneIds = storyData.participatingSceneIds;
        this.participatingDPs = storyData.participatingDPs;
    }
}

export default Story;
