let SystemDataElementsUtils = require("../../../common/SystemDataElementsUtils");
import memoizeOne from "memoize-one";
class DynamicValueSetSelector {
    static getDynamicValueSet(dataElement, wireframes, getStoriesAsArray) {
        let valueSet = [];
        let systemDataElementId = dataElement.id;
        switch (systemDataElementId) {
            case SystemDataElementsUtils.SYSTEM_DATA_STORY_ID: {
                return DynamicValueSetSelector.getStoriesValueSet(wireframes, getStoriesAsArray);
            }
        }
        return valueSet;
    }
    static getStoriesValueSet(wireframes, getStoriesAsArray) {
        let storiesArray = getStoriesAsArray(wireframes);
        return storiesArray.map((story) => ({ dn: story.name, id: "_system_" + story.id }));
    }
}
DynamicValueSetSelector.buildDynamicValueSetData = memoizeOne((wireframes, getStoriesAsArray) => {
    let sysDataElements = SystemDataElementsUtils.getDynamicSystemDataElements();
    let dynamicDataForValueSet = {};
    sysDataElements.forEach(function (sde) {
        dynamicDataForValueSet[sde.id] = DynamicValueSetSelector.getDynamicValueSet(sde, wireframes, getStoriesAsArray);
    });
    return dynamicDataForValueSet;
});
export default DynamicValueSetSelector;
