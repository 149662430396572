import { addVariablesToContext, resolveMappingTables, resolveVar } from "@sundaysky/vlx-runner";
import { buildInputRules } from "./logixBuilder";
import { createDataFeed, buildDerivedDataElementsFromLogic, extractMappingTableNames, createTablesObject } from "./dataElements";
import { LOGIC_CONTEXT, LOGIC_TYPE } from "./consts";
import type { LogicJSON } from "../../../common/types/logic";
import type { DataElementValues } from "../../../common/types/preset";
import type { PrioritizedList, TestResult } from "../common/types";
import type { DataTable } from "../../../common/types/dataTable";

let deepmerge = require("deepmerge");
if (typeof deepmerge === "object") {
    deepmerge = deepmerge.default;
}

type DerivedLogic = { [key: string]: LogicJSON };

function testSingleInputLogic(
    inputLogic: LogicJSON,
    dataElementsValues: DataElementValues,
    derivedLogic?: DerivedLogic,
    mappingTables?: DataTable[],
    dtaasEndPoint?: string,
    programDataFields?: any,
    prioritizedLists?: PrioritizedList[],
    inputName?: string
): Promise<TestResult> {
    const rules = buildInputRules(inputLogic, programDataFields);

    const dataElementNameAndValues: DataElementValues = dataElementsValues.map((deVal) => {
        return { name: deVal.name, value: deVal.value };
    });
    let data = createDataFeed(dataElementNameAndValues);

    let tables = createTablesObject(mappingTables);
    let tableData = extractMappingTableNames({ inputLogic, derivedLogic }, mappingTables);
    let variableLogic;
    if (inputLogic && inputLogic.outputType === LOGIC_CONTEXT.Prioritized) {
        const pList = prioritizedLists.find((pl) => pl.id === inputName);
        const slotLimit = pList ? pList.maxSlots : undefined;
        variableLogic = {
            type: "object",
            cardinality: {
                multiple: true,
                transform: "slots",
                limit: slotLimit
            },
            rules: rules
        };
    }
    else if (inputLogic && inputLogic.outputType === LOGIC_TYPE.Color) {
        variableLogic = {
            type: LOGIC_TYPE.Color,
            rules: rules
        };
    }
    else {
        variableLogic = {
            type: "text",
            rules: rules
        };
    }

    let promise;
    if (tableData.tables && tableData.tables.length > 0) {
        promise = resolveMappingTables({ tables }, { tableData }, data.dataElements, dtaasEndPoint);
    }
    else {
        promise = Promise.resolve({});
    }

    return promise.then((resolvedMappingTables) => {
        data.dataElements = deepmerge(data.dataElements, resolvedMappingTables);

        if (derivedLogic) {
            const derivedDataElements = buildDerivedDataElementsFromLogic(inputLogic, { ...programDataFields, derivedLogic });
            addVariablesToContext(derivedDataElements, data);
        }

        const resolved = resolveVar(variableLogic, data);
        return { result: resolved.value, case: resolved.case };
    });
}

export default testSingleInputLogic;
