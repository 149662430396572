import PNotify from "pnotify";
import escape from "escape-html";
import "pnotify/dist/pnotify.confirm";
import "pnotify/dist/pnotify.buttons";
import "pnotify/dist/pnotify.callbacks";
import "pnotify/dist/pnotify.css";
import "pnotify/dist/pnotify.brighttheme.css";
import "../../css/pnotify.css";
import "../../css/pnotify.brighttheme.css";
import "./hubNotifications.css";
class HubNotifications {
    static error(errorMessage) {
        let encodedErrorMessage = escape(errorMessage);
        new PNotify({
            title: "Error",
            text: encodedErrorMessage,
            hide: false,
            type: "error",
            buttons: {
                sticker: false
            }
        });
    }
    static confirm(title, confirmMsg, confirmCB, cancelCB, confirmButtonText, cancelButtonText) {
        let encodedTitle = escape(title);
        let encodedConfirmMsg = escape(confirmMsg);
        new PNotify({
            width: "450px",
            title: encodedTitle,
            text: encodedConfirmMsg,
            hide: false,
            icon: "brighttheme-question-icon",
            addclass: "stack-modal input-popup-notification",
            stack: { dir1: "down", dir2: "right", modal: true, firstpos1: 200 },
            buttons: {
                closer: false,
                sticker: false
            },
            confirm: {
                confirm: true,
                buttons: [
                    {
                        text: cancelButtonText ? cancelButtonText : "No",
                        addClass: "whiteBTN",
                        click: function (notice) {
                            notice.remove();
                            if (typeof cancelCB === "function") {
                                cancelCB();
                            }
                        }
                    },
                    {
                        text: confirmButtonText ? confirmButtonText : "Yes",
                        click: function (notice) {
                            notice.remove();
                            confirmCB();
                        }
                    }
                ]
            }
        });
    }
    static confirmWithCancel(title, confirmMsg, confirmCB, cancelCB, closeCB) {
        let encodedTitle = escape(title);
        let encodedConfirmMsg = escape(confirmMsg);
        new PNotify({
            width: "450px",
            height: "260px",
            title: encodedTitle,
            text: encodedConfirmMsg,
            hide: false,
            icon: "brighttheme-question-icon",
            addclass: "stack-modal input-popup-notification",
            stack: { dir1: "down", dir2: "right", modal: true, firstpos1: 200 },
            buttons: {
                closer: false,
                sticker: false
            },
            confirm: {
                confirm: true,
                buttons: [
                    {
                        text: "Cancel",
                        addClass: "whiteBTN",
                        click: function (notice) {
                            notice.remove();
                            if (typeof closeCB === "function") {
                                closeCB();
                            }
                        }
                    },
                    {
                        text: "No",
                        addClass: "whiteBTN",
                        click: function (notice) {
                            notice.remove();
                            if (typeof cancelCB === "function") {
                                cancelCB();
                            }
                        }
                    },
                    {
                        text: "Yes",
                        click: function (notice) {
                            notice.remove();
                            confirmCB();
                        }
                    }
                ]
            }
        });
    }
    static sorry(title, confirmMsg, confirmCB) {
        let encodedTitle = escape(title);
        let encodedConfirmMsg = escape(confirmMsg);
        new PNotify({
            width: "450px",
            height: "260px",
            title: encodedTitle,
            text: encodedConfirmMsg,
            hide: false,
            icon: "brighttheme-error-icon",
            addclass: "stack-modal input-popup-notification",
            stack: { dir1: "down", dir2: "right", modal: true, firstpos1: 200 },
            buttons: {
                closer: false,
                sticker: false
            },
            confirm: {
                confirm: true,
                buttons: [
                    {
                        text: "Got it!",
                        click: function (notice) {
                            notice.remove();
                            confirmCB();
                        }
                    },
                    null
                ]
            }
        });
    }
    static inputPopup(title, confirmMsg, confirmCB, cancelCB, confirmButtonText, cancelButtonText, validateFunction, initialValue, emptyPromptMessage) {
        let encodedTitle = escape(title);
        let encodedConfirmMsg = escape(confirmMsg);
        let promptValue = initialValue ? initialValue : "";
        let emptyFieldValue = emptyPromptMessage ? encodedConfirmMsg(emptyPromptMessage) : "Enter name";
        let getValidationResult = (value) => {
            if (typeof validateFunction !== "function") {
                return {
                    result: true
                };
            }
            return validateFunction(value);
        };
        let buttons = function () {
            return [
                {
                    text: cancelButtonText ? cancelButtonText : "No",
                    addClass: "whiteBTN reject-button",
                    promptTrigger: false,
                    click: (notice) => {
                        notice.remove();
                        if (typeof cancelCB === "function") {
                            cancelCB();
                        }
                    }
                },
                {
                    text: confirmButtonText ? confirmButtonText : "Yes",
                    addClass: "confirm-button",
                    promptTrigger: false,
                    click: (notice, value) => {
                        if (typeof confirmCB === "function" && value !== initialValue && getValidationResult(value).result) {
                            notice.remove();
                            confirmCB(value);
                        }
                    }
                }
            ];
        };
        let getConfirm = function (value) {
            return {
                prompt: true,
                prompt_class: "input-popup",
                prompt_default: value,
                confirm: true,
                buttons: buttons()
            };
        };
        let setValidateFunction = function (notice, value, emptyFieldValue) {
            notice
                .get()
                .find(".input-popup")
                .attr("placeholder", emptyFieldValue);
            const toggleConfirmButtonState = (status) => {
                const confirmButton = notice.get().find(".confirm-button");
                if (status) {
                    confirmButton.removeClass("invalid");
                }
                else {
                    confirmButton.addClass("invalid");
                }
            };
            const toggleValidationMessage = (status, value) => {
                const inputPopup = notice.get().find(".input-popup");
                const invalidMessage = inputPopup.siblings(".input-popup-invalid-message").get(0);
                const validFunctionResult = getValidationResult(value);
                if (invalidMessage) {
                    invalidMessage.remove();
                    inputPopup.removeClass("invalid");
                }
                if (status && validFunctionResult.result === false) {
                    inputPopup.after(`<div class="input-popup-invalid-message">${validFunctionResult.message}</div>`);
                    inputPopup.addClass("invalid");
                }
            };
            // Initial
            toggleValidationMessage(false, value);
            if (!value) {
                toggleConfirmButtonState(false);
            }
            // Add keyup event listener
            notice
                .get()
                .find(".input-popup")
                .focus()
                .keyup(({ keyCode }) => {
                if (keyCode === 27) {
                    // Esc
                    notice
                        .get()
                        .find(".reject-button")
                        .get(0)
                        .click();
                }
                else if (keyCode === 13) {
                    // Enter
                    // Check value is not invalid
                    const confirmButton = notice.get().find(".confirm-button");
                    const confirmButtonClass = confirmButton.attr("class");
                    const valid = confirmButton && confirmButtonClass ? !confirmButtonClass.includes("invalid") : false;
                    if (valid) {
                        confirmButton.get(0).click();
                    }
                }
                else {
                    // All other keys
                    const newValue = notice
                        .get()
                        .find(".input-popup")
                        .get(0).value;
                    const valueValid = newValue !== "" && getValidationResult(newValue).result;
                    if (valueValid) {
                        toggleConfirmButtonState(true);
                        toggleValidationMessage(false);
                    }
                    else {
                        toggleConfirmButtonState(false);
                        toggleValidationMessage(true, newValue);
                    }
                }
            });
        };
        new PNotify({
            width: "450px",
            height: "260px",
            title: encodedTitle,
            text: encodedConfirmMsg,
            hide: false,
            icon: "",
            addclass: "stack-modal input-popup-notification",
            stack: { dir1: "down", dir2: "right", modal: true, firstpos1: 200 },
            buttons: {
                closer: false,
                sticker: false
            },
            confirm: getConfirm(promptValue),
            before_open: (notice) => {
                setValidateFunction(notice, promptValue, emptyFieldValue);
                notice
                    .get()
                    .find(".input-popup")
                    .get(0)
                    .select();
            }
        });
    }
}
export default HubNotifications;
