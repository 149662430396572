export enum SuccessAction {
    CREATED = "created",
    UPDATED = "updated",
    DELETED = "deleted",
    RE_ACTIVATE = "re-activated"
}

export type SuccessMessageType = {
    success: SuccessAction;
    warning?: string;
};
