import ntol from "number-to-letter";
import { CC_STAGING_ACCOUNT_IDS, EDITOR_ACCOUNT_ID, entityType, LogicContainers, placeholderType, sceneAssetType, storyAssetType, wireFramesAssetType } from "../../../common/commonConst";
import { changeGetterForValueSet, findDataElementByLogicValue, getDataElementDisplayName, getDataElementFromDerivedKey, getDataElementId, getDerivedDataElementLogicId, isValueSetSupportedForDataElementType } from "../DataElements/DataElementsManager";
import { createLogic } from "../Logics/Logic";
import { isPermitted, permissions } from "../../../common/rolesPermissionsConst";
import NarrationsModelUtils from "../projects/projectWireframes/projectNarrations/NarrationsModelUtils";
import { ASSET_TYPES, LOGIC_CONTEXT, VALIDATION_STATUS } from "../vlx/consts";
import memoizeOne from "memoize-one";
import DynamicValueSetSelector from "./DynamicValueSetSelector";
import { MediaTypes } from "../../../common/types/asset";
import { reportConfigurationTemplate } from "@sundaysky/smartvideo-hub-config";
import { DataElementContentTypes, DataElementOrigins } from "../../../common/types/dataElement";
import { LogicContext, LogicType } from "../../../common/types/logic";
import * as _ from "lodash";
import { findProgramSummaryForProgramId, getDashboardsProgramIds } from "../../components/main/mainContainerUtils";
import { GqlClientDynamoMigrationStatus } from "../../graphql/graphqlGeneratedTypes/graphqlClient";
import UrlUtils from "./urlUtils";
import { CREATIVES_PRESET_ID } from "./Consts";
import { getAccountIdFromPath } from "./routingUtils";
class StateReaderUtils {
    ///////////////////////////////////////         UTILS          ////////////////////////////////////////////////////
    static getTasksStartingTime(state) {
        return state.tasksStartingTime;
    }
    static getTaskStartingTime(state, taskId) {
        return state.tasksStartingTime[taskId];
    }
    static getBrowserHistory(state) {
        return state.browserHistory.allLinks;
    }
    static getBrowserHistoryctiveId(state) {
        return state.browserHistory.activeId;
    }
    static getShouldTurnOffMergeDPSession(state) {
        return state.shouldTurnOffMergeDPSession;
    }
    static objectComparator(field, reverse) {
        return function (a, b) {
            a = a[field];
            b = b[field];
            if (typeof a === "string") {
                a = a.toLowerCase();
                b = b.toLowerCase();
            }
            return reverse * ((a > b) - (b > a));
        };
    }
    static isFeatureFlagsLoad(state) {
        return state.enableAllFeatureFlags !== undefined || state.featureFlags !== undefined;
    }
    static isProgramsDynamoMigrationStatusLoaded(state) {
        return state.programsDynamoMigrationStatus !== undefined;
    }
    static isFeatureEnable(state, featureFlagId, overridingProgramId) {
        if (state.enableAllFeatureFlags !== undefined) {
            return !!state.enableAllFeatureFlags;
        }
        const featureFlagObj = state.featureFlags && state.featureFlags[featureFlagId];
        if (!featureFlagObj) {
            return false;
        }
        const accountId = getAccountIdFromPath();
        if (StateReaderUtils.isAccountExcludedFromFeatureFlag(accountId, featureFlagObj.excludedAccounts)) {
            return !(featureFlagObj.defaultValue);
        }
        const { program: programFromUrl } = UrlUtils.getAccountAndProgramFromPath();
        const programIdOrDashboardName = overridingProgramId !== null && overridingProgramId !== void 0 ? overridingProgramId : programFromUrl;
        if (programIdOrDashboardName && StateReaderUtils.isProgramExcludedFromFeatureFlag(accountId, programIdOrDashboardName, featureFlagObj.excludedPrograms, state.projectSummaries)) {
            return !(featureFlagObj.defaultValue);
        }
        return featureFlagObj.defaultValue;
    }
    static isFeatureEnableIgnoreExcludedPrograms(state, featureFlagId) {
        if (state.enableAllFeatureFlags !== undefined) {
            return !!state.enableAllFeatureFlags;
        }
        return state.featureFlags && state.featureFlags[featureFlagId] && state.featureFlags[featureFlagId].defaultValue;
    }
    static isAccountExcludedFromFeatureFlag(accountId, excludedAccounts) {
        return excludedAccounts.some((account) => account.accountId === accountId);
    }
    static isProgramExcludedFromFeatureFlag(accountId, programIdOrDashboardName, excludedPrograms, projectSummaries = []) {
        const isProgramExcluded = (programKey) => excludedPrograms.some((program) => program === programKey || // this check is for backward compatibility, when programs were saved as plain string
            program.dynamoUniqueId === programKey ||
            program.postgresUniqueId === programKey);
        // Check programs that their id is programIdOrDashboardName
        const programKey = `${accountId}/${programIdOrDashboardName}`;
        const result = isProgramExcluded(programKey);
        if (result) {
            return result;
        }
        // Check program that their DASHBOARD id is programIdOrDashboardName
        const projectSummary = projectSummaries.find(ps => ps.projectIds.dashboards === programIdOrDashboardName);
        if (projectSummary) {
            const alternativeKey = `${accountId}/${projectSummary.id}`;
            return isProgramExcluded(alternativeKey);
        }
        return false;
    }
    static getFeatureFlags(state) {
        return state.featureFlags;
    }
    static getProgramDynamoMigrationStatus(state, programId) {
        return state.programsDynamoMigrationStatus && state.programsDynamoMigrationStatus[programId];
    }
    // todo - these should be properly cleaned up along with the migration mechanic and the legacy rest/dynamo
    static isProgramBulkDynamoMigrationDone(state, programId, bulkId) {
        return true;
    }
    static isProgramBulkDynamoMigrationDoneByLegacyId(state, projectName, bulkId) {
        return true;
    }
    static getForceLoad(state) {
        return state.forceLoad;
    }
    static getReportConfigurationJSON(state) {
        return reportConfigurationTemplate;
    }
    ///////////////////////////////////////         DASHBOARDS         ////////////////////////////////////////////////
    static getDashboardScenes(state) {
        let arr = [];
        state.dashboardScenes.allIds.forEach((id) => {
            arr.push(state.dashboardScenes.byId[id]);
        });
        arr = arr.sort(this.objectComparator("name", 1));
        return arr;
    }
    static getDashboardProgramsScenes(programIds, statePrograms) {
        let selectedScenesObj = {};
        programIds.forEach((programId) => {
            if (statePrograms.byId[programId]) {
                statePrograms.byId[programId].scenes.forEach((sceneId) => {
                    selectedScenesObj[sceneId] = true;
                });
            }
        });
        return Object.keys(selectedScenesObj);
    }
    static getDashboardSelectedProgramDetails(state) {
        if (state.dashboardPrograms && state.selectedProgramId) {
            return {
                id: state.selectedProgramId,
                details: state.dashboardPrograms.byId[state.selectedProgramId].details
            };
        }
    }
    static getDashboardPrograms(state, drawerFilter) {
        let arr = [];
        const filteredProgramIds = getDashboardsProgramIds(StateReaderUtils.getProgramSummaries(state), drawerFilter);
        state.dashboardPrograms.allIds.forEach((id) => {
            filteredProgramIds.has(id.toLowerCase()) && arr.push(state.dashboardPrograms.byId[id]);
        });
        return arr;
    }
    static getDashboardAccountInfo(state) {
        return state.accountInfo;
    }
    static getPreviewDashboardSessionObj(state) {
        return state.previewDashboardSessionObj;
    }
    static getSecondPreviewSessionObject(state) {
        return state.secondPreviewDashboardSessionObj;
    }
    static getSharePreviewSessionObject(state) {
        return state.shareDashboardSessionObj;
    }
    static getShareUrl(state) {
        return state.shareUrl;
    }
    static getImageUrl(state) {
        return state.imageUrl;
    }
    //////////////////////////////////         PROJECTS | PROGRAMS           /////////////////////////////////////
    static getProjects(stateProjects) {
        let projects = [];
        if (stateProjects.allNames) {
            stateProjects.allNames.forEach(function (projectName) {
                projects.push(stateProjects.byName[projectName]);
            });
        }
        return projects;
    }
    static getProject(state, projectName) {
        return state.projects.byName[projectName];
    }
    static getProjectType(state, projectName) {
        const project = StateReaderUtils.getProject(state, projectName);
        return project && project.programType;
    }
    static getProjectId(state, projectName) {
        return StateReaderUtils.getProject(state, projectName).programId;
    }
    static getNewPrograms(state) {
        return state.newPrograms;
    }
    static getProgramWithNoBuilders(state) {
        const allAccountPrograms = StateReaderUtils.getNewPrograms(state);
        const existingPrograms = StateReaderUtils.getBuildersProgramsSummariesWithSfIds(state) || [];
        return allAccountPrograms && allAccountPrograms.filter((program) => program.programId && !existingPrograms.find((bProg) => bProg.projectIds.sfId === program.programId));
    }
    static getProgramRFR(state, programName, stage, version) {
        let wireframes = StateReaderUtils.getWireFrame(state, programName, stage, version);
        return (wireframes && wireframes.programRFR) || 0;
    }
    static getProgramRFRTimestamp(state, programName, stage, version) {
        let wireframes = StateReaderUtils.getWireFrame(state, programName, stage, version);
        return wireframes && wireframes.programRFRTimestamp;
    }
    static getStoriesVersion(state, programName, stage, version) {
        let wireframes = StateReaderUtils.getWireFrame(state, programName, stage, version);
        return StateReaderUtils.getStoriesVersionFromWireframes(wireframes);
    }
    static getStoriesVersionFromWireframes(wireframes) {
        return wireframes && wireframes.storiesVersion;
    }
    static getTransition(wireframes) {
        if (!wireframes) {
            return undefined;
        }
        if (wireframes.transition) {
            return wireframes.transition;
        }
        // Backward compatibility
        if (!wireframes.stories) {
            return undefined;
        }
        const firstStoryId = Object.keys(wireframes.stories)[0];
        if (!firstStoryId) {
            return undefined;
        }
        return wireframes.stories[firstStoryId].transition;
    }
    static getCreativeVersion(state, programName, stage, version) {
        let wireframes = StateReaderUtils.getWireFrame(state, programName, stage, version);
        return StateReaderUtils.getCreativeVersionFromWireframes(wireframes);
    }
    static getCreativeVersionFromWireframes(wireframes) {
        return wireframes && wireframes.creativeVersion;
    }
    static getCreativeDDE(state, programName, stage, version) {
        let wireframes = StateReaderUtils.getWireFrame(state, programName, stage, version);
        return StateReaderUtils.getCreativeDDEFromWireframes(wireframes);
    }
    static getCreativeDDEFromWireframes(wireframes) {
        return wireframes && wireframes.creativeDDE;
    }
    static getCreativeNameLabel(state, programName, stage, version) {
        let wireframes = StateReaderUtils.getWireFrame(state, programName, stage, version);
        return StateReaderUtils.getCreativeNameLabelFromWireframes(wireframes);
    }
    static getCreativeNameLabelFromWireframes(wireframes) {
        return wireframes && wireframes.creativeNameLabel;
    }
    static getCustomAnalyticFields(wireframes) {
        return wireframes && wireframes.customAnalyticFields;
    }
    //////////////////////////////////         SNAPSHOTS | VERSIONS | LIFECYCLE         ///////////////////////////////
    static getSnapshot(state, snapshot) {
        if (state.snapshots) {
            return state.snapshots.byId[snapshot.snapshotId];
        }
    }
    static getSnapshotByNumber(state, projectName, snapshotNumber) {
        const snapshotId = StateReaderUtils.getSnapshotIdByNumber(projectName, snapshotNumber);
        return state.snapshots && state.snapshots.byId && state.snapshots.byId[snapshotId];
    }
    static getSnapshotsFromState(state) {
        return state.snapshots;
    }
    static getSnapshots(snapshotsById, stageSnapshots) {
        let snapshots = [];
        if (stageSnapshots) {
            stageSnapshots.forEach(function (stageSnapshot) {
                snapshots.push(snapshotsById[stageSnapshot.snapshotId]);
            });
        }
        return snapshots;
    }
    static getIsStageView(stage, version) {
        return !!((stage && stage !== "draft") || version);
    }
    static getIsViewOnly(state, stage, version) {
        return this.getIsStageView(stage, version) || this.getIsEditorsViewer(state);
    }
    static getSnapshotNumber(stageSnapshot) {
        return stageSnapshot.snapshotNumber;
    }
    static getSnapshotDate(stageSnapshot) {
        return stageSnapshot.promotedDate;
    }
    static getSnapshotName(stageSnapshot) {
        return stageSnapshot.snapshotName;
    }
    static getPromoteStatus(state) {
        if (state.versionManager) {
            return state.versionManager.promoteStatus;
        }
        return false;
    }
    static getRevertToVersion(state) {
        return state.revertToVersion;
    }
    static getSnapshotIdByNumber(projectName, snapshotNumber) {
        if (snapshotNumber && snapshotNumber > -1) {
            return projectName + "/" + snapshotNumber;
        }
        return "";
    }
    static getSnapshotAssetsBySnapshotStage(assets, snapshots, project, stage) {
        let projectStageSnapshots = project && project[stage + "Snapshots"];
        if (assets &&
            projectStageSnapshots &&
            projectStageSnapshots[0] &&
            projectStageSnapshots[0].snapshotId &&
            snapshots.byId[projectStageSnapshots[0].snapshotId] &&
            snapshots.byId[projectStageSnapshots[0].snapshotId].assets) {
            return snapshots.byId[projectStageSnapshots[0].snapshotId].assets.map((snapshotAsset) => {
                let assetId = StateReaderUtils.getAssetUniqueId(project.accountId, project.projectName, snapshotAsset.type, snapshotAsset.name);
                if (assets.byId[assetId]) {
                    snapshotAsset.title = assets.byId[assetId].title;
                }
                return snapshotAsset;
            });
        }
        return [];
    }
    static getSnapshotAssetsBySnapshotVersion(assets, snapshots, project, version) {
        const versionSnapshotId = this.getSnapshotIdByNumber(project.projectName, version);
        if (assets && snapshots.byId[versionSnapshotId] && snapshots.byId[versionSnapshotId].assets) {
            return snapshots.byId[versionSnapshotId].assets.map((snapshotAsset) => {
                let assetId = StateReaderUtils.getAssetUniqueId(project.accountId, project.projectName, snapshotAsset.type, snapshotAsset.name);
                if (assets.byId[assetId]) {
                    snapshotAsset.title = assets.byId[assetId].title;
                }
                return snapshotAsset;
            });
        }
        return [];
    }
    static getLatestSnapshotIdForStage(project, stage) {
        const stageSnapshot = `${stage}Snapshots`;
        return project && project[stageSnapshot] && project[stageSnapshot][0] && project[stageSnapshot][0].snapshotId;
    }
    static getLatestSnapshotNumberForStage(state, projectName, stage) {
        var _a, _b;
        const project = StateReaderUtils.getProject(state, projectName);
        return (_b = (_a = project === null || project === void 0 ? void 0 : project[stage + "Snapshots"]) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.snapshotNumber;
    }
    static getWireframesUIVersionFromSnapshot({ state, projectName, version, stage }) {
        let snapshotId;
        if (version) {
            snapshotId = StateReaderUtils.getSnapshotIdByNumber(projectName, version);
        }
        else if (stage) {
            const project = StateReaderUtils.getProject(state, projectName);
            snapshotId = StateReaderUtils.getLatestSnapshotIdForStage(project, stage);
        }
        const snapshotAssets = StateReaderUtils.getSnapshotAssets(state, snapshotId);
        const wireframesUIAsset = snapshotAssets && snapshotAssets.find((asset) => asset.type === wireFramesAssetType);
        return wireframesUIAsset && wireframesUIAsset.version;
    }
    static getSnapshotDetails(state, snapshotNumber) {
        return state.versions && state.versions.find(snapshot => snapshot.snapshotNumber === snapshotNumber);
    }
    static getAllVersions(state) {
        return state.versions;
    }
    static getLatestVersionNumber(state, projectName) {
        if (state.versions && state.versions.length) {
            let versionNumbers = state.versions.map((version) => {
                return version.snapshotNumber;
            });
            return Math.max(...versionNumbers);
        }
        //legacy programs dont load data to state.versions.
        else if (state.snapshots && state.snapshots.byId) {
            const snapshotsArr = Object.values(state.snapshots.byId);
            let versionNumbers = snapshotsArr.reduce((acc, snapshot) => {
                const snapshotProjectName = snapshot.snapshotId.split("/")[0];
                if (snapshotProjectName === projectName) {
                    acc.push(snapshot.snapshotNumber);
                }
                return acc;
            }, []);
            if (versionNumbers.length) {
                return Math.max(...versionNumbers);
            }
        }
        return undefined;
    }
    static getSnapshotAssets(state, snapshotId) {
        return snapshotId ? state.snapshots.byId[snapshotId] && state.snapshots.byId[snapshotId].assets : [];
    }
    static getProjectStageSnapshots(state, projectName, stage) {
        var _a, _b;
        let project = StateReaderUtils.getProject(state, projectName);
        if (project) {
            let stageSnapshots = project[stage + "Snapshots"];
            if (stageSnapshots && stageSnapshots.length > 0) {
                const versionToNameMap = (_b = (_a = state.versions) === null || _a === void 0 ? void 0 : _a.reduce((acc, version) => {
                    acc[version.snapshotNumber] = version.snapshotName;
                    return acc;
                }, {})) !== null && _b !== void 0 ? _b : {};
                return stageSnapshots.map((stageSnapshot) => {
                    return Object.assign(Object.assign({}, stageSnapshot), { snapshotName: versionToNameMap[stageSnapshot.snapshotNumber] });
                });
            }
        }
        return [];
    }
    static getDiffWireframes(state, projectName, version) {
        const projectDiffWireframes = state["diffwireframes"].byName[projectName];
        return projectDiffWireframes && version ? projectDiffWireframes[version] : projectDiffWireframes;
    }
    static getDiffModel(state) {
        return state.diffModel;
    }
    ////////////////////////////////////////////         ASSETS         ///////////////////////////////////////////////
    static getAssetUniqueId(accountId, projectName, assetType, assetName) {
        return accountId + "/" + projectName + "/" + assetType + "/" + assetName;
    }
    static getAssetId(assetType, assetName) {
        return assetType + "/" + assetName;
    }
    static getAssets(state) {
        return state.assets;
    }
    static getProjectAssetsValidAsBackgroundAsset(stateProject, stateAssets) {
        return StateReaderUtils.memoizeGetProjectAssetsValidAsBackgroundAsset(stateProject, stateAssets);
    }
    static getLiveProjectAssetsByType(stateProject, stateAssets, assetType) {
        return StateReaderUtils.memoizeGetLiveProjectAssetsByType(stateProject, stateAssets, assetType);
    }
    static getProjectAssets(stateProject, stateAssets) {
        return StateReaderUtils.memoizeGetProjectAssets(stateProject, stateAssets);
    }
    static getProjectAssetsByType(stateProject, stateAssets, assetType) {
        return StateReaderUtils.memoizeGetProjectAssetsByType(stateProject, stateAssets, assetType);
    }
    static getProjectAssetsCount(stateProject, assetType) {
        assetType = assetType ? assetType : "all";
        return stateProject && stateProject.totalAssetCount && stateProject.totalAssetCount[assetType] ? stateProject.totalAssetCount[assetType] : 0;
    }
    static getAssetsFromSnapshotOrDraft(program, stateAssets, stateSnapshots, stage, version, assetType) {
        if (version) {
            return StateReaderUtils.getAssetsInVersionMode(program, stateAssets, stateSnapshots, version, assetType);
        }
        else if (stage) {
            return StateReaderUtils.getAssetsInStageMode(program, stateAssets, stateSnapshots, stage, assetType);
        }
        else if (assetType) {
            return StateReaderUtils.getProjectAssetsByType(program, stateAssets, assetType);
        }
        else {
            return StateReaderUtils.getProjectAssets(program, stateAssets);
        }
    }
    static getAssetsInVersionMode(program, stateAssets, stateSnapshots, version, assetType) {
        let snapshotId = StateReaderUtils.getSnapshotIdByNumber(program.projectName, version);
        return StateReaderUtils._getAssetsAdjustedToSnapshot(program, stateAssets, stateSnapshots, snapshotId, assetType);
    }
    static getAssetsInStageMode(program, stateAssets, stateSnapshots, stage, assetType) {
        const stageSnapshots = program && program[stage + "Snapshots"];
        const snapshotId = stageSnapshots && stageSnapshots[0] && stageSnapshots[0].snapshotId;
        return StateReaderUtils._getAssetsAdjustedToSnapshot(program, stateAssets, stateSnapshots, snapshotId, assetType);
    }
    static _getAssetsAdjustedToSnapshot(program, stateAssets, stateSnapshots, snapshotId, assetType) {
        const { accountId, projectName } = program;
        return StateReaderUtils.memoizeGetAssetsAdjustedToSnapshot(accountId, projectName, stateAssets, stateSnapshots, snapshotId, assetType);
    }
    static getAsset(state, accountId, projectName, assetType, dynamoAssetName) {
        const assetUniqueId = StateReaderUtils.getAssetUniqueId(accountId, projectName, assetType, dynamoAssetName);
        const stateAsset = StateReaderUtils.getAssets(state).byId[assetUniqueId];
        return stateAsset && Object.assign({}, stateAsset);
    }
    ////////////////////////////         WIREFRANES - SCENES | SCENE PARTS           //////////////////////////////////
    static getProjectMasterData(state, project, stage, version) {
        const wireframes = this.getWireFrame(state, project, stage, version);
        const masterScene = this.getProjectWireframesScene(wireframes, LogicContainers.Master);
        return masterScene && masterScene.sceneParts[0];
    }
    static getProjectMasterAnimationGuidelineByName(state, project, name, stage, version) {
        const masterData = this.getProjectMasterData(state, project, stage, version);
        return ((masterData && masterData.parameters) || []).find((parameter) => parameter.name === name);
    }
    static getProjectWireframesScene(wireframe, sceneId) {
        return wireframe && wireframe.scenes[sceneId];
    }
    static getProjectWireframesScenePart(wireframe, sceneId, scenePartIndex) {
        return wireframe && wireframe.scenes && wireframe.scenes[sceneId] && wireframe.scenes[sceneId].sceneParts && wireframe.scenes[sceneId].sceneParts[scenePartIndex];
    }
    static getProjectWireframesScenePartById(wireframe, sceneId, scenePartId) {
        let scenePart;
        if (wireframe && wireframe.scenes && wireframe.scenes[sceneId] && wireframe.scenes[sceneId].sceneParts) {
            scenePart = wireframe.scenes[sceneId].sceneParts.find((part) => part.scenePart === scenePartId);
        }
        return scenePart;
    }
    static getProjectWireframesStories(state, projectName, stage, version) {
        let wireframes = this.getWireFrame(state, projectName, stage, version);
        if (!wireframes || !wireframes.stories) {
            return undefined;
        }
        return wireframes.stories;
    }
    static getProjectWireframesStoriesArray(state, project, stage, version) {
        let storiesObj = this.getProjectWireframesStories(state, project, stage, version);
        if (!storiesObj) {
            return undefined;
        }
        let storiesArray = Object.keys(storiesObj).map((storyId) => {
            return storiesObj[storyId];
        });
        return storiesArray;
    }
    static getProjectWireframeScenes(state, project, stage, version) {
        let wireframes = this.getWireFrame(state, project, stage, version);
        return wireframes && wireframes.scenes ? wireframes.scenes : undefined;
    }
    static getProjectWireframeScenesArray(state, project, stage, version) {
        let scenesObj = this.getProjectWireframeScenes(state, project, stage, version);
        if (!scenesObj) {
            return undefined;
        }
        let sceneArray = Object.keys(scenesObj).map((sceneId) => {
            return scenesObj[sceneId];
        });
        return sceneArray;
    }
    static getProjectWireframeScenesIdsArray(state, project, stage, version) {
        let wireframes = this.getWireFrame(state, project, stage, version);
        if (!wireframes || !wireframes.scenes) {
            return undefined;
        }
        return Object.keys(wireframes.scenes);
    }
    static getProjectWireframeNarrations(state, project, stage, version) {
        const wireframes = this.getWireFrame(state, project, stage, version);
        return wireframes && wireframes.narrations ? wireframes.narrations : undefined;
    }
    static getWireframesPathOnState(projectName, stage, version) {
        if (stage && stage !== "draft") {
            if (stage === "diff" && version) {
                return ["diffwireframes", "byName", projectName, version];
            }
            return [`${stage}wireframes`, "byName", projectName];
        }
        else if (version) {
            return ["versionwireframes", "byName", projectName];
        }
        else {
            return ["wireframes", "byName", projectName];
        }
    }
    static getWireFrame(state, programName, stage, version) {
        if (stage && stage !== "draft") {
            if (stage === "diff" && version) {
                return StateReaderUtils.getDiffWireframes(state, programName, version);
            }
            return state[`${stage}wireframes`].byName[programName];
        }
        else if (version) {
            return state["versionwireframes"].byName[programName];
        }
        else {
            return state["wireframes"].byName[programName];
        }
    }
    static getWireframesGraphQLParentProgramVersionId(state, projectName) {
        if (state.wireframes.byName[projectName]) {
            return state.wireframes.byName[projectName].graphQLParentProgramVersionId;
        }
    }
    static serializeWireFrame(state, project, stage, version) {
        let wireframe = this.getWireFrame(state, project, stage, version);
        let serializedWireframe = Object.assign({}, wireframe);
        //Handle Narration data
        if (serializedWireframe.narrations) {
            serializedWireframe.narrations = Object.assign({}, serializedWireframe.narrations);
            serializedWireframe.narrations.byId = Object.assign({}, serializedWireframe.narrations.byId);
            Object.keys(serializedWireframe.narrations.byId).forEach((narrationId) => {
                let narrationState = serializedWireframe.narrations.byId[narrationId];
                serializedWireframe.narrations.byId[narrationId] = {
                    structure: NarrationsModelUtils.serializeStructure(narrationState.structure),
                    variationDataMap: NarrationsModelUtils.serializeVariationDataMap(narrationState.variationDataMap)
                };
            });
        }
        return serializedWireframe;
    }
    static getProjectLogicItemsThatMayContainHotspot(state, programName, stage, version) {
        const wireframes = StateReaderUtils.getWireFrame(state, programName, stage, version);
        return StateReaderUtils.getProjectLogicItemsThatMayContainHotspotInternal(wireframes.scenes, wireframes.logic);
    }
    static getPlaceHoldersTypes(scene, pHName) {
        let phTypes = [];
        if (scene) {
            scene.sceneParts.forEach((scenePart) => {
                scenePart[placeholderType.onscreen].forEach((ph) => {
                    if (ph.name === pHName) {
                        phTypes.push(ph.type);
                    }
                });
                if (scenePart.groups) {
                    scenePart.groups.forEach((group) => {
                        group.placeholders.forEach((ph) => {
                            if (ph.name === pHName) {
                                phTypes.push(ph.type);
                            }
                        });
                    });
                }
                if (scenePart[placeholderType.narration] && scenePart[placeholderType.narration].find((narration) => narration.name === pHName)) {
                    phTypes.push(placeholderType.narration);
                }
                if (scenePart[placeholderType.parameter].find((parameter) => parameter.name === pHName)) {
                    phTypes.push(placeholderType.parameter);
                }
            });
        }
        return phTypes;
    }
    static getScenePrioritizedList(wireframes, sceneId) {
        if (!wireframes ||
            !wireframes.logic ||
            !wireframes.logic[sceneId] ||
            !wireframes.scenes[sceneId] || // may happen if sceneId is "derived"
            !wireframes.scenes[sceneId].sceneParts || // may happen if sceneId is "derived"
            !wireframes.scenes[sceneId].sceneParts.length)
            return [];
        // get prioritized list name and max slots from wireframes
        const sceneparts = wireframes.scenes[sceneId].sceneParts;
        const prioritizedListWireframesArray = sceneparts.reduce((acc, scenePart) => (scenePart.prioritizedLists ? acc.concat(...scenePart.prioritizedLists) : acc), []);
        // filter prioritized list for scene
        const logicObjArray = Object.keys(wireframes.logic[sceneId]).map((id) => (Object.assign(Object.assign({}, wireframes.logic[sceneId][id]), { id })));
        const prioritizedListLogicArray = logicObjArray.filter((obj) => obj.outputType === LogicType.Prioritized);
        // create prioritizedListArray by combining logic, name and max slots
        const prioritizedListArray = prioritizedListLogicArray
            .map((plLogic) => {
            const matchPl = prioritizedListWireframesArray.find((plWireFrames) => plWireFrames.id === plLogic.id);
            // if there's no pl in wireframe that matches logic, return undefined (will be filtered out)
            if (!matchPl)
                return;
            return Object.assign(Object.assign({}, plLogic), { name: matchPl.name, maxSlots: matchPl.slots.length, slots: matchPl.slots });
        })
            .filter((item) => item !== undefined);
        return prioritizedListArray;
    }
    static getProjectPrioritizedLists(wireframes) {
        let prioritizedList = [];
        if (wireframes && wireframes.scenes) {
            const sceneIds = Object.keys(wireframes.scenes);
            sceneIds.forEach((sceneId) => {
                prioritizedList = prioritizedList.concat(StateReaderUtils.getScenePrioritizedList(wireframes, sceneId));
            });
        }
        return prioritizedList;
    }
    static getProjectPlaceholdersFromWireframes(wireframes) {
        const sceneIds = Object.keys(wireframes.scenes);
        let placeholders = [];
        sceneIds.forEach((sceneId) => {
            placeholders = placeholders.concat(StateReaderUtils.getScenePlaceholders(wireframes, sceneId));
        });
        return placeholders;
    }
    static getProjectDataElements(state, projectName, stage, version) {
        let wireFrames = StateReaderUtils.getWireFrame(state, projectName, stage, version);
        return StateReaderUtils.getProjectDataElementsFromWireframes(wireFrames);
    }
    static getProjectPrioritizedListsFromWireframes(wireframes) {
        if (!wireframes || !wireframes.logic || !wireframes.scenes)
            return [];
        const sceneIds = Object.keys(wireframes.scenes);
        const scenesLogicById = sceneIds.map((sceneId) => wireframes.logic[sceneId]).reduce((acc, item) => Object.assign(acc, item), {});
        const prioritizedLists = Object.keys(scenesLogicById)
            .filter((key) => scenesLogicById[key].outputType === LOGIC_CONTEXT.Prioritized)
            .map((plId) => (Object.assign(Object.assign({}, scenesLogicById[plId]), { id: plId })));
        return prioritizedLists;
    }
    static getProjectDataElementsFromWireframes(wireframes) {
        let dataElements = wireframes && wireframes.dataElements;
        let derivedLogic = StateReaderUtils.getAllProgramLogicFromWireframes(wireframes, LogicContainers.Derived);
        let dynamicValueSetData = DynamicValueSetSelector.buildDynamicValueSetData(wireframes, StateReaderUtils.getStoriesAsArray);
        return StateReaderUtils.memoizeAddGetterForValueSet(dataElements, derivedLogic, dynamicValueSetData);
    }
    static getProjectWireframesSceneInputLogic(state, project, sceneId, inputName, stage, version) {
        let wireframes = this.getWireFrame(state, project, stage, version);
        return wireframes && wireframes.logic && wireframes.logic[sceneId] ? wireframes.logic[sceneId][inputName] : undefined;
    }
    static getDecisionPointLogicItemsForStory(state, projectName, storyId, stage, version) {
        let wireframes = this.getWireFrame(state, projectName, stage, version);
        return StateReaderUtils.getDecisionPointLogicItemsForStoryFromWireframes(wireframes, storyId);
    }
    static getDecisionPointLogicItemsForStoryFromWireframes(wireframes, storyId) {
        return ((wireframes &&
            wireframes.logic &&
            wireframes.logic[LogicContainers.Story] &&
            wireframes.logic[LogicContainers.Story][storyId] &&
            wireframes.logic[LogicContainers.Story][storyId][LogicContainers.DecisionPoint]) ||
            {});
    }
    static getStoryLogicItems(state, projectName, storyId, stage, version) {
        let wireframes = this.getWireFrame(state, projectName, stage, version);
        return (wireframes && wireframes.logic && wireframes.logic[LogicContainers.Story] && wireframes.logic[LogicContainers.Story][storyId]) || {};
    }
    static getStoryBackgroundAssetLogic(state, projectName, storyId, stage, version) {
        let wireframes = this.getWireFrame(state, projectName, stage, version);
        return this.getStoryBackgroundAssetLogicFromWireframe(wireframes, storyId);
    }
    static getStoryBackgroundAssetLogicFromWireframe(wireframes, storyId) {
        let story = this.getStoryById(wireframes, storyId);
        if (!story || !story.backgroundAssetType) {
            return {};
        }
        return this.getStoryLogicItemFromWireframeByType(wireframes, storyId, LogicContainers.BackgroundAsset, story.backgroundAssetType);
    }
    static getStorySoundtrackLogic(state, projectName, storyId, stage, version) {
        let wireframes = this.getWireFrame(state, projectName, stage, version);
        return this.getStorySoundtrackLogicFromWireframe(wireframes, storyId);
    }
    static getStoryVideoRatioQualityLogic(state, projectName, storyId, stage, version) {
        let wireframes = this.getWireFrame(state, projectName, stage, version);
        return this.getStoryVideoRatioQualityLogicWireframe(wireframes, storyId);
    }
    static getStorySoundtrackLogicFromWireframe(wireframes, storyId) {
        return this.getStoryLogicItemFromWireframeByType(wireframes, storyId, LogicContainers.Soundtrack, MediaTypes.Audio);
    }
    static getStoryVideoRatioQualityLogicWireframe(wireframes, storyId) {
        return this.getStoryLogicItemFromWireframeByType(wireframes, storyId, LogicContainers.VideoSettings, LogicContext.StoryRatioAndQuality);
    }
    static getStoryLogicItemFromWireframeByType(wireframes, storyId, logicType, logicId) {
        return ((wireframes &&
            wireframes.logic &&
            wireframes.logic[LogicContainers.Story] &&
            wireframes.logic[LogicContainers.Story][storyId] &&
            wireframes.logic[LogicContainers.Story][storyId][logicType] &&
            wireframes.logic[LogicContainers.Story][storyId][logicType][logicId]) ||
            {});
    }
    static getDecisionPointsLogic(state, projectName, storyId, decisionPointId, stage, version) {
        let allDPLogic = StateReaderUtils.getDecisionPointLogicItemsForStory(state, projectName, storyId, stage, version);
        return allDPLogic[decisionPointId];
    }
    static getInputLogicFromStateProjectLogic(project, sceneId, inputName) {
        return project && project[sceneId] ? project[sceneId][inputName] : undefined;
    }
    static getProgramStorySelectionLogic(state, projectName, stage, version) {
        let wireframes = this.getWireFrame(state, projectName, stage, version);
        return StateReaderUtils.getProgramLogicItemFromWireframeByType(wireframes, LogicContainers.StorySelection, LogicContainers.StorySelection);
    }
    static getAllProgramLogicItemsFromWireframeByType(wireframes, logicType) {
        return wireframes && wireframes.logic && wireframes.logic[LogicContainers.Program] && wireframes.logic[LogicContainers.Program][logicType];
    }
    static getProgramLogicItemFromWireframeByType(wireframes, logicType, logicId) {
        return (wireframes &&
            wireframes.logic &&
            wireframes.logic[LogicContainers.Program] &&
            wireframes.logic[LogicContainers.Program][logicType] &&
            wireframes.logic[LogicContainers.Program][logicType][logicId]);
    }
    static getProgramLogicItems(state, projectName, stage, version) {
        let wireframes = this.getWireFrame(state, projectName, stage, version);
        return wireframes && wireframes.logic && wireframes.logic[LogicContainers.Program];
    }
    static getProjectWireframesLogic(state, projectName, stage, version) {
        let wireframes = this.getWireFrame(state, projectName, stage, version);
        return wireframes && wireframes.logic ? wireframes.logic : undefined;
    }
    // Gets all logic data for under a scene / derived / analytics field
    static getAllProgramLogic(state, programName, container, stage, version) {
        let wireframes = this.getWireFrame(state, programName, stage, version);
        return StateReaderUtils.getAllProgramLogicFromWireframes(wireframes, container);
    }
    static getAllProgramLogicFromWireframes(wireframes, container) {
        if (!wireframes || !wireframes.logic || !wireframes.logic[container]) {
            return undefined;
        }
        return wireframes.logic[container];
    }
    static getCustomAnalyticsFieldData(programWireframes, fieldName) {
        if (!programWireframes || !programWireframes.logic || !programWireframes.logic[LogicContainers.Analytics]) {
            return undefined;
        }
        return programWireframes.logic[LogicContainers.Analytics][fieldName];
    }
    static getCustomAnalyticsParticipatingDataElements(programWireframes, dataElements) {
        const analyticsFieldsArray = StateReaderUtils.getCustomAnalyticsFieldsArray(programWireframes);
        if (!analyticsFieldsArray || !programWireframes || !programWireframes.logic || !programWireframes.logic[LogicContainers.Analytics]) {
            return undefined;
        }
        let analyticsDataElements = [];
        analyticsFieldsArray.forEach((analyticFieldKey) => {
            let analyticFieldData = StateReaderUtils.getCustomAnalyticsFieldData(programWireframes, analyticFieldKey);
            const dataElement = analyticFieldData && findDataElementByLogicValue(analyticFieldData.defaultValue, dataElements);
            const dataElementId = dataElement && getDataElementId(dataElement);
            if (dataElementId && !analyticsDataElements.includes(dataElementId)) {
                analyticsDataElements.push(dataElementId);
            }
        });
        return analyticsDataElements;
    }
    static getCustomAnalyticsFieldsArray(programWireframes) {
        if (!programWireframes) {
            return undefined;
        }
        if (programWireframes.customAnalyticsOrder) {
            return programWireframes.customAnalyticsOrder;
        }
        // Backward compatibility
        if (!programWireframes.stories) {
            return undefined;
        }
        const firstStoryId = Object.keys(programWireframes.stories)[0];
        if (!firstStoryId) {
            return undefined;
        }
        return programWireframes.stories[firstStoryId].customAnalyticsOrder;
    }
    static getElementTypeFromElement(element) {
        if (element.endsWith("_animation")) {
            return "animation";
        }
        else if (element.endsWith("_nextScene")) {
            return "next scene";
        }
        else if (element.endsWith("_validation")) {
            return "validation";
        }
        else {
            return "placeholder";
        }
    }
    static getDerivedLogicCycles(wireframes) {
        return (wireframes && wireframes.derivedLogicCycles) || [];
    }
    static getDerivedLogicCyclesFromWireframes(state, programName, stage, version) {
        const wireframes = StateReaderUtils.getWireFrame(state, programName, stage, version);
        return (wireframes && wireframes.derivedLogicCycles) || [];
    }
    static isLogicContainerAScene(logicContainer) {
        // logic containers of type Scene have only ID of the scene. (in contrast to 'derived' 'analytics' and stories)
        return /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/.test(logicContainer);
    }
    ///////////////////////////////////////         ENDPOINTS       ///////////////////////////////////////////////////
    static getImageThumbnail(state) {
        return state.imageThumbnail;
    }
    static getSecondImageUrl(state) {
        return state.secondImageUrl;
    }
    static getSecondImageThumbnail(state) {
        return state.secondImageThumbnail;
    }
    static getAnalyticsEmbedContainerChartUrl(state) {
        return state.analyticsEmbedContainerChartUrl;
    }
    static getdtaasEndPoint(state) {
        return state.dtaasEndPoint;
    }
    static getResolverEndPoint(state) {
        return state.resolverEndPoint;
    }
    static getNarrationServerEndpoint(state) {
        return state.narrationServerEndpoint;
    }
    static getMockNarrationServerEndpoint(state) {
        return state.mockNarrationServerEndpoint;
    }
    ///////////////////////////////////////         PRESETS       /////////////////////////////////////////////////////
    static getActivePresetId(state, projectName) {
        if (state.activePresetId && state.activePresetId !== CREATIVES_PRESET_ID) {
            return state.activePresetId;
        }
        // Gets the first preset ordered by ABC
        const presets = (state.presets && state.presets.byName[projectName]) || {};
        const defaultPresetId = Object.keys(presets).reduce((acc, presetId) => {
            if (presetId !== CREATIVES_PRESET_ID && (!acc || presets[presetId].name < presets[acc].name)) {
                return presetId;
            }
            return acc;
        }, undefined);
        return defaultPresetId;
    }
    static getPresets(state, projectName) {
        return StateReaderUtils.memoizeFilterPresets(state.presets.byName[projectName]);
    }
    static memoizeFilterPresets(statePresets) {
        return _.pickBy(statePresets, (presetValue) => presetValue.id !== CREATIVES_PRESET_ID); //filter out the creative preset
    }
    static getPresetById(state, project, id) {
        var _a;
        return (_a = state.presets.byName[project]) === null || _a === void 0 ? void 0 : _a[id];
    }
    static getCreativePreset(state, project) {
        var _a;
        return (_a = state.presets.byName[project]) === null || _a === void 0 ? void 0 : _a[CREATIVES_PRESET_ID];
    }
    ///////////////////////////////////////         ACCOUNTS & USERS        ///////////////////////////////////////////
    static getActiveAccount(state) {
        return state.accounts && state.accounts.byId[state.activeAccountId];
    }
    static isEditorAccount(state) {
        return state.activeAccountId === EDITOR_ACCOUNT_ID;
    }
    static getActiveAccountId(state) {
        return state.activeAccountId;
    }
    static getUserName(state) {
        return state.userName;
    }
    static getUserRoles(state) {
        return state.userRoles;
    }
    static getAccountsFromState(state) {
        return StateReaderUtils.memoizeGetAccountsFromState(state.accounts);
    }
    static getAccountsNames(state) {
        return StateReaderUtils.memoizeGetAccountsNames(state.accounts);
    }
    static getAccounts(stateAccounts) {
        return StateReaderUtils.memoizeGetAccounts(stateAccounts);
    }
    static getAccountVideoViews(state) {
        return state.accountVideoViews;
    }
    static getUsers(state) {
        return state.users.allNames;
    }
    static getAvailableRoles(state) {
        return state.users.roles;
    }
    static getSelectedUser(state) {
        return state.users.selectedUser;
    }
    static getAccountDisplayName(stateAccount) {
        return stateAccount.displayName;
    }
    static getAccountId(stateAccount) {
        return stateAccount.accountId;
    }
    static getTableauBaseURL(state) {
        return state.tableauBaseURL;
    }
    static getTableauWorkbookName(state) {
        return state.tableauWorkbookName;
    }
    static isFetchingUser(state) {
        return state.admin.isFetchingUser;
    }
    static isSettingFeatureFlag(state) {
        return state.admin.isSettingFeatureFlag;
    }
    static isLoadingUsers(state) {
        return state.admin.isLoadingUsers;
    }
    static isLoadingAllProjects(state) {
        return state.admin.isLoadingAllProjects;
    }
    static getSuccessMessage(state) {
        return state.users.successMessage;
    }
    static getWarningMessage(state) {
        return state.users.warningMessage;
    }
    static getIsEditorsViewer(state) {
        let userRoles = StateReaderUtils.getUserRoles(state);
        return isPermitted(userRoles, permissions.getProjects) && !isPermitted(userRoles, permissions.editProjects);
    }
    static isCampaignViewOnly(state) {
        let userRoles = StateReaderUtils.getUserRoles(state);
        return isPermitted(userRoles, permissions.adsGetCampaignSettings)
            && !isPermitted(userRoles, permissions.adsEditCampaignSettings);
    }
    static isExperimentManagerViewOnly(state) {
        let userRoles = StateReaderUtils.getUserRoles(state);
        return isPermitted(userRoles, permissions.viewBuilderReports)
            && isPermitted(userRoles, permissions.editReports)
            && !isPermitted(userRoles, permissions.editProjects);
    }
    static isAllowedCampaignSetting(state) {
        let userRoles = StateReaderUtils.getUserRoles(state) || [];
        return isPermitted(userRoles, permissions.adsGetCampaignSettings)
            || isPermitted(userRoles, permissions.adsEditCampaignSettings);
    }
    static isAllowedDetailedReport(state) {
        let userRoles = StateReaderUtils.getUserRoles(state) || [];
        return isPermitted(userRoles, permissions.viewBuilderReports)
            || isPermitted(userRoles, permissions.viewEditorReports);
    }
    static isAllowedExperiments(state) {
        let userRoles = StateReaderUtils.getUserRoles(state) || [];
        return isPermitted(userRoles, permissions.getExperiments);
    }
    ///////////////////////////////////////         NARRATIONS PAGE          //////////////////////////////////////////
    static getNarrationPostgresId(wireframes, narrationId) {
        return wireframes.narrations.byId[narrationId] && (wireframes.narrations.byId[narrationId].postgresNarrationId || narrationId);
    }
    static getNarrationTableUpdating(wireframes, narrationId) {
        return wireframes.narrations.byId[narrationId] && wireframes.narrations.byId[narrationId].updating;
    }
    static getNarrationTableDimensions(wireframes, narrationId) {
        return wireframes.narrations.byId[narrationId] && wireframes.narrations.byId[narrationId].dimensions;
    }
    static getNarrationTableVariations(wireframes, narrationId) {
        return wireframes.narrations.byId[narrationId] && wireframes.narrations.byId[narrationId].variations;
    }
    static getNarrationTableRepresentativeKey(wireframes, narrationId) {
        return wireframes.narrations.byId[narrationId] && wireframes.narrations.byId[narrationId].structure.representativeKey;
    }
    static getNarrationTableCreativeOverride(wireframes, narrationId) {
        return wireframes.narrations.byId[narrationId] && wireframes.narrations.byId[narrationId].structure.creativeOverride;
    }
    /*
    static getNarrationTableDataElements(wireframes, narrationId) {
        return wireframes.narrations.byId[narrationId] && wireframes.narrations.byId[narrationId].structure.stack.map(layer => layer.dataElementId);
    }
*/
    //TODO: PLIN - Should this include all ValueSetProviders?
    static getNarrationTableDataElements(wireframes, narrationId) {
        return (wireframes.narrations.byId[narrationId] &&
            wireframes.narrations.byId[narrationId].structure.stack.filter((layer) => layer.valueSetSource === "DataElements").map((layer) => layer.valueSetId));
    }
    static getNarrationTablePrioritizedListProviders(wireframes, narrationId) {
        return (wireframes.narrations.byId[narrationId] &&
            wireframes.narrations.byId[narrationId].structure.stack
                .filter((layer) => layer.valueSetSource === "candidateNames" || layer.valueSetSource === "numberOfValidSlots")
                .map((layer) => layer.valueSetId));
    }
    static getNarrationTableOverrideIds(wireframes, narrationId) {
        let overrideIds = new Set();
        const variations = StateReaderUtils.getNarrationTableVariations(wireframes, narrationId);
        variations.forEach((variation) => {
            if (variation && variation.variationData && variation.variationData.overrideId) {
                overrideIds.add(variation.variationData.overrideId);
            }
        });
        return Array.from(overrideIds);
    }
    static getNarrationDefaultText(wireframes, narrationId) {
        let entry = StateReaderUtils.getNarrationDefaultTextEntry(wireframes, narrationId);
        if (entry) {
            let variation = entry[1];
            return variation.variationData.text;
        }
        return "";
    }
    static getNarrationDefaultTextKey(wireframes, narrationId) {
        let entry = StateReaderUtils.getNarrationDefaultTextEntry(wireframes, narrationId);
        if (entry) {
            return entry[0];
        }
    }
    static getNarrationDefaultTextEntry(wireframes, narrationId) {
        let variations = StateReaderUtils.getNarrationTableVariations(wireframes, narrationId);
        if (variations) {
            let entry = variations.findEntry((variation) => variation.variationData.text && !variation.variationData.muted);
            return entry || variations.entrySeq().first();
        }
    }
    static getNarrationName(wireframes, sceneId, narrationId) {
        let scene = StateReaderUtils.getProjectWireframesScene(wireframes, sceneId);
        return StateReaderUtils.getNarrationNameInScene(scene, narrationId);
    }
    static getNarrationIndexInScene(scene, narrationId) {
        let scenePartIndex = 0;
        let narrationIndex = -1;
        let scenePart = scene.sceneParts && scene.sceneParts[scenePartIndex];
        while (scenePart) {
            let matchNarration = scenePart[placeholderType.narration].some((narrationPart) => {
                ++narrationIndex;
                return narrationPart.id === narrationId;
            });
            scenePart = matchNarration ? undefined : scene.sceneParts && scene.sceneParts[++scenePartIndex];
        }
        return narrationIndex;
    }
    static getNarrationNameInScene(scene, narrationId) {
        let narrationIndex = this.getNarrationIndexInScene(scene, narrationId);
        if (narrationIndex > -1) {
            return "Narrations." + ntol(narrationIndex);
        }
        else {
            return undefined;
        }
    }
    static getNarrationLetterInScene(scene, narrationId) {
        let narrationIndex = this.getNarrationIndexInScene(scene, narrationId);
        if (narrationIndex > -1) {
            return ntol(narrationIndex);
        }
        else {
            return undefined;
        }
    }
    static getLastRelevantNarrationUpdateTimeStamp(state) {
        return state.lastRelevantNarrationUpdateTimeStamp;
    }
    static getShowMutedNarrationRows(state) {
        return state.showMutedNarrationRows;
    }
    static getShowNarrationNoteColumn(state) {
        return state.showNarrationNoteColumn;
    }
    static getShowNarrationStatusColumn(state) {
        return state.showNarrationStatusColumn;
    }
    static getNarrator(wireframes, storyId) {
        if (storyId) {
            return wireframes.stories[storyId] && wireframes.stories[storyId].narrator;
        }
        else {
            let storiesVersion = StateReaderUtils.getStoriesVersionFromWireframes(wireframes);
            if (!storiesVersion) {
                let defaultStoryId = Object.keys(wireframes.stories)[0];
                return wireframes.stories[defaultStoryId].narrator;
            }
            else {
                return null;
            }
        }
    }
    static getAllNarrationIds(wireframes) {
        return wireframes.narration.allIds;
    }
    static getSceneIdOfNarration(wireframes, narrationId) {
        return Object.keys(wireframes.scenes).find(sceneId => {
            return wireframes.scenes[sceneId].sceneParts.some(scenePart => {
                var _a;
                return (_a = scenePart.NarrationParts) === null || _a === void 0 ? void 0 : _a.some(narration => narration.id === narrationId);
            });
        });
    }
    static getStories(wireframes) {
        return wireframes && wireframes.stories;
    }
    static getStoriesAsArray(wireframes) {
        let stories = StateReaderUtils.getStories(wireframes);
        let storiesArray = [];
        if (stories) {
            for (let storyField in stories) {
                storiesArray.push(stories[storyField]);
            }
        }
        return storiesArray;
    }
    static getStoryById(wireframes, storyId) {
        return wireframes && wireframes["stories"] && wireframes["stories"][storyId];
    }
    static getProjectRecordingDocs(state) {
        return state.recordingDocs || [];
    }
    ///////////////////////////////////////         DISPLAY DATA         //////////////////////////////////////////////
    static enableHubStickyBarLogoMenu(state) {
        return state.enableHubStickyBarLogoMenu;
    }
    static getBreadCrumbsArray(state) {
        return state.breadCrumbsArray;
    }
    static getHubStickyBarLogoMenuItemsArray(state) {
        return state.hubStickyBarLogoMenuItemsArray;
    }
    static getStudioProgramsDrawerFilter(state) {
        return state.studioProgramsDrawerFilter;
    }
    static getMiddlePanelCollapsed(state) {
        return state.middlePanelCollapsed;
    }
    static isStateError(state) {
        return state.error;
    }
    static getStateError(state) {
        return state.errorMessage;
    }
    static getStateErrorStack(state) {
        return state.errorStackMessage;
    }
    static getStateErrorRest(state) {
        return state.errorRestUrl;
    }
    static getPageTitle(state) {
        return state.pageTitle;
    }
    static getLoadingCount(state) {
        return state.loadingCount;
    }
    static isSoftLoading(state) {
        return state.softLoading;
    }
    static getLoadingProgress(state) {
        return state.loadingProgress;
    }
    static getLoadingUpdateNarrationLibrary(state) {
        return state.loadingUpdateNarrationLibrary;
    }
    static getDataLibrarySelectedTabId(state) {
        return state.dataLibrarySelectedTabId || 0;
    }
    static getRawRecordingUploadStage(state) {
        return state.rawRecordingUploadStatus && state.rawRecordingUploadStatus.getStage();
    }
    static getRawRecordingUploadStageNumber(state) {
        return state.rawRecordingUploadStatus && state.rawRecordingUploadStatus.getStageNumber();
    }
    static getRawRecordingUploadProgress(state) {
        return state.rawRecordingUploadStatus && state.rawRecordingUploadStatus.getProgress();
    }
    static getRawRecordingUploadFilename(state) {
        return state.rawRecordingUploadStatus && state.rawRecordingUploadStatus.getFilename();
    }
    static getRawRecordingUploadTranscript(state) {
        return state.rawRecordingUploadStatus && state.rawRecordingUploadStatus.getTranscript();
    }
    static getRawRecordingUploadConfidence(state) {
        return state.rawRecordingUploadStatus && state.rawRecordingUploadStatus.getConfidence();
    }
    static getRawRecordingUploadSentence(state) {
        return state.rawRecordingUploadStatus && state.rawRecordingUploadStatus.getSentences();
    }
    static getRawRecordingUploadFinished(state) {
        return state.rawRecordingUploadFinish;
    }
    static getLogicErrorsAndWarnings(wireframes, projectAssets, currentRFR) {
        return StateReaderUtils.memoizeGetLogicErrorsAndWarnings(wireframes, projectAssets, currentRFR);
    }
    static getProjectsForAllAccounts(state) {
        return state.admin.allProjects;
    }
    ///////////////////////////////////////         Framework         ////////////////////////////////////////////////////
    static getFrameworkProgram(state, programId) {
        return state.frameworkEditor.byId[programId] || null;
    }
    static getProgramSummaries(state) {
        return state.projectSummaries;
    }
    static getUsedProgramNames(state) {
        return StateReaderUtils.getProgramSummaries(state).reduce((usedProgramNames, programSummary) => {
            usedProgramNames.add(programSummary.displayName.toLocaleLowerCase());
            programSummary.projectIds.builders && usedProgramNames.add(programSummary.projectIds.builders.toLocaleLowerCase());
            // TODO remove when summary is using postgres name and not dynamo name. rename program from builder should also write to postgres
            programSummary.postgresName && usedProgramNames.add(programSummary.postgresName.toLocaleLowerCase());
            return usedProgramNames;
        }, new Set());
    }
    static getIsFrameworkViewer(state) {
        let userRoles = StateReaderUtils.getUserRoles(state);
        return isPermitted(userRoles, permissions.getFramework)
            && !isPermitted(userRoles, permissions.deleteEntity)
            && !isPermitted(userRoles, permissions.updateEntity);
    }
    static getIsEditorViewer(state) {
        let userRoles = StateReaderUtils.getUserRoles(state);
        return !isPermitted(userRoles, permissions.editEditorPrograms);
    }
    static getProgramId(state, programName) {
        if (state.projectSummaries) {
            const selectedProgramSummary = state.projectSummaries.find((ps) => ps.projectIds && ps.projectIds.builders === programName);
            return selectedProgramSummary && selectedProgramSummary.id;
        }
    }
    ///////////////////////////////////////         CREATIVES         ////////////////////////////////////////////////////
    static getCreativeRecordingAssets(state, programId) {
        return state.creativeRecordingAssets.byId[programId];
    }
    ///////////////////////////////////////         REPORT       /////////////////////////////////////////////////////
    static getSurveys(state, projectName, stage, version) {
        let wireframes = StateReaderUtils.getWireFrame(state, projectName, stage, version);
        return wireframes.surveys;
    }
}
StateReaderUtils.getBuildersProgramsSummariesWithSfIds = (state) => {
    const programSummaries = StateReaderUtils.getProgramSummaries(state);
    return (programSummaries &&
        programSummaries.filter((program) => {
            return program.projectIds.builders && program.projectIds.sfId;
        }));
};
StateReaderUtils.memoizeGetProjectAssets = memoizeOne((stateProject, stateAssets) => {
    let assets = [];
    if (stateProject && stateProject.assets && stateAssets) {
        stateProject.assets.forEach((assetId) => assets.push(stateAssets.byId[assetId]));
    }
    return assets;
});
StateReaderUtils.memoizeGetProjectAssetsValidAsBackgroundAsset = memoizeOne((stateProject, stateAssets) => {
    return StateReaderUtils.memoizeGetLiveProjectAssetsByType(stateProject, stateAssets, ASSET_TYPES.curated).filter((asset) => asset.width === 640 && (asset.height === 360 || asset.height === 640));
});
StateReaderUtils.memoizeGetLiveProjectAssetsByType = memoizeOne((stateProject, stateAssets, assetType) => {
    return StateReaderUtils.memoizeGetProjectAssetsByType(stateProject, stateAssets, assetType).filter((asset) => !asset.archived && !asset.deleted);
});
StateReaderUtils.memoizeGetProjectAssetsByType = memoizeOne((stateProject, stateAssets, assetType) => {
    let assets = [];
    if (stateProject && stateProject.assets && stateAssets) {
        stateProject.assets.forEach(function (assetId) {
            if (stateAssets.byId[assetId].type === assetType) {
                assets.push(stateAssets.byId[assetId]);
            }
        });
    }
    return assets;
});
StateReaderUtils.memoizeGetAssetsAdjustedToSnapshot = memoizeOne((accountId, projectName, stateAssets, stateSnapshots, snapshotId, assetType) => {
    const snapshot = stateSnapshots.byId[snapshotId];
    if (stateAssets && snapshot && snapshot.assets) {
        let assets = snapshot.assets.map((snapshotAsset) => {
            let assetId = StateReaderUtils.getAssetUniqueId(accountId, projectName, snapshotAsset.type, snapshotAsset.name);
            let asset = stateAssets.byId[assetId];
            if (asset) {
                return Object.assign(Object.assign({}, asset), { stageVersion: snapshotAsset.version, stageAssetItem: Object.assign({}, snapshotAsset) });
            }
            return Object.assign(Object.assign({}, snapshotAsset), { stageAssetItem: snapshotAsset });
        });
        if (assetType) {
            assets = assets.filter((asset) => asset.type === assetType);
        }
        return assets;
    }
    return [];
});
StateReaderUtils.getProjectLogicItemsThatMayContainHotspotInternal = memoizeOne((scenes, logicItems) => {
    return Object.keys(scenes)
        .map((sceneId) => StateReaderUtils.getSceneLogicItemsThatMayContainHotspot(scenes[sceneId], logicItems[sceneId]))
        .filter((logicItemsArray) => !!logicItemsArray)
        .flat();
});
StateReaderUtils.getSceneLogicItemsThatMayContainHotspot = memoizeOne((scene, sceneLogicItems) => {
    let logicItemsIds = new Set();
    scene.sceneParts.forEach((scenePart) => {
        scenePart[entityType.groups] && scenePart[entityType.groups].forEach((group) => logicItemsIds.add(group.id));
        scenePart[entityType.prioritizedLists] && scenePart[entityType.prioritizedLists].forEach((list) => logicItemsIds.add(list.id));
        scenePart[entityType.placeholders] && scenePart[entityType.placeholders].forEach((ph) => logicItemsIds.add(ph.name));
    });
    return Array.from(logicItemsIds)
        .map((logicId) => sceneLogicItems[logicId])
        .filter((logicItem) => !!logicItem);
});
StateReaderUtils.getScenePlaceholders = memoizeOne((wireframes, sceneId) => {
    // collect scene's placeholders and grouped placeholders
    const sceneParts = wireframes.scenes[sceneId] && wireframes.scenes[sceneId].sceneParts;
    if (!sceneParts || !Array.isArray(sceneParts))
        return []; // protect against scene 'derived' :(
    let placeholders = {};
    sceneParts.forEach((scenePart) => {
        if (scenePart[placeholderType.onscreen] && scenePart[placeholderType.onscreen].length) {
            scenePart[placeholderType.onscreen].forEach((ph) => {
                placeholders[ph.name] = Object.assign(Object.assign({}, ph), { source: placeholderType.onscreen });
            });
        }
        if (scenePart[placeholderType.parameter] && scenePart[placeholderType.parameter].length) {
            scenePart[placeholderType.parameter].forEach((pr) => {
                placeholders[pr.name] = Object.assign(Object.assign({}, pr), { source: placeholderType.parameter });
            });
        }
        if (scenePart[entityType.groups] && scenePart[entityType.groups].length) {
            scenePart[entityType.groups].forEach((grp) => {
                grp[entityType.placeholders].forEach((ph) => {
                    placeholders[ph.name] = Object.assign(Object.assign({}, ph), { source: placeholderType.onscreen, groupId: grp.id });
                });
            });
        }
    });
    return Object.values(placeholders);
});
StateReaderUtils.getProjectNarrationParts = (wireframes) => {
    let narrationParts = [];
    if (wireframes && wireframes.scenes) {
        Object.values(wireframes.scenes).forEach((scene) => {
            if (scene && scene.sceneParts) {
                scene.sceneParts.forEach((scenePart) => {
                    if (scenePart && scenePart.NarrationParts) {
                        scenePart.NarrationParts.forEach((narrationPart) => {
                            if (narrationPart) {
                                narrationParts.push(Object.assign(Object.assign({}, narrationPart), { sceneId: scene.id, scenePartId: scenePart.scenePart }));
                            }
                        });
                    }
                });
            }
        });
    }
    return narrationParts;
};
StateReaderUtils.getNarrationsVersion = (wireframes) => {
    return wireframes && typeof wireframes.narrationsVersion === "number" ? wireframes.narrationsVersion : 0;
};
StateReaderUtils.getNarrationsVersionFromState = (state, projectName, stage, version) => {
    const wireframes = StateReaderUtils.getWireFrame(state, projectName, stage, version);
    return StateReaderUtils.getNarrationsVersion(wireframes);
};
////////////////////////////////////         LOGIC | DATA ELEMENTS            /////////////////////////////////////
StateReaderUtils.memoizeAddGetterForValueSet = memoizeOne((dataElements, derivedLogic, dynamicValueSetData) => {
    let effectiveDerivedLogic = derivedLogic || {};
    let effectiveDataElements = dataElements || [];
    let getDataElementsWithGetter;
    let dataElementsWithGetter = effectiveDataElements.map((dataElement) => {
        return changeGetterForValueSet(dataElement, () => {
            if (dataElement.origin === DataElementOrigins.Derived) {
                let logicID = getDerivedDataElementLogicId(dataElement);
                let logic = createLogic(effectiveDerivedLogic[logicID] || {});
                return logic.getValueSet({ dataElements: getDataElementsWithGetter(), derivedLogic });
            }
            else if (dataElement.origin === DataElementOrigins.System) {
                if (dataElement.valueSet) {
                    let staticValueSet = dataElement.valueSet;
                    let dynamicValueSet = dynamicValueSetData[dataElement.id];
                    if (dynamicValueSet) {
                        staticValueSet = staticValueSet.concat(dynamicValueSet);
                    }
                    return staticValueSet.map((value) => (typeof value === "string" ? { id: "_bc_system_" + value.toLowerCase(), dn: value } : value));
                }
                else {
                    return null;
                }
            }
            else if (dataElement.type === DataElementContentTypes.Boolean) {
                return [
                    { id: "_boolean_false", dn: "false" },
                    { id: "_boolean_true", dn: "true" }
                ];
            }
            else {
                //return (isValueSetSupportedForDataElementType(dataElement) && dataElement.useValueSet && dataElement.valueSet) || null;
                //Support older projects where values didn't have ids by generating them on the fly
                if (isValueSetSupportedForDataElementType(dataElement) && dataElement.useValueSet && dataElement.valueSet) {
                    return dataElement.valueSet.map((value) => (typeof value === "string" ? { id: "_bc_" + value.toLowerCase(), dn: value } : value));
                }
                return null;
            }
        });
    });
    getDataElementsWithGetter = () => dataElementsWithGetter;
    return dataElementsWithGetter;
});
StateReaderUtils.memoizeGetAccountsFromState = memoizeOne((stateAccounts) => {
    return stateAccounts.allIds.map((accountId) => stateAccounts.byId[accountId]);
});
StateReaderUtils.memoizeGetAccountsNames = memoizeOne((stateAccounts) => {
    return stateAccounts.allIds.map((accountId) => stateAccounts.byId[accountId].displayName);
});
StateReaderUtils.memoizeGetAccounts = memoizeOne((stateAccounts) => {
    return stateAccounts.allIds.map((accountId) => stateAccounts.byId[accountId]);
});
StateReaderUtils.getStoryGqlId = (state, projectName, storyId) => {
    let story = StateReaderUtils.getStoryById(StateReaderUtils.getWireFrame(state, projectName), storyId);
    return story.graphQLId;
};
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
StateReaderUtils.memoizeGetLogicErrorsAndWarnings = memoizeOne((wireframes, projectAssets, currentRFR) => {
    let errors = [];
    let warnings = [];
    if (wireframes) {
        let { logic, stories, scenes } = wireframes;
        let storiesScenes = stories
            ? Object.keys(Object.values(stories).reduce((acc, story) => {
                if (story.lineup) {
                    story.lineup.forEach((sceneId) => (acc[sceneId] = sceneId));
                }
                return acc;
            }, {}))
            : [];
        if (logic) {
            if (scenes) {
                const updateErrors = ({ sceneName, scenePartIndex, wireframesEntity, sceneLogic, entitySelector = "id", entityType }) => {
                    if (wireframesEntity) {
                        let entityLogic = sceneLogic[wireframesEntity[entitySelector]];
                        let entityLogicValidation = entityLogic && entityLogic.validation;
                        if (entityLogicValidation) {
                            if (entityLogicValidation.status === VALIDATION_STATUS.Invalid) {
                                errors.push(`Error in scene ${sceneName} Part #${scenePartIndex} with ${entityType} ${wireframesEntity.name} logic`);
                            }
                            else if (entityLogicValidation.status === VALIDATION_STATUS.Partial) {
                                warnings.push(`Warning in scene ${sceneName} Part #${scenePartIndex} with ${entityType} ${wireframesEntity.name} logic`);
                            }
                        }
                    }
                };
                storiesScenes.forEach((sceneId) => {
                    let scene = scenes[sceneId];
                    let sceneLogic = logic[sceneId];
                    if (storiesScenes.includes(sceneId) && sceneLogic) {
                        // Handle Placeholders, groups and prioritized lists in scene
                        if (scene.sceneParts) {
                            scene.sceneParts.forEach((scenePart, index) => {
                                if (scenePart.placeholders) {
                                    scenePart.placeholders.forEach((placeholder) => {
                                        updateErrors({
                                            sceneName: scene.name,
                                            scenePartIndex: index + 1,
                                            wireframesEntity: placeholder,
                                            entityType: "placeholder",
                                            entitySelector: "name",
                                            sceneLogic
                                        });
                                    });
                                }
                                if (scenePart.groups) {
                                    scenePart.groups.forEach((group) => {
                                        updateErrors({
                                            sceneName: scene.name,
                                            scenePartIndex: index + 1,
                                            wireframesEntity: group,
                                            entityType: "group",
                                            sceneLogic
                                        });
                                    });
                                }
                                if (scenePart.prioritizedLists) {
                                    scenePart.prioritizedLists.forEach((prioritizedList) => {
                                        updateErrors({
                                            sceneName: scene.name,
                                            scenePartIndex: index + 1,
                                            wireframesEntity: prioritizedList,
                                            entityType: "prioritized list",
                                            sceneLogic
                                        });
                                    });
                                }
                            });
                        }
                        // Handle animation, next scene & validation logic
                        Object.keys(sceneLogic).forEach((element) => {
                            let scenePartLogic = sceneLogic[element];
                            let scenePartValidation = scenePartLogic.validation;
                            if (scenePartValidation && scenePartValidation.status !== VALIDATION_STATUS.valid) {
                                let elementType = StateReaderUtils.getElementTypeFromElement(element);
                                // excluded placeholders
                                if (["animation", "validation"].includes(elementType)) {
                                    // excluded animations from errors
                                    if (scenePartValidation.status === VALIDATION_STATUS.Invalid && elementType !== "animation") {
                                        errors.push(`Error in scene ${scene.name} with ${elementType} logic`);
                                    }
                                    else if (scenePartValidation.status === VALIDATION_STATUS.Partial ||
                                        (scenePartValidation.status === VALIDATION_STATUS.Invalid && elementType === "animation")) {
                                        warnings.push(`Warning in scene ${scene.name} with ${elementType} logic`);
                                    }
                                }
                            }
                        });
                    }
                });
            }
            const analyticsFieldsArray = StateReaderUtils.getCustomAnalyticsFieldsArray(wireframes);
            if (analyticsFieldsArray) {
                analyticsFieldsArray.forEach((analyticFieldKey, index) => {
                    let analyticFieldData = StateReaderUtils.getCustomAnalyticsFieldData(wireframes, analyticFieldKey);
                    if (analyticFieldData && analyticFieldData.isNew) {
                        errors.push(`Error - Missing field name in custom field #${index + 1} in Analytics Settings`);
                    }
                    let analyticsFieldValidation = analyticFieldData && analyticFieldData.validation;
                    if (analyticsFieldValidation && analyticsFieldValidation.status !== VALIDATION_STATUS.valid) {
                        if (analyticsFieldValidation.status === VALIDATION_STATUS.Invalid) {
                            errors.push(`Error in custom field #${index + 1} in Analytics Settings`);
                        }
                        else if (analyticsFieldValidation.status === VALIDATION_STATUS.Partial) {
                            warnings.push(`Warning in custom field #${index + 1} in Analytics Settings`);
                        }
                    }
                });
            }
            let derivedLogic = logic[LogicContainers.Derived];
            let dataElements = (wireframes && wireframes.dataElements) || [];
            if (derivedLogic) {
                Object.keys(derivedLogic).forEach((derivedDataElementKey) => {
                    let derivedDataElement = getDataElementFromDerivedKey(derivedDataElementKey, dataElements);
                    if (derivedDataElement) {
                        let derivedDataElementLogic = derivedLogic[derivedDataElementKey];
                        let derivedValidation = derivedDataElementLogic.validation;
                        if (derivedValidation && derivedValidation.status !== VALIDATION_STATUS.valid) {
                            let derivedDataElementDisplayName = getDataElementDisplayName(derivedDataElement);
                            if (derivedValidation.status === VALIDATION_STATUS.Invalid) {
                                errors.push(`Error in Studio Data Element ${derivedDataElementDisplayName} logic`);
                            }
                            else if (derivedValidation.status === VALIDATION_STATUS.Partial) {
                                warnings.push(`Warning in Studio Data Element ${derivedDataElementDisplayName} logic`);
                            }
                        }
                    }
                    else {
                        // todo - log this
                    }
                });
            }
        }
    }
    if (projectAssets) {
        let excludedTypes = [sceneAssetType, storyAssetType, wireFramesAssetType];
        let pendingAssets = projectAssets
            .filter((asset) => !excludedTypes.includes(asset.type.toLowerCase()) && !asset.archived && asset.version === 0 && (asset.type !== ASSET_TYPES.recording || asset.RFR >= currentRFR))
            .map((asset) => asset.title || asset.filename);
        let pendingCount = pendingAssets.length;
        if (pendingCount > 0) {
            if (pendingCount === 1) {
                warnings.push(`There is 1 asset request: ${pendingAssets[0]}`);
            }
            else {
                warnings.push(`There are ${pendingCount} asset requests${pendingCount > 3 ? ", including" : ""}: ${pendingAssets.slice(0, 3).join(", ")}${pendingCount > 3 ? ", ..." : ""}`);
            }
        }
    }
    return { errors, warnings };
});
StateReaderUtils.getBuilderProgramAndDraftVersionIds = (state, projectName) => {
    const programSummary = findProgramSummaryForProgramId(StateReaderUtils.getProgramSummaries(state), projectName);
    const programId = programSummary && programSummary.id;
    const programVersionId = programSummary && programSummary.projectIds && programSummary.projectIds.builderDraftVersionId;
    return { programId, programVersionId };
};
StateReaderUtils.isEditorProgram = (state, projectName) => {
    const programSummary = findProgramSummaryForProgramId(StateReaderUtils.getProgramSummaries(state), projectName);
    return programSummary && programSummary.isEditor;
};
export default StateReaderUtils;
