import { LOADING_FEATURE_FLAGS_SUCCESS, ENABLING_ALL_FEATURE_FLAGS_SUCCESS, SET_FEATURE_FLAG_SUCCESS, SET_FEATURE_FLAG_STATUS_IN_STATE, LOADING_PROGRAMS_DYNAMO_MIGRATION_STATUS } from "./featureFlagsActions";
import { convertArrayOfObjectsToObject } from "../../../common/arrayUtils";
function featureFlagsReducer(state, action) {
    switch (action.type) {
        case LOADING_FEATURE_FLAGS_SUCCESS: {
            return Object.assign(Object.assign({}, state), { featureFlags: action.payload.featureFlags });
        }
        case LOADING_PROGRAMS_DYNAMO_MIGRATION_STATUS: {
            return Object.assign(Object.assign({}, state), { programsDynamoMigrationStatus: convertArrayOfObjectsToObject(action.payload.programsDynamoMigrationStatus, "programId") });
        }
        case ENABLING_ALL_FEATURE_FLAGS_SUCCESS:
            return Object.assign(Object.assign({}, state), { featureFlags: {}, enableAllFeatureFlags: action.payload.enable });
        case SET_FEATURE_FLAG_STATUS_IN_STATE: {
            const featureFlags = Object.assign({}, state.featureFlags);
            if (featureFlags[action.payload.featureFlagId]) {
                featureFlags[action.payload.featureFlagId].defaultValue = action.payload.status;
            }
            return Object.assign(Object.assign({}, state), { featureFlags });
        }
        case SET_FEATURE_FLAG_SUCCESS: {
            const featureFlags = Object.assign({}, state.featureFlags);
            const { defaultValue, excludedPrograms, featureFlagId, excludedAccounts } = action.payload.featureFlag;
            featureFlags[featureFlagId] = { defaultValue, excludedPrograms, excludedAccounts };
            return Object.assign(Object.assign({}, state), { featureFlags });
        }
        default:
            return state;
    }
}
export default featureFlagsReducer;
