import type { ProgramDataFields } from "./consts";
import type { VLXInputLogic, VLXLineupScene, VLXInputLogicType } from "../../../common/types/vlxTypes";
import { ADAVarName, ANIMATION_REPOSITORY, LOGIC_DATA_TYPES, LOGIC_TYPE, VLX_SCOPE } from "./consts";
import { buildInputRules, buildValue, evalScopeVariable, getInputLogicType, wrapPrioritizedListNumberOfSlots, wrapPrioritizedListSlotName } from "./logixBuilder";
import { entityType, LogicContainers, onscreenTypes, placeholderType } from "../../../common/commonConst";
import { createJsonHierarchyForVar } from "./utils";
import { buildActionableData } from "../Logics/ActionableDataUtils";
import { isLogicEmpty } from "../Logics/Logic";
import StateReaderUtils from "../common/StateReaderUtils";
import { findDataElementById, findDataElementByLogicValue, getDataElementDisplayName, getDataElementId } from "../DataElements/DataElementsManager";
import { PRIORITIZED_LIST_VALUE_SET_SOURCES } from "../projects/projectWireframes/projectNarrations/PrioritizedListValueSetAdapter";
import { mapLetterToNum } from "../../../common/generalUtils";
import type { Parameter, Placeholder, Scene } from "../../../common/types/scene";
import type { Asset } from "../../../common/types/asset";
import type { Program } from "../../../common/types/program";
import type { DataElement } from "../../../common/types/dataElement";

const VALID_SLOTS_PATTERN = /(.*) - # of valid slots/;
const SLOT_NAME_PATTERN = /(.*) - Slot (.*)/;

/**
 * Old code to be deleted, once all scene animations will be kept in logic
 * */
function createSceneAnimation(wireFrameScene, useAbsoluteAnimationPath) {
    let animationPath;
    if (wireFrameScene.animation) {
        animationPath = useAbsoluteAnimationPath ? wireFrameScene.animation.self : wireFrameScene.animation.name + "/";
    }
    return {
        href: {
            "repo-url": animationPath,
            repository: ANIMATION_REPOSITORY
        }
    };
}

function getNarrationDefaultText(wireframes, scenePartNarration) {
    if (StateReaderUtils.getNarrationsVersion(wireframes) > 0) {
        /*
        let representativeKey = StateReaderUtils.getNarrationTableRepresentativeKey(wireframes, scenePartNarration.id);
        let variations = StateReaderUtils.getNarrationTableVariations(wireframes, scenePartNarration.id);
        let representativeVariation = variations.get(representativeKey);
        return representativeVariation ? representativeVariation.variationData.text : "";
*/
        return StateReaderUtils.getNarrationDefaultText(wireframes, scenePartNarration.id);
    }
    else {
        return scenePartNarration.mockValue;
    }
}

function getNarrationName(wireframes, sceneId, scenePartNarration, program) {
    if (StateReaderUtils.getNarrationsVersion(wireframes) > 0) {
        return StateReaderUtils.getNarrationName(wireframes, sceneId, scenePartNarration.id);
    }
    else {
        return scenePartNarration.name;
    }
}

function getPrioritizedList(participantName) {
    let m;
    if ((m = VALID_SLOTS_PATTERN.exec(participantName)) !== null && m.length > 0) {
        return { prioritizedListName: m[1] };
    }
    if ((m = SLOT_NAME_PATTERN.exec(participantName)) !== null && m.length > 1) {
        return { prioritizedListName: m[1], slotName: m[2], slotIdx: mapLetterToNum(m[2]) };
    }
    return undefined;
}

function getCodeforFromCodeforPattern(dimension, wireframes, narrationPartId, programDataFields: ProgramDataFields) {
    let { id, name } = dimension;
    if (id.startsWith("DataElements")) {
        let dataElementId = id.split("__")[1];
        let dataElement: DataElement = findDataElementById(dataElementId, programDataFields.dataElements);
        if (dataElement) {
            let actionableData = buildActionableData({
                id: getDataElementId(dataElement),
                mediaType: dataElement.type,
                dataType: LOGIC_DATA_TYPES.DataElement
            });
            return buildValue(actionableData, undefined, undefined, programDataFields);
        }
    }
    else if (id.startsWith(PRIORITIZED_LIST_VALUE_SET_SOURCES.CandidateNames)) {
        let prioritizedList = getPrioritizedList(name);
        if (prioritizedList && prioritizedList.prioritizedListName && !isNaN(prioritizedList.slotIdx)) {
            return wrapPrioritizedListSlotName(prioritizedList.prioritizedListName, prioritizedList.slotIdx, VLX_SCOPE.Scene);
        }
    }
    else if (id.startsWith(PRIORITIZED_LIST_VALUE_SET_SOURCES.NumberOfValidSlots)) {
        let prioritizedList = getPrioritizedList(name);
        if (prioritizedList && prioritizedList.prioritizedListName) {
            return wrapPrioritizedListNumberOfSlots(prioritizedList.prioritizedListName, VLX_SCOPE.Scene);
        }
    }
    return undefined;
}

function getCodefors(wireframes, sceneId, narrationPart, programDataFields, program) {
    let codefor = {};
    if (StateReaderUtils.getNarrationsVersion(wireframes) > 0) {
        let narrationTableDimensions = StateReaderUtils.getNarrationTableDimensions(wireframes, narrationPart.id);
        narrationTableDimensions.forEach((dimension) => {
            let codeForValue = getCodeforFromCodeforPattern(dimension, wireframes, narrationPart.id, programDataFields);
            if (codeForValue) {
                codefor[dimension.id] = codeForValue;
            }
        });
    }
    else {
        if (narrationPart.participatingDataElements) {
            narrationPart.participatingDataElements.forEach((actionableDataDataElement) => {
                // Find the data element to get the latest display name to be stored as the codeFor
                const dataElement = findDataElementByLogicValue(actionableDataDataElement, programDataFields.dataElements);
                if (dataElement) {
                    codefor[getDataElementDisplayName(dataElement)] = buildValue(actionableDataDataElement, undefined, undefined, programDataFields);
                }
            });
        }
    }
    return codefor;
}

function createSceneInputsLogic({ wireframes, sceneId, skipNarration, mockNarrator, accountId, program, projectAssetsServices, programDataFields, enabledFeatureFlags = [], masterData }) {
    let promises = [];
    let wireFrameLogic = wireframes.logic;
    let wireframeScene = wireframes.scenes[sceneId];
    const prioritizedLists = StateReaderUtils.getScenePrioritizedList(wireframes, sceneId);
    const context = { ...programDataFields, prioritizedLists };
    wireframeScene.sceneParts.forEach((scenePart) => {
        if (scenePart[placeholderType.narration]) {
            if (mockNarrator) {
                scenePart[placeholderType.narration].forEach((narration) => {
                    let narrationName = getNarrationName(wireframes, sceneId, narration, program);
                    let narrationDefaultText = getNarrationDefaultText(wireframes, narration);
                    promises.push(buildTtsUrl(accountId, program.projectName, projectAssetsServices, mockNarrator, narrationDefaultText, narrationName));
                });
            }
        }
    });
    return Promise.all(promises).then((resultArray) => {
        let sceneVariables = createSceneVariables(wireframes.scenes[sceneId], wireframes.logic[sceneId], context);
        let placeHolders = {};

        if (masterData && masterData.sceneParts && masterData.sceneParts.length) {
            sceneVariables.push(...createSceneVariables(masterData, wireframes.logic[LogicContainers.Master], context));
            populatePlaceholders(wireframes, masterData, placeHolders, wireFrameLogic, program, context, mockNarrator, skipNarration, resultArray);
        }

        populatePlaceholders(wireframes, wireframeScene, placeHolders, wireFrameLogic, program, context, mockNarrator, skipNarration, resultArray);

        return { placeHolders, sceneVariables };
    });
}

function populatePlaceholders(wireframes, wireframeScene, placeHolders, wireFrameLogic, program, programDataFields, mockNarrator, skipNarration, mockNarrationsArr) {
    let inputs = {};
    wireframeScene.sceneParts.forEach((scenePart) => {
        if (scenePart[placeholderType.onscreen]) {
            scenePart[placeholderType.onscreen].forEach((placeholder) => {
                if (!inputs[placeholder.name] && wireFrameLogic[wireframeScene.id][placeholder.name]) {
                    if (placeholder.type === onscreenTypes.Hotspot) {
                        createJsonHierarchyForVar(placeHolders, placeholder.name, {
                            rules: buildInputRules(wireFrameLogic[wireframeScene.id][placeholder.name], programDataFields, false, false, true),
                            type: LOGIC_TYPE.Text
                        });
                    }
                    else {
                        createJsonHierarchyForVar(placeHolders, placeholder.name, {
                            rules: buildInputRules(wireFrameLogic[wireframeScene.id][placeholder.name], programDataFields),
                            type: getInputLogicType(wireFrameLogic[wireframeScene.id][placeholder.name])
                        });
                    }

                    inputs[placeholder.name] = true;
                }
            });
        }
        if (scenePart[entityType.groups]) {
            scenePart[entityType.groups].forEach((group) => {
                if (!inputs[group.id] && wireFrameLogic[wireframeScene.id][group.id]) {
                    createJsonHierarchyForVar(placeHolders, group.id, {
                        rules: buildInputRules(wireFrameLogic[wireframeScene.id][group.id], programDataFields, false, false, true),
                        type: getInputLogicType(wireFrameLogic[wireframeScene.id][group.id])
                    });
                    inputs[group.id] = true;
                }
            });
        }
        if (scenePart[entityType.prioritizedLists]) {
            scenePart[entityType.prioritizedLists].forEach((pList) => {
                if (!inputs[pList.id] && wireFrameLogic[wireframeScene.id][pList.id]) {
                    createJsonHierarchyForVar(placeHolders, pList.name, {
                        value: evalScopeVariable(pList.name, VLX_SCOPE.Scene),
                        type: "object"
                    });
                    inputs[pList.id] = true;
                }
            });
        }
        if (scenePart[placeholderType.parameter]) {
            scenePart[placeholderType.parameter].forEach((parameter) => {
                if (!inputs[parameter.name] && wireFrameLogic[wireframeScene.id][parameter.name]) {
                    createJsonHierarchyForVar(placeHolders, parameter.name, {
                        rules: buildInputRules(wireFrameLogic[wireframeScene.id][parameter.name], programDataFields),
                        type: getInputLogicType(wireFrameLogic[wireframeScene.id][parameter.name])
                    });

                    inputs[parameter.name] = true;
                }
            });
        }
        if (scenePart[placeholderType.narration]) {
            if (!mockNarrator) {
                if (!skipNarration) {
                    scenePart[placeholderType.narration].forEach((narration) => {
                        let narrationName = getNarrationName(wireframes, wireframeScene.id, narration, program);
                        createJsonHierarchyForVar(placeHolders, narrationName, {
                            type: "narration",
                            value: {
                                codefor: getCodefors(wireframes, wireframeScene.id, narration, programDataFields, program)
                            }
                        });
                    });
                }
            }
            else {
                let index = 0;
                scenePart[placeholderType.narration].forEach((narration) => {
                    let value = {
                        transcript: getNarrationDefaultText(wireframes, narration),
                        source: mockNarrationsArr[index].url
                    };
                    let narrationName = getNarrationName(wireframes, wireframeScene.id, narration, program);
                    createJsonHierarchyForVar(placeHolders, narrationName, value);
                    ++index;
                });
            }
        }
    });
}

function createAnimationLogic(wireFrameLogic, sceneId: string, wireFrameScene, useAbsoluteAnimationPath: boolean, programDataFields) {
    // If An animatic preview is requested (or forced by the user),
    // don't mind the logic definitions
    if (wireFrameScene.animaticPath) {
        return { href: wireFrameScene.animaticPath };
    }
    // If animation logic is defined, send it in a logic format for the VLX to know
    if (wireFrameLogic[sceneId] && wireFrameLogic[sceneId][sceneId + "_animation"] && !isLogicEmpty(wireFrameLogic[sceneId][sceneId + "_animation"])) {
        let animationLogics = {
            rules: buildInputRules(wireFrameLogic[sceneId][sceneId + "_animation"], programDataFields, useAbsoluteAnimationPath),
            type: getInputLogicType(wireFrameLogic[sceneId][sceneId + "_animation"])
        };
        return animationLogics;
    }
    // Fallback to get animation from scene if no logic is defined
    return createSceneAnimation(wireFrameScene, useAbsoluteAnimationPath);
}

function createValidationData(wireFrameLogic, sceneId: string, programDataFields): VLXInputLogic | boolean {
    let validationLogic = wireFrameLogic[sceneId] && wireFrameLogic[sceneId][sceneId + "_validation"];
    if (!isLogicEmpty(validationLogic)) {
        let inputsLogics: VLXInputLogic = {
            rules: buildInputRules(wireFrameLogic[sceneId][sceneId + "_validation"], programDataFields),
            type: getInputLogicType(wireFrameLogic[sceneId][sceneId + "_validation"]) as VLXInputLogicType
        };
        return inputsLogics;
    }
    return true;
}

function createSceneVariables(sceneWireframesData, sceneWireframesLogic, programDataFields) {
    let variables = [];
    let prioritizedListsIDs = new Set([]);

    sceneWireframesData.sceneParts.forEach((scenePart) => {
        if (scenePart[entityType.prioritizedLists]) {
            scenePart[entityType.prioritizedLists].forEach((pList) => {
                if (!prioritizedListsIDs.has(pList.id)) {
                    prioritizedListsIDs.add(pList.id);
                    const variable = {
                        name: pList.name,
                        value: {
                            type: "object",
                            cardinality: { multiple: true, transform: "slots", limit: pList.slots.length },
                            rules: buildInputRules(sceneWireframesLogic[pList.id], programDataFields)
                        }
                    };
                    variables.push(variable);
                }
            });
        }
    });

    let placeholdersNames = new Set([]);

    const addVariable = (placeholder: Placeholder | Parameter, overrideType?: string) => {
        //@ts-ignore
        const identifier = placeholder.id || placeholder.name;
        if (!placeholdersNames.has(identifier)) {
            placeholdersNames.add(identifier);
            const variable = {
                name: identifier,
                value: {
                    type: overrideType || placeholder.type,
                    rules: buildInputRules(sceneWireframesLogic[identifier], programDataFields)
                }
            };
            variables.push(variable);
        }
    };

    sceneWireframesData.sceneParts.forEach((scenePart) => {
        if (scenePart[placeholderType.onscreen]) {
            scenePart[placeholderType.onscreen].forEach((ph) => {
                addVariable(ph);
            });
        }

        if (scenePart[entityType.groups]) {
            scenePart[entityType.groups].forEach((group) => {
                addVariable(group, LOGIC_TYPE.Compound);
            });
        }

        if (scenePart[placeholderType.parameter]) {
            scenePart[placeholderType.parameter].forEach((parameter: Parameter) => {
                addVariable(parameter);
            });
        }
    });

    // descriptive transcript is per-scene and doesn't exists in scenePrats
    const descriptiveTranscriptLogic = sceneWireframesLogic && sceneWireframesLogic[ADAVarName];
    if (descriptiveTranscriptLogic) {
        const descriptiveTranscriptVar = {
            name: ADAVarName,
            value: {
                type: LOGIC_TYPE.Text,
                rules: buildInputRules(descriptiveTranscriptLogic, programDataFields)
            }
        };
        variables.push(descriptiveTranscriptVar);
    }

    return variables;
}

type CreateSceneLogicParams = {
    wireframes: any;
    assets: Asset[];
    skipNarration?: boolean;
    ignoreValidation?: boolean;
    mockNarrator?: any;
    selectedSceneIds?: string[];
    accountId?: string;
    program: Program;
    projectAssetsServices?: any;
    enabledFeatureFlags?: any;
    masterData: Scene;
};

function createSceneLogic({
    wireframes,
    assets,
    skipNarration,
    ignoreValidation,
    mockNarrator,
    selectedSceneIds,
    accountId,
    program,
    projectAssetsServices,
    enabledFeatureFlags,
    masterData
}: CreateSceneLogicParams) {
    let scenesIds = Object.keys(wireframes.scenes).filter((id) => id !== LogicContainers.Master && (Array.isArray(selectedSceneIds) ? selectedSceneIds.includes(id) : true));
    const dataElements = StateReaderUtils.getProjectDataElementsFromWireframes(wireframes) || [];
    const prioritizedLists = StateReaderUtils.getProjectPrioritizedListsFromWireframes(wireframes);

    const programDataFields: ProgramDataFields = {
        dataElements: dataElements,
        prioritizedListLogic: prioritizedLists,
        assets
    };

    let promises = [];
    scenesIds.map((sceneId) => {
        promises.push(
            createSceneInputsLogic({
                wireframes,
                sceneId,
                skipNarration,
                mockNarrator,
                accountId,
                program,
                projectAssetsServices,
                programDataFields,
                enabledFeatureFlags,
                masterData
            })
        );
    });
    return Promise.all(promises).then((resultArray) => {
        return scenesIds.map((sceneId, index) => {
            let wireFrameScene = wireframes.scenes[sceneId];
            let useAbsoluteAnimationPath: boolean = !!wireframes.scenes[sceneId].animaticPath;
            const prioritizedLists = StateReaderUtils.getScenePrioritizedList(wireframes, sceneId);
            let context = { ...programDataFields, prioritizedLists };
            const animation = createAnimationLogic(wireframes.logic, sceneId, wireFrameScene, useAbsoluteAnimationPath, context);

            let sceneLogic: VLXLineupScene = {
                animation,
                id: sceneId,
                name: wireFrameScene.name,
                data: resultArray[index].placeHolders,
                valid: ignoreValidation ? true : createValidationData(wireframes.logic, sceneId, context)
            };
            if (resultArray[index].sceneVariables && Array.isArray(resultArray[index].sceneVariables) && resultArray[index].sceneVariables.length > 0) {
                sceneLogic.variables = resultArray[index].sceneVariables;
                resultArray[index].sceneVariables.forEach((variable) => {
                    if (!sceneLogic.data[variable.name]) {
                        sceneLogic.data[variable.name] = variable.value;
                    }
                });
            }
            return sceneLogic;
        });
    });
}

function buildTtsUrl(accountId, projectName, projectAssetsServices, mockNarrator, value, defaultValue) {
    return projectAssetsServices.getMockNarration(accountId, projectName, mockNarrator.toLowerCase(), value ? value : defaultValue);
}

export default createSceneLogic;
