import { createAction } from "redux-actions";
import { reportError } from "../common/commonActions";
export const UPDATE_PROJECT_APIKEY_ENDPOINT = "UPDATE_PROJECT_APIKEY_ENDPOINT";
export const updateProjectApikeyEndpoint = createAction(UPDATE_PROJECT_APIKEY_ENDPOINT, function (apiKeys, endPoint, renderer3EndPoint) {
    return { apiKeys: apiKeys, endPoint: endPoint, renderer3EndPoint: renderer3EndPoint };
});
export const getAndUpdateProjectApikeyEndpoint = function (accountId, projectName) {
    return function (dispatch, state, services) {
        services.previewServices
            .getProjectApiKeyAndEndPoint(accountId, projectName)
            .then(function (res) {
            dispatch(updateProjectApikeyEndpoint(res.apiKey, res.url, res.renderer3EndPoint));
        })
            .catch(function (err) {
            dispatch(reportError(err));
        });
    };
};
