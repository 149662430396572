import BaseServices from "./BaseServices";
import { EDITING_MODE, STAGE_MODE } from "./Consts";
import type { GetAuthUriResponse } from "../../../common/types/restApiTypes";
import type { ThirdPartyServiceType } from "../../../common/types/serviceTypes";


class OAuth2Services extends BaseServices {
    constructor(authService, builderModeRetriever) {
        super(authService, builderModeRetriever);
    }

    getAuthUri(accountId: string, service: ThirdPartyServiceType, subdomain?: string): Promise<GetAuthUriResponse> {
        const url = `/rest/${accountId}/oauth2/authuri?service=${service}${subdomain ? `&subdomain=${subdomain}` : ""}`;
        return this.get<GetAuthUriResponse>([EDITING_MODE, STAGE_MODE], url);
    }
}

export default OAuth2Services;
