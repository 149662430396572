import type { ApolloClient, ApolloError } from "@apollo/client";
import type { IFrameworkEntity, onSaveArgs, Roles } from "../../types";
import type {
    GqlClientAudiencePersonalization,
    GqlClientChannelPersonalization,
    GqlClientCreateSceneMutationVariables,
    GqlClientCreateSceneWithKpIsPersonalizationsAndDataElementsInput,
    GqlClientCreateSceneWithKpIsPersonalizationsAndDataElementsNewAudiencePersonalizationInput,
    GqlClientCreateSceneWithKpIsPersonalizationsAndDataElementsNewChannelPersonalizationInput,
    GqlClientCreateSceneWithKpIsPersonalizationsAndDataElementsNewTouchpointPersonalizationInput,
    GqlClientFeedDataElement,
    GqlClientKpi,
    GqlClientScene,
    GqlClientSceneSupportersFragment,
    GqlClientTouchpointPersonalization,
    GqlClientUpdateSceneMutationVariables,
    GqlClientUpdateSceneWithKpIsPersonalizationsAndDataElementsAudiencePersonalizationInput,
    GqlClientUpdateSceneWithKpIsPersonalizationsAndDataElementsChannelPersonalizationInput,
    GqlClientUpdateSceneWithKpIsPersonalizationsAndDataElementsInput,
    GqlClientUpdateSceneWithKpIsPersonalizationsAndDataElementsTouchpointPersonalizationInput
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { SceneSupportersFragmentDoc, withCreateScene, withDeleteScene, withUpdateScene } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import type { CardData } from "../../../../components/framework/EntityCard";
import { getRole, initCardData } from "../../utils";
import type { withGqlMutationProps } from "../../../../components/framework/WithGqlMutations";
import type { SceneDialogProps } from "../../../../components/framework/dialogs/SceneDialog";

// region Types
type CreateAndUpdateAudiencePersonalizations = {
    audiencePersonalizations: GqlClientUpdateSceneWithKpIsPersonalizationsAndDataElementsAudiencePersonalizationInput[];
    newAudiencePersonalizations: GqlClientCreateSceneWithKpIsPersonalizationsAndDataElementsNewAudiencePersonalizationInput[];
};

type CreateAndUpdateChannelPersonalizations = {
    channelPersonalizations: GqlClientUpdateSceneWithKpIsPersonalizationsAndDataElementsChannelPersonalizationInput[];
    newChannelPersonalizations: GqlClientCreateSceneWithKpIsPersonalizationsAndDataElementsNewChannelPersonalizationInput[];
};

type CreateAndUpdateTouchpointPersonalizations = {
    touchpointPersonalizations: GqlClientUpdateSceneWithKpIsPersonalizationsAndDataElementsTouchpointPersonalizationInput[];
    newTouchpointPersonalizations: GqlClientCreateSceneWithKpIsPersonalizationsAndDataElementsNewTouchpointPersonalizationInput[];
};
// endregion

const onSave = (args: onSaveArgs<GqlClientScene, GqlClientCreateSceneMutationVariables, GqlClientUpdateSceneMutationVariables>) => {
    const { data, createCB, updateCB, programVersionId, dryRun } = args;
    const { id, updated, name, description, kpis, personalizations, feedDataElements } = data;
    const isNewScene: boolean = !id;

    const { audiencePersonalizations, newAudiencePersonalizations } = transformPersonalizationsToAudiencePersonalizations(
        personalizations.filter((p) => (p as GqlClientAudiencePersonalization).audience) as GqlClientAudiencePersonalization[]
    );
    const { channelPersonalizations, newChannelPersonalizations } = transformPersonalizationsToChannelPersonalizations(
        personalizations.filter((p) => (p as GqlClientChannelPersonalization).channel) as GqlClientChannelPersonalization[]
    );
    const { touchpointPersonalizations, newTouchpointPersonalizations } = transformPersonalizationsToTouchpointPersonalizations(
        personalizations.filter((p) => (p as GqlClientTouchpointPersonalization).touchpoint) as GqlClientTouchpointPersonalization[]
    );
    const dataElementIds: string[] = feedDataElements.filter((de: GqlClientFeedDataElement) => de.id).map((de: GqlClientFeedDataElement) => de.id);
    const newFeedDataElements: GqlClientFeedDataElement[] = feedDataElements.filter((de: GqlClientFeedDataElement) => !de.id);
    const kpiIds: string[] = kpis.map((kpi: GqlClientKpi) => kpi.id);

    if (isNewScene) {
        const newScene: GqlClientCreateSceneWithKpIsPersonalizationsAndDataElementsInput = {
            name,
            description,
            newAudiencePersonalizations,
            newChannelPersonalizations,
            newTouchpointPersonalizations,
            dataElementIds,
            newFeedDataElements,
            kpiIds
        };
        createCB({ variables: { programVersionId, input: newScene } });
    }
    else {
        const updatedScene: GqlClientUpdateSceneWithKpIsPersonalizationsAndDataElementsInput = {
            id,
            updated,
            name,
            description,
            audiencePersonalizations,
            newAudiencePersonalizations,
            channelPersonalizations,
            newChannelPersonalizations,
            touchpointPersonalizations,
            newTouchpointPersonalizations,
            dataElementIds,
            newFeedDataElements,
            kpiIds
        };
        updateCB({ variables: { input: updatedScene, dryRun } });
    }
};

const transformPersonalizationsToAudiencePersonalizations = (personalizations: GqlClientAudiencePersonalization[]): CreateAndUpdateAudiencePersonalizations => {
    const newAudiencePersonalizations: GqlClientCreateSceneWithKpIsPersonalizationsAndDataElementsNewAudiencePersonalizationInput[] = personalizations
        .filter((ap) => !ap.id)
        .map(({ audience, type, description, audiencePersonalizationValues }) => ({
            audienceId: audience.id,
            type: type || [],
            description: description,
            newAudiencePersonalizationValues: audiencePersonalizationValues.map(({ audienceValue, description }) => ({
                audienceValueId: audienceValue.id,
                description
            }))
        }));

    const audiencePersonalizations: GqlClientUpdateSceneWithKpIsPersonalizationsAndDataElementsAudiencePersonalizationInput[] = personalizations
        .filter((ap) => ap.id)
        .map(({ id, updated, description, type, audience, audiencePersonalizationValues }) => ({
            id,
            updated,
            description,
            type: type || [],
            audienceId: audience.id,
            audiencePersonalizationValues: audiencePersonalizationValues
                .filter((apv) => apv.id)
                .map(({ id, updated, description, audienceValue }) => ({
                    id,
                    description,
                    updated,
                    audienceValueId: audienceValue.id
                })),
            newAudiencePersonalizationValues: audiencePersonalizationValues
                .filter((apv) => !apv.id)
                .map(({ audienceValue, description }) => ({
                    audienceValueId: audienceValue.id,
                    description
                }))
        }));

    return { audiencePersonalizations, newAudiencePersonalizations };
};

const transformPersonalizationsToChannelPersonalizations = (personalizations: GqlClientChannelPersonalization[]): CreateAndUpdateChannelPersonalizations => {
    const newChannelPersonalizations: GqlClientCreateSceneWithKpIsPersonalizationsAndDataElementsNewChannelPersonalizationInput[] = personalizations
        .filter((cp) => !cp.id)
        .map(({ channel, type, description }) => ({
            channelId: channel.id,
            type: type || [],
            description
        }));

    const channelPersonalizations: GqlClientUpdateSceneWithKpIsPersonalizationsAndDataElementsChannelPersonalizationInput[] = personalizations
        .filter((cp) => cp.id)
        .map(({ id, updated, description, type, channel }) => ({
            id,
            updated,
            description,
            type,
            channelId: channel.id
        }));

    return { channelPersonalizations, newChannelPersonalizations };
};

const transformPersonalizationsToTouchpointPersonalizations = (personalizations: GqlClientTouchpointPersonalization[]): CreateAndUpdateTouchpointPersonalizations => {
    const newTouchpointPersonalizations: GqlClientCreateSceneWithKpIsPersonalizationsAndDataElementsNewTouchpointPersonalizationInput[] = personalizations
        .filter((tp) => !tp.id)
        .map(({ touchpoint, type, description }) => ({
            touchpointId: touchpoint.id,
            type: type || [],
            description
        }));

    const touchpointPersonalizations: GqlClientUpdateSceneWithKpIsPersonalizationsAndDataElementsTouchpointPersonalizationInput[] = personalizations
        .filter((p) => p.id)
        .map(({ id, updated, description, type, touchpoint }) => ({
            id,
            updated,
            description,
            type,
            touchpointId: touchpoint.id
        }));

    return { touchpointPersonalizations, newTouchpointPersonalizations };
};

const getSupporters = (scene: GqlClientScene, client: ApolloClient<any>): string[] => {
    /** supporters for scenes:
     * stories related to the scene
     * touchpoints related to those stories
     * channels related to those stories
     * kpis that are related to that scene
     * parent goals of those kpis
     * audiences related to the scene
     * values that are related to the scene
     * parent audiences of those values
     * feedDataElements related to the scene
     * channels related to the scene
     * touchpoints related to the scene
     */
    let supporterIds = [];
    const { stories, kpis, personalizations, feedDataElements } = scene;
    stories.forEach(({ id, __typename }) => {
        supporterIds.push(id);
        const storyData: GqlClientSceneSupportersFragment = client.readFragment({
            id: `${__typename}:${id}`,
            fragment: SceneSupportersFragmentDoc
        });
        storyData.touchpoints.forEach(({ id }) => supporterIds.push(id));
        storyData.channels.forEach(({ id }) => supporterIds.push(id));
    });
    kpis.forEach(({ id, goal }) => {
        supporterIds.push(id);
        supporterIds.push(goal.id);
    });
    feedDataElements.forEach(({ id }) => supporterIds.push(id));
    personalizations.forEach((personalization) => {
        const { audiencePersonalizationValues, audience } = personalization as GqlClientAudiencePersonalization;
        const { channel } = personalization as GqlClientChannelPersonalization;
        const { touchpoint } = personalization as GqlClientTouchpointPersonalization;
        audience && supporterIds.push(audience.id);
        audience && audience.feedDataElements && supporterIds.push(...audience.feedDataElements.map(({ id }) => id));
        audiencePersonalizationValues && audiencePersonalizationValues.forEach(({ audienceValue }) => supporterIds.push(audienceValue.id));
        channel && supporterIds.push(channel.id);
        touchpoint && supporterIds.push(touchpoint.id);
    });
    return supporterIds;
};

const getCardData = (scene: GqlClientScene, roles?: Roles): CardData => {
    let sceneData: CardData = initCardData(scene);
    if (scene.stories.length > 1) {
        sceneData.header.icons.push({ prefix: "far", name: "link" });
    }
    sceneData.contents = [
        {
            title: "Description:",
            descriptionText: scene.description ? `${scene.description}` : "None"
        }
    ];

    if (roles) sceneData.role = getRole(scene, roles);
    return sceneData;
};

const Scene: IFrameworkEntity<SceneDialogProps> = {
    mutations: {
        create: withCreateScene<withGqlMutationProps<SceneDialogProps>>({
            name: "createScene",
            options: ({ setMutationResult, setError }) => ({
                onCompleted: ({ createSceneWithKPIsPersonalizationsAndDataElements }) => setMutationResult(createSceneWithKPIsPersonalizationsAndDataElements.result),
                onError: (error: ApolloError) => {
                    setError(error);
                }
            })
        }),
        update: withUpdateScene<withGqlMutationProps<SceneDialogProps>>({
            name: "updateScene",
            options: ({ setMutationResult, setError }) => ({
                onCompleted: ({ updateSceneWithKPIsPersonalizationsAndDataElements }) => setMutationResult(updateSceneWithKPIsPersonalizationsAndDataElements.result),
                onError: (error: ApolloError) => {
                    setError(error);
                }
            })
        }),
        delete: withDeleteScene<withGqlMutationProps<SceneDialogProps>>({ name: "deleteScene" })
    },
    getSupporters,
    getCardData,
    onSave
};

export default Scene;
