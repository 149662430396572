import type { GraphQLError } from "graphql";
import createHistory from "history/createBrowserHistory";
import * as smst from "sourcemapped-stacktrace";
import { errors } from "@sundaysky/smartvideo-hub-auth";
import { legacyLoginPath, newNavLoginPath } from "@sundaysky/smartvideo-hub-urls";
import { isNewNav } from "../../utils/newNavUtils";
import type { ReportErrorData } from "./logReportUtils";
import { reportErrorToSplunk } from "./logReportUtils";
import { getHomePageByUserRoles, removeLastSlash } from "./routingUtils";
import StateReaderUtils from "./StateReaderUtils";
import { getReduxState } from "../../components/store";
import { ERROR_ID_KEY, getErrorIdFromRestError, getSskyErrorCodeFromGqlError, SskyErrorCode } from "../../../common/errors";


const getErrorStackMessage = (stack: string[]): string => {
    // regex - filter our everything that isn't from our code (like node modules)
    let stackArr: string[] =
        stack &&
        stack
            .filter((line: string) => /webpack:\/\/\/client/.test(line))
            .map((line: string) => {
                // using regex to parse stack-trace - returns [at functionName (sourceFile.ext:lineNumber)]
                // example - "at renderValidationContent (webpack:///client/components/common/HubCollapsePanel.jsx:108:83)" -> "at renderValidationContent (HubCollapsePanel.jsx:108)"
                let regexArr = /(at \w+)(?:.+\/)*(.+\..+)(?::\d+\))/gm.exec(line);
                return regexArr && regexArr.length > 2 ? `${regexArr[1]} (${regexArr[2]})` : "";
            });
    return stackArr.slice(0, 5).join(" < ");
};

const getComponentStackMessage = (stack: string[]): string => {
    let stackArr: string[] =
        stack &&
        stack.map((line: string) => {
            // using regex to parse stack-trace - returns component name
            // example - "in span (created by TestRunnerView)" -> "TestRunnerView"
            let regexArr = /(?:\(created by )(.*?)(?:\))/gm.exec(line);
            return regexArr && regexArr.length > 1 ? regexArr[1] : "";
        });
    let uniqueStackArr: string[] = [...new Set(stackArr)];
    let filteredArr: string[] = uniqueStackArr.filter((elem) => elem && !elem.includes("Connect")).slice(0, 10);
    return filteredArr.join(" < ");
};

const mapStackTracePromise = (err: any): Promise<any> => {
    return new Promise((resolve) => {
        if (typeof err === "object" && err.stack) {
            smst.mapStackTrace(err.stack, function(mappedStack) {
                let stackMessage = getErrorStackMessage(mappedStack) || getComponentStackMessage(mappedStack) || mappedStack;
                if (stackMessage) {
                    err.stack = stackMessage;
                }
                resolve(err);
            });
        }
        else {
            resolve(err);
        }
    });
};

const parseErrorForReport = async (err: any, crashed: boolean) => {
    const firstGqlError = err?.graphQLErrors?.[0];
    const gqlServerErrorMessage: string = firstGqlError?.message || "";
    const gqlNetworkErrorMessage: string = err?.networkError?.message;
    //Preparing info for splunk report.
    err = await mapStackTracePromise(err);
    if (!err[ERROR_ID_KEY]) {
        if (firstGqlError?.[ERROR_ID_KEY]) {
            err[ERROR_ID_KEY] = firstGqlError[ERROR_ID_KEY];
        }
        else if (firstGqlError?.extensions?.[ERROR_ID_KEY]) {
            err[ERROR_ID_KEY] = firstGqlError.extensions[ERROR_ID_KEY];
        }
    }

    const errorIdFromRestError = getErrorIdFromRestError(err);
    let errorMessage: string;
    if (gqlServerErrorMessage) {
        errorMessage = "[GraphQL error]: " + gqlServerErrorMessage;
    }
    else if (gqlNetworkErrorMessage) {
        errorMessage = "[GraphQL Network error]: " + gqlNetworkErrorMessage;
    }
    //rest user error parsing - backward compatible.
    else if (errorIdFromRestError) {
        err[ERROR_ID_KEY] = errorIdFromRestError;
        const responseData = err.response.data;
        errorMessage = "Error ID:" + responseData.errorId + "\n\nDescription:" + (responseData.message || responseData.error) + "\n\nDetails:" + responseData.metadata;
    }
    else {
        errorMessage = err.message || String(err).toString();
    }
    let requestUrl = "";
    if (err.details?.method && err.details?.url) {
        requestUrl = `${err.details.method} : ${err.details.url}`;
    }
    else if (err.config) {
        requestUrl = `${err.config.method} : ${err.config.url}`;
    }
    //TODO when no legacy programs use getProgramNameFromPathname
    const metadataObj: ReportErrorData = {
        errorStackMessage: err.stack,
        requestUrl,
        error: err,
        crashed
    };
    return { errorMessage, metadataObj };
};

export const handleError = async ({ error }): Promise<boolean> => {
    const { errorMessage, metadataObj } = await parseErrorForReport(error, false);
    reportErrorToSplunk(errorMessage, metadataObj);

    // there are cases that the users will be redirect to login/home page
    let isRedirected = false;
    const response = error.response;
    const gqlError: GraphQLError | undefined = error.graphQLErrors?.[0];
    const gqlServerErrorMessage: string = gqlError?.message || "";
    const history = createHistory();

    const shouldNavigateToLoginPage = (
        removeLastSlash(window.location.pathname) !== legacyLoginPath()
        || removeLastSlash(window.location.pathname) !== newNavLoginPath()
    ) && (
        gqlServerErrorMessage === "Error: Client is outdated"
        || error === errors.userNotLoggedIn
        || response === errors.userNotLoggedIn
        || response?.status === 401
        || getSskyErrorCodeFromGqlError(gqlError) === SskyErrorCode.Unauthorized
        || error.getSskyErrorCode?.() === SskyErrorCode.Unauthorized
    );

    if (shouldNavigateToLoginPage) {
        history.push(
            isNewNav
                ? newNavLoginPath()
                : legacyLoginPath()
        );
        window.location.reload();
        isRedirected = true;
    }
    // ToDo: add support to qgl errors to cause redirect to home page when needed.
    else if (response?.status === 403) {
        const userRoles = StateReaderUtils.getUserRoles(getReduxState());
        const activeAccountId = StateReaderUtils.getActiveAccountId(getReduxState());
        const redirect = getHomePageByUserRoles(userRoles, activeAccountId);
        if (redirect) {
            history.push(redirect);
            window.location.reload();
            isRedirected = true;
        }
    }

    return isRedirected;
};

export const handleUnexpectedError = async ({ error }): Promise<void> => {
    const errorMessage = error?.message?.split("\n")[0];
    error = await mapStackTracePromise(error);
    reportErrorToSplunk(errorMessage, { stackMessage: error.stack, crashed: true });
};
