import { LOGIC_DATA_TYPES, LOGIC_MEDIA_TYPES } from "../vlx/consts";
import PropTypes from "prop-types";
import { valueExists } from "../../components/legacyCommon/utils";
import type { ActionableData, ActionableDataValueType, LogicMediaTypes } from "../../../common/types/logic";

export const actionableDataObjectType = Symbol("_OBJECT_LOGIC_ACTIONABLE_DATA");

// duplicated with LOGIC_DATA_TYPES

export const ActionableDataPropType = PropTypes.shape({
    mediaType: PropTypes.oneOf(Object.values(LOGIC_MEDIA_TYPES)),
    displayName: PropTypes.string,
    id: PropTypes.string,
    type: PropTypes.oneOf(Object.values(LOGIC_DATA_TYPES)),
    name: PropTypes.string,
    actions: PropTypes.array
});

// This functions builds an object, supporting formatting and modifications
// in an easy way
export function buildActionableData(args, mediaType?: LogicMediaTypes, dataType?: ActionableDataValueType): ActionableData {
    // Old signature -> buildActionableData(value, mediaType = LOGIC_MEDIA_TYPES.String, dataType = LOGIC_DATA_TYPES.Const)
    // This functions now uses 'RORO' (receive obj return obj) In order to support this old signature we check if the value is an object
    // (value used to be string) if so, get the values and defaults, if not, get the args as they used to be
    // Computability code
    if (typeof args !== "object") {
        let value = args;
        args = { value };
        if (mediaType) {
            args.mediaType = mediaType;
        }
        if (dataType) {
            args.dataType = dataType;
        }
    }
    const defaults = {
        mediaType: LOGIC_MEDIA_TYPES.String,
        dataType: LOGIC_DATA_TYPES.Const,
        displayName: args.value,
        actions: []
    };
    // Merge to get defaults
    args = Object.assign({}, defaults, args);
    const { id, value, displayName, dataType: effectiveDataType, mediaType: effectiveMediaType, actions } = args;

    // TODO: Stop using name
    return {
        id,
        name: value,
        value: value,
        displayName,
        mediaType: effectiveMediaType,
        type: effectiveDataType,
        actions,
        _objectType: actionableDataObjectType
    };
}

/***
 * Extracts the value stored in the actionable data of an object
 * (used to be name, for old objects, and has value/id for newer ones
 * @param actionableDataObj - An object representing data in logic (which has actions that can be aplied on it) created with buildAcitonableData functions (see above)
 */
export function getValueFromActionableData(actionableDataObj: ActionableData): string {
    // Return null for empty values
    if (!actionableDataObj) return null;

    // Value can be float, number, boolean, etc.
    if (valueExists(actionableDataObj.value)) {
        return actionableDataObj.value.toString();
    }

    if (valueExists(actionableDataObj.id)) {
        return actionableDataObj.id;
    }

    if (valueExists(actionableDataObj.name)) {
        return actionableDataObj.name.toString();
    }
}
