import type { EntityInstance, EntityTypes } from "./definitions";
import type { GetValidationResultFunction, Issue, IssueCodes, ValidationResult } from "../validations/validationManager";
import ValidationManager from "../validations/validationManager";
import type { ChangeDetail, DiffResult, GetDiffResultFunc } from "../versionDiff/diffManager";
import DiffManager, { ChangeType } from "../versionDiff/diffManager";

export type GetDependencyIdFunction = (dependency: any) => string;
export type AnyEntity = any; // basically any entity or thing we have in the system.
export type IdentityFunc = (AnEntity) => string;
export type AnyEntityById = { [id: string]: AnyEntity };
export type AnyEntityContextPair = { previousEntity: AnyEntity; currentEntity: AnyEntity };
export type AnyEntitiesByContextById = Map<string, AnyEntityContextPair>;

export class BaseEntity {
    private readonly type: EntityTypes;

    constructor(type: EntityTypes) {
        this.type = type;
    }

    getType = (): EntityTypes => {
        return this.type;
    };

    private validateDependency = (type: EntityTypes, id: string, getValidationResult: GetValidationResultFunction): ValidationResult => {
        return getValidationResult(type, id);
    };

    getDependenciesIssue = (
        dependencies: any[],
        dependencyType: EntityTypes,
        getDependencyId: GetDependencyIdFunction,
        getValidationResult: GetValidationResultFunction,
        issueCode: IssueCodes
    ): Issue => {
        // Collect dependencies validation results, filter ones with issues
        let dependenciesValidationResults: ValidationResult[] = dependencies
            .map((dependency) => {
                return this.validateDependency(dependencyType, getDependencyId(dependency), getValidationResult);
            })
            .filter((result) => result && result.issues.length > 0);

        // If there are validation result, return an issue with those as children
        if (dependenciesValidationResults.length > 0) {
            let severity = ValidationManager.getHighestSeverity(dependenciesValidationResults.map((result) => result.severity));
            return {
                code: issueCode,
                severity: severity,
                details: {},
                children: dependenciesValidationResults
            };
        }
        return undefined;
    };

    private diffDependency = (type: EntityTypes, id: string, getDiffResult: GetDiffResultFunc): DiffResult => getDiffResult(type, id);

    /**
     * Iterates through the dependencies list and returns a ChangeDetail object
     * @param dependencies - list of entities. also indicates the amount of children (a placeholder only have one logic)
     * @param dependencyType - the type of the dependencies
     * @param getDependencyId - identity function
     * @param getDiffResult - callback to get dependencies table keys
     * @param assumeChildChangeType - used when there's a 1:1 link between parent and child.
     * For example a placeholder and it's logic object or analytics and it's logic object.
     * False when entity is a collection of other entities- scene and sceneParts or sceneParts and it's placeholders,
     * @returns a ChangeDetail object or undefined if all changes are 'none'
     */
    getDependenciesDiff = (
        dependencies: AnyEntity[],
        dependencyType: EntityTypes,
        getDependencyId: GetDependencyIdFunction,
        getDiffResult: GetDiffResultFunc,
        assumeChildChangeType: boolean = false
    ): ChangeDetail => {
        let dependenciesDiffResults: DiffResult[] = dependencies
            .map((dependency: AnyEntity) => this.diffDependency(dependencyType, getDependencyId(dependency), getDiffResult))
            .filter((result: DiffResult) => result && (result.changeType !== ChangeType.None || result.changes.length > 0));

        if (dependenciesDiffResults.length > 0) {
            const changes: ChangeType[] = dependenciesDiffResults.map((result: DiffResult) => result.changeType);
            const changeType: ChangeType = DiffManager.getChangeType(changes, assumeChildChangeType);
            return {
                changeType: changeType,
                details: assumeChildChangeType && `assumed the change of a child - '${changeType}'`,
                children: dependenciesDiffResults
            };
        }

        return undefined;
    };

    private transformEntityInstanceArrayToObject = (entityList: AnyEntity[], identityFunc: IdentityFunc): AnyEntityById => {
        let out: AnyEntityById = {};
        entityList.forEach((entity: EntityInstance) => {
            out[identityFunc(entity)] = entity;
        });
        return out;
    };

    /**
     * Convert two entity lists to a Map where the key is the entity Id and the value is an object comparable entities.
     * Use entityObjectsToMap when entities are in object and not in a list
     * @param previousEntityList - any list of entities
     * @param currentEntityList - any list of entities
     * @param identityFunc - where to find the Id of the entity, ex entity => entity.id
     */
    entityListsToMap = (previousEntityList: AnyEntity[] = [], currentEntityList: AnyEntity[] = [], identityFunc: IdentityFunc): AnyEntitiesByContextById => {
        let EntitiesById: AnyEntitiesByContextById = new Map();
        const previousObj: AnyEntityById = this.transformEntityInstanceArrayToObject(previousEntityList, identityFunc);
        const currentObj: AnyEntityById = this.transformEntityInstanceArrayToObject(currentEntityList, identityFunc);

        // add previous entity pairs to map
        previousEntityList.forEach((previousEntity: AnyEntity) => {
            const itemId: string = identityFunc(previousEntity);
            const currentEntity: AnyEntity = currentObj[itemId];
            EntitiesById.set(itemId, { previousEntity: previousEntity, currentEntity: currentEntity });
        });

        // add entities that were added (won't appear in previous)
        currentEntityList.forEach((currentEntity: AnyEntity) => {
            const itemId: string = identityFunc(currentEntity);
            if (!EntitiesById.has(itemId)) {
                const previousEntity: AnyEntity = previousObj[itemId];
                EntitiesById.set(itemId, { previousEntity: previousEntity, currentEntity: currentEntity });
            }
        });
        return EntitiesById;
    };

    /**
     * Convert two entity objects to a Map where the key is the entity Id and the value is an object comparable entities.
     * Use entityListsToMap when entities are in a list and not in an object
     * @param previousEntitiesObj
     * @param currentEntitiesObj
     */
    entityObjectsToMap = (previousEntitiesObj: AnyEntityById = {}, currentEntitiesObj: AnyEntityById = {}): AnyEntitiesByContextById => {
        let EntitiesById: AnyEntitiesByContextById = new Map();

        // add previous entity pairs to map
        Object.entries(previousEntitiesObj).forEach(([id, previousEntity]) => {
            const currentEntity: AnyEntity = currentEntitiesObj[id];
            EntitiesById.set(id, { previousEntity: previousEntity, currentEntity: currentEntity });
        });

        // add entities that were added (won't appear in previous)
        Object.entries(currentEntitiesObj).forEach(([id, currentEntity]) => {
            if (!EntitiesById.has(id)) {
                const previousEntity: AnyEntity = previousEntitiesObj[id];
                EntitiesById.set(id, { previousEntity: previousEntity, currentEntity: currentEntity });
            }
        });

        return EntitiesById;
    };

    getPropsFromPreviousOrCurrent = (previousEntity: AnyEntity, currentEntity: AnyEntity, propName: string): any => (currentEntity ? currentEntity[propName] : previousEntity[propName]);
}
