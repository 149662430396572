import type { IFrameworkEntity, onSaveArgs, Roles } from "../../types";
import type {
    GqlClientAudience,
    GqlClientAudienceSupportersFragment,
    GqlClientCreateAudienceMutationVariables,
    GqlClientCreateAudienceWithDataElementsInput,
    GqlClientUpdateAudienceMutationVariables,
    GqlClientUpdateAudienceWithDataElementsInput
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { AudienceSupportersFragmentDoc, withCreateAudience, withDeleteAudience, withUpdateAudience } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import type { ApolloClient, ApolloError } from "@apollo/client";
import type { CardData } from "../../../../components/framework/EntityCard";
import { getPersonalizationIconsForHeader, getRole, initCardData } from "../../utils";
import type { AudienceDialogProps } from "../../../../components/framework/dialogs/AudienceDialog";
import type { withGqlMutationProps } from "../../../../components/framework/WithGqlMutations";

const onSave = (
    args: onSaveArgs<GqlClientCreateAudienceWithDataElementsInput & GqlClientUpdateAudienceWithDataElementsInput, GqlClientCreateAudienceMutationVariables, GqlClientUpdateAudienceMutationVariables>
) => {
    const { data, createCB, updateCB, programVersionId, dryRun } = args;
    const { id, updated, name, description, impactsVolume, audienceValues, newAudienceValues, newFeedDataElements, dataElementIds } = data;
    const isNew = !id;

    if (isNew) {
        const newAudience: GqlClientCreateAudienceWithDataElementsInput = { name, description, impactsVolume, newAudienceValues, dataElementIds, newFeedDataElements };
        createCB({ variables: { programVersionId, input: newAudience } });
    }
    else {
        const updatedAudience: GqlClientUpdateAudienceWithDataElementsInput = { id, updated, name, description, impactsVolume, newAudienceValues, audienceValues, dataElementIds, newFeedDataElements };
        updateCB({ variables: { input: updatedAudience, dryRun } });
    }
};

const getSupporters = (audience: GqlClientAudience, client: ApolloClient<any>): string[] => {
    /** supporters for audience:
     * the audiences' values
     * scenes related to this audience
     * kpis that are related to those scenes
     * parent goals of those kpis
     * stories related to those scenes
     */
    let supporterIds = [];
    audience.audienceValues.forEach(({ id }) => supporterIds.push(id));
    audience.audiencePersonalizations.forEach(({ scene }) => {
        supporterIds.push(scene.id);
        const sceneData: GqlClientAudienceSupportersFragment = client.readFragment({
            id: `${scene.__typename}:${scene.id}`,
            fragment: AudienceSupportersFragmentDoc
        });
        sceneData.kpis.forEach(({ id, goal }) => {
            supporterIds.push(id);
            supporterIds.push(goal.id);
        });
        sceneData.stories.forEach(({ id }) => supporterIds.push(id));
    });
    audience.feedDataElements.forEach(({ id }) => supporterIds.push(id));
    return supporterIds;
};

const getCardData = (audience: GqlClientAudience, roles: Roles): CardData => {
    let audienceData: CardData = initCardData(audience);
    const { audienceValues, description } = audience;

    audienceData.header.title = audienceValues.length ? `${audienceData.header.title} (${audienceValues.length})` : audienceData.header.title;
    audienceData.contents = [{ title: "Description:", descriptionText: description ? `${description}` : "None" }];

    if (roles) {
        audienceData.role = getRole(audience, roles);
        if (audience.audiencePersonalizations.length) {
            const uniquePersonalizationTypes: Set<string> = new Set([]);
            audience.audiencePersonalizations.forEach((pers) => pers.type.forEach((type) => uniquePersonalizationTypes.add(type)));
            audienceData.header.icons = getPersonalizationIconsForHeader(Array.from(uniquePersonalizationTypes));
        }
    }

    return audienceData;
};

const Audience: IFrameworkEntity<AudienceDialogProps> = {
    mutations: {
        create: withCreateAudience<withGqlMutationProps<AudienceDialogProps>>({
            name: "createAudience",
            options: ({ setMutationResult, setError }) => ({
                onCompleted: ({ createAudienceWithDataElements }) => setMutationResult(createAudienceWithDataElements.result),
                onError: (error: ApolloError) => {
                    setError(error);
                }
            })
        }),
        update: withUpdateAudience<withGqlMutationProps<AudienceDialogProps>>({
            name: "updateAudience",
            options: ({ setMutationResult, setError }) => ({
                onCompleted: ({ updateAudienceWithDataElements }) => setMutationResult(updateAudienceWithDataElements.result),
                onError: (error: ApolloError) => {
                    setError(error);
                }
            })
        }),
        delete: withDeleteAudience<withGqlMutationProps<AudienceDialogProps>>({ name: "deleteAudience" })
    },
    getSupporters,
    getCardData,
    onSave
};

export default Audience;
