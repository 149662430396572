import type { Context } from "./Logic";
import { createLogic } from "./Logic";
import type { LogicJSON, ReadonlyValueSet, Rule, ValueSet, ValueSetValue } from "../../../common/types/logic";
import { ActionableDataValueType } from "../../../common/types/logic";
import { LOGIC_DATA_TYPES, LOGIC_MEDIA_TYPES } from "../vlx/consts";
import StudioDataManager from "../DataElements/StudioDataManager";
import { findDataElementByLogicValue, getDataElementId } from "../DataElements/DataElementsManager";
import memoizeOne from "memoize-one";

const NULL_KEY = "~~~null_key~~~";
const NULL_ID = "~~~null_id~~~";

type GetValueSetFromLogicFunc = (logic: LogicJSON, type: string, withConstructingIds: boolean, context?: Context) => ValueSet;

export const generateValueId = () => {
    let chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    let result = "";
    for (let i = 0; i < 8; ++i) {
        let idx = Math.floor(Math.random() * chars.length);
        result += chars.charAt(idx);
    }

    return result;
};

function ruleValuePartIsConst(value: any) {
    return typeof value === "string" || (typeof value === "object" && value.type === LOGIC_DATA_TYPES.Const);
}

function addValueToValueSet(value: ValueSetValue, type: string, valueSet: Map<string, ValueSetValue>): void {
    let key = normalizeValueSetValueDisplayName(value.dn, type);
    if (!valueSet.has(key)) {
        valueSet.set(key, value);
    }
}

// add all entries of addedValueSet to mainValueSet
function concatValueSets(addedValueSet: Map<string, ValueSetValue>, mainValueSet: Map<string, ValueSetValue>): Map<string, ValueSetValue> {
    addedValueSet.forEach((value, key) => {
        if (!mainValueSet.has(key)) {
            mainValueSet.set(key, value);
        }
    });
    return mainValueSet;
}

function join(valueSet1: Map<string, ValueSetValue>, valueSet2: Map<string, ValueSetValue>): Map<string, ValueSetValue> {
    let joined: Map<string, ValueSetValue> = new Map<string, ValueSetValue>();
    valueSet1.forEach((value1, key1) => {
        valueSet2.forEach((value2, key2) => {
            //Note:
            //value1 and/or value2 might be null.
            //A null value represents the "RETURN NOTHING" option available to Studio Elements.
            //If both are null, we ignore this join altogether.
            //If one is null, then its dn and id must be synthesized.
            //
            //It is important NOT to use an empty id (see NULL_ID below). Here's why...
            //
            //Assume we have a Data Element named Color with values (Red, Blue)
            //Assume we have a Studio Data Element name Color1 where rule1 returns Color and rule2 returns NOTHING
            //Thus, Color1 has three values in its set ({ id: "TvEvsuDt", dn: "Red" }, { id: "VkVGC3J9", dn: "Blue" }, null)
            //
            //Now, let's create a Studio Data Element named Color_Color, which returns in one of its rules:
            //Color1_Color1
            //
            //This produces 9 combinations (3 x 3) as follows:
            //Red_Red
            //Red_Blue
            //Red_
            //Blue_Red
            //Blue_Blue
            //Blue_
            //_Red
            //_Blue
            //_
            //
            //Since "Red_" and "_Red" need to be accepted as distinct values, they need to have distinct ids!
            //
            //Q.E.D. The case for using NULL_ID
            //
            //Note also, that when a value is null (read above when this can be), its key will be NULL_KEY.
            //It is important not to join NULL_KEYs when producing the joined key.
            //

            if (value1 || value2) {
                let key = (key1 != NULL_KEY ? key1 : "") + (key2 != NULL_KEY ? key2 : "");
                let displayName = (value1 ? value1.dn : "") + (value2 ? value2.dn : "");

                let value1Id = value1 ? value1.id : NULL_ID;
                let value2Id = value2 ? value2.id : NULL_ID;

                //More bla, bla...
                //
                //Note:
                //value1Id or value2Id might be an empty string.
                //An empty string is the id used for literals (e.g., "_" in the example above).
                //When a literal is joined to a value, it is important NOT to use a joiner (e.g., "^").
                //Otherwise we would get different ids based on the presence or lack thereof the literal.
                //
                //It is important that Color1 + "_" + Color1 return the same ids as Color1 + Color1
                //

                let id = value1Id && value2Id ? value1Id + "^" + value2Id : value1Id + value2Id;

                joined.set(key, { dn: displayName, id: id });
            }
        });
    });
    return joined;
}

export function normalizeValueSetValueDisplayName(dn: string, type: string): string {
    if (type === LOGIC_MEDIA_TYPES.Number && !isNaN(Number(dn))) {
        return Number(dn).toString();
    }

    return dn.toLowerCase();
}

function getDisplayNameForValuePart(ruleValue: any) {
    if (typeof ruleValue === "string") {
        return ruleValue;
    }
    else if (ruleValue.mediaType === LOGIC_MEDIA_TYPES.String) {
        return ruleValue.name;
    }
    else if (ruleValue.mediaType === LOGIC_MEDIA_TYPES.Number) {
        return ruleValue.name.toString();
    }
    else if (ruleValue.mediaType === LOGIC_MEDIA_TYPES.Boolean) {
        return ruleValue.name.toString();
    }
    else if (ruleValue.mediaType === LOGIC_MEDIA_TYPES.Date) {
        return ruleValue.name.toString();
    }
}

function getIDForValuePart(ruleValue: any): string {
    if (typeof ruleValue === "object" && ruleValue.mediaType === LOGIC_MEDIA_TYPES.Boolean) {
        return ruleValue.name ? "true" : "false";
    }
    else {
        return "";
    }
}

function getValueSetFromValuePart(valuePart: any, logicMediaType: string, context?: Context): Map<string, ValueSetValue> {
    if (valuePart === undefined || valuePart === null) {
        return null;
    }
    if (ruleValuePartIsConst(valuePart)) {
        let valueSetSizeOne = new Map<string, ValueSetValue>();
        addValueToValueSet(
            {
                dn: getDisplayNameForValuePart(valuePart),
                id: getIDForValuePart(valuePart)
            },
            logicMediaType,
            valueSetSizeOne
        );
        return valueSetSizeOne;
    }
    else if (typeof valuePart === "object" && valuePart.type === ActionableDataValueType.MappingTable) {
        return null; // TODO: extract all the ouptut variations of the mapping table and return it as VS
    }
    else if (typeof valuePart === "object" && valuePart.type === ActionableDataValueType.Prioritized) {
        return null; // TODO: extract all the ouptut variations of the mapping table and return it as VS
    }
    else if (typeof valuePart === "object" && valuePart.type === ActionableDataValueType.DataElement) {
        if (valuePart.actions && valuePart.actions.length > 0) {
            return null;
        }

        let dataElement = findDataElementByLogicValue(valuePart, context && context.dataElements);
        if (!dataElement) {
            return null;
        }

        let deValueSet: ReadonlyValueSet = dataElement.getValueSet();
        if (!deValueSet) {
            return null;
        }

        let deValueSetMap = new Map<string, ValueSetValue>();
        deValueSet.forEach((value: ValueSetValue) => {
            if (value === null) {
                deValueSetMap.set(NULL_KEY, null);
            }
            else {
                let deId = getDataElementId(dataElement);
                addValueToValueSet({ ...value }, logicMediaType, deValueSetMap);
            }
        });
        return deValueSetMap;
    }
}

function getValueSetFromArrayLogicRule(ruleValue: any[], logicMediaType: string, context?: Context): Map<string, ValueSetValue> {
    return ruleValue.reduce((accVS, valuePart, index) => {
        if (!accVS) {
            return null;
        }

        let valueSetOfValuePart = getValueSetFromValuePart(valuePart, logicMediaType, context);
        if (!valueSetOfValuePart) {
            return null;
        }
        else if (index === 0) {
            return valueSetOfValuePart;
        }
        else {
            return join(accVS, valueSetOfValuePart);
        }
    }, new Map<string, ValueSetValue>());
}

/**
 * Gets a wrapped value from a rule, and returns weather or not the value is literal, and the value itself.
 * A value is considered a literal if it an actionable data with type 'const', or a string.
 * @param ruleValue The value that a rule returns.
 * @param showValue boolean from the logic that determines weather or not the value is shown.
 * @returns {object} An object with fields: isLiteral - weather or not the value is literal. value: the literal value.
 */
function getValueSetFromLogicRule(ruleValue: any, showValue: boolean, logicMediaType: string, context?: Context): Map<string, ValueSetValue> {
    let valueSetMap = new Map<string, ValueSetValue>();
    if (showValue === false) {
        valueSetMap.set(NULL_KEY, null);
    }
    else {
        if (ruleValue && Array.isArray(ruleValue)) {
            valueSetMap = getValueSetFromArrayLogicRule(ruleValue, logicMediaType, context);
        }
        else {
            valueSetMap = getValueSetFromArrayLogicRule([ruleValue], logicMediaType, context);
        }
    }
    return valueSetMap;
}

function addRuleKeyPrefixToIDs(valueSet: Map<string, ValueSetValue>, keyPrefix: string) {
    valueSet.forEach((value) => {
        if (value) {
            value.id = keyPrefix + (value.id ? "_" + value.id : "");
        }
    });
}

/**
 * Gets the value set from logic if it has a closed set, or null if it hasn't.
 * A value set of logic is all the possible values it can return, and it is considered closed set if all of them are literals.
 * We consider the character 'line-feed' as non-literal.
 * @param logicObj a logic of a derived element
 * @param type the type of the derived element
 * @param context context includes derivedLogic, mappingTables, dataElements
 * @param withConstructingIds determines weather to return for each value an array of the value ids that constructed that value.
 * @returns {array} An array of all possible values that the logic can return, or null if it isn't a closed set.
 */
export function getValueSet(logicObj: LogicJSON, type: string, context?: Context): ValueSet {
    return memoizeGetValueSet(logicObj, type, false, context) as ValueSet;
}

export function getValueSetWithConstructingIds(logicObj: LogicJSON, type: string, context?: Context): ValueSet {
    return memoizeGetValueSet(logicObj, type, true, context) as ValueSet;
}

let memoizeGetValueSet: GetValueSetFromLogicFunc = memoizeOne<GetValueSetFromLogicFunc>((logicObj: LogicJSON, type: string, withConstructingIds: boolean, context?: Context) => {
    let manager = new StudioDataManager(context);
    manager.buildDerivedGraphFromLogic(logicObj, false);

    if (manager.detectCycles().length > 0) {
        return null;
    }

    let valueSet: Map<string, ValueSetValue> = new Map<string, ValueSetValue>();

    let logic = createLogic(logicObj);
    let rules: Rule[] = logic.getAllKeysAndValues();

    for (let i = 0; i < rules.length; ++i) {
        let ruleValueSet = getValueSetFromLogicRule(rules[i].value, rules[i].show, type, context);
        if (!ruleValueSet) {
            return null;
        }

        let prefix;
        if (rules[i].convertedKey) {
            prefix = rules[i].convertedKey;
        }
        else {
            prefix = rules[i].key ? "_derived_rule_" + rules[i].key : "_derived_default";
        }
        addRuleKeyPrefixToIDs(ruleValueSet, prefix);

        concatValueSets(ruleValueSet, valueSet);
    }

    // Push the "Nothing" value to be the last
    if (valueSet.has(NULL_KEY)) {
        valueSet.delete(NULL_KEY);
        valueSet.set(NULL_KEY, null);
    }

    let valuesArr: ValueSet = Array.from(valueSet.values());
    if (!withConstructingIds) {
        return valuesArr.map((value) => value && { dn: value.dn, id: value.id });
    }
    return Array.from(valueSet.values());
});
