var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAction } from "redux-actions";
import { reportError } from "../../common/commonActions";
export const GET_ACCOUNT_DASHBOARD_DATA_SUCCESS = "GET_ACCOUNT_DASHBOARD_DATA_SUCCESS";
export const GET_ACCOUNT_VIDEO_VIEWS_SUCCESS = "GET_ACCOUNT_VIDEO_VIEWS_SUCCESS";
export const getAccountDashboardDataSuccess = createAction(GET_ACCOUNT_DASHBOARD_DATA_SUCCESS, (dashboardData) => dashboardData);
export const getAccountVideoViewsSuccess = createAction(GET_ACCOUNT_VIDEO_VIEWS_SUCCESS, (accountVideoViews, timespan, selectedProgramIds) => ({ accountVideoViews }));
export const getAccountDashboardData = (accountId) => {
    return function (dispatch, state, services) {
        return __awaiter(this, void 0, void 0, function* () {
            const { accountOverviewServices } = services;
            try {
                const dashboardData = yield Promise.all([
                    accountOverviewServices.getAccountInfo(accountId),
                    accountOverviewServices.getAccountPrograms(accountId),
                    accountOverviewServices.getAccountScenes(accountId)
                ]);
                dispatch(getAccountDashboardDataSuccess(dashboardData));
            }
            catch (err) {
                dispatch(reportError(err));
            }
        });
    };
};
export const getAccountVideoViews = (accountId, programIDs, timespan) => {
    return function (dispatch, state, services) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield services.accountOverviewServices.getAccountVideoViews(accountId, programIDs, timespan);
                dispatch(getAccountVideoViewsSuccess(res, timespan, programIDs));
            }
            catch (err) {
                dispatch(reportError(err));
            }
        });
    };
};
