import { parseLogicObject } from "../Logics/Logic";
import type { LogicJSON } from "../../../common/types/logic";
import type SceneNodeModel from "../../components/HubEditor/stories/diagramComponents/models/SceneNodeModel";
import type StoryNodeModel from "../../components/HubEditor/stories/diagramComponents/models/StoryNodeModel";
import type DecisionPointModel from "../../components/HubEditor/stories/diagramComponents/models/DecisionPointModel";
import type EndNodeModel from "../../components/HubEditor/stories/diagramComponents/models/EndNodeModel";
import memoizeOne from "memoize-one";
import type { Story } from "../../../common/types/story";
import { extractParentDpIds, extractSceneIdsAndDPsFromDPLogic } from "../Logics/DecisionPointLogic";

export enum StoryEditorContext {
    Story = "STORY",
    DecisionPoint = "DECISION_POINT",
    Scene = "SCENE",
    Program = "PROGRAM"
}

export enum DPLogicContainerWarningTypes {
    DeleteCondition = "DELETE_CONDITION",
    ChangeConditionValue = "CHANGE_CONDITION_VALUE"
}

export type HubStoryNodeModel = DecisionPointModel | SceneNodeModel | StoryNodeModel | EndNodeModel;

export function generateSceneNodeId(parentDPNodeId: string, ruleKey: string, sceneId: string): string {
    return `${parentDPNodeId}_${ruleKey}_${sceneId}`;
}

export function generateEndNodeId(parentDPNodeId: string, ruleKey: string): string {
    return `${parentDPNodeId}_${ruleKey}_${"endNode"}`;
}

export function getSceneIdFromSceneNodeId(sceneNodeId: string): string {
    return sceneNodeId.split("_")[2];
}

export const sceneActionTypes = {
    Before: "before",
    After: "after",
    InsteadOf: "insteadOf"
};

export enum SceneActionTypes {
    Before = "before",
    After = "after",
    InsteadOf = "insteadOf"
}

export function isSceneRemovable(prevDPObject: LogicJSON, nextDPObject: LogicJSON): boolean {
    return parseLogicObject(prevDPObject).getRules().length === 0 || parseLogicObject(nextDPObject).getRules().length === 0;
}

export function getMaxLongestRouteFromNodesMap(nodesMap: Map<string, HubStoryNodeModel>): number {
    let maxLongestRoute = 0;
    nodesMap.forEach((node) => {
        maxLongestRoute = node.getLongestRoute() > maxLongestRoute ? node.getLongestRoute() : maxLongestRoute;
    });
    return maxLongestRoute;
}

export const SCENE_ID_FOR_SCENE_NOT_EXISTS: string = "sceneNotExists";

export const SCENE_NAME_FOR_SCENE_NOT_EXISTS: string = "N/A";

export const RULE_KEY_FOR_DEFAULT_VALUE: string = "default";

export const REMOVE_SCENE_TOOLTIP_TEXT: string = "Can't remove scene";

export const getStoryIdToStoryName = memoizeOne((stories: { [storyId: string]: Story }) => {
    return Object.keys(stories).reduce((acc, storyId) => {
        acc[storyId] = stories[storyId].name;
        return acc;
    }, {});
});

export const calculateDisabledNodes = memoizeOne((mergeSessionActive: boolean, storyInitialDecisionPointId: string, storyDPLogicItems: { [dpId: string]: LogicJSON }, selectedNodeId: string) => {
    if (!mergeSessionActive) {
        return [];
    }
    const parentDpIds = extractParentDpIds(storyInitialDecisionPointId, storyDPLogicItems);
    const childrenDpIds = extractSceneIdsAndDPsFromDPLogic(storyDPLogicItems[selectedNodeId], storyDPLogicItems).participatingDPs;
    const nodeParent = parentDpIds.get(selectedNodeId) || new Set<string>();
    const nodeChildren = childrenDpIds || [];
    return Array.from(nodeParent).concat(nodeChildren);
});
