import traverse from "traverse";
import { createJsonHierarchyForVar } from "./utils";
import { LOGIC_DATA_TYPES, LOGIC_MEDIA_TYPES, CYCLIC_ERROR_MESSAGE, ASSET_TYPES } from "./consts";
import { getMappingTableNameFromValue, extractDataElementIdsFromLogic, getCreativeDataElementsUsedInNarrationsOverrides } from "./editorLogicUtils";
import { buildInputRules, getInputLogicType } from "./logixBuilder";
import { findDataElementByLogicValue, findDataElementById, findDataElementByName, getDataElementDisplayName, getDataElementId, getDerivedDataElementLogicId, getDataElementOrigin } from "../DataElements/DataElementsManager";
import StudioDataManager from "../DataElements/StudioDataManager";
import StateReaderUtils from "../common/StateReaderUtils";
let moment = require("moment-timezone");
import * as _ from "lodash";
import { DataElementOrigins } from "../../../common/types/dataElement";
/**
 * This function constructs a set of feed values, coherent with the stracture
 * @param dataElementsNamesAndValues Array<nameAndValue>: and object describing a data element display name and the value it should get
 *      ex: [{ name: "de1", value: "1"}, { name: "dateDe", value: "27/11/91" }]
 * @return {{dataElements: an object simulating a data feed with deeply nested object and their values
 *     ex: { userDevice: { type: "mac", location: "" } }
 * }}
 */
export function createDataFeed(dataElementsNamesAndValues) {
    let dataElementsJson = {};
    if (dataElementsNamesAndValues) {
        dataElementsJson = dataElementsNamesAndValues.reduce((acc, dePresetValue) => {
            createJsonHierarchyForVar(acc, dePresetValue.name, dePresetValue.value);
            return acc;
        }, {});
    }
    return { dataElements: dataElementsJson };
}
/**
 * Gets logic object, and returns all the data elements in which it depends.
 * Data elements of type 'derived' should NOT be returned, instead, THEIR dependent data elements should be returned.
 * @param input an object that includes logic.
 * @param derivedLogic the logic of the derived data elements
 * @param mappingTables a list of the project's mapping table
 * @param dataElements an array of all the data elements
 * @returns an array of objects, containing data elements ids, of the data elements which appear in the logic.
 */
export function extractDataElementsFromLogic(input, derivedLogic, mappingTables, dataElements) {
    let manager = new StudioDataManager({ derivedLogic, dataElements, mappingTables });
    manager.buildDerivedGraphFromLogic(input);
    if (manager.detectCycles().length > 0) {
        throw CYCLIC_ERROR_MESSAGE;
    }
    let participatingDataElementMap = extractDataElementsFromLogicStep(input, new Map(), derivedLogic, mappingTables, dataElements);
    addTimeZoneDataElement(participatingDataElementMap, dataElements);
    return Array.from(participatingDataElementMap.values());
}
function extractDataElementsFromLogicStep(input, accumulatedParticipatingDataElementMap, derivedLogic, mappingTables, dataElements) {
    // 1. Extract the data elements, and data table inputs (which are also data elements) which appear in the input
    let dataElementIds = extractDataElementIdsFromLogic(input, mappingTables);
    let dataElementsMap = dataElementIds.reduce((acc, deId) => {
        let dataElement = findDataElementById(deId, dataElements);
        dataElement = dataElement || findDataElementByName(deId, dataElements); // Backward compatibility
        if (dataElement) {
            acc.set(deId, dataElement);
        }
        return acc;
    }, new Map());
    // 2. split to derived and non derived data elements
    let { derivedDataElementMap, nonDerivedDataElementMap } = splitToDerivedAndNonDerivedMaps(dataElementsMap);
    accumulatedParticipatingDataElementMap.forEach((val, key) => nonDerivedDataElementMap.set(key, val));
    // 3. if there are no derived data elements, stop and return all the accumulated data elements.
    if (derivedDataElementMap.size === 0) {
        return nonDerivedDataElementMap;
    }
    // 4. for each derived data element, add its logic to an array.
    let participatingDerivedLogics = [];
    derivedDataElementMap.forEach((derivedDE) => {
        const derivedLogicId = getDerivedDataElementLogicId(derivedDE);
        participatingDerivedLogics.push(derivedLogic[derivedLogicId]);
    });
    // 5. recursively, extract all the data elements from the logic array
    return extractDataElementsFromLogicStep(participatingDerivedLogics, nonDerivedDataElementMap, derivedLogic, mappingTables, dataElements);
}
function splitToDerivedAndNonDerivedMaps(dataElementMap) {
    let derivedDataElementMap = new Map();
    let nonDerivedDataElementMap = new Map();
    dataElementMap.forEach((dataElement, dataElementId) => {
        if (getDataElementOrigin(dataElement) === DataElementOrigins.Derived) {
            derivedDataElementMap.set(dataElementId, dataElement);
        }
        else {
            nonDerivedDataElementMap.set(dataElementId, dataElement);
        }
    });
    return {
        derivedDataElementMap,
        nonDerivedDataElementMap
    };
}
export function extractMappingTableNames(input, mappingTables) {
    let tableData = {};
    if (mappingTables) {
        let tables = traverse(input).reduce((acc, node) => {
            if (mappingTables && node && node.type === LOGIC_DATA_TYPES.MappingTable) {
                let mappingTableName = getMappingTableNameFromValue(node);
                let mappingTable = mappingTables.find((mappingTable) => mappingTable.name === mappingTableName);
                if (mappingTable) {
                    acc[mappingTableName] = mappingTableName;
                }
            }
            return acc;
        }, {});
        tableData.tables = Object.keys(tables);
    }
    return tableData;
}
export function createTablesObject(mappingTables) {
    let tables = {};
    if (mappingTables) {
        mappingTables.forEach((table) => {
            tables[table.name] = { dtKey: table.decisionTablesLocation };
        });
    }
    return tables;
}
function addTimeZoneDataElement(dataElementMap, dataElements) {
    // Add TimeZone system data element if needed
    let hasDateType = false;
    dataElementMap.forEach((de) => (hasDateType = hasDateType || de.type === LOGIC_MEDIA_TYPES.Date));
    if (hasDateType) {
        const timeZoneDe = dataElements.find((de) => getDataElementId(de) === "HubSystem:TimeZone");
        if (timeZoneDe) {
            dataElementMap.set("HubSystem:TimeZone", timeZoneDe);
        }
    }
}
export function getDefaultValueForSystemDataElement(dataElementId) {
    switch (dataElementId) {
        case "HubSystem:now":
            return moment().format();
        case "HubSystem:TimeZone":
            return "America/New_York";
        default:
            return undefined;
    }
}
export function buildDerivedDataElements_Old(derivedElementsLogic, dataFields) {
    let derivedData = {};
    for (let derivedLogicId in derivedElementsLogic) {
        if (derivedElementsLogic.hasOwnProperty(derivedLogicId)) {
            // Find data elements
            const dataElement = (dataFields.dataElements || []).find((de) => getDerivedDataElementLogicId(de) === derivedLogicId);
            // Get data element display name from data element
            const deDisplayName = getDataElementDisplayName(dataElement);
            createJsonHierarchyForVar(derivedData, deDisplayName, {
                rules: buildInputRules(derivedElementsLogic[derivedLogicId], dataFields),
                type: getInputLogicType(derivedElementsLogic[derivedLogicId])
            });
        }
    }
    return {
        dataElements: derivedData
    };
}
export function buildDerivedDataElements(context) {
    return buildDerivedDataElementsFromLogic(null, context);
}
export function buildDerivedDataElementsFromLogic(logic, context) {
    let { derivedLogic, assets, dataElements } = context;
    const mappingTables = assets ? assets.filter((asset) => asset.type === ASSET_TYPES.mappingTable) : [];
    const derivedDataElements = dataElements ? dataElements.filter((dataElement) => dataElement.origin === DataElementOrigins.Derived) : [];
    context.mappingTables = mappingTables;
    let manager = new StudioDataManager(context);
    logic ? manager.buildDerivedGraphFromLogic(logic) : manager.buildDerivedGraph(derivedDataElements);
    let sortedIds = manager.sortDerivedByDependencies();
    return sortedIds.map((dataElementId) => {
        // Find data elements
        let dataElement = findDataElementById(dataElementId, derivedDataElements);
        dataElement = dataElement || findDataElementByName(dataElementId, derivedDataElements);
        const logicId = getDerivedDataElementLogicId(dataElement);
        // Get data element display name from data element
        const deDisplayName = getDataElementDisplayName(dataElement);
        return {
            name: deDisplayName,
            value: {
                rules: buildInputRules(derivedLogic[logicId], context),
                type: getInputLogicType(derivedLogic[logicId])
            }
        };
    });
}
/**
 *The function gets the params bellow and return object that for each scene contains all the logics that are being used in it (including derived Logic).
 * @param logicData logic object, where the first layer must be scene ids
 * @param dataElementsArr array of  data Elements objects
 * @param derivedLogic the logic of the derived data elements
 ** @return ByScene - logic Data included in each scene logic object, where the first layer must be scene ids
 */
function addDerivedLogicToAllLogicsPerSceneObject(logicData, dataElementsArr, derivedLogic) {
    let logicsByScene = {};
    for (let sceneId in logicData) {
        let derivedLogicsForSceneId = traverse(logicData[sceneId]).reduce(function (acc, node) {
            if (node && node.type === LOGIC_DATA_TYPES.DataElement) {
                const dataElement = findDataElementByLogicValue(node, dataElementsArr);
                if (dataElement && dataElement.origin === DataElementOrigins.Derived) {
                    let derivedLogicId;
                    let participatingDerivedLogic;
                    let derivedDataElementId;
                    if ((derivedLogicId = getDerivedDataElementLogicId(dataElement)) &&
                        (participatingDerivedLogic = derivedLogic[derivedLogicId]) &&
                        (derivedDataElementId = getDataElementId(dataElement))) {
                        acc[derivedDataElementId] = Object.assign({}, participatingDerivedLogic);
                    }
                }
            }
            return acc;
        }, {});
        logicsByScene[sceneId] = Object.assign(logicData[sceneId], derivedLogicsForSceneId);
    }
    return logicsByScene;
}
/**
 *The function returns for each data element id the array of scene that use it.
 * @param object args = {
 * logicData: logic object, where the first layer must be scene ids
 * dataElementsArr: array of  data Elements objects
 * derivedLogic: the logic of the derived data elements
 * mappingTables: a list of the project's mapping table
 * }
 * @return a map of data element -> list of scene ids in which it is used
 */
export function extractScenes(args) {
    let { wireframes, logicData, dataElementsArr, derivedLogic, mappingTables } = args;
    // add Derived Logics To Logics Per Scene Object
    let logicsByScene = addDerivedLogicToAllLogicsPerSceneObject(logicData, dataElementsArr, derivedLogic);
    let dataElementIdsMappedToScenesId = {};
    for (let sceneId in logicsByScene) {
        let deToScenesMap = traverse(logicsByScene[sceneId]).reduce(function (acc, node) {
            if (node) {
                if (node.type === LOGIC_DATA_TYPES.DataElement) {
                    const dataElement = findDataElementByLogicValue(node, dataElementsArr);
                    if (dataElement) {
                        let dataElementId = getDataElementId(dataElement);
                        acc[dataElementId] = sceneId;
                    }
                }
                else if (mappingTables && node.type === LOGIC_DATA_TYPES.MappingTable) {
                    let mappingTableName = getMappingTableNameFromValue(node);
                    let mappingTable = mappingTables.find((mappingTable) => mappingTable.name === mappingTableName);
                    if (mappingTable && mappingTable.mappingTableScheme) {
                        let inputs = mappingTable.mappingTableScheme.inputs;
                        if (inputs) {
                            inputs.forEach((input) => {
                                const dataElement = (dataElementsArr || []).find((de) => input.id === getDataElementId(de) || input.name === getDataElementId(de));
                                if (dataElement) {
                                    let dataElementId = getDataElementId(dataElement);
                                    acc[dataElementId] = sceneId;
                                }
                            });
                        }
                    }
                }
            }
            return acc;
        }, {});
        //add current acconulator (baced on current scene id) to general accomulator/
        for (let dataElementId in deToScenesMap) {
            let sceneArr = dataElementIdsMappedToScenesId[dataElementId] || [];
            sceneArr.push(sceneId);
            dataElementIdsMappedToScenesId[dataElementId] = sceneArr;
        }
    }
    //add data elements that are being used in scenes' narrations (including derived and their logic)
    dataElementIdsMappedToScenesId = getDataElementsUsedInNarrations(wireframes, wireframes.scenes, dataElementIdsMappedToScenesId);
    //add narration creative data elements that are being used in scenes' narrations overrides
    dataElementIdsMappedToScenesId = getCreativeDataElementsUsedInNarrationsOverrides(wireframes, dataElementIdsMappedToScenesId, true);
    return dataElementIdsMappedToScenesId;
}
function getDataElementsUsedInNarrations(wireframes, wireframeScenes, dataElementIdsMappedToScenesId) {
    function addDataElement(deId, sceneId) {
        if (!dataElementIdsMappedToScenesId[deId]) {
            dataElementIdsMappedToScenesId[deId] = [];
        }
        dataElementIdsMappedToScenesId[deId].push(sceneId);
    }
    Object.values(wireframeScenes).forEach((scene) => {
        scene.sceneParts.forEach((scenePart) => {
            scenePart.NarrationParts &&
                scenePart.NarrationParts.forEach((narrationPart) => {
                    let dataElementsIdsInUse = wireframes.narrations && StateReaderUtils.getNarrationTableDataElements(wireframes, narrationPart.id);
                    if (dataElementsIdsInUse) {
                        dataElementsIdsInUse = dataElementsIdsInUse.toArray();
                        dataElementsIdsInUse.forEach((deId) => {
                            const dataElement = findDataElementById(deId, wireframes.dataElements);
                            if (dataElement.origin === DataElementOrigins.Derived) {
                                let dataElementsInUseInDerived = getDataElementsUsedInDerived(wireframes.dataElements, dataElement, wireframes.logic.derived[dataElement.id]);
                                dataElementsInUseInDerived.forEach((id) => {
                                    addDataElement(id, scene.id);
                                });
                            }
                            addDataElement(deId, scene.id);
                        });
                    }
                });
        });
    });
    for (let deId in dataElementIdsMappedToScenesId) {
        dataElementIdsMappedToScenesId[deId] = _.uniq(dataElementIdsMappedToScenesId[deId]);
    }
    return dataElementIdsMappedToScenesId;
}
function getDataElementsUsedInDerived(allDataElements, derivedDataElement, derivedLogic) {
    return traverse(derivedLogic).reduce(function (acc, node) {
        if (node) {
            if (node.type === LOGIC_DATA_TYPES.DataElement) {
                const dataElement = findDataElementByLogicValue(node, allDataElements);
                if (dataElement) {
                    let dataElementId = getDataElementId(dataElement);
                    acc.push(dataElementId);
                }
            }
        }
        return acc;
    }, []);
}
