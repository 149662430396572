import type { IFrameworkEntity, onSaveArgs, Roles } from "../../types";
import type {
    GqlClientCreateBasicFeedDataElementMutationVariables,
    GqlClientCreateFeedDataElementInput,
    GqlClientDataElementSupportersFragment,
    GqlClientFeedDataElement,
    GqlClientUpdateBasicFeedDataElementMutationVariables,
    GqlClientUpdateFeedDataElementInput } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import {
    DataElementSupportersFragmentDoc,
    GqlClientFeedDataElementStatus,
    withCreateBasicFeedDataElement,
    withDeleteDataElement,
    withUpdateBasicFeedDataElement
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import type { DataDialogProps } from "../../../../components/framework/dialogs/DataElementDialog";
import type { ApolloClient, ApolloError } from "@apollo/client";
import type { CardData } from "../../../../components/framework/EntityCard";
import { getRole, initCardData } from "../../utils";
import { ColorPalette, STATUSES } from "../../defaultValues";
import type { withGqlMutationProps } from "../../../../components/framework/WithGqlMutations";

export const convertEnumStatusToString = (status: GqlClientFeedDataElementStatus): string => {
    switch (status) {
        case GqlClientFeedDataElementStatus.PENDING:
            return STATUSES[0];
        case GqlClientFeedDataElementStatus.APPROVED:
            return STATUSES[1];
        case GqlClientFeedDataElementStatus.REJECTED:
            return STATUSES[2];
        case GqlClientFeedDataElementStatus.MISSING:
            return STATUSES[3];
    }
};

export const convertStringStatusToEnum = (status: string): GqlClientFeedDataElementStatus => {
    switch (status) {
        case STATUSES[0]:
            return GqlClientFeedDataElementStatus.PENDING;
        case STATUSES[1]:
            return GqlClientFeedDataElementStatus.APPROVED;
        case STATUSES[2]:
            return GqlClientFeedDataElementStatus.REJECTED;
        case STATUSES[3]:
            return GqlClientFeedDataElementStatus.MISSING;
    }
};

const getSupporters = (dataElement: GqlClientFeedDataElement, client: ApolloClient<any>): string[] => {
    /** supporters for data elements:
     * scenes related to the data element
     * kpis that are related to those scenes
     * parent goals of those kpis
     * stories related to those scenes
     */
    let supporterIds = [];
    dataElement.scenes.forEach(({ id, __typename }) => {
        supporterIds.push(id);
        const sceneData: GqlClientDataElementSupportersFragment = client.readFragment({
            id: `${__typename}:${id}`,
            fragment: DataElementSupportersFragmentDoc
        });
        sceneData.kpis.forEach(({ id, goal }) => {
            supporterIds.push(id);
            supporterIds.push(goal.id);
        });
        sceneData.stories.forEach(({ id }) => supporterIds.push(id));
    });
    dataElement.audiences && supporterIds.push(...dataElement.audiences.map(({ id }) => id));
    return supporterIds;
};

const getCardData = (dataElement: GqlClientFeedDataElement, roles: Roles): CardData => {
    let dataElementData: CardData = initCardData(dataElement);
    dataElementData.contents = [
        { title: "Source:", descriptionText: dataElement.source ? `${dataElement.source}` : "None" },
        {
            title: "Status:",
            descriptionText: convertEnumStatusToString(dataElement.status),
            descriptionColor:
                dataElement.status === GqlClientFeedDataElementStatus.APPROVED
                    ? ColorPalette.GreenApproved
                    : dataElement.status === GqlClientFeedDataElementStatus.REJECTED
                        ? ColorPalette.RedRejected
                        : ColorPalette.GrayDescriptionText
        },
        { title: "Description:", descriptionText: dataElement.description ? `${dataElement.description}` : "None" }
    ];

    if (roles) {
        dataElementData.role = getRole(dataElement, roles);
        if (dataElement.scenes.length) {
            const sceneNames: string[] = dataElement.scenes.map((scene) => scene.name);
            dataElementData.contents.push({ title: "Used in scenes:", descriptionText: `${sceneNames.join("\n")}` });
        }
    }

    return dataElementData;
};

const onSave = (args: onSaveArgs<GqlClientCreateFeedDataElementInput & GqlClientUpdateFeedDataElementInput,
    GqlClientCreateBasicFeedDataElementMutationVariables, GqlClientUpdateBasicFeedDataElementMutationVariables>) => {
    const { data, createCB, updateCB, programVersionId, dryRun } = args;
    const { id, name, description, source, status, updated } = data;
    const isNew = !id;
    if (isNew) {
        const newDataElement: GqlClientCreateFeedDataElementInput = { name, description, source, status };
        createCB({ variables: { programVersionId, input: newDataElement } });
    }
    else {
        const updatedDataElement: GqlClientUpdateFeedDataElementInput = { id, name, description, source, status, updated };
        updateCB({ variables: { input: updatedDataElement, dryRun } });
    }
};
const DataElement: IFrameworkEntity<DataDialogProps> = {
    mutations: {
        create: withCreateBasicFeedDataElement<withGqlMutationProps<DataDialogProps>>({
            name: "createDataElement",
            options: ({ setMutationResult, setError }) => ({
                onCompleted: ({ createBasicFeedDataElement }) => {
                    setMutationResult(createBasicFeedDataElement.result);
                },
                onError: (error: ApolloError) => {
                    setError(error);
                }
            })
        }),
        update: withUpdateBasicFeedDataElement<withGqlMutationProps<DataDialogProps>>({
            name: "updateDataElement",
            options: ({ setMutationResult, setError }) => ({
                onCompleted: ({ updateBasicFeedDataElement }) => {
                    setMutationResult(updateBasicFeedDataElement.result);
                },
                onError: (error: ApolloError) => {
                    setError(error);
                }
            })
        }),
        delete: withDeleteDataElement<withGqlMutationProps<DataDialogProps>>({
            name: "deleteDataElement"
        })
    },
    getSupporters,
    getCardData,
    onSave
};

export default DataElement;
