import type { AnyEntitiesByContextById, AnyEntity, AnyEntityContextPair, GetDependencyIdFunction } from "./baseEntity";
import { BaseEntity } from "./baseEntity";
import StateReaderUtils from "../common/StateReaderUtils";
import type { GetValidationResultFunction, Issue, ValidationResult } from "../validations/validationManager";
import ValidationManager, { IssueCodes, IssueSeverity } from "../validations/validationManager";
import type { Collector, CollectorArgs, EntityInstance, IEntity } from "./definitions";
import { EntityTypes } from "./definitions";
import { LogicContainers } from "../../../common/commonConst";
import type { ChangeDetail, DiffResult, GetDiffResultFunc } from "../versionDiff/diffManager";
import DiffManager, { ChangeType } from "../versionDiff/diffManager";
import { diffEntities, getStoryPropertyCollectionId, updateDiffResultWithChanges } from "../versionDiff/diffUtils";
import type { Story, StoryRatioAndQualityOption } from "../../../common/types/story";
import { featureFlagConst } from "@sundaysky/smartvideo-hub-config";
import LogicEntity from "./logicEntity";
import { shouldUseVideoRatioAndQualityLogic } from "../Logics/StoryRatioAndQualityLogic";
import { ProgramType } from "../../../common/types/program";

const { CRM_STORY_VIDEO_RATIO_QUALITY_LOGIC, ADS_STORY_VIDEO_RATIO_QUALITY_DEFINITION } = featureFlagConst;

export type RatioAndQualityOptionIssueDetails = { ratioValidationMessage?: string, qualityValidationMessage?: string };

export default class StoryEntity extends BaseEntity implements IEntity {
    constructor() {
        super(EntityTypes.STORY);
    }

    collector: Collector = (args: CollectorArgs): EntityInstance[] => {
        const { context, previousContext } = args;
        let stories: EntityInstance[] = [];

        const storiesVersion = StateReaderUtils.getStoriesVersion(context.state, context.projectName, context.stage, context.version);
        const currentStories = StateReaderUtils.getProjectWireframesStories(context.state, context.projectName, context.stage, context.version);
        const previousStories = previousContext && StateReaderUtils.getProjectWireframesStories(previousContext.state, previousContext.projectName, previousContext.stage, previousContext.version);
        const storiesMap: AnyEntitiesByContextById = this.entityObjectsToMap(previousStories, currentStories);
        const programType = StateReaderUtils.getProjectType(context.state, context.projectName);
        const isCRMStoryVideoRatioQualityLogicFFEnabled = StateReaderUtils.isFeatureEnable(context.state, CRM_STORY_VIDEO_RATIO_QUALITY_LOGIC);
        const useVideoRatioAndQualityLogic = shouldUseVideoRatioAndQualityLogic(programType, isCRMStoryVideoRatioQualityLogicFFEnabled ? [CRM_STORY_VIDEO_RATIO_QUALITY_LOGIC] : []);
        const includeAdsAdditionalVideoDefinitions = programType === ProgramType.Ads && StateReaderUtils.isFeatureEnable(context.state, ADS_STORY_VIDEO_RATIO_QUALITY_DEFINITION);

        storiesMap.forEach((storiesPair: AnyEntityContextPair, storyId: string) => {
            stories.push({
                id: storyId,
                validate: (prevValidationResult: ValidationResult, getValidationResultFunction: GetValidationResultFunction): ValidationResult => {
                    return this.doValidate(currentStories[storyId], getValidationResultFunction, storiesVersion, useVideoRatioAndQualityLogic, includeAdsAdditionalVideoDefinitions);
                },
                diff: (getDiffResult: GetDiffResultFunc): DiffResult => {
                    return this.doDiff(getDiffResult, storiesPair.previousEntity, storiesPair.currentEntity, storiesVersion, useVideoRatioAndQualityLogic, includeAdsAdditionalVideoDefinitions);
                }
            });
        });
        return stories;
    };

    doValidate = (
        story: Story,
        getValidationResult: GetValidationResultFunction,
        storiesVersion: number,
        useVideoRatioAndQualityLogic: boolean,
        includeAdsAdditionalVideoDefinitions: boolean
    ): ValidationResult => {
        const type: EntityTypes = this.getType();
        const id: string = story.id;
        let storyValidationResult: ValidationResult = { type, id, issues: [], name: story.name };

        // check scenes validity
        if (story.participatingSceneIds) {
            const getDependencyId: GetDependencyIdFunction = (dependency) => dependency;
            let issue: Issue = this.getDependenciesIssue(story.participatingSceneIds, EntityTypes.SCENE, getDependencyId, getValidationResult, IssueCodes.STORY_SCENE_ERROR);
            if (issue) {
                storyValidationResult.issues.push(issue);
                storyValidationResult.severity = issue.severity;
            }
        }

        // check decisionPoints validity
        if (story.participatingDPs) {
            const getDependencyId: GetDependencyIdFunction = (dependency) => story.id + "_" + dependency;
            let issue: Issue = this.getDependenciesIssue(story.participatingDPs, EntityTypes.LOGIC, getDependencyId, getValidationResult, IssueCodes.STORY_LOGIC_ERROR);
            if (issue) {
                storyValidationResult.issues.push(issue);
                storyValidationResult.severity = ValidationManager.getHighestSeverity([storyValidationResult.severity, issue.severity]);
            }
        }

        // check soundtrack logic validity
        if (storiesVersion) {
            const getDependencyId: GetDependencyIdFunction = (dependency) => dependency;
            let issue: Issue = this.getDependenciesIssue(
                [getStoryPropertyCollectionId(story.id, LogicContainers.Soundtrack)],
                EntityTypes.LOGIC,
                getDependencyId,
                getValidationResult,
                IssueCodes.STORY_SOUNDTRACK_LOGIC_ERROR
            );
            if (issue) {
                storyValidationResult.issues.push(issue);
                storyValidationResult.severity = ValidationManager.getHighestSeverity([storyValidationResult.severity, issue.severity]);
            }
        }

        // check background asset validity
        if (storiesVersion) {
            const getDependencyId = (dependency) => dependency;
            let issue = this.getDependenciesIssue(
                [getStoryPropertyCollectionId(story.id, LogicContainers.BackgroundAsset)],
                EntityTypes.LOGIC,
                getDependencyId,
                getValidationResult,
                IssueCodes.STORY_BACKGROUND_ASSET_LOGIC_ERROR
            );
            if (issue) {
                storyValidationResult.issues.push(issue);
                storyValidationResult.severity = ValidationManager.getHighestSeverity([storyValidationResult.severity, issue.severity]);
            }
        }

        // check ratio and quality logic validity
        if (useVideoRatioAndQualityLogic) {
            const getDependencyId: GetDependencyIdFunction = (dependency) => getStoryPropertyCollectionId(story.id, dependency);
            let issue: Issue = this.getDependenciesIssue(
                [LogicEntity.VIDEO_RATIO_QUALITY_LOGIC_VALIDATION_ID],
                EntityTypes.LOGIC,
                getDependencyId,
                getValidationResult,
                IssueCodes.STORY_VIDEO_RATIO_QUALITY_LOGIC_ERROR
            );
            if (issue) {
                storyValidationResult.issues.push(issue);
                storyValidationResult.severity = ValidationManager.getHighestSeverity([storyValidationResult.severity, issue.severity]);
            }
        }

        // check ads additional video definitions validity
        if (includeAdsAdditionalVideoDefinitions) {
            const emptyMultiSelectErrorMessage = "At least one value must be selected";
            const emptySelectErrorMessage = "Value must not be blank";
            if (!(Array.isArray(story.durationOptions) && story.durationOptions.length > 0)) {
                const issue: Issue = {
                    code: IssueCodes.STORY_VIDEO_DURATION_OPTIONS_ERROR,
                    severity: IssueSeverity.ERROR,
                    details: { message : emptyMultiSelectErrorMessage }
                };
                storyValidationResult.issues.push(issue);
                storyValidationResult.severity = ValidationManager.getHighestSeverity([storyValidationResult.severity, issue.severity]);
            }
            if (!(Array.isArray(story.numberOfProducts) && story.numberOfProducts.length > 0)) {
                const issue: Issue = {
                    code: IssueCodes.STORY_VIDEO_NUM_OF_PRODUCT_ERROR,
                    severity: IssueSeverity.ERROR,
                    details: { message : emptyMultiSelectErrorMessage }
                };
                storyValidationResult.issues.push(issue);
                storyValidationResult.severity = ValidationManager.getHighestSeverity([storyValidationResult.severity, issue.severity]);
            }

            let isRatioAndQualityOptionsValid = true;
            let ratioAndQualityOptionsIssueDetails: RatioAndQualityOptionIssueDetails[];
            if (Array.isArray(story.ratioAndQualityOptions) && story.ratioAndQualityOptions.length > 0) {
                ratioAndQualityOptionsIssueDetails = story.ratioAndQualityOptions.map((ratioAndQualityOption: StoryRatioAndQualityOption) => {
                    let result: RatioAndQualityOptionIssueDetails = {};
                    if (!ratioAndQualityOption.ratio) {
                        isRatioAndQualityOptionsValid = false;
                        result.ratioValidationMessage = emptySelectErrorMessage;
                    }
                    if (!ratioAndQualityOption.quality) {
                        isRatioAndQualityOptionsValid = false;
                        result.qualityValidationMessage = emptySelectErrorMessage;
                    }
                    return result;
                });
            }
            else {
                isRatioAndQualityOptionsValid = false;
                ratioAndQualityOptionsIssueDetails = [{
                    ratioValidationMessage: emptySelectErrorMessage,
                    qualityValidationMessage: emptySelectErrorMessage
                }];
            }
            if (!isRatioAndQualityOptionsValid) {
                const issue: Issue = {
                    code: IssueCodes.STORY_VIDEO_RATIO_QUALITY_OPTIONS_ERROR,
                    severity: IssueSeverity.ERROR,
                    details: ratioAndQualityOptionsIssueDetails
                };
                storyValidationResult.issues.push(issue);
                storyValidationResult.severity = ValidationManager.getHighestSeverity([storyValidationResult.severity, issue.severity]);
            }
        }

        return storyValidationResult;
    };

    doDiff = (
        getDiffResult: GetDiffResultFunc,
        previousStory: AnyEntity,
        currentStory: AnyEntity,
        storiesVersion: number,
        useVideoRatioAndQualityLogic: boolean,
        includeAdsAdditionalVideoDefinitions: boolean
    ): DiffResult => {
        const changeType: ChangeType = diffEntities(previousStory, currentStory);
        const storyId = (currentStory && currentStory.id) || previousStory.id;
        let result: DiffResult = {
            type: this.getType(),
            id: storyId,
            name: (currentStory && currentStory.name) || previousStory.name,
            changeType: changeType,
            changes: [],
            isShown: changeType !== ChangeType.None
        };

        let changeList: ChangeType[] = [];
        const getDependencyId: GetDependencyIdFunction = (dependency) => dependency;

        if (storiesVersion) {
            const settingsChange = this.getPropertyChanges(storyId, previousStory, currentStory, "activateCaching", "Caching", EntityTypes.SETTINGS);
            if (settingsChange) {
                result.changes.push(settingsChange);
                changeList.push(settingsChange.changeType);
            }

            //Ads additional definitions
            if (includeAdsAdditionalVideoDefinitions) {
                const durationOptionsChange = this.getPropertyChanges(storyId, previousStory, currentStory, "durationOptions", "Duration options", EntityTypes.SETTINGS);
                if (durationOptionsChange) {
                    result.changes.push(durationOptionsChange);
                    changeList.push(durationOptionsChange.changeType);
                }

                const numberOfProductsChange = this.getPropertyChanges(storyId, previousStory, currentStory, "numberOfProducts", "Number of products", EntityTypes.SETTINGS);
                if (numberOfProductsChange) {
                    result.changes.push(numberOfProductsChange);
                    changeList.push(numberOfProductsChange.changeType);
                }
                const ratioAndQualityOptionsChange = this.getPropertyChanges(storyId, previousStory, currentStory, "ratioAndQualityOptions", "Ratio and quality options", EntityTypes.SETTINGS);
                if (ratioAndQualityOptionsChange) {
                    result.changes.push(ratioAndQualityOptionsChange);
                    changeList.push(ratioAndQualityOptionsChange.changeType);
                }
            }

            //Ratio and quality
            if (useVideoRatioAndQualityLogic) {
                const ratioAndQualityChanges = this.addLogicPropertyChangeToResult(storyId, LogicEntity.VIDEO_RATIO_QUALITY_LOGIC_VALIDATION_ID, getDependencyId, getDiffResult);
                if (ratioAndQualityChanges?.children?.[0]) {
                    ratioAndQualityChanges.children[0].type = EntityTypes.SETTINGS;
                    result.changes.push(ratioAndQualityChanges);
                    changeList.push(ratioAndQualityChanges.changeType);
                }
            }

            const overviewChange = this.getPropertyChanges(storyId, previousStory, currentStory, "description", "Overview", EntityTypes.OVERVIEW);
            if (overviewChange) {
                result.changes.push(overviewChange);
                changeList.push(overviewChange.changeType);
            }

            //DecisionPoint
            const decisionPointChanges: ChangeDetail = this.addLogicPropertyChangeToResult(storyId, LogicContainers.DecisionPoint, getDependencyId, getDiffResult);
            if (decisionPointChanges) {
                result.changes.push(decisionPointChanges);
                changeList.push(decisionPointChanges.changeType);
            }

            //Soundtrack
            const soundtrackChanges = this.addLogicPropertyChangeToResult(storyId, LogicContainers.Soundtrack, getDependencyId, getDiffResult);
            if (soundtrackChanges) {
                result.changes.push(soundtrackChanges);
                changeList.push(soundtrackChanges.changeType);
            }

            //Background
            const backgroundChanges = this.addLogicPropertyChangeToResult(storyId, LogicContainers.BackgroundAsset, getDependencyId, getDiffResult);
            if (backgroundChanges) {
                result.changes.push(backgroundChanges);
                changeList.push(backgroundChanges.changeType);
            }
        }
        else {
            if (result.changeType === ChangeType.None) {
                const change: ChangeDetail = this.getDependenciesDiff([result.id], EntityTypes.LOGIC, getDependencyId, getDiffResult, true);
                return updateDiffResultWithChanges(result, change);
            }
        }

        if (result.changeType === ChangeType.None) {
            result.changeType = DiffManager.getChangeType(changeList, false);
        }
        if (result.changeType !== ChangeType.None) {
            result.isShown = true;
        }
        return result;
    };

    private getPropertyChanges = (storyId: string, previousStory: AnyEntity, currentStory: AnyEntity, propertyName: string, propertyHeader: string, entityType: EntityTypes): ChangeDetail => {
        const propertyChange: ChangeType = diffEntities(previousStory ? previousStory[propertyName] : undefined, currentStory ? currentStory[propertyName] : undefined);
        if (propertyChange && propertyChange !== ChangeType.None) {
            return {
                changeType: propertyChange,
                details: "",
                children: [
                    {
                        type: entityType,
                        id: getStoryPropertyCollectionId(storyId, propertyName),
                        name: propertyHeader,
                        changeType: propertyChange,
                        changes: [],
                        isShown: true
                    }
                ]
            };
        }
    };

    private addLogicPropertyChangeToResult = (storyId: string, propertyName: string, getDependencyId: GetDependencyIdFunction, getDiffResult: GetDiffResultFunc): ChangeDetail => {
        const logicPropertyChange: ChangeDetail = this.getDependenciesDiff([getStoryPropertyCollectionId(storyId, propertyName)], EntityTypes.LOGIC, getDependencyId, getDiffResult, true);
        if (logicPropertyChange) {
            if (logicPropertyChange.children && logicPropertyChange.children.length > 0) {
                logicPropertyChange.children[0].isShown = logicPropertyChange.changeType !== ChangeType.None;
                return logicPropertyChange;
            }
        }
    };
}
