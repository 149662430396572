import { UPDATE_PROJECT_APIKEY_ENDPOINT } from "./previewActions";
function previewReducer(state, action) {
    switch (action.type) {
        case UPDATE_PROJECT_APIKEY_ENDPOINT: {
            const { endPoint, apiKeys, renderer3EndPoint } = action.payload;
            return Object.assign({}, state, {
                endPoint,
                apiKeys,
                renderer3EndPoint
            });
        }
        default:
            return state;
    }
}
export default previewReducer;
