import type { IFrameworkEntity, onSaveArgs, Roles } from "../../types";
import type {
    GqlClientChannel,
    GqlClientChannelSupportersFragment,
    GqlClientCreateChannelInput,
    GqlClientCreateChannelMutationVariables,
    GqlClientUpdateChannelInput,
    GqlClientUpdateChannelMutationVariables } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import {
    ChannelSupportersFragmentDoc,
    withCreateChannel,
    withDeleteChannel,
    withUpdateChannel
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import type { ChannelDialogProps } from "../../../../components/framework/dialogs/ChannelDialog";
import type { ApolloClient, ApolloError } from "@apollo/client";
import type { CardData } from "../../../../components/framework/EntityCard";
import { getPersonalizationDescriptionOrUsedInScenes, getPersonalizationIconsForDescription, getPersonalizationIconsForHeader, getRole, initCardData } from "../../utils";
import { ColorPalette } from "../../defaultValues";
import type { withGqlMutationProps } from "../../../../components/framework/WithGqlMutations";

const onSave = (args: onSaveArgs<GqlClientCreateChannelInput & GqlClientUpdateChannelInput, GqlClientCreateChannelMutationVariables, GqlClientUpdateChannelMutationVariables>): void => {
    const { data, createCB, updateCB, programVersionId, dryRun } = args;
    const { id, name, status, platforms, updated } = data;
    const isNew: boolean = !id;
    if (isNew) {
        const newChannel: GqlClientCreateChannelInput = { name, status, platforms };
        createCB({ variables: { programVersionId, input: newChannel } });
    }
    else {
        const updatedInput: GqlClientUpdateChannelInput = { id, name, status, platforms, updated };
        updateCB({ variables: { input: updatedInput, dryRun } });
    }
};

const getSupporters = (channel: GqlClientChannel, client: ApolloClient<any>) => {
    /** supporters for channels:
     * stories related to the channel
     * scenes that uses channel as personalization
     * kpis that are related to those scenes
     * parent goals of those kpis
     * additional stories that are related to those scenes
     */
    let supporterIds = [];
    channel.stories.forEach(({ id }) => supporterIds.push(id));
    channel.channelPersonalizations.forEach(({ scene }) => {
        supporterIds.push(scene.id);
        const sceneData: GqlClientChannelSupportersFragment = client.readFragment({
            id: `${scene.__typename}:${scene.id}`,
            fragment: ChannelSupportersFragmentDoc
        });
        sceneData.kpis.forEach(({ id, goal }) => {
            supporterIds.push(id);
            supporterIds.push(goal.id);
        });
        sceneData.stories.forEach(({ id }) => supporterIds.push(id));
    });
    return supporterIds;
};

const getCardData = (channel: GqlClientChannel, roles: Roles): CardData => {
    let channelData: CardData = initCardData(channel);
    channelData.contents = [
        {
            title: "Status:",
            descriptionText: `${channel.status}`,
            descriptionColor: channel.status.includes("Approved") ? ColorPalette.GreenApproved : channel.status.includes("Rejected") ? ColorPalette.RedRejected : ColorPalette.GrayDescriptionText
        },
        { title: "Platform:", descriptionText: channel.platforms ? `${channel.platforms.join(", ")}` : "None" }
    ];

    if (roles) {
        channelData.role = getRole(channel, roles);
        if (channel.channelPersonalizations.length) {
            let uniquePersonalizationTypes: Set<string> = new Set([]);
            channel.channelPersonalizations.forEach((pers) => pers.type.forEach((type) => uniquePersonalizationTypes.add(type)));

            let personalizationTypes: string[] = Array.from(uniquePersonalizationTypes);
            channelData.header.icons = getPersonalizationIconsForHeader(personalizationTypes);
            personalizationTypes.length && channelData.contents.push({ title: "Personalization type:", descriptionIcons: getPersonalizationIconsForDescription(personalizationTypes) });
            channelData.contents.push(getPersonalizationDescriptionOrUsedInScenes(channel.channelPersonalizations, roles.leadId));
        }
    }

    return channelData;
};

const Channel: IFrameworkEntity<ChannelDialogProps> = {
    mutations: {
        create: withCreateChannel<withGqlMutationProps<ChannelDialogProps>>({
            name: "createChannel",
            options: ({ setMutationResult, setError }) => ({
                onCompleted: ({ createChannel }) => {
                    setMutationResult(createChannel.result);
                },
                onError: (error: ApolloError) => {
                    setError(error);
                }
            })
        }),
        update: withUpdateChannel<withGqlMutationProps<ChannelDialogProps>>({
            name: "updateChannel",
            options: ({ setMutationResult, setError }) => ({
                onCompleted: ({ updateChannel }) => {
                    setMutationResult(updateChannel.result);
                },
                onError: (error: ApolloError) => {
                    setError(error);
                }
            })
        }),
        delete: withDeleteChannel<withGqlMutationProps<ChannelDialogProps>>({
            name: "deleteChannel"
        })
    },
    getSupporters,
    getCardData,
    onSave
};

export default Channel;
