import moment from "moment";

const durationToDisplayTimeFromNow = 2 * 24 * 60 * 60 * 1000;
const timeDateFormat = "YYYY MM DD hh:mm:ss a";
export const readableDateFormat = "DD/MM/YYYY";
const readableTimeFormat = "HH:mm:ss";
const readableTimeDateFormat = "DD/MM/YYYY \u00A0 HH:mm:ss";
const readableEditorTimeFormat = "HH:mm";
const readableEditorTimeDateFormat = "MM/DD/YYYY HH:mm";
const secondsDurationFormat = "ss:SS";
const minutesDurationFormat = "mm:ss:SS";
const minutesDurationNoMiliFormat = "mm:ss";
export const designSystemReadableTimeFormat = "h:mm A";
export const designSystemReadableDateAndYearFormat = "MMM D, YYYY";
export const designSystemReadableDateFormat = "MMM D";
export const reportsPageDateFormat = "DD-MMM-yyyy \u00A0 HH:mm:ss";
export const DETAILED_REPORTS_DATE_FORMAT = "YYYY-MM-DD";

export class TimeUtils {
    static getTimeDate(timestamp: moment.MomentInput, disableFromNow: boolean) {
        // @ts-ignore
        if (!disableFromNow && moment().format("x") - timestamp < durationToDisplayTimeFromNow) {
            return moment(timestamp).fromNow();
        }
        else {
            return moment(timestamp).format(timeDateFormat);
        }
    }

    static getReadableDuration = (milliseconds: number, format: string): string => {
        const duration = moment.duration(milliseconds);
        return moment.utc(duration.as("milliseconds")).format(format).toString();
    };

    static getReadableTime(timestamp: moment.MomentInput) {
        return moment(timestamp).format(readableTimeFormat);
    }

    static getReadableDate(timestamp: moment.MomentInput) {
        return moment(timestamp).format(readableDateFormat);
    }

    static getReadableTimeDate(timestamp: moment.MomentInput) {
        return moment(timestamp).format(readableTimeDateFormat);
    }
    static getReportsPageDateFormats(timestamp: moment.MomentInput) {
        return moment(timestamp).format(reportsPageDateFormat);
    }

    static getEditorReadableTime(timestamp: moment.MomentInput) {
        return moment(timestamp).format(readableEditorTimeFormat);
    }

    static getEditorReadableTimeDate(timestamp: moment.MomentInput) {
        return moment(timestamp).format(readableEditorTimeDateFormat);
    }

    static getReadableTimeDateWithTodayText(timestamp: moment.MomentInput) {
        if (moment(timestamp).isSame(moment(), "day")) {
            return `Today at ${moment(timestamp).format(readableEditorTimeFormat)}`;
        }
        return moment(timestamp).format(readableEditorTimeDateFormat);
    }

    static getReadableSecondsDuration = (milliseconds: number): string => {
        return TimeUtils.getReadableDuration(milliseconds, secondsDurationFormat);
    };

    static getReadableMinutesDuration = (milliseconds: number): string => {
        return TimeUtils.getReadableDuration(milliseconds, minutesDurationFormat);
    };

    static getReadableMinutesNoMiliDuration = (milliseconds: number): string => {
        return TimeUtils.getReadableDuration(milliseconds, minutesDurationNoMiliFormat);
    };

    static compare(timeStampA: string, timeStampB: string, format?: string): number {
        const compareFormat = format ? format : readableTimeDateFormat;
        const dateA = moment(timeStampA, compareFormat).valueOf();
        const dateB = moment(timeStampB, compareFormat).valueOf();
        return dateA - dateB;
    }

    // This readable time date was created as part of the new branding (2022) and creation of the deisgn system.
    // This readable time date should probably be the default formatter to pick.
    static getDesignSystemReadableTimeDate(
        timestamp: moment.MomentInput,
        ignoreTime = false
    ) {
        if (!timestamp) return "";

        const momentFromStamp = moment(timestamp);
        let readableDate: string;

        if (momentFromStamp.isSame(moment(), "day")) {
            readableDate = "Today";
        }
        else if (momentFromStamp.isSame(moment().subtract(1, "days").startOf("day"), "day")) {
            readableDate = "Yesterday";
        }
        else if (momentFromStamp.isSame(moment().add(1, "days").startOf("day"), "day")) {
            readableDate = "Tomorrow";
        }
        else {
            if (momentFromStamp.isSame(moment(), "year"))
                readableDate = momentFromStamp.format(designSystemReadableDateFormat);
            else
                readableDate = momentFromStamp.format(designSystemReadableDateAndYearFormat);
        }
        if (ignoreTime) {
            return readableDate;
        }
        const readableTime = momentFromStamp.format(designSystemReadableTimeFormat);
        return `${readableDate}, ${readableTime}`;
    }
}

export default TimeUtils;
