import { v4 as uuid } from "uuid";
import { LOGIC_TYPE } from "../vlx/consts";
import { getAllValues, parseLogicObject } from "./Logic";
import type { DecisionPointValue, LogicJSON, Rule } from "../../../common/types/logic";
import { unionSets } from "../../../common/generalUtils";

type participatingElements = {
    participatingSceneIds: string[];
    participatingDPs: string[];
    numOfOccurrencesPerDP: Map<string, number>;
};

/**
 * return actions possible on Decision Point Logic Value
 * @param decoratedDPLogicValue - decorated decision Point Logic Value (as it is in DB and redax store)
 *
 */
export function buildDecisionPointLogicValue(DPLogicValue) {
    return createDecisionPointLogicValue(DPLogicValue.sceneId, DPLogicValue.nextDPLogicId);
}

/**
 * return actions possible on Decision Point Logic Value
 * @param notDecoratedDPLogicValue - NOT decorated decision Point Logic Value (used for working with LogicContainer)
 *
 */
export function buildAndDecorateDecisionPointLogicValue(notDecoratedDPLogicValue) {
    return createDecisionPointLogicValue(notDecoratedDPLogicValue, null);
}

export function createDecisionPointLogicValue(sceneId, nextDPLogicId) {
    let _sceneId = sceneId;
    let _nextDPLogicId = nextDPLogicId || uuid();
    return {
        getSceneId: function() {
            return _sceneId;
        },
        getNextDPLogicId: function() {
            return _nextDPLogicId;
        },
        updateSceneId: function(sceneIdStr) {
            _sceneId = sceneIdStr;
        },
        updateNextDPLogicId: function(nextDPLogicIdStr) {
            _nextDPLogicId = nextDPLogicIdStr || uuid();
        },
        serializeDecisionPointLogicValue: function() {
            return {
                nextDPLogicId: _nextDPLogicId,
                sceneId: _sceneId
            };
        },
        serializeNotDecoratedDecisionPointLogicValue: function() {
            return _sceneId;
        }
    };
}

/**
 * The function gets decision point logic object and replace all old Decision Point Id with new Decision Point Id
 * @param {LogicJSON} DP logicObj
 * @param {string} oldDecisionPointId
 * @param {string} newDecisionPointId
 * @return {LogicJSON} updated logic object
 */
export function replaceDPLogicIdWithOther(logicObj: LogicJSON, oldDecisionPointId: string, newDecisionPointId: string): LogicJSON {
    let logic = parseLogicObject(logicObj);
    let newRules = logic.getRules().map((rule: Rule) => {
        if (rule.value && buildDecisionPointLogicValue(rule.value).getNextDPLogicId() === oldDecisionPointId) {
            let newValue = buildDecisionPointLogicValue(rule.value);
            newValue.updateNextDPLogicId(newDecisionPointId);
            let newRule: Rule = { ...rule, value: newValue.serializeDecisionPointLogicValue() };
            return newRule;
        }
        else {
            return rule;
        }
    });
    logic.setRules(newRules);
    let defaultValue = logic.getDefaultValue();
    if (defaultValue && buildDecisionPointLogicValue(defaultValue).getNextDPLogicId() === oldDecisionPointId) {
        let newDefaultValue = buildDecisionPointLogicValue(defaultValue);
        newDefaultValue.updateNextDPLogicId(newDecisionPointId);
        logic.setDefaultValue(newDefaultValue.serializeDecisionPointLogicValue());
    }
    return logic.serializeLogicData();
}

/**
 * The function gets decision point logic object and replace all old Decision Point Id with new Decision Point Id
 * @param {LogicJSON} DP logicObj
 * @param {string} decisionPointId
 * @return {boolean} isDPLogicItemContainDPId
 */
export function isDPLogicItemContainDPId(logicObj: LogicJSON, decisionPointId: string): boolean {
    let nextDPPointsIds = extractUniqueNextDPPointsIdsFromDPLogic(logicObj);
    return nextDPPointsIds.has(decisionPointId);
}

//TODO generalize recursive transition on all DPLogic in story.
export function extractSceneIdsAndDPsFromDPLogic(logicObj: LogicJSON, allDPLogicInStory): participatingElements {
    let logic = parseLogicObject(logicObj);

    if (logic.getOutputType() !== LOGIC_TYPE.NextScene) {
        return { participatingSceneIds: [], participatingDPs: [], numOfOccurrencesPerDP: new Map<string, number>() };
    }

    const getLogic = (dpId: string) => allDPLogicInStory[dpId];
    const participatingScenes = new Set<string>();
    const visitedDPs = new Map<string, number>();

    extractSceneIdsFromDPLogicStep(logicObj, participatingScenes, visitedDPs, getLogic);
    return { participatingSceneIds: Array.from(participatingScenes), participatingDPs: Array.from(visitedDPs.keys()), numOfOccurrencesPerDP: visitedDPs };
}

function extractSceneIdsFromDPLogicStep(logicObj: LogicJSON, participatingScenes: Set<string>, visitedDPs: Map<string, number>, getLogic: (dpId: string) => LogicJSON) {
    let values = getAllValues(logicObj);

    values.forEach((value: DecisionPointValue) => {
        if (value) {
            if (value.sceneId) {
                // value doesn't necessary have sceneId. This is a valid state in our system (not for the user)
                participatingScenes.add(value.sceneId);
            }
            if (!visitedDPs.has(value.nextDPLogicId)) {
                visitedDPs.set(value.nextDPLogicId, 1);
                extractSceneIdsFromDPLogicStep(getLogic(value.nextDPLogicId), participatingScenes, visitedDPs, getLogic);
            }
            else {
                visitedDPs.set(value.nextDPLogicId, visitedDPs.get(value.nextDPLogicId) + 1);
            }
        }
    });
}

export function extractUniqueNextDPPointsIdsFromDPLogic(logicObj: LogicJSON): Set<string> {
    let logic = parseLogicObject(logicObj);
    let logicNextDPPointsIds = new Set<string>();
    logic.getValues().forEach((value) => {
        if (value) {
            logicNextDPPointsIds.add(buildDecisionPointLogicValue(value).getNextDPLogicId());
        }
    });
    return logicNextDPPointsIds;
}

export function extractParentDpIds(dpId: string, allDPLogicInStory): Map<string, Set<string>> {
    const getLogic = (dpId: string) => allDPLogicInStory[dpId];
    let logic = parseLogicObject(getLogic(dpId));
    const dpParents = new Map<string, Set<string>>();
    if (logic.getOutputType() !== LOGIC_TYPE.NextScene) {
        return dpParents;
    }
    dpParents.set(dpId, new Set<string>());
    extractParentDpIdsStep(dpId, dpParents, getLogic);
    return dpParents;
}

function extractParentDpIdsStep(dpId: string, dpParents: Map<string, Set<string>>, getLogic: (dpId: string) => LogicJSON) {
    let children: Set<string> = extractUniqueNextDPPointsIdsFromDPLogic(getLogic(dpId));
    children.forEach((childDpId) => {
        if (!dpParents.has(childDpId)) {
            dpParents.set(childDpId, new Set<string>());
        }
        // add to child parents id.
        dpParents.get(childDpId).add(dpId);
        // add to child all parent parents ids.
        dpParents.set(childDpId, unionSets(dpParents.get(dpId), dpParents.get(childDpId)));
        extractParentDpIdsStep(childDpId, dpParents, getLogic);
    });
}
