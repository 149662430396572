import { valueExists } from "../../components/legacyCommon/utils";
import PropTypes from "prop-types";
import { STATUS_REQUEST } from "../../components/legacyCommon/Consts";
import {
    audioMaxUploadSize,
    audioSizeExceededMsg,
    fileTypeCheckRequiredBufferSize,
    fileTypesWhitelist,
    illegalFileFormatMsg,
    imageMaxUploadSize,
    imageSizeExceededMsg,
    legacyIllegalFileFormatMsg,
    mimeTypesByMediaType,
    supportedExtensions,
    videoMaxUploadSize,
    videoSizeExceededMsg
} from "../../../common/commonConst";
import type { FileTypeResult } from "file-type";
import FileType from "file-type/browser";
import { Base64 } from "js-base64";
import type { Asset, AssetHistoryItem, ConvertedGqlAsset, RecordingDocItem } from "../../../common/types/asset";
import type { GqlClientRecordingDocFragment } from "../../graphql/graphqlGeneratedTypes/graphqlClient";


class AssetUtils {
    public static recordingStatuses = {
        APPROVED: "approved",
        REJECTED: "rejected",
        PENDING_APPROVAL: "pendingApproval"
    };

    public static AssetPropType = PropTypes.shape({
        activeVersion: PropTypes.number.isRequired,
        assetId: PropTypes.string.isRequired,
        filename: PropTypes.string,
        name: PropTypes.string.isRequired,
        version: PropTypes.number
    });

    static getRecordingStatusText(asset) {
        if (asset.status === STATUS_REQUEST) {
            return "Pending Recording";
        }
        switch (asset.recordingStatus) {
            case AssetUtils.recordingStatuses.PENDING_APPROVAL:
                return "Pending Approval";
            case AssetUtils.recordingStatuses.REJECTED:
                return asset.recordingErrorReason;
            case AssetUtils.recordingStatuses.APPROVED:
                return "Approved";
            default:
                return "";
        }
    }

    static getRecordingStatusClassName(asset) {
        if (asset.status === STATUS_REQUEST) {
            return "pending";
        }
        switch (asset.recordingStatus) {
            case AssetUtils.recordingStatuses.PENDING_APPROVAL:
                return "pending-approval";
            case AssetUtils.recordingStatuses.REJECTED:
                return "rejected";
            case AssetUtils.recordingStatuses.APPROVED:
                return "approved";
            default:
                return "";
        }
    }

    public static deconstructRecordingAssetName(assetName: string) : { narrationId: string, narratorId: string, key: string } {
        const deconstructed = assetName.split("~");

        return {
            narrationId: deconstructed[0],
            narratorId: deconstructed[1],
            key: deconstructed[2]
        };
    }

    public static getVersion = (asset, stageView: boolean): number => {
        if (stageView && valueExists(asset.stageVersion)) {
            return asset.stageVersion;
        }
        else {
            if (valueExists(asset.activeVersion)) {
                return asset.activeVersion;
            }
            else {
                return asset.version;
            }
        }
    };

    public static getDownloadUrl = (asset: Asset | Partial<AssetHistoryItem>, stageView: boolean, preview?: boolean): string => {
        if (asset.isPostgresAsset) {
            if (stageView && (asset as Asset).stageAssetItem) {
                const { url, thumbnailUrl } = (asset as Asset).stageAssetItem;
                return preview ? thumbnailUrl : url;
            }
            else {
                const { url, thumbnailUrl } = (asset as any) as ConvertedGqlAsset;
                return preview ? thumbnailUrl : url;
            }
        }

        const parameters: string = preview ? "?preview=true" : "";
        return AssetUtils.getAssetField(asset as Asset, "downloadSelf", stageView, parameters);
    };

    public static getLocation = (asset: Asset, stageView: boolean): string => {
        return AssetUtils.getAssetField(asset, "location", stageView);
    };

    public static getFormat = (asset: Asset, stageView: boolean): string => {
        return AssetUtils.getAssetField(asset, "format", stageView);
    };

    private static getAssetField = (asset: Asset, fieldName: string, stageView: boolean, parameters: string = ""): string => {
        if (stageView && asset.stageAssetItem && asset.stageAssetItem[fieldName]) {
            return asset.stageAssetItem[fieldName] + parameters;
        }

        if (asset.version !== asset.activeVersion) {
            if (asset.activeVersionItem && asset.activeVersionItem[fieldName]) {
                return asset.activeVersionItem[fieldName] + parameters;
            }
            if (asset.history && asset.history.Items) {
                const assetVersion = asset.history.Items.find((assetVersionItem) => assetVersionItem.version === asset.activeVersion);
                if (assetVersion) {
                    return assetVersion[fieldName] + parameters;
                }
            }
        }

        return asset[fieldName] ? asset[fieldName] + parameters : undefined;
    };

    /**
     *
     * @param {string} projectAssetId id that appears in redux under projects -> byName -> [projectName] -> assets.
     * Will be in the form: [accountId]/[projectName]/[assetType]/[assetName]
     */
    public static getAssetTypeFromId = (projectAssetId: string) => {
        return projectAssetId.split("/")[2];
    };

    public static getFileType = async (file: File): Promise<string> => {
        let reader: FileReader;
        return new Promise((resolve, reject) => {
            reader = new FileReader();
            reader.onload = resolve;
            reader.onerror = reject;
            reader.readAsArrayBuffer(file.slice(0, fileTypeCheckRequiredBufferSize));
        }).then(() => {
            let buffer: ArrayBuffer = reader && (reader.result as ArrayBuffer);
            return FileType.fromBuffer(buffer);
        }).then((type: FileTypeResult) => {
            return type && type.mime;
        });
    };

    public static getAssetNameNoFileExtension = (filename: string): string => {
        return filename.substr(0, filename.lastIndexOf("."));
    };

    static getMockNarrationUrl = (narrator: string, text: string, mockNarrationServerEndpoint: string): string => {
        return `${mockNarrationServerEndpoint}tts-service/play?narrator=${narrator}&text=${Base64.encode(text)}&filename=any.mp3`;
    };

    static convertGraphQLRecordingDocForRedux = (recordingDoc: GqlClientRecordingDocFragment): RecordingDocItem => {
        return {
            createdTime: recordingDoc.created,
            createdBy: recordingDoc.createdBy,
            audioFileName: recordingDoc.audioFilename,
            scriptFileName: recordingDoc.scriptFilename,
            totalRecordingFiles: recordingDoc.totalRecordingFiles,
            folderLocation: recordingDoc.folderLocation,
            audioFileUrl: recordingDoc.audioFileUrl,
            scriptFileUrl: recordingDoc.scriptFileUrl,
            id: recordingDoc.id,
            isPostgresAsset: true
        };
    };

    static validateFileType(fileMimeType: string, requiredMediaType?: string, isLegacyProject?: boolean) {
        if (!fileMimeType) {
            throw illegalFileFormatMsg;
        }

        if (!fileTypesWhitelist.includes(fileMimeType)) {
            throw isLegacyProject ? legacyIllegalFileFormatMsg : illegalFileFormatMsg;
        }

        if (requiredMediaType) {
            let allowedTypes = mimeTypesByMediaType[requiredMediaType];
            if (allowedTypes && !allowedTypes.includes(fileMimeType)) {
                throw `File of type: "${fileMimeType}" isn't compatible for ${requiredMediaType} media type`;
            }
        }
    }

    static validateMediaFileType(filename: string, fileMimeType: string, requiredMediaType?: string[]) {

        if (!AssetUtils.isFileExtensionValid(filename, supportedExtensions).isValid) {
            throw illegalFileFormatMsg;
        }

        if (!fileMimeType) {
            throw illegalFileFormatMsg;
        }

        if (!fileTypesWhitelist.includes(fileMimeType)) {
            throw illegalFileFormatMsg;
        }

        if (requiredMediaType) {
            let allowedTypes = [];
            requiredMediaType.forEach(requiredType => {
                if (mimeTypesByMediaType[requiredType]) {
                    allowedTypes.push(...mimeTypesByMediaType[requiredType]);
                }
            });
            if (allowedTypes && !allowedTypes.includes(fileMimeType)) {
                throw illegalFileFormatMsg;
            }
        }
    }

    static validateSizeLimitExceeded(type, size) {
        if (type.startsWith("audio") && size > audioMaxUploadSize) {
            throw audioSizeExceededMsg;
        }
        else if (type.startsWith("image") && size > imageMaxUploadSize) {
            throw imageSizeExceededMsg;
        }
        else if (type.startsWith("video") && size > videoMaxUploadSize) {
            throw videoSizeExceededMsg;
        }
    }

    public static isFileExtensionValid = (filename: string, allowedExtensions: string[]): {isValid: boolean, fileExtension: string} => {
        const fileExtension = filename.substr(filename.lastIndexOf(".") + 1).toLowerCase();
        return {
            isValid: allowedExtensions.includes(fileExtension),
            fileExtension
        };
    };

    static getCrossVersionId = (graphQLId: string) : string => {
        if (graphQLId.includes("|")) {
            return graphQLId.split("|").slice(2).join("|"); // removes programId|versionId from graphQLId
        }
        else {
            return graphQLId; // not graphQLId
        }
    };
}

export default AssetUtils;
