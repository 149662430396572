import { createAction } from "redux-actions";
import { reportError } from "../common/commonActions";
import { LogicContainers } from "../../../common/commonConst";
import { createNewBackgroundImageLogic, createNewBackgroundVideoLogic, createNewDecisionPointLogic, createNewSoundtrackLogic, isLogicEmpty, parseLogicObject } from "../Logics/Logic";
import {
    buildAndDecorateDecisionPointLogicValue,
    buildDecisionPointLogicValue,
    createDecisionPointLogicValue,
    extractSceneIdsAndDPsFromDPLogic,
    isDPLogicItemContainDPId,
    replaceDPLogicIdWithOther
} from "../Logics/DecisionPointLogic";
import StateReaderUtils from "../common/StateReaderUtils";
import StoryManager from "./StoryManager";
import { RULE_KEY_FOR_DEFAULT_VALUE } from "./HubStoryEditorUtils";
import { updateDataElementSuccess } from "../DataElements/DataElementActions";
import { SYSTEM_DATA_STORY_ID } from "../../../common/SystemDataElementsUtils";
import { MediaTypes } from "../../../common/types/asset";
import { addAssignedStory, getDataElementId, removeAssignedStory } from "../DataElements/DataElementsManager";
import { newScene } from "../builder/sceneUtils";
import type { CreativeDataElement, DataElement } from "../../../common/types/dataElement";
import { DataElementOrigins } from "../../../common/types/dataElement";
import { findProgramSummaryForProgramId } from "../../components/main/mainContainerUtils";
import type { ProgramSummary } from "../../../common/types/program";
import type {
    GqlClientCopyStoryInput,
    GqlClientCopyStoryMutation,
    GqlClientCreateStoryWithDecisionPointAndBackgroundAssetsInput,
    GqlClientCreateStoryWithDecisionPointAndBackgroundAssetsMutation,
    GqlClientDeleteStoryInput,
    GqlClientUpdateStoryForBuilderInput,
    GqlClientUpdateStoryForBuilderMutation,
    GqlClientUpdateStoryWithDecisionPointsInput,
    GqlClientUpdateStoryWithDecisionPointsMutation,
    GqlClientUpdateStoryWithNewSceneInput,
    GqlClientUpdateStoryWithNewSceneMutation
} from "../../graphql/graphqlGeneratedTypes/graphqlClient";
import {
    CopyStoryDocument,
    CreateStoryWithDecisionPointAndBackgroundAssetsDocument,
    DeleteStoryDocument,
    UpdateStoryForBuilderDocument,
    UpdateStoryWithDecisionPointsDocument,
    UpdateStoryWithNewSceneDocument
} from "../../graphql/graphqlGeneratedTypes/graphqlClient";
import { IGNORE_SERVER_ERRORS, IGNORE_UPDATED } from "../common/Consts";

import type { Story } from "../../../common/types/story";
import type { LogicJSON } from "../../../common/types/logic";
import { LogicContext } from "../../../common/types/logic";
import type { Scene, ScenePart } from "../../../common/types/scene";
import type { ThunkServices } from "../common/types";
import { BulkId } from "../common/types";
import { getIdFromGqlId } from "../common/convertGqlEntityToWireframesUtils";
import type { FetchResult } from "@apollo/client";
import { v4 as uuid } from "uuid";
import { valueSetsChanged } from "../projects/projectWireframes/projectsWireframesActions";

export const REMOVE_SCENE_FROM_STORY = "REMOVE_SCENE_FROM_STORY";
export const removeSceneFromStoryAction = createAction(REMOVE_SCENE_FROM_STORY, (projectName, data) => {
    return { projectName, data };
});

export const ADD_NEW_STORY_SUCCESS = "ADD_NEW_STORY_SUCCESS";
export const addNewStory = createAction(ADD_NEW_STORY_SUCCESS, (projectName, data) => {
    return { projectName, data };
});

export const DUPLICATE_STORY = "DUPLICATE_STORY";
export const duplicateStory = createAction(DUPLICATE_STORY, (projectName, data) => {
    return { projectName, data };
});

export const DELETE_STORY_SUCCESS = "DELETE_STORY_SUCCESS";
export const deleteStory = createAction(DELETE_STORY_SUCCESS, (projectName, data) => {
    return { projectName, data };
});

export const UPDATE_STORY_SUCCESS = "UPDATE_STORY_SUCCESS";
export const updateStory = createAction(UPDATE_STORY_SUCCESS, (accountId, projectName, storyId, storyData) => {
    return { accountId, projectName, storyId, storyData };
});

export const ADD_SCENE_TO_STORY_SUCCESS = "ADD_NEW_SCENE_TO_STORY_SUCCESS";
export const addSceneToStory = createAction(ADD_SCENE_TO_STORY_SUCCESS, (projectName, data) => {
    return { projectName, data };
});

export const UPDATE_DECISION_POINT_LOGIC_AND_ADD_NEW_DECISION_POINT_LOGIC = "UPDATE_DECISION_POINT_LOGIC_AND_ADD_NEW_DECISION_POINT_LOGIC";
export const updateDPLogicAndAddNewDPLogic = createAction(UPDATE_DECISION_POINT_LOGIC_AND_ADD_NEW_DECISION_POINT_LOGIC, (projectName, data) => {
    return { projectName, data };
});

export const UPDATE_DECISION_POINT_LOGIC = "UPDATE_DECISION_POINT_LOGIC";
export const updateDPLogic = createAction(UPDATE_DECISION_POINT_LOGIC, (projectName, data) => {
    return { projectName, data };
});

export const UPDATE_STORY_LOGIC_ITEMS = "UPDATE_STORY_LOGIC_ITEMS";
export const updateStoryLogicItems = createAction(UPDATE_STORY_LOGIC_ITEMS, (projectName, data) => {
    return { projectName, data };
});

export const MIGRATE_TO_MULTIPLE_STORIES_SUCCESS = "MIGRATE_TO_MULTIPLE_STORIES_SUCCESS";

export const MERGE_DECISION_POINTS_SUCCESS = "MERGE_DECISION_POINTS_SUCCESS";
export const mergeDecisionPointsSuccess = createAction(MERGE_DECISION_POINTS_SUCCESS, (projectName, data) => {
    return { projectName, data };
});

export const scenePartDefaultData: Omit<ScenePart, "scenePart"> = {
    placeholders: [],
    NarrationParts: [],
    parameters: []
};

export function getStoryLogicContainerId(storyId) {
    return getDynamoLogicContainerId("story", storyId);
}

export function getDecisionPointLogicContainerId(decisionPointId) {
    return getDynamoLogicContainerId(LogicContainers.DecisionPoint, decisionPointId);
}

export function getSoundtrackLogicContainerId(soundtrackId) {
    return getDynamoLogicContainerId(LogicContainers.Soundtrack, soundtrackId);
}

export function getBackgroundAssetLogicContainerId(backgroundAssetId) {
    return getDynamoLogicContainerId(LogicContainers.BackgroundAsset, backgroundAssetId);
}

export function getDynamoLogicContainerId(containerType, containerId) {
    return containerType + "_" + containerId;
}

function addValueToDecisionPoint(logicObj, value) {
    let logic = parseLogicObject(logicObj);
    if (isLogicEmpty(logicObj)) {
        //In case there are no rules and default value is set to "Last" we will add the new value as the default.
        logic.setLast(false);
        logic.setDefaultValue(value);
    }
    else {
        //otherwise the new value will be added as new rule.
        logic.addRuleWithValue(value);
    }
    return logic.serializeLogicData();
}

interface dataToDispatchAddNewProjectStory {
    storyId: string;
    storyData: Story;
    DpLogicData: LogicJSON;
    soundtrackLogicData: LogicJSON;
    backgroundImageLogicData: LogicJSON;
    backgroundVideoLogicData: LogicJSON;
}

interface dataToDispatchAddSceneToStory extends dataToDispatchUpdatedStoryDecisionPointsLogics {
    newSceneData?: Scene;
}

interface dataToDispatchUpdatedStoryDecisionPointsLogics {
    storyId: string;
    updatedStoryDecisionPointsLogics: { [dpId: string]: LogicJSON };
    decisionPointLogicIdToDelete?: string;
    storyDataToUpdate?: Pick<Story, "graphQLUpdated" | "graphQLId">;
}

interface dataToDispatchUpdatedStoryLogicItems {
    storyId: string;
    storyDataToUpdate?: Pick<Story, "graphQLUpdated" | "graphQLId">;
    logicContainerType: LogicContainers;
    updatedStoryLogicItems: { [mediaType: string]: LogicJSON };
}

interface dataToDispatchCopyStory {
    storyLogicItems: { [dpId: string]: LogicJSON };
    storyId: string;
    storyData?: Story;
}

const handleUpdateStoryWithNewSceneResponse = (dispatch, projectName: string, newSceneId: string, newSceneName: string,
    dataToDispatch: dataToDispatchAddSceneToStory, gqlOutput: FetchResult<GqlClientUpdateStoryWithNewSceneMutation>, cb) => {
    if (gqlOutput.data.updateStoryWithNewScene.__typename === "UpdateStoryWithNewSceneOutputSuccess") {
        const sceneData = newScene(newSceneName);
        const gqlScene = gqlOutput.data.updateStoryWithNewScene.scene;
        dataToDispatch.storyDataToUpdate = {
            graphQLUpdated: gqlOutput.data.updateStoryWithNewScene.story.updated
        };
        dataToDispatch.newSceneData = {
            ...sceneData,
            id: newSceneId,
            graphQLId: gqlScene.id,
            graphQLUpdated: gqlScene.updated,
            sceneParts: [
                {
                    ...scenePartDefaultData,
                    scenePart: getIdFromGqlId(gqlScene.sceneParts[0].id),
                    graphQLId: gqlScene.sceneParts[0].id,
                    graphQLUpdated: gqlScene.sceneParts[0].updated
                }]
        };
        dispatch(addSceneToStory(projectName, dataToDispatch));
        if (typeof cb === "function") {
            cb();
        }
    }
};

export const addNewProjectStory = (account, projectName, storyObj: Story, cb) => {
    return (dispatch, getState, services: ThunkServices) => {
        let logicData = createNewDecisionPointLogic().serializeLogicData();
        let soundtrackLogicData = createNewSoundtrackLogic().serializeLogicData();
        let backgroundImageLogicData = createNewBackgroundImageLogic().serializeLogicData();
        let backgroundVideoLogicData = createNewBackgroundVideoLogic().serializeLogicData();
        let dataToDispatch: dataToDispatchAddNewProjectStory = {
            storyId: undefined,
            storyData: storyObj,
            DpLogicData: logicData,
            soundtrackLogicData: soundtrackLogicData,
            backgroundImageLogicData: backgroundImageLogicData,
            backgroundVideoLogicData: backgroundVideoLogicData
        };
        const dispatchCreateNewStorySuccess = (dataToDispatch: dataToDispatchAddNewProjectStory) => {
            dispatch(addNewStory(projectName, dataToDispatch));
            dispatch(valueSetsChanged(account, projectName, [SYSTEM_DATA_STORY_ID]));
            if (typeof cb === "function") {
                cb(dataToDispatch.storyId);
            }
        };
        let input: GqlClientCreateStoryWithDecisionPointAndBackgroundAssetsInput = {
            programVersionId: StateReaderUtils.getBuilderProgramAndDraftVersionIds(getState(), projectName).programVersionId,
            name: dataToDispatch.storyData.name,
            description: dataToDispatch.storyData.description,
            activateCaching: dataToDispatch.storyData.activateCaching,
            // @ts-ignore
            backgroundAssetType: dataToDispatch.storyData.backgroundAssetType,
            backgroundVideoLogic: dataToDispatch.backgroundVideoLogicData,
            backgroundImageLogic: dataToDispatch.backgroundImageLogicData,
            backgroundAudioLogic: dataToDispatch.soundtrackLogicData,
            displaySceneOrder: [],
            initialDecisionPoint: {
                localId: dataToDispatch.storyData.initialDecisionPointId,
                logic: dataToDispatch.DpLogicData
            }
        };
        return services.graphQlClient
            .mutate({
                mutation: CreateStoryWithDecisionPointAndBackgroundAssetsDocument,
                variables: {
                    input: input
                }
            })
            .then((gqlOutput: FetchResult<GqlClientCreateStoryWithDecisionPointAndBackgroundAssetsMutation>) => {
                const gqlStory = gqlOutput.data.createStoryWithDecisionPointAndBackgroundAssets.story;
                const storyLocalId: string = getIdFromGqlId(gqlStory.id);
                dataToDispatch.storyData = { ...storyObj, id: storyLocalId, graphQLId: gqlStory.id, graphQLUpdated: gqlStory.updated };
                dataToDispatch.storyId = storyLocalId;
                dispatchCreateNewStorySuccess(dataToDispatch);
            })
            .catch((err) => {});
    };
};

export const updateProjectStory = (account: string, projectName: string, storyId: string, story: Story) => {
    return (dispatch, getState, services: ThunkServices) => {
        const dispatchUpdateStorySuccess = (updatedStory: Story) => {
            dispatch(updateStory(account, projectName, storyId, updatedStory));
            dispatch(valueSetsChanged(account, projectName, [SYSTEM_DATA_STORY_ID]));
        };
        let input: GqlClientUpdateStoryForBuilderInput = {
            id: story.graphQLId,
            updated: IGNORE_UPDATED,
            name: story.name,
            description: story.description,
            activateCaching: story.activateCaching,
            // @ts-ignore
            backgroundAssetType: story.backgroundAssetType,
            narratorName: story.narrator,
            numberOfProducts: story.numberOfProducts,
            durationOptions: story.durationOptions,
            ratioAndQualityOptions: story.ratioAndQualityOptions
        };
        return services.graphQlClient
            .mutate({
                mutation: UpdateStoryForBuilderDocument,
                variables: {
                    input: input,
                    dryRun: false
                }
            })
            .then((gqlOutput: FetchResult<GqlClientUpdateStoryForBuilderMutation>) => {
                if (gqlOutput.data.updateStoryForBuilder.__typename === "UpdateStoryForBuilderOutputSuccess") {
                    dispatchUpdateStorySuccess({ ...story, graphQLUpdated: gqlOutput.data.updateStoryForBuilder.story.updated });
                }
            })
            .catch((err) => {});
    };
};

export const duplicateProjectStory = (account: string, projectName: string, oldStory: Story, storyName: string, cb) => {
    return (dispatch, getState, services: ThunkServices) => {
        let newStory: Story = StoryManager.duplicateStory(oldStory, storyName);
        let storyLogicItems = StateReaderUtils.getStoryLogicItems(getState(), projectName, oldStory.id);
        let clonedLogicItems = Object.keys(storyLogicItems).reduce((acc, logicType) => {
            acc[logicType] = {};
            Object.keys(storyLogicItems[logicType]).forEach(logicId => {
                acc[logicType][logicId] = { ...storyLogicItems[logicType][logicId] };
            });
            return acc;
        }, {});
        let dataToDispatch: dataToDispatchCopyStory = {
            storyId: undefined,
            storyData: newStory,
            storyLogicItems: clonedLogicItems
        };

        let allDataElements: DataElement[] = StateReaderUtils.getProjectDataElements(getState(), projectName) || [];
        let creativeDataElementsToUpdate: CreativeDataElement[] =
            allDataElements.filter(de => de.origin === DataElementOrigins.Creative && de.assignedStories.includes(oldStory.id)) as CreativeDataElement[];
        let newCDEArr: CreativeDataElement[];

        const dispatchDuplicateStorySuccess = (newCDEArr: CreativeDataElement[], dataToDispatch: dataToDispatchCopyStory) => {
            dispatch(duplicateStory(projectName, dataToDispatch));
            dispatch(valueSetsChanged(account, projectName, [SYSTEM_DATA_STORY_ID]));
            newCDEArr.forEach(dataElement => {
                dispatch(updateDataElementSuccess(account, projectName, dataElement, undefined));
            });
            if (typeof cb === "function") {
                cb(dataToDispatch.storyId);
            }
        };

        let input: GqlClientCopyStoryInput = {
            sourceId: oldStory.graphQLId,
            targetName: dataToDispatch.storyData.name
        };
        return services.graphQlClient
            .mutate({
                mutation: CopyStoryDocument,
                variables: {
                    input: input
                }
            })
            .then((gqlOutput: FetchResult<GqlClientCopyStoryMutation>) => {
                const targetStory = gqlOutput.data.copyStory.targetStory;
                const storyLocalId: string = getIdFromGqlId(targetStory.id);
                dataToDispatch.storyId = storyLocalId;
                dataToDispatch.storyData = { ...dataToDispatch.storyData, id: storyLocalId, graphQLId: targetStory.id, graphQLUpdated: targetStory.updated };
                // Update all creative data elements that are assigned to the original story so they will be assigned also to the duplicated story
                newCDEArr = creativeDataElementsToUpdate.map(cde => addAssignedStory(cde, dataToDispatch.storyId));
                dispatchDuplicateStorySuccess(newCDEArr, dataToDispatch);
            })
            .catch((err) => {});

    };
};

export const deleteProjectStory = (account, projectName, storyId, cb) => {
    return (dispatch, getState, services) => {
        let dataToDispatch = { storyId };
        let allDataElements = StateReaderUtils.getProjectDataElements(getState(), projectName) || [];
        let creativeDataElementsToUpdate = allDataElements.filter((de) => de.origin === DataElementOrigins.Creative && de.assignedStories.includes(storyId));
        let newCDEArr: CreativeDataElement[] = [];
        if (creativeDataElementsToUpdate && creativeDataElementsToUpdate.length > 0) {
            newCDEArr = creativeDataElementsToUpdate.map(cde => removeAssignedStory(cde, storyId));
        }
        const dispatchDeleteStorySuccess = (newCDEArr, dataToDispatch) => {
            if (typeof cb === "function") {
                let stories = StateReaderUtils.getStories(StateReaderUtils.getWireFrame(getState(), projectName));
                let nextStoryId = Object.keys(stories).find(sId => sId !== storyId);
                cb(nextStoryId);
            }
            dispatch(deleteStory(projectName, dataToDispatch));
            dispatch(valueSetsChanged(account, projectName, [SYSTEM_DATA_STORY_ID]));
            newCDEArr.forEach(result => {
                dispatch(updateDataElementSuccess(account, projectName, result, undefined));
            });
        };
        const isPostgresOnly: boolean = StateReaderUtils.isProgramBulkDynamoMigrationDoneByLegacyId(getState(), projectName, BulkId.MostEntities);

        if (!isPostgresOnly) {
            return services.wireframes
                .deleteProjectWireframesStory(account, projectName, storyId)
                .then((deletedStory) => {
                    return services.wireframes.deleteProjectWireFramesInputLogics(account, projectName, getStoryLogicContainerId(storyId));
                })
                .then(() => {
                    let promiseArray = newCDEArr.map(newCDE => services.dataElements.updateDataElement(account, projectName, getDataElementId(newCDE), newCDE));
                    return Promise.all(promiseArray);
                })
                .then(() => {
                    dispatchDeleteStorySuccess(newCDEArr, dataToDispatch);
                    //add data to postgres
                    let programSummary: ProgramSummary = findProgramSummaryForProgramId(StateReaderUtils.getProgramSummaries(getState()), projectName);
                    let input: GqlClientDeleteStoryInput = {
                        id: `${programSummary.projectIds.builderDraftVersionId}|${storyId}`
                    };
                    services.graphQlClient
                        .mutate({
                            mutation: DeleteStoryDocument,
                            variables: {
                                input: input,
                                [IGNORE_SERVER_ERRORS]: true
                            }
                        })
                        .catch((err) => {});
                })
                .catch((err) => dispatch(reportError(err)));
        }
        else {
            let input: GqlClientDeleteStoryInput = {
                id: StateReaderUtils.getStoryGqlId(getState(), projectName, storyId)
            };
            return services.graphQlClient
                .mutate({
                    mutation: DeleteStoryDocument,
                    variables: {
                        input: input
                    }
                })
                .then(() => {
                    dispatchDeleteStorySuccess(newCDEArr, dataToDispatch);
                })
                .catch((err) => {});
        }
    };
};

export const removeSceneFromStoryWithPrevDPHasNoRules = (account, projectName, storyId, prevDecisionPointLogicId, nextDecisionPointLogicId, cb) => {
    return (dispatch, getState, services: ThunkServices) => {
        let dataToDispatch: dataToDispatchUpdatedStoryDecisionPointsLogics = {
            storyId,
            decisionPointLogicIdToDelete: "",
            updatedStoryDecisionPointsLogics: {}
        };
        let nextDecisionPointLogic = parseLogicObject(StateReaderUtils.getDecisionPointsLogic(getState(), projectName, storyId, nextDecisionPointLogicId));
        let storyDecisionPointsObj = StateReaderUtils.getDecisionPointLogicItemsForStory(getState(), projectName, storyId);
        //In case the scene is pointed through prev DP default value and there are no other rules:
        //1. copy the logic from next DP to prev DP.
        //2. Replace all reference to next DP to prev DP.
        //3. Delete next DP.

        dataToDispatch.decisionPointLogicIdToDelete = nextDecisionPointLogicId;
        let updatedStoryDecisionPointsObj = Object.keys(storyDecisionPointsObj).reduce((acc, dpId) => {
            if (isDPLogicItemContainDPId(storyDecisionPointsObj[dpId], nextDecisionPointLogicId)) {
                acc[dpId] = replaceDPLogicIdWithOther(storyDecisionPointsObj[dpId], nextDecisionPointLogicId, prevDecisionPointLogicId);
            }
            return acc;
        }, {});
        updatedStoryDecisionPointsObj[prevDecisionPointLogicId] = nextDecisionPointLogic.serializeLogicData();
        dataToDispatch.updatedStoryDecisionPointsLogics = updatedStoryDecisionPointsObj;

        const isPostgresOnly: boolean = StateReaderUtils.isProgramBulkDynamoMigrationDoneByLegacyId(getState(), projectName, BulkId.MostEntities);
        const dispatchRemoveSceneFromStorySuccess = (dataToDispatch: dataToDispatchUpdatedStoryDecisionPointsLogics) => {
            if (typeof cb === "function") {
                cb();
            }
            dispatch(removeSceneFromStoryAction(projectName, dataToDispatch));
        };
        if (!isPostgresOnly) {
            let promiseArray = Object.keys(updatedStoryDecisionPointsObj).map((dpID) => {
                return services.wireframes.setProjectWireFramesSceneInputLogic(
                    account,
                    projectName,
                    getStoryLogicContainerId(storyId),
                    getDecisionPointLogicContainerId(dpID),
                    updatedStoryDecisionPointsObj[dpID]
                );
            });
            promiseArray.push(services.wireframes.deleteProjectWireFramesInputLogic(account, projectName,
                getStoryLogicContainerId(storyId), getDecisionPointLogicContainerId(nextDecisionPointLogicId)));
            return Promise.all(promiseArray)
                .then(() => {
                    dispatchRemoveSceneFromStorySuccess(dataToDispatch);
                    //add data to postgres
                    let programSummary: ProgramSummary = findProgramSummaryForProgramId(StateReaderUtils.getProgramSummaries(getState()), projectName);
                    let input: GqlClientUpdateStoryWithDecisionPointsInput = {
                        id: `${programSummary.projectIds.builderDraftVersionId}|${dataToDispatch.storyId}`,
                        updated: IGNORE_UPDATED,
                        decisionPointsToUpdate: Object.keys(dataToDispatch.updatedStoryDecisionPointsLogics).map((dpId) => {
                            return {
                                id: `${programSummary.projectIds.builderDraftVersionId}|${dataToDispatch.storyId}|${dpId}`,
                                logic: dataToDispatch.updatedStoryDecisionPointsLogics[dpId],
                                updated: IGNORE_UPDATED
                            };
                        }),
                        decisionPointsToDeleteIds: dataToDispatch.decisionPointLogicIdToDelete
                            ? [`${programSummary.projectIds.builderDraftVersionId}|${dataToDispatch.storyId}|${dataToDispatch.decisionPointLogicIdToDelete}`]
                            : undefined
                    };
                    services.graphQlClient
                        .mutate({
                            mutation: UpdateStoryWithDecisionPointsDocument,
                            variables: {
                                input: input,
                                dryRun: false,
                                [IGNORE_SERVER_ERRORS]: true
                            }
                        })
                        .catch((err) => {});
                })
                .catch((err) => dispatch(reportError(err)));
        }
        else {
            const storyGQLId: string = StateReaderUtils.getStoryGqlId(getState(), projectName, storyId);
            let input: GqlClientUpdateStoryWithDecisionPointsInput = {
                id: storyGQLId,
                updated: IGNORE_UPDATED,
                decisionPointsToUpdate: Object.keys(dataToDispatch.updatedStoryDecisionPointsLogics).map((dpId) => {
                    return {
                        id: `${storyGQLId}|${dpId}`,
                        logic: dataToDispatch.updatedStoryDecisionPointsLogics[dpId],
                        updated: IGNORE_UPDATED
                    };
                }),
                decisionPointsToDeleteIds: dataToDispatch.decisionPointLogicIdToDelete
                    ? [`${storyGQLId}|${dataToDispatch.decisionPointLogicIdToDelete}`]
                    : undefined
            };
            return services.graphQlClient
                .mutate({
                    mutation: UpdateStoryWithDecisionPointsDocument,
                    variables: {
                        input: input,
                        dryRun: false
                    }
                })
                .then((gqlOutput: FetchResult<GqlClientUpdateStoryWithDecisionPointsMutation>) => {
                    if (gqlOutput.data.updateStoryWithDecisionPoints.__typename === "UpdateStoryWithDecisionPointsOutputSuccess") {
                        dataToDispatch.storyDataToUpdate = {
                            graphQLUpdated: gqlOutput.data.updateStoryWithDecisionPoints.story.updated
                        };
                        dispatchRemoveSceneFromStorySuccess(dataToDispatch);
                    }
                })
                .catch((err) => {});
        }

    };
};

export const removeSceneFromStoryWithNextDPHasNoRules = (account, projectName, storyId, ruleKey, prevDecisionPointLogicId, nextDecisionPointLogicId, cb) => {
    return (dispatch, getState, services: ThunkServices) => {
        // Assuming the nextDecisionPointLogic has 0 rules.
        //1. copy next DP default value instade of the rule's value/ default value that point the deleted scene in prev DP logic item.
        //2. (see if we can delete next DP;
        let dataToDispatch: dataToDispatchUpdatedStoryDecisionPointsLogics = {
            storyId,
            decisionPointLogicIdToDelete: "",
            updatedStoryDecisionPointsLogics: {}
        };
        let story = StateReaderUtils.getStoryById(StateReaderUtils.getWireFrame(getState(), projectName), storyId);
        let storyDecisionPointsObj = StateReaderUtils.getDecisionPointLogicItemsForStory(getState(), projectName, storyId);
        let prevDecisionPointLogic = parseLogicObject(StateReaderUtils.getDecisionPointsLogic(getState(), projectName, storyId, prevDecisionPointLogicId));
        let nextDecisionPointLogic = parseLogicObject(StateReaderUtils.getDecisionPointsLogic(getState(), projectName, storyId, nextDecisionPointLogicId));
        let isLast = nextDecisionPointLogic.isLast();
        let { numOfOccurrencesPerDP } = extractSceneIdsAndDPsFromDPLogic(storyDecisionPointsObj[story.initialDecisionPointId], storyDecisionPointsObj);

        let isPossibleToDeleteNextDecision = !(numOfOccurrencesPerDP.get(nextDecisionPointLogicId) > 1);
        dataToDispatch.decisionPointLogicIdToDelete = isPossibleToDeleteNextDecision ? nextDecisionPointLogicId : "";
        if (!isLast && isPossibleToDeleteNextDecision) {
            // Case the DP after the deleted scene points to other DP and no other dp reference to this nextDecisionPointLogicId,
            // we will copy its default value to the rule/ default value that was pointing to the deleted scene.
            let value = nextDecisionPointLogic.getDefaultValue();
            if (ruleKey !== RULE_KEY_FOR_DEFAULT_VALUE) {
                // Case the deleted scene was pointed by a rule , we will rewrite that rule value and make it to point the scene and dp that was after the scene that was deleted.
                prevDecisionPointLogic.updateRuleValue(ruleKey, value);
            }
            else {
                // Case the deleted scene was pointed by a default value , we will rewrite the default value and make it to point the scene and dp that was after the scene that was deleted.
                prevDecisionPointLogic.setDefaultValue(value);
            }
        }
        else {
            // Case the DP after the deleted scene dose not point to any other DP, or/and  the DP after the deleted scene is pointed by other dp,
            // we will delete the rule/ default value that was pointing the deleted scene.
            if (ruleKey !== RULE_KEY_FOR_DEFAULT_VALUE) {
                // Case the deleted scene was pointed by a rule , we will delete that rule.
                prevDecisionPointLogic.removeRule(ruleKey);
            }
            else {
                // Case the deleted scene was pointed by a default value ,we will delete that default value.
                prevDecisionPointLogic.setDefaultValue(null);
                prevDecisionPointLogic.setLast(true);
            }
        }
        dataToDispatch.updatedStoryDecisionPointsLogics = { [prevDecisionPointLogicId]: prevDecisionPointLogic.serializeLogicData() };
        const dispatchRemoveSceneFromStorySuccess = (dataToDispatch: dataToDispatchUpdatedStoryDecisionPointsLogics) => {
            if (typeof cb === "function") {
                cb();
            }
            dispatch(removeSceneFromStoryAction(projectName, dataToDispatch));
        };
        const isPostgresOnly: boolean = StateReaderUtils.isProgramBulkDynamoMigrationDoneByLegacyId(getState(), projectName, BulkId.MostEntities);
        if (!isPostgresOnly) {

            return services.wireframes
                .setProjectWireFramesSceneInputLogic(
                    account,
                    projectName,
                    getStoryLogicContainerId(storyId),
                    getDecisionPointLogicContainerId(prevDecisionPointLogicId),
                    prevDecisionPointLogic.serializeLogicData()
                )
                .then((updateDecisionPointLogic) => {
                    dispatchRemoveSceneFromStorySuccess(dataToDispatch);
                    let promiseDelete = Promise.resolve(null);
                    if (isPossibleToDeleteNextDecision) {
                        promiseDelete = services.wireframes.deleteProjectWireFramesInputLogic(
                            account,
                            projectName,
                            getStoryLogicContainerId(storyId),
                            getDecisionPointLogicContainerId(nextDecisionPointLogicId)
                        );
                    }
                    return promiseDelete
                        .then(() => {
                            //add data to postgres
                            let programSummary: ProgramSummary = findProgramSummaryForProgramId(StateReaderUtils.getProgramSummaries(getState()), projectName);
                            let input: GqlClientUpdateStoryWithDecisionPointsInput = {
                                id: `${programSummary.projectIds.builderDraftVersionId}|${dataToDispatch.storyId}`,
                                updated: IGNORE_UPDATED,
                                decisionPointsToUpdate: Object.keys(dataToDispatch.updatedStoryDecisionPointsLogics).map((dpId) => {
                                    return {
                                        id: `${programSummary.projectIds.builderDraftVersionId}|${dataToDispatch.storyId}|${dpId}`,
                                        logic: dataToDispatch.updatedStoryDecisionPointsLogics[dpId],
                                        updated: IGNORE_UPDATED
                                    };
                                }),
                                decisionPointsToDeleteIds: dataToDispatch.decisionPointLogicIdToDelete
                                    ? [`${programSummary.projectIds.builderDraftVersionId}|${dataToDispatch.storyId}|${dataToDispatch.decisionPointLogicIdToDelete}`]
                                    : undefined
                            };
                            services.graphQlClient
                                .mutate({
                                    mutation: UpdateStoryWithDecisionPointsDocument,
                                    variables: {
                                        input: input,
                                        dryRun: false,
                                        [IGNORE_SERVER_ERRORS]: true
                                    }
                                })
                                .catch((err) => {});
                        })
                        .catch((err) => {});
                })
                .catch((err) => dispatch(reportError(err)));
        }
        else {
            const storyGQLId: string = StateReaderUtils.getStoryGqlId(getState(), projectName, storyId);
            let input: GqlClientUpdateStoryWithDecisionPointsInput = {
                id: storyGQLId,
                updated: IGNORE_UPDATED,
                decisionPointsToUpdate: Object.keys(dataToDispatch.updatedStoryDecisionPointsLogics).map((dpId) => {
                    return {
                        id: `${storyGQLId}|${dpId}`,
                        logic: dataToDispatch.updatedStoryDecisionPointsLogics[dpId],
                        updated: IGNORE_UPDATED
                    };
                }),
                decisionPointsToDeleteIds: dataToDispatch.decisionPointLogicIdToDelete
                    ? [`${storyGQLId}|${dataToDispatch.decisionPointLogicIdToDelete}`]
                    : undefined
            };
            return services.graphQlClient
                .mutate({
                    mutation: UpdateStoryWithDecisionPointsDocument,
                    variables: {
                        input: input,
                        dryRun: false
                    }
                })
                .then((gqlOutput: FetchResult<GqlClientUpdateStoryWithDecisionPointsMutation>) => {
                    if (gqlOutput.data.updateStoryWithDecisionPoints.__typename === "UpdateStoryWithDecisionPointsOutputSuccess") {
                        dataToDispatch.storyDataToUpdate = {
                            graphQLUpdated: gqlOutput.data.updateStoryWithDecisionPoints.story.updated
                        };
                        dispatchRemoveSceneFromStorySuccess(dataToDispatch);
                    }
                })
                .catch((err) => {});
        }
    };
};

export const addNewScene = (account, projectName, storyId, decisionPointLogicId, sceneName) => {
    return (dispatch, getState, services: ThunkServices) => {
        let decisionPointLogic = StateReaderUtils.getDecisionPointsLogic(getState(), projectName, storyId, decisionPointLogicId);
        let dataToDispatch: dataToDispatchAddSceneToStory = {
            updatedStoryDecisionPointsLogics: {},
            storyId
        };
        let newRuleNextDpId;

        let newSceneId = uuid();

        //Adding new value to DP that will point to the new scene and DP.
        let newValue = buildAndDecorateDecisionPointLogicValue(newSceneId);
        newRuleNextDpId = newValue.getNextDPLogicId();
        decisionPointLogic = addValueToDecisionPoint(decisionPointLogic, newValue.serializeDecisionPointLogicValue());
        let newDecisionPointLogic = createNewDecisionPointLogic().serializeLogicData();

        dataToDispatch.updatedStoryDecisionPointsLogics[newRuleNextDpId] = newDecisionPointLogic;
        dataToDispatch.updatedStoryDecisionPointsLogics[decisionPointLogicId] = decisionPointLogic;

        //add data to postgres
        const storyGQLId: string = StateReaderUtils.getStoryGqlId(getState(), projectName, storyId);
        let input: GqlClientUpdateStoryWithNewSceneInput = {
            id: storyGQLId,
            updated: IGNORE_UPDATED,
            newScene: {
                name: sceneName
            },
            decisionPointToUpdate: {
                id: `${storyGQLId}|${decisionPointLogicId}`,
                logic: decisionPointLogic,
                updated: IGNORE_UPDATED
            },
            newDecisionPoint: {
                localId: newRuleNextDpId,
                logic: newDecisionPointLogic
            }
        };
        return services.graphQlClient
            .mutate({
                mutation: UpdateStoryWithNewSceneDocument,
                variables: {
                    input: input,
                    dryRun: false,
                    sceneLegacyId: newSceneId
                }
            })
            .then((gqlOutput: FetchResult<GqlClientUpdateStoryWithNewSceneMutation>) => {
                handleUpdateStoryWithNewSceneResponse(dispatch, projectName, newSceneId, sceneName,
                    dataToDispatch, gqlOutput, undefined);
            })
            .catch((err) => {});
    };
};

export const addExistingScene = (account, projectName, storyId, decisionPointLogicId, sceneId) => {
    return (dispatch, getState, services: ThunkServices) => {
        let newDecisionPointLogic = createNewDecisionPointLogic().serializeLogicData();
        let decisionPointLogic = StateReaderUtils.getDecisionPointsLogic(getState(), projectName, storyId, decisionPointLogicId);
        let dataToDispatch: dataToDispatchUpdatedStoryDecisionPointsLogics = {
            updatedStoryDecisionPointsLogics: {},
            storyId
        };
        //Adding new rule to DP that will point to the new scene and DP.
        let newValue = buildAndDecorateDecisionPointLogicValue(sceneId);
        let newRuleNextDpId = newValue.getNextDPLogicId();
        decisionPointLogic = addValueToDecisionPoint(decisionPointLogic, newValue.serializeDecisionPointLogicValue());
        dataToDispatch.updatedStoryDecisionPointsLogics[newRuleNextDpId] = newDecisionPointLogic;
        dataToDispatch.updatedStoryDecisionPointsLogics[decisionPointLogicId] = decisionPointLogic;
        const dispatchAddSceneToStorySuccess = (dataToDispatch: dataToDispatchUpdatedStoryDecisionPointsLogics) => {
            dispatch(addSceneToStory(projectName, dataToDispatch));
        };
        const isPostgresOnly: boolean = StateReaderUtils.isProgramBulkDynamoMigrationDoneByLegacyId(getState(), projectName, BulkId.MostEntities);
        if (!isPostgresOnly) {
            return services.wireframes
                .setProjectWireFramesSceneInputLogic(account, projectName, getStoryLogicContainerId(storyId), getDecisionPointLogicContainerId(newRuleNextDpId), newDecisionPointLogic)
                .then((logicData) => {
                    return services.wireframes.setProjectWireFramesSceneInputLogic(
                        account,
                        projectName,
                        getStoryLogicContainerId(storyId),
                        getDecisionPointLogicContainerId(decisionPointLogicId),
                        decisionPointLogic
                    );
                })
                .then((logicData2) => {
                    dispatchAddSceneToStorySuccess(dataToDispatch);

                    //add data to postgres
                    let programSummary: ProgramSummary = findProgramSummaryForProgramId(StateReaderUtils.getProgramSummaries(getState()), projectName);
                    let input: GqlClientUpdateStoryWithDecisionPointsInput = {
                        id: `${programSummary.projectIds.builderDraftVersionId}|${dataToDispatch.storyId}`,
                        updated: IGNORE_UPDATED,
                        newDecisionPoints: [
                            {
                                localId: newRuleNextDpId,
                                logic: dataToDispatch.updatedStoryDecisionPointsLogics[newRuleNextDpId]
                            }
                        ],
                        decisionPointsToUpdate: [
                            {
                                id: `${programSummary.projectIds.builderDraftVersionId}|${dataToDispatch.storyId}|${decisionPointLogicId}`,
                                logic: dataToDispatch.updatedStoryDecisionPointsLogics[decisionPointLogicId],
                                updated: IGNORE_UPDATED
                            }
                        ]
                    };
                    services.graphQlClient
                        .mutate({
                            mutation: UpdateStoryWithDecisionPointsDocument,
                            variables: {
                                input: input,
                                dryRun: false,
                                [IGNORE_SERVER_ERRORS]: true
                            }
                        })
                        .catch((err) => {});
                })
                .catch((err) => dispatch(reportError(err)));
        }
        else {
            const storyGQLId: string = StateReaderUtils.getStoryGqlId(getState(), projectName, storyId);
            let input: GqlClientUpdateStoryWithDecisionPointsInput = {
                id: storyGQLId,
                updated: IGNORE_UPDATED,
                newDecisionPoints: [
                    {
                        localId: newRuleNextDpId,
                        logic: dataToDispatch.updatedStoryDecisionPointsLogics[newRuleNextDpId]
                    }
                ],
                decisionPointsToUpdate: [
                    {
                        id: `${storyGQLId}|${decisionPointLogicId}`,
                        logic: dataToDispatch.updatedStoryDecisionPointsLogics[decisionPointLogicId],
                        updated: IGNORE_UPDATED
                    }
                ]
            };
            return services.graphQlClient
                .mutate({
                    mutation: UpdateStoryWithDecisionPointsDocument,
                    variables: {
                        input: input,
                        dryRun: false
                    }
                })
                .then((gqlOutput: FetchResult<GqlClientUpdateStoryWithDecisionPointsMutation>) => {
                    if (gqlOutput.data.updateStoryWithDecisionPoints.__typename === "UpdateStoryWithDecisionPointsOutputSuccess") {
                        dataToDispatch.storyDataToUpdate = {
                            graphQLUpdated: gqlOutput.data.updateStoryWithDecisionPoints.story.updated
                        };
                        dispatchAddSceneToStorySuccess(dataToDispatch);
                    }
                })
                .catch((err) => {});
        }
    };
};

export const replaceSceneWithNewScene = (account, projectName, storyId, decisionPointLogicId, ruleKey, sceneName, cb) => {
    return (dispatch, getState, services: ThunkServices) => {
        let decisionPointLogic = parseLogicObject(StateReaderUtils.getDecisionPointsLogic(getState(), projectName, storyId, decisionPointLogicId));
        let dataToDispatch: dataToDispatchAddSceneToStory = {
            updatedStoryDecisionPointsLogics: {},
            storyId
        };
        const isPostgresOnly: boolean = StateReaderUtils.isProgramBulkDynamoMigrationDoneByLegacyId(getState(), projectName, BulkId.MostEntities);
        if (!isPostgresOnly) {
            let sceneData: Scene;
            return services.wireframes
                .addProjectWireframesScene(account, projectName, newScene(sceneName))
                .then((res) => {
                    sceneData = res;
                    return services.wireframes.addProjectWireframesScenePart(account, projectName, sceneData.id, scenePartDefaultData);
                })
                .then((scenePart) => {
                    sceneData.sceneParts.push(scenePart);
                    dataToDispatch.newSceneData = sceneData;

                    //Change the default value to point the new scene (and the same DP);
                    if (ruleKey !== RULE_KEY_FOR_DEFAULT_VALUE) {
                        let rulesNextDpId = buildDecisionPointLogicValue(decisionPointLogic.getRule(ruleKey).value).getNextDPLogicId();
                        let newRulesValue = createDecisionPointLogicValue(sceneData.id, rulesNextDpId).serializeDecisionPointLogicValue();
                        decisionPointLogic.updateRuleValue(ruleKey, newRulesValue);
                    }
                    else {
                        //Change the rule to point the new scene (and the same DP);
                        let defaultValueNextDpId = buildDecisionPointLogicValue(decisionPointLogic.getDefaultValue()).getNextDPLogicId();
                        let newDefaultValue = createDecisionPointLogicValue(sceneData.id, defaultValueNextDpId);
                        decisionPointLogic.setDefaultValue(newDefaultValue.serializeDecisionPointLogicValue());
                    }
                    return services.wireframes.setProjectWireFramesSceneInputLogic(
                        account,
                        projectName,
                        getStoryLogicContainerId(storyId),
                        getDecisionPointLogicContainerId(decisionPointLogicId),
                        decisionPointLogic.serializeLogicData()
                    );
                })
                .then((logicData) => {
                    dataToDispatch.updatedStoryDecisionPointsLogics[decisionPointLogicId] = logicData;
                    dispatch(addSceneToStory(projectName, dataToDispatch));
                    if (typeof cb === "function") {
                        cb(sceneData.id);
                    }

                    //add data to postgres
                    let programSummary: ProgramSummary = findProgramSummaryForProgramId(StateReaderUtils.getProgramSummaries(getState()), projectName);
                    let input: GqlClientUpdateStoryWithNewSceneInput = {
                        id: `${programSummary.projectIds.builderDraftVersionId}|${dataToDispatch.storyId}`,
                        updated: IGNORE_UPDATED,
                        newScene: {
                            name: sceneName
                        },
                        decisionPointToUpdate: {
                            id: `${programSummary.projectIds.builderDraftVersionId}|${dataToDispatch.storyId}|${decisionPointLogicId}`,
                            logic: dataToDispatch.updatedStoryDecisionPointsLogics[decisionPointLogicId],
                            updated: IGNORE_UPDATED
                        }
                    };
                    services.graphQlClient
                        .mutate({
                            mutation: UpdateStoryWithNewSceneDocument,
                            variables: {
                                input: input,
                                dryRun: false,
                                sceneLegacyId: dataToDispatch.newSceneData.id,
                                scenePartLegacyId: dataToDispatch.newSceneData.sceneParts[0].scenePart,
                                [IGNORE_SERVER_ERRORS]: true
                            }
                        })
                        .catch((err) => {});
                })
                .catch((err) => dispatch(reportError(err)));
        }
        else {
            let newSceneId = uuid();
            //Change the default value to point the new scene (and the same DP);
            if (ruleKey !== RULE_KEY_FOR_DEFAULT_VALUE) {
                let rulesNextDpId = buildDecisionPointLogicValue(decisionPointLogic.getRule(ruleKey).value).getNextDPLogicId();
                let newRulesValue = createDecisionPointLogicValue(newSceneId, rulesNextDpId).serializeDecisionPointLogicValue();
                decisionPointLogic.updateRuleValue(ruleKey, newRulesValue);
            }
            else {
                //Change the rule to point the new scene (and the same DP);
                let defaultValueNextDpId = buildDecisionPointLogicValue(decisionPointLogic.getDefaultValue()).getNextDPLogicId();
                let newDefaultValue = createDecisionPointLogicValue(newSceneId, defaultValueNextDpId);
                decisionPointLogic.setDefaultValue(newDefaultValue.serializeDecisionPointLogicValue());
            }
            dataToDispatch.updatedStoryDecisionPointsLogics[decisionPointLogicId] = decisionPointLogic.serializeLogicData();
            //add data to postgres
            const storyGQLId: string = StateReaderUtils.getStoryGqlId(getState(), projectName, storyId);
            let input: GqlClientUpdateStoryWithNewSceneInput = {
                id: storyGQLId,
                updated: IGNORE_UPDATED,
                newScene: {
                    name: sceneName
                },
                decisionPointToUpdate: {
                    id: `${storyGQLId}|${decisionPointLogicId}`,
                    logic: decisionPointLogic.serializeLogicData(),
                    updated: IGNORE_UPDATED
                }
            };
            return services.graphQlClient
                .mutate({
                    mutation: UpdateStoryWithNewSceneDocument,
                    variables: {
                        input: input,
                        dryRun: false,
                        sceneLegacyId: newSceneId
                    }
                })
                .then((gqlOutput: FetchResult<GqlClientUpdateStoryWithNewSceneMutation>) => {
                    handleUpdateStoryWithNewSceneResponse(dispatch, projectName, newSceneId, sceneName,
                        dataToDispatch, gqlOutput, () => cb && cb(newSceneId));
                })
                .catch((err) => {});
        }
    };
};

export const replaceSceneWithExistingScene = (account, projectName, storyId, decisionPointLogicId, ruleKey, sceneId, cb) => {
    return (dispatch, getState, services: ThunkServices) => {
        let decisionPointLogic = parseLogicObject(StateReaderUtils.getDecisionPointsLogic(getState(), projectName, storyId, decisionPointLogicId));
        let dataToDispatch: dataToDispatchUpdatedStoryDecisionPointsLogics = {
            updatedStoryDecisionPointsLogics: {},
            storyId
        };
        //Change the rule to point the new scene (and the same DP);
        if (ruleKey !== RULE_KEY_FOR_DEFAULT_VALUE) {
            let rulesNextDpId = buildDecisionPointLogicValue(decisionPointLogic.getRule(ruleKey).value).getNextDPLogicId();
            let newRulesValue = createDecisionPointLogicValue(sceneId, rulesNextDpId).serializeDecisionPointLogicValue();
            decisionPointLogic.updateRuleValue(ruleKey, newRulesValue);
        }
        else {
            //Change the default value to point the new scene (and the same DP);
            let defaultValueNextDpId = buildDecisionPointLogicValue(decisionPointLogic.getDefaultValue()).getNextDPLogicId();
            let newDefaultValue = createDecisionPointLogicValue(sceneId, defaultValueNextDpId);
            decisionPointLogic.setDefaultValue(newDefaultValue.serializeDecisionPointLogicValue());
        }
        dataToDispatch.updatedStoryDecisionPointsLogics[decisionPointLogicId] = decisionPointLogic.serializeLogicData();

        const dispatchAddSceneToStorySuccess = (dataToDispatch: dataToDispatchUpdatedStoryDecisionPointsLogics) => {
            dispatch(addSceneToStory(projectName, dataToDispatch));
            if (typeof cb === "function") {
                cb();
            }
        };
        const isPostgresOnly: boolean = StateReaderUtils.isProgramBulkDynamoMigrationDoneByLegacyId(getState(), projectName, BulkId.MostEntities);
        if (!isPostgresOnly) {

            return services.wireframes
                .setProjectWireFramesSceneInputLogic(
                    account,
                    projectName,
                    getStoryLogicContainerId(storyId),
                    getDecisionPointLogicContainerId(decisionPointLogicId),
                    decisionPointLogic.serializeLogicData()
                )
                .then((logicData) => {
                    dispatchAddSceneToStorySuccess(dataToDispatch);

                    //add data to postgres
                    let programSummary: ProgramSummary = findProgramSummaryForProgramId(StateReaderUtils.getProgramSummaries(getState()), projectName);
                    let input: GqlClientUpdateStoryWithDecisionPointsInput = {
                        id: `${programSummary.projectIds.builderDraftVersionId}|${dataToDispatch.storyId}`,
                        updated: IGNORE_UPDATED,
                        decisionPointsToUpdate: [
                            {
                                id: `${programSummary.projectIds.builderDraftVersionId}|${dataToDispatch.storyId}|${decisionPointLogicId}`,
                                logic: dataToDispatch.updatedStoryDecisionPointsLogics[decisionPointLogicId],
                                updated: IGNORE_UPDATED
                            }
                        ]
                    };
                    services.graphQlClient
                        .mutate({
                            mutation: UpdateStoryWithDecisionPointsDocument,
                            variables: {
                                input: input,
                                dryRun: false,
                                [IGNORE_SERVER_ERRORS]: true
                            }
                        })
                        .catch((err) => {});
                })
                .catch((err) => dispatch(reportError(err)));
        }
        else {
            const storyGQLId: string = StateReaderUtils.getStoryGqlId(getState(), projectName, storyId);

            let input: GqlClientUpdateStoryWithDecisionPointsInput = {
                id: storyGQLId,
                updated: IGNORE_UPDATED,
                decisionPointsToUpdate: [
                    {
                        id: `${storyGQLId}|${decisionPointLogicId}`,
                        logic: dataToDispatch.updatedStoryDecisionPointsLogics[decisionPointLogicId],
                        updated: IGNORE_UPDATED
                    }
                ]
            };
            return services.graphQlClient
                .mutate({
                    mutation: UpdateStoryWithDecisionPointsDocument,
                    variables: {
                        input: input,
                        dryRun: false
                    }
                })
                .then((gqlOutput: FetchResult<GqlClientUpdateStoryWithDecisionPointsMutation>) => {
                    if (gqlOutput.data.updateStoryWithDecisionPoints.__typename === "UpdateStoryWithDecisionPointsOutputSuccess") {
                        dataToDispatch.storyDataToUpdate = {
                            graphQLUpdated: gqlOutput.data.updateStoryWithDecisionPoints.story.updated
                        };
                        dispatchAddSceneToStorySuccess(dataToDispatch);
                    }
                })
                .catch((err) => {});
        }
    };
};

export const addNewSceneBefore = (account, projectName, storyId, decisionPointLogicId, sceneName, cb) => {
    return (dispatch, getState, services: ThunkServices) => {
        let dataToDispatch: dataToDispatchAddSceneToStory = {
            updatedStoryDecisionPointsLogics: {},
            storyId
        };
        let newDefaultValueNextDpId;
        let newDecisionPointLogic;
        const isPostgresOnly: boolean = StateReaderUtils.isProgramBulkDynamoMigrationDoneByLegacyId(getState(), projectName, BulkId.MostEntities);
        if (!isPostgresOnly) {
            let sceneData: Scene;
            return services.wireframes
                .addProjectWireframesScene(account, projectName, newScene(sceneName))
                .then((res) => {
                    sceneData = res;
                    return services.wireframes.addProjectWireframesScenePart(account, projectName, sceneData.id, scenePartDefaultData);
                })
                .then((scenePart) => {
                    sceneData.sceneParts.push(scenePart);
                    dataToDispatch.newSceneData = sceneData;
                    //Creating new DP that will point to the new scene and existing DP. this DP will get existing DP id.
                    let newDefaultValue = buildAndDecorateDecisionPointLogicValue(sceneData.id);
                    newDefaultValueNextDpId = newDefaultValue.getNextDPLogicId();
                    newDecisionPointLogic = createNewDecisionPointLogic();
                    newDecisionPointLogic.setDefaultValue(newDefaultValue.serializeDecisionPointLogicValue());
                    newDecisionPointLogic.setLast(false);
                    let decisionPointLogicObj = StateReaderUtils.getDecisionPointsLogic(getState(), projectName, storyId, decisionPointLogicId);
                    return services.wireframes.setProjectWireFramesSceneInputLogic(
                        account,
                        projectName,
                        getStoryLogicContainerId(storyId),
                        getDecisionPointLogicContainerId(newDefaultValueNextDpId),
                        decisionPointLogicObj
                    );
                })
                .then((logicData) => {
                    dataToDispatch.updatedStoryDecisionPointsLogics[newDefaultValueNextDpId] = logicData;
                    return services.wireframes.setProjectWireFramesSceneInputLogic(
                        account,
                        projectName,
                        getStoryLogicContainerId(storyId),
                        getDecisionPointLogicContainerId(decisionPointLogicId),
                        newDecisionPointLogic.serializeLogicData()
                    );
                })
                .then((logicData2) => {
                    dataToDispatch.updatedStoryDecisionPointsLogics[decisionPointLogicId] = logicData2;
                    dispatch(addSceneToStory(projectName, dataToDispatch));
                    if (typeof cb === "function") {
                        cb(newDefaultValueNextDpId);
                    }

                    //add data to postgres
                    let programSummary: ProgramSummary = findProgramSummaryForProgramId(StateReaderUtils.getProgramSummaries(getState()), projectName);
                    let input: GqlClientUpdateStoryWithNewSceneInput = {
                        id: `${programSummary.projectIds.builderDraftVersionId}|${dataToDispatch.storyId}`,
                        updated: IGNORE_UPDATED,
                        newScene: {
                            name: sceneName
                        },
                        decisionPointToUpdate: {
                            id: `${programSummary.projectIds.builderDraftVersionId}|${dataToDispatch.storyId}|${decisionPointLogicId}`,
                            logic: dataToDispatch.updatedStoryDecisionPointsLogics[decisionPointLogicId],
                            updated: IGNORE_UPDATED
                        },
                        newDecisionPoint: {
                            localId: newDefaultValueNextDpId,
                            logic: dataToDispatch.updatedStoryDecisionPointsLogics[newDefaultValueNextDpId]
                        }
                    };
                    services.graphQlClient
                        .mutate({
                            mutation: UpdateStoryWithNewSceneDocument,
                            variables: {
                                input: input,
                                dryRun: false,
                                sceneLegacyId: dataToDispatch.newSceneData.id,
                                scenePartLegacyId: dataToDispatch.newSceneData.sceneParts[0].scenePart,
                                [IGNORE_SERVER_ERRORS]: true
                            }
                        })
                        .catch((err) => {});
                })
                .catch((err) => dispatch(reportError(err)));
        }
        else {
            let newSceneId = uuid();
            //Creating new DP that will point to the new scene and existing DP. this DP will get existing DP id.
            let newDefaultValue = buildAndDecorateDecisionPointLogicValue(newSceneId);
            newDefaultValueNextDpId = newDefaultValue.getNextDPLogicId();
            newDecisionPointLogic = createNewDecisionPointLogic();
            newDecisionPointLogic.setDefaultValue(newDefaultValue.serializeDecisionPointLogicValue());
            newDecisionPointLogic.setLast(false);
            let decisionPointLogicObj = StateReaderUtils.getDecisionPointsLogic(getState(), projectName, storyId, decisionPointLogicId);

            dataToDispatch.updatedStoryDecisionPointsLogics[newDefaultValueNextDpId] = decisionPointLogicObj;
            dataToDispatch.updatedStoryDecisionPointsLogics[decisionPointLogicId] = newDecisionPointLogic.serializeLogicData();

            //add data to postgres
            const storyGQLId: string = StateReaderUtils.getStoryGqlId(getState(), projectName, storyId);
            let input: GqlClientUpdateStoryWithNewSceneInput = {
                id: storyGQLId,
                updated: IGNORE_UPDATED,
                newScene: {
                    name: sceneName
                },
                decisionPointToUpdate: {
                    id: `${storyGQLId}|${decisionPointLogicId}`,
                    logic: newDecisionPointLogic.serializeLogicData(),
                    updated: IGNORE_UPDATED
                },
                newDecisionPoint: {
                    localId: newDefaultValueNextDpId,
                    logic: decisionPointLogicObj
                }
            };
            return services.graphQlClient
                .mutate({
                    mutation: UpdateStoryWithNewSceneDocument,
                    variables: {
                        input: input,
                        dryRun: false,
                        sceneLegacyId: newSceneId
                    }
                })
                .then((gqlOutput: FetchResult<GqlClientUpdateStoryWithNewSceneMutation>) => {
                    handleUpdateStoryWithNewSceneResponse(dispatch, projectName, newSceneId, sceneName,
                        dataToDispatch, gqlOutput, () => cb && cb(newDefaultValueNextDpId));
                })
                .catch((err) => {});
        }
    };
};

export const addExistingSceneBefore = (account, projectName, storyId, decisionPointLogicId, sceneId, cb) => {
    return (dispatch, getState, services: ThunkServices) => {
        let newDecisionPointLogic = createNewDecisionPointLogic();
        let dataToDispatch: dataToDispatchUpdatedStoryDecisionPointsLogics = {
            updatedStoryDecisionPointsLogics: {},
            storyId
        };
        //Creating new DP that will point to the new scene and existing DP. this DP will get existing DP id.
        let newDefaultValue = buildAndDecorateDecisionPointLogicValue(sceneId);
        let newDefaultValueNextDpId = newDefaultValue.getNextDPLogicId();
        newDecisionPointLogic.setDefaultValue(newDefaultValue.serializeDecisionPointLogicValue());
        newDecisionPointLogic.setLast(false);
        let decisionPointLogicObj = StateReaderUtils.getDecisionPointsLogic(getState(), projectName, storyId, decisionPointLogicId);
        dataToDispatch.updatedStoryDecisionPointsLogics[newDefaultValueNextDpId] = decisionPointLogicObj;
        dataToDispatch.updatedStoryDecisionPointsLogics[decisionPointLogicId] = newDecisionPointLogic.serializeLogicData();
        const dispatchAddSceneToStorySuccess = (dataToDispatch: dataToDispatchUpdatedStoryDecisionPointsLogics) => {
            dispatch(addSceneToStory(projectName, dataToDispatch));
            if (typeof cb === "function") {
                cb(newDefaultValueNextDpId);
            }
        };
        const isPostgresOnly: boolean = StateReaderUtils.isProgramBulkDynamoMigrationDoneByLegacyId(getState(), projectName, BulkId.MostEntities);
        if (!isPostgresOnly) {
            return services.wireframes
                .setProjectWireFramesSceneInputLogic(account, projectName, getStoryLogicContainerId(storyId), getDecisionPointLogicContainerId(newDefaultValueNextDpId), decisionPointLogicObj)
                .then((logicData) => {
                    return services.wireframes.setProjectWireFramesSceneInputLogic(
                        account,
                        projectName,
                        getStoryLogicContainerId(storyId),
                        getDecisionPointLogicContainerId(decisionPointLogicId),
                        newDecisionPointLogic.serializeLogicData()
                    );
                })
                .then((logicData2) => {
                    dispatchAddSceneToStorySuccess(dataToDispatch);

                    //add data to postgres
                    let programSummary: ProgramSummary = findProgramSummaryForProgramId(StateReaderUtils.getProgramSummaries(getState()), projectName);
                    let input: GqlClientUpdateStoryWithDecisionPointsInput = {
                        id: `${programSummary.projectIds.builderDraftVersionId}|${dataToDispatch.storyId}`,
                        updated: IGNORE_UPDATED,
                        newDecisionPoints: [
                            {
                                localId: newDefaultValueNextDpId,
                                logic: dataToDispatch.updatedStoryDecisionPointsLogics[newDefaultValueNextDpId]
                            }
                        ],
                        decisionPointsToUpdate: [
                            {
                                id: `${programSummary.projectIds.builderDraftVersionId}|${dataToDispatch.storyId}|${decisionPointLogicId}`,
                                logic: dataToDispatch.updatedStoryDecisionPointsLogics[decisionPointLogicId],
                                updated: IGNORE_UPDATED
                            }
                        ]
                    };
                    services.graphQlClient
                        .mutate({
                            mutation: UpdateStoryWithDecisionPointsDocument,
                            variables: {
                                input: input,
                                dryRun: false,
                                [IGNORE_SERVER_ERRORS]: true
                            }
                        })
                        .catch((err) => {});
                })
                .catch((err) => dispatch(reportError(err)));
        }
        else {
            const storyGQLId: string = StateReaderUtils.getStoryGqlId(getState(), projectName, storyId);
            let input: GqlClientUpdateStoryWithDecisionPointsInput = {
                id: storyGQLId,
                updated: IGNORE_UPDATED,
                newDecisionPoints: [
                    {
                        localId: newDefaultValueNextDpId,
                        logic: dataToDispatch.updatedStoryDecisionPointsLogics[newDefaultValueNextDpId]
                    }
                ],
                decisionPointsToUpdate: [
                    {
                        id: `${storyGQLId}|${decisionPointLogicId}`,
                        logic: dataToDispatch.updatedStoryDecisionPointsLogics[decisionPointLogicId],
                        updated: IGNORE_UPDATED
                    }
                ]
            };
            return services.graphQlClient
                .mutate({
                    mutation: UpdateStoryWithDecisionPointsDocument,
                    variables: {
                        input: input,
                        dryRun: false
                    }
                })
                .then((gqlOutput: FetchResult<GqlClientUpdateStoryWithDecisionPointsMutation>) => {
                    if (gqlOutput.data.updateStoryWithDecisionPoints.__typename === "UpdateStoryWithDecisionPointsOutputSuccess") {
                        dataToDispatch.storyDataToUpdate = {
                            graphQLUpdated: gqlOutput.data.updateStoryWithDecisionPoints.story.updated
                        };
                        dispatchAddSceneToStorySuccess(dataToDispatch);
                    }
                })
                .catch((err) => {});
        }
    };
};

export const updateDecisionPointLogicAndAddNewDecisionPointLogic = (account, projectName, storyId, decisionPointLogicId, decisionPointLogic, newDPLogicItemsIdsArray) => {
    return (dispatch, getState, services: ThunkServices) => {
        let newDecisionPointLogic = createNewDecisionPointLogic().serializeLogicData();
        let dataToDispatch: dataToDispatchUpdatedStoryDecisionPointsLogics = {
            storyId,
            updatedStoryDecisionPointsLogics: {}
        };
        newDPLogicItemsIdsArray.forEach((logicId) => {
            dataToDispatch.updatedStoryDecisionPointsLogics[logicId] = newDecisionPointLogic;
        });
        dataToDispatch.updatedStoryDecisionPointsLogics[decisionPointLogicId] = decisionPointLogic;
        const dispatchUpdateDPLogicAndAddNewDPLogicSuccess = (dataToDispatch: dataToDispatchUpdatedStoryDecisionPointsLogics) => {
            dispatch(updateDPLogicAndAddNewDPLogic(projectName, dataToDispatch));
        };
        const isPostgresOnly: boolean = StateReaderUtils.isProgramBulkDynamoMigrationDoneByLegacyId(getState(), projectName, BulkId.MostEntities);
        if (!isPostgresOnly) {
            let promiseArr = newDPLogicItemsIdsArray.map((newDecisionPointLogicId) =>
                services.wireframes.setProjectWireFramesSceneInputLogic(
                    account,
                    projectName,
                    getStoryLogicContainerId(storyId),
                    getDecisionPointLogicContainerId(newDecisionPointLogicId),
                    newDecisionPointLogic
                ));
            return Promise.all(promiseArr)
                .then((logicDataArray) => {
                    return services.wireframes.setProjectWireFramesSceneInputLogic(
                        account,
                        projectName,
                        getStoryLogicContainerId(storyId),
                        getDecisionPointLogicContainerId(decisionPointLogicId),
                        decisionPointLogic
                    );
                })
                .then((logicData2) => {
                    dispatchUpdateDPLogicAndAddNewDPLogicSuccess(dataToDispatch);

                    //add data to postgres
                    let programSummary: ProgramSummary = findProgramSummaryForProgramId(StateReaderUtils.getProgramSummaries(getState()), projectName);
                    let input: GqlClientUpdateStoryWithDecisionPointsInput = {
                        id: `${programSummary.projectIds.builderDraftVersionId}|${dataToDispatch.storyId}`,
                        updated: IGNORE_UPDATED,
                        newDecisionPoints: newDPLogicItemsIdsArray.map((dpId) => {
                            return {
                                localId: dpId,
                                logic: dataToDispatch.updatedStoryDecisionPointsLogics[dpId]
                            };
                        }),
                        decisionPointsToUpdate: [
                            {
                                id: `${programSummary.projectIds.builderDraftVersionId}|${dataToDispatch.storyId}|${decisionPointLogicId}`,
                                logic: dataToDispatch.updatedStoryDecisionPointsLogics[decisionPointLogicId],
                                updated: IGNORE_UPDATED
                            }
                        ]
                    };
                    services.graphQlClient
                        .mutate({
                            mutation: UpdateStoryWithDecisionPointsDocument,
                            variables: {
                                input: input,
                                dryRun: false,
                                [IGNORE_SERVER_ERRORS]: true
                            }
                        })
                        .catch((err) => {});
                })
                .catch((err) => dispatch(reportError(err)));
        }
        else {
            const storyGQLId: string = StateReaderUtils.getStoryGqlId(getState(), projectName, storyId);
            let input: GqlClientUpdateStoryWithDecisionPointsInput = {
                id: storyGQLId,
                updated: IGNORE_UPDATED,
                newDecisionPoints: newDPLogicItemsIdsArray.map((dpId) => {
                    return {
                        localId: dpId,
                        logic: dataToDispatch.updatedStoryDecisionPointsLogics[dpId]
                    };
                }),
                decisionPointsToUpdate: [
                    {
                        id: `${storyGQLId}|${decisionPointLogicId}`,
                        logic: dataToDispatch.updatedStoryDecisionPointsLogics[decisionPointLogicId],
                        updated: IGNORE_UPDATED
                    }
                ]
            };
            return services.graphQlClient
                .mutate({
                    mutation: UpdateStoryWithDecisionPointsDocument,
                    variables: {
                        input: input,
                        dryRun: false
                    }
                })
                .then((gqlOutput: FetchResult<GqlClientUpdateStoryWithDecisionPointsMutation>) => {
                    if (gqlOutput.data.updateStoryWithDecisionPoints.__typename === "UpdateStoryWithDecisionPointsOutputSuccess") {
                        dataToDispatch.storyDataToUpdate = {
                            graphQLUpdated: gqlOutput.data.updateStoryWithDecisionPoints.story.updated
                        };
                        dispatchUpdateDPLogicAndAddNewDPLogicSuccess(dataToDispatch);
                    }
                })
                .catch((err) => {});
        }

    };
};

export const updateDecisionPointLogic = (account, projectName, storyId, decisionPointLogicId, decisionPointLogic) => {
    return (dispatch, getState, services: ThunkServices) => {
        let dataToDispatch: dataToDispatchUpdatedStoryDecisionPointsLogics = {
            storyId,
            updatedStoryDecisionPointsLogics: {
                [decisionPointLogicId]: decisionPointLogic
            }
        };
        const isPostgresOnly: boolean = StateReaderUtils.isProgramBulkDynamoMigrationDoneByLegacyId(getState(), projectName, BulkId.MostEntities);
        if (!isPostgresOnly) {
            return services.wireframes
                .setProjectWireFramesSceneInputLogic(account, projectName, getStoryLogicContainerId(storyId), getDecisionPointLogicContainerId(decisionPointLogicId), decisionPointLogic)
                .then((logicData) => {
                    dispatch(updateDPLogic(projectName, dataToDispatch));

                    //add data to postgres
                    let programSummary: ProgramSummary = findProgramSummaryForProgramId(StateReaderUtils.getProgramSummaries(getState()), projectName);
                    let input: GqlClientUpdateStoryWithDecisionPointsInput = {
                        id: `${programSummary.projectIds.builderDraftVersionId}|${dataToDispatch.storyId}`,
                        updated: IGNORE_UPDATED,
                        decisionPointsToUpdate: [
                            {
                                id: `${programSummary.projectIds.builderDraftVersionId}|${dataToDispatch.storyId}|${decisionPointLogicId}`,
                                logic: dataToDispatch.updatedStoryDecisionPointsLogics[decisionPointLogicId],
                                updated: IGNORE_UPDATED
                            }
                        ]
                    };
                    services.graphQlClient
                        .mutate({
                            mutation: UpdateStoryWithDecisionPointsDocument,
                            variables: {
                                input: input,
                                dryRun: false,
                                [IGNORE_SERVER_ERRORS]: true
                            }
                        })
                        .catch((err) => {});
                })
                .catch((err) => dispatch(reportError(err)));
        }
        else {
            const storyGQLId: string = StateReaderUtils.getStoryGqlId(getState(), projectName, storyId);
            let input: GqlClientUpdateStoryWithDecisionPointsInput = {
                id: storyGQLId,
                updated: IGNORE_UPDATED,
                decisionPointsToUpdate: [
                    {
                        id: `${storyGQLId}|${decisionPointLogicId}`,
                        logic: dataToDispatch.updatedStoryDecisionPointsLogics[decisionPointLogicId],
                        updated: IGNORE_UPDATED
                    }
                ]
            };
            return services.graphQlClient
                .mutate({
                    mutation: UpdateStoryWithDecisionPointsDocument,
                    variables: {
                        input: input,
                        dryRun: false
                    }
                })
                .then((gqlOutput: FetchResult<GqlClientUpdateStoryWithDecisionPointsMutation>) => {
                    if (gqlOutput.data.updateStoryWithDecisionPoints.__typename === "UpdateStoryWithDecisionPointsOutputSuccess") {
                        dataToDispatch.storyDataToUpdate = {
                            graphQLUpdated: gqlOutput.data.updateStoryWithDecisionPoints.story.updated
                        };
                        dispatch(updateDPLogic(projectName, dataToDispatch));
                    }
                })
                .catch((err) => {});
        }
    };
};

export const updateSoundtrackLogic = (account, projectName, storyId, soundtrackLogic) => {
    return updateStoryLogic(account, projectName, storyId, LogicContainers.Soundtrack, MediaTypes.Audio, soundtrackLogic);

};

export const updateStoryBackgroundAssetLogic = (account, projectName, storyId, backgroundAssetType: MediaTypes, backgroundLogic: LogicJSON) => {
    return updateStoryLogic(account, projectName, storyId, LogicContainers.BackgroundAsset, backgroundAssetType, backgroundLogic);
};

export const updateStoryVideoRatioQualityLogic = (account: string, projectName: string, storyId: string, ratioQualityLogic: LogicJSON) => {
    return updateStoryLogic(account, projectName, storyId, LogicContainers.VideoSettings, LogicContext.StoryRatioAndQuality, ratioQualityLogic);
};

const updateStoryLogic = (account, projectName, storyId, logicContainerType: LogicContainers, logicId: MediaTypes| LogicContext, storyLogic: LogicJSON) => {
    return (dispatch, getState, services: ThunkServices) => {
        let dataToDispatch: dataToDispatchUpdatedStoryLogicItems = {
            storyId,
            logicContainerType: logicContainerType,
            updatedStoryLogicItems: {
                [logicId]: storyLogic
            }
        };
        const storyGQLId: string = StateReaderUtils.getStoryGqlId(getState(), projectName, storyId);
        let input: GqlClientUpdateStoryForBuilderInput = {
            id: storyGQLId,
            updated: IGNORE_UPDATED
        };
        switch (logicId) {
            case MediaTypes.Image: {
                input.backgroundImageLogic = storyLogic;
                break;
            }
            case MediaTypes.Video: {
                input.backgroundVideoLogic = storyLogic;
                break;
            }
            case MediaTypes.Audio: {
                input.backgroundAudioLogic = storyLogic;
                break;
            }
            case LogicContext.StoryRatioAndQuality: {
                input.ratioAndQualityLogic = storyLogic;
                break;
            }
            default: {
                break;
            }
        }
        return services.graphQlClient
            .mutate({
                mutation: UpdateStoryForBuilderDocument,
                variables: {
                    input: input,
                    dryRun: false
                }
            })
            .then((gqlOutput: FetchResult<GqlClientUpdateStoryForBuilderMutation>) => {
                if (gqlOutput.data.updateStoryForBuilder.__typename === "UpdateStoryForBuilderOutputSuccess") {
                    dataToDispatch.storyDataToUpdate = {
                        graphQLUpdated: gqlOutput.data.updateStoryForBuilder.story.updated
                    };
                    dispatch(updateStoryLogicItems(projectName, dataToDispatch));
                }
            })
            .catch((err) => {});
    };
};

export const mergeDecisionPoints = (accountId, projectName, storyId, decisionPointAId, decisionPointBId, cb) => {
    return (dispatch, getState, services: ThunkServices) => {
        // We assume there are no circles in graph and the merged DP are't pointing each other!
        let dataToDispatch: dataToDispatchUpdatedStoryDecisionPointsLogics = {
            storyId,
            updatedStoryDecisionPointsLogics: {},
            decisionPointLogicIdToDelete: ""
        };
        const storyDecisionPointsObj = StateReaderUtils.getDecisionPointLogicItemsForStory(getState(), projectName, storyId);

        if (!isLogicEmpty(storyDecisionPointsObj[decisionPointAId]) && !isLogicEmpty(storyDecisionPointsObj[decisionPointBId])) {
            dispatch(reportError("illegal merge action"));
            if (typeof cb === "function") {
                cb(decisionPointAId);
            }
        }
        else {
            let prevDpId = decisionPointBId;
            let nextDpId = decisionPointAId;
            let nextDpLogic = !isLogicEmpty(storyDecisionPointsObj[decisionPointBId]) ? storyDecisionPointsObj[decisionPointBId] : storyDecisionPointsObj[decisionPointAId];

            let updatedStoryDecisionPointsObj = Object.keys(storyDecisionPointsObj).reduce((acc, dpId) => {
                if (isDPLogicItemContainDPId(storyDecisionPointsObj[dpId], prevDpId)) {
                    acc[dpId] = replaceDPLogicIdWithOther(storyDecisionPointsObj[dpId], prevDpId, nextDpId);
                }
                return acc;
            }, {});
            updatedStoryDecisionPointsObj[nextDpId] = nextDpLogic;
            dataToDispatch.updatedStoryDecisionPointsLogics = updatedStoryDecisionPointsObj;
            dataToDispatch.decisionPointLogicIdToDelete = prevDpId;
            const dispatchMergeDecisionPointsSuccess = (dataToDispatch: dataToDispatchUpdatedStoryDecisionPointsLogics) => {
                dispatch(mergeDecisionPointsSuccess(projectName, dataToDispatch));
                if (typeof cb === "function") {
                    cb(nextDpId);
                }
            };
            const isPostgresOnly: boolean = StateReaderUtils.isProgramBulkDynamoMigrationDoneByLegacyId(getState(), projectName, BulkId.MostEntities);
            if (!isPostgresOnly) {
                let promiseArray = Object.keys(updatedStoryDecisionPointsObj).map((dpID) => {
                    return services.wireframes.setProjectWireFramesSceneInputLogic(
                        accountId,
                        projectName,
                        getStoryLogicContainerId(storyId),
                        getDecisionPointLogicContainerId(dpID),
                        updatedStoryDecisionPointsObj[dpID]
                    );
                });

                return Promise.all(promiseArray)
                    .then((updateDecisionPointLogic) => {
                        dispatchMergeDecisionPointsSuccess(dataToDispatch);
                        return services.wireframes
                            .deleteProjectWireFramesInputLogic(accountId, projectName, getStoryLogicContainerId(storyId), getDecisionPointLogicContainerId(prevDpId))
                            .then(() => {
                                //add data to postgres
                                let programSummary: ProgramSummary = findProgramSummaryForProgramId(StateReaderUtils.getProgramSummaries(getState()), projectName);
                                let input: GqlClientUpdateStoryWithDecisionPointsInput = {
                                    id: `${programSummary.projectIds.builderDraftVersionId}|${dataToDispatch.storyId}`,
                                    updated: IGNORE_UPDATED,
                                    decisionPointsToUpdate: Object.keys(dataToDispatch.updatedStoryDecisionPointsLogics).map((dpId) => {
                                        return {
                                            id: `${programSummary.projectIds.builderDraftVersionId}|${dataToDispatch.storyId}|${dpId}`,
                                            logic: dataToDispatch.updatedStoryDecisionPointsLogics[dpId],
                                            updated: IGNORE_UPDATED
                                        };
                                    }),
                                    decisionPointsToDeleteIds: [`${programSummary.projectIds.builderDraftVersionId}|${dataToDispatch.storyId}|${prevDpId}`]
                                };
                                services.graphQlClient
                                    .mutate({
                                        mutation: UpdateStoryWithDecisionPointsDocument,
                                        variables: {
                                            input: input,
                                            dryRun: false,
                                            [IGNORE_SERVER_ERRORS]: true
                                        }
                                    })
                                    .catch((err) => {});
                            })
                            .catch((err) => {});
                    })
                    .catch((err) => dispatch(reportError(err)));
            }
            else {
                const storyGQLId: string = StateReaderUtils.getStoryGqlId(getState(), projectName, storyId);
                let input: GqlClientUpdateStoryWithDecisionPointsInput = {
                    id: storyGQLId,
                    updated: IGNORE_UPDATED,
                    decisionPointsToUpdate: Object.keys(dataToDispatch.updatedStoryDecisionPointsLogics).map((dpId) => {
                        return {
                            id: `${storyGQLId}|${dpId}`,
                            logic: dataToDispatch.updatedStoryDecisionPointsLogics[dpId],
                            updated: IGNORE_UPDATED
                        };
                    }),
                    decisionPointsToDeleteIds: [`${storyGQLId}|${prevDpId}`]
                };
                return services.graphQlClient
                    .mutate({
                        mutation: UpdateStoryWithDecisionPointsDocument,
                        variables: {
                            input: input,
                            dryRun: false
                        }
                    })
                    .then((gqlOutput: FetchResult<GqlClientUpdateStoryWithDecisionPointsMutation>) => {
                        if (gqlOutput.data.updateStoryWithDecisionPoints.__typename === "UpdateStoryWithDecisionPointsOutputSuccess") {
                            dataToDispatch.storyDataToUpdate = {
                                graphQLUpdated: gqlOutput.data.updateStoryWithDecisionPoints.story.updated
                            };
                            dispatchMergeDecisionPointsSuccess(dataToDispatch);
                        }
                    })
                    .catch((err) => {});
            }
        }
    };
};

export const moveProjectWireframesSceneInStory = function(accountId: string, projectName: string, storyId: string, sceneId: string, storyScenesOrder: string[], offset: number) {
    return function(dispatch, getState, services: ThunkServices) {
        let story = StateReaderUtils.getStoryById(StateReaderUtils.getWireFrame(getState(), projectName), storyId);
        let index: number = (storyScenesOrder || []).indexOf(sceneId);
        let newIndex: number = index + offset;
        if (newIndex < 0) newIndex = 0;
        if (newIndex >= storyScenesOrder.length) newIndex = storyScenesOrder.length - 1;
        let newScenesOrder = storyScenesOrder.filter((id) => id !== sceneId);
        newScenesOrder = [...newScenesOrder.slice(0, newIndex), sceneId, ...newScenesOrder.slice(newIndex)];
        const scenes: Scene[] = StateReaderUtils.getProjectWireframeScenes(getState(), projectName);
        const input: GqlClientUpdateStoryForBuilderInput = {
            id: story.graphQLId,
            updated: IGNORE_UPDATED,
            displaySceneOrder: newScenesOrder && (newScenesOrder.map(sceneLocalId => scenes[sceneLocalId]?.graphQLId).filter(id => !!id))
        };
        return services.graphQlClient
            .mutate({
                mutation: UpdateStoryForBuilderDocument,
                variables: {
                    input: input,
                    dryRun: false
                }
            })
            .then((gqlOutput: FetchResult<GqlClientUpdateStoryForBuilderMutation>) => {
                if (gqlOutput.data.updateStoryForBuilder.__typename === "UpdateStoryForBuilderOutputSuccess") {
                    const updatedStoryObject = {
                        ...story,
                        displaySceneOrder: newScenesOrder,
                        graphQLUpdated: gqlOutput.data.updateStoryForBuilder.story.updated
                    };
                    dispatch(updateStory(accountId, projectName, storyId, updatedStoryObject));
                }
            })
            .catch((err) => {});
    };
};
