import HubNotifications from "./hubNotifications";
import { LogicContainers } from "../../../common/commonConst";
import type { Scene } from "../../../common/types/scene";
import type { Cell, ThunkServices } from "./types";
import { Order } from "./types";
import type { LoadedNarration } from "../projects/projectWireframes/projectsWireframesActions";
import TimeUtils from "./timeUtils";
import { sameArrays } from "../../../common/compareUtils";

export function deepCloneObj<T>(obj: T, notifyUser = true): T {
    if (obj === undefined) return;
    try {
        return JSON.parse(JSON.stringify(obj));
    }
    catch (err) {
        // console.error(err);
        if (notifyUser) HubNotifications.error(err);
    }
}

type SceneID = string;
type Scenes = { [sceneId: string]: Scene } | Scene[] | SceneID[];
// 'scenes' is expected to be either scenes object or array of scenes/scenes IDs
export function removeMasterFromScenes<Scenes>(scenes: Scenes): Scenes {
    let scenesCopy: Scenes = deepCloneObj(scenes);
    if (Array.isArray(scenesCopy)) {
        const masterIndex: number = scenesCopy.findIndex((scene) => (scene.id || scene) === LogicContainers.Master);
        masterIndex > -1 && scenesCopy.splice(masterIndex, 1);
    }
    else if (typeof scenesCopy === "object") {
        delete scenesCopy[LogicContainers.Master];
    }
    return scenesCopy;
}


export const getCircularReplacer = () => {
    // usage:
    // JSON.stringify(circularReference, getCircularReplacer())
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Cyclic_object_value#Examples
    const seen = new WeakSet();
    return (key, value) => {
        if (typeof value === "object" && value !== null) {
            if (seen.has(value)) {
                return;
            }
            seen.add(value);
        }
        return value;
    };
};

export const printPropsStateChanges = (props, prevProps, state, prevState, verbose = false) => {
    // print (SHALLOW) changes in state and props.
    // put in componentDidUpdate and enable the console.logs
    // use verbose with care...

    const isObject = (something) => something && typeof something === "object";
    const printer = (something) => (isObject(something) ? JSON.stringify(something, getCircularReplacer()) : something);

    Object.entries(props).forEach(([key, val]) => {
        if (prevProps[key] !== val) {
            let txt = `Prop '${key}' changed`;
            if (verbose) {
                txt += ` from ${printer(prevProps[key])} to ${printer(val)}`;
            }
            // console.log(txt);
        }
    });

    Object.entries(state).forEach(([key, val]) => {
        if (prevState[key] !== val) {
            let txt = `State '${key}' changed`;
            if (verbose) {
                txt += ` from ${printer(prevState[key])} to ${printer(val)}`;
            }
            // console.log(txt);
        }
    });
};

export function sortByPropName(cellA: Cell, cellB: Cell, orderBy, order, propName: string) {
    const nameA = (cellA.value as JSX.Element).props[propName];
    const nameB = (cellB.value as JSX.Element).props[propName];
    return order === "desc" ? nameB.localeCompare(nameA) : nameA.localeCompare(nameB);
}

export function sortByString(cellA: {value: string}, cellB: {value: string}, orderBy, order) {
    const valA = cellA.value;
    const valB = cellB.value;

    return order === "desc" ? valB.localeCompare(valA) : valA.localeCompare(valB);
}

export function sortByDisplayText(cellA: Cell, cellB: Cell, orderBy, order) {
    const nameA = (cellA.value as JSX.Element).props.textValue;
    const nameB = (cellB.value as JSX.Element).props.textValue;
    return order === "desc" ? nameB.localeCompare(nameA) : nameA.localeCompare(nameB);
}

function extractDateFromVersionString(cell: Cell) {
    // Getting only the first part of the version string which is the date.
    return (cell as {value: string}).value.split(",")[0];
}

export function sortByVersionString(cellA: Cell, cellB: Cell, orderBy: string, order: Order) {
    if (typeof cellA.value !== "string") {
        return order === Order.Desc ? 1 : -1;
    }
    else if (typeof cellB.value !== "string") {
        return order === Order.Desc ? -1 : 1;
    }

    return sortByDate({ value: extractDateFromVersionString(cellA) }, { value: extractDateFromVersionString(cellB) }, orderBy, order);
}

export function getSortByDisplayDateFunc(format?: string): (cellA: Cell, cellB: Cell, orderBy: string, order: Order) => number {
    return (cellA, cellB, orderBy, order) => sortByDate(cellA, cellB, orderBy, order, format);
}

function sortByDate(cellA: Cell, cellB: Cell, orderBy: string, order: Order, format?: string): number {
    const dateA = typeof cellA.value === "string" ? cellA.value : (cellA.value as JSX.Element).props.textValue;
    const dateB = typeof cellB.value === "string" ? cellB.value : (cellB.value as JSX.Element).props.textValue;
    return order === Order.Desc ? TimeUtils.compare(dateB, dateA, format) : TimeUtils.compare(dateA, dateB, format);
}

export function convertNarrationArrayOfObjectsToObject<T>(narrationArr: LoadedNarration[]): { [key: string]: T } {
    const representativeField = "id";
    if (narrationArr === undefined) return;
    return narrationArr.reduce((acc, item: LoadedNarration) => {
        if (item.dbNarration.postgresNarrationId) {
            acc[item.dbNarration.postgresNarrationId] = item;
        }
        else if (item[representativeField]) {
            acc[item[representativeField]] = item;
        }
        return acc;
    }, {});
}


type ThunkAction = (distach, getState, services?: ThunkServices) => void;

export const memoizeThunkAction = (thunkAction: (...args) => ThunkAction) => {
    let lastArgs;

    return (...args) : ThunkAction => {
        if (sameArrays(lastArgs, args)) {
            return () => {};
        }
        else {
            lastArgs = Array.from(args);
            return thunkAction(...args);
        }
    };
};
