import { fetchFile } from "@ffmpeg/util";

export type FfmpegOnMessage = (message: string) => void;

const getFFmpeg = async (onMessage?: FfmpegOnMessage) => {
    const { FFmpeg } = await import("@ffmpeg/ffmpeg");
    const ffmpeg = new FFmpeg();
    await ffmpeg.load();
    if (typeof onMessage === "function") {
        ffmpeg.on("log", ({ message }) => {
            onMessage(message);
        });
    }
    return ffmpeg;
};

const runMediainfo = async (ffmpeg): Promise<any> => {
    await ffmpeg.ffprobe(["-v", "quiet", "-print_format", "json", "-show_format", "-show_streams", "input.any", "-o", "output.json"]);
    const data = await ffmpeg.readFile("output.json");
    const text = new TextDecoder().decode(data as Uint8Array);
    return JSON.parse(text);
};

export const getMediainfo = async (input: Uint8Array): Promise<any> => {
    const ffmpeg = await getFFmpeg();
    await ffmpeg.writeFile("input.any", input);
    return runMediainfo(ffmpeg);
};

export const transcodeToMp4 = async (blob: Blob, onMessageCallback?: FfmpegOnMessage): Promise<Blob> => {
    const input: Uint8Array = await fetchFile(URL.createObjectURL(blob));

    const ffmpeg = await getFFmpeg(onMessageCallback);
    await ffmpeg.writeFile("input.any", input);

    // Mediainfo
    const inputMediainfo = await runMediainfo(ffmpeg);
    
    const visualStream = inputMediainfo?.streams?.find((stream) => stream.width && stream.height);
    const audioStream = inputMediainfo?.streams?.find((stream) => stream.codec_type === "audio");
    const inputVideoCodecName = visualStream?.codec_name;
    const inputAudioCodecName = audioStream?.codec_name;
    const isCopyVideoStream = inputVideoCodecName?.toLowerCase() === "h264";
    const isCopyAudioStream = inputAudioCodecName?.toLowerCase() === "aac";

    // input
    const ffmpegCommand = ["-i", "input.any"];
    // video codec
    if (isCopyVideoStream) {
        ffmpegCommand.push("-c:v", "copy");
    }
    else {
        ffmpegCommand.push("-c:v", "libx264");
    }
    // audio codec
    if (isCopyAudioStream) {
        ffmpegCommand.push("-c:a", "copy");
    }
    else if (audioStream) {
        ffmpegCommand.push("-c:a", "aac");
    }
    else {
        ffmpegCommand.push("-an");
    }
    // output
    ffmpegCommand.push("output.mp4");

    onMessageCallback?.(`Transcoding to mp4 with command: ffmpeg ${ffmpegCommand.join(" ")}`);

    await ffmpeg.exec(ffmpegCommand);
    const data = await ffmpeg.readFile("output.mp4");
    // @ts-ignore
    return new Blob([data.buffer], { type: "video/mp4" });
};
