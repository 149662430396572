import type { EntityTypes } from "../entities/definitions";
import type { IDiffTableRecord } from "./diffTableRecord";

export interface IDiffTable {
    getByKey(key: string): IDiffTableRecord;
    putByKey(key: string, record: IDiffTableRecord): void;
    getAllRecords(): IDiffTableRecord[];
    getAllKeysAndRecords(): [string, IDiffTableRecord][];
    deleteRecordByKey(key: string): void;
}

export type DiffTableType = { [id: string]: IDiffTableRecord };

export default class DiffTable implements IDiffTable {
    private readonly table: DiffTableType;

    constructor() {
        this.table = {};
    }

    getByKey = (key: string): IDiffTableRecord => this.table[key];

    putByKey = (key: string, record: IDiffTableRecord): void => {
        this.table[key] = record;
    };

    getAllRecords = (): IDiffTableRecord[] => Object.values(this.table);

    getAllKeysAndRecords = (): [string, IDiffTableRecord][] => Object.entries(this.table);

    static getTableKey = (type: EntityTypes, id: string): string => `${type}/${id}`;

    deleteRecordByKey = (key: string): void => {
        delete this.table[key];
    };
}
