import { createAction } from "redux-actions";
import { reportError, setLoading } from "../common/commonActions";
import { loadingCreativeRecordingAssetsSuccess, loadingProjectAssetsCountSuccess, loadingProjectAssetsSuccess } from "./projectAssets/projectAssetsActions";
import StateReaderUtils from "../common/StateReaderUtils";
import type { AccountsDBProgram, Program, ProgramSummary } from "../../../common/types/program";
import AssetUtils from "../../logic/common/assetUtils";
import { adsDataElements, CC_LIBRARY_ID, LogicContainers, programDefaultDynamoMigrationStatusFields, programTypes } from "../../../common/commonConst";
import { getDynamoLogicContainerId } from "../stories/StoryActions";
import type { LogicJSON } from "../../../common/types/logic";
import { featureFlagConst } from "@sundaysky/smartvideo-hub-config";
import type {
    CreateRecordingDocMutationResult,
    GetProgramWithAssetsQueryResult,
    GqlClientAnimationFragment,
    GqlClientAssetFragment,
    GqlClientCopyDraftEditorProgramBetweenAccountsInput,
    GqlClientCopyDraftEditorProgramBetweenAccountsMutation,
    GqlClientCopyEditorProgramMutation,
    GqlClientCopyProgramInput,
    GqlClientCopyProgramMutation,
    GqlClientCreateEditorProgramMutation,
    GqlClientCreateEditorProgramMutationVariables,
    GqlClientCreateProgramAndBuilderDraftMutation,
    GqlClientCreateProgramAndBuilderDraftMutationVariables,
    GqlClientCreateProgramVersionDraftMutation,
    GqlClientCreateProgramVersionDraftMutationVariables,
    GqlClientCreateRecordingDocInput,
    GqlClientCustomAnalyticFieldFragment,
    GqlClientDataTableFragment,
    GqlClientGetProgramLifecycleHistoryQuery,
    GqlClientGetProgramVersionSnapshotsQuery,
    GqlClientGetProgramWithAssetsQuery,
    GqlClientGetProgramWithAssetsQueryVariables,
    GqlClientLifecycleStage,
    GqlClientNarratorFragment,
    GqlClientProgram,
    GqlClientProgramFragment,
    GqlClientProgramVersionSnapshotDetailsFragment,
    GqlClientUpdateEditorIsArchivedInput,
    GqlClientUpdateEditorIsArchivedMutation,
    GqlClientUpdateProgramInput,
    GqlClientUpdateProgramMutation,
    GqlClientUpdateProgramVersionDraftInput,
    GqlClientUpdateProgramVersionMutation,
    GqlClientNewlyCreatedEditorProgramFragment
} from "../../graphql/graphqlGeneratedTypes/graphqlClient";
import {
    CopyDraftEditorProgramBetweenAccountsDocument,
    CopyEditorProgramDocument,
    CopyProgramDocument,
    CreateEditorProgramDocument,
    CreateProgramAndBuilderDraftDocument,
    CreateProgramVersionDraftDocument,
    CreateRecordingDocDocument,
    GetAllCreativesNarrationRecordingsDocument,
    GetProgramDocument,
    GetProgramLifecycleHistoryDocument,
    GetProgramVersionSnapshotsDocument,
    GetProgramWithAssetsDocument,
    GqlClientUpdateEditorIsArchivedResult,
    UpdateEditorIsArchivedDocument,
    UpdateProgramDocument,
    UpdateProgramVersionDocument
} from "../../graphql/graphqlGeneratedTypes/graphqlClient";
import { IGNORE_SERVER_ERRORS, IGNORE_UPDATED } from "../common/Consts";
import type { ApolloQueryResult, FetchResult } from "@apollo/client";
import Entities from "../framework/entities";
import { getGQLEditorProgramType, getGQLProgramType } from "../common/programUtils";
import type { AnimationItem, ConvertedGqlAsset, ConvertedGqlNarrator, ConvertedGqlRecordingAsset, RecordingDocItem } from "../../../common/types/asset";
import { AssetTypes } from "../../../common/types/asset";
import { convertPostgresAnimationToDynamoAnimation, convertPostgresAssetToDynamoAsset } from "./projectAssets/gqlUtils";
import { loadFeatureFlags, loadProgramsMigrationStatus } from "../featureFlags/featureFlagsActions";
import type { ThunkServices, WithGraphQLId } from "../common/types";
import { BulkId } from "../common/types";
import {
    buildDataTableFromGql,
    buildNarrationRecordingsFromGql,
    buildNarratorsFromGql,
    buildProgramFromGql,
    buildStateSnapshotAssetsFromGql,
    buildStateSnapshotFromGql,
    buildStateVersionsFromGql
} from "../common/convertGqlEntityToWireframesUtils";
import type { ProgramToBeCreated } from "../../components/newProject/CreateProjectContainer";
import { convertGqlLifecycleHistoryResult } from "../common/convertGqlLifecycleHistoryUtils";
import type { LifecycleHistory, StageLifeHistory } from "../../../common/types/lifecycle";
import { Stages } from "../../../common/types/lifecycle";
import type { DataTable } from "../../../common/types/dataTable";
import { v4 as uuid } from "uuid";
import type { Snapshot, SnapshotAsset } from "../../../common/types/snapshot";
import { SnapshotSource } from "../../../common/types/snapshot";
import { convertArrayOfObjectsToObject } from "../../../common/arrayUtils";
import { memoizeThunkAction } from "../common/generalUtils";
import { loadingProjectSnapshotSuccess, loadProjectSnapshotPromise } from "./projectLifecycle/projectLifecycleActions";
import type { DuplicateProgramSnapshot } from "../../components/projectDetails/DuplicateProgramPopup";
import { getActiveStages } from "../../components/versionManager/VersionManagerUtil";
import { getSskyErrorCodeFromGqlError, SskyErrorCode } from "../../../common/errors";
import { StudioMainCategoriesPaths } from "@sundaysky/smartvideo-hub-urls";

const { CREATIVE } = featureFlagConst;

export const LOADING_ALL_PROJECTS_SUCCESS = "LOADING_ALL_PROJECTS_SUCCESS";
export const LOADING_PROJECT_DETAILS = "LOADING_PROJECT_DETAILS";
export const LOADING_PROJECT_DETAILS_SUCCESS = "LOADING_PROJECT_DETAILS_SUCCESS";
export const ADD_PROJECT_SUCCESS = "ADD_PROJECT_SUCCESS";
export const DELETE_PROJECT_COMPLETE = "DELETE_PROJECT_COMPLETE";
export const UPDATE_PROJECT_COMPLETE = "UPDATE_PROJECT_COMPLETE";
export const GET_PROJECT_VERSIONS_COMPLETE = "GET_PROJECT_VERSIONS_COMPLETE";

export const LOAD_PROJECT_SUMMARIES_SUCCESS = "LOAD_PROJECT_SUMMARIES_SUCCESS";
export const loadProjectSummariesSuccess = createAction(LOAD_PROJECT_SUMMARIES_SUCCESS, (projectSummaries: ProgramSummary[]) => ({ projectSummaries }));

export const UPDATE_PROGRAM_SUMMARY_SUCCESS = "UPDATE_PROGRAM_SUMMARY_SUCCESS";
export const updateProgramSummarySuccess = createAction(UPDATE_PROGRAM_SUMMARY_SUCCESS, (programId: string, updatedFields: Partial<ProgramSummary>) => ({ programId, updatedFields }));

export const loadingProjectDetails = createAction(LOADING_PROJECT_DETAILS, (project) => ({ project }));
export const loadingProjectDetailsSuccess = createAction(LOADING_PROJECT_DETAILS_SUCCESS, (project) => ({ project }));
export const loadingAllProjectsSuccess = createAction(LOADING_ALL_PROJECTS_SUCCESS, (projects) => ({ projects }));

export const LOADING_GQL_PROGRAM_SUCCESS = "LOADING_GQL_PROJECT_SUCCESS";
export const loadingGqlProgramSuccess = createAction(LOADING_GQL_PROGRAM_SUCCESS, (program: GqlClientProgramFragment | GqlClientCustomAnalyticFieldFragment["program"]) => ({ program }));

export const LOADING_NEW_PROGRAMS_SUCCESS = "LOADING_NEW_PROGRAMS_SUCCESS";
export const getNewProgramsSuccess = createAction(LOADING_NEW_PROGRAMS_SUCCESS, function(accountId, newPrograms: AccountsDBProgram[]) {
    return { accountId, newPrograms };
});

export const LOADING_PROJECT_LIFECYCLE_SUCCESS = "LOADING_PROJECT_LIFECYCLE_SUCCESS";
export const loadingProjectLifeCycleSuccess = createAction(LOADING_PROJECT_LIFECYCLE_SUCCESS, function(accountId, projectName, projectLifeCycle) {
    return { accountId, projectName, projectLifeCycle };
});
export const LOADING_PROJECT_LIFECYCLE_STAGE_SUCCESS = "LOADING_PROJECT_LIFECYCLE_STAGE_SUCCESS";
export const loadingProjectLifeCycleStageSuccess = createAction(LOADING_PROJECT_LIFECYCLE_STAGE_SUCCESS, function(accountId, projectName, projectLifeCycle, stage) {
    return { accountId, projectName, projectLifeCycle, stage };
});

export const addProjectSuccess = createAction(ADD_PROJECT_SUCCESS, function(project) {
    return project;
});
export const deleteProjectComplete = createAction(DELETE_PROJECT_COMPLETE, function(index) {
    return { index };
});
export const updateProjectComplete = createAction(UPDATE_PROJECT_COMPLETE, function(project: Partial<Program>) {
    return { project };
});
export const getProjectVersionsComplete = createAction(GET_PROJECT_VERSIONS_COMPLETE, function(projectName: string, versions: Snapshot[]) {
    return { projectName, versions };
});

export const GENERATE_REC_DOC_SUCCESS = "GENERATE_REC_DOC_SUCCESS";
export const generateRecordingDocSuccess = createAction(GENERATE_REC_DOC_SUCCESS, (recordingDoc, projectName) => ({ recordingDoc, projectName }));
export const LOADING_REC_DOC_SUCCESS = "LOADING_REC_DOC_SUCCESS";
export const loadingProjectRecordingDocSuccess = createAction(LOADING_REC_DOC_SUCCESS, function(projectName, docs) {
    return { projectName, docs };
});
export const UPDATE_PROGRAM_LOGIC_ITEMS = "UPDATE_PROGRAM_LOGIC_ITEMS";
export const updateProgramLogicItems = createAction(UPDATE_PROGRAM_LOGIC_ITEMS, (projectName, data) => {
    return { projectName, data };
});

type AddProjectParams = {
    accountId: string;
    postgresProgramId?: string;
    project: ProgramToBeCreated;
    shouldCreateProgram: boolean;
    history;
    cb: (data: Program) => void;
};

type CreateProgramFromStoryTemplateParams = {
    newProgram: GqlClientNewlyCreatedEditorProgramFragment;
    history;
};


export type DuplicateProgramParams = {
    accountId: string;
    sourceProgramVersionId: string;
    targetProgramName: string;
    targetProgramDescription: string;
    targetAccountId: string;
    isEditor: boolean
    history;
    cb?: (isError: boolean) => void;
};

export type CopyProgramBetweenAccountsParams = {
    accountId: string;
    sourceProgramVersionId: string;
    targetProgramName: string;
    targetProgramDescription: string;
    targetAccountId: string;
    history;
    cb?: (isError: boolean) => void;
};

type TypeOfAssetFromGql = {
    [AssetTypes.curated]?: boolean;
    [AssetTypes.narrator]?: boolean;
    [AssetTypes.animation]?: boolean;
};

type UpdateProgramFetchResult = FetchResult<GqlClientUpdateProgramMutation | GqlClientUpdateProgramVersionMutation>;

type GqlClientCreateProgramVersionDraftMutationProgram = FetchResult<GqlClientCreateProgramVersionDraftMutation>["data"]["createProgramVersionDraft"]["programVersion"]["program"];
type CreateEditorProgramMutationProgram = FetchResult<GqlClientCreateEditorProgramMutation>["data"]["createEditorProgram"]["editorProgram"];
type CreateProgramAndBuilderDraftMutationProgram = FetchResult<GqlClientCreateProgramAndBuilderDraftMutation>["data"]["createProgramAndBuilderDraft"]["program"];

type CreatedProgram = GqlClientCreateProgramVersionDraftMutationProgram | CreateEditorProgramMutationProgram | CreateProgramAndBuilderDraftMutationProgram;

const mutateProjectUpdate = function(programId: string, programVersionId: string, project: Partial<Program>, services: ThunkServices): Promise<UpdateProgramFetchResult> {
    if (project.hasOwnProperty("description") || project.hasOwnProperty("displayName") || project.hasOwnProperty("programId")
        || project.hasOwnProperty("pii") || project.hasOwnProperty("publishTarget")) {
        let input: GqlClientUpdateProgramInput = {
            id: programId,
            updated: IGNORE_UPDATED,
            name: project.displayName,
            description: project.description,
            salesforceId: project.programId,
            pii: project.pii,
            publishTarget: project.publishTarget as GqlClientLifecycleStage
        };

        return services.graphQlClient.mutate<GqlClientUpdateProgramMutation>({
            mutation: UpdateProgramDocument,
            variables: {
                input: input,
                [IGNORE_SERVER_ERRORS]: true
            }
        });
    }
    else if (project.hasOwnProperty("creativeNameLabel") ||
        project.hasOwnProperty("creativeDDE") ||
        project.hasOwnProperty("creativeVersion") ||
        project.hasOwnProperty("programType") ||
        project.hasOwnProperty("editorDataConnectorEnabled") ||
        project.hasOwnProperty("chapteringEnabled")
    ) {
        let input: GqlClientUpdateProgramVersionDraftInput = {
            id: programVersionId,
            updated: IGNORE_UPDATED,
            creativeNameLabel: project.creativeNameLabel,
            creativeDDE: project.creativeDDE,
            chapteringEnabled: project.chapteringEnabled,
            supportsCreatives: project.hasOwnProperty("creativeVersion") ? !!project.creativeVersion : undefined,
            programType: getGQLProgramType(project.programType),
            editorDataConnectorEnabled: project.editorDataConnectorEnabled
        };

        return services.graphQlClient.mutate({
            mutation: UpdateProgramVersionDocument,
            variables: {
                input: input,
                [IGNORE_SERVER_ERRORS]: true
            }
        });
    }
};

const getProgramFromGQLResponse = (gqlResponse: UpdateProgramFetchResult) => {
    if (gqlResponse.data.hasOwnProperty("updateProgramVersionDraft")) {
        const output = (gqlResponse.data as GqlClientUpdateProgramVersionMutation).updateProgramVersionDraft;
        if (output.__typename === "UpdateProgramVersionDraftOutputSuccess") {
            return output.programVersion.program;
        }
    }
    else if (gqlResponse.data.hasOwnProperty("updateProgram")) {
        const output = (gqlResponse.data as GqlClientUpdateProgramMutation).updateProgram;
        if (output.__typename === "UpdateProgramOutputSuccess") {
            return output.program;
        }
    }
    return null;
};

export const generateRecordingDoc = function(accountId, projectName, recordingAssets, cb) {
    return (dispatch, getState, services) => {
        let programVersionId: string = StateReaderUtils.getBuilderProgramAndDraftVersionIds(getState(), projectName).programVersionId;
        let gqlBuilderReadRecordingDocsEnabled = StateReaderUtils.isProgramBulkDynamoMigrationDoneByLegacyId(getState(), projectName, BulkId.RecordingDocs);

        if (gqlBuilderReadRecordingDocsEnabled) {
            const program = StateReaderUtils.getProject(getState(), projectName);
            const input: GqlClientCreateRecordingDocInput = {
                programVersionId,
                recordingAssets: recordingAssets.recordingAssets,
                programName: program.displayName
            };
            services.graphQlClient
                .mutate({
                    context: { headers: { "legacy-program-id": projectName } }, //todo - remove once always sent
                    mutation: CreateRecordingDocDocument,
                    variables: { input }
                })
                .then(function(res: CreateRecordingDocMutationResult) {
                    let recordingDoc: RecordingDocItem = AssetUtils.convertGraphQLRecordingDocForRedux(res.data.createRecordingDoc.recordingDoc);
                    dispatch(generateRecordingDocSuccess(recordingDoc, projectName));
                    cb();
                })
                .catch((err) => {
                    dispatch(reportError(err));
                    cb();
                });
        }
        else {
            services.projectServices
                .generateRecordingDoc(accountId, projectName, recordingAssets, programVersionId)
                .then(function(res) {
                    dispatch(generateRecordingDocSuccess(res, projectName));
                    cb();
                })
                .catch((err) => {
                    dispatch(reportError(err));
                    cb();
                });
        }
    };
};

export const syncAnimations = function(accountId, projectName) {
    return async function(dispatch, state, services) {
        let { programVersionId } = StateReaderUtils.getBuilderProgramAndDraftVersionIds(state(), projectName);
        if (!programVersionId) {
            const projectSummaries = await services.projectServices.loadProjectSummaries(accountId);
            programVersionId = StateReaderUtils.getBuilderProgramAndDraftVersionIds({ projectSummaries }, projectName).programVersionId;
        }

        //We need to return the promise as we use it in projectWireframesAction.js in loadProjectWireframes
        return services.projectAssetsServices
            .syncAnimations(accountId, projectName, programVersionId)
            .then(function(syncData) {
                if (Object.keys(syncData).length !== 0) {
                    dispatch(loadProjectDetails(accountId, projectName, undefined, undefined, undefined, true));
                }
            })
            .catch(function(err) {
                dispatch(reportError(err));
            });
    };
};

export const loadProjectDetails = function(accountId, projectName, version: string = undefined, stage: Stages = undefined, triggeredWithForceLoad = false, includeCreativeNarrations = true) {
    return async function(dispatch, getState) {
        if (!version && !stage) {
            let { programVersionId } = StateReaderUtils.getBuilderProgramAndDraftVersionIds(getState(), projectName);
            dispatch(loadProjectAndAssets(accountId, projectName, programVersionId, includeCreativeNarrations, stage, version));
        }
        else {
            let versionNumber = Number(version);
            if (stage) {
                const snapshot: Snapshot = getState().projects.byName[projectName]?.[stage + "Snapshots"]?.[0];
                versionNumber = snapshot?.snapshotNumber;
            }
            if (Number.isInteger(versionNumber)) {
                const snapshotDetails: Snapshot = StateReaderUtils.getSnapshotDetails(getState(), versionNumber);
                if (snapshotDetails) {
                    dispatch(loadProjectAndAssets(accountId, projectName, versionNumber, includeCreativeNarrations, stage, version));
                }
            }
        }
    };
};

export const loadProjectDetailsLegacy = function(
    accountId,
    projectName,
    stages: Stages[],
    cb = undefined,
    version = undefined,
    stageToView: Stages = undefined,
    triggeredWithForceLoad = false,
    includeCreativeNarrations = true
) {
    return async function(dispatch, state, services: ThunkServices) {
        dispatch(setLoading(true, accountId, projectName));

        let { programId, programVersionId } = StateReaderUtils.getBuilderProgramAndDraftVersionIds(state(), projectName);
        if (!programVersionId || !programVersionId) {
            const projectSummaries = await services.projectServices.loadProjectSummaries(accountId);
            let programAndVersionId = StateReaderUtils.getBuilderProgramAndDraftVersionIds({ projectSummaries }, projectName);
            programId = programAndVersionId.programId;
            programVersionId = programAndVersionId.programVersionId;
        }

        let projectLifecycleResult;
        // in case of version / stage view for a program snapshot that is in postgres - we want to load the data from the relevant program version by overriding the programVersionId
        if (version || stageToView) {
            let snapshotNumber: number;
            if (stageToView) {
                projectLifecycleResult = await services.projectLifeCycleServices.getProjectLifeCycle(accountId, projectName);
                let lifecycleStage = (projectLifecycleResult || []).find((lifecycleStage) => lifecycleStage.stage === stageToView);
                snapshotNumber = lifecycleStage?.snapshotNumber;
            }
            else {
                snapshotNumber = Number(version);
            }
            const snapshotDetails: Snapshot = StateReaderUtils.getSnapshotDetails(state(), snapshotNumber);
            if (snapshotDetails?.snapshotSource === SnapshotSource.POSTGRES) {
                programVersionId = snapshotDetails.graphQLId;
            }
        }

        let isTypeOfAssetFromGql: TypeOfAssetFromGql = {};
        isTypeOfAssetFromGql[AssetTypes.curated] = StateReaderUtils.isProgramBulkDynamoMigrationDone(state(), programId, BulkId.Assets);
        isTypeOfAssetFromGql[AssetTypes.narrator] = StateReaderUtils.isProgramBulkDynamoMigrationDone(state(), programId, BulkId.Narrators);
        isTypeOfAssetFromGql[AssetTypes.animation] = StateReaderUtils.isProgramBulkDynamoMigrationDone(state(), programId, BulkId.Animations);
        isTypeOfAssetFromGql[AssetTypes.mappingTable] = StateReaderUtils.isProgramBulkDynamoMigrationDone(state(), programId, BulkId.DataTables);

        const getProgramFromGQL: boolean = StateReaderUtils.isProgramBulkDynamoMigrationDone(state(), programId, BulkId.MostEntities);

        let gqlProgramPromise: Promise<ApolloQueryResult<GqlClientGetProgramWithAssetsQuery>> = Promise.resolve(null);
        const anyAssetFromGql: boolean = Object.values(isTypeOfAssetFromGql).some((flag) => !!flag);
        const getRecordingDocsFromGQL: boolean = StateReaderUtils.isProgramBulkDynamoMigrationDone(state(), programId, BulkId.RecordingDocs);
        if (getProgramFromGQL || anyAssetFromGql || getRecordingDocsFromGQL) {
            const variables: GqlClientGetProgramWithAssetsQueryVariables = {
                programId,
                programVersionId
            };

            // we don't check if it already exists on the state since in the future it should include all asset types.
            gqlProgramPromise = services.graphQlClient.query<GqlClientGetProgramWithAssetsQuery>({ fetchPolicy: "no-cache", query: GetProgramWithAssetsDocument, variables });
        }

        let failedToGetVersion = false;
        let graphQlError = false;

        let forceLoad = triggeredWithForceLoad || StateReaderUtils.getForceLoad(state());

        let project: Program = StateReaderUtils.getProject(state(), projectName);

        let projectPromise: Promise<Program> = getProgramFromGQL || (project && !forceLoad) ? Promise.resolve(null) : services.projectServices.getProject(accountId, projectName);

        let assetCountPromise = isTypeOfAssetFromGql[AssetTypes.curated] ? Promise.resolve() : services.projectAssetsServices.getAssetCount(accountId, projectName, AssetTypes.curated);

        let recordingDocsPromise: Promise<RecordingDocItem[]> = getRecordingDocsFromGQL ? Promise.resolve(null) : services.projectServices.getRecordingDocs(accountId, projectName);

        let lifecyclePromise = projectLifecycleResult ? Promise.resolve(projectLifecycleResult) : services.projectLifeCycleServices.getProjectLifeCycle(accountId, projectName);

        let assetsPromises = Object.values(AssetTypes)
            .map((assetType) => {
                let hasAssetsOfThisType = project && project.assets && project.assets.some((projectAssetId) => AssetUtils.getAssetTypeFromId(projectAssetId) === assetType);

                // check if asset should be loaded from dynamo - if type isn't already on the state and shouldn't be loaded from GQL
                if (isTypeOfAssetFromGql[assetType] || (hasAssetsOfThisType && !forceLoad) || assetType === AssetTypes.recording) {
                    return null;
                }
                else {
                    return services.projectAssetsServices.getAllAssets(accountId, projectName, assetType, undefined, undefined, true);
                }
            })
            .filter(Boolean);

        // region creative
        let creativeEnabled = StateReaderUtils.isFeatureEnable(state(), CREATIVE);

        let creativesRecordingsPromise; // goes to graphql -> postgres
        if (creativeEnabled && !version && includeCreativeNarrations) {
            creativesRecordingsPromise = services.graphQlClient.query({
                fetchPolicy: "no-cache",
                query: GetAllCreativesNarrationRecordingsDocument,
                variables: { id: programId }
            });
        }
        else {
            const creativeRecordingAssetsData = { data: { program: { creatives: [] } } };
            creativesRecordingsPromise = Promise.resolve(creativeRecordingAssetsData);
        }
        // endregion

        Promise.all([projectPromise, assetCountPromise, recordingDocsPromise, lifecyclePromise, creativesRecordingsPromise, gqlProgramPromise, ...assetsPromises])
            .then(async ([project, totalCuratedAssets, recordingDocs, projectLifecycle, creativeRecordingAssetsData, gqlProgramResponse, ...dbAssets]) => {
                const gqlProgram = gqlProgramResponse ? buildProgramFromGql(gqlProgramResponse?.data?.program) : null;
                const gqlProgramVersion = gqlProgramResponse?.data?.program?.programVersion;

                // A nullish program means that we already have it on the state
                if (project || getProgramFromGQL) {
                    dispatch(loadingProjectDetailsSuccess(getProgramFromGQL ? gqlProgram : project));
                }

                if (isTypeOfAssetFromGql[AssetTypes.narrator]) {
                    const gqlNarrators: GqlClientNarratorFragment[] = gqlProgramVersion.narrators;
                    const convertedNarrators: ConvertedGqlNarrator[] = buildNarratorsFromGql(accountId, projectName, gqlNarrators || []);
                    dispatch(loadingProjectAssetsSuccess(projectName, { Items: convertedNarrators }, undefined));
                }

                if (isTypeOfAssetFromGql[AssetTypes.curated]) {
                    const gqlAssets: GqlClientAssetFragment[] = gqlProgramVersion.assets;
                    let convertedAssets: ConvertedGqlAsset[] = (gqlAssets || []).map(convertPostgresAssetToDynamoAsset);
                    let totalCount: number = convertedAssets.length;
                    dispatch(loadingProjectAssetsCountSuccess(projectName, { totalCount }, AssetTypes.curated));
                    dispatch(loadingProjectAssetsSuccess(projectName, { Items: convertedAssets }, undefined));
                }
                else {
                    dispatch(loadingProjectAssetsCountSuccess(projectName, totalCuratedAssets, AssetTypes.curated));
                }

                if (isTypeOfAssetFromGql[AssetTypes.animation]) {
                    const gqlAnimations: GqlClientAnimationFragment[] = gqlProgramVersion.animations;
                    let convertedAnimations: WithGraphQLId<AnimationItem>[] = (gqlAnimations || []).map(convertPostgresAnimationToDynamoAnimation);
                    dispatch(loadingProjectAssetsSuccess(projectName, { Items: convertedAnimations }, undefined));
                }

                if (isTypeOfAssetFromGql[AssetTypes.mappingTable]) {
                    const gqlDataTables: GqlClientDataTableFragment[] = gqlProgramVersion.dataTables;
                    let convertedDataTables: DataTable[] = (gqlDataTables || []).map(buildDataTableFromGql);
                    dispatch(loadingProjectAssetsSuccess(projectName, { Items: convertedDataTables }, undefined));
                }

                dbAssets.map((typeAssets) => {
                    dispatch(loadingProjectAssetsSuccess(projectName, typeAssets, undefined));
                });

                if (getRecordingDocsFromGQL) {
                    const gqlRecordingDocs: GetProgramWithAssetsQueryResult["data"]["program"]["programVersion"]["recordingDocs"] = gqlProgramVersion.recordingDocs;
                    const convertedRecordingDocs: RecordingDocItem[] = (gqlRecordingDocs || []).map(AssetUtils.convertGraphQLRecordingDocForRedux);
                    dispatch(loadingProjectRecordingDocSuccess(projectName, convertedRecordingDocs));
                }
                else {
                    dispatch(loadingProjectRecordingDocSuccess(projectName, recordingDocs));
                }

                dispatch(loadingProjectLifeCycleSuccess(accountId, projectName, projectLifecycle));

                const { data: allCreativesNarrationRecordings } = creativeRecordingAssetsData;
                dispatch(loadingCreativeRecordingAssetsSuccess(projectName, allCreativesNarrationRecordings));

                let resArray: LifecycleHistory = [];
                if (version) {
                    try {
                        await loadProjectSnapshotPromise(services, state(), dispatch, accountId, projectName, version);
                    }
                    catch (e) {
                        failedToGetVersion = true;
                        throw e;
                    }
                }
                else if (stages && stages.length > 0) {
                    resArray = await getProjectLifecycleHistory(state, services, accountId, projectName, programId, stages, forceLoad);
                    if (resArray) {
                        let stagePromises = [];
                        resArray.forEach((res) => {
                            if (stages.includes(res.name)) {
                                dispatch(loadingProjectLifeCycleStageSuccess(accountId, projectName, res, res.name));
                                if (res.snapshots[0]) {
                                    stagePromises.push(loadProjectSnapshotPromise(services, state(), dispatch, accountId, projectName, res.snapshots[0].snapshotNumber));
                                }
                            }
                        });
                        await Promise.all(stagePromises);
                    }
                    else {
                        graphQlError = true;
                    }
                }

                dispatch(setLoading(false, accountId, projectName));
                if (cb instanceof Function) {
                    cb();
                }
            })
            .catch((err) => {
                if (failedToGetVersion) {
                    dispatch(reportError(`Version ${version} doesn't exist.`));
                }
                else if (!graphQlError) {
                    // graphQL errors are handled in the error link
                    dispatch(reportError(err));
                }
                dispatch(setLoading(false, accountId, projectName));
            });
    };
};

const loadProjectAndAssets = memoizeThunkAction((accountId: string, projectName: string, versionIdOrNumber: string | number, includeCreativeNarrations: boolean, stage: Stages, version: string) => {
    return async function(dispatch, getState, services: ThunkServices) {
        let { programId, programVersionId: draftVersionId } = StateReaderUtils.getBuilderProgramAndDraftVersionIds(getState(), projectName);
        const isDraft = versionIdOrNumber === draftVersionId;
        let loadVersionFromDynamo: boolean = false;

        let programVersionId: string = versionIdOrNumber as string;
        if (typeof versionIdOrNumber === "number") {
            const snapshotDetails: Snapshot = StateReaderUtils.getSnapshotDetails(getState(), versionIdOrNumber);
            if (snapshotDetails.snapshotSource === SnapshotSource.POSTGRES) {
                programVersionId = snapshotDetails.graphQLId;
            }
            else {
                programVersionId = draftVersionId;
                loadVersionFromDynamo = true;
            }
        }

        try {
            dispatch(setLoading(true, accountId, projectName));
            const creativeEnabled = StateReaderUtils.isFeatureEnable(getState(), CREATIVE);

            let creativeRecordingAssetsPromise;
            if (creativeEnabled && isDraft && includeCreativeNarrations) {
                creativeRecordingAssetsPromise = services.graphQlClient.query({
                    fetchPolicy: "no-cache",
                    query: GetAllCreativesNarrationRecordingsDocument,
                    variables: { id: programId }
                });
            }
            else {
                creativeRecordingAssetsPromise = Promise.resolve({ data: { program: { creatives: [] } } });
            }

            const [ gqlProgramResponse, creativeRecordingAssetsData ] = await Promise.all([services.graphQlClient.query<GqlClientGetProgramWithAssetsQuery>({
                fetchPolicy: "no-cache",
                query: GetProgramWithAssetsDocument,
                variables: {
                    programId,
                    programVersionId
                }
            }), creativeRecordingAssetsPromise]);
            const gqlProgram = gqlProgramResponse?.data?.program as GqlClientProgram;
            const program: Program = buildProgramFromGql(gqlProgram);

            dispatch(loadingProjectDetailsSuccess(program));

            const gqlProgramVersion = gqlProgramResponse?.data?.program?.programVersion;

            if (!isDraft) {
                if (loadVersionFromDynamo) {
                    const projectSnapshot: Snapshot = await services.projectLifeCycleServices.getProjectSnapshot(accountId, projectName, versionIdOrNumber as number);
                    dispatch(loadingProjectSnapshotSuccess(accountId, projectName, projectSnapshot));
                }
                else {
                    const snapshotAssets: SnapshotAsset[] = buildStateSnapshotAssetsFromGql(
                        gqlProgramVersion.assets,
                        gqlProgramVersion.animations,
                        gqlProgramVersion.dataTables,
                        gqlProgramVersion.narrationRecordings,
                        gqlProgramVersion.narrators
                    );
                    const projectSnapshot = buildStateSnapshotFromGql(gqlProgramVersion, snapshotAssets);
                    dispatch(loadingProjectSnapshotSuccess(accountId, projectName, projectSnapshot));
                }
            }

            const gqlNarrators: GqlClientNarratorFragment[] = gqlProgramVersion.narrators;
            const convertedNarrators: ConvertedGqlNarrator[] = buildNarratorsFromGql(accountId, projectName, gqlNarrators);
            dispatch(loadingProjectAssetsSuccess(projectName, { Items: convertedNarrators }, !isDraft));

            let convertedAssets: ConvertedGqlAsset[] = gqlProgramVersion.assets.map(convertPostgresAssetToDynamoAsset);
            let totalCount: number = convertedAssets.length;
            dispatch(loadingProjectAssetsCountSuccess(projectName, { totalCount }, AssetTypes.curated));
            dispatch(loadingProjectAssetsSuccess(projectName, { Items: convertedAssets }, !isDraft));

            let convertedAnimations: WithGraphQLId<AnimationItem>[] = gqlProgramVersion.animations.map(convertPostgresAnimationToDynamoAnimation);
            dispatch(loadingProjectAssetsSuccess(projectName, { Items: convertedAnimations }, !isDraft));

            let convertedDataTables: DataTable[] = gqlProgramVersion.dataTables.map(buildDataTableFromGql);
            dispatch(loadingProjectAssetsSuccess(projectName, { Items: convertedDataTables }, !isDraft));

            const convertedRecordingAssets: ConvertedGqlRecordingAsset[] = buildNarrationRecordingsFromGql(accountId, projectName, gqlProgramVersion.narrationRecordings, gqlProgram.isEditor);
            dispatch(loadingProjectAssetsSuccess(projectName, { Items: convertedRecordingAssets }, !isDraft));

            const convertedRecordingDocs: RecordingDocItem[] = gqlProgramVersion.recordingDocs.map(AssetUtils.convertGraphQLRecordingDocForRedux);
            dispatch(loadingProjectRecordingDocSuccess(projectName, convertedRecordingDocs));

            const { data: allCreativesNarrationRecordings } = creativeRecordingAssetsData;
            dispatch(loadingCreativeRecordingAssetsSuccess(projectName, allCreativesNarrationRecordings));
        }
        catch (err) {
            dispatch(reportError(err));
        }
        finally {
            dispatch(setLoading(false, accountId, projectName, stage, version));

        }
    };
});

export const loadGraphQLProgram = (accountId: string, projectName: string) => {
    return (dispatch, getState, services) => {
        const { programId } = StateReaderUtils.getBuilderProgramAndDraftVersionIds(getState(), projectName);
        services.graphQlClient
            .query({
                query: GetProgramDocument,
                variables: {
                    id: programId,
                    [IGNORE_SERVER_ERRORS]: true
                }
            })
            .then((result) => {
                dispatch(loadingGqlProgramSuccess(result.data.program));
            });
    };
};

export const loadProjectForDashboard = (accountId, stages) => {
    return (dispatch, state, services) => {
        dispatch(setLoading(true, accountId, undefined));

        services.projectServices.getProjects(accountId).then((data) => {
            dispatch(loadingAllProjectsSuccess(data));

            data.forEach((project) => {
                dispatch(setLoading(true, accountId, project.projectName));

                let projectName = project.projectName;
                let promises = [services.projectServices.getProject(accountId, projectName), services.projectLifeCycleServices.getProjectLifeCycle(accountId, projectName)];

                Promise.all(promises)
                    .then(async ([project, projectLifecycle]) => {
                        if (project) {
                            dispatch(loadingProjectDetailsSuccess(project));
                            dispatch(loadingProjectLifeCycleSuccess(accountId, projectName, projectLifecycle));
                        }
                        else {
                            dispatch(reportError("Project " + projectName + " not found"));
                        }

                        let resArray = [];
                        if (stages && stages.length > 0) {
                            // get programId
                            let programId: string = StateReaderUtils.getProgramId(state(), projectName);
                            if (!programId) {
                                const projectSummaries = await services.projectServices.loadProjectSummaries(accountId);
                                programId = StateReaderUtils.getProgramId({ projectSummaries }, projectName);
                            }

                            resArray = await getProjectLifecycleHistory(state, services, accountId, projectName, programId, stages);
                        }

                        resArray.forEach((res) => {
                            dispatch(loadingProjectLifeCycleStageSuccess(accountId, projectName, res, res.name));
                        });
                        dispatch(setLoading(false, accountId, projectName));
                    })
                    .catch((err) => {
                        dispatch(reportError(err));
                        dispatch(setLoading(false, accountId, projectName));
                    });
            });
            dispatch(setLoading(false, accountId, undefined));
        });
    };
};

export const deleteProject = function(accountId, index, projectName) {
    return function(dispatch, state, services) {
        services.projectServices
            .deleteProject(accountId, projectName)
            .then(function() {
                dispatch(deleteProjectComplete(index));
            })
            .catch(function(err) {
                dispatch(reportError(err));
            });
    };
};

export const updateProject = function(accountId: string, projectName: string, project: Partial<Program>) {
    return async function(dispatch, getState, services: ThunkServices) {
        const { programId, programVersionId } = StateReaderUtils.getBuilderProgramAndDraftVersionIds(getState(), projectName);

        try {
            const currentProgram: Program = StateReaderUtils.getProject(getState(), projectName);
            const gqlResponse = await mutateProjectUpdate(programId, programVersionId, project, services);
            const gqlProgram = getProgramFromGQLResponse(gqlResponse);

            const updatedFields = Object.keys(project);
            if (
                (updatedFields.includes("displayName") && currentProgram.displayName !== project.displayName) ||
                (updatedFields.includes("creativeVersion") && currentProgram.creativeVersion !== project.creativeVersion) ||
                (updatedFields.includes("programId") && currentProgram.programId !== project.programId) ||
                (updatedFields.includes("programType") && currentProgram.programType !== project.programType)
            ) {
                const projectSummaries: ProgramSummary[] = await services.projectServices.loadProjectSummaries(accountId);
                dispatch(loadProjectSummariesSuccess(projectSummaries));
            }
            dispatch(updateProjectComplete({ ...project, projectName, graphQLUpdated: gqlProgram.updated }));
        }
        catch (err) {
            dispatch(reportError(err));
        }

    };
};

export const updateArchiveProgram = function(programId: string, updatedIsArchive: boolean, cb: () => void) {
    return async function(dispatch, getState, services: ThunkServices) {
        const onArchiveSuccess = () => {
            dispatch(updateProgramSummarySuccess(programId, { isArchive: updatedIsArchive }));
            cb();
        };
        const isEditor: boolean = StateReaderUtils.isEditorProgram(getState(), programId);
        if (isEditor) {
            const input: GqlClientUpdateEditorIsArchivedInput = {
                id: programId,
                updated: IGNORE_UPDATED,
                isArchive: updatedIsArchive
            };
            const gqlResponse: FetchResult<GqlClientUpdateEditorIsArchivedMutation> = await services.graphQlClient.mutate<GqlClientUpdateEditorIsArchivedMutation>({
                mutation: UpdateEditorIsArchivedDocument,
                variables: {
                    input: input
                }
            });

            if (gqlResponse?.data?.updateEditorIsArchived?.result === GqlClientUpdateEditorIsArchivedResult.SUCCESS) {
                onArchiveSuccess();
            }
        }
        else {
            const input: GqlClientUpdateProgramInput = {
                id: programId,
                updated: IGNORE_UPDATED,
                isArchive: updatedIsArchive
            };
            const gqlResponse: FetchResult<GqlClientUpdateProgramMutation> = await services.graphQlClient.mutate<GqlClientUpdateProgramMutation>({
                mutation: UpdateProgramDocument,
                variables: {
                    input: input
                }
            });

            if (gqlResponse?.data?.updateProgram?.__typename === "UpdateProgramOutputSuccess") {
                onArchiveSuccess();
            }
        }
    };
};

//we use this for legacy pages only.
export const updateProjectThumbnail = function(accountId, projectName, projectThumbnail, cb) {
    return function(dispatch, state, services) {
        dispatch(setLoading(true, accountId, projectName));
        services.projectServices
            .addProjectThumbnail(accountId, projectName, projectThumbnail)
            .then(function(updatedProject) {
                dispatch(updateProjectComplete(updatedProject));
                if (cb instanceof Function) {
                    cb();
                }
                dispatch(setLoading(false, accountId, projectName));
            })
            .catch(function(err) {
                dispatch(reportError(err));
                dispatch(setLoading(false, accountId, projectName));
            });
    };
};

/**
 * When the GQL Builders FF is open and we create a program, we need to create a Builder program version in Postgres as well or else
 * all GQL mutations in the Builder will fail (NOT_IN_DB => no program version).
 * Creation of Builder program version can occur when:
 * 1. creating a new program as described above.
 * 2. clicking "+ Builder" when there is only Framework in the program.
 * Both cases are handled here. This is why `postgresProgramId` and `shouldCreateProgram` params were added to this thunk
 * **/
export const addProject = ({ accountId, postgresProgramId, project, shouldCreateProgram, history, cb }: AddProjectParams) => {
    return async (dispatch, getState, services: ThunkServices) => {
        let audienceDeId: string;
        let projectName: string;

        project.narrationsVersion = 1;
        project.RFR = 0;
        project.storiesVersion = 1;
        let state = getState();

        //TODO - this is for qa - delete later
        const permanentlyMigratedToPostgres = programDefaultDynamoMigrationStatusFields.most_entities === "done" || accountId === "0010b00002R0wc3AAB";

        if (!permanentlyMigratedToPostgres) {
            try {
                let data: Program = await services.projectServices.addProject(accountId, project);
                projectName = data.projectName;

                // create 'audience' DE for Ads programs
                if (data.programType && data.programType.toLowerCase() === programTypes.ADS.toLowerCase()) {
                    const createdDataElement = await services.dataElements.addDataElement(accountId, data.projectName, adsDataElements[0]);
                    audienceDeId = createdDataElement.id;
                }

                dispatch(addProjectSuccess(data));

                if (cb instanceof Function) {
                    cb(data);
                }
            }
            catch (err) {
                dispatch(reportError(err));
                return;
            }
        }

        // creating Postgres data
        try {
            if (project.programType && project.programType.toLowerCase() === programTypes.ADS.toLowerCase() && !audienceDeId) {
                audienceDeId = uuid();
            }
            let newlyCreatedProgram: CreatedProgram;

            // case 1
            if (shouldCreateProgram) {

                // Add Editor Program if we are in Editor account
                if (project.isEditor) {
                    const mutationVariables: GqlClientCreateEditorProgramMutationVariables & { [IGNORE_SERVER_ERRORS]: boolean } = {
                        input: {
                            name: project.projectDisplayName,
                            type: getGQLEditorProgramType(project.programType),
                            accountCcLibraryId: CC_LIBRARY_ID,
                            aspectRatio: project.aspectRatio
                        },
                        [IGNORE_SERVER_ERRORS]: true
                    };

                    const mutationResult: FetchResult<GqlClientCreateEditorProgramMutation> = await services.graphQlClient.mutate({
                        mutation: CreateEditorProgramDocument,
                        variables: mutationVariables
                    });

                    newlyCreatedProgram = mutationResult.data.createEditorProgram.editorProgram;
                }
                else {
                    const mutationVariables: GqlClientCreateProgramAndBuilderDraftMutationVariables & { [IGNORE_SERVER_ERRORS]: boolean } = {
                        input: {
                            name: project.projectDisplayName,
                            type: getGQLProgramType(project.programType)
                        },
                        legacyBuilderProgramId: projectName,
                        legacyAudienceDataElementId: audienceDeId,
                        [IGNORE_SERVER_ERRORS]: true
                    };

                    const mutationResult: FetchResult<GqlClientCreateProgramAndBuilderDraftMutation> = await services.graphQlClient.mutate({
                        mutation: CreateProgramAndBuilderDraftDocument,
                        variables: mutationVariables
                    });

                    newlyCreatedProgram = mutationResult.data.createProgramAndBuilderDraft.program;
                }
            }
            // case 2
            else {
                const mutationVariables: GqlClientCreateProgramVersionDraftMutationVariables & { [IGNORE_SERVER_ERRORS]: boolean } = {
                    input: {
                        name: project.projectDisplayName,
                        type: getGQLProgramType(project.programType),
                        isBuilder: true
                    },
                    programId: postgresProgramId,
                    legacyBuilderProgramId: projectName,

                    legacyAudienceDataElementId: audienceDeId,
                    [IGNORE_SERVER_ERRORS]: true
                };

                const mutationResult: FetchResult<GqlClientCreateProgramVersionDraftMutation> = await services.graphQlClient.mutate({
                    mutation: CreateProgramVersionDraftDocument,
                    variables: mutationVariables
                });

                newlyCreatedProgram = mutationResult.data.createProgramVersionDraft.programVersion.program;
            }

            if (permanentlyMigratedToPostgres) {
                project.displayName = project.projectDisplayName;
                project.description = project.projectDescription;
                project.projectName = newlyCreatedProgram.id;
                project.graphQLId = newlyCreatedProgram.id;
                project.graphQLUpdated = newlyCreatedProgram.created;

                // We differentiate between Editor Program and Non Editor Program
                if (!project.isEditor) {
                    project.graphQLBuilderDraftId = (newlyCreatedProgram as CreateProgramAndBuilderDraftMutationProgram).programVersions[0].id;
                }

                dispatch(addProjectSuccess(project));
            }

            const userRoles: string[] = StateReaderUtils.getUserRoles(state);
            const loadProjectSummariesCB = (accountId: string, forceLoad: boolean, cb) => dispatch(loadProjectSummaries(accountId, forceLoad, cb));

            Entities.Program.navigateToProgramAfterCreation(loadProjectSummariesCB, userRoles, newlyCreatedProgram, history, project.isEditor ? StudioMainCategoriesPaths.Editor : undefined);

            dispatch(loadProgramsMigrationStatus());
            dispatch(setLoading(false, accountId, projectName));
        }
        catch (err) {
            if (permanentlyMigratedToPostgres) {
                dispatch(reportError(err));
            }
        }
        // Duplication for GQL - end
    };
};

export const postProgramCreation = ({ newProgram, history }: CreateProgramFromStoryTemplateParams) => {
    return async (dispatch, getState, services: ThunkServices) => {

        try {

            let state = getState();
            const userRoles: string[] = StateReaderUtils.getUserRoles(state);
            const loadProjectSummariesCB = (accountId: string, forceLoad: boolean, cb) => dispatch(loadProjectSummaries(accountId, forceLoad, cb));

            Entities.Program.navigateToProgramAfterCreation(loadProjectSummariesCB, userRoles, newProgram, history, StudioMainCategoriesPaths.Editor);

            dispatch(loadProgramsMigrationStatus());
            dispatch(setLoading(false, newProgram.accountId, newProgram.name));
        }
        catch (err) {
            dispatch(reportError(err));
        }
    };
};

export const duplicateProgram = ({ accountId, sourceProgramVersionId, targetAccountId, targetProgramName, targetProgramDescription, isEditor, history, cb }: DuplicateProgramParams) => {
    return async (dispatch, getState, services: ThunkServices) => {
        // dynamic import to prevent circular dependency error
        const { handleEditorError } = await import("../../components/editor/Nooks");
        let isError = false;
        try {
            dispatch(setLoading(true, accountId, undefined));

            let input: GqlClientCopyProgramInput = {
                sourceProgramVersionId,
                targetProgramName,
                targetProgramDescription,
                copyStoryTemplateData: true
            };

            if (accountId !== targetAccountId) {
                input.targetAccountId = targetAccountId;
            }
            let newlyCreatedProgram: Partial<Pick<GqlClientProgram, "accountId" | "id" | "legacyBuilderProgramId">>;
            if (isEditor) {
                const {
                    data: {
                        copyEditorProgram
                    }
                }: FetchResult<GqlClientCopyEditorProgramMutation> = await services.graphQlClient.mutate<GqlClientCopyEditorProgramMutation>({
                    mutation: CopyEditorProgramDocument,
                    variables: {
                        input: input,
                        [IGNORE_SERVER_ERRORS]: true
                    }
                });
                newlyCreatedProgram = copyEditorProgram.targetProgram;
            }
            else {
                const {
                    data: {
                        copyProgram
                    }
                }: FetchResult<GqlClientCopyProgramMutation> = await services.graphQlClient.mutate<GqlClientCopyProgramMutation>({
                    mutation: CopyProgramDocument,
                    variables: {
                        input: input,
                        [IGNORE_SERVER_ERRORS]: true
                    }
                });
                newlyCreatedProgram = copyProgram.targetProgram;
            }

            // We create a new program. We need to fetch a couple of things...
            dispatch(loadFeatureFlags());
            dispatch(loadProgramsMigrationStatus());

            const userRoles: string[] = StateReaderUtils.getUserRoles(getState());
            const loadProjectSummariesCB = (accountId: string, forceLoad: boolean, cb) => dispatch(loadProjectSummaries(accountId, forceLoad, cb));

            Entities.Program.navigateToProgramAfterCreation(loadProjectSummariesCB, userRoles, newlyCreatedProgram, history, isEditor ? StudioMainCategoriesPaths.Overview : undefined);

        }
        catch (error) {
            isError = true;
            let sskyCode = SskyErrorCode.CopyProgramFailed;
            if (getSskyErrorCodeFromGqlError(error?.graphQLErrors?.[0]) === SskyErrorCode.DuplicateProgramWithRemovedWireframeError) {
                sskyCode = SskyErrorCode.DuplicateProgramWithRemovedWireframeError;
            }
            handleEditorError({ error, sskyCode });
        }
        finally {
            dispatch(setLoading(false, accountId, undefined));
            if (typeof cb === "function") {
                cb(isError);
            }
        }
    };
};

export const copyProgramBetweenAccounts = ({ accountId, sourceProgramVersionId, targetAccountId, targetProgramName, targetProgramDescription, history, cb }: CopyProgramBetweenAccountsParams) => {
    return async (dispatch, getState, services: ThunkServices) => {
        // dynamic import to prevent circular dependency error
        const { handleEditorError } = await import("../../components/editor/Nooks");
        let isError = false;
        try {
            dispatch(setLoading(true, accountId, undefined));

            const input: GqlClientCopyDraftEditorProgramBetweenAccountsInput = {
                sourceDraftEditorProgramId: sourceProgramVersionId,
                targetAccount: targetAccountId,
                name: targetProgramName,
                description: targetProgramDescription
            };

            let newlyCreatedProgram: Partial<Pick<GqlClientProgram, "accountId" | "id" | "legacyBuilderProgramId">>;
            const {
                data: {
                    copyDraftEditorProgramBetweenAccounts
                }
            }: FetchResult<GqlClientCopyDraftEditorProgramBetweenAccountsMutation> = await services.graphQlClient.mutate<GqlClientCopyDraftEditorProgramBetweenAccountsMutation>({
                mutation: CopyDraftEditorProgramBetweenAccountsDocument,
                variables: {
                    input,
                    [IGNORE_SERVER_ERRORS]: true
                }
            });
            newlyCreatedProgram = copyDraftEditorProgramBetweenAccounts.editorProgram;

            // We create a new program. We need to fetch a couple of things...
            dispatch(loadFeatureFlags());
            dispatch(loadProgramsMigrationStatus());

            const userRoles: string[] = StateReaderUtils.getUserRoles(getState());
            const loadProjectSummariesCB = (accountId: string, forceLoad: boolean, cb) => dispatch(loadProjectSummaries(accountId, forceLoad, cb));

            Entities.Program.navigateToProgramAfterCreation(loadProjectSummariesCB, userRoles, newlyCreatedProgram, history, StudioMainCategoriesPaths.Overview);

        }
        catch (error) {
            isError = true;
            let sskyCode = SskyErrorCode.CopyProgramBetweenAccountsFailed;
            if (getSskyErrorCodeFromGqlError(error?.graphQLErrors?.[0]) === SskyErrorCode.DuplicateProgramWithRemovedWireframeError) {
                sskyCode = SskyErrorCode.DuplicateProgramWithRemovedWireframeError;
            }
            handleEditorError({ error, sskyCode });
        }
        finally {
            dispatch(setLoading(false, accountId, undefined));
            if (typeof cb === "function") {
                cb(isError);
            }
        }
    };
};

export const getNewPrograms = function(accountId) {
    return function(dispatch, state, services: ThunkServices) {
        dispatch(setLoading(true, accountId, undefined));
        services.projectServices
            .getNewPrograms(accountId)
            .then(function(data: AccountsDBProgram[]) {
                dispatch(getNewProgramsSuccess(accountId, data));
                dispatch(setLoading(false, accountId, undefined));
            })
            .catch(function(err) {
                dispatch(reportError(err));
                dispatch(setLoading(false, accountId, undefined));
            });
    };
};

export const getProjectVersions = function(accountId, projectName) {
    return async function(dispatch, getState, services: ThunkServices) {
        let programId: string = StateReaderUtils.getProgramId(getState(), projectName);
        if (!programId) {
            const projectSummaries = await services.projectServices.loadProjectSummaries(accountId);
            programId = StateReaderUtils.getProgramId({ projectSummaries }, projectName);
        }
        const combineSnapshots = (postgresSnapshots: Snapshot[], dynamoSnapshots: Snapshot[]): Snapshot[] => {
            if (!postgresSnapshots || !postgresSnapshots[0]) {
                return dynamoSnapshots;
            }
            if (!dynamoSnapshots || !dynamoSnapshots[0]) {
                return postgresSnapshots;
            }
            const dynamoSnapshotsObject = convertArrayOfObjectsToObject<Snapshot>(dynamoSnapshots, "snapshotNumber");
            const postgresSnapshotsObject = convertArrayOfObjectsToObject<Snapshot>(postgresSnapshots, "snapshotNumber");
            const combineSnapshotsObject = { ...dynamoSnapshotsObject, ...postgresSnapshotsObject };
            return Object.values(combineSnapshotsObject).sort((a, b) => a.snapshotNumber - b.snapshotNumber);
        };
        return Promise.all([
            services.projectServices.getProjectSnapshots(accountId, projectName),
            services.graphQlClient.query<GqlClientGetProgramVersionSnapshotsQuery>({
                fetchPolicy: "no-cache",
                query: GetProgramVersionSnapshotsDocument,
                variables: {
                    programId: programId
                }
            })
        ])
            .then(([data, gqlData]) => {
                const postgresSnapshots: Snapshot[] = buildStateVersionsFromGql(gqlData.data.program.programVersionSnapshots);
                const dynamoSnapshots: Snapshot[] =
                    data &&
                    data.Items &&
                    data.Items.map((snapshot) => {
                        snapshot.snapshotSource = SnapshotSource.DYNAMO;
                        return snapshot;
                    });
                const combinedSnapshot = combineSnapshots(postgresSnapshots, dynamoSnapshots);
                dispatch(getProjectVersionsComplete(projectName, combinedSnapshot));
            })
            .catch(function(err) {
                dispatch(reportError(err));
            });
    };
};

export const getProjectVersionsForDuplicate = function(accountId, projectName, cb: (allVersions: DuplicateProgramSnapshot[], isPartialVersions: boolean) => void) {
    return async function(dispatch, getState, services: ThunkServices) {
        let programId: string = StateReaderUtils.getProgramId(getState(), projectName);

        if (!programId) {
            const projectSummaries = await services.projectServices.loadProjectSummaries(accountId);
            programId = StateReaderUtils.getProgramId({ projectSummaries }, projectName);
        }

        return Promise.all([
            services.projectServices.getProjectSnapshots(accountId, projectName),
            services.graphQlClient.query<GqlClientGetProgramVersionSnapshotsQuery>({
                fetchPolicy: "no-cache",
                query: GetProgramVersionSnapshotsDocument,
                variables: {
                    programId: programId
                }
            }),
            services.projectLifeCycleServices.getProjectLifeCycle(accountId, projectName)
        ])
            .then(([projectSnapshots, gqlProjectSnapshots, projectLifecycle]: [{ Items: Omit<Snapshot, "assets">[] }, ApolloQueryResult<GqlClientGetProgramVersionSnapshotsQuery>, any]) => {
                const postgresSnapshots: GqlClientProgramVersionSnapshotDetailsFragment[] = gqlProjectSnapshots.data.program.programVersionSnapshots;
                const dynamoSnapshots: Snapshot[] =
                    projectSnapshots &&
                    projectSnapshots.Items &&
                    projectSnapshots.Items.map((snapshot) => {
                        snapshot.snapshotSource = SnapshotSource.DYNAMO;
                        return snapshot;
                    });

                let devVersion: number;
                let uatVersion: number;
                let prodVersion: number;
                projectLifecycle?.forEach((lifecycle) => {
                    switch (lifecycle.stage) {
                        case Stages.dev:
                            devVersion = lifecycle.snapshotNumber;
                            break;
                        case Stages.UAT:
                            uatVersion = lifecycle.snapshotNumber;
                            break;
                        case Stages.prod:
                            prodVersion = lifecycle.snapshotNumber;
                            break;
                    }
                });

                const allVersions: DuplicateProgramSnapshot[] = postgresSnapshots.map((snapshot: GqlClientProgramVersionSnapshotDetailsFragment) => ({
                    ...snapshot,
                    activeStages: getActiveStages(snapshot.snapshotNumber, devVersion, uatVersion, prodVersion).reverse()
                }));

                const gqlSnapshotByNumber: { [p: number]: GqlClientProgramVersionSnapshotDetailsFragment } = convertArrayOfObjectsToObject(postgresSnapshots, "snapshotNumber");
                const isPartialVersions: boolean = dynamoSnapshots.some((snapshot) => !gqlSnapshotByNumber[snapshot.snapshotNumber]);

                if (typeof cb === "function") {
                    cb(allVersions, isPartialVersions);
                }
            })
            .catch(function(err) {
                dispatch(reportError(err));
            });
    };
};

export const loadProjectSummaries = (accountId: string, forceLoad: boolean = false, cb?: () => void) => {
    return (dispatch, getState, services: ThunkServices) => {
        let projectSummaries = StateReaderUtils.getProgramSummaries(getState());
        if (!forceLoad && projectSummaries && projectSummaries.length > 0) {
            return;
        }

        dispatch(setLoading(true, accountId, undefined));

        services.projectServices
            .loadProjectSummaries(accountId)
            .then((projectSummaries: ProgramSummary[]) => {
                dispatch(loadProjectSummariesSuccess(projectSummaries));
                if (cb instanceof Function) {
                    cb();
                }
                dispatch(setLoading(false, accountId, undefined));
            })
            .catch(function(err) {
                dispatch(reportError(err));
                dispatch(setLoading(false, accountId, undefined));
            });
    };
};

export const updateProjectStorySelectionLogic = (accountId: string, projectName: string, storySelectionLogic: LogicJSON) => {
    return (dispatch, getState, services: ThunkServices) => {
        const program: Program = StateReaderUtils.getProject(getState(), projectName);
        const isProgramPermanentlyMigratedToPostgres = StateReaderUtils.isProgramBulkDynamoMigrationDone(getState(), program.graphQLId, BulkId.MostEntities);

        let dataToDispatch: any = {
            logicContainerType: LogicContainers.StorySelection,
            logicItems: {
                [LogicContainers.StorySelection]: storySelectionLogic
            }
        };

        if (!isProgramPermanentlyMigratedToPostgres) {
            return services.wireframes
                .setProjectWireFramesSceneInputLogic(
                    accountId,
                    projectName,
                    LogicContainers.Program,
                    getDynamoLogicContainerId(LogicContainers.StorySelection, LogicContainers.StorySelection),
                    storySelectionLogic
                )
                .then(() => {
                    dispatch(updateProgramLogicItems(projectName, dataToDispatch));
                })
                .then(() => {
                    // Graph QL update
                    const { programVersionId } = StateReaderUtils.getBuilderProgramAndDraftVersionIds(getState(), projectName);

                    let input: GqlClientUpdateProgramVersionDraftInput = {
                        id: programVersionId,
                        updated: IGNORE_UPDATED,
                        storySelectionLogic: storySelectionLogic
                    };

                    return services.graphQlClient
                        .mutate({
                            mutation: UpdateProgramVersionDocument,
                            variables: {
                                input: input,
                                [IGNORE_SERVER_ERRORS]: true
                            }
                        })
                        .catch(() => {});
                })
                .catch((err) => dispatch(reportError(err)));
        }
        else {
            const { programVersionId } = StateReaderUtils.getBuilderProgramAndDraftVersionIds(getState(), projectName);

            let input: GqlClientUpdateProgramVersionDraftInput = {
                id: programVersionId,
                updated: IGNORE_UPDATED,
                storySelectionLogic: storySelectionLogic
            };

            return services.graphQlClient
                .mutate<GqlClientUpdateProgramMutation>({
                    mutation: UpdateProgramVersionDocument,
                    variables: {
                        input: input,
                        [IGNORE_SERVER_ERRORS]: true
                    }
                })
                .then((res) => {
                    const program = getProgramFromGQLResponse(res);
                    dataToDispatch.updated = program.updated;
                    dispatch(updateProgramLogicItems(projectName, dataToDispatch));
                })
                .catch((err) => dispatch(reportError(err)));
        }
    };
};

const getProjectLifecycleHistory = async (getState, services: ThunkServices, accountId: string, projectName: string, programId: string, stages, forceLoad?: boolean): Promise<LifecycleHistory> => {
    const readBuilderLifecycleHistoryFromPostgres = StateReaderUtils.isProgramBulkDynamoMigrationDone(getState(), programId, BulkId.LifecycleHistory);
    let dynamoProjectLifecycleHistory: LifecycleHistory;
    let gqlProjectLifecycleHistory: LifecycleHistory;

    // Need dynamo project lifecycle stages
    if (!readBuilderLifecycleHistoryFromPostgres) {
        let promises: Array<Promise<StageLifeHistory>> = [];
        stages.forEach((stage) => {
            let stageSnapshot = StateReaderUtils.getProjectStageSnapshots(getState(), projectName, stage);
            if (stageSnapshot.length <= 1 || forceLoad) {
                // Another action puts one record in stageSnapshot, so even if it has one, we still want to bring everything

                promises.push(services.projectLifeCycleServices.getProjectLifecycleStage(accountId, projectName, stage));
            }
        });
        dynamoProjectLifecycleHistory = await Promise.all(promises);
    }

    // Need postgres project lifecycle stages
    if (readBuilderLifecycleHistoryFromPostgres) {
        const getProgramLifecycleHistoryQueryResponse = await services.graphQlClient
            .query<GqlClientGetProgramLifecycleHistoryQuery>({
                fetchPolicy: "no-cache",
                query: GetProgramLifecycleHistoryDocument,
                variables: {
                    programId: programId
                }
            })
            .catch(() => null);
        gqlProjectLifecycleHistory = convertGqlLifecycleHistoryResult(getProgramLifecycleHistoryQueryResponse);
    }

    return readBuilderLifecycleHistoryFromPostgres ? gqlProjectLifecycleHistory : dynamoProjectLifecycleHistory;
};
