import { createDataElementValueSetAdapter } from "./DataElementValueSetAdapter";
import { createPrioritizedListCandidatesValueSetAdapter, createPrioritizedListValidSlotsValueSetAdapter } from "./PrioritizedListValueSetAdapter";
import { mapNumToLetter } from "../../../../../common/generalUtils";
import type { DataElement } from "../../../../../common/types/dataElement";
import type { ValueSetProvider, PrioritizedList } from "../../../common/types";

export const generatePrioritizedListCandidateNamesValueSetProviders = (prioritizedList) => {
    let prioritizedListValueSetProviders = [];
    Array.from(Array(prioritizedList.maxSlots).keys()).forEach((num) => {
        const slotCharacter = mapNumToLetter(num);
        prioritizedListValueSetProviders.push(createPrioritizedListCandidatesValueSetAdapter(prioritizedList, `Slot ${slotCharacter}`));
    });
    return prioritizedListValueSetProviders;
};

const generatePrioritizedListValueSetProviders = (prioritizedList): ValueSetProvider[] => {
    let prioritizedListValueSetProviders = [];
    prioritizedListValueSetProviders.push(...generatePrioritizedListCandidateNamesValueSetProviders(prioritizedList));
    prioritizedListValueSetProviders.push(createPrioritizedListValidSlotsValueSetAdapter(prioritizedList));
    return prioritizedListValueSetProviders;
};

export const generateValueSetProviders = (dataElements: DataElement[], prioritizedLists: PrioritizedList[]): ValueSetProvider[] => {
    let valueSetProviders: ValueSetProvider[] = [];

    dataElements.forEach((dataElement) => {
        valueSetProviders.push(createDataElementValueSetAdapter(dataElement));
    });
    prioritizedLists.forEach((prioritizedList) => {
        valueSetProviders.push(...generatePrioritizedListValueSetProviders(prioritizedList));
    });

    return valueSetProviders;
};
