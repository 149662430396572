import BaseServices from "./BaseServices";
import { EDITING_MODE, STAGE_MODE } from "./Consts";
import type { Asset } from "../../../common/types/asset";


class FileToVideoServices extends BaseServices {
    constructor(authService, builderModeRetriever) {
        super(authService, builderModeRetriever);
    }

    uploadFile(accountId: string, file: File): Promise<any> {
        const formData: FormData = new FormData();
        formData.append("assetName", file.name);
        formData.append("file", file);
        formData.append("fileName", file.name);
        formData.append("fileType", file.type); //"message/rfc822");
        return this.post<Asset>(
            [EDITING_MODE, STAGE_MODE],
            `/rest/${accountId}/uploadFileToVideo`,
            formData,
            { "Content-Type": "multipart/form-data" });
    }
}

export default FileToVideoServices;
